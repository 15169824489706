import React, { useEffect, useState } from "react";
import { Table, Typography } from "@mui/material";
import { MedStaffProfileItem } from "@veris-health/user-ms/lib/v1";
import { VrsAvatar } from "@veris-health/web-core";
import { StyledTableContainer } from "../HospitalsTable";
import {
  StyledTableBody,
  StyledTableCell,
  StyledTableRow,
} from "../../../../ui/components/Tables/shared/StyledTableElements";
import {
  Column,
  TableColumnNames,
  VrsSortedTableHeader,
} from "../../../../ui/components/VrsSortedTableHeader";
import { genericSorter } from "../../../../utils/sorting";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { selectHospitalBasedOnFilter } from "../../doctorsProfileSlice";

export interface MedicalStaffTableProps {
  medicalStaff: MedStaffProfileItem[];
}

const columns: Column[] = [
  { id: TableColumnNames.Placeholder, label: TableColumnNames.Placeholder },
  {
    id: TableColumnNames.MemberFirstName,
    label: TableColumnNames.MemberFirstName,
    orderBy: "first_name",
  },
  {
    id: TableColumnNames.MemberLastName,
    label: TableColumnNames.MemberLastName,
    orderBy: "last_name",
  },
  {
    id: TableColumnNames.MemberProfession,
    label: TableColumnNames.MemberProfession,
    orderBy: "profession",
  },
  {
    id: TableColumnNames.HospitalName,
    label: TableColumnNames.HospitalName,
  },
];

export function MedicalStaffTable({ medicalStaff }: MedicalStaffTableProps): JSX.Element {
  const [sortedMembers, setSortedMembers] = useState(medicalStaff);
  const currentHospital = useAppSelector(selectHospitalBasedOnFilter);

  useEffect(() => {
    setSortedMembers(medicalStaff);
  }, [medicalStaff]);

  return (
    <StyledTableContainer style={{ maxHeight: "250px" }}>
      <Table>
        <VrsSortedTableHeader
          columns={columns}
          sortTableData={(order, orderBy) =>
            sortedMembers &&
            setSortedMembers(
              genericSorter(sortedMembers, order, orderBy as keyof MedStaffProfileItem),
            )
          }
        />

        <StyledTableBody>
          {sortedMembers?.map((member) => (
            <StyledTableRow key={member.id}>
              {columns.map((column) => (
                <StyledTableCell key={column.id}>
                  {column.id === TableColumnNames.Placeholder && (
                    <VrsAvatar name={`${member.first_name} ${member.last_name}`} size={30} />
                  )}
                  {column.id === TableColumnNames.MemberFirstName && (
                    <Typography
                      variant="subtitle2"
                      color={(theme) => theme.veris.colors.neutrals["grey-4"]}
                    >
                      {member.first_name}
                    </Typography>
                  )}
                  {column.id === TableColumnNames.MemberLastName && (
                    <Typography
                      variant="subtitle2"
                      color={(theme) => theme.veris.colors.neutrals["grey-4"]}
                    >
                      {member.last_name}
                    </Typography>
                  )}
                  {column.id === TableColumnNames.MemberProfession && (
                    <Typography
                      variant="subtitle2"
                      color={(theme) => theme.veris.colors.neutrals["grey-4"]}
                      sx={{
                        textTransform: "capitalize",
                      }}
                    >
                      {member.profession}
                    </Typography>
                  )}
                  {column.id === TableColumnNames.HospitalName && (
                    <Typography
                      variant="subtitle2"
                      color={(theme) => theme.veris.colors.neutrals["grey-4"]}
                    >
                      {currentHospital?.hospital_name}
                    </Typography>
                  )}
                </StyledTableCell>
              ))}
            </StyledTableRow>
          ))}
        </StyledTableBody>
      </Table>
    </StyledTableContainer>
  );
}
