import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchCalendarEventsProps } from "../../Calendar/calendarSlice";
import { extractErrorMessage } from "../../shared/helpers";
import { fetchCalendarEvents } from "../../shared/slices/api/calendarApi";

export const fetchCalendarEventsAsync = createAsyncThunk(
  "dashboardSlice/fetchCalendarEventsAsync",
  async ({ userId, start, end, statuses }: fetchCalendarEventsProps, { rejectWithValue }) => {
    try {
      const events = await fetchCalendarEvents(userId, start, end, statuses);
      return events;
    } catch (err) {
      const errorMsg = extractErrorMessage(err);
      if (errorMsg) return rejectWithValue(errorMsg || "Could not load appointments.");
      throw err;
    }
  },
);
