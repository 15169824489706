import { useMemo } from "react";
import { get, maxBy, minBy } from "lodash";
import { BloodPressureNormalRange, SensorNormalRange } from "../measurementSlice";

export type SensorDetails = {
  normalRange: SensorNormalRange;
  abnormalRange: {
    positive: number;
    negative: number;
  };
  calculatedRange: {
    min: number;
    max: number;
  };
};

function getSensorMinValue(
  dataKey: string,
  normalRange: {
    min: number | BloodPressureNormalRange;
    max: number | BloodPressureNormalRange;
  },
): number {
  if (typeof normalRange.min === "number") {
    return normalRange.min;
  }

  return normalRange.min && normalRange.min[dataKey as keyof BloodPressureNormalRange];
}

function getSensorMaxValue(
  dataKey: string,
  normalRange: {
    min: number | BloodPressureNormalRange;
    max: number | BloodPressureNormalRange;
  },
): number {
  if (typeof normalRange.max === "number") {
    return normalRange.max;
  }

  return normalRange.max && normalRange.max[dataKey as keyof BloodPressureNormalRange];
}

function getAbnormalPositivePercentage(
  maxMeasuredValue: number,
  minMeasuredValue: number,
  sensorNormalMax: number,
): number {
  if (maxMeasuredValue < sensorNormalMax) return 0;
  return (
    ((maxMeasuredValue - (sensorNormalMax || 0)) * 100) /
    (maxMeasuredValue - minMeasuredValue === 0 ? 1 : maxMeasuredValue - minMeasuredValue)
  );
}

function getAbnormalNegativePercentage(
  maxMeasuredValue: number,
  minMeasuredValue: number,
  sensorNormalMin: number,
): number {
  if (minMeasuredValue > sensorNormalMin) return 0;
  return (
    (((sensorNormalMin || 0) - minMeasuredValue) * 100) /
    (maxMeasuredValue - minMeasuredValue === 0 ? 1 : maxMeasuredValue - minMeasuredValue)
  );
}
export default function useSensorDetails({
  data,
  dataKey,
  normalRange,
  implantKey,
}: {
  data: unknown[];
  normalRange: {
    min: number | BloodPressureNormalRange;
    max: number | BloodPressureNormalRange;
  };
  dataKey?: string;
  implantKey?: string;
}): SensorDetails {
  const {
    sensorNormalMin,
    sensorNormalMax,
    abnormalPositivePercentage,
    abnormalNegativePercentage,
    minMeasuredValue,
    maxMeasuredValue,
  } = useMemo((): {
    sensorNormalMin: number;
    sensorNormalMax: number;
    abnormalPositivePercentage: number;
    abnormalNegativePercentage: number;
    maxMeasuredValue: number;
    minMeasuredValue: number;
  } => {
    if (!dataKey && !implantKey)
      return {
        sensorNormalMin: 0,
        sensorNormalMax: 0,
        abnormalPositivePercentage: 0,
        abnormalNegativePercentage: 0,
        maxMeasuredValue: 0,
        minMeasuredValue: 0,
      };

    const normalMin = getSensorMinValue(dataKey as string, normalRange);
    const normalMax = getSensorMaxValue(dataKey as string, normalRange);

    const getMaxMeasured = () => {
      const max1: number =
        get(
          maxBy(data, (value) => get(value, dataKey as string)),
          dataKey as string,
        ) || 0;

      const max2: number =
        get(
          maxBy(data, (value) => get(value, implantKey as string)),
          implantKey as string,
        ) || 0;

      if (max1 && max2) return max1 > max2 ? max1 : max2;
      return max1 || max2;
    };

    const getMinMeasured = () => {
      const min1: number = get(
        minBy(data, (value) => get(value, dataKey as string)),
        dataKey as string,
      );

      const min2: number = get(
        minBy(data, (value) => get(value, implantKey as string)),
        implantKey as string,
      );
      if (min1 && min2) return min1 < min2 ? min1 : min2;
      return min1 || min2;
    };
    const minMeasured = getMinMeasured();
    const maxMeasured = getMaxMeasured();

    const positivePercentage = getAbnormalPositivePercentage(maxMeasured, minMeasured, normalMax);
    const negativePercentage = getAbnormalNegativePercentage(maxMeasured, minMeasured, normalMin);
    return {
      sensorNormalMin: normalMin,
      sensorNormalMax: normalMax,
      abnormalPositivePercentage: positivePercentage,
      abnormalNegativePercentage: negativePercentage,
      maxMeasuredValue: maxMeasured,
      minMeasuredValue: minMeasured,
    };
  }, [data, dataKey]);

  return {
    normalRange: {
      min: sensorNormalMin,
      max: sensorNormalMax,
    },
    abnormalRange: {
      positive: abnormalPositivePercentage,
      negative: abnormalNegativePercentage,
    },
    calculatedRange: {
      min: minMeasuredValue,
      max: maxMeasuredValue,
    },
  };
}
