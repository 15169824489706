import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TagItem } from "@veris-health/user-ms/lib/v1";
import { uniqBy } from "lodash";
import {
  sortByKeyAsc,
  sortByKeyDesc,
  sortVrsPatientByInfusionTimeDesc,
  sortVrsPatientByInfusionTimeAsc,
} from "../shared/helpers";
import { Status, VrsPatientInfo } from "../shared/interfaces";
import { localizedLogout, logout } from "../shared/slices/authSlice";
import {
  selectCancerTypeFilter,
  selectPatients,
  selectSortByAlphabet,
  selectSortByLastInfusion,
  selectSymptomFilter,
  selectTagFilter,
} from "../shared/slices/patientsSlice";

export type SortByAlphabet = "asc" | "desc";
export type SortByLastInfusion =
  | "Sorted by last infusion ascending"
  | "Sorted by last infusion descending";

interface PatientsState {
  page: number;
  rowsPerPage: number;
  status: Status;
}

const initialState: PatientsState = {
  page: 0,
  rowsPerPage: 5,
  status: "idle",
};

// Reducers & Thunks

const patientsSlice = createSlice({
  name: "Patient List",
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setRowsPerPage: (state, action: PayloadAction<number>) => {
      state.page = 0;
      state.rowsPerPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout, () => {
      return initialState;
    });
    builder.addCase(localizedLogout, () => {
      return initialState;
    });
  },
});

// Actions

export const { setPage, setRowsPerPage } = patientsSlice.actions;

// Selectors

export const selectPatientsDataCancerTypeOptions = createSelector([selectPatients], (patients) => {
  const cancerTypeOptions = patients
    .map((patient) => patient.diagnosis?.cancerType)
    .filter((value, index, arr) => arr.indexOf(value) === index)
    .filter((value) => value !== undefined) as string[];

  return uniqBy(cancerTypeOptions, (option) => option.toLowerCase());
});

export const selectPatientTagOptions = createSelector([selectPatients], (patients) =>
  patients
    .reduce((acc, { tags }) => [...acc, ...(tags as TagItem[])], [] as TagItem[])
    .reduce((acc, tagObject) => [...acc, tagObject.label ? tagObject.label : ""], [] as string[])
    .filter((value, index, arr) => arr.indexOf(value) === index),
);

export const selectPatientSymptomOptions = createSelector([selectPatients], (patients) => [
  ...patients
    .reduce(
      (acc: string[], { activeSymptoms }) => [...acc, ...(activeSymptoms?.allActive as string[])],
      [],
    )
    .filter((value, index, arr) => arr.indexOf(value) === index),
]);

export const selectFilteredPatientsByCancerType = createSelector(
  [selectCancerTypeFilter, selectPatients],
  (cancerType, patients) =>
    patients.filter(
      (patient) => !cancerType || cancerType.includes(patient.diagnosis?.cancerType || ""),
    ),
);

export const selectFilteredPatients = createSelector(
  [selectCancerTypeFilter, selectTagFilter, selectSymptomFilter, selectPatients],
  (cancerTypeFilters, tagFilters, symptomFilter, patients) => {
    return patients
      .filter(
        ({ diagnosis }) =>
          cancerTypeFilters?.length === 0 ||
          cancerTypeFilters?.includes(diagnosis?.cancerType || ""),
      )
      .filter(
        ({ tags }) =>
          tagFilters?.length === 0 ||
          tagFilters?.every((filter) => tags?.some((tag) => tag.label === filter)),
      )
      .filter(
        ({ activeSymptoms }) =>
          symptomFilter?.length === 0 ||
          symptomFilter?.every((filter) =>
            activeSymptoms?.allActive?.some((symptom) => symptom === filter),
          ),
      );
  },
);

export const selectSortedByAlphabetAndFilteredPatients = createSelector(
  [selectSortByAlphabet, selectFilteredPatients],
  (direction, filteredPatients) => {
    switch (direction) {
      case "asc":
        return filteredPatients.slice().sort(sortByKeyAsc<VrsPatientInfo>("name"));
      case "desc":
        return filteredPatients.slice().sort(sortByKeyDesc<VrsPatientInfo>("name"));
      default:
        return filteredPatients;
    }
  },
);

export const selectSortedByLastInfusionAndFilteredPatients = createSelector(
  [selectSortByLastInfusion, selectFilteredPatients],
  (direction, filteredPatients) => {
    switch (direction) {
      case "Sorted by last infusion descending":
        return filteredPatients.slice().sort(sortVrsPatientByInfusionTimeDesc("lastInfusion"));
      case "Sorted by last infusion ascending":
        return filteredPatients.slice().sort(sortVrsPatientByInfusionTimeAsc("lastInfusion"));
      default:
        return filteredPatients;
    }
  },
);

export const selectSortedAndFilteredPatients = createSelector(
  [selectSortByAlphabet, selectSortByLastInfusion, selectFilteredPatients],
  (sortbyAlphabet, sortbyLastInfusion, filteredPatients) => {
    if (sortbyAlphabet) {
      return filteredPatients
        .slice()
        .sort(
          sortbyAlphabet === "asc"
            ? sortByKeyAsc<VrsPatientInfo>("name")
            : sortByKeyDesc<VrsPatientInfo>("name"),
        );
    }
    if (sortbyLastInfusion) {
      return filteredPatients
        .slice()
        .sort(
          sortbyLastInfusion === "Sorted by last infusion descending"
            ? sortVrsPatientByInfusionTimeDesc("lastInfusion")
            : sortVrsPatientByInfusionTimeAsc("lastInfusion"),
        );
    }
    return filteredPatients.slice();
  },
);

export const selectSortedAndFilteredVrsPatients = createSelector(
  [
    selectSortByAlphabet,
    selectSortByLastInfusion,
    selectSortedByAlphabetAndFilteredPatients,
    selectSortedByLastInfusionAndFilteredPatients,
    selectSortedAndFilteredPatients,
  ],
  (
    sortbyAlphabet,
    sortbyLastInfusion,
    sortedByAlphabetAndfilteredPatients,
    sortedByLastInfusionAndFilteredPatients,
    sortedAndFilteredPatients,
  ) => {
    if (sortbyAlphabet) {
      return sortedByAlphabetAndfilteredPatients.slice();
    }
    if (sortbyLastInfusion) {
      return sortedByLastInfusionAndFilteredPatients.slice();
    }
    return sortedAndFilteredPatients.slice();
  },
);

export default patientsSlice.reducer;
