import { Box, CircularProgress, Typography } from "@mui/material";
import { DiagnosticReportObservation } from "@veris-health/med-data-ms/lib/v1";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { isNumber, toNumber, isFinite, get } from "lodash";
import {
  VrsIconButton,
  IconName,
  VrsSelect,
  VrsErrorMsg,
  dateFormats,
} from "@veris-health/web-core";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { VrsLabResultsTable } from "../../../ui/components/Tables/VrsLabResultsTable";
import { VrsLabResultsComparisonChart } from "../../../ui/components/VrsLabResultsComparisonChart";
import {
  loadLabResultsComparisonDataAsync,
  loadLabResultsDetailedListAsync,
  selectLabResultsComparisonData,
  selectLabResultsComparisonStatus,
  selectLabResultsDetailedList,
  selectLabResultsDetailedListStatus,
  selectSelectedRecord,
} from "../patientRecordsSlice";
import { useCurrentPatient } from "../../../hooks/useCurrentPatient";

const ComparisonChartOptions = [
  {
    value: "3",
    label: "3 results",
  },
  {
    value: "5",
    label: "5 results",
  },
];

const InvalidChartDataMessage = () => (
  <Box display="flex" alignItems="center" justifyContent="center" height={238}>
    <Typography variant="body" color={(theme) => theme.veris.colors.neutrals["grey-3"]}>
      Please select a lab parameter that has numeric result value.
    </Typography>
  </Box>
);

export interface LabResultPreviewContainerProps {
  onCloseClickHandler: () => void;
}

const LabResultPreviewContainer = ({
  onCloseClickHandler,
}: LabResultPreviewContainerProps): JSX.Element => {
  const currentPatient = useCurrentPatient();
  const selectedRecord = useAppSelector(selectSelectedRecord);
  const labResultsList = useAppSelector((state) =>
    selectLabResultsDetailedList(state, selectedRecord?.id ? selectedRecord.id : undefined),
  );
  const labResultsComparisonStatus = useAppSelector(selectLabResultsComparisonStatus);
  const labResultsStatus = useAppSelector(selectLabResultsDetailedListStatus);
  const [selectedResult, setSelectedResult] = useState(labResultsList?.observations[0]);
  const [showChartMessage, setShowChartMessage] = useState(false);
  const labResultsComparisonData = useAppSelector((state) =>
    selectLabResultsComparisonData(state, get(selectedResult, "codes[0]")),
  );
  const [range, setRange] = useState<number>(3);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setShowChartMessage(false);
  }, [selectedRecord, selectedResult]);

  useEffect(() => {
    setSelectedResult(labResultsList?.observations[0]);
  }, [labResultsList]);

  useEffect(() => {
    setShowChartMessage(false);

    if (selectedResult?.value) {
      const convertedValueToNumeric = toNumber(selectedResult.value);
      const isValueNumber = isNumber(convertedValueToNumeric) && isFinite(convertedValueToNumeric);
      if (!isValueNumber) {
        setShowChartMessage(true);
        return;
      }
    }

    if (
      currentPatient &&
      selectedResult?.date &&
      selectedResult?.codes &&
      selectedResult?.codes?.length > 0
    ) {
      dispatch(
        loadLabResultsComparisonDataAsync({
          patientId: currentPatient.id,
          date: selectedResult.date,
          codes: selectedResult.codes,
          numberOfObservations: range,
        }),
      );
    }
  }, [dispatch, selectedResult, currentPatient, range]);

  const codes = get(selectedResult, "codes");

  return (
    <Box
      width="100%"
      sx={{
        borderLeft: (theme) => `1px solid ${theme.veris.colors.neutrals["grey-2"]}`,
        maxHeight: "70vh",
        padding: (theme) => theme.spacing(1, 1, 0, 1),
      }}
    >
      <Box
        p={1}
        display="flex"
        alignItems="center"
        sx={{
          borderBottom: (theme) => `1px solid ${theme.veris.colors.neutrals["grey-2"]}`,
          marginTop: (theme) => theme.spacing(0.25),
        }}
        gap={1}
      >
        <Typography variant="h6Semibold"> {selectedRecord?.reportName} </Typography>
        <Typography variant="body1" sx={{ fontSize: (theme) => theme.spacing(3.5) }}>
          •
        </Typography>
        <Typography variant="subtitle2">
          {" "}
          {dayjs(selectedRecord?.date).format(dateFormats["MMM D"])}
        </Typography>
        <Box display="flex" flex={1} />
        <VrsIconButton
          iconProps={{ name: IconName.CloseIcon, size: 13 }}
          onClick={onCloseClickHandler}
        />
      </Box>
      {codes?.length === 0 && (
        <Typography
          variant="subtitle2"
          sx={{ fontSize: (theme) => theme.spacing(3.5) }}
          mt={1}
          ml={1}
        >
          Observation doesn&apos;t have a code and cannot be compared with other observations
        </Typography>
      )}
      {codes?.length !== 0 && labResultsComparisonStatus === "failed" && (
        <VrsErrorMsg
          size="small"
          onClick={() => {
            if (currentPatient && selectedResult?.date && codes && codes.length > 0)
              dispatch(
                loadLabResultsComparisonDataAsync({
                  patientId: currentPatient.id,
                  date: selectedResult.date,
                  codes,
                  numberOfObservations: range,
                }),
              );
          }}
        />
      )}

      {labResultsComparisonStatus === "loading" && (
        <Box display="flex" justifyContent="center" alignItems="center" height={238}>
          <CircularProgress />
        </Box>
      )}
      {Boolean(codes?.length) && (
        <>
          {labResultsComparisonStatus === "idle" &&
            labResultsStatus === "idle" &&
            (showChartMessage ? (
              <InvalidChartDataMessage />
            ) : (
              <>
                <Box p={1} display="flex" alignItems="center">
                  <Typography
                    variant="bodyMedium"
                    color={(theme) => theme.veris.colors.neutrals["grey-4"]}
                  >
                    Compare Results Overview
                  </Typography>
                  <Box display="flex" flex={1} />
                  <VrsSelect
                    options={ComparisonChartOptions}
                    onSelected={(value) => setRange(+value)}
                    initialValue="3"
                    value={String(range)}
                  />
                </Box>
                <VrsLabResultsComparisonChart data={labResultsComparisonData || []} />
              </>
            ))}
        </>
      )}

      {labResultsStatus === "failed" && (
        <VrsErrorMsg
          size="small"
          onClick={() => {
            if (currentPatient && selectedRecord)
              dispatch(
                loadLabResultsDetailedListAsync({
                  patientId: currentPatient.id,
                  reportId: selectedRecord.id,
                }),
              );
          }}
        />
      )}
      {labResultsStatus === "loading" && (
        <Box display="flex" justifyContent="center" alignItems="end" height="45vh">
          <CircularProgress />
        </Box>
      )}
      {labResultsStatus === "idle" && (
        <VrsLabResultsTable
          labResults={labResultsList}
          selectedResult={selectedResult}
          onClick={(result: DiagnosticReportObservation) => setSelectedResult(result)}
        />
      )}
    </Box>
  );
};

export default LabResultPreviewContainer;
