import React, { CSSProperties } from "react";
import { Box, Grid, Theme, Typography, useMediaQuery, Badge } from "@mui/material";
import dayjs from "dayjs";
import {
  IconName,
  VrsIcon,
  VrsAvatar,
  VrsTooltip,
  verisColors,
  dateFormats,
} from "@veris-health/web-core";
import { PatientStatusEnumAPI } from "@veris-health/user-ms/lib/v2";
import { VrsPatientInfo } from "../../../features/shared/interfaces";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { selectAvatarById } from "../../../features/shared/slices/patientsSlice";
import { PatientStatusesMap } from "../../../constants";

export interface VrsPatientInfoCardProps {
  patientInfo: VrsPatientInfo;
  backgroundColor?: string;
  newNotification?: boolean;
  onClick?: (id: number) => void;
  displayBattery?: boolean;
  styles?: {
    diagnosis?: CSSProperties;
  };
}

const renderBatteryByPercentageLvl = (percentage: number) => {
  switch (Math.floor(percentage / 20)) {
    case 0:
      return percentage <= 15 ? IconName.AlertBattery : IconName.Battery1Bar;
    case 1:
      return IconName.Battery1Bar;
    case 2:
      return IconName.HalfBattery;
    case 3:
    case 4:
      return IconName.Battery3Bars;
    case 5:
      return IconName.FullBattery;
    default:
      return IconName.FullBattery;
  }
};

const getPatientsAgeFromDoB = (dob: string) => {
  return dayjs(dayjs()).diff(dob, "years");
};

export function VrsPatientInfoCard({
  patientInfo,
  backgroundColor,
  newNotification,
  onClick,
  displayBattery = false,
  styles,
}: VrsPatientInfoCardProps): JSX.Element {
  const avatar = useAppSelector((state) => selectAvatarById(state, patientInfo.id));

  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("xl"));
  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));
  const { name, id, diagnosis, isInactiveOrDeceased, observation, patientStatus } = patientInfo;
  const avatarSize = isSmallScreen ? 38 : 44;

  const age = patientInfo?.dob && getPatientsAgeFromDoB(patientInfo?.dob);
  const dateOfBirth = patientInfo.dob
    ? `${dayjs(patientInfo.dob).format(dateFormats["MM/DD/YYYY"])} (${age})`
    : "";
  const MRN = patientInfo?.ehrId;

  return (
    <Box
      data-test-hook="patientInfoCard-element"
      display="flex"
      alignItems="center"
      paddingY={(theme) => theme.spacing(0.375)}
      paddingX={(theme) => theme.spacing(0.625)}
      sx={{
        borderRadius: 1.5,
        flexGrow: 1,
        backgroundColor: backgroundColor || verisColors.neutrals["grey-background"],
      }}
    >
      <>
        {newNotification ? (
          <Badge
            data-test-hook="badge-element"
            variant="dot"
            color="info"
            anchorOrigin={{ vertical: "top", horizontal: "left" }}
            sx={{ paddingLeft: "4px", paddingTop: "4px" }}
            overlap="circular"
          >
            <Box mr={1.5}>
              <VrsAvatar
                imageUrl={avatar?.picture}
                name={name}
                size={avatarSize}
                opacity={isInactiveOrDeceased ? "70%" : "100%"}
                bottomRightIcon={observation && <VrsIcon name={IconName.Observation} />}
              />
            </Box>
          </Badge>
        ) : (
          <Box
            data-test-hook="avatarWrapper-element"
            mr={1.5}
            onClick={() => onClick && onClick(id)}
            sx={{ cursor: onClick ? "pointer" : "auto" }}
          >
            <VrsAvatar
              imageUrl={avatar?.picture}
              name={name}
              size={avatarSize}
              opacity={isInactiveOrDeceased ? "70%" : "100%"}
              bottomRightIcon={observation && <VrsIcon name={IconName.Observation} />}
            />
          </Box>
        )}
      </>

      <Grid marginX={(theme) => theme.spacing(0, 1.5)} marginTop={(theme) => theme.spacing(0.5)}>
        <Box
          sx={{
            display: "flex",
            cursor: onClick ? "pointer" : "auto",
            alignItems: "center",
          }}
          onClick={() => onClick && onClick(id)}
        >
          <Typography
            variant="subtitle1"
            color={
              isInactiveOrDeceased ? verisColors.neutrals["grey-3"] : verisColors.neutrals.black
            }
            sx={{ wordBreak: "break-word" }}
            marginRight={(theme) => theme.spacing(1)}
          >
            {name}
          </Typography>

          {!isTablet && !!MRN && (
            <Box display="flex" sx={{ minWidth: "45px" }}>
              <Typography variant="subtitle1" marginRight={(theme) => theme.spacing(1)}>
                •
              </Typography>
              <Typography variant="subtitle2" fontSize={isSmallScreen ? "14px" : ""}>
                {MRN}
              </Typography>
            </Box>
          )}
        </Box>

        {isTablet && (
          <Typography display="block" variant="subtitle2" fontSize={isSmallScreen ? "14px" : ""}>
            {MRN}
          </Typography>
        )}
        <Box display="flex" whiteSpace="nowrap" justifyContent="flex-start" alignItems="baseline">
          <Box
            data-test-hook="diagnosis-tag"
            sx={{
              display: "flex",
              alignItems: "center",
              flexFlow: "row wrap",
              wordBreak: "break-word",
              whiteSpace: "normal",
              ...styles?.diagnosis,
            }}
          >
            <Box marginRight={(theme) => theme.spacing(0.25)}>
              <Typography
                variant="subtitle2"
                color={
                  isInactiveOrDeceased
                    ? verisColors.neutrals["grey-3"]
                    : verisColors.neutrals["grey-dark"]
                }
              >
                {diagnosis?.cancerType || "N/A"}
                ,&nbsp;
              </Typography>
            </Box>
            <Typography
              variant="caption"
              color={
                isInactiveOrDeceased
                  ? verisColors.neutrals["grey-3"]
                  : verisColors.neutrals["grey-dark"]
              }
            >
              Stage {diagnosis?.cancerStage || "N/A"}
            </Typography>
            {displayBattery && patientInfo?.batteryLevel && (
              <>
                <Typography
                  variant="subtitle1"
                  margin={(theme) => theme.spacing(0, 1)}
                  color={(theme) =>
                    isInactiveOrDeceased
                      ? theme.veris.colors.neutrals["grey-3"]
                      : theme.veris.colors.neutrals["grey-dark"]
                  }
                >
                  •
                </Typography>
                <VrsTooltip
                  data-test-hook="battery-icon"
                  title={`Battery level: ${patientInfo.batteryLevel}%`}
                  bcgcolor={verisColors.neutrals.black}
                >
                  <span
                    style={{
                      alignSelf: "center",
                      height: "18px",
                    }}
                  >
                    <VrsIcon
                      name={renderBatteryByPercentageLvl(Number(patientInfo.batteryLevel))}
                    />
                  </span>
                </VrsTooltip>
              </>
            )}
          </Box>
        </Box>
        <Typography
          variant="caption"
          color={(theme) =>
            patientStatus === PatientStatusEnumAPI.Deceased
              ? theme.veris.colors.mango.dark
              : theme.veris.colors.neutrals["grey-3"]
          }
        >
          {patientStatus && PatientStatusesMap[patientStatus]}
        </Typography>
        <Typography display="block" variant="subtitle2" fontSize="16px">
          {dateOfBirth}
        </Typography>
      </Grid>
    </Box>
  );
}
