import { Box, Typography } from "@mui/material";
import React from "react";

export interface VrsEmptyDatasetMessageProps {
  message: string;
}

export function VrsEmptyDatasetMessage({ message }: VrsEmptyDatasetMessageProps): JSX.Element {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-start",
        flexDirection: "column",
      }}
    >
      <Typography
        variant="caption"
        color={(theme) => theme.veris.colors.neutrals["grey-4"]}
        data-test-hook="empty-dateset-message"
      >
        {message}
      </Typography>
    </Box>
  );
}
