import {
  AllergiesApi as AllergiesApiV1,
  AppointmentsApi as AppointmentsApiV1,
  CareTeamNotesApi as CareTeamNotesApiV1,
  ClinicalNotesApi as ClinicalNotesApiV1,
  ConditionsApi as ConditionsApiV1,
  DiagnosticReportsApi as DiagnosticReportsApiV1,
  MedicationsApi as MedicationsApiV1,
  MedicationAdherenceApi as MedicationAdherenceApiV1,
  SensorDataApi as SensorDataApiV1,
  ClinicalTasksApi as ClinicalTaskApiV1,
} from "@veris-health/med-data-ms/lib/v1";

import config from "../axios-config";
import { API_ROUTES, getVersion, VERSIONS } from "../helpers";

export const appointmentsApiV1 = new AppointmentsApiV1(
  undefined,
  getVersion(API_ROUTES.MED_DATA, VERSIONS.v1),
  config,
);
export const allergiesApiV1 = new AllergiesApiV1(
  undefined,
  getVersion(API_ROUTES.MED_DATA, VERSIONS.v1),
  config,
);
export const labResultsApiV1 = new DiagnosticReportsApiV1(
  undefined,
  getVersion(API_ROUTES.MED_DATA, VERSIONS.v1),
  config,
);
export const careTeamNotesApiV1 = new CareTeamNotesApiV1(
  undefined,
  getVersion(API_ROUTES.MED_DATA, VERSIONS.v1),
  config,
);
export const clinicalNotesApiV1 = new ClinicalNotesApiV1(
  undefined,
  getVersion(API_ROUTES.MED_DATA, VERSIONS.v1),
  config,
);
export const medicationsApiV1 = new MedicationsApiV1(
  undefined,
  getVersion(API_ROUTES.MED_DATA, VERSIONS.v1),
  config,
);
export const medicationAdherenceApiV1 = new MedicationAdherenceApiV1(
  undefined,
  getVersion(API_ROUTES.MED_DATA, VERSIONS.v1),
  config,
);
export const conditionsApiV1 = new ConditionsApiV1(
  undefined,
  getVersion(API_ROUTES.MED_DATA, VERSIONS.v1),
  config,
);
export const sensorDataApiV1 = new SensorDataApiV1(
  undefined,
  getVersion(API_ROUTES.MED_DATA, VERSIONS.v1),
  config,
);

export const tasksApi = new ClinicalTaskApiV1(
  undefined,
  getVersion(API_ROUTES.MED_DATA, VERSIONS.v1),
  config,
);
