import { Reporter } from "@veris-health/communication-ms/lib/v1";
import { IconName, VrsIconButton } from "@veris-health/web-core";
import React, { useEffect } from "react";
import { matchPath, useHistory } from "react-router-dom";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import { Routes } from "../../routes-config";
import SnackbarUtils from "../../utils/SnackbarUtils";
import { selectUserId } from "../shared/slices/authSlice";
import { getUserUnconfirmedCallsList, setSelectedNotification } from "../shared/slices/voipSlice";
import { StyledNotificationBadge } from "../shared/styledComponents";
import { useFirebaseMessaging } from "./hooks/useFirebaseMessaging";
import { NotificationsDropdownContainer } from "./NotificationsDropdownContainer";
import {
  fetchNotificationDetailsAsync,
  increaseUnreadCount,
  loadNotificationsDataAsync,
  NotificationFilter,
  selectNotificationsFilter,
  selectUnreadCount,
  setNotificationsFilter,
  setupFirebaseToken,
} from "./notificationsSlice";
import { useProfile } from "../../context/profile";
import TaskContainer from "../Tasks/components/TaskContainer";
import { useTaskNotification } from "./hooks/useTaskNotification";

const NotificationsContainer = (): JSX.Element => {
  const unreadCount = useAppSelector(selectUnreadCount);
  const currentUserId = useAppSelector(selectUserId);
  const activeFilter = useAppSelector(selectNotificationsFilter);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | undefined>(undefined);
  const history = useHistory();
  const dispatch = useAppDispatch();
  const currentUser = useProfile();
  const isNotificationsRoute = Boolean(
    matchPath(history.location.pathname, { path: Routes.NOTIFICATIONS_DETAILS }),
  );
  const { setNotification, notificationTask } = useTaskNotification();

  const iconName: IconName =
    history.location.pathname.includes("notifications") || !!anchorEl
      ? IconName.ActiveNotifications
      : IconName.Notification;

  const { firebaseMessaging } = useFirebaseMessaging({
    onFirebaseMessage: (vrsNotificationId, type, isOnCall) => {
      if (vrsNotificationId) {
        if (type === "backgroundMessage" || type === "out-of-range-patients") {
          dispatch(fetchNotificationDetailsAsync({ vrsNotificationId })).then(() =>
            dispatch(increaseUnreadCount()),
          );
        } else if (type === "release-notes") {
          dispatch(fetchNotificationDetailsAsync({ vrsNotificationId })).then(() => {
            dispatch(increaseUnreadCount());
            SnackbarUtils.success("New Veris App Version");
          });
        } else {
          if (!isOnCall) dispatch(setSelectedNotification(Number(vrsNotificationId)));
          dispatch(
            getUserUnconfirmedCallsList({
              userId: Number(currentUserId),
              offset: 0,
            }),
          );
        }
      } else {
        SnackbarUtils.error("Failed to load the notification. Please reload the page.");
      }
    },
  });

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    dispatch(setNotificationsFilter(NotificationFilter.ViewAll));
    dispatch(
      loadNotificationsDataAsync({
        userId: Number(currentUserId),
        patientId: undefined,
        offset: 0,
        reportedBy:
          activeFilter === NotificationFilter.ViewAll
            ? undefined
            : (activeFilter as unknown as Reporter),
      }),
    );
  };

  useEffect(() => {
    if (Number(currentUserId) && firebaseMessaging) {
      dispatch(loadNotificationsDataAsync({ userId: Number(currentUserId) }));
      dispatch(setupFirebaseToken({ userId: Number(currentUserId), firebaseMessaging }));
    }
  }, [dispatch, currentUserId, firebaseMessaging]);

  return (
    <>
      <StyledNotificationBadge
        invisible={unreadCount < 1}
        variant="dot"
        onClick={(e) =>
          !isNotificationsRoute && handleClick(e as React.MouseEvent<HTMLButtonElement>)
        }
        data-test-hook="notifications"
      >
        <VrsIconButton disabled={isNotificationsRoute} iconProps={{ name: iconName, size: 36 }} />
      </StyledNotificationBadge>
      {!!anchorEl && (
        <NotificationsDropdownContainer
          anchorEl={anchorEl}
          handleClose={() => setAnchorEl(undefined)}
          setNotificationForTask={setNotification}
        />
      )}
      {notificationTask && currentUser && (
        <TaskContainer
          onClose={() => setNotification(undefined)}
          currentUser={currentUser}
          open={!!notificationTask}
          modalView
          currentPatientId={notificationTask?.patient?.id}
          notificationTask={notificationTask}
        />
      )}
    </>
  );
};

export default NotificationsContainer;
