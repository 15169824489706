import { ConditionResponse } from "@veris-health/med-data-ms/lib/v1";
import React, { useMemo, useState } from "react";
import { VrsPatientDetailsDialog } from "../../../ui/components/VrsPatientDetailsDialog";
import { PatientActiveProblemsTable } from "./PatientActiveProblemsTable";

export interface ActiveProblemsDialogProps {
  conditions: ConditionResponse[];
  openActiveProblemsDialog: boolean;
  patientName: string;
  onClose: () => void;
}

export function ActiveProblemsDialog({
  conditions,
  openActiveProblemsDialog,
  patientName,
  onClose,
}: ActiveProblemsDialogProps): JSX.Element {
  const [searchPhase, setSearchPhase] = useState<string>("");
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchPhase(event.target.value);
  };
  const filteredConditions = useMemo(() => {
    return searchPhase
      ? conditions.filter((condition) =>
          condition.condition?.trim()?.toLowerCase()?.includes(searchPhase.trim().toLowerCase()),
        )
      : conditions;
  }, [searchPhase, conditions]);
  return (
    <VrsPatientDetailsDialog
      isOpen={openActiveProblemsDialog}
      onClose={onClose}
      title="active problems"
      patientName={patientName}
      searchPhase={searchPhase}
      setSearchPhase={setSearchPhase}
      handleInputChange={handleInputChange}
    >
      <PatientActiveProblemsTable conditions={filteredConditions} />
    </VrsPatientDetailsDialog>
  );
}
