import React from "react";
import {
  ResponsiveContainer,
  Tooltip,
  ReferenceLine,
  YAxis,
  XAxis,
  LineChart,
  Line,
  DotProps,
} from "recharts";
import dayjs from "dayjs";
import { Measurement, SensorEnum } from "@veris-health/med-data-ms/lib/v1";
import { verisColors, dateFormats } from "@veris-health/web-core";
import { get } from "lodash";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import {
  ReferenceLine as ReferenceLineType,
  selectDateFrom,
  selectDateTo,
  SensorNormalRange,
  SensorsGraphView,
  setReferenceLine,
} from "../../measurementSlice";
import { trendingChartColors } from "../../../../constants";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { ChartTooltipWrapper, ChartLabel, ChartNormalValueLabel } from "../shared/tooltips";
import useSensorDetails from "../../hooks/useSensorDetails";
import { calculateDomain, getColorForStroke } from "../shared/utils";

interface CustomDotProps extends DotProps {
  sensorMin: number;
  sensorMax: number;
  payload: { v: number };
  index: number;
  data: (Measurement | null)[];
  sensorName: string;
}
const CustomDot = (props: CustomDotProps) => {
  const { sensorMin, sensorMax, cx, cy, payload, data, index, sensorName } = props;

  return (
    <>
      {payload && payload.v && ((!data[index - 1] && !data[index + 1]) || data.length === 1) && (
        <circle
          cx={cx}
          cy={cy}
          r={sensorName === "ecg" ? 4 : 2}
          stroke="none"
          fill={
            (payload.v >= sensorMin && payload.v <= sensorMax) || sensorName === "ecg"
              ? verisColors.moderate.normal
              : verisColors.mango.normal
          }
        />
      )}
    </>
  );
};
export interface MeasurementGraphProps {
  isPreview?: boolean;
  sensorValues: (Measurement | null)[];
  chartColor?: string;
  syncId: string;
  withMeasurementUnit?: string;
  referenceLine: ReferenceLineType;
  sensorName: SensorEnum;
  normalRange: SensorNormalRange;
  sensorsGraphView: SensorsGraphView;
}
export const MeasurementGraph = ({
  isPreview,
  sensorValues,
  withMeasurementUnit,
  referenceLine,
  sensorName,
  normalRange,
  sensorsGraphView,
}: MeasurementGraphProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const sensorDetails = useSensorDetails({
    data: sensorValues,
    dataKey: "v",
    normalRange,
  });
  const clickedIndex = sensorValues.findIndex((el) => el?.dt === referenceLine.date);
  const clickedDot = sensorValues[clickedIndex];
  const startDate = useAppSelector(selectDateFrom);
  const endDate = useAppSelector(selectDateTo);
  const filteredSensorValues = sensorValues.filter((value) => value !== null);
  const dateFormatter = (date: number) => {
    return dayjs.unix(date).format(dateFormats["MMMM DD, YYYY"]);
  };

  return (
    <>
      <ResponsiveContainer width="99%" height={110}>
        <LineChart
          data={sensorValues}
          margin={{
            bottom: 0,
            left: 26,
            right: 0,
            top: 25,
          }}
          onClick={(d1: { activeTooltipIndex?: number }) => {
            if (d1 && d1.activeTooltipIndex !== undefined && sensorName !== SensorEnum.Ecg) {
              const measurementValue = sensorValues[d1.activeTooltipIndex];
              if (measurementValue !== null) {
                dispatch(
                  setReferenceLine({
                    date: measurementValue?.dt,
                    valueIndex: d1.activeTooltipIndex,
                  }),
                );
              }
            }
          }}
        >
          <ReferenceLine
            y={sensorDetails.normalRange.min}
            stroke={verisColors.neutrals["grey-2"]}
            isFront
            ifOverflow="extendDomain"
            label={(payload) => (
              <ChartNormalValueLabel
                value={sensorDetails.normalRange.min}
                viewBox={payload.viewBox}
              />
            )}
          />
          <ReferenceLine
            y={sensorDetails.normalRange.max}
            stroke={verisColors.neutrals["grey-2"]}
            ifOverflow="extendDomain"
            isFront
            label={(payload) => (
              <ChartNormalValueLabel
                value={sensorDetails.normalRange.max}
                viewBox={payload.viewBox}
              />
            )}
          />
          <XAxis
            dataKey="dt"
            scale="time"
            tickFormatter={dateFormatter}
            type="number"
            domain={[dayjs(startDate).unix(), dayjs(endDate).unix()]}
            allowDataOverflow={false}
            padding={{ left: 10, right: 10 }}
            hide
          />

          {sensorName !== "ecg" && (
            <Tooltip
              content={
                <ChartTooltipWrapper
                  lineValue={sensorValues[referenceLine?.valueIndex]?.dt}
                  measurementUnit={withMeasurementUnit}
                  isVerisChart
                  isPreview={isPreview}
                  isMotionChart={sensorName === "motion"}
                  showValue
                  sensorsGraphView={sensorsGraphView}
                />
              }
              filterNull
            />
          )}

          <Line
            dataKey="v"
            type="monotone"
            stroke={
              filteredSensorValues.every(
                (item) => sensorValues.findIndex((value) => value && value.v === item?.v) === 0,
              )
                ? getColorForStroke(
                    sensorDetails.normalRange.min,
                    sensorDetails.normalRange.max,
                    sensorValues[0] ? sensorValues[0].v : 0,
                  )
                : `url(#${sensorName})`
            }
            fill="none"
            cursor="pointer"
            strokeWidth={1.8}
            dot={
              <CustomDot
                sensorMin={sensorDetails.normalRange.min}
                sensorMax={sensorDetails.normalRange.max}
                payload={{
                  v: 0,
                }}
                data={sensorValues as unknown as Measurement[]}
                index={0}
                sensorName={sensorName}
              />
            }
            isAnimationActive={false}
          />
          <YAxis
            type="number"
            hide
            padding={{ top: 5, bottom: 5 }}
            domain={calculateDomain(
              sensorName,
              sensorDetails.calculatedRange.max,
              sensorDetails.normalRange.max,
              sensorDetails.normalRange.min,
            )}
          />
          <defs>
            <linearGradient id={sensorName} x1="0" y1="0%" x2="0" y2="100%">
              {sensorDetails.abnormalRange.positive > 0 && (
                <>
                  <stop offset={`${0}%`} stopColor={trendingChartColors.Critical.start} />
                  <stop
                    offset={`${Math.abs(sensorDetails.abnormalRange.positive)}%`}
                    stopColor={trendingChartColors.Abnormal.start}
                  />
                </>
              )}
              {!Number.isNaN(sensorDetails.abnormalRange.positive) && (
                <stop
                  offset={`${Math.abs(sensorDetails.abnormalRange.positive)}%`}
                  stopColor={trendingChartColors.Good.start}
                />
              )}
              {!Number.isNaN(sensorDetails.abnormalRange.negative) && (
                <stop
                  offset={`${100 - Math.abs(sensorDetails.abnormalRange.negative)}%`}
                  stopColor={trendingChartColors.Good.start}
                />
              )}
              {sensorDetails.abnormalRange.negative > 0 && (
                <>
                  <stop
                    offset={`${100 - Math.abs(sensorDetails.abnormalRange.negative)}%`}
                    stopColor={trendingChartColors.Abnormal.start}
                  />
                  <stop offset={`${100}%`} stopColor={trendingChartColors.Critical.start} />
                </>
              )}
            </linearGradient>
          </defs>
          {clickedDot && sensorName !== SensorEnum.Ecg && (
            <ReferenceLine
              x={referenceLine?.date}
              stroke={verisColors.neutrals["grey-5"]}
              strokeWidth={1}
              isFront
              strokeDasharray="3 3"
              label={(payload) => (
                <ChartLabel
                  value={clickedDot}
                  dateTime={clickedDot?.dt}
                  measurementUnit={withMeasurementUnit}
                  direction={
                    clickedIndex < sensorValues.length / 2 || clickedIndex === sensorValues.length
                  }
                  isPreview={isPreview}
                  viewBox={payload.viewBox}
                  isMotionChart={sensorName === "motion"}
                  timezone={clickedDot && get(clickedDot, "ldt", undefined)}
                  sensorsGraphView={sensorsGraphView}
                />
              )}
            />
          )}
        </LineChart>
      </ResponsiveContainer>
    </>
  );
};
