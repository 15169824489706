import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Stack, styled, Typography } from "@mui/material";
import { CareTeamNote } from "@veris-health/med-data-ms/lib/v1";
import { convertToRaw, EditorState } from "draft-js";
import { VrsIcon, IconName, verisColors, VrsErrorMsg } from "@veris-health/web-core";
import {
  addCareTeamNoteAsync,
  loadCareTeamNotesAsync,
  selectCareTeamNotes,
  selectCareTeamNotesStatus,
} from "../patientDetailsSlice";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { PatientNoteItem } from "../components/PatientNoteItem";
import { NotePreview } from "../components/NotePreview";
import { AddNewNote } from "../components/AddNote";
import { useCurrentPatient } from "../../../hooks/useCurrentPatient";

const noteMaxLength = 500;

export const PatientCareTeamNotesContainer = (): JSX.Element => {
  const currentPatient = useCurrentPatient();
  const careTeamNotes = useAppSelector((state) =>
    selectCareTeamNotes(state, currentPatient && +currentPatient.id),
  );

  const careTeamNotesStatus = useAppSelector(selectCareTeamNotesStatus);
  const [selectedNote, setSelectedNote] = useState<CareTeamNote>();
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (currentPatient) dispatch(loadCareTeamNotesAsync({ id: currentPatient?.id }));
  }, [dispatch, currentPatient]);

  useEffect(() => {
    if (!selectedNote) {
      setSelectedNote(careTeamNotes && careTeamNotes[0]);
    } else {
      const note = careTeamNotes && careTeamNotes.find(({ id }) => id === selectedNote.id);
      if (note) {
        setSelectedNote(note);
      }
    }
  }, [dispatch, careTeamNotes, selectedNote]);

  useEffect(() => {
    setSelectedNote(careTeamNotes && careTeamNotes[0]);
  }, [careTeamNotes]);

  const onCreateNewNote = (editorState: EditorState, title: string) => {
    if (currentPatient) {
      const raw = convertToRaw(editorState.getCurrentContent());
      dispatch(
        addCareTeamNoteAsync({ id: currentPatient.id, note: { title, note: JSON.stringify(raw) } }),
      );
    }
  };

  return (
    <Box sx={{ display: "flex", overflow: "hidden", height: "70vh" }}>
      {careTeamNotesStatus === "loading" && (
        <Box display="flex" justifyContent="center" alignItems="center" width="100%">
          <CircularProgress />
        </Box>
      )}
      {careTeamNotesStatus === "failed" && (
        <VrsErrorMsg
          size="small"
          onClick={() => {
            if (currentPatient) dispatch(loadCareTeamNotesAsync({ id: currentPatient?.id }));
          }}
        />
      )}
      {careTeamNotesStatus === "idle" && careTeamNotes?.length === 0 && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          marginX="auto"
          marginY="2rem"
          height="70vh"
        >
          <VrsIcon name={IconName.NoNotes} />
          <Typography
            variant="h6"
            color={(theme) => theme.veris.colors.neutrals["grey-3"]}
            mt={5}
            textAlign="center"
          >
            There are no private notes for this patient.
          </Typography>
          <AddNewNote maxLength={noteMaxLength} onSave={onCreateNewNote} />
        </Box>
      )}
      {careTeamNotesStatus === "idle" &&
        careTeamNotes &&
        careTeamNotes?.length > 0 &&
        !!selectedNote && (
          <>
            <Box width="18rem">
              <Stack
                height="58vh"
                sx={{ overflowY: "scroll" }}
                padding={(theme) => theme.spacing(2.75, 2.75, 0, 2.75)}
              >
                {careTeamNotes?.map((note) => (
                  <PatientNoteItem
                    noteInfo={note}
                    key={note.id}
                    open={selectedNote.id === note.id}
                    onClick={() => setSelectedNote(note)}
                  />
                ))}
              </Stack>
              <AddNewNote maxLength={noteMaxLength} onSave={onCreateNewNote} />
            </Box>
            <NotePreview maxLength={noteMaxLength} noteInfo={selectedNote} />
          </>
        )}
    </Box>
  );
};

export const TitleInput = styled("input")(({ theme }) => ({
  ...theme.typography.h2,
  background: "none",
  padding: 0,
  marginBottom: ".75rem",
  borderStyle: "none",
  outline: "none",
  width: "95%",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  color: verisColors.neutrals["grey-5"],
  "::placeholder": {
    color: verisColors.neutrals["grey-2"],
  },
}));
