import { PluginFunctions } from "@draft-js-plugins/editor";
import {
  BlockMapBuilder,
  CharacterMetadata,
  ContentBlock,
  DraftHandleValue,
  EditorState,
  genKey,
  Modifier,
} from "draft-js";
import { List, Repeat } from "immutable";

import importHTML from "./importer";

export function handleRichPastedText(
  text: string,
  html: string,
  editorState: EditorState,
  pluginFunctions: PluginFunctions,
): DraftHandleValue {
  if (html) {
    const htmlContentState = importHTML(html);
    const selection = editorState.getSelection();
    const htmlBlockMap = htmlContentState.getBlockMap();

    const newContent = Modifier.replaceWithFragment(
      editorState.getCurrentContent(),
      selection,
      htmlBlockMap,
    );

    const newEditorState = EditorState.push(editorState, newContent, "insert-fragment");

    pluginFunctions.setEditorState(newEditorState);
    return "handled";
  }
  if (text) {
    const textBlocks = text.split("\n").map(
      (t) =>
        new ContentBlock({
          key: genKey(),
          type: "unstyled",
          characterList: List(Repeat(CharacterMetadata.create(), t.length)),
          text,
        }),
    );

    pluginFunctions.setEditorState(
      EditorState.push(
        editorState,
        Modifier.replaceWithFragment(
          editorState.getCurrentContent(),
          editorState.getSelection(),
          BlockMapBuilder.createFromArray(textBlocks),
        ),
        "insert-fragment",
      ),
    );
    return "handled";
  }

  return "not-handled";
}
