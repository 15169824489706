import React from "react";
import { VrsTabsContainer } from "../../../ui/components/VrsTabsContainer";
import { PatientCareTeamNotesContainer } from "./PatientCareTeamNotesContainer";
import { ClinicalNotes } from "./ClinicalNotes";

export const PatientAllNotesContainer = (): JSX.Element => {
  return (
    <VrsTabsContainer
      withTopMargin
      tabsContainerContent={{
        "Progress Notes": <ClinicalNotes key="notes-progress" />,
        "Clinical Care Team Notes": <PatientCareTeamNotesContainer key="notes-care-team" />,
      }}
      tabValue={0}
    />
  );
};
