import React, { useState, useMemo, useEffect } from "react";
import {
  Box,
  Stack,
  Grid,
  InputAdornment,
  Skeleton,
  Typography,
  styled,
  Badge,
  buttonClasses,
  badgeClasses,
  useMediaQuery,
  Theme,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import { ChatThreadInfo, ThreadParticipantInfo } from "@veris-health/communication-ms/lib/v1";
import {
  VrsIcon,
  IconName,
  VrsButton,
  VrsButtonProps,
  VrsIconButton,
  VrsTooltip,
} from "@veris-health/web-core";
import { StyledTextField, VrsNewChatDialog } from "../components/VrsNewChatDialog";
import { Routes } from "../../../routes-config";
import { VrsThreadPreview } from "../components/VrsThreadPreview";
import { calculateTimeFromNow } from "../../../utils/date";
import {
  ExtendedVrsChatThread,
  resetThreadId,
  selectChatHistoryPatientId,
  selectChatHistoryThreads,
  selectChatHistoryThreadsStatus,
  selectChatThreadsPreviewWithActiveOnTop,
  selectChatThreadsStatus,
  selectCurrentChatHistoryThread,
  selectCurrentThread,
  setThreadId,
  setThreadUnreadStatus,
} from "../store";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { selectPatients, selectPatientsMap } from "../../shared/slices/patientsSlice";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { VrsPatientInfo } from "../../shared/interfaces";
import { useCurrentPatient } from "../../../hooks/useCurrentPatient";
import { useProfile } from "../../../context/profile";
import { removeFirstOccurrenceFromArray } from "../../../utils/helpers";

const ThreadToggle = styled(VrsButton)<VrsButtonProps>(({ theme }) => ({
  width: "100%",
  [`&.${buttonClasses.disabled}`]: {
    color: theme.veris.colors.neutrals.white,
    backgroundColor: theme.veris.colors.amethyst.normal,
  },
}));

const UnreadBadge = styled(Badge)(({ theme }) => ({
  [`& .${badgeClasses.badge}`]: {
    color: theme.veris.colors.neutrals.white,
    background: theme.veris.colors.pink.normal,
    paddingTop: "2px",
    right: "-15px",
    top: "-1px",
  },
}));

const LoadingSkeleton = () => (
  <Box
    sx={{
      paddingY: (theme) => theme.spacing(2),
      paddingX: (theme) => theme.spacing(0.4),
      borderBottom: (theme) => `1px solid ${theme.veris.colors.neutrals["grey-2"]}`,
    }}
    display="flex"
  >
    <Skeleton variant="circular" width={50} height={50} sx={{ marginRight: "0.5rem" }} />
    <Box width="84%">
      <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
      <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
    </Box>
  </Box>
);

export const ChatSidebarContainer = (): JSX.Element => {
  const [toggleNewChatDialog, setToggleNewChatDialog] = useState<boolean>(false);
  const [isPrivateActive, setIsPrivateActive] = useState<boolean>(false);
  const [chatThreadsFilterTerm, setChatThreadsFilterTerm] = useState<string>("");

  const patients = useAppSelector(selectPatients);
  const patientsMap = useAppSelector(selectPatientsMap);
  const myChatThreads = useAppSelector(selectChatThreadsPreviewWithActiveOnTop);
  const patientHistoryId = useAppSelector(selectChatHistoryPatientId);

  const chatHistoryThreads = useAppSelector((state) =>
    // https://github.com/reduxjs/reselect/issues/550
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    selectChatHistoryThreads(state, patientHistoryId),
  );
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("xl"));
  const chatThreads: ExtendedVrsChatThread[] = patientHistoryId
    ? chatHistoryThreads
    : myChatThreads;
  const chatThreadsStatus = useAppSelector(
    patientHistoryId ? selectChatHistoryThreadsStatus : selectChatThreadsStatus,
  );
  const currentThread = useAppSelector((state) =>
    patientHistoryId
      ? // https://github.com/reduxjs/reselect/issues/550
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        selectCurrentChatHistoryThread(state, patientHistoryId)
      : selectCurrentThread(state),
  );
  const selectedPatient = useCurrentPatient();
  const currentUser = useProfile();

  const dispatch = useAppDispatch();
  const history = useHistory();

  const { privateUnreadCount, publicUnreadCount } = useMemo(() => {
    return chatThreads.reduce(
      (accumulator, thread) => {
        if ("hasUnread" in thread && thread.hasUnread) {
          if (thread.private) {
            accumulator.privateUnreadCount += 1;
            return accumulator;
          }
          accumulator.publicUnreadCount += 1;
        }
        return accumulator;
      },
      { privateUnreadCount: 0, publicUnreadCount: 0 },
    );
  }, [chatThreads]);

  const activeThreads = useMemo(() => {
    return chatThreads?.filter((thread) => thread.private === isPrivateActive);
  }, [isPrivateActive, chatThreads]);

  const filteredChatThreads = useMemo(() => {
    const filterTerm = chatThreadsFilterTerm.toLowerCase();
    return activeThreads.filter(({ label }) => {
      const filteredLabelList = removeFirstOccurrenceFromArray(
        label.split(", "),
        currentUser?.fullName || "",
      ).join(", ");
      const labelMatchesFilter =
        filteredLabelList && filteredLabelList.toLowerCase().includes(filterTerm);
      return labelMatchesFilter;
    });
  }, [chatThreadsFilterTerm, patientsMap, activeThreads]);

  const handleThreadPreviewClick = (
    thread: ChatThreadInfo & { patient: ThreadParticipantInfo },
  ) => {
    dispatch(setThreadId({ threadId: thread.id }));
    setChatThreadsFilterTerm("");
    dispatch(setThreadUnreadStatus(thread.id));
    if (thread.private || thread.participants.length < 3) {
      history.push(
        `${Routes.COMMUNICATION}/${thread.for_patient?.user_id || thread.patient.user_id}`,
      );
    } else {
      history.push(`${Routes.COMMUNICATION}/${thread.id}`);
    }
  };

  const handleChatCreation = (patient: VrsPatientInfo): void => {
    setToggleNewChatDialog(false);
    if (patient.id !== selectedPatient?.id) {
      dispatch(resetThreadId());
      history.push(`${Routes.COMMUNICATION}/${patient.id.toString()}`);
    }
  };

  useEffect(() => {
    if (currentThread?.id) {
      setIsPrivateActive(currentThread.private);
    }
  }, [currentThread]);

  useEffect(() => {
    return () => setChatThreadsFilterTerm("");
  }, []);

  return (
    <Grid
      item
      lg={4}
      xl={3}
      p={1}
      xs={4}
      sx={{
        borderLeft: (theme) => `2px solid ${theme.veris.colors.neutrals["grey-2"]}`,
        height: "90vh",
        overflow: "hidden",
      }}
      data-test-hook="sidebar-chat-container"
    >
      <Grid container spacing={0.25} alignItems="center">
        <Grid item xs={9} lg={9} xl={10}>
          <StyledTextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <VrsIcon name={IconName.Search} />
                </InputAdornment>
              ),
            }}
            type="text"
            variant="outlined"
            fullWidth
            placeholder="Search by name"
            onChange={(e) => setChatThreadsFilterTerm(e.target.value)}
            value={chatThreadsFilterTerm}
            autoFocus
          />
        </Grid>
        {!patientHistoryId && (
          <Grid
            display="flex"
            alignItems="center"
            justifyContent="center"
            item
            xs={3}
            lg={3}
            xl={2}
          >
            <VrsTooltip title="New Chat" bcgcolor="black" placement="top">
              <Box>
                <VrsIconButton
                  iconProps={{ name: IconName.Plus }}
                  onClick={() => setToggleNewChatDialog(true)}
                  sx={{
                    border: (theme) => `1px solid ${theme.veris.colors.neutrals["grey-2"]}`,
                  }}
                />
              </Box>
            </VrsTooltip>
          </Grid>
        )}

        {toggleNewChatDialog && (
          <VrsNewChatDialog
            isOpen={toggleNewChatDialog}
            patients={patients}
            handleChatCreation={handleChatCreation}
            closeNewChatDialog={() => setToggleNewChatDialog(false)}
          />
        )}
      </Grid>
      {!patientHistoryId && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: (theme) => theme.spacing(2),
            mt: (theme) => theme.spacing(3),
            mb: (theme) => theme.spacing(1),
          }}
        >
          <ThreadToggle
            disabled={!isPrivateActive}
            buttonType="quaternary"
            onClick={() => setIsPrivateActive(false)}
          >
            Patient Chat
            <UnreadBadge badgeContent={publicUnreadCount} showZero={false} />
          </ThreadToggle>

          <ThreadToggle
            disabled={isPrivateActive}
            buttonType="quaternary"
            onClick={() => setIsPrivateActive(true)}
          >
            Private Chat
            <UnreadBadge badgeContent={privateUnreadCount} showZero={false} />
          </ThreadToggle>
        </Box>
      )}
      <Box
        display="flex"
        flexDirection="column"
        sx={{
          overflowY: "auto",
          maxHeight: isSmallScreen ? "85%" : "89%",
          backgroundColor: (theme) => theme.veris.colors.neutrals.white,
        }}
      >
        {chatThreadsStatus === "loading" && filteredChatThreads?.length === 0 && (
          <Stack
            sx={{
              paddingTop: (theme) => theme.spacing(1),
            }}
          >
            <LoadingSkeleton />
            <LoadingSkeleton />
            <LoadingSkeleton />
            <LoadingSkeleton />
          </Stack>
        )}
        {chatThreadsStatus === "idle" && filteredChatThreads?.length === 0 && (
          <Typography
            pt={2}
            color={(theme) => theme.veris.colors.neutrals["grey-3"]}
            textAlign="center"
            variant="body"
          >
            No matches found
          </Typography>
        )}

        {filteredChatThreads?.map((thread) => (
          <Box
            sx={{
              paddingY: (theme) => theme.spacing(1),
              borderBottom: (theme) => `1px solid ${theme.veris.colors.neutrals["grey-2"]}`,
            }}
            key={thread.id}
          >
            <VrsThreadPreview
              isGroupChat={thread.participants.length > 2 && !thread.private}
              groupChatParticipants={thread.participants}
              key={thread.id}
              participantInfo={patientsMap[thread.for_patient?.user_id || thread.patient.user_id]}
              threadPatient={thread.for_patient || thread.patient}
              msgTime={
                thread?.lastMessageReceivedOn && calculateTimeFromNow(thread.lastMessageReceivedOn)
              }
              hasUnread={thread.hasUnread}
              isActive={currentThread?.id === thread.id}
              handleClick={() => handleThreadPreviewClick(thread)}
              doctorThread={!!patientHistoryId}
            />
          </Box>
        ))}
      </Box>
    </Grid>
  );
};
