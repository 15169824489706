import React from "react";
import { Box, Typography } from "@mui/material";
import { IconName, VrsDialog, VrsIconButton } from "@veris-health/web-core";
import {
  ClinicalTask,
  ClinicalTaskDataAbnormalReadingTypeEnum,
  ClinicalTaskDataSymptomReportTypeEnum,
  ClinicalTaskStatusEnum,
} from "@veris-health/med-data-ms/lib/v1";
import { DataSourceModel } from "@veris-health/user-ms/lib/v2";
import { VrsDrawer } from "../../../ui/components/VrsDrawer";
import CreateTask from "./CreateTask";
import { VrsMedStaffProfileModel } from "../../shared/interfaces";

export type NotificationTaskData = {
  type:
    | ClinicalTaskDataSymptomReportTypeEnum.Symptom
    | ClinicalTaskDataAbnormalReadingTypeEnum.AbnormalReading;
  patient?: {
    name: string;
    id: number;
    image?: string;
    hospital?: number | number[];
    dataSources?: DataSourceModel[];
  };
  subType?: string;
  description?: string;
};

interface CreateTaskContainerProps {
  onClose: () => void;
  fetchTasks?: () => void;
  open: boolean;
  task?: ClinicalTask;
  currentUser: VrsMedStaffProfileModel;
  modalView?: boolean;
  currentPatientId?: number;
  notificationTask?: NotificationTaskData;
}

const TaskContainer = ({
  open,
  onClose,
  task,
  currentUser,
  modalView,
  currentPatientId,
  notificationTask,
  fetchTasks,
}: CreateTaskContainerProps): JSX.Element => {
  return (
    <>
      {modalView ? (
        <VrsDialog open={open} onClose={onClose} title="Create a new task" width="700px">
          <CreateTask
            onClose={onClose}
            task={task}
            currentUser={currentUser}
            currentPatientId={currentPatientId}
            notificationTask={notificationTask}
          />
        </VrsDialog>
      ) : (
        <VrsDrawer onClose={onClose} open={open} width={600}>
          <Box display="flex" alignItems="center" justifyContent="space-between" py={2}>
            <Typography variant="h3" sx={{ wordBreak: "break-word", whiteSpace: "break-spaces" }}>
              {task ? task.title : "Create a new task"}
            </Typography>
            <VrsIconButton iconProps={{ name: IconName.CloseIcon }} onClick={onClose} />
          </Box>
          <CreateTask
            onClose={onClose}
            task={task}
            currentUser={currentUser}
            currentPatientId={currentPatientId}
            viewMode={
              task?.status === ClinicalTaskStatusEnum.Resolved ||
              (task &&
                !task?.shared_team.map((el) => el.user_id).includes(currentUser.id) &&
                task?.assignee.user_id !== currentUser.id &&
                task?.reporter.user_id !== currentUser.id)
            }
            fetchTasks={fetchTasks}
          />
        </VrsDrawer>
      )}
    </>
  );
};

export default TaskContainer;
