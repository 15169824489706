import {
  Box,
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  Radio,
  RadioGroup,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import { IconName, Option, VrsIcon, VrsIconButton, VrsInput } from "@veris-health/web-core";
import React, { useEffect, useState } from "react";

interface VrsRadioGroupProps {
  onChange: (val: string) => void;
  name: string;
  options: Option[];
  value?: string;
  disabled?: boolean;
  predefined?: string;
  onInputChange?: (val: string) => void;
  isInputDisabled?: boolean;
  autoFocus?: boolean;
}
const StyledFormControlLabel = styled(FormControlLabel)<FormControlLabelProps>(
  ({ theme, checked }) => ({
    border: checked
      ? `1px solid ${theme.veris.colors.amethyst.normal}`
      : `1px solid ${theme.veris.colors.neutrals["grey-2"]}`,
    backgroundColor: checked ? theme.veris.colors.amethyst.light : "unset",
    borderRadius: theme.spacing(1),
    padding: theme.spacing(0, 2, 0, 0),
    "&:hover, &:focus, &:active": {
      border: `1px solid ${theme.veris.colors.amethyst.normal}`,
      backgroundColor: theme.veris.colors.amethyst.light,
      transition: ".3s",
    },
    margin: theme.spacing(0.5, 0),
  }),
);

const VrsRadioGroup = ({
  value,
  onChange,
  name,
  options,
  disabled,
  predefined,
  onInputChange,
  isInputDisabled,
  autoFocus = false,
}: VrsRadioGroupProps): JSX.Element => {
  const [createdValue, setCreatedValue] = useState(predefined);
  const theme = useTheme();

  useEffect(() => {
    setCreatedValue(predefined);
  }, [predefined]);

  const getCheckedCondition = (option: Option) => {
    if (createdValue !== undefined && option.label === "Other") {
      return true;
    }
    return value?.toLowerCase() === option.value.toLowerCase();
  };

  return (
    <FormControl fullWidth disabled={disabled}>
      <RadioGroup
        name={name}
        value={value}
        onChange={(_, val) => {
          setCreatedValue(undefined);
          onChange(val);
        }}
        sx={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}
      >
        {options.map((option) => {
          return (
            <StyledFormControlLabel
              key={option.value}
              value={option.value}
              control={<Radio checked={getCheckedCondition(option)} />}
              label={
                <Box display="flex" alignItems="center">
                  {option.icon && <VrsIcon name={option.icon} stroke={option.color ?? "default"} />}
                  <Typography
                    variant="body"
                    mx={1}
                    color={option.color ?? theme.veris.colors.neutrals["grey-4"]}
                  >
                    {option.label}
                  </Typography>
                  {option.label === "Other" && (
                    <Box>
                      <VrsInput
                        name="creatable"
                        height="30px"
                        variant="standard"
                        typography="body1"
                        maxInputLength={200}
                        disabled={isInputDisabled}
                        onChange={(e) => setCreatedValue(e.target.value)}
                        value={createdValue || ""}
                        padding={{ top: 0, left: 0, right: 0, bottom: 0 }}
                        autoFocus={autoFocus}
                      />
                      {!isInputDisabled && (
                        <VrsIconButton
                          iconProps={{ name: IconName.Check }}
                          onClick={() => {
                            if (createdValue && option.label === "Other" && onInputChange)
                              onInputChange(createdValue);
                          }}
                          disabled={isInputDisabled}
                        />
                      )}
                    </Box>
                  )}
                </Box>
              }
              checked={getCheckedCondition(option)}
            />
          );
        })}{" "}
      </RadioGroup>
    </FormControl>
  );
};

export default VrsRadioGroup;
