import React, { useEffect, useMemo, useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import {
  CallAgentProvider,
  CallClientProvider,
  DEFAULT_COMPONENT_ICONS,
  FluentThemeProvider,
} from "@azure/communication-react";
import { initializeIcons, registerIcons } from "@fluentui/react";
import { AzureCommunicationTokenCredential } from "@azure/communication-common";
import { JoinCallOptions } from "@azure/communication-calling";
import { VrsErrorMsg } from "@veris-health/web-core";
import { useVideo } from "../../../../context/video";
import { VideoCallSettingsComponent } from "../VideoCallSettingsComponent";
import { VrsPatientInfo } from "../../../shared/interfaces";
import { VideoLobby } from "../VideoLobby";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { selectChatUserAccessToken, selectMyChatDisplayName } from "../../voipSlice";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import { startCall } from "../../../shared/slices/voipSlice";

initializeIcons();
registerIcons({ icons: DEFAULT_COMPONENT_ICONS });

export interface VideoCallSettingsComponentProps {
  isGroupCall: boolean;
  isOnLobby: boolean;
  currentPatient: VrsPatientInfo;
}

export function VideoCallProvider({
  isGroupCall,
  isOnLobby,
  currentPatient,
}: VideoCallSettingsComponentProps): JSX.Element | null {
  const dispatch = useAppDispatch();
  const videoProps = useVideo();
  const userAccessToken = useAppSelector(selectChatUserAccessToken);
  const displayName = useAppSelector(selectMyChatDisplayName);
  const [startCallOptions, setStartCallOptions] = useState<JoinCallOptions>();

  const tokenCredential = useMemo(() => {
    return new AzureCommunicationTokenCredential(userAccessToken);
  }, [userAccessToken]);

  useEffect(() => {
    videoProps.createCallAgent(tokenCredential, displayName);
  }, []);

  if (videoProps.videoClientError) {
    if (videoProps.videoClientError.message === "BrowserNotSupported")
      return (
        <VrsErrorMsg
          size="small"
          title="Your browser does not support this feature. Please use latest version of Chrome or Safari."
        />
      );
    return <VrsErrorMsg size="small" />;
  }

  if (!videoProps.statefulCallClient || !videoProps.callAgent) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" sx={{ mt: 2 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <FluentThemeProvider>
      <CallClientProvider callClient={videoProps.statefulCallClient}>
        <CallAgentProvider callAgent={videoProps.callAgent}>
          {isOnLobby ? (
            <VideoLobby
              startCall={(callOptions) => {
                setStartCallOptions({
                  videoOptions: {
                    localVideoStreams: callOptions.video ? [callOptions.video] : undefined,
                  },
                  audioOptions: { muted: callOptions.audio },
                });
                dispatch(startCall());
              }}
            />
          ) : (
            <VideoCallSettingsComponent
              isGroupCall={isGroupCall}
              currentPatient={currentPatient}
              startCallOptions={startCallOptions}
            />
          )}
        </CallAgentProvider>
      </CallClientProvider>
    </FluentThemeProvider>
  );
}
