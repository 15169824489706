import {
  QuestionnaireResponse,
  QuestionnaireView,
  QuestionnaireViewLabel,
  ReportItem,
} from "@veris-health/virtual-doc-ms/lib/v1";
import dayjs from "dayjs";
import { symptomsApiV1 } from "../../../../../api";

export interface VrsReportedSymptom {
  [key: string]: QuestionnaireViewLabel[];
}

export const fetchAllSymptoms = async (): Promise<VrsReportedSymptom> => {
  const response = await symptomsApiV1.getSymptoms();
  return response.data;
};

export const fetchSymptomQuestionnare = async (symptomName: string): Promise<QuestionnaireView> => {
  const response = await symptomsApiV1.getQuestionnaire(symptomName);
  return response.data;
};

export const submitSymptomQuestionnare = async (
  userId: number,
  data: QuestionnaireResponse,
): Promise<string[]> => {
  const timezone = dayjs().format("ZZ");
  const response = await symptomsApiV1.putQuestionnaireResponse(userId, data, undefined, timezone);
  return response.data;
};

export const getReports = async (userId: number): Promise<ReportItem[]> => {
  const response = await symptomsApiV1.getReports(userId);
  return response.data;
};
