import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { ContentState, convertFromRaw, EditorState } from "draft-js";
import { VrsAvatar, dateFormats } from "@veris-health/web-core";
import { PatientNoteItemProps } from "../NotePreview";
import { linkDecorator } from "../../../../ui/components/Editor/Link/Link";
import { Editor } from "../../../../ui/components/Editor";

export function PatientNoteItem({ noteInfo, open, onClick }: PatientNoteItemProps): JSX.Element {
  const {
    time_created: timeCreated,
    title,
    note,
    time_updated: timeUpdated,
    full_name: fullName,
    profession,
    picture,
  } = noteInfo;

  const [editorState, setEditorState] = useState<EditorState | null>(null);

  useEffect(() => {
    try {
      const parsed = JSON.parse(note);
      setEditorState(EditorState.createWithContent(convertFromRaw(parsed), linkDecorator));
    } catch (e) {
      setEditorState(
        EditorState.createWithContent(ContentState.createFromText(note), linkDecorator),
      );
    }
  }, [noteInfo]);
  return (
    <Box
      sx={{
        flexGrow: 0,
        display: "flex",
        border: (theme) =>
          open
            ? `2px solid ${theme.veris.colors.amethyst.normal}`
            : `1px solid ${theme.veris.colors.neutrals["grey-2"]}`,
        borderRadius: "8px",
        flexDirection: "column",
        background: open ? "rgba(231, 232, 253, 0.5)" : "inherit",
        cursor: "pointer",
      }}
      p={2.3}
      mb={2.3}
      onClick={() => onClick && onClick()}
    >
      <Box mb={1}>
        <Typography variant="body" color={(theme) => theme.veris.colors.neutrals["grey-3"]}>
          {timeUpdated
            ? dayjs(timeUpdated).format(dateFormats["MMM DD, YYYY"])
            : dayjs(timeCreated).format(dateFormats["MMM DD, YYYY"])}
        </Typography>
      </Box>
      <Box mb={1}>
        {title ? (
          <Typography
            variant="h6"
            sx={{
              fontWeight: 600,
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {title}
          </Typography>
        ) : (
          <Typography
            variant="h6"
            sx={{ fontWeight: 600 }}
            color={(theme) => theme.veris.colors.neutrals["grey-3"]}
          >
            Untitled
          </Typography>
        )}
      </Box>
      <Box mb={1} style={{ maxHeight: "45px", overflow: "hidden" }}>
        {editorState && <Editor editorState={editorState} backgroundColor="transparent" readOnly />}
      </Box>
      <Box gridColumn="span 2">
        <Typography variant="caption" color={(theme) => theme.veris.colors.neutrals["grey-mid"]}>
          Added by
        </Typography>
      </Box>
      {fullName && (
        <Box
          p={0.8}
          display="flex"
          alignItems="center"
          sx={{
            borderRadius: (theme) => theme.shape.borderRadius,
            border: (theme) => `1px solid ${theme.veris.colors.neutrals["grey-2"]}`,
          }}
        >
          <VrsAvatar imageUrl={picture} size={25} name={fullName} />
          <Typography
            variant="caption"
            style={{ textTransform: "capitalize", marginLeft: "0.5rem" }}
          >
            {fullName}
            {profession && ` • ${profession.replaceAll("_", " ")}`}
          </Typography>
        </Box>
      )}
    </Box>
  );
}
