import React, { useEffect, useState } from "react";
import {
  Stack,
  Typography,
  Box,
  styled,
  Tooltip as MuiTooltip,
  tooltipClasses,
  TooltipProps as MuiTooltipProps,
} from "@mui/material";
import { verisColors, dateFormats } from "@veris-health/web-core";
import { ReportStatusEnum } from "@veris-health/virtual-doc-ms/lib/v1";
import { ExpandedSymptomEvent, SymptomStatusEnum } from "../../measurementSlice";
import { symptomSeverityColor } from "../../../../utils/symptoms";
import { ChartTooltip } from "../shared/tooltips";
import { utcToLocal } from "../../../../utils/date";

export interface SymptomProps {
  symptom: ExpandedSymptomEvent;
  isPreview: boolean;
}
const noUpdateStatus: SymptomStatusEnum[] = ["no_update", "incomplete_no_update"];
const incompleteOrDeletedStatus: SymptomStatusEnum[] = [
  ReportStatusEnum.Incomplete,
  ReportStatusEnum.Deleted,
];
const finalSymptomStatus: SymptomStatusEnum[] = [
  ReportStatusEnum.Incomplete,
  ReportStatusEnum.Deleted,
  ReportStatusEnum.Resolved,
];

const renderSymptomContent = (symptom: ExpandedSymptomEvent, isPreview: boolean) => {
  if (symptom?.calculatedStatus === "empty")
    return (
      <Typography component="span" variant="description" pt={isPreview ? 0 : 1}>
        Symptom not reported
      </Typography>
    );
  if (noUpdateStatus.includes(symptom?.calculatedStatus))
    return (
      <Typography component="span" variant="description" pt={isPreview ? 0 : 1}>
        No updates
        <br />
        {symptom?.calculatedStatus === "incomplete_no_update" && "Incomplete symptom"}
      </Typography>
    );
  return (
    <>
      {symptom?.calculatedStatus === "resolved" && (
        <Stack direction="column" justifyContent="space-between" pb={isPreview ? 0 : 1}>
          <Typography component="span" variant="description" fontWeight={600}>
            Resolved
          </Typography>
        </Stack>
      )}
      <Stack direction="column" justifyContent="space-between">
        {incompleteOrDeletedStatus.includes(symptom?.calculatedStatus) ? (
          <Typography
            component="span"
            variant="description"
            color={verisColors.neutrals["grey-mid"]}
          >
            Symptom {symptom?.calculatedStatus === "incomplete" ? "added" : "deleted"} on
          </Typography>
        ) : (
          <Typography
            component="span"
            variant="description"
            color={verisColors.neutrals["grey-mid"]}
          >
            {symptom?.calculatedStatus !== "resolved" && "Active"} Symptom Since
          </Typography>
        )}
        <Typography component="span" variant="description">
          {utcToLocal(symptom?.active_since_date).format(dateFormats["MMM D, YYYY"])}
        </Typography>
      </Stack>
      {!finalSymptomStatus.includes(symptom?.calculatedStatus) && (
        <Stack direction="column" justifyContent="space-between" pt={isPreview ? 0 : 0.5}>
          {isPreview ? (
            <li style={{ paddingRight: 0 }}>
              <Typography
                component="span"
                variant="description"
                style={{ textTransform: "capitalize" }}
              >
                {symptom?.severity} ({symptomSeverityColor[symptom?.severity ?? "mild"]?.fillValue})
              </Typography>
            </li>
          ) : (
            <Typography
              component="span"
              variant="description"
              style={{ textTransform: "capitalize" }}
              pt={1}
            >
              {symptom?.severity} ({symptomSeverityColor[symptom?.severity ?? "mild"]?.fillValue})
            </Typography>
          )}
        </Stack>
      )}
    </>
  );
};

const CustomSymptomTooltip = ({ symptom, isPreview }: SymptomProps): JSX.Element => {
  return (
    <ChartTooltip className="tooltip">
      <Box p={0.125}> {renderSymptomContent(symptom, isPreview)}</Box>
    </ChartTooltip>
  );
};

export default CustomSymptomTooltip;

export interface VrsTooltipProps extends MuiTooltipProps {
  bcgcolor: string;
  padding?: string;
  timestamp?: string;
  clickedSymptomTimestamp?: string;
}

const StyledTooltip = styled(({ children, className, ...props }: VrsTooltipProps) => (
  <MuiTooltip {...props} classes={{ popper: className }} arrow>
    {children}
  </MuiTooltip>
))(({ theme, bcgcolor, padding }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: bcgcolor || theme.veris.colors.neutrals.black,
    padding,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: bcgcolor || theme.veris.colors.neutrals.black,
  },
}));

export function SymptomsTooltip({
  children,
  bcgcolor,
  padding,
  timestamp,
  clickedSymptomTimestamp,
  ...rest
}: VrsTooltipProps): JSX.Element {
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if (clickedSymptomTimestamp === timestamp) setOpen(false);
  }, [clickedSymptomTimestamp]);

  return (
    <StyledTooltip
      bcgcolor={bcgcolor}
      {...rest}
      padding={padding}
      disableFocusListener
      disableHoverListener
      disableInteractive
      disableTouchListener
      open={open}
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
      onClick={() => setOpen(false)}
      onTransitionEnd={() => setOpen(false)}
    >
      {children}
    </StyledTooltip>
  );
}
