import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import Registration from "./Registration";
import { Routes } from "../../routes-config";
import LoginContainer from "./LoginContainer";
import { ForgotPasswordContainer } from "./ForgotPasswordContainer";
import { ExpiredPasswordContainer } from "./ExpiredPasswordContainer";
import packageJson from "../../../package.json";
import { AuthShell } from "./AuthShell";

const renderRoute = (pathname: string) => {
  switch (pathname) {
    case Routes.STAFF_REGISTRATION:
      return <Registration />;
    case Routes.STAFF_LOGIN:
      return <LoginContainer />;
    case Routes.FORGOT_PASSWORD:
      return <ForgotPasswordContainer />;
    case Routes.PASSWORD_EXPIRATION:
      return <ExpiredPasswordContainer />;
    default:
      return <LoginContainer />;
  }
};

const LoginRegistrationContainer = (): JSX.Element => {
  const location = useLocation();
  return (
    <AuthShell>
      <Grid container style={{ margin: "0 auto" }} display="block" xs={10} item>
        {renderRoute(location.pathname)}
        <Grid item display="block" xs={10}>
          <Version />
        </Grid>
      </Grid>
    </AuthShell>
  );
};

function Version() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
      py={2}
    >
      <Typography variant="caption" color={(theme) => theme.veris.colors.neutrals["grey-3"]}>
        Last Version {packageJson.version}
      </Typography>
      <Typography variant="caption" color={(theme) => theme.veris.colors.neutrals["grey-3"]}>
        UDI-DI: {import.meta.env.VITE_UDI_DI}
      </Typography>
    </Box>
  );
}

export default LoginRegistrationContainer;
