import { createSelector } from "@reduxjs/toolkit";
import {
  ChatThreadInfo,
  SupportedAttachmentTypesEnum as SupportedAttachmentTypes,
} from "@veris-health/communication-ms/lib/v1";
import { orderBy } from "lodash";
import { RootState } from "../../../store";
import { Status } from "../../shared/interfaces";
import { selectUserId } from "../../shared/slices/authSlice";
import {
  AvailableAttachmentFiltering,
  AzureChatThreadItem,
  ChatHistoryThreadMessages,
  NormalizedAttachments,
  NormalizedChatMessages,
  VrChatHistoryAttachment,
  VrsTypingIndicatorReceived,
} from "./interfaces";
import {
  attachmentsAdapter,
  chatHistoryThreadsAdapter,
  messagesAdapter,
} from "./communicationSlice";

export const selectChatApiEndpoint = ({ communication }: RootState): string =>
  communication.chatApiEndpoint;

export const selectChatUserAccessToken = ({ communication }: RootState): string =>
  communication.chatUserAccessToken;

export const selectMyAzureChatUserId = ({ communication }: RootState): string =>
  communication.chatUserId;

export const selectMyChatDisplayName = ({ communication }: RootState): string =>
  communication.chatDisplayName;

export const selectAzureChatThreads = ({ communication }: RootState): AzureChatThreadItem[] =>
  communication.azureChatThreads;

export const selectAzureChatThreadsMap = createSelector(
  [selectAzureChatThreads],
  (azureChatThreads) =>
    azureChatThreads.reduce(
      (acc, thread) => ({
        ...acc,
        [thread.id]: { ...thread },
      }),
      {} as { [id: string]: AzureChatThreadItem },
    ),
);

export const selectChatThreads = ({ communication }: RootState): ChatThreadInfo[] =>
  communication.chatThreads.threads;

export const selectChatThreadsStatus = ({ communication }: RootState): Status =>
  communication.chatThreads.status;

export const selectCurrentThreadId = ({ communication }: RootState): string | undefined =>
  communication.currentThreadId;

export const selectMessages = (rootState: RootState, threadId?: string): NormalizedChatMessages => {
  if (threadId) {
    return (
      messagesAdapter
        .getSelectors<RootState>((state) => state.communication.chatMessages.items)
        .selectById(rootState, threadId) || { messages: [], isLastPage: true, id: threadId }
    );
  }
  return {
    messages: [],
    isLastPage: true,
    id: "",
  };
};

export const selectNewMessageNotification = ({ communication }: RootState): { threadId?: string } =>
  communication.newMessageNotification;

export const selectStatus = ({ communication }: RootState): Status => communication.status;

export const selectMessagesStatus = ({ communication }: RootState): Status =>
  communication.chatMessages.status;

export const selectThreadAttachments = (
  rootState: RootState,
  threadId?: string,
): NormalizedAttachments | undefined => {
  if (threadId) {
    return attachmentsAdapter
      .getSelectors<RootState>((state) => state.communication.attachments.items)
      .selectById(rootState, threadId);
  }
  return undefined;
};

export const selectChatThreadsWithSingleParticipantId = createSelector(
  [selectChatThreads, selectUserId],
  (threads, currentLoggedInUserId) => {
    if (currentLoggedInUserId) {
      const filteredThreads = threads.map((thread) => {
        const index = thread.participants.findIndex(
          (participant) => participant.user_id !== +currentLoggedInUserId,
        );
        return { ...thread, patient: thread.participants[index] };
      });

      return filteredThreads;
    }
    return [];
  },
);

export const selectUnreadInfo = ({ communication }: RootState): Record<string, boolean> =>
  communication.chatThreads.unreadThreadStatus;

export const selectChatHistoryThreadsPreview = (
  rootState: RootState,
  id: number | undefined,
): ChatThreadInfo[] | [] => {
  if (id) {
    return (
      chatHistoryThreadsAdapter
        .getSelectors<RootState>((state) => state.communication.chatHistoryThreads.threads)
        .selectById(rootState, id)?.threads || []
    );
  }
  return [];
};

export const selectChatThreadsPreview = createSelector(
  [selectChatThreadsWithSingleParticipantId, selectAzureChatThreadsMap, selectUnreadInfo],
  (threads, azureThreadMap, unreadInfo) =>
    threads.map((thread) => ({
      ...thread,
      topic: azureThreadMap[thread.id] && azureThreadMap[thread.id].topic,
      hasUnread: unreadInfo[thread.id],
      lastMessageReceivedOn:
        azureThreadMap[thread.id] && azureThreadMap[thread.id].lastMessageReceivedOn,
    })),
);

export const selectChatHistoryThreads = createSelector(
  [selectChatHistoryThreadsPreview, selectCurrentThreadId],
  (threads, threadId) => {
    const mappedThreads = threads.map((thread) => {
      const index = thread.participants.findIndex(
        (participant) => participant.user_type === "patient",
      );
      return {
        ...thread,
        lastMessageReceivedOn: thread.last_message_received_on,
        patient: thread.participants[index],
        for_patient: thread.participants[index],
        hasUnread: false,
        topic: "",
      };
    });

    const index = mappedThreads.findIndex(({ id }) => id === threadId);

    if (!threadId || index === -1) {
      return [...mappedThreads];
    }
    return [
      mappedThreads[index],
      ...mappedThreads.slice(0, index),
      ...mappedThreads.slice(index + 1, mappedThreads.length),
    ];
  },
);

export const selectChatHistoryThreadsStatus = ({ communication }: RootState): Status =>
  communication.chatHistoryThreads.status;

export const selectChatThreadsPreviewWithActiveOnTop = createSelector(
  [selectCurrentThreadId, selectChatThreadsPreview],
  (currentThreadId, threads) => {
    const sortedThreads = orderBy(threads, (thread) => thread.lastMessageReceivedOn || "", "desc");
    const index = sortedThreads.findIndex(({ id }) => id === currentThreadId);
    if (!currentThreadId || index === -1) {
      return [...sortedThreads];
    }
    return [
      sortedThreads[index],
      ...sortedThreads.slice(0, index),
      ...sortedThreads.slice(index + 1, sortedThreads.length),
    ];
  },
);

export const selectDocTypeAttachments = createSelector(
  [(state: RootState, threadId: string) => selectThreadAttachments(state, threadId)],
  (data) => {
    if (data && data.attachments) {
      return data.attachments.filter(
        (attachment) =>
          attachment?.file_extension === SupportedAttachmentTypes.Pdf ||
          attachment?.file_extension === SupportedAttachmentTypes.Doc ||
          attachment?.file_extension === SupportedAttachmentTypes.Docx ||
          attachment?.file_extension === SupportedAttachmentTypes.Xls ||
          attachment?.file_extension === SupportedAttachmentTypes.Xlsx ||
          attachment?.file_extension === SupportedAttachmentTypes.Rtf ||
          attachment?.file_extension === SupportedAttachmentTypes.Ppt ||
          attachment?.file_extension === SupportedAttachmentTypes.Pptx ||
          attachment?.file_extension === SupportedAttachmentTypes.Odf,
      );
    }
    return [];
  },
);

export const selectImageTypeAttachments = createSelector(
  [(state: RootState, threadId: string) => selectThreadAttachments(state, threadId)],
  (data) => {
    if (data) {
      return data.attachments?.filter(
        (attachment) =>
          attachment?.file_extension === SupportedAttachmentTypes.Jpg ||
          attachment?.file_extension === SupportedAttachmentTypes.Svg ||
          attachment?.file_extension === SupportedAttachmentTypes.Png ||
          attachment?.file_extension === SupportedAttachmentTypes.Jpeg,
      );
    }
    return [];
  },
);

export const selectAttachmentFilteringType = ({
  communication,
}: RootState): AvailableAttachmentFiltering => communication.attachments.attachmentFilteringType;

export const selectFilteredThreadAttachments = createSelector(
  [
    (state: RootState, threadId: string) => selectThreadAttachments(state, threadId),
    (state: RootState, threadId: string) => selectImageTypeAttachments(state, threadId),
    (state: RootState, threadId: string) => selectDocTypeAttachments(state, threadId),
    selectAttachmentFilteringType,
  ],
  (data, imgAttachments, docTypeAttachments, filterType) => {
    switch (filterType) {
      case "all": {
        return data?.attachments?.slice();
      }

      case "doc": {
        return docTypeAttachments.slice();
      }

      case "img": {
        return imgAttachments.slice();
      }
      default: {
        return data?.attachments?.slice();
      }
    }
  },
);

export const selectAttachmentsStatus = ({ communication }: RootState): Status =>
  communication.attachments.attachmentsStatus;

export const selectChatHistoryAttachments = (
  rootState: RootState,
): {
  status: Status;
  attachments: VrChatHistoryAttachment[];
} => rootState.communication.chatHistoryAttachments;

export const selectTypingIndicator = ({
  communication,
}: RootState): VrsTypingIndicatorReceived | undefined => communication.typingIndicator;

export const selectCurrentThread = createSelector(
  [selectChatThreads, selectCurrentThreadId],
  (threads, currentThreadId) => {
    if (!currentThreadId) return undefined;
    return threads.find((thread) => thread.id === currentThreadId);
  },
);

export const selectCurrentChatHistoryThread = createSelector(
  [selectChatHistoryThreadsPreview, selectCurrentThreadId],
  (threads, currentThreadId) => {
    if (!currentThreadId) return undefined;
    return threads.find((thread) => thread.id === currentThreadId);
  },
);

export const selectHasUnreadMessages = createSelector([selectUnreadInfo], (threadMap) => {
  return Object.keys(threadMap).some((key) => threadMap[key]);
});

export const selectCurrentChatThreadStatus = ({ communication }: RootState): Status =>
  communication.currentChatThreadStatus;

export const selectChatHistoryPatientId = ({ communication }: RootState): number | undefined =>
  communication.chatHistoryPatientId;

export const selectChatHistoryMessagesStatus = ({ communication }: RootState): Status =>
  communication.chatHistoryMessages.status;

export const selectChatHistoryThreadMessages = (
  rootState: RootState,
  threadId: string,
): ChatHistoryThreadMessages => {
  return (
    rootState.communication.chatHistoryMessages.items[threadId] || {
      messages: [],
      isLastPage: false,
    }
  );
};

export const selectSingleThreadStatus = ({ communication }: RootState): Status =>
  communication.singleThreadStatus;
