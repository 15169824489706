import {
  Medication,
  MedicationAdherence,
  MedicationStatusEnum,
} from "@veris-health/med-data-ms/lib/v1";
import dayjs from "dayjs";
import { GetMedicationsResponse } from "@veris-health/med-data-ms/lib/v1/api";
import { medicationAdherenceApiV1, medicationsApiV1 } from "../../../../api";
import { ResourceEnum } from "../../../TreatmentPlan/components/timelineConstants";
import { stripHtml } from "../../../../api/stripHtml";

const getStartDateForMedication = (medication: Medication, start?: string) => {
  if (medication.datePeriod?.start) return dayjs.utc(medication.datePeriod?.start).local().format();
  if (medication.dateTime) return dayjs.utc(medication.dateTime).startOf("hour").local().format();
  if (medication.datePeriod?.end && start) return dayjs.utc(start).local().format();
};

const getEndDateForMedication = (medication: Medication, end?: string) => {
  if (medication.datePeriod?.end) {
    if (
      medication.datePeriod.start &&
      dayjs(medication.datePeriod.end).diff(dayjs(medication.datePeriod.start), "hours") <= 1
    ) {
      return dayjs(medication.datePeriod.start).add(1, "hour").local().format();
    }
    return dayjs.utc(medication.datePeriod?.end).local().format();
  }
  if (medication.datePeriod?.start && end) return dayjs.utc(end).local().format();
  if (medication.dateTime)
    return dayjs.utc(medication.dateTime).startOf("hour").add(1, "hour").local().format();
};

export const getPatientMedications = async (
  patientId: number,
  start?: string,
  end?: string,
  status?: MedicationStatusEnum,
): Promise<Medication[]> => {
  const response = await medicationsApiV1.getMedications(patientId, start, end, status);
  const data = stripHtml(response.data) as GetMedicationsResponse;
  return data.medications
    ? data.medications.map((medication) => ({
        ...medication,
        resource: ResourceEnum.Medication,
        start: getStartDateForMedication(medication, start),
        end: getEndDateForMedication(medication, end),
        date: dayjs.utc(medication.datePeriod?.start).local().format(),
        title: medication.name,
      }))
    : [];
};

export const getPatientMedicationAdherence = async (
  patientId: number,
): Promise<MedicationAdherence> => {
  const response = await medicationAdherenceApiV1.getMedicationAdherence(patientId);
  return stripHtml(response.data) as MedicationAdherence;
};
