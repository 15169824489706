import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { omit, uniqBy } from "lodash";
import dayjs from "dayjs";
import {
  AvailableAttachmentFiltering,
  AzureChatThreadItem,
  CommunicationState,
  NormalizedAttachments,
  NormalizedChatHistoryThreads,
  NormalizedChatMessages,
  VrsChatMessage,
  VrsChatMessageReceivedEvent,
  VrsChatThreadCreatedEvent,
  VrsTypingIndicatorReceived,
} from "./interfaces";
import {
  addChatThreadAsync,
  joinChatThreadAsync,
  createChatThreadAsync,
  downloadAttachmentSasAsync,
  fetchAttachmentsForThreadAsync,
  fetchChatConfiguration,
  fetchChatThreadsCombinedAsync,
  getChatThreadMessagesAsync,
  getUnreadPerThreadAsync,
  getPrivateChatAsync,
  updateThreadAsync,
  getChatHistoryThreadsAsync,
  getChatHistoryThreadMessagesAsync,
  downloadChatHistoryAttachmentSasAsync,
  fetchAttachmentsForChatHistoryThreadAsync,
} from "./asyncThunks";
import { localizedLogout, logout } from "../../shared/slices/authSlice";

export const messagesAdapter = createEntityAdapter<NormalizedChatMessages>();
export const attachmentsAdapter = createEntityAdapter<NormalizedAttachments>();
export const chatHistoryThreadsAdapter = createEntityAdapter<NormalizedChatHistoryThreads>();

const initialState: CommunicationState = {
  chatApiEndpoint: "",
  chatUserAccessToken: "",
  chatUserId: "",
  chatDisplayName: "",
  chatMessages: {
    status: "idle",
    items: messagesAdapter.getInitialState(),
  },
  chatThreads: {
    threads: [],
    status: "idle",
    unreadThreadStatus: {},
  },
  azureChatThreads: [],
  chatHistoryThreads: {
    status: "idle",
    threads: chatHistoryThreadsAdapter.getInitialState(),
  },
  chatHistoryMessages: {
    status: "idle",
    items: {},
  },
  chatHistoryAttachments: {
    status: "idle",
    attachments: [],
  },
  currentChatThreadStatus: "idle",
  status: "idle",
  attachments: {
    attachmentsStatus: "idle",
    attachmentFilteringType: "all",
    items: attachmentsAdapter.getInitialState(),
  },
  newMessageNotification: { threadId: undefined },
  singleThreadStatus: "idle",
};

export const communicationSlice = createSlice({
  name: "communication",
  initialState,
  reducers: {
    setChatHistoryPatientId: (state, { payload }: PayloadAction<{ patientId?: number }>) => {
      state.chatHistoryPatientId = payload.patientId;
    },
    setThreadId: (state, { payload }: PayloadAction<{ threadId: string }>) => {
      state.currentThreadId = payload.threadId;
      state.currentChatThreadStatus = "idle";
    },
    resetThreadId: (state) => {
      state.currentThreadId = undefined;
      state.currentChatThreadStatus = "idle";
    },
    addChatThreadMessage: (state, { payload }: PayloadAction<VrsChatMessageReceivedEvent>) => {
      const { id, version, senderDisplayName, metadata, message, sender, createdOn, threadId } =
        payload;
      const vrsMessage: VrsChatMessage = {
        id,
        version,
        type: "text",
        content: {
          message,
        },
        senderDisplayName,
        sender,
        createdOn,
        metadata,
        threadId,
      };

      if (state.chatMessages.items.ids.includes(threadId)) {
        const items = state.chatMessages.items.entities[threadId];
        if (items && items.messages) {
          items.messages.unshift(vrsMessage);
          messagesAdapter.updateOne(state.chatMessages.items, {
            id: threadId,
            changes: { messages: items.messages },
          });
        }
      }
      const azureIndex = state.azureChatThreads.findIndex(
        (azureThread) => azureThread.id === threadId,
      );
      if (azureIndex !== -1) {
        state.azureChatThreads[azureIndex].lastMessageReceivedOn = createdOn;
      }
      state.typingIndicator = undefined;
    },
    addChatThread: (state, { payload }: PayloadAction<VrsChatThreadCreatedEvent>) => {
      const azureIndex = state.azureChatThreads.findIndex(
        (azureThread) => azureThread.id === payload.threadId,
      );
      if (azureIndex === -1) {
        const chatThread: AzureChatThreadItem = {
          id: payload.threadId,
          topic: payload.properties.topic,
        };
        state.azureChatThreads.unshift(chatThread);
      }
      state.currentThreadId = payload.threadId;
    },
    setAttachmentTypeFiltering: (
      state,
      { payload }: PayloadAction<AvailableAttachmentFiltering>,
    ) => {
      state.attachments.attachmentFilteringType = payload;
    },
    setTypingIndicator: (state, action: PayloadAction<VrsTypingIndicatorReceived | undefined>) => {
      state.typingIndicator = action.payload;
    },
    setThreadUnreadStatus: (state, { payload }: PayloadAction<string>) => {
      state.chatThreads.unreadThreadStatus[payload] = state.currentThreadId !== payload;
    },
    resetCurrentChatThreadStatus: (state) => {
      state.currentChatThreadStatus = "idle";
    },
    resetMessages: (state, { payload }: PayloadAction<string>) => {
      if (state.chatMessages.items.ids.includes(payload)) {
        messagesAdapter.setOne(state.chatMessages.items, {
          id: payload,
          messages: [],
          isLastPage: true,
          continuationToken: undefined,
        });
      }
      if (state.chatHistoryMessages.items[payload]) {
        state.chatHistoryMessages.items = omit(state.chatHistoryMessages.items, [payload]);
      }
    },
    setNewMessageNotification: (
      state,
      { payload }: PayloadAction<{ threadId: string | undefined }>,
    ) => {
      state.newMessageNotification = {
        threadId: payload.threadId,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchChatConfiguration.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchChatConfiguration.fulfilled, (state, { payload }) => {
        state.chatApiEndpoint = payload.url;
        state.chatUserAccessToken = payload.token;
        state.chatUserId = payload.user_id;
        state.chatDisplayName = payload.chatDisplayName;
        state.status = "idle";
      })
      .addCase(fetchChatConfiguration.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(fetchChatThreadsCombinedAsync.pending, (state) => {
        state.chatThreads.status = "loading";
      })
      .addCase(fetchChatThreadsCombinedAsync.fulfilled, (state, { payload }) => {
        const { azureChatThreads, vrsChatThreads } = payload;
        const azureChatThreadsMap = azureChatThreads.reduce(
          (acc, thread) => ({
            ...acc,
            [thread.id]: { ...thread },
          }),
          {} as { [id: string]: AzureChatThreadItem },
        );
        const mergedAndSortedVrsChatThreads = vrsChatThreads
          .map((thread) => ({
            ...thread,
            topic: azureChatThreadsMap[thread.id] && azureChatThreadsMap[thread.id].topic,
            lastMessageReceivedOn:
              azureChatThreadsMap[thread.id] &&
              azureChatThreadsMap[thread.id].lastMessageReceivedOn,
          }))
          .sort((a, b) =>
            a.lastMessageReceivedOn && b.lastMessageReceivedOn
              ? new Date(b.lastMessageReceivedOn).getTime() -
                new Date(a.lastMessageReceivedOn).getTime()
              : 0,
          );
        state.azureChatThreads = azureChatThreads;
        state.chatThreads.threads = mergedAndSortedVrsChatThreads;
        state.chatThreads.status = "idle";
      })
      .addCase(fetchChatThreadsCombinedAsync.rejected, (state) => {
        state.chatThreads.status = "failed";
      })
      .addCase(getChatThreadMessagesAsync.pending, (state) => {
        state.chatMessages.status = "loading";
      })
      .addCase(getChatThreadMessagesAsync.fulfilled, (state, { payload }) => {
        if (state.chatMessages.items.ids.includes(payload.id)) {
          const oldItems = state.chatMessages.items.entities[payload.id];
          if (oldItems && oldItems.messages) {
            oldItems.messages.push(...payload.messages);
            messagesAdapter.upsertOne(state.chatMessages.items, {
              ...payload,
              messages: uniqBy(oldItems.messages, (message) => message.id),
            });
          }
        } else {
          messagesAdapter.addOne(state.chatMessages.items, payload);
        }
        state.chatMessages.status = "idle";
      })
      .addCase(getChatThreadMessagesAsync.rejected, (state) => {
        state.chatMessages.status = "failed";
      })
      .addCase(createChatThreadAsync.fulfilled, (state, { payload }) => {
        const index = state.chatThreads.threads.findIndex((thread) => thread.id === payload.id);
        if (index === -1) {
          state.chatThreads.threads.unshift({ ...payload, topic: "chatTopic" });
        }
        state.currentThreadId = payload.id;
        state.currentChatThreadStatus = "idle";
      })
      .addCase(addChatThreadAsync.fulfilled, (state, { payload }) => {
        const index = state.chatThreads.threads.findIndex((thread) => thread.id === payload.id);
        if (index === -1) {
          state.chatThreads.threads.unshift(payload);
        }
      })
      .addCase(downloadAttachmentSasAsync.fulfilled, (state, { payload }) => {
        if (payload) {
          const { objectUrl, messageId, threadId } = payload;
          const threadMessages = state.chatMessages.items.entities[threadId];
          if (threadMessages) {
            const index = threadMessages.messages.findIndex(({ id }) => id === messageId);
            if (index >= 0) {
              threadMessages.messages[index] = {
                ...threadMessages.messages[index],
                metadata: {
                  ...threadMessages.messages[index].metadata,
                  objectUrl,
                },
              };
            }
            messagesAdapter.setOne(state.chatMessages.items, threadMessages);
          }
        }
      })
      .addCase(downloadChatHistoryAttachmentSasAsync.fulfilled, (state, { payload }) => {
        if (payload) {
          const { objectUrl, messageId, threadId } = payload;
          const threadMessages = state.chatHistoryMessages.items[threadId].messages;
          if (threadMessages) {
            const index = threadMessages.findIndex(({ id }) => id === messageId);
            if (index >= 0) {
              threadMessages[index] = {
                ...threadMessages[index],
                metadata: {
                  ...threadMessages[index].metadata,
                  objectUrl,
                },
              };
            }
          }
          state.chatHistoryMessages.items[threadId].messages = threadMessages;
        }
      })
      .addCase(fetchAttachmentsForThreadAsync.pending, (state) => {
        state.attachments.attachmentsStatus = "loading";
      })
      .addCase(fetchAttachmentsForThreadAsync.fulfilled, (state, { payload }) => {
        state.attachments.attachmentsStatus = "idle";
        const currentAttachments = attachmentsAdapter
          .getSelectors()
          .selectById(state.attachments.items, payload.id);

        if (currentAttachments) {
          attachmentsAdapter.updateOne(state.attachments.items, {
            id: payload.id,
            changes: payload,
          });
        } else attachmentsAdapter.addOne(state.attachments.items, payload);
      })
      .addCase(fetchAttachmentsForThreadAsync.rejected, (state) => {
        state.attachments.attachmentsStatus = "failed";
      })
      .addCase(fetchAttachmentsForChatHistoryThreadAsync.pending, (state) => {
        state.chatHistoryAttachments.status = "loading";
      })
      .addCase(fetchAttachmentsForChatHistoryThreadAsync.fulfilled, (state, { payload }) => {
        state.chatHistoryAttachments.status = "idle";
        state.chatHistoryAttachments.attachments = payload.attachments;
      })
      .addCase(fetchAttachmentsForChatHistoryThreadAsync.rejected, (state) => {
        state.chatHistoryAttachments.status = "failed";
      })
      .addCase(joinChatThreadAsync.pending, (state) => {
        state.currentChatThreadStatus = "loading";
        state.singleThreadStatus = "loading";
      })
      .addCase(joinChatThreadAsync.fulfilled, (state, { payload }) => {
        state.currentChatThreadStatus = "idle";
        state.singleThreadStatus = "idle";
        state.currentThreadId = payload.threadId;
      })
      .addCase(joinChatThreadAsync.rejected, (state) => {
        state.currentChatThreadStatus = "failed";
        state.singleThreadStatus = "failed";
      })
      .addCase(getUnreadPerThreadAsync.fulfilled, (state, { payload }) => {
        state.status = "idle";
        const formattedPayload: Record<string, boolean> = {};
        payload.forEach((thread) => {
          formattedPayload[thread.thread_id] = thread.has_unread;
        });
        state.chatThreads.unreadThreadStatus = formattedPayload;
      })
      .addCase(getPrivateChatAsync.pending, (state) => {
        state.currentChatThreadStatus = "loading";
      })
      .addCase(getPrivateChatAsync.fulfilled, (state, { payload }) => {
        state.currentChatThreadStatus = "idle";
        const foundIndex = state.chatThreads.threads.findIndex(({ id }) => id === payload.id);
        if (foundIndex === -1)
          state.chatThreads.threads.push({
            ...payload,
            topic: "",
            lastMessageReceivedOn: dayjs().toISOString(),
          });
        state.currentThreadId = payload.id;
      })
      .addCase(updateThreadAsync.pending, (state) => {
        state.singleThreadStatus = "loading";
      })
      .addCase(updateThreadAsync.fulfilled, (state, { payload }) => {
        const threadIndex = state.chatThreads.threads.findIndex(
          (thread) => thread.id === payload.id,
        );
        if (threadIndex > -1) {
          state.chatThreads.threads[threadIndex] = {
            ...state.chatThreads.threads[threadIndex],
            ...payload,
          };
        }
        state.singleThreadStatus = "idle";
      })
      .addCase(updateThreadAsync.rejected, (state) => {
        state.singleThreadStatus = "failed";
      })
      .addCase(getChatHistoryThreadsAsync.pending, (state) => {
        state.chatHistoryThreads.status = "loading";
      })
      .addCase(getChatHistoryThreadsAsync.fulfilled, (state, { payload }) => {
        const currentChatThreads = chatHistoryThreadsAdapter
          .getSelectors()
          .selectById(state.chatHistoryThreads.threads, payload.id);
        if (currentChatThreads) {
          chatHistoryThreadsAdapter.updateOne(state.chatHistoryThreads.threads, {
            id: payload.id,
            changes: payload,
          });
        } else chatHistoryThreadsAdapter.addOne(state.chatHistoryThreads.threads, payload);
        state.chatHistoryThreads.status = "idle";
      })
      .addCase(getChatHistoryThreadsAsync.rejected, (state) => {
        state.chatHistoryThreads.status = "failed";
      })
      .addCase(getChatHistoryThreadMessagesAsync.pending, (state) => {
        state.chatHistoryMessages.status = "loading";
      })
      .addCase(getChatHistoryThreadMessagesAsync.fulfilled, (state, { payload }) => {
        const { threadId } = payload;
        const threadMessages = state.chatHistoryMessages.items[threadId] || {
          messages: [],
          continuationToken: null,
          isLastPage: false,
        };

        threadMessages.messages = [...threadMessages.messages, ...payload.data.value];
        threadMessages.continuationToken = payload.data.nextLink;
        threadMessages.isLastPage = !payload.data.nextLink;

        state.chatHistoryMessages.items[threadId] = threadMessages;

        state.chatHistoryMessages.status = "idle";
      })
      .addCase(getChatHistoryThreadMessagesAsync.rejected, (state) => {
        state.chatHistoryMessages.status = "failed";
      })
      .addCase(logout, () => {
        return initialState;
      })
      .addCase(localizedLogout, () => {
        return initialState;
      });
  },
});

// Actions

export const {
  setThreadId,
  resetThreadId,
  addChatThreadMessage,
  addChatThread,
  setAttachmentTypeFiltering,
  setTypingIndicator,
  setThreadUnreadStatus,
  resetCurrentChatThreadStatus,
  resetMessages,
  setNewMessageNotification,
  setChatHistoryPatientId,
} = communicationSlice.actions;

export default communicationSlice.reducer;
