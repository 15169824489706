import { MbscCalendarEvent } from "@mobiscroll/react/dist/src/react/components/eventcalendar";
import { Box, CircularProgress, Grid, Theme, Typography, useMediaQuery } from "@mui/material";
import { VrsButton, VrsErrorMsg } from "@veris-health/web-core";
import React from "react";
import { Status } from "../../../features/shared/interfaces";
import { selectPatientsMap } from "../../../features/shared/slices/patientsSlice";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { VrsPatientAppointmentDetails } from "../VrsPatientAppointmentDetails";

export interface VrsTodaysAppointmentsProps {
  todaysAppointmentsList: MbscCalendarEvent[];
  appointmentsStatus?: Status;
  onTodaysAppointmentsClick?: () => void;
  width?: string;
  onViewAllClick?: () => void;
  onPatientClick: (id: string) => void;
}

export function VrsTodaysAppointments({
  todaysAppointmentsList,
  onTodaysAppointmentsClick,
  onViewAllClick,
  appointmentsStatus,
  onPatientClick,
}: VrsTodaysAppointmentsProps): JSX.Element {
  const isMediumScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("xl"));
  const patientsMap = useAppSelector(selectPatientsMap);
  return (
    <Box
      sx={{
        borderRadius: 2,
        border: (theme) => `1px solid ${theme.veris.colors.neutrals["grey-light"]}`,
        background: (theme) => theme.veris.colors.neutrals.white,
        marginBottom: (theme) => theme.spacing(2),
      }}
      padding={(theme) => theme.spacing(0.5, 0.5, 1.13, 0.5)}
    >
      <Grid
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        padding={(theme) => theme.spacing(0.5, 0.625, 1, 0.625)}
      >
        <Typography color={(theme) => theme.veris.colors.neutrals["grey-dark"]} variant="body1">
          TODAY&apos;S APPOINTMENTS
        </Typography>
        {appointmentsStatus !== "failed" && (
          <VrsButton buttonType="quaternary" onClick={onViewAllClick}>
            <Typography variant="caption"> View all </Typography>
          </VrsButton>
        )}
      </Grid>
      <Box
        sx={{
          maxHeight: isMediumScreen ? "35vh" : "38vh",
          overflow: "auto",
          padding: (theme) => theme.spacing(0, 1, 0, 0.5),
        }}
      >
        {appointmentsStatus === "loading" && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="80%"
            data-test-hook="todaysAppointments-loading"
          >
            <CircularProgress />
          </Box>
        )}
        {todaysAppointmentsList.length === 0 && appointmentsStatus === "idle" && (
          <Typography
            color={(theme) => theme.veris.colors.neutrals["grey-3"]}
            textAlign="center"
            variant="body"
          >
            There are no appointments scheduled for today.
          </Typography>
        )}
        {appointmentsStatus === "idle" &&
          todaysAppointmentsList.map((todaysAppsDetails) => {
            const patientDetails = patientsMap[todaysAppsDetails.patientId];
            return (
              <Box key={todaysAppsDetails?.id} paddingY={(theme) => theme.spacing(0.375)}>
                {patientDetails && (
                  <VrsPatientAppointmentDetails
                    appointmentLocation={todaysAppsDetails?.type}
                    appointmentDateTime={todaysAppsDetails?.start as Date}
                    onPatientClick={() => onPatientClick(todaysAppsDetails.patientId)}
                    {...patientDetails}
                  />
                )}
              </Box>
            );
          })}
        {appointmentsStatus === "failed" && (
          <VrsErrorMsg size="small" onClick={onTodaysAppointmentsClick} />
        )}
      </Box>
    </Box>
  );
}
