import React, { ReactElement, useEffect, useState } from "react";
import { CircularProgress, Drawer, styled, Box } from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import { verisColors, VrsErrorMsg } from "@veris-health/web-core";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import { VrsPatientListTable } from "../../ui/components/Tables/VrsPatientListTable";
import {
  selectPatientsDataCancerTypeOptions,
  selectPatientTagOptions,
  SortByAlphabet,
  selectPatientSymptomOptions,
} from "./patientsSlice";
import { toggleDrawers, selectTogglePreviewBar, togglePreviewBar } from "../shared/slices/uiSlice";
import { replaceRouteParam } from "../../routes-config";
import SidebarPreview from "./components/SidebarPreview/SidebarPreview";
import {
  filterByCancerType,
  filterBySymptom,
  resetFilters,
  selectCancerTypeFilter,
  selectPatientStatus,
  selectSortByAlphabet,
  selectSymptomFilter,
  sortByAlphabet,
} from "../shared/slices/patientsSlice";
import { searchPatientsAsync } from "../shared/slices/asyncThunks";
import { filteringAction, usePatientsFilter } from "./hooks/usePatientsFilter";
import { getSelectOptions } from "../shared/helpers";
import { VrsPatientInfo } from "../shared/interfaces";
import { PatientFilters } from "./components/PatientFilters/PatientFilters";

const drawerWidth = "20%";

const PreviewDrawer = styled(Drawer)(() => ({
  flexShrink: 0,
  zIndex: 0,
  "& .MuiDrawer-paper": {
    width: drawerWidth,
    boxSizing: "border-box",
    zIndex: 0,
    borderLeft: `1px solid ${verisColors.neutrals["grey-light"]}`,
  },
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  marginTop: 18,
  ...theme.mixins.toolbar,
}));

function PatientsContainer(): ReactElement {
  const cancerTypes = useAppSelector(selectPatientsDataCancerTypeOptions);
  const patientTags = useAppSelector(selectPatientTagOptions);
  const symptoms = useAppSelector(selectPatientSymptomOptions);
  const sortByAlphabetValue = useAppSelector(selectSortByAlphabet);
  const cancerTypeFilter = useAppSelector(selectCancerTypeFilter);
  const symptomFilter = useAppSelector(selectSymptomFilter);
  const previewExpanded = useAppSelector(selectTogglePreviewBar);
  const patientsStatus = useAppSelector(selectPatientStatus);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const history = useHistory();

  const urlFilter = filteringAction[`${location.pathname}`];
  const initialFilters =
    location.pathname.includes("/qol") || location.pathname === "/patients" ? [] : [urlFilter];

  const [patientToPreview, setPatientToPreview] = useState<VrsPatientInfo>();
  const [tagFilters, setTagFilters] = useState<string[]>(initialFilters);

  const sortedAndFilteredVrsPatients = usePatientsFilter(location.pathname, tagFilters);

  const symptomSelectOptions = React.useMemo(() => getSelectOptions(symptoms), [symptoms]);
  const cancerTypeSelectOptions = React.useMemo(() => getSelectOptions(cancerTypes), [cancerTypes]);

  const patientTagSelectOptions = React.useMemo(() => getSelectOptions(patientTags), [patientTags]);

  const handleClickPreview = (patient: VrsPatientInfo) => {
    if (!previewExpanded) {
      dispatch(toggleDrawers());
    }
    setPatientToPreview(patient);
  };

  const handleClickHidePreview = () => {
    dispatch(togglePreviewBar(false));
    setPatientToPreview(undefined);
  };

  const handleFullDetailsClick = ({ id }: { id: number }) => {
    dispatch(togglePreviewBar(false));
    setPatientToPreview(undefined);
    history.push({
      pathname: replaceRouteParam("PATIENT_DETAILS", ":userId", id.toString()),
      state: "Table",
    });
  };

  useEffect(() => {
    setTagFilters(initialFilters);
    dispatch(resetFilters());
    return () => {
      handleClickHidePreview();
      setTagFilters([]);
    };
  }, [location]);

  useEffect(() => {
    return () => {
      dispatch(resetFilters());
    };
  }, []);

  return (
    <Box width="100%">
      {patientsStatus === "idle" && (
        <Box
          sx={{
            backgroundColor: (theme) => theme.veris.colors.neutrals["grey-1"],
          }}
          display="flex"
          width="100%"
        >
          <Box width={previewExpanded ? "80%" : "100%"} py={2.5} px={2}>
            <PatientFilters
              onCancerTypeSelected={(filterType) => dispatch(filterByCancerType(filterType))}
              cancerTypeFilter={cancerTypeFilter}
              cancerTypeSelectOptions={cancerTypeSelectOptions}
              symptomFilter={symptomFilter}
              symptomSelectOptions={symptomSelectOptions}
              onSymptomSelected={(symptom) => dispatch(filterBySymptom(symptom))}
              tagFilter={tagFilters}
              tagOptions={patientTagSelectOptions}
              onTagSelected={(tags) => setTagFilters(tags)}
              onFiltersReset={() => dispatch(resetFilters())}
              sortByAlphabetValue={sortByAlphabetValue}
              onAlphabetSorting={(direction) =>
                dispatch(sortByAlphabet(direction as SortByAlphabet))
              }
              predefinedFilters={location.pathname.includes("/qol") ? undefined : urlFilter}
            />
            <Box>
              <VrsPatientListTable
                data={sortedAndFilteredVrsPatients}
                onClickPreview={(patient: VrsPatientInfo) => {
                  handleClickPreview(patient);
                }}
                onClickHidePreview={handleClickHidePreview}
                onClickFullDetails={handleFullDetailsClick}
                onPatientClick={(id: number) =>
                  history.push(replaceRouteParam("PATIENT_DETAILS", ":userId", id.toString()))
                }
                patientToPreview={patientToPreview}
              />
            </Box>
          </Box>
          <PreviewDrawer variant="persistent" anchor="right" open={previewExpanded}>
            <DrawerHeader />
            {previewExpanded && patientToPreview && <SidebarPreview patient={patientToPreview} />}
          </PreviewDrawer>
        </Box>
      )}
      {patientsStatus === "loading" && (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%" p={2}>
          <CircularProgress />
        </Box>
      )}
      {patientsStatus === "failed" && (
        <VrsErrorMsg
          size="small"
          onClick={() => dispatch(searchPatientsAsync({ allPatients: false }))}
        />
      )}
    </Box>
  );
}

export default PatientsContainer;
