import { Box, Typography, useMediaQuery, Theme, CircularProgress } from "@mui/material";
import { ReportItem } from "@veris-health/virtual-doc-ms/lib/v1";
import { VrsErrorMsg } from "@veris-health/web-core";
import React from "react";
import { Status } from "../../../shared/interfaces";

export interface SymptomNotificationDetailsProps {
  activeNotificationSymptomDetails?: ReportItem;
  loadReportedSymptoms: () => void;
  symptomsReportHistoryStatus: Status;
}

export const SymptomNotificationDetails = ({
  activeNotificationSymptomDetails,
  loadReportedSymptoms,
  symptomsReportHistoryStatus,
}: SymptomNotificationDetailsProps): JSX.Element => {
  const isMediumScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("xl"));

  return (
    <Box display="grid" gridTemplateColumns="repeat(8, 1fr)" gap={2.3}>
      {symptomsReportHistoryStatus === "loading" && (
        <Box
          display="flex"
          justifyContent="center"
          gridColumn="span 12"
          style={{
            paddingBlock: "2.3rem",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {symptomsReportHistoryStatus === "failed" && (
        <Box
          display="flex"
          justifyContent="center"
          gridColumn="span 12"
          sx={{
            paddingBlock: "0.6rem",
            backgroundColor: (theme) => theme.veris.colors.neutrals.white,
          }}
        >
          <VrsErrorMsg
            size="small"
            onClick={() => {
              loadReportedSymptoms();
            }}
          />
        </Box>
      )}
      {symptomsReportHistoryStatus === "idle" && activeNotificationSymptomDetails && (
        <>
          <NotificationDetailItem
            label={isMediumScreen ? "Symptom" : "Symptom reported"}
            value={activeNotificationSymptomDetails.symptom.label}
          />
          <NotificationDetailItem
            label="Severity"
            value={activeNotificationSymptomDetails.severity?.value || ""}
          />
          {activeNotificationSymptomDetails.body_parts && (
            <NotificationDetailItem
              label="Body Part"
              value={activeNotificationSymptomDetails.body_parts?.replaceAll(",", ", ") || ""}
            />
          )}
          <NotificationDetailItem
            label="Photo"
            value={activeNotificationSymptomDetails.picture ? "Yes" : "No"}
          />
        </>
      )}
      {symptomsReportHistoryStatus === "idle" && !activeNotificationSymptomDetails && (
        <Box gridColumn="span 12">
          <Typography
            variant="subtitle2"
            color={(theme) => theme.veris.colors.neutrals["grey-mid"]}
          >
            No symptom notification report details available
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export function NotificationDetailItem({
  label,
  value,
}: {
  label: string;
  value: string;
}): JSX.Element {
  return (
    <>
      <Box gridColumn="span 2">
        <Typography variant="subtitle2" color={(theme) => theme.veris.colors.neutrals["grey-mid"]}>
          {label}
        </Typography>
      </Box>
      <Box gridColumn="span 6">
        <Typography
          variant="subtitle2"
          sx={{
            "::first-letter": {
              textTransform: "capitalize",
            },
          }}
        >
          {value.replaceAll("-", " ")}
        </Typography>
      </Box>
    </>
  );
}
