import React from "react";
import { Box, Typography } from "@mui/material";
import { ThreadParticipantInfo } from "@veris-health/communication-ms/lib/v1";
import { VrsAvatar } from "@veris-health/web-core";
import { StyledBadge } from "../VrsGroupChatHeader";

export interface VrsGroupThreadContentProps {
  groupChatParticipants: ThreadParticipantInfo[];
  label: string;
  caption: string;
}

export const VrsGroupThreadContent = ({
  groupChatParticipants,
  label,
  caption,
}: VrsGroupThreadContentProps): JSX.Element => {
  const patient = groupChatParticipants.find((participant) => participant.user_type === "patient");
  return (
    <Box
      paddingY={(theme) => theme.spacing(0.375)}
      paddingX={(theme) => theme.spacing(0.625)}
      sx={{
        borderRadius: 1.5,
        flexGrow: 1,
        display: "flex",
        alignItems: "center",
      }}
    >
      <StyledBadge
        badgeContent={groupChatParticipants.length}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
      >
        <VrsAvatar
          name={patient?.display_name ?? "Unknown"}
          imageUrl={patient && patient.image && `data:image/png;base64,${patient.image}`}
        />
      </StyledBadge>
      <Box sx={{ overflowX: "hidden", marginLeft: (theme) => theme.spacing(1) }}>
        <Typography
          sx={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            display: "block",
            maxWidth: "200px",
          }}
          variant="subtitle2"
        >
          {label}
        </Typography>
        <Typography
          sx={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            display: "block",
            maxWidth: "200px",
          }}
          variant="caption"
        >
          {caption}
        </Typography>
      </Box>
    </Box>
  );
};
