import { Box, CircularProgress, Theme, useMediaQuery, Typography } from "@mui/material";
import { SymptomEvent } from "@veris-health/virtual-doc-ms/lib/v1";
import React, { useEffect } from "react";
import { verisColors, VrsErrorMsg } from "@veris-health/web-core";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../hooks/useAppSelector";
import {
  loadSymptomReportAnswers,
  resetSymptomReportAnswers,
} from "../../PatientDetailsSidebar/patientDetailsSidebarSlice";
import {
  selectExpandedSymptomDetails,
  addExpandedSymptomDetails,
  toggleLeftSideBar,
} from "../../shared/slices/uiSlice";
import SymptomsDailyView from "../components/Symptoms/SymptomsDailyView";
import SymptomEvents from "../components/Symptoms/SymptomEvents";
import { SymptomLabel } from "../components/Symptoms/SymptomLabel";
import {
  fetchUserSymptomsData,
  selectConfiguredSymptomsData,
  selectUserSymptomsStatus,
  SensorsGraphView,
} from "../measurementSlice";
import { SymptomReport } from "../../../utils/symptoms";
import { VrsPatientInfo } from "../../shared/interfaces";

interface SymptomsViewContainer {
  isPreview: boolean | undefined;
  view: SensorsGraphView;
  currentPatient: VrsPatientInfo;
  summary?: boolean;
}

const SymptomsViewContainer = ({
  isPreview,
  view,
  currentPatient,
  summary,
}: SymptomsViewContainer): JSX.Element => {
  const dispatch = useAppDispatch();
  const configuredSymptomsData = useAppSelector((state) =>
    selectConfiguredSymptomsData(state, +currentPatient.id),
  );
  const expandedSymptomDetails = useAppSelector(selectExpandedSymptomDetails);
  const symptomsStatus = useAppSelector(selectUserSymptomsStatus);

  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("xl"));

  const symptomClickHandler = (symptomEvent: SymptomEvent | undefined) => {
    const isClickedSymptom =
      expandedSymptomDetails &&
      symptomEvent &&
      expandedSymptomDetails?.report_id + expandedSymptomDetails?.symptom_name ===
        symptomEvent.report_id + symptomEvent.symptom_name;
    if (isClickedSymptom) {
      dispatch(resetSymptomReportAnswers());
      dispatch(addExpandedSymptomDetails(undefined));
      if (!isSmallScreen) dispatch(toggleLeftSideBar(true));
    } else {
      dispatch(
        loadSymptomReportAnswers({
          reporterId: String(currentPatient.id),
          reportId: String(symptomEvent?.report_id),
        }),
      );
      dispatch(addExpandedSymptomDetails(symptomEvent));
      dispatch(toggleLeftSideBar(false));
    }
  };

  useEffect(() => {
    return () => {
      dispatch(addExpandedSymptomDetails(undefined));
    };
  }, [dispatch]);

  return (
    <Box
      display="flex"
      px={0}
      py={1}
      flexDirection="column"
      sx={{ maxHeight: summary ? "auto" : "300px", overflowY: "auto" }}
    >
      {symptomsStatus === "failed" && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height={150}
          sx={{ backgroundColor: (theme) => theme.veris.colors.neutrals["grey-1"] }}
        >
          <VrsErrorMsg
            title="Unfortunately, we could not fetch the symptom data"
            size="small"
            backgroundColor={verisColors.neutrals["grey-1"]}
            onClick={() =>
              dispatch(
                fetchUserSymptomsData({
                  userId: String(currentPatient.id),
                }),
              )
            }
          />
        </Box>
      )}
      {symptomsStatus === "loading" && (
        <Box display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      )}
      {symptomsStatus === "idle" && (
        <>
          {configuredSymptomsData && configuredSymptomsData.length > 0 ? (
            configuredSymptomsData?.map((symptom: SymptomReport, index: number) => {
              return (
                <Box display="flex" flexDirection="row" key={symptom.name} className="break-before">
                  <Box overflow="initial">
                    <SymptomLabel
                      symptomType={symptom.name}
                      symptomStatus={symptom.events[symptom.events.length - 1]?.generalStatus}
                      isFirst={index === 0}
                      isLast={index === configuredSymptomsData.length - 1}
                      key={symptom.name}
                      isPreview={!!isPreview}
                      view={view}
                    />
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                    }}
                  >
                    {(view === "monthly" ||
                      view === "weekly" ||
                      (view === "3-days" && isPreview)) && (
                      <SymptomEvents
                        data={symptom.events}
                        onSymptomClickHandler={symptomClickHandler}
                        clickedSymptom={expandedSymptomDetails}
                        key={symptom.name}
                        isPreview={!!isPreview}
                        isSmallScreen={isSmallScreen}
                        view={view}
                        isFirst={index === 0}
                        isLast={index === configuredSymptomsData.length - 1}
                      />
                    )}
                    {(view === "daily" || (view === "3-days" && !isPreview)) && (
                      <SymptomsDailyView
                        data={symptom.events}
                        onSymptomClickHandler={symptomClickHandler}
                        clickedSymptom={expandedSymptomDetails}
                        key={symptom.name}
                        isPreview={!!isPreview}
                        summary={summary}
                        view={view}
                        isFirst={index === 0}
                        isLast={index === configuredSymptomsData.length - 1}
                      />
                    )}
                  </Box>
                </Box>
              );
            })
          ) : (
            <Box
              display="flex"
              alignItems={!isPreview ? "center" : "baseline"}
              sx={{
                backgroundColor: (theme) => theme.veris.colors.neutrals["grey-2"],
                borderRadius: (theme) => +theme.shape.borderRadius * 0.375,
                padding: (theme) => theme.spacing(0.6, 1.15),
                width: "fit-content",
              }}
            >
              <Typography variant="subtitle1" pr={1}>
                Note:
              </Typography>
              <Typography variant="subtitle24">
                There are no active symptoms at the moment.
              </Typography>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default SymptomsViewContainer;
