import { Autocomplete, Box, Paper, styled, TextField, TextFieldProps } from "@mui/material";
import React, { HTMLAttributes } from "react";
import { GetAllergyResponse } from "@veris-health/med-data-ms/lib/v1";
import { MedStaffProfileItem } from "@veris-health/user-ms/lib/v1";
import { VrsIcon, IconName } from "@veris-health/web-core";
import { VrsPatientInfo } from "../../../features/shared/interfaces";

export type AutoCompleteOptionType =
  | MedStaffProfileItem
  | GetAllergyResponse
  | VrsPatientInfo
  | { name: string; id: number };

export type AutoCompleteOption<T extends AutoCompleteOptionType> = {
  label: string;
  value: T;
};

export interface VrsSearchBarProps<T extends AutoCompleteOptionType> {
  id: string;
  options: AutoCompleteOption<T>[];
  placeholder?: string;
  onChange?: (event: React.SyntheticEvent, value?: AutoCompleteOption<T>) => void;
  onInputChange?: (value: string) => void;
  renderOption?: (
    props: HTMLAttributes<HTMLLIElement>,
    option: AutoCompleteOption<T>,
  ) => React.ReactNode;
  clearIcon?: React.ReactNode;
  freeSolo?: boolean;
  inputValue?: string;
  value?: AutoCompleteOption<T>;
  disabled?: boolean;
  predefinedValue?: AutoCompleteOption<T>;
  onBlur?: React.FocusEventHandler<HTMLDivElement>;
}

export const StyledTextField = styled(TextField)<TextFieldProps>(({ theme }) => ({
  marginLeft: theme.spacing(1),
  ".MuiInput-root:before, .MuiInput-root:after, .MuiInput-root:hover, .MuiInput-root:hover:not(.Mui-disabled):before,.MuiInputBase-root.Mui-disabled:before ":
    {
      border: "none",
    },
  ".MuiInput-root.MuiInputBase-sizeSmall": {
    ".MuiInput-input": {
      ...theme.typography.body,
      padding: theme.spacing(0.429, 0.58, 0, 0),
      height: "20px",
      [theme.breakpoints.down("lg")]: {
        padding: theme.spacing(0.725, 0.58, 0, 0),
      },
    },
  },
}));

export function VrsSearchBar<T extends AutoCompleteOptionType>({
  id,
  options,
  placeholder = "Search...",
  onChange,
  onInputChange,
  renderOption,
  clearIcon,
  freeSolo = false,
  inputValue,
  disabled = false,
  predefinedValue,
  onBlur,
  ...rest
}: VrsSearchBarProps<T>): JSX.Element {
  return (
    <Autocomplete
      {...rest}
      id={id}
      data-test-hook={id}
      sx={{
        borderRadius: (theme) => +theme.shape.borderRadius * 0.375,
        border: (theme) => `1px solid ${theme.veris.colors.neutrals["grey-2"]} `,
        padding: (theme) => theme.spacing(1, 1.25),
        width: "100%",
      }}
      options={[...options]}
      isOptionEqualToValue={(option, { value }) => option.value?.id === value?.id}
      autoComplete
      forcePopupIcon={false}
      PaperComponent={({ children }) => (
        <Paper
          sx={{
            position: "relative",
            top: 10,
            background: (theme) => theme.veris.colors.neutrals.white,
            width: "max-content",
            left: -15,
          }}
        >
          {children}
        </Paper>
      )}
      inputValue={inputValue}
      onInputChange={(event, value) => onInputChange && onInputChange(value)}
      onChange={(event, value) => {
        if (typeof value === "string") {
          return;
        }

        if (onChange) {
          onChange(event, value ?? undefined);
        }
      }}
      renderInput={(params) => (
        <Box ref={params.InputProps.ref} display="flex" alignItems="center">
          <VrsIcon name={IconName.Search} />
          <StyledTextField {...params} variant="standard" size="small" placeholder={placeholder} />
        </Box>
      )}
      includeInputInList
      renderOption={renderOption}
      clearIcon={clearIcon}
      freeSolo={freeSolo}
      disabled={disabled}
      defaultValue={predefinedValue}
      onBlur={onBlur}
    />
  );
}
