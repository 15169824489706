import React from "react";
import { Box, Grid, Typography, useMediaQuery, Theme } from "@mui/material";
import { VrsIcon, IconName, VrsAvatar, VrsTooltip, verisColors } from "@veris-health/web-core";
import { renderDatestampString, renderTimestampString } from "../../../utils/date";
import { VrsBadgeAvatar } from "../VrsBadgeAvatar";
import { getShortName } from "../../../utils/helpers";
import { VrsPatientInfo } from "../../../features/shared/interfaces";
import { PatientStatusesMap } from "../../../constants";

export interface VrsPatientAppointmentDetailsProps extends VrsPatientInfo {
  verisPatient?: boolean;
  dateTimeSwap?: boolean;
  size?: number;
  border?: boolean;
  backgroundColor?: string;
  dots?: boolean;
  onPatientClick?: () => void;
  appointmentDateTime: string | Date;
  appointmentLocation?: string;
}

export function VrsPatientAppointmentDetails({
  name,
  id,
  appointmentDateTime,
  appointmentLocation,
  picture,
  dateTimeSwap = false,
  size = 44,
  border = false,
  backgroundColor,
  dots = false,
  verisPatient = true,
  isInactiveOrDeceased,
  observation,
  patientStatus,
  onPatientClick,
}: VrsPatientAppointmentDetailsProps): JSX.Element {
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("xl"));

  return (
    <Box
      data-test-hook="appointment-details-element"
      display="flex"
      alignItems="flex-end"
      paddingY={size < 44 ? (theme) => theme.spacing(0.625) : (theme) => theme.spacing(0.375)}
      paddingX={(theme) => theme.spacing(0.625)}
      sx={{
        borderRadius: 1.5,
        flexGrow: 1,
        backgroundColor: backgroundColor || verisColors.neutrals["grey-background"],
      }}
      border={border ? `1px solid ${verisColors.neutrals["grey-light"]}` : "inherit"}
    >
      <Box
        data-test-hook="patient-element"
        marginX={(theme) => theme.spacing(0.25)}
        alignSelf="flex-start"
        sx={{ cursor: id ? "pointer" : "auto" }}
        onClick={onPatientClick}
        mr={1}
      >
        {verisPatient ? (
          <VrsAvatar
            imageUrl={picture}
            size={size}
            name={name}
            opacity={isInactiveOrDeceased ? "70%" : "100%"}
            bottomRightIcon={observation && <VrsIcon name={IconName.Observation} />}
          />
        ) : (
          <VrsBadgeAvatar
            imageUrl={picture}
            badgeContent={<VrsIcon name={IconName.OutsidePatient} />}
            name={name}
            inactive={isInactiveOrDeceased}
          />
        )}
      </Box>
      <Box
        display="flex"
        paddingTop={(theme) => theme.spacing(0.5)}
        width="100%"
        justifyContent="space-between"
      >
        <Grid marginRight={(theme) => theme.spacing(1)} maxWidth={isSmallScreen ? "95px" : "144px"}>
          <Box
            data-test-hook="name-wrapper"
            marginBottom={(theme) => theme.spacing(0.25)}
            whiteSpace="nowrap"
            sx={{ cursor: id ? "pointer" : "auto" }}
            onClick={onPatientClick}
          >
            {isSmallScreen ? (
              <VrsTooltip title={name} bcgcolor={verisColors.neutrals.black}>
                <Typography
                  variant="subtitle1"
                  color={
                    isInactiveOrDeceased
                      ? verisColors.neutrals["grey-3"]
                      : verisColors.neutrals.black
                  }
                  overflow="hidden"
                  textOverflow="ellipsis"
                >
                  {getShortName(name)}
                </Typography>
              </VrsTooltip>
            ) : (
              <Typography
                data-test-hook="patient-name"
                variant="subtitle1"
                color={
                  isInactiveOrDeceased ? verisColors.neutrals["grey-3"] : verisColors.neutrals.black
                }
                overflow="hidden"
                textOverflow="ellipsis"
              >
                {name}
              </Typography>
            )}
          </Box>
          <Typography
            data-test-hook="appointment-location"
            variant="caption"
            color={
              isInactiveOrDeceased
                ? verisColors.neutrals["grey-3"]
                : verisColors.neutrals["grey-dark"]
            }
            sx={{ textTransform: "capitalize" }}
          >
            {appointmentLocation} {!isSmallScreen && "Appointment"}
          </Typography>
          <Typography
            variant="caption"
            color={(theme) => theme.veris.colors.neutrals["grey-3"]}
            sx={{ display: "block" }}
          >
            {patientStatus && PatientStatusesMap[patientStatus]}
          </Typography>
        </Grid>
        <Grid>
          <Box marginBottom={(theme) => theme.spacing(0.25)}>
            <Typography
              data-test-hook="appointment-time"
              variant="subtitle1"
              color={verisColors.neutrals["grey-dark"]}
            >
              {!dateTimeSwap
                ? renderTimestampString(appointmentDateTime)
                : renderDatestampString(appointmentDateTime)}
            </Typography>
          </Box>
          <Typography
            data-test-hook="appointment-date"
            variant="caption"
            color={verisColors.neutrals["grey-dark"]}
          >
            {!dateTimeSwap
              ? renderDatestampString(appointmentDateTime)
              : renderTimestampString(appointmentDateTime)}
          </Typography>
        </Grid>
      </Box>
      <Box
        data-test-hook="more-icon-wrapper"
        display={!dots ? "none" : "inherit"}
        marginX={(theme) => theme.spacing(0.25)}
        alignSelf="flex-start"
        paddingTop={(theme) => theme.spacing(0.5)}
      >
        <VrsIcon name={IconName.MoreVertical} size={size} />
      </Box>
    </Box>
  );
}
