import React from "react";
import { Typography, Box, styled, Button } from "@mui/material";
import {
  WellbeingClassification,
  QOLQuestionnaireResponse,
  QOLReportStatusEnum,
} from "@veris-health/virtual-doc-ms/lib/v1";
import { round } from "lodash";
import { IconName, VrsIcon, VrsButton, verisColors, dateFormats } from "@veris-health/web-core";
import { utcToLocal } from "../../../utils/date";

const reportStatusColor: Record<QOLReportStatusEnum, { color: string; backgroundColor: string }> = {
  incomplete: {
    color: verisColors.mango.normal,
    backgroundColor: verisColors.mango.light,
  },
  complete: {
    color: verisColors.moderate.normal,
    backgroundColor: verisColors.moderate.light,
  },
  deleted: {
    color: verisColors.neutrals.white,
    backgroundColor: verisColors.neutrals.white,
  },
};
export function ReportStatusField({ status }: { status: QOLReportStatusEnum }): JSX.Element {
  return (
    <Box
      sx={{
        borderRadius: (theme) => theme.shape.borderRadius,
        padding: (theme) => theme.spacing(0, 1),
        textTransform: "capitalize",
        ...reportStatusColor[status ?? "complete"],
      }}
    >
      <Typography variant="captionSemibold" data-test-hook="qol-report-status-field">
        {status}
      </Typography>
    </Box>
  );
}
const ReportIndexButton = styled(Button)(() => ({
  backgroundColor: verisColors.neutrals.white,
  border: `1px solid ${verisColors.neutrals["grey-2"]}`,
  minWidth: "fit-content",
}));

export function ReportIndex({ index }: { index: number }): JSX.Element {
  return (
    <ReportIndexButton
      sx={{
        borderRadius: (theme) => theme.shape.borderRadius,
        padding: (theme) => theme.spacing(0.5, 0.75, 0, 0.75),
      }}
    >
      <Typography
        color={(theme) => theme.veris.colors.neutrals["grey-4"]}
        variant="h6Semibold"
        data-test-hook="qol-report-index"
      >
        {round(index * 100, 1)}
      </Typography>
    </ReportIndexButton>
  );
}

export interface ReportScoreFieldProps {
  score?: WellbeingClassification;
}
export function ReportScoreField({ score }: ReportScoreFieldProps): JSX.Element {
  return (
    <Box
      sx={{
        borderRadius: (theme) => theme.shape.borderRadius,
        padding: (theme) => theme.spacing(0, 1),
        textTransform: "capitalize",
        backgroundColor: (theme) => theme.veris.colors.neutrals["grey-2"],
        color: (theme) => theme.veris.colors.neutrals["grey-3"],
      }}
    >
      <Typography variant="captionSemibold" data-test-hook="qol-report-score">
        {score?.replaceAll("_", " ") ?? "N/A"}
      </Typography>
    </Box>
  );
}

export interface VrsQOLReportCardProps {
  reportItem: QOLQuestionnaireResponse;
  onClick: () => void;
}

export function VrsQOLReportCard({ reportItem, onClick }: VrsQOLReportCardProps): JSX.Element {
  return (
    <VrsButton
      buttonType="quaternary"
      sx={{
        borderRadius: (theme) => +theme.shape.borderRadius * 0.375,
        border: (theme) => `1px solid ${theme.veris.colors.neutrals["grey-light"]}`,
        cursor: "pointer",
        padding: (theme) => theme.spacing(2.3),
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        margin: (theme) => theme.spacing(1, 0),
      }}
      endIcon={<VrsIcon name={IconName.ArrowRight} />}
      onClick={onClick}
      data-test-hook="qol-report-card-btn"
    >
      <Box display="flex" flexDirection="column" gap={1} alignItems="baseline">
        <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
          <Typography
            variant="bodySemibold"
            color={(theme) => theme.veris.colors.neutrals["grey-4"]}
            mr={1}
            data-test-hook="qol-report-item-period"
          >
            {reportItem.period}
          </Typography>
          <ReportStatusField status={reportItem.status ?? QOLReportStatusEnum.Complete} />
          {reportItem.score?.eq5d_index && <ReportIndex index={reportItem.score?.eq5d_index} />}
          <ReportScoreField score={reportItem.score?.wellbeing_classification} />
        </Box>
        <Typography
          variant="subtitle2"
          color={(theme) => theme.veris.colors.neutrals["grey-4"]}
          data-test-hook="qol-report-item-date-updated"
        >
          {reportItem.date_updated &&
            utcToLocal(reportItem.date_updated).format(
              `${dateFormats.dddd} • ${dateFormats["HH:mm"]} a`,
            )}
        </Typography>
      </Box>
    </VrsButton>
  );
}
