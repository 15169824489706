import React, { useCallback, useEffect, useState } from "react";
import Table from "@mui/material/Table";
import Typography from "@mui/material/Typography";
import {
  Box,
  Paper,
  TableHead,
  TableRow,
  TableSortLabel,
  Theme,
  useMediaQuery,
} from "@mui/material";
import dayjs from "dayjs";
import { round } from "lodash";
import { TableVirtuoso } from "react-virtuoso";
import { IconName, VrsIcon, VrsTooltip, verisColors } from "@veris-health/web-core";
import { PatientStatusEnumAPI } from "@veris-health/user-ms/lib/v1";
import { VrsPatientTags } from "../../VrsPatientTags";
import { StyledTableBody, StyledTableCell } from "../shared/StyledTableElements";
import { VrsActiveSymptoms, VrsPatientInfo } from "../../../../features/shared/interfaces";
import { VrsPatientInfoCard } from "../../VrsPatientInfoCard";
import { VrsQualityOfLife } from "../../VrsQualityOfLife";
import { VrsSymptoms } from "../shared/VrsSymptoms";
import { Column, TableColumnNames } from "../../VrsSortedTableHeader";
import {
  QofLScoreExplanation,
  QofLTrendExplanation,
} from "../../QOLScoreExplanation/QofLScoreExplanation";
import { VrsQofLTrend } from "../../VrsQofLTrend";
import { AdherenceChart } from "../../AdherenceChart";
import {
  DASHBOARD_DIRECTION,
  DASHBOARD_FIELD,
  genericSorter,
  Order,
} from "../../../../utils/sorting";
import { RPMChart } from "../../RPMChart";

export interface VrsPatientDetailsTableProps {
  data: VrsPatientInfo[];
  onPatientClick: (id: number) => void;
}
const columns: Column[] = [
  {
    id: TableColumnNames.PatientDetails,
    label: TableColumnNames.PatientDetails,
    orderBy: "name",
    width: "17vw",
  },
  {
    id: TableColumnNames.QOL,
    label: TableColumnNames.QOL,
    orderBy: "qualityOfLife.eq5d_index",
  },
  {
    id: TableColumnNames.AdherenceChart,
    label: TableColumnNames.AdherenceChart,
    orderBy: "ingestionData.days_of_readings",
  },
  {
    id: TableColumnNames.ClinicalTime,
    label: TableColumnNames.ClinicalTime,
    orderBy: "rpm_seconds",
  },
  {
    id: TableColumnNames.Symptoms,
    label: TableColumnNames.Symptoms,
    orderBy: "activeSymptoms.latestSymptomTimeline.name",
  },
  {
    id: TableColumnNames.Tags,
    label: TableColumnNames.Tags,
    hideOnSmallScreen: true,
  },
];

const tooltipContent = [
  { id: TableColumnNames.QOL, content: <QofLScoreExplanation /> },
  {
    id: TableColumnNames.QofLTrend,
    content: <QofLTrendExplanation />,
  },
];

export interface SortableVirtuosoTableHeaderProps {
  headerColumns: Column[];
  setOrderBy: (field: string) => void;
  setOrder: (direction: Order) => void;
  order: Order;
  orderBy: string;
  styles?: React.CSSProperties;
  isSmallScreen: boolean;
}

function SortableVirtuosoTableHeader({
  headerColumns,
  order,
  orderBy,
  setOrderBy,
  setOrder,
  isSmallScreen,
}: SortableVirtuosoTableHeaderProps): JSX.Element {
  return (
    <TableRow>
      {headerColumns.map((column) => (
        <React.Fragment key={column.id}>
          {column.hideOnSmallScreen && isSmallScreen ? (
            <></>
          ) : (
            <StyledTableCell
              sx={{
                borderBottom: (theme) =>
                  `1px solid ${theme.veris.colors.neutrals["grey-2"]} !important`,
                // Adding width to prevent blikining caused by the virutal table inability to predict the max width
                // of the incoming content,therefore the header blinks while scrolling
                width: column.width,
                maxWidth: column.width,
              }}
            >
              <TableSortLabel
                hideSortIcon={!column.orderBy}
                active={orderBy === column.orderBy}
                direction={orderBy === column.orderBy ? order : "asc"}
                onClick={() => {
                  if (column.orderBy) {
                    setOrderBy(column.orderBy);
                    setOrder(order === "asc" ? "desc" : "asc");
                  }
                }}
                style={{
                  cursor: column.orderBy ? "pointer" : "default",
                }}
              >
                <VrsTooltip
                  bcgcolor={verisColors.neutrals.black}
                  title={
                    (tooltipContent?.find((el) => el.id === column.id) &&
                      tooltipContent?.find((el) => el.id === column.id)?.content) ||
                    ""
                  }
                  placement="bottom"
                >
                  <Typography
                    variant="subtitle24"
                    component="span"
                    pt={0.5}
                    sx={{
                      textTransform: "uppercase",
                      fontWeight: 500,
                      color: (theme) => theme.veris.colors.neutrals["grey-3"],
                    }}
                  >
                    {column.label}
                  </Typography>
                </VrsTooltip>
              </TableSortLabel>
            </StyledTableCell>
          )}
        </React.Fragment>
      ))}
    </TableRow>
  );
}

export interface PatientRowProps {
  user: VrsPatientInfo;
  onPatientClick: (id: number) => void;
  isSmallScreen: boolean;
}

const PatientRow = ({ user, onPatientClick, isSmallScreen }: PatientRowProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [activePatientId, setActivePatientId] = React.useState<number | undefined>(undefined);

  const handleQOlTrendClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>, userId: number) => {
      if (event.currentTarget) setAnchorEl(event.currentTarget);
      setActivePatientId(userId);
    },
    [],
  );

  const handleQolTrendClose = useCallback(() => {
    setAnchorEl(null);
    setActivePatientId(undefined);
  }, []);

  return (
    <React.Fragment key={user.id}>
      {columns.map((column) =>
        column.hideOnSmallScreen && isSmallScreen ? (
          <></>
        ) : (
          <StyledTableCell
            sx={{
              borderBottom: (theme) =>
                `1px solid ${theme.veris.colors.neutrals["grey-2"]} !important`,
              width: column.width,
              maxWidth: column.width,
            }}
            key={column.id}
          >
            {column.id === TableColumnNames.PatientDetails && (
              <VrsPatientInfoCard
                patientInfo={user}
                backgroundColor={verisColors.neutrals.white}
                onClick={onPatientClick}
                displayBattery
              />
            )}
            {column.id === TableColumnNames.QOL && (
              <>
                {user.isInactiveOrDeceased ? (
                  <Box display="flex" flexDirection="column">
                    <Typography
                      color={(theme) => theme.veris.colors.neutrals["grey-3"]}
                      variant="captionSemibold"
                    >
                      {user.patientStatus === PatientStatusEnumAPI.Inactive
                        ? "Patient Inactive."
                        : "Patient Deceased"}
                    </Typography>
                    <Typography
                      color={(theme) => theme.veris.colors.neutrals["grey-3"]}
                      variant="caption"
                    >
                      No data available.
                    </Typography>
                  </Box>
                ) : (
                  <Box>
                    <VrsQualityOfLife scoreItem={user.qualityOfLife} />
                    {user.qualityOfLifeTrend && (
                      <Typography
                        color={(theme) => theme.veris.colors.amethyst.normal}
                        sx={{ cursor: "pointer", marginTop: 0.5, display: "flex" }}
                        variant="captionSemibold"
                        onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                          handleQOlTrendClick(event, user.id)
                        }
                      >
                        Trend
                        <VrsIcon
                          name={IconName.ArrowRight}
                          size={14}
                          stroke={verisColors.amethyst.normal}
                        />
                      </Typography>
                    )}
                    {user.qualityOfLifeTrend && anchorEl && activePatientId === user.id && (
                      <VrsQofLTrend
                        data={
                          user.qualityOfLifeTrend
                            ? user?.qualityOfLifeTrend.map((el) => {
                                return {
                                  ...el,
                                  dt: dayjs(el.timestamp).unix(),
                                  eq5d_index: round(el.eq5d_index * 100, 1),
                                };
                              })
                            : []
                        }
                        open={!!anchorEl}
                        toggleDialog={handleQolTrendClose}
                        anchorEl={anchorEl}
                      />
                    )}
                  </Box>
                )}
              </>
            )}
            {column.id === TableColumnNames.AdherenceChart &&
              (user.isInactiveOrDeceased ? (
                <Box display="flex" flexDirection="column">
                  <Typography
                    color={(theme) => theme.veris.colors.neutrals["grey-3"]}
                    variant="captionSemibold"
                  >
                    {user.patientStatus === PatientStatusEnumAPI.Inactive
                      ? "Patient Inactive."
                      : "Patient Deceased"}
                  </Typography>
                  <Typography
                    color={(theme) => theme.veris.colors.neutrals["grey-3"]}
                    variant="caption"
                  >
                    No data available.
                  </Typography>
                </Box>
              ) : (
                <AdherenceChart ingestionData={user.ingestionData} />
              ))}
            {column.id === TableColumnNames.ClinicalTime &&
              (user.isInactiveOrDeceased ? (
                <Box display="flex" flexDirection="column">
                  <Typography
                    color={(theme) => theme.veris.colors.neutrals["grey-3"]}
                    variant="captionSemibold"
                  >
                    {user.patientStatus === PatientStatusEnumAPI.Inactive
                      ? "Patient Inactive."
                      : "Patient Deceased"}
                  </Typography>
                  <Typography
                    color={(theme) => theme.veris.colors.neutrals["grey-3"]}
                    variant="caption"
                  >
                    No data available.
                  </Typography>
                </Box>
              ) : (
                <RPMChart timePassed={user.rpmSeconds || 0} />
              ))}{" "}
            {column.id === TableColumnNames.Symptoms &&
              (user.isInactiveOrDeceased ? (
                <Box display="flex" flexDirection="column">
                  <Typography
                    color={(theme) => theme.veris.colors.neutrals["grey-3"]}
                    variant="captionSemibold"
                  >
                    {user.patientStatus === PatientStatusEnumAPI.Inactive
                      ? "Patient Inactive."
                      : "Patient Deceased"}
                  </Typography>
                  <Typography
                    color={(theme) => theme.veris.colors.neutrals["grey-3"]}
                    variant="caption"
                  >
                    No data available.
                  </Typography>
                </Box>
              ) : (
                <VrsSymptoms symptoms={user.activeSymptoms as VrsActiveSymptoms} />
              ))}
            {column.id === TableColumnNames.Tags && user.tags && !isSmallScreen && (
              <VrsPatientTags
                patientTags={user.tags}
                patientId={user.id}
                patientHospitalId={user.hospital?.id}
                patientName={user.name}
                inactive={user.isInactiveOrDeceased}
              />
            )}
          </StyledTableCell>
        ),
      )}
    </React.Fragment>
  );
};

const VrsPatientDetailsTable = ({
  data,
  onPatientClick,
}: VrsPatientDetailsTableProps): JSX.Element => {
  const rows = [...data];
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("xl"));
  const [sortedPatients, setSortedPatients] = useState(data);
  const initialDirection = (localStorage.getItem(DASHBOARD_DIRECTION) as Order) || "asc";
  const initialField = localStorage.getItem(DASHBOARD_FIELD) || "";
  const [order, setOrder] = React.useState<Order>(initialDirection);
  const [orderBy, setOrderBy] = React.useState(initialField);
  const [isScrolling, setIsScrolling] = useState<boolean>(false);
  const [bottomReached, setBottomReached] = useState<boolean>(false);

  useEffect(() => {
    setSortedPatients(genericSorter(sortedPatients, order, orderBy as keyof VrsPatientInfo));
  }, [order, orderBy]);

  useEffect(() => {
    if (initialDirection && initialField) {
      setSortedPatients(genericSorter(data, order, orderBy as keyof VrsPatientInfo));
    } else setSortedPatients(data);
  }, [data]);

  const onSetOrder = (direction: Order) => {
    localStorage.setItem(DASHBOARD_DIRECTION, direction);
    setOrder(direction);
  };
  const onSetOrderBy = (field: string) => {
    localStorage.setItem(DASHBOARD_FIELD, field);
    setOrderBy(field);
  };

  return (
    <Box
      id="vrs-patient-details-table"
      sx={{
        overflow: "scroll",
        position: "relative",
      }}
    >
      {data && (
        <Paper
          sx={{
            boxShadow: "none",
            "&::after":
              isScrolling && !bottomReached
                ? {
                    content: '"Loading..."',
                    width: "100%",
                    height: "40px",
                    position: "absolute",
                    bottom: 0,
                    zIndex: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "white",
                    color: "gray",
                  }
                : { content: '""' },
          }}
        >
          <TableVirtuoso
            style={{
              height: "70vh",
              border: `1px solid ${verisColors.neutrals["grey-2"]}`,
              borderRadius: "6px",
            }}
            atBottomStateChange={(atBottom) => setBottomReached(atBottom)}
            data={sortedPatients}
            isScrolling={setIsScrolling}
            components={{
              Table: (props) => (
                <Table
                  {...props}
                  sx={{
                    borderCollapse: "separate",
                    tableLayout: "fixed",
                  }}
                />
              ),
              TableHead: React.forwardRef((props, ref) => (
                <TableHead
                  {...props}
                  ref={ref}
                  sx={{
                    backgroundColor: (theme) => theme.veris.colors.neutrals.white,
                    borderBottom: (theme) =>
                      `1px solid ${theme.veris.colors.neutrals["grey-2"]} !important`,
                    top: "-1px",
                  }}
                  data-test-hook="vrsSortedTableHeader-element"
                />
              )),
              TableRow,
              TableBody: React.forwardRef((props, ref) => <StyledTableBody {...props} ref={ref} />),
            }}
            fixedHeaderContent={() => (
              <SortableVirtuosoTableHeader
                headerColumns={columns}
                order={order}
                orderBy={orderBy}
                setOrder={(direction) => onSetOrder(direction)}
                setOrderBy={(field) => onSetOrderBy(field)}
                isSmallScreen={isSmallScreen}
              />
            )}
            itemContent={(index, user) => (
              <PatientRow
                user={user}
                onPatientClick={onPatientClick}
                isSmallScreen={isSmallScreen}
                key={user.id}
              />
            )}
          />
        </Paper>
      )}

      {rows.length === 0 && (
        <Typography
          color={(theme) => theme.veris.colors.neutrals["grey-3"]}
          textAlign="center"
          variant="body"
          p={2}
          sx={{
            borderColor: (theme) => theme.veris.colors.neutrals["grey-light"],
            borderStyle: "solid",
            borderWidth: "0px 1px 1px 1px",
          }}
        >
          There are no patients at the moment.
        </Typography>
      )}
    </Box>
  );
};
export default VrsPatientDetailsTable;
