import { Typography, Box } from "@mui/material";
import React from "react";
import { VrsButton } from "@veris-health/web-core";

interface EventFooterProps {
  isPassedDate: boolean;
  editMode: boolean;
  cancelEditMode: () => void;
  onCancel: () => void;
  onClose: () => void;
  disableEditButton: boolean;
  confirmed: boolean;
  setEditMode: () => void;
  onDeclineProposedEvent: () => void;
  onAcceptProposedEvent: () => void;
  editable: boolean;
  needsAction: boolean;
}

export const EventFooter = ({
  isPassedDate,
  editMode,
  cancelEditMode,
  onCancel,
  disableEditButton,
  onClose,
  confirmed,
  setEditMode,
  onAcceptProposedEvent,
  onDeclineProposedEvent,
  editable,
  needsAction,
}: EventFooterProps): JSX.Element => {
  if (editMode)
    return (
      <Box display="flex" gap={1} alignItems="center">
        <VrsButton buttonType="primary" onClick={cancelEditMode}>
          Cancel
        </VrsButton>
        <VrsButton buttonType="primary" type="submit" disabled={disableEditButton}>
          Save
        </VrsButton>
      </Box>
    );
  if (isPassedDate) return <></>;

  if (!confirmed && needsAction)
    return (
      <Box display="flex" gap={1} alignItems="center" justifyContent="space-between" width="100%">
        {editable && (
          <Box>
            <Typography
              variant="subtitle2"
              color={(theme) => theme.veris.colors.amethyst.normal}
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={setEditMode}
            >
              Propose new time
            </Typography>
          </Box>
        )}
        <Box display="flex" gap={2}>
          <VrsButton buttonType="primary" onClick={onDeclineProposedEvent}>
            Decline
          </VrsButton>
          <VrsButton buttonType="primary" onClick={onAcceptProposedEvent}>
            Accept
          </VrsButton>
        </Box>
      </Box>
    );
  return (
    <Box display="flex" gap={1} alignItems="center">
      <Typography variant="subtitle2" color={(theme) => theme.veris.colors.neutrals["grey-4"]}>
        Do you want to cancel this appointment?
      </Typography>
      <VrsButton buttonType="primary" onClick={onClose}>
        No
      </VrsButton>
      <VrsButton buttonType="primary" onClick={onCancel}>
        Yes
      </VrsButton>
    </Box>
  );
};
