import { Box, CircularProgress, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import dayjs from "dayjs";
import { MbscCalendarEvent } from "@mobiscroll/react";
import { VrsErrorMsg, dateFormats } from "@veris-health/web-core";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import { loadPastAppointmentsAsync } from "../../notificationsSlice";
import { VrsPastAppointmentsOrSymptoms } from "../../../../ui/components/VrsPastAppointments";
import { useAppSelector } from "../../../../hooks/useAppSelector";

interface PastAppointmentsProps {
  patientId: number;
  patientName?: string;
}

export function PastAppointments({ patientId, patientName }: PastAppointmentsProps): JSX.Element {
  const dispatch = useAppDispatch();

  const status = useAppSelector((state) => state.notifications.pastAppointmentsStatus);
  const pastAppointments = useAppSelector((state) => state.notifications.pastAppointments);

  useEffect(() => {
    if (!pastAppointments[patientId]) {
      dispatch(
        loadPastAppointmentsAsync({
          userId: patientId,
        }),
      );
    }
  }, [dispatch, patientId]);

  return (
    <Box paddingX={1.7} marginBottom={1}>
      <Grid display="flex" justifyContent="space-between" alignItems="baseline">
        <Typography marginBottom={1} variant="h6">
          Past Appointments with {patientName}
        </Typography>
      </Grid>
      <Box mt={1}>
        {status === "loading" && (
          <Box display="flex" justifyContent="center" alignItems="center">
            <CircularProgress />
          </Box>
        )}
        {status === "failed" && (
          <Box
            display="flex"
            sx={{
              justifyContent: "center",
              paddingBlock: "6.25rem",
              backgroundColor: (theme) => `2px solid ${theme.veris.colors.neutrals.white}`,
            }}
          >
            <VrsErrorMsg
              size="small"
              onClick={() => {
                dispatch(
                  loadPastAppointmentsAsync({
                    userId: patientId,
                  }),
                );
              }}
            />
          </Box>
        )}
        {status === "idle" && (
          <>
            {pastAppointments && pastAppointments[patientId]?.length === 0 && (
              <Typography
                color={(theme) => theme.veris.colors.neutrals["grey-3"]}
                textAlign="center"
                variant="body"
              >
                There are no Past Appointments
              </Typography>
            )}
            <Stack
              px={1.45}
              sx={{
                borderBottom: (theme) => `1px solid ${theme.veris.colors.neutrals["grey-light"]}`,
              }}
              borderRadius={2}
              maxHeight="248px"
              overflow="scroll"
            >
              {pastAppointments &&
                pastAppointments[patientId]?.map((appointment: MbscCalendarEvent) => (
                  <VrsPastAppointmentsOrSymptoms
                    key={appointment.id}
                    name={appointment.title}
                    date={dayjs(appointment.date as string).format(dateFormats["MM/DD"])}
                    notes=""
                  />
                ))}
            </Stack>
          </>
        )}
      </Box>
    </Box>
  );
}
