import {
  GetDiagnosticReportObsResponse,
  GetObservationsToCompareResponse,
} from "@veris-health/med-data-ms/lib/v1";
import { fetchLabResultsComparisonData, fetchLabResultsDetailedList } from "../patientRecordsApi";

export const getLabResultsComparison = async (
  patientId: number,
  date: string,
  codes: string[],
  numberOfObservations?: number,
): Promise<GetObservationsToCompareResponse> => {
  const response = await fetchLabResultsComparisonData(
    patientId,
    date,
    codes,
    numberOfObservations,
  );
  const mappedObservations = response.observations.map((observation) => {
    if (observation.value && !Number.isNaN(Number(observation.value))) {
      return {
        ...observation,
        value: Number(observation.value).toFixed(1),
      };
    }
    return observation;
  });
  return { observations: mappedObservations };
};

export const fetchLabResultsDetails = async (
  patientId: number,
  reportId: string,
): Promise<GetDiagnosticReportObsResponse> => {
  const response = await fetchLabResultsDetailedList(patientId, reportId);
  const mappedObservations = response.observations.map((observation) => {
    if (observation.value && !Number.isNaN(Number(observation.value))) {
      return {
        ...observation,
        value: Number(observation.value).toFixed(1),
      };
    }
    return observation;
  });
  return { ...response, observations: mappedObservations } as GetDiagnosticReportObsResponse;
};
