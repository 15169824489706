import { Box, CircularProgress, styled, Typography } from "@mui/material";
import { VrsErrorMsg } from "@veris-health/web-core";
import React from "react";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { useCurrentPatient } from "../../../../hooks/useCurrentPatient";
import { GoalsType, selectPatientGoals, selectPatientGoalsStatus } from "../../patientDetailsSlice";
import { TreatmentGoalsTable } from "../TreatmentGoalsTable";

export interface PatientTreatmentGoalsProps {
  fetchTreatmentGoals: () => void;
}

interface GoalsCategoryProps {
  therapy: GoalsType[];
  other: GoalsType[];
}

const StyledGoalsBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  marginTop: theme.spacing(2),
  backgroundColor: theme.veris.colors.neutrals.white,
  border: `1px solid ${theme.veris.colors.neutrals["grey-2"]}`,
  borderRadius: +theme.shape.borderRadius * 1.5,
}));

export function PatientTreatmentGoals({
  fetchTreatmentGoals,
}: PatientTreatmentGoalsProps): JSX.Element {
  const patient = useCurrentPatient();
  const patientGoals = useAppSelector((state) => selectPatientGoals(state, patient && patient.id));
  const patientGoalsStatus = useAppSelector(selectPatientGoalsStatus);

  const goalsCategory = patientGoals?.reduce(
    (goals, goal) => {
      const category = goal.category?.group === "Goals of Therapy" ? "therapy" : "other";
      goals[category].push(goal);
      return goals;
    },
    { therapy: [], other: [] } as GoalsCategoryProps,
  );

  return (
    <>
      {patientGoalsStatus === "loading" && (
        <Box display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      )}
      {patientGoalsStatus === "failed" && (
        <Box display="flex" justifyContent="center" alignItems="center">
          <VrsErrorMsg size="small" onClick={fetchTreatmentGoals} />
        </Box>
      )}
      {patientGoalsStatus === "idle" && (
        <>
          {goalsCategory &&
            Object.keys(goalsCategory).map((goalCategory) => (
              <StyledGoalsBox key={goalCategory}>
                <Box display="flex" mt={1} mb={1.5} p={0.5}>
                  <Typography variant="h6" fontWeight={600}>
                    {goalCategory === "therapy" ? "Goals of Therapy" : "Other Goals"}
                  </Typography>
                </Box>
                {goalsCategory[goalCategory as keyof typeof goalsCategory].length > 0 ? (
                  <TreatmentGoalsTable
                    goals={goalsCategory[goalCategory as keyof typeof goalsCategory]}
                  />
                ) : (
                  <Typography
                    color={(theme) => theme.veris.colors.neutrals["grey-3"]}
                    textAlign="center"
                    variant="body"
                    p={2}
                    sx={{
                      borderColor: (theme) => theme.veris.colors.neutrals["grey-light"],
                      borderStyle: "solid",
                      borderWidth: "1px 0px 0px 0px",
                    }}
                  >
                    There are no {goalCategory === "therapy" ? "goals of therapy" : "other goals"}{" "}
                    at this moment.
                  </Typography>
                )}
              </StyledGoalsBox>
            ))}
        </>
      )}
    </>
  );
}
