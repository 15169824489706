import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MbscCalendarEvent } from "@mobiscroll/react";
import { RootState } from "../../store";
import { VrsPatientAppointmentDetailsProps } from "../../ui/components/VrsPatientAppointmentDetails";
import { fetchCalendarEventsAsync } from "./api/dashboardApi";
import { Status } from "../shared/interfaces";
import { localizedLogout, logout } from "../shared/slices/authSlice";

export type Appointments = VrsPatientAppointmentDetailsProps[];

export interface DashboardState {
  page: number;
  rowsPerPage: number;
  nextAppointments: {
    appointments: MbscCalendarEvent[];
    status: Status;
  };
  status: Status;
}

const initialState: DashboardState = {
  page: 0,
  rowsPerPage: 10,
  nextAppointments: {
    appointments: [],
    status: "idle",
  },
  status: "idle",
};

export const dashboardSlice = createSlice({
  name: "Dashboard",
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setRowsPerPage: (state, action: PayloadAction<number>) => {
      state.page = 0;
      state.rowsPerPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCalendarEventsAsync.pending, (state) => {
        state.nextAppointments.status = "loading";
      })
      .addCase(fetchCalendarEventsAsync.fulfilled, (state, action) => {
        state.nextAppointments.status = "idle";
        state.nextAppointments.appointments = action.payload as MbscCalendarEvent[];
      })
      .addCase(fetchCalendarEventsAsync.rejected, (state) => {
        state.nextAppointments.status = "failed";
      })
      .addCase(logout, () => {
        return initialState;
      })
      .addCase(localizedLogout, () => {
        return initialState;
      });
  },
});

export const { setPage, setRowsPerPage } = dashboardSlice.actions;

export const selectDashboard = ({ dashboard }: RootState): DashboardState => dashboard;

export const selectPage = ({ dashboard }: RootState): number => dashboard.page;

export const selectRowsPerPage = ({ dashboard }: RootState): number => dashboard.rowsPerPage;

export const selectAppointments = ({ dashboard }: RootState): MbscCalendarEvent[] =>
  dashboard.nextAppointments.appointments;

export const selectIsAppointmentsError = ({ dashboard }: RootState): boolean =>
  dashboard.nextAppointments.status === "failed";

export const selectAppointmentsStatus = ({ dashboard }: RootState): Status =>
  dashboard.nextAppointments.status;

export default dashboardSlice.reducer;
