import { MbscCalendarLabel } from "@mobiscroll/react/dist/src/core/shared/calendar-view/calendar-view";
import { Box, Typography } from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import { verisColors } from "@veris-health/web-core";
import { getShortName } from "../../../utils/helpers";
import { getEventBorderColor } from "./RenderScheduleEvent";

export const eventToColorMap: Record<AppointmentType, string> = {
  Video: verisColors.neutrals.white,
  Clinic: verisColors.moderate.soft,
};

const getEventBackgroundColorMonthlyView = (
  isPassedDate: boolean,
  isConfirmed: boolean,
  type: string,
) => {
  if (isPassedDate) return verisColors.neutrals["grey-3"];
  if (!isConfirmed) return verisColors.neutrals["grey-5"];
  return eventToColorMap[type as AppointmentType];
};

interface RenderLabelProps {
  data: MbscCalendarLabel;
}

export type AppointmentType = "Video" | "Clinic";

export const RenderLabel = ({ data }: RenderLabelProps): JSX.Element => {
  const isPassDate = dayjs(data.original.end).isBefore(dayjs());

  return (
    <div style={{ overflow: "hidden" }}>
      <Typography
        sx={{
          background: getEventBackgroundColorMonthlyView(
            isPassDate,
            data.original?.confirmed,
            data.original?.type,
          ),
          border: getEventBorderColor(
            isPassDate,
            data.original?.outOfPlatform,
            data.original?.confirmed,
            true,
          ),

          width: (theme) => theme.spacing(0.85),
          height: (theme) => theme.spacing(0.85),
          borderRadius: "50%",
          position: "absolute",
          top: (theme) => theme.spacing(0.7),
        }}
      />
      <Box sx={{ paddingLeft: (theme) => theme.spacing(1.2) }}>
        <Typography
          variant="caption"
          color={
            data.original?.confirmed ? verisColors.neutrals.black : verisColors.amethyst.normal
          }
          fontWeight={600}
        >
          {`${data.start} `}
        </Typography>
        <Typography
          variant="caption"
          color={
            data.original?.confirmed ? verisColors.neutrals["grey-4"] : verisColors.amethyst.normal
          }
          fontWeight={600}
          mr={0.25}
        >
          {data.original.type}
        </Typography>
        <Typography
          variant="caption"
          color={
            data.original?.confirmed ? verisColors.neutrals["grey-4"] : verisColors.amethyst.normal
          }
          style={{
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
        >
          {getShortName(data.original.patient)}
        </Typography>
      </Box>
    </div>
  );
};
