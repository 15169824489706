import React from "react";
import { Route, RouteProps, Redirect } from "react-router-dom";
import { Routes } from "../routes-config";
import { useAppSelector } from "../hooks/useAppSelector";
import { selectIsLoggedIn } from "../features/shared/slices/authSlice";

const VrsPrivateRoute = ({ children, ...rest }: RouteProps): JSX.Element => {
  const isLoggedIn = useAppSelector(selectIsLoggedIn);
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoggedIn ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: Routes.STAFF_LOGIN,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export default VrsPrivateRoute;
