import Box from "@mui/material/Box";
import { capitalize } from "lodash";
import { AttachmentMetadata, ThreadParticipantInfo } from "@veris-health/communication-ms/lib/v1";
import React from "react";
import { CommunicationUserIdentifier } from "@azure/communication-common";
import { VrsChatTextMessage } from "../VrsChatTextMessage";
import {
  VrChatHistoryAttachment,
  VrsChatHistoryMessage,
  VrsChatMessage,
  VrsFileAttachment,
} from "../../store";

export interface ChatMessageProps {
  message: VrsChatMessage | VrsChatHistoryMessage;
  myAzureChatUserId: string;
  messageSender: ThreadParticipantInfo;
  handleDownload: ({
    metadata,
    messageId,
  }: {
    metadata: AttachmentMetadata;
    messageId: string;
  }) => void;
  attachment?: VrsFileAttachment | VrChatHistoryAttachment;
}

export const ChatMessage = ({
  message,
  messageSender,
  myAzureChatUserId,
  handleDownload,
  attachment,
}: ChatMessageProps): JSX.Element => {
  const { sender, id, type, content, metadata, createdOn } = message;
  const { communicationUserId } = sender as CommunicationUserIdentifier;

  return (
    <Box key={id} py={1}>
      {type === "text" && (
        <VrsChatTextMessage
          staffData={{
            name: messageSender?.display_name ?? "Unknown",
            role: messageSender?.user_type
              ? capitalize(messageSender.user_type.split("_").join(" "))
              : "",
          }}
          text={(content && content.message) || ""}
          metadata={metadata as unknown as AttachmentMetadata}
          currentUserMsg={myAzureChatUserId === communicationUserId}
          timestamp={createdOn}
          isPrivateChat
          imageUrl={messageSender?.image && `data:image/png;base64,${messageSender.image}`}
          onAttachmentDownload={() =>
            handleDownload({
              metadata: metadata as unknown as AttachmentMetadata,
              messageId: id,
            })
          }
          attachment={attachment}
        />
      )}
    </Box>
  );
};
