import { VrsButton, VrsDialog, VrsSelect } from "@veris-health/web-core";
import React, { useState } from "react";
import { Box } from "@mui/material";
import { PatientStatusEnumAPI } from "@veris-health/user-ms/lib/v2";
import { PatientInfoLabel } from "../../../../ui/components/PatientInfoLabel";
import { PatientStatusesMap } from "../../../../constants";

interface StatusEditProps {
  open: boolean;
  onClose: () => void;
  onSave: (status: PatientStatusEnumAPI) => void;
  currentStatus?: PatientStatusEnumAPI;
}

const StatusEdit = ({ open, onClose, onSave, currentStatus }: StatusEditProps): JSX.Element => {
  const [status, setStatus] = useState<PatientStatusEnumAPI | undefined>(currentStatus);

  const statusOptions = [
    { label: "Choose status", value: "" },
    ...Object.values(PatientStatusEnumAPI).map((value) => ({
      label: PatientStatusesMap[value],
      value,
    })),
  ];
  return (
    <VrsDialog open={open} title="Edit Status" onClose={onClose} width="450px">
      <Box display="flex" alignItems="center">
        <PatientInfoLabel fieldName="Status" />
        <VrsSelect
          options={statusOptions}
          onSelected={(value) => setStatus(value as PatientStatusEnumAPI)}
          value={status}
          maxHeight="250px"
          fullWidth
          outerSx={{ width: "100%" }}
        />
      </Box>
      <Box mt={2} display="flex" gap={2} justifyContent="flex-end">
        <VrsButton buttonType="secondary" onClick={onClose}>
          Cancel
        </VrsButton>
        <VrsButton buttonType="primary" disabled={!status} onClick={() => status && onSave(status)}>
          Confirm
        </VrsButton>
      </Box>
    </VrsDialog>
  );
};

export default StatusEdit;
