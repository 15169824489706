import React from "react";
import {
  styled,
  Table,
  TableContainer,
  tableContainerClasses,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { verisColors, dateFormats } from "@veris-health/web-core";
import { AllergyClinicalStatusEnum, GetAllergyResponse } from "@veris-health/med-data-ms/lib/v1";
import { StyledTableBody, StyledTableCell, StyledTableRow } from "../shared/StyledTableElements";
import { EMPTY_DATASET_MESSAGE } from "../../../../constants";

export interface VrsAllergiesTableProps {
  data: GetAllergyResponse[];
}

const enum TableColumnNames {
  Allergen = "Allergic To",
  AllergyType = "Allergy Type",
  DateRecorded = "Date Recorded",
  Status = "Status",
  CurrentMeds = "Current Meds",
}

interface Column {
  id: TableColumnNames;
  label: TableColumnNames;
}

const columns: Column[] = [
  { id: TableColumnNames.Allergen, label: TableColumnNames.Allergen },
  { id: TableColumnNames.AllergyType, label: TableColumnNames.AllergyType },
  { id: TableColumnNames.DateRecorded, label: TableColumnNames.DateRecorded },
  { id: TableColumnNames.Status, label: TableColumnNames.Status },
];

const statusColor = {
  [AllergyClinicalStatusEnum.Active]: verisColors.moderate.light,
  [AllergyClinicalStatusEnum.Inactive]: verisColors.neutrals["grey-2"],
  [AllergyClinicalStatusEnum.Resolved]: verisColors.amethyst.light,
};

export const StyledTableContainer = styled(TableContainer)(() => ({
  [`&.${tableContainerClasses.root}`]: {
    maxHeight: "70vh",
    overflowY: "scroll",
  },
}));

export function VrsAllergiesTable({ data }: VrsAllergiesTableProps): JSX.Element {
  return (
    <StyledTableContainer data-test-hook="allergies-table-container">
      <Table>
        <TableHead
          sx={{
            borderBottom: (theme) => `2px solid ${theme.veris.colors.neutrals["grey-light"]}`,
            marginBottom: (theme) => theme.spacing(1),
          }}
        >
          <TableRow>
            {columns.map((column) => (
              <StyledTableCell key={column.id}>
                <Typography
                  variant="bodyMedium"
                  component="span"
                  sx={{
                    textTransform: "uppercase",
                    color: (theme) => theme.veris.colors.neutrals["grey-mid"],
                  }}
                >
                  {column.label}
                </Typography>
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <StyledTableBody>
          {data.map((allergy: GetAllergyResponse) => (
            <StyledTableRow key={allergy.id}>
              {columns.map((column) => {
                return (
                  <StyledTableCell key={column.id}>
                    {column.id === TableColumnNames.Allergen && (
                      <Typography variant="subtitle1" color={verisColors.neutrals["grey-4"]}>
                        {allergy.allergen || "Not on file"}
                      </Typography>
                    )}
                    {column.id === TableColumnNames.AllergyType && (
                      <Typography
                        variant="bodyMedium"
                        component="span"
                        sx={{ textTransform: "capitalize", fontWeight: "regular" }}
                      >
                        {allergy.type || "N/A"}
                      </Typography>
                    )}
                    {column.id === TableColumnNames.DateRecorded && (
                      <Typography variant="button" component="span" sx={{ textTransform: "unset" }}>
                        {allergy.recordedDateTime
                          ? dayjs(allergy.recordedDateTime).format(dateFormats["MMM D, YYYY"])
                          : "N/A"}
                      </Typography>
                    )}
                    {column.id === TableColumnNames.Status && (
                      <Typography
                        variant="button"
                        component="div"
                        sx={{
                          textTransform: "unset",
                          backgroundColor: statusColor[allergy.clinicalStatus || "active"],
                          padding: (theme) => theme.spacing(0.625, 0.75),
                          margin: (theme) => theme.spacing(1.7, 0),
                          width: "4.125rem",
                          display: "flex",
                          justifyContent: "center",
                          color:
                            allergy.clinicalStatus === AllergyClinicalStatusEnum.Inactive
                              ? verisColors.neutrals["grey-3"]
                              : verisColors.neutrals.black,
                          borderRadius: (theme) => +theme.shape.borderRadius * 0.25,
                        }}
                      >
                        {allergy.clinicalStatus || AllergyClinicalStatusEnum.Active}
                      </Typography>
                    )}
                  </StyledTableCell>
                );
              })}
            </StyledTableRow>
          ))}
        </StyledTableBody>
      </Table>
      {data.length === 0 && (
        <Typography
          color={(theme) => theme.veris.colors.neutrals["grey-3"]}
          textAlign="center"
          variant="body"
          p={2}
          sx={{
            borderColor: (theme) => theme.veris.colors.neutrals["grey-light"],
            borderStyle: "solid",
            borderWidth: "1px 0px 0px 0px",
          }}
        >
          {EMPTY_DATASET_MESSAGE}
        </Typography>
      )}
    </StyledTableContainer>
  );
}
