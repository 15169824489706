import {
  GetDiagnosticReportObsResponse,
  ConditionResponse,
  SearchAppointmentsResponse,
  AppointmentStatusEnum,
} from "@veris-health/med-data-ms/lib/v1";
import { LimitOffsetPageQOLQuestionnaireResponse } from "@veris-health/virtual-doc-ms/lib/v1";
import {
  appointmentsApiV1,
  conditionsApiV1,
  labResultsApiV1,
  qualityOfLifeApiV1,
} from "../../../api";
import { stripHtml } from "../../../api/stripHtml";

// TO-DO remove filtering when BE provides filtering in the API call
const activeConditionStatuses = ["active", "remission", "recurrence", "relapse"];

export const fetchRecentLabResults = async (
  id: number,
): Promise<GetDiagnosticReportObsResponse> => {
  const response = await labResultsApiV1.getMostRecentDiagnosticReportObservations(id);
  return stripHtml(response.data) as GetDiagnosticReportObsResponse;
};

export const fetchTreatmentPlan = async (id: number): Promise<SearchAppointmentsResponse> => {
  const response = await appointmentsApiV1.getAppointments(
    id,
    new Date().toISOString(),
    undefined,
    [AppointmentStatusEnum.Booked, AppointmentStatusEnum.Proposed],
    1,
  );
  return response.data;
};

export const fetchPatientConditions = async (id: number): Promise<ConditionResponse[]> => {
  const response = await conditionsApiV1.getConditions(id);
  const { conditions }: { conditions: ConditionResponse[] } = stripHtml(response.data);

  // TO-DO filtering to be removed from FE when BE provides filtering
  return (
    conditions?.filter((condition) =>
      activeConditionStatuses.includes(condition?.clinicalStatus || "active"),
    ) || []
  );
};

export const fetchQOLReports = async (
  id: string,
  offset: number,
): Promise<LimitOffsetPageQOLQuestionnaireResponse> => {
  const response = await qualityOfLifeApiV1.getAllQolReports(Number(id), 10, offset);

  return response.data;
};
