import { ContentBlock } from "draft-js";
import { EditorPlugin, PluginFunctions } from "@draft-js-plugins/editor";
import { TextBlock } from "./TextBlock";

export { TextBlock } from "./TextBlock";

const blockTypes = ["unstyled", "header-one", "header-two"];

export default (): EditorPlugin => {
  // eslint-disable-next-line consistent-return
  const blockRendererFn = (contentBlock: ContentBlock, pluginFunctions: PluginFunctions) => {
    const type = contentBlock.getType();

    if (blockTypes.includes(type)) {
      return {
        component: TextBlock,
        editable: !pluginFunctions.getReadOnly(),
        props: { pluginFunctions },
      };
    }
  };
  return {
    blockRendererFn,
  };
};
