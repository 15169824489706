import {
  CallRecordResponse,
  CallRecordActivity,
  UnconfirmedCallInfo,
  ListBillingNotificationsResponse,
  CallRecordBilling,
} from "@veris-health/communication-ms/lib/v1";
import { voipApiV1 } from "../../../../api";

export const addVideoCallRecord = async (
  callRecord: CallRecordActivity,
): Promise<CallRecordResponse> => {
  const response = await voipApiV1.trackCallRecordEvent(callRecord);
  return response.data;
};

export const getBillingCodes = async (): Promise<object> => {
  const response = await voipApiV1.getBillingCodesForVideoCalls();

  return response.data;
};

export const setBillingCode = async (
  userId: number,
  callId: number,
  billing: CallRecordBilling,
): Promise<object> => {
  const response = await voipApiV1.setBillingCodeForCall(userId, callId, billing);

  return response.data;
};

export const getUnconfirmedCallDetails = async (
  userId: number,
  callId: number,
): Promise<UnconfirmedCallInfo> => {
  const response = await voipApiV1.getUnconfirmedCallInfo(userId, callId);

  return response.data;
};

export const getUnconfirmedCallsList = async (
  userId: number,
  offset: number,
): Promise<ListBillingNotificationsResponse> => {
  const response = await voipApiV1.getUnconfirmedCallsNotifications(userId, 10, offset);

  return response.data;
};
