import Box from "@mui/material/Box";
import {
  IconName,
  VrsButton,
  VrsDivider,
  VrsIcon,
  VrsTooltip,
  verisColors,
} from "@veris-health/web-core";
import React, { useEffect, useState } from "react";
import {
  ClinicalTask,
  ClinicalTaskSortingEnum,
  ClinicalTaskStatusEnum,
} from "@veris-health/med-data-ms/lib/v1";
import { useLocation, useParams } from "react-router-dom";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useProfile } from "../../context/profile";
import {
  fetchTasksAsync,
  selectPatientTasks,
  selectPatientTasksStatus,
  selectTasksOrder,
  selectTasksStatus,
  setSortOrder,
  updateTaskAsync,
} from "./tasksSlice";
import { useAppSelector } from "../../hooks/useAppSelector";
import { TasksFilters } from "./components/TasksFilters";
import { filterOptionsEnum } from "./utils/constants";
import { TaskPreview } from "./components/TaskPreview";
import SnackbarUtils from "../../utils/SnackbarUtils";
import { TasksList } from "./components/TasksList";
import CreateTaskContainer from "./components/TaskContainer";
import { UserQueryParams } from "../shared/interfaces";
import useModal from "../../hooks/useModal";
import { ResolveConfirmationModal } from "./components/Modals";

const TasksContainerHeader = ({
  onCreateTask,
  filter,
  setFilter,
  order,
  userId,
}: {
  onCreateTask: () => void;
  filter: filterOptionsEnum;
  setFilter: (filter: filterOptionsEnum) => void;
  order: ClinicalTaskSortingEnum;
  userId: string;
}): JSX.Element => {
  const dispatch = useAppDispatch();
  return (
    <Box
      position="sticky"
      top={0}
      width="100%"
      sx={{
        backgroundColor: (theme) => theme.veris.colors.neutrals.white,
        zIndex: 1000,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between" p={3}>
        <TasksFilters
          filter={filter}
          onFilterChange={setFilter}
          order={order}
          onSort={(selected) => dispatch(setSortOrder(selected as ClinicalTaskSortingEnum))}
          inPatientDetails={!!userId}
        />
        {userId ? (
          <VrsButton buttonType="secondary" onClick={onCreateTask}>
            Create Task
          </VrsButton>
        ) : (
          <VrsTooltip bcgcolor={verisColors.neutrals.black} title="Create Task">
            <Box>
              <VrsButton
                buttonType="primary"
                onClick={onCreateTask}
                sx={{ minWidth: "40px", padding: 0 }}
              >
                <VrsIcon name={IconName.Plus} stroke={verisColors.neutrals.white} />
              </VrsButton>
            </Box>
          </VrsTooltip>
        )}
      </Box>
      <VrsDivider height="80" sx={{ borderWidth: "3px" }} />
    </Box>
  );
};

export function TasksContainer(): JSX.Element {
  const [filter, setFilter] = useState<filterOptionsEnum>("all");
  const [openCreateTask, setOpenCreateTask] = useState<boolean>(false);
  const [selectedTask, setSelectedTask] = useState<ClinicalTask>();
  const dispatch = useAppDispatch();
  const currentUser = useProfile();
  const order = useAppSelector(selectTasksOrder);
  const { userId } = useParams<UserQueryParams>();
  const tasks = useAppSelector((state) => selectPatientTasks(state, userId ? +userId : undefined));
  const [modal, showModal] = useModal();
  const location = useLocation<{ taskId?: number }>();
  const status = useAppSelector(selectTasksStatus);
  const patientStatus = useAppSelector(selectPatientTasksStatus);

  useEffect(() => {
    if (location.state && location.state?.taskId)
      setSelectedTask(tasks?.items?.find((task) => task.id === location.state.taskId));
  }, [location, tasks]);

  const fetchInitialTasks = () => {
    dispatch(
      fetchTasksAsync({
        sorting: [order],
        assignee: filter === "assignedToMe" ? currentUser?.id : undefined,
        createdBy: filter === "createdByMe" ? currentUser?.id : undefined,
        taskTeamMemberId: filter === "all" && !userId ? currentUser?.id : undefined,
        patientId: userId ? +userId : undefined,
      }),
    );
  };

  useEffect(() => {
    if (currentUser) {
      fetchInitialTasks();
    }
  }, [dispatch, currentUser, order, filter, userId]);

  const fetchMore = () => {
    dispatch(
      fetchTasksAsync({
        sorting: [order],
        assignee: filter === "assignedToMe" ? currentUser?.id : undefined,
        createdBy: filter === "createdByMe" ? currentUser?.id : undefined,
        taskTeamMemberId: filter === "all" && !userId ? currentUser?.id : undefined,
        offset: (tasks?.offset || 0) + (tasks?.limit || 10),
        patientId: userId ? +userId : undefined,
      }),
    );
  };

  return (
    <Box display={userId ? "block" : "flex"} flexWrap="nowrap" gap={2}>
      <Box
        sx={{ backgroundColor: (theme) => theme.veris.colors.neutrals.white, overflow: "scroll" }}
        my={2.5}
        ml={2}
        border={(theme) => `1px solid ${theme.veris.colors.neutrals["grey-2"]}`}
        borderRadius={(theme) => +theme.shape.borderRadius * 0.5}
        mb={0}
        height={userId ? "75vh" : "84vh"}
        flexBasis={userId ? "100%" : "70%"}
        id="tasks-scroll-container"
      >
        {modal}

        <TasksContainerHeader
          filter={filter}
          setFilter={setFilter}
          order={order}
          userId={userId}
          onCreateTask={() => {
            setSelectedTask(undefined);
            setOpenCreateTask(!openCreateTask);
          }}
        />

        <TasksList
          tasks={tasks}
          fetchMore={fetchMore}
          status={userId ? patientStatus : status}
          onClick={(el) => {
            setOpenCreateTask(false);
            setSelectedTask(el);
          }}
          task={selectedTask}
          onResolve={(el) => {
            setOpenCreateTask(false);
            showModal("Are you sure you want to resolve this task?", (onClose) => (
              <ResolveConfirmationModal
                onClose={onClose}
                onConfirm={() => {
                  if (el)
                    dispatch(
                      updateTaskAsync({
                        patientId: el?.patient.user_id,
                        taskId: el?.id,
                        data: { status: ClinicalTaskStatusEnum.Resolved },
                      }),
                    ).then((res) => {
                      if (res.meta.requestStatus === "fulfilled")
                        SnackbarUtils.success("Task resolved successfully!");
                      if (res.meta.requestStatus === "rejected") {
                        SnackbarUtils.error(
                          (res.payload as string) || "Could not resolve the task.",
                        );
                      }
                    });
                  onClose();
                }}
              />
            ));
          }}
          currentUserId={currentUser?.id}
        />
        {openCreateTask && currentUser && (
          <CreateTaskContainer
            open={openCreateTask}
            onClose={() => {
              setOpenCreateTask(!openCreateTask);
              setSelectedTask(undefined);
            }}
            task={selectedTask}
            currentUser={currentUser}
            modalView={!!userId}
            currentPatientId={userId ? +userId : undefined}
            fetchTasks={fetchInitialTasks}
          />
        )}
      </Box>
      <TaskPreview
        onClose={() => {
          setOpenCreateTask(false);
          setSelectedTask(undefined);
        }}
        patientDetails={!!userId}
        task={selectedTask}
        currentUser={currentUser}
        userId={userId}
        fetchTasks={fetchInitialTasks}
      />
    </Box>
  );
}
