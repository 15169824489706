import * as yup from "yup";

export const eventValidationSchema = yup.object().shape({
  type: yup.string().required("Meeting type is required!"),
  date: yup.date().required("Event date is required!"),
  startTime: yup.date().required("Starting time is required!"),
  endTime: yup.date().required("Ending time is required!"),
  title: yup.string().required("Event title is required!"),
  link: yup.string(),
  participants: yup
    .array()
    .min(1, "Participants field must have at least 1 medical staff member.")
    .max(4, "You can only select 3 medical staff members."),
});
