import Box from "@mui/material/Box";
import { IconName, VrsIcon, verisColors, VrsIconButton, VrsDivider } from "@veris-health/web-core";
import React, { useMemo, useState } from "react";
import { CircularProgress, Typography } from "@mui/material";
import {
  ClinicalTask,
  ClinicalTaskStatusEnum,
  ListTasksResponse,
} from "@veris-health/med-data-ms/lib/v1";
import InfiniteScroll from "react-infinite-scroll-component";
import { useHistory } from "react-router-dom";
import { TaskDetails } from "./TasksDetails";
import { replaceRouteParam } from "../../../routes-config";
import { Status } from "../../shared/interfaces";

interface TasksListProps {
  tasks?: ListTasksResponse;
  fetchMore: (tasks?: ListTasksResponse) => void;
  status: Status;
  onClick: (task: ClinicalTask) => void;
  task?: ClinicalTask;
  onResolve: (task: ClinicalTask) => void;
  currentUserId?: number;
}

export function TasksList({
  tasks,
  fetchMore,
  status,
  onClick,
  task,
  onResolve,
  currentUserId,
}: TasksListProps): JSX.Element {
  const [resolvedOpen, setResolvedOpen] = useState<boolean>(false);
  const history = useHistory();
  const unresolvedTasks = useMemo(() => {
    return tasks?.items?.filter((el) => el.status === ClinicalTaskStatusEnum.Unresolved);
  }, [tasks]);

  return (
    <InfiniteScroll
      scrollableTarget="tasks-scroll-container"
      hasMore={(tasks?.items?.length || 0) < (tasks?.total || 0)}
      style={{ overflow: "hidden" }}
      dataLength={tasks?.items?.length || 0}
      next={() => fetchMore(tasks)}
      loader={
        <Box display="flex" justifyContent="center" alignItems="center" p={1}>
          <CircularProgress />
        </Box>
      }
    >
      <Box p={3}>
        <Box py={2}>
          <Typography color={(theme) => theme.veris.colors.neutrals["grey-3"]} variant="h6Semibold">
            Unresolved
          </Typography>
        </Box>
        {status === "loading" && (
          <Box display="flex" justifyContent="center" alignItems="center" p={1}>
            <CircularProgress />
          </Box>
        )}
        {(tasks?.total === 0 || unresolvedTasks?.length === 0) && status !== "loading" && (
          <Box display="flex" alignItems="center" gap={3} justifyContent="center" width="100%">
            <VrsIcon
              name={IconName.Check}
              stroke={verisColors.moderate.normal}
              size={20}
              sx={{
                border: (theme) => `3px solid ${theme.veris.colors.moderate.normal}`,
                borderRadius: "50%",
                width: "37.5px",
                height: "37.5px",
                padding: (theme) => theme.spacing(1),
              }}
            />
            <Typography
              variant="h3"
              color={(theme) => theme.veris.colors.neutrals["grey-4"]}
              sx={{ fontWeight: 400 }}
            >
              You have no open tasks.
            </Typography>
          </Box>
        )}
        {unresolvedTasks &&
          unresolvedTasks.map((el) => (
            <TaskDetails
              key={el.id}
              {...el}
              onClick={() => onClick(el)}
              onResolve={() => onResolve(el)}
              isActive={task && task?.id === el.id}
              onPatientClick={() =>
                history.push(
                  replaceRouteParam("PATIENT_DETAILS", ":userId", el.patient.user_id.toString()),
                )
              }
              currentUserId={currentUserId}
            />
          ))}
      </Box>

      <VrsDivider height="80" />
      <Box p={3}>
        <Box py={2}>
          <Typography
            color={(theme) => theme.veris.colors.neutrals["grey-3"]}
            variant="h6Semibold"
            sx={{ cursor: "pointer" }}
            onClick={() => setResolvedOpen(!resolvedOpen)}
          >
            Resolved
            <VrsIconButton
              iconProps={{
                name: resolvedOpen ? IconName.OpenEye : IconName.CloseEye,
                size: 24,
              }}
              sx={{ ml: (theme) => theme.spacing(2) }}
            />
          </Typography>
        </Box>
        {resolvedOpen && (
          <Box>
            {tasks?.items
              ?.filter((el) => el.status === ClinicalTaskStatusEnum.Resolved)
              .map((el) => (
                <TaskDetails
                  key={el.id}
                  {...el}
                  onClick={() => onClick(el)}
                  isActive={task && task?.id === el.id}
                  onPatientClick={() =>
                    history.push(
                      replaceRouteParam(
                        "PATIENT_DETAILS",
                        ":userId",
                        el.patient.user_id.toString(),
                      ),
                    )
                  }
                />
              ))}
          </Box>
        )}
      </Box>
    </InfiniteScroll>
  );
}
