import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { Routes, USER_ID } from "../routes-config";
import PrivateRoute from "./PrivateRoute";
import { VrsAppContainer } from "../features/AppContainer/AppContainer";
import { DashboardContainer } from "../features/Dashboard/DashboardContainer";
import { LoginRegistrationContainer } from "../features/LoginRegistration";
import PatientDetailsContainer from "../features/PatientDetails/PatientDetailsContainer";
import PatientContainer from "../features/Patients/PatientsContainer";
import PageNotFoundContainer from "../features/PageNotFound/PageNotFoundContainer";
import CalendarContainer from "../features/Calendar/CalendarContainer";
import { DoctorProfileContainer } from "../features/DoctorsProfile/DoctorProfileContainer";
import { CommunicationContainer } from "../features/Communication";
import { VideoContainer } from "../features/VideoCommunication";
import { NotificationsListContainer } from "../features/Notifications";
import { MobileLandingPage } from "../features/LoginRegistration/MobileLandingPage";
import { TasksContainer } from "../features/Tasks/TasksContainer";
import { SuccessfulRegistration } from "../features/LoginRegistration/SuccessfulRegistration";

// TODO: refactor Routing
function VrsRouter(): JSX.Element {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={() => <Redirect to={Routes.STAFF_LOGIN} />} />
        <Route exact path={Routes.STAFF_LOGIN}>
          <LoginRegistrationContainer />
        </Route>
        <Route exact path={Routes.STAFF_REGISTRATION}>
          <LoginRegistrationContainer />
        </Route>
        <Route exact path={Routes.SUCCESSFUL_REGISTRATION}>
          <SuccessfulRegistration />
        </Route>
        <Route exact path={Routes.FORGOT_PASSWORD}>
          <LoginRegistrationContainer />
        </Route>
        <Route exact path={Routes.PASSWORD_EXPIRATION}>
          <LoginRegistrationContainer />
        </Route>
        <Route exact path={Routes.MOBILE_LANDING_PAGE}>
          <MobileLandingPage />
        </Route>
        <PrivateRoute exact path={Routes.DASHBOARD}>
          <VrsAppContainer>
            <DashboardContainer />
          </VrsAppContainer>
        </PrivateRoute>
        <PrivateRoute exact path={Routes.TASKS}>
          <VrsAppContainer>
            <TasksContainer />
          </VrsAppContainer>
        </PrivateRoute>
        <PrivateRoute path={Routes.PATIENTS}>
          <VrsAppContainer>
            <PatientContainer />
          </VrsAppContainer>
        </PrivateRoute>
        <PrivateRoute path={Routes.PATIENT_DETAILS}>
          <VrsAppContainer>
            <PatientDetailsContainer />
          </VrsAppContainer>
        </PrivateRoute>
        <PrivateRoute path={Routes.PATIENT_GOALS}>
          <VrsAppContainer>
            <PatientDetailsContainer />
          </VrsAppContainer>
        </PrivateRoute>
        <PrivateRoute path={Routes.PATIENT_GOALS}>
          <VrsAppContainer>
            <PatientDetailsContainer />
          </VrsAppContainer>
        </PrivateRoute>
        <PrivateRoute path={Routes.NOTIFICATIONS_DETAILS}>
          <VrsAppContainer>
            <NotificationsListContainer />
          </VrsAppContainer>
        </PrivateRoute>
        <PrivateRoute path={Routes.CALENDAR}>
          <VrsAppContainer>
            <CalendarContainer />
          </VrsAppContainer>
        </PrivateRoute>
        <PrivateRoute path={Routes.DOCTOR_PROFILE}>
          <VrsAppContainer>
            <DoctorProfileContainer />
          </VrsAppContainer>
        </PrivateRoute>
        <PrivateRoute path={Routes.VIDEO_COMMUNICATION}>
          <VrsAppContainer>
            <VideoContainer />
          </VrsAppContainer>
        </PrivateRoute>
        <PrivateRoute path={[Routes.COMMUNICATION, `${Routes.COMMUNICATION}/${USER_ID}`]} exact>
          <VrsAppContainer>
            <CommunicationContainer />
          </VrsAppContainer>
        </PrivateRoute>
        <Route>
          <PageNotFoundContainer />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

export default VrsRouter;
