/* eslint-disable camelcase */
import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { NotificationView } from "@veris-health/communication-ms/lib/v1";
import {
  NotificationType,
  OutOfRangePatientsNotificationViewData,
  SymptomActionNotificationViewData,
} from "@veris-health/communication-ms/lib/v1/api";
import { VrsDivider, verisColors } from "@veris-health/web-core";
import { VrsSystemCard } from "../../../features/Notifications/components/VrsSystemCard";
import { VrsPatientInfoCard } from "../VrsPatientInfoCard";
import { VrsPatientInfo } from "../../../features/shared/interfaces";
import { VrsSymptomNotificationDescription } from "../VrsSymptomNotificationDescription";
import { calculateTimeFromNow, utcToLocal } from "../../../utils/date";
import { formatNotificationDetailsText } from "../../../features/Notifications/components/VrsNotificationDetails/AppointmentNotification";
import { isSystemNotification } from "../../../constants";
import { StyledNotificationWrapper } from "../../../features/shared/styledComponents";
import { useProfile } from "../../../context/profile";
import {
  isOutOfRangeNotification,
  isSymptomNotification,
} from "../../../features/Notifications/utils";

dayjs.extend(relativeTime);

export interface NotificationListItemProps {
  notification: NotificationView;
  isActive?: boolean;
  onClick?: () => void;
  isLast?: boolean;
  onCreateTask?: (task: NotificationView) => void;
}

export function NotificationListItem({
  notification,
  isActive,
  onClick,
  isLast,
  onCreateTask,
}: NotificationListItemProps): JSX.Element {
  const { payload, date_created, read } = notification;
  const handleClick = () => onClick && onClick();
  const { data } = payload;
  const systemNotification = isSystemNotification(data.type as unknown as NotificationType);
  const currentUser = useProfile();
  const symptomNotification =
    isSymptomNotification(notification) && (data as SymptomActionNotificationViewData).id;
  const outOfRangeNotification =
    isOutOfRangeNotification(notification) && (data as OutOfRangePatientsNotificationViewData).id;

  return (
    <>
      <StyledNotificationWrapper
        data-test-hook="notificationItemList-element"
        isActive={!!isActive}
        onClick={handleClick}
        isUnread={!read}
        showBorder
        cursor="pointer"
      >
        <Stack direction="row" paddingRight={1} justifyContent="space-between">
          <Box paddingLeft={notification?.read || isActive ? 0.5 : 0}>
            {systemNotification ? (
              <VrsSystemCard backgroundColor="transparent" newNotification={!read && !isActive} />
            ) : (
              <VrsPatientInfoCard
                patientInfo={
                  "patient" in data
                    ? (data.patient as unknown as VrsPatientInfo)
                    : ({} as unknown as VrsPatientInfo)
                }
                backgroundColor="transparent"
                newNotification={!read && !isActive}
              />
            )}
          </Box>
          <Typography
            data-test-hook="notificationItemList-date"
            variant="body"
            color={verisColors.neutrals["grey-mid"]}
            pt={1}
          >
            {calculateTimeFromNow(utcToLocal(date_created).toISOString())}
          </Typography>
        </Stack>
        <Box pl={8}>
          {data.type.includes("symptom") ? (
            <VrsSymptomNotificationDescription data={data as SymptomActionNotificationViewData} />
          ) : (
            <Typography variant="subtitle2" p={1}>
              {("text" in data &&
                "text-values" in data &&
                formatNotificationDetailsText(data.text, data["text-values"])) ||
                payload.text}
            </Typography>
          )}
        </Box>
        {currentUser && (symptomNotification || outOfRangeNotification) && (
          <Box m={2}>
            <VrsDivider height="3px" orientation="horizontal" />
            <Typography
              sx={{ textDecoration: "underline", textAlign: "center" }}
              color={(theme) => theme.veris.colors.amethyst.normal}
              pt={1}
              variant="body2"
              onClick={(e) => {
                e.stopPropagation();
                handleClick();
                if (onCreateTask) onCreateTask(notification);
              }}
            >
              Create Task
            </Typography>
          </Box>
        )}
      </StyledNotificationWrapper>

      {!isLast && <VrsDivider height="1px" orientation="horizontal" />}
    </>
  );
}
