import { createAsyncThunk, createSlice, createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import {
  GetDiagnosticReportObsResponse,
  GetAppointmentResponse,
  ConditionResponse,
  Medication,
  MedicationStatusEnum,
  MedicationAdherence,
  ActiveMedication,
} from "@veris-health/med-data-ms/lib/v1";
import {
  QuestionsAnswersModel,
  QOLQuestionnaireResponse,
} from "@veris-health/virtual-doc-ms/lib/v1";
import dayjs from "dayjs";
import {
  fetchTreatmentPlan,
  fetchPatientConditions,
  fetchQOLReports,
} from "./api/patientDetailsSidebarApi";
import { Status } from "../shared/interfaces";
import {
  getPatientMedicationAdherence,
  getPatientMedications,
} from "../shared/slices/api/medDataApi";
import { RootState } from "../../store";
import { loadReportAnswersAsync } from "../PatientDetailsMeasurements/api/patientDetailsMeasurementsApi";
import { extractErrorMessage } from "../shared/helpers";
import { fetchRecentLabs } from "./api/services/labresults.service";

interface medications {
  id: number;
  medications: Medication[];
}

export interface NormalizedQOLReports {
  id: string;
  items: QOLQuestionnaireResponse[];
  limit: number;
  offset: number;
  total: number;
}

export interface PatientDetailsState {
  recentLabResults: {
    labResults: EntityState<GetDiagnosticReportObsResponse>;
    status: Status;
  };
  treatmentPlan: {
    appointments: EntityState<{ treatments: GetAppointmentResponse[] }>;
    status: Status;
  };
  symptomDetails: {
    symptomDetails: QuestionsAnswersModel;
    status: Status;
  };
  patientConditions: {
    conditions: EntityState<{ conditions: Array<ConditionResponse> }>;
    status: Status;
  };
  medications: {
    data: EntityState<{ medications: Array<Medication> }>;
    status: Status;
  };
  medicationsAdherence: {
    data: EntityState<MedicationAdherence>;
    status: Status;
  };
  qualityOfLifeReports: {
    reports: EntityState<NormalizedQOLReports>;
    status: Status;
  };
}

const labsResultsAdapter = createEntityAdapter<GetDiagnosticReportObsResponse>();
const treatmentPlansAdapter = createEntityAdapter<{ treatments: GetAppointmentResponse[] }>();
const patientConditionsAdapter = createEntityAdapter<{ conditions: Array<ConditionResponse> }>();
const medicationsAdapter = createEntityAdapter<{ medications: Array<Medication> }>();
const medicationsAdherenceAdapter = createEntityAdapter<MedicationAdherence>();
const qolAdapter = createEntityAdapter<NormalizedQOLReports>();

const initialState: PatientDetailsState = {
  recentLabResults: {
    labResults: labsResultsAdapter.getInitialState(),
    status: "idle",
  },
  treatmentPlan: {
    appointments: treatmentPlansAdapter.getInitialState(),
    status: "idle",
  },
  symptomDetails: {
    symptomDetails: {} as QuestionsAnswersModel,
    status: "idle",
  },
  patientConditions: {
    conditions: patientConditionsAdapter.getInitialState(),
    status: "idle",
  },
  medications: {
    data: medicationsAdapter.getInitialState(),
    status: "idle",
  },
  medicationsAdherence: {
    data: medicationsAdherenceAdapter.getInitialState(),
    status: "idle",
  },
  qualityOfLifeReports: {
    reports: qolAdapter.getInitialState(),
    status: "idle",
  },
};

export const loadRecentLabResultsAsync = createAsyncThunk(
  "patientDetails/fetchRecentLabResults",
  async ({ id }: { id: number }) => {
    const response = await fetchRecentLabs(id);
    return { id, ...response };
  },
);

export const loadTreatmentPlanAsync = createAsyncThunk(
  "patientDetails/fetchTreatmentPlan",
  async ({ id }: { id: number }) => {
    const response = await fetchTreatmentPlan(id);
    return { id, treatments: response.appointments };
  },
);

export const loadSymptomReportAnswers = createAsyncThunk(
  "symptoms/reportAnswers",
  loadReportAnswersAsync,
);

export const loadPatientConditionsAsync = createAsyncThunk(
  "patientDetails/fetchPatientConditions",
  async ({ id }: { id: number }) => {
    const response = await fetchPatientConditions(id);
    return { id, conditions: response || [] };
  },
);

export const fetchPatientActiveMedicationsAsync = createAsyncThunk(
  "patientDetailsSlice/getMedications",
  async ({ userId }: { userId: number }, { rejectWithValue }) => {
    try {
      const response = await getPatientMedications(
        userId,
        dayjs().toISOString(),
        dayjs().endOf("day").toISOString(),
        MedicationStatusEnum.Active,
      );
      return { id: userId, medications: response || [] };
    } catch (err) {
      const errorMsg = extractErrorMessage(err);
      return rejectWithValue(errorMsg || "Could not fetch medications data.");
    }
  },
);

export const fetchPatientActiveMedicationsAdherenceAsync = createAsyncThunk(
  "patientDetailsSlice/getMedicationsAdherence",
  async ({ userId }: { userId: number }, { rejectWithValue }) => {
    try {
      const response = await getPatientMedicationAdherence(userId);
      return { id: userId, medications: response.medications };
    } catch (err) {
      const errorMsg = extractErrorMessage(err);
      return rejectWithValue(errorMsg || "Could not fetch medications data.");
    }
  },
);

export const loadPatientQOLReportsAsync = createAsyncThunk(
  "patientDetails/fetchPatientQOLReports",
  async ({ id, offset }: { id: string; offset: number }) => {
    const response = await fetchQOLReports(id, offset);
    return { id, ...response };
  },
);

export const patientDetailsSidebarSlice = createSlice({
  name: "patientDetails",
  initialState,
  reducers: {
    resetSymptomReportAnswers: (state) => {
      state.symptomDetails.symptomDetails = {} as QuestionsAnswersModel;
    },
    resetQOLReportsPreview: (state, { payload }) => {
      if (state.qualityOfLifeReports.reports.ids.includes(payload)) {
        qolAdapter.setOne(state.qualityOfLifeReports.reports, {
          id: payload,
          items: [],
          limit: 0,
          offset: 0,
          total: 0,
        });
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadRecentLabResultsAsync.pending, (state) => {
        state.recentLabResults.status = "loading";
      })
      .addCase(loadRecentLabResultsAsync.fulfilled, (state, action) => {
        state.recentLabResults.status = "idle";
        labsResultsAdapter.upsertOne(state.recentLabResults.labResults, action.payload);
      })
      .addCase(loadRecentLabResultsAsync.rejected, (state) => {
        state.recentLabResults.status = "failed";
      })
      .addCase(loadTreatmentPlanAsync.pending, (state) => {
        state.treatmentPlan.status = "loading";
      })
      .addCase(loadTreatmentPlanAsync.fulfilled, (state, action) => {
        state.treatmentPlan.status = "idle";
        if (state.treatmentPlan.appointments.ids.includes(action.payload.id))
          treatmentPlansAdapter.updateOne(state.treatmentPlan.appointments, {
            id: action.payload.id,
            changes: { ...action.payload },
          });
        else treatmentPlansAdapter.addOne(state.treatmentPlan.appointments, action.payload);
      })
      .addCase(loadTreatmentPlanAsync.rejected, (state) => {
        state.treatmentPlan.status = "failed";
      })
      .addCase(loadSymptomReportAnswers.pending, (state) => {
        state.symptomDetails.status = "loading";
      })
      .addCase(loadSymptomReportAnswers.fulfilled, (state, action) => {
        state.symptomDetails.status = "idle";
        state.symptomDetails.symptomDetails = action.payload;
      })
      .addCase(loadSymptomReportAnswers.rejected, (state) => {
        state.symptomDetails.status = "failed";
      })
      .addCase(loadPatientConditionsAsync.pending, (state) => {
        state.patientConditions.status = "loading";
      })
      .addCase(loadPatientConditionsAsync.fulfilled, (state, action) => {
        state.patientConditions.status = "idle";
        patientConditionsAdapter.upsertOne(state.patientConditions.conditions, action.payload);
      })
      .addCase(loadPatientConditionsAsync.rejected, (state) => {
        state.patientConditions.status = "failed";
      })
      .addCase(fetchPatientActiveMedicationsAsync.pending, (state) => {
        state.medications.status = "loading";
      })
      .addCase(fetchPatientActiveMedicationsAsync.fulfilled, (state, { payload }) => {
        medicationsAdapter.upsertOne(state.medications.data, payload as medications);
        state.medications.status = "idle";
      })
      .addCase(fetchPatientActiveMedicationsAsync.rejected, (state) => {
        state.medications.status = "failed";
      })
      .addCase(fetchPatientActiveMedicationsAdherenceAsync.pending, (state) => {
        state.medicationsAdherence.status = "loading";
      })
      .addCase(fetchPatientActiveMedicationsAdherenceAsync.fulfilled, (state, { payload }) => {
        medicationsAdherenceAdapter.upsertOne(
          state.medicationsAdherence.data,
          payload as MedicationAdherence,
        );
        state.medicationsAdherence.status = "idle";
      })
      .addCase(fetchPatientActiveMedicationsAdherenceAsync.rejected, (state) => {
        state.medicationsAdherence.status = "failed";
      })
      .addCase(loadPatientQOLReportsAsync.pending, (state) => {
        state.qualityOfLifeReports.status = "loading";
      })
      .addCase(loadPatientQOLReportsAsync.fulfilled, (state, { payload }) => {
        state.qualityOfLifeReports.status = "idle";
        if (state.qualityOfLifeReports.reports.ids.includes(payload.id)) {
          const oldItems = state.qualityOfLifeReports.reports.entities[payload.id];
          if (oldItems && oldItems.items) {
            oldItems.items.push(...payload.items);
            qolAdapter.upsertOne(state.qualityOfLifeReports.reports, {
              ...payload,
              items: oldItems.items,
            });
          }
        } else {
          qolAdapter.addOne(state.qualityOfLifeReports.reports, payload);
        }
      })
      .addCase(loadPatientQOLReportsAsync.rejected, (state) => {
        state.qualityOfLifeReports.status = "failed";
      });
  },
});

export const { resetSymptomReportAnswers, resetQOLReportsPreview } =
  patientDetailsSidebarSlice.actions;

export const selectLabResults = (
  rootState: RootState,
  id: number | undefined,
): GetDiagnosticReportObsResponse | undefined => {
  if (id) {
    return labsResultsAdapter
      .getSelectors<RootState>((state) => state.patientDetailsSidebar.recentLabResults.labResults)
      .selectById(rootState, id);
  }
  return undefined;
};

export const selectLabResultsStatus = ({ patientDetailsSidebar }: RootState): Status =>
  patientDetailsSidebar.recentLabResults.status;

export const selectAppointments = (
  rootState: RootState,
  id: number | undefined,
): GetAppointmentResponse[] => {
  if (id) {
    return (
      treatmentPlansAdapter
        .getSelectors<RootState>((state) => state.patientDetailsSidebar.treatmentPlan.appointments)
        .selectById(rootState, id)?.treatments || []
    );
  }
  return [];
};

export const selectNextTreatmentsStatus = ({ patientDetailsSidebar }: RootState): Status =>
  patientDetailsSidebar.treatmentPlan.status;

export const selectSymptomDetails = ({ patientDetailsSidebar }: RootState): QuestionsAnswersModel =>
  patientDetailsSidebar.symptomDetails.symptomDetails;

export const selectSymptomDetailsStatus = ({ patientDetailsSidebar }: RootState): Status =>
  patientDetailsSidebar.symptomDetails.status;

export const selectPatientConditions = (
  rootState: RootState,
  id: number | undefined,
): Array<ConditionResponse> => {
  if (id) {
    return (
      patientConditionsAdapter
        .getSelectors<RootState>(
          (state) => state.patientDetailsSidebar.patientConditions.conditions,
        )
        .selectById(rootState, id)?.conditions || []
    );
  }
  return [];
};

export const selectPatientConditionsStatus = ({ patientDetailsSidebar }: RootState): Status =>
  patientDetailsSidebar.patientConditions.status;

export const selectActiveMeds = (rootState: RootState, id: number | undefined): Medication[] => {
  if (id) {
    return (
      medicationsAdapter
        .getSelectors<RootState>((state) => state.patientDetailsSidebar.medications.data)
        .selectById(rootState, id)?.medications || []
    );
  }
  return [];
};

export const selectActiveMedsStatus = (state: RootState): Status =>
  state.patientDetailsSidebar.medications.status;

export const selectMedicationAdherence = (
  rootState: RootState,
  id: number | undefined,
): ActiveMedication[] => {
  if (id) {
    return (
      medicationsAdherenceAdapter
        .getSelectors<RootState>((state) => state.patientDetailsSidebar.medicationsAdherence.data)
        .selectById(rootState, id)?.medications || []
    );
  }
  return [];
};

export const selectActiveMedAdherenceStatus = (state: RootState): Status =>
  state.patientDetailsSidebar.medicationsAdherence.status;

export const selectQOLReportsStatus = ({ patientDetailsSidebar }: RootState): Status =>
  patientDetailsSidebar.qualityOfLifeReports.status;

export const selectQOLReports = (
  rootState: RootState,
  id: number | undefined,
): NormalizedQOLReports => {
  if (id) {
    return (
      qolAdapter
        .getSelectors<RootState>(
          (state) => state.patientDetailsSidebar.qualityOfLifeReports.reports,
        )
        .selectById(rootState, id) || ({} as NormalizedQOLReports)
    );
  }
  return {} as NormalizedQOLReports;
};

export default patientDetailsSidebarSlice.reducer;
