import React, { useState } from "react";
import { Avatar, Box, ClickAwayListener, Dialog, Typography } from "@mui/material";
import {
  QuestionType,
  ReportAnswer,
  ReportAnswerOption,
} from "@veris-health/virtual-doc-ms/lib/v1";
import { VrsDivider, VrsButton, VrsTooltip, verisColors, VrsInfo } from "@veris-health/web-core";
import { getFullPictureFormat } from "../../shared/helpers";
import { formatReportAnswer } from "../utils";
import { QolAnswerColor } from "../../../constants";

export interface ImagePreviewDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  name?: string;
  answer?: ReportAnswer["answer"];
}

const ImagePreviewDialog = ({ open, setOpen, name, answer }: ImagePreviewDialogProps) => {
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      sx={{ ".MuiDialog-paper": { maxWidth: "40vw" } }}
    >
      <Box display="flex" alignItems="center" p={6} pb={2}>
        <Typography
          variant="body"
          color={(theme) => theme.veris.colors.neutrals["grey-3"]}
          display="flex"
        >
          Symptom Reported:
          <Typography
            variant="body"
            color={(theme) => theme.veris.colors.neutrals["grey-4"]}
            pl={1}
            sx={{ textTransform: "capitalize" }}
          >
            {name}
          </Typography>
        </Typography>
        <VrsInfo
          description="In case of large images, please use the provided scroll for better experience."
          size={20}
        />
      </Box>
      <Box px={6} sx={{ maxHeight: "80vh", maxWidth: "40vw", overflow: "auto" }}>
        <Avatar
          variant="square"
          sx={{ width: "100%", height: "100%" }}
          src={answer && getFullPictureFormat(String(answer).split("base64:")[1])}
        />
      </Box>
      <Box display="flex" justifyContent="center" p={4}>
        <VrsButton onClick={() => setOpen(false)} buttonType="primary">
          Close
        </VrsButton>
      </Box>
    </Dialog>
  );
};

export interface VrsReportQADetailsProps {
  reportAnswer: ReportAnswer;
  qoLView?: boolean;
  answerValue?: number;
  reportName?: string;
}

export function VrsReportQADetails({
  reportAnswer,
  qoLView = false,
  answerValue,
  reportName,
}: VrsReportQADetailsProps): JSX.Element {
  const { questionId, question, answer, questionType } = reportAnswer;
  const [openPhotoDialog, setOpenPhotoDialog] = useState<boolean>(false);

  return (
    <>
      <Box display="grid" gridTemplateColumns="repeat(10, 1fr)" gap={2.3}>
        <Box gridColumn="span 7" whiteSpace="normal">
          {answerValue && (
            <Typography
              color={QolAnswerColor[answerValue]}
              sx={{ fontSize: "3rem" }}
              mb={1.14}
              variant="body"
            >
              •
            </Typography>
          )}
          <Typography
            mb={1.14}
            variant="body"
            color={verisColors.neutrals["grey-mid"]}
            whiteSpace="normal"
          >
            Question {questionId}
            {qoLView && `: ${question}`}
          </Typography>
        </Box>
        {reportAnswer.options && (
          <QuestionOptions question={question} options={reportAnswer.options} />
        )}
      </Box>
      <Box display="grid" gridTemplateColumns="repeat(10, 1fr)" gap={2.3}>
        <Box
          gridColumn="span 7"
          whiteSpace="normal"
          color={verisColors.neutrals[qoLView ? "grey-4" : "black"]}
        >
          <Typography variant="subtitle1">{qoLView ? answer : question}</Typography>
        </Box>
        {!qoLView && (
          <Box gridColumn="span 3" display="flex" whiteSpace="normal">
            <Typography
              variant="subtitle2"
              color={verisColors.neutrals["grey-mid"]}
              sx={{
                "&:first-letter": { textTransform: "capitalize" },
                cursor: questionType === QuestionType.Photo ? "pointer" : "default",
              }}
              onClick={() => {
                if (questionType === QuestionType.Photo) setOpenPhotoDialog(true);
              }}
            >
              {answer ? formatReportAnswer(answer, questionType) : "No answer"}
            </Typography>
          </Box>
        )}
      </Box>
      <ImagePreviewDialog
        answer={answer}
        open={openPhotoDialog}
        setOpen={setOpenPhotoDialog}
        name={reportName}
      />
    </>
  );
}

interface QuestionOptionsProps {
  options: ReportAnswerOption[];
  question: string;
}
function QuestionOptions({ options, question }: QuestionOptionsProps) {
  const [open, setOpen] = useState(false);

  const onClose = () => {
    setOpen(false);
  };
  const onOpen = () => {
    setOpen(true);
  };

  return (
    <ClickAwayListener onClickAway={onClose}>
      <Box gridColumn="span 3" whiteSpace="normal">
        <VrsTooltip
          open={open}
          bcgcolor={verisColors.neutrals.white}
          disableFocusListener
          disableHoverListener
          border={`1px solid ${verisColors.neutrals["grey-2"]}`}
          title={
            <Box sx={{ minWidth: "12rem", padding: (theme) => theme.spacing(2, 1.5, 1, 1.5) }}>
              <Typography
                variant="caption"
                sx={{ marginBottom: (theme) => theme.spacing(1) }}
                color={verisColors.neutrals["grey-3"]}
              >
                {question}
              </Typography>
              <VrsDivider
                orientation="horizontal"
                height="1.5px"
                color={verisColors.neutrals["grey-2"]}
              />
              {options?.map((option, index, { length }) => (
                <React.Fragment key={option.value}>
                  <Typography
                    variant="captionSemibold"
                    variantMapping={{ captionSemibold: "p" }}
                    sx={{
                      textTransform: "capitalize",
                      fontWeight: "600",
                      marginBottom: "6px",
                      marginTop: "6px",
                      wordBreak: "break-all",
                    }}
                    color={verisColors.neutrals["grey-4"]}
                  >
                    {option.value}
                  </Typography>
                  {Boolean(index < length - 1) && (
                    <VrsDivider orientation="horizontal" height="0.5px" color="#E2E2EA" />
                  )}
                </React.Fragment>
              ))}
            </Box>
          }
        >
          <Typography
            variant="body"
            color={verisColors.amethyst.normal}
            onClick={onOpen}
            sx={{ cursor: "pointer", border: "none", textDecoration: "underline" }}
            whiteSpace="normal"
          >
            Possible Answers
          </Typography>
        </VrsTooltip>
      </Box>
    </ClickAwayListener>
  );
}
