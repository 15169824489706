import {
  Box,
  InputAdornment,
  inputBaseClasses,
  Radio,
  styled,
  TextField,
  TextFieldProps,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { IconName, VrsIcon, VrsButton, VrsAvatar, VrsDialog } from "@veris-health/web-core";
import { VrsPatientInfo } from "../../../shared/interfaces";

export interface VrsNewChatDialogProps {
  isOpen: boolean;
  patients: VrsPatientInfo[];
  handleChatCreation: (participant: VrsPatientInfo) => void;
  closeNewChatDialog: () => void;
}

export const StyledTextField = styled(TextField)<TextFieldProps>(({ theme }) => ({
  ".MuiOutlinedInput-notchedOutline": {
    borderColor: theme.veris.colors.neutrals["grey-2"],
  },
  [`& .${inputBaseClasses.input}`]: {
    ...theme.typography.body,
    padding: theme.spacing(1, 0),
  },
  [`& .${inputBaseClasses.root}`]: {
    color: theme.veris.colors.neutrals["grey-4"],
    "&:hover fieldset": {
      border: `1px solid ${theme.veris.colors.neutrals["grey-2"]}`,
    },
  },
}));

export function VrsNewChatDialog({
  isOpen,
  patients,
  handleChatCreation,
  closeNewChatDialog,
}: VrsNewChatDialogProps): JSX.Element {
  const [selectedPatient, setSelectedPatient] = useState<VrsPatientInfo>();
  const [filteredPatientResults, setFilteredPatientResults] = useState(patients);
  const [inputSearchTerm, setInputSearchTerm] = useState("");

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputSearchTerm(event.target.value);
  };

  useEffect(() => {
    const filteredPatientsBySearchTerm = patients.filter((patient) =>
      patient.name?.trim().toLowerCase().includes(inputSearchTerm.trim().toLowerCase()),
    );
    setFilteredPatientResults(filteredPatientsBySearchTerm);
  }, [inputSearchTerm]);

  return (
    <VrsDialog
      open={isOpen}
      title="Patient List"
      onClose={closeNewChatDialog}
      subtitle="Select a patient to start conversation"
      height="530px"
    >
      <Box my={7}>
        <StyledTextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <VrsIcon name={IconName.Search} />
              </InputAdornment>
            ),
          }}
          type="text"
          fullWidth
          placeholder="Search by name"
          onChange={handleInputChange}
          value={inputSearchTerm}
          autoFocus
        />

        <Box my={5} sx={{ maxHeight: "240px", overflowY: "scroll" }}>
          {filteredPatientResults.length === 0 && (
            <Typography
              pt={2}
              color={(theme) => theme.veris.colors.neutrals["grey-3"]}
              textAlign="center"
              variant="body"
            >
              No matches found
            </Typography>
          )}
          {filteredPatientResults.map((patient) => (
            <Box
              display="flex"
              alignItems="center"
              sx={{
                borderBottom: (theme) => `1px solid ${theme.veris.colors.neutrals["grey-2"]}`,
                cursor: "pointer",
              }}
              py={1}
              key={patient.id}
              onClick={() =>
                setSelectedPatient(selectedPatient?.id === patient.id ? undefined : patient)
              }
            >
              <Radio
                checked={selectedPatient?.id === patient.id}
                sx={{ color: (theme) => theme.veris.colors.amethyst.normal }}
              />
              <Box display="flex" alignItems="center">
                <VrsAvatar imageUrl={patient.picture} name={patient.name} size={30} />
                <Typography variant="body" mx={1}>
                  {patient.name}
                </Typography>
                <Typography variant="body" color={(theme) => theme.veris.colors.neutrals["grey-3"]}>
                  {patient.diagnosis?.cancerType}, Stage {patient.diagnosis?.cancerStage}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
      <VrsButton
        buttonType="primary"
        sx={{ width: "150px", alignSelf: "center" }}
        disabled={!selectedPatient}
        onClick={() => selectedPatient && handleChatCreation(selectedPatient)}
      >
        Start conversation
      </VrsButton>
    </VrsDialog>
  );
}
