import {
  StaffLoginOTPRequest,
  TokenResponse,
  RequestStaffRegisterOTP,
  StaffLoginRequest,
  RequestStaffRegister,
  ChangePasswordRequest,
  ResetPasswordRequest,
  ValidateOtpStaffRequest,
  ExpiredPasswordResetRequest,
  ExpiredPasswordOtpRequest,
  SuccessfulResponse,
} from "@veris-health/user-ms/lib/v1";
import {
  loginMedicalStaffApiV1,
  refreshTokenApiV1,
  registerMedicalStaffApiV1,
  changeCredentialsStaffApiV1,
} from "../../../../api";

export const loginMedStaff = async ({
  email,
  password,
}: StaffLoginOTPRequest): Promise<SuccessfulResponse> => {
  const response = await loginMedicalStaffApiV1.staffUserLoginOtp({ email, password });
  return response.data;
};

export const loginOTP = async ({
  email,
  password,
  otp,
}: StaffLoginRequest): Promise<TokenResponse> => {
  const response = await loginMedicalStaffApiV1.staffUserLogin({ email, password, otp });
  return response.data;
};

export const registerMedStaff = async ({
  ...params
}: RequestStaffRegisterOTP): Promise<SuccessfulResponse> => {
  const response = await registerMedicalStaffApiV1.verifyAndGetOtp({
    ...params,
  });
  return response.data;
};

export const registerProcedureStaff = async ({
  ...params
}: RequestStaffRegister): Promise<TokenResponse> => {
  const response = await registerMedicalStaffApiV1.verifyAndRegister({
    ...params,
  });
  return response.data;
};

export const registerMedStaffOtp = async ({
  ...params
}: RequestStaffRegister): Promise<TokenResponse> => {
  const response = await registerMedicalStaffApiV1.verifyAndRegister({
    ...params,
  });
  return response.data;
};

export const refreshExpiredToken = async (refreshToken: string): Promise<TokenResponse> => {
  const response = await refreshTokenApiV1.refreshToken({ refreshToken });
  return response.data;
};

export const changePassword = async (
  id: number,
  data: ChangePasswordRequest,
): Promise<TokenResponse> => {
  const response = await changeCredentialsStaffApiV1.changePassword(id, data);
  return response.data;
};

export const getOTP = async (email: string): Promise<unknown> => {
  const response = await changeCredentialsStaffApiV1.verificationCodeStaff({ email });
  return response.data;
};

export const resetPassword = async (data: ResetPasswordRequest): Promise<{ status?: string }> => {
  const response = await changeCredentialsStaffApiV1.resetMedStaffPassword(data);
  return response.data;
};

export const validateOTP = async (data: ValidateOtpStaffRequest): Promise<unknown> => {
  const response = await changeCredentialsStaffApiV1.validateOtpStaff(data);
  return response.data;
};

export const passwordExpired = async (
  data: ExpiredPasswordOtpRequest,
): Promise<{ status?: string }> => {
  const response = await changeCredentialsStaffApiV1.verificationCodeEmailExpiredPassword(data);
  return response.data;
};

export const passwordExpiredOtpValidation = async (
  data: ExpiredPasswordResetRequest,
): Promise<{ status?: string }> => {
  const response = await changeCredentialsStaffApiV1.resetMedStaffExpiredPassword(data);
  return response.data;
};
