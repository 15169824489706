import { Box, CircularProgress, Popover, Typography } from "@mui/material";
import {
  WeightTrendAggregationWindowSize,
  WeightTrendLookbackPeriod,
} from "@veris-health/med-data-ms/lib/v1";
import dayjs from "dayjs";
import { findLast, round } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";
import {
  VrsSelect,
  verisTypography,
  verisColors,
  VrsErrorMsg,
  dateFormats,
} from "@veris-health/web-core";
import {
  loadPatientWeightTrendAsync,
  selectPatientWeightTrend,
  selectWeightTrendErrorMessage,
  selectWeightTrendStatus,
} from "../../../features/PatientDetails/patientDetailsSlice";
import {
  TooltipWrapperProps,
  ChartTooltip,
} from "../../../features/PatientDetailsMeasurements/components/shared/tooltips";
import { VrsPatientInfo } from "../../../features/shared/interfaces";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../hooks/useAppSelector";

interface WeightTrendDialogProps {
  open: boolean;
  toggleDialog: () => void;
  anchorEl: HTMLDivElement;
  patient: VrsPatientInfo;
  currentValue?: string;
}

const CustomTooltipWrapper = ({ payload }: Omit<TooltipWrapperProps, "sensorsGraphView">) => {
  if (payload && payload.length) {
    const sensorData = payload[0]?.payload;

    return (
      <ChartTooltip>
        <Box display="flex">
          <Typography
            component="span"
            variant="caption"
            color={verisColors.neutrals["grey-mid"]}
            sx={{ width: "35px", marginRight: (theme) => theme.spacing(1), textAlign: "left" }}
          >
            Index
          </Typography>

          <Typography
            component="span"
            variant="description"
            sx={{
              marginRight: "auto",
            }}
          >
            {sensorData?.median_value}
          </Typography>
        </Box>
        <Box display="flex">
          <Typography
            component="span"
            variant="caption"
            color={verisColors.neutrals["grey-mid"]}
            sx={{ width: "35px", marginRight: (theme) => theme.spacing(1), textAlign: "left" }}
          >
            Date
          </Typography>

          <Typography
            component="span"
            variant="description"
            sx={{
              marginRight: "auto",
            }}
          >
            {dayjs(sensorData.start).format(dateFormats["MMM DD YYYY"])}
          </Typography>
        </Box>
      </ChartTooltip>
    );
  }
  return null;
};

type WeightTrendGraphView = "2 weeks" | "1 month" | "2 months" | "3 months" | "6 months";

const options: { label: WeightTrendGraphView; value: WeightTrendLookbackPeriod }[] = [
  { label: "2 weeks", value: WeightTrendLookbackPeriod._2w },
  { label: "1 month", value: WeightTrendLookbackPeriod._1m },
  { label: "2 months", value: WeightTrendLookbackPeriod._2m },
  { label: "3 months", value: WeightTrendLookbackPeriod._3m },
  { label: "6 months", value: WeightTrendLookbackPeriod._6m },
];

const aggregationSize: Record<WeightTrendLookbackPeriod, WeightTrendAggregationWindowSize> = {
  [WeightTrendLookbackPeriod._2w]: WeightTrendAggregationWindowSize.Day,
  [WeightTrendLookbackPeriod._1m]: WeightTrendAggregationWindowSize.Day,
  [WeightTrendLookbackPeriod._2m]: WeightTrendAggregationWindowSize.Week,
  [WeightTrendLookbackPeriod._3m]: WeightTrendAggregationWindowSize.Week,
  [WeightTrendLookbackPeriod._6m]: WeightTrendAggregationWindowSize.Week,
};
export function WeightTrendDialog({
  open,
  toggleDialog,
  anchorEl,
  patient,
  currentValue,
}: WeightTrendDialogProps): JSX.Element {
  const weightData = useAppSelector((state) => selectPatientWeightTrend(state, patient.id));
  const weightStatus = useAppSelector(selectWeightTrendStatus);
  const weightError = useAppSelector(selectWeightTrendErrorMessage);
  const [period, setPeriod] = useState<WeightTrendLookbackPeriod>(WeightTrendLookbackPeriod._2w);
  const dispatch = useAppDispatch();
  const startWeight = useMemo(() => {
    return (
      weightData &&
      weightData.length > 0 &&
      weightData.find((el) => el.median_value !== null)?.median_value
    );
  }, [weightData]);
  const currentWeight = useMemo(() => {
    if (currentValue) return +currentValue;
    return (
      weightData &&
      weightData.length > 0 &&
      findLast(weightData, (el) => el.median_value !== null)?.median_value
    );
  }, [weightData]);

  const diff = startWeight && currentWeight && startWeight - currentWeight;

  useEffect(() => {
    dispatch(
      loadPatientWeightTrendAsync({ id: patient.id, period, size: aggregationSize[period] }),
    );
  }, [dispatch, period]);

  return (
    <Popover
      open={open}
      onClose={toggleDialog}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      anchorEl={anchorEl}
      PaperProps={{
        sx: {
          overflow: "visible",
          mt: 2.1,
          "&:before": {
            content: '""',
            position: "absolute",
            left: 35,
            width: 20,
            height: 20,
            bgcolor: (theme) => theme.palette.background.paper,
            transform: "translateY(-50%) rotate(45deg)",
          },
        },
      }}
    >
      <Box p={2} width="500px" minHeight="250px">
        <Box display="flex" alignItems="center" justifyContent="space-between" mb={3}>
          <Typography variant="body1" color={(theme) => theme.veris.colors.neutrals["grey-4"]}>
            Weight Trend
          </Typography>
          <VrsSelect
            initialValue="2w"
            options={options}
            onSelected={(value) => setPeriod(value as WeightTrendLookbackPeriod)}
            outerSx={{ width: "120px" }}
            value={period}
          />
        </Box>
        {weightStatus === "loading" && (
          <Box display="flex" justifyContent="center" alignItems="center" minHeight="100px">
            <CircularProgress />
          </Box>
        )}
        {weightStatus === "failed" && (
          <>
            {weightError ? (
              <Typography
                color={(theme) => theme.veris.colors.neutrals["grey-3"]}
                textAlign="center"
                variant="body"
                p={2}
              >
                {weightError}
              </Typography>
            ) : (
              <VrsErrorMsg
                size="small"
                onClick={() =>
                  loadPatientWeightTrendAsync({
                    id: patient.id,
                    period,
                    size: aggregationSize[period],
                  })
                }
              />
            )}
          </>
        )}
        {weightStatus === "idle" && (
          <>
            {weightData.length > 0 ? (
              <>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  width="200px"
                >
                  <Box display="flex" flexDirection="column">
                    <Typography variant="captionSemibold">{startWeight}lb</Typography>
                    <Typography
                      variant="description"
                      color={(theme) => theme.veris.colors.neutrals["grey-3"]}
                    >
                      START
                    </Typography>
                  </Box>
                  <Box display="flex" flexDirection="column">
                    <Typography
                      variant="captionSemibold"
                      color={(theme) => theme.veris.colors.amethyst.normal}
                    >
                      {currentWeight}lb
                    </Typography>
                    <Typography
                      variant="description"
                      color={(theme) => theme.veris.colors.neutrals["grey-3"]}
                    >
                      CURRENT
                    </Typography>
                  </Box>
                  {startWeight && currentWeight && (
                    <Box display="flex" flexDirection="column">
                      <Typography
                        variant="captionSemibold"
                        color={(theme) => theme.veris.colors.neutrals["grey-3"]}
                      >
                        {startWeight !== currentWeight && (startWeight > currentWeight ? "-" : "+")}
                        {diff && round(diff, 1)}lb
                      </Typography>
                      <Typography
                        variant="description"
                        color={(theme) => theme.veris.colors.neutrals["grey-3"]}
                      >
                        CHANGE
                      </Typography>
                    </Box>
                  )}
                </Box>

                <ResponsiveContainer height={130}>
                  <LineChart data={weightData} margin={{ top: 20, left: 20, right: 5, bottom: 5 }}>
                    <CartesianGrid strokeDasharray="1 0" horizontal={false} />
                    <XAxis
                      dataKey="start"
                      tick={{
                        fontSize: verisTypography.caption?.fontSize,
                        fontFamily: verisTypography.caption?.fontFamily || "",
                        fontWeight: verisTypography.caption?.fontWeight,
                        color: verisColors.neutrals["grey-3"],
                      }}
                      axisLine={false}
                      tickLine={false}
                      stroke={verisColors.neutrals["grey-3"]}
                      padding="no-gap"
                      interval="preserveStartEnd"
                      tickFormatter={(value) => dayjs(value).format(dateFormats["MM/DD"])}
                    />
                    <Tooltip cursor={false} content={<CustomTooltipWrapper />} />
                    <YAxis hide dataKey="median_value" domain={["auto", "auto"]} />
                    <Line
                      type="monotone"
                      dataKey="median_value"
                      stroke={verisColors.amethyst.normal}
                      connectNulls
                    />
                  </LineChart>
                </ResponsiveContainer>
              </>
            ) : (
              <Box
                p={3}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant="caption"
                  color={(theme) => theme.veris.colors.neutrals["grey-3"]}
                >
                  No data available
                </Typography>
              </Box>
            )}
          </>
        )}
      </Box>
    </Popover>
  );
}
