import { Box, DialogActions, DialogContent, TextField, Typography } from "@mui/material";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import React, { HTMLAttributes, useEffect, useState } from "react";
import { AppointmentCreateRequest, AppointmentTypeEnum } from "@veris-health/med-data-ms/lib/v1";
import { PatientBasicInfo, MedStaffProfileItem } from "@veris-health/user-ms/lib/v1";
import { v4 as uuidv4 } from "uuid";
import { useFormik } from "formik";
import { flatten } from "lodash";
import {
  IconName,
  VrsIcon,
  VrsButton,
  VrsSelect,
  verisColors,
  VrsDialog,
} from "@veris-health/web-core";
import { CombinedDatepicker } from "../components/CombinedDatepicker";
import { EventContents } from "./EventContents";
import { eventValidationSchema } from "./EventValidationSchema";
import { CalendarTimeslotProps } from "../Calendar";
import { roundToNearest15 } from "../../../utils/date";
import { VrsMedStaffProfileModel } from "../../shared/interfaces";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { selectHospitalStaff } from "../../Hospitals/hospitalSlice";
import { VrsChipMultiselect } from "../../../ui/components/VrsChipMultiselect";

dayjs.extend(utc);

const renderOption = (
  { onClick, ...rest }: HTMLAttributes<HTMLLIElement | HTMLDivElement>,
  { value, label }: { value: MedStaffProfileItem | VrsMedStaffProfileModel; label: string },
) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      sx={{
        cursor: "pointer",
      }}
      onClick={onClick}
      {...rest}
      key={`${value.id}`}
    >
      <Typography
        variant="body"
        ml={1}
        mr={1}
        color={(theme) => theme.veris.colors.neutrals["grey-dark"]}
      >
        {label}
      </Typography>
    </Box>
  );
};

export const constructDate = (date: Date | string, time: Date | string): string => {
  const localDate = dayjs(time)
    .set("date", dayjs(date).get("date"))
    .set("month", dayjs(date).get("month"))
    .set("year", dayjs(date).get("year"));
  return dayjs(localDate).format();
};

type VrsPatient = PatientBasicInfo;

export interface CreateEventProps {
  isOpen: boolean;
  existingTimeSlot: CalendarTimeslotProps | undefined;
  currentUserId: string;
  patientList?: VrsPatient[];
  patientId: number | undefined;
  onClose: () => void;
  onEventCreate: ({ appointment }: { appointment: AppointmentCreateRequest }) => void;
  currentUserProfile?: MedStaffProfileItem | VrsMedStaffProfileModel;
  timelinePatient?: VrsPatient;
}

const CreateEvent = ({
  isOpen,
  existingTimeSlot,
  currentUserId,
  patientList,
  patientId,
  onClose,
  onEventCreate,
  currentUserProfile,
  timelinePatient,
}: CreateEventProps): JSX.Element => {
  const [showLinkInput, setShowLinkInput] = useState(false);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [openTimePicker, setOpenTimePicker] = useState(false);
  const hospitalStaff = useAppSelector(selectHospitalStaff);
  const [staffByHospital, setStaffByHospital] = useState<
    (MedStaffProfileItem | VrsMedStaffProfileModel)[]
  >([]);
  const preselectedPatient =
    timelinePatient || patientList?.find((patient: VrsPatient) => patientId === patient.id);
  const [selectedPatient, setSelectedPatient] = useState<VrsPatient | undefined>(
    preselectedPatient,
  );
  const patientOptions =
    patientList?.map((patient) => ({
      value: String(patient.id),
      label: patient.full_name,
    })) || [];

  const initialValues = {
    type: "",
    date: (existingTimeSlot?.date as Date) ?? (dayjs().toDate() as Date),
    startTime: (existingTimeSlot?.start as Date) ?? roundToNearest15(),
    endTime: (existingTimeSlot?.end as Date) ?? roundToNearest15(dayjs().add(1, "hour")),
    title: preselectedPatient ? preselectedPatient.id?.toString() : "",
    participants: currentUserProfile
      ? [
          {
            label:
              "fullName" in currentUserProfile
                ? currentUserProfile.fullName
                : `${currentUserProfile.first_name} ${currentUserProfile.last_name}`,
            value: currentUserProfile,
          },
        ]
      : [],
    link: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema: eventValidationSchema,
    onSubmit: (values: EventContents) => {
      const constructedStartDate = constructDate(values.date as Date, values.startTime as Date);
      const constructedEndDate = constructDate(values.date as Date, values.endTime as Date);
      const selectedPatientId: number = values?.title ? +values?.title : 721;
      const participants = values.participants?.map((participant) => participant.value.id);

      const newAppointment: AppointmentCreateRequest = {
        attendees: [...participants, selectedPatientId],
        title: `New ${values.type} Appointment`,
        startTime: constructedStartDate,
        endTime: constructedEndDate,
        type:
          values.type === "Video"
            ? AppointmentTypeEnum.VideoAppointment
            : AppointmentTypeEnum.ClinicAppointment,
      };
      if (values.type === "Video") newAppointment.videoLink = values.link;
      handleEventCreation(newAppointment);
    },
  });

  useEffect(() => {
    const filteredMedStaffByHospital = hospitalStaff.filter((hospital) => {
      return hospital.id === selectedPatient?.hospital_id;
    });

    if (currentUserProfile)
      setStaffByHospital([
        ...flatten(filteredMedStaffByHospital?.map((staff) => staff.med_staff)),
        currentUserProfile,
      ]);
  }, [selectedPatient, hospitalStaff]);

  useEffect(() => {
    formik.setFieldValue("title", preselectedPatient?.id.toString());
    setSelectedPatient(preselectedPatient);
  }, [preselectedPatient]);

  useEffect(() => {
    if (existingTimeSlot) {
      formik.setFieldValue("date", existingTimeSlot?.date);
      formik.setFieldValue("startTime", existingTimeSlot?.start);
      formik.setFieldValue("endTime", existingTimeSlot?.end);
    }
  }, [existingTimeSlot]);

  useEffect(() => {
    if (!isOpen && formik.dirty) {
      formik.resetForm();
    }
  }, [isOpen]);

  function handleEventCreation(appointment: AppointmentCreateRequest) {
    onEventCreate({ appointment });
  }

  return (
    <VrsDialog open={isOpen} onClose={onClose} title="New Event" height="280px">
      <form style={{ minWidth: "442px", maxWidth: "442px" }}>
        <DialogContent>
          <CombinedDatepicker
            formValues={formik.values}
            openDatePicker={openDatePicker}
            openTimePicker={openTimePicker}
            setFieldValue={formik.setFieldValue}
            setOpenDatePicker={(value) => setOpenDatePicker(value)}
            setOpenTimePicker={(value) => setOpenTimePicker(value)}
          />
          <Box display="flex" pt={1} pb={3} gap={5} alignItems="center">
            <VrsIcon name={IconName.ClinicOrVideo} />

            <VrsSelect
              placeholder="Choose Type"
              options={[
                { value: "Video", label: "Video" },
                { value: "Clinic", label: "Clinic" },
              ]}
              value={formik.values.type}
              onSelected={(value) => {
                formik.setFieldValue("type", value).finally(() => {
                  if (value === "Video") formik.setFieldValue("link", uuidv4(), true);
                });
              }}
            />
          </Box>
          <Box display="flex" pb={3} gap={5} alignItems="center">
            <VrsIcon name={IconName.AllPatients} color={verisColors.neutrals["grey-3"]} />
            {preselectedPatient ? (
              <Typography variant="body">{preselectedPatient.full_name}</Typography>
            ) : (
              <VrsSelect
                value={formik.values.title}
                placeholder="Choose Patient"
                maxHeight="450px"
                options={patientOptions ?? []}
                onSelected={(value) => {
                  formik.setFieldValue("title", value);
                  setSelectedPatient(patientList?.find((patient) => patient.id === +value));
                }}
              />
            )}
          </Box>

          <Box display="flex" gap={4} pb={3} alignItems="center">
            <VrsIcon name={IconName.MedicalTeam} size={30} />
            <Box>
              <VrsChipMultiselect<MedStaffProfileItem | VrsMedStaffProfileModel>
                initialValue={formik.values.participants || []}
                onChange={(e, value) => formik.setFieldValue("participants", value)}
                options={
                  staffByHospital?.map((careTeamMember) => ({
                    value: careTeamMember,
                    label: `${careTeamMember.first_name || ""} ${careTeamMember.last_name || ""}`,
                  })) || []
                }
                placeholder="Medical Staff"
                renderOption={renderOption}
                disabledOptionId={+currentUserId}
                disableClearable
                disabled={!formik.values.title}
                width="300px"
              />

              <Typography color={(theme) => theme.veris.colors.errors.normal} variant="description">
                {formik.errors.participants}
              </Typography>
            </Box>
          </Box>

          {formik.values.type !== "Clinic" && (
            <Box display="flex" pb={3} gap={5} height="55px" alignItems="center">
              <VrsIcon name={IconName.Link} />
              {showLinkInput ? (
                <TextField size="small" name="link" value={formik.values.link} disabled />
              ) : (
                <VrsButton buttonType="primary" onClick={() => setShowLinkInput(true)}>
                  Link
                </VrsButton>
              )}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <VrsButton
            onClick={formik.submitForm}
            buttonType="secondary"
            disabled={!formik.isValid || !formik.dirty || formik.isSubmitting}
          >
            Send Invite
          </VrsButton>
        </DialogActions>
      </form>
    </VrsDialog>
  );
};

export default CreateEvent;
