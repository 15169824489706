import React, { useEffect } from "react";
import { Box, CircularProgress, Grid } from "@mui/material";
import { VrsErrorMsg } from "@veris-health/web-core";
import { useAppSelector } from "../../hooks/useAppSelector";
import { selectUserId } from "../shared/slices/authSlice";
import {
  selectChatApiEndpoint,
  selectChatUserAccessToken,
  selectStatus,
  selectMyAzureChatUserId,
  selectMyChatDisplayName,
  fetchChatConfiguration,
  selectChatHistoryPatientId,
  resetThreadId,
  setChatHistoryPatientId,
} from "./store";
import ChatContainer from "./containers/ChatContainer";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import ChatHistoryContainer from "./containers/ChatHistoryContainer";
import { useCurrentPatient } from "../../hooks/useCurrentPatient";

const CommunicationContainer = (): JSX.Element => {
  const status = useAppSelector(selectStatus);
  const chatApiEndpoint = useAppSelector(selectChatApiEndpoint);
  const chatUserAccessToken = useAppSelector(selectChatUserAccessToken);
  const myAzureChatUserId = useAppSelector(selectMyAzureChatUserId);
  const myChatDisplayName = useAppSelector(selectMyChatDisplayName);
  const userId = useAppSelector(selectUserId);
  const currentPatient = useCurrentPatient();
  const dispatch = useAppDispatch();
  const isChatConfigInitialized =
    chatApiEndpoint && chatUserAccessToken && myAzureChatUserId && myChatDisplayName;
  const chatHistoryPatientId = useAppSelector(selectChatHistoryPatientId);

  useEffect(() => {
    return () => {
      dispatch(resetThreadId());
      dispatch(setChatHistoryPatientId({ patientId: undefined }));
    };
  }, []);

  return (
    <Grid container height="90vh">
      {status === "loading" && (
        <Grid display="flex" justifyContent="center" py={2} item xs={12}>
          <CircularProgress />
        </Grid>
      )}
      {status === "idle" && userId && (
        <Grid item xs={12}>
          {chatHistoryPatientId ? (
            <ChatHistoryContainer
              userId={userId}
              myAzureChatUserId={myAzureChatUserId}
              myChatDisplayName={myChatDisplayName}
              currentPatient={currentPatient}
            />
          ) : (
            <>
              {isChatConfigInitialized && (
                <ChatContainer
                  endpoint={chatApiEndpoint}
                  token={chatUserAccessToken}
                  userId={userId}
                  myAzureChatUserId={myAzureChatUserId}
                  myChatDisplayName={myChatDisplayName}
                  currentPatient={currentPatient}
                />
              )}
            </>
          )}
        </Grid>
      )}
      {status === "failed" && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            backgroundColor: (theme) => theme.veris.colors.neutrals.white,
            margin: "auto",
            width: "100%",
            height: "90vh",
          }}
        >
          <VrsErrorMsg
            size="large"
            onClick={() => dispatch(fetchChatConfiguration({ id: Number(userId) }))}
          />
        </Box>
      )}
    </Grid>
  );
};

export default CommunicationContainer;
