/* eslint-disable camelcase */
import {
  createAction,
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { get } from "lodash";
import { getToken, Messaging } from "firebase/messaging";
import {
  QuestionsAnswersModel,
  ReportItem,
  ReportStatusEnum,
} from "@veris-health/virtual-doc-ms/lib/v1";
import { MarkAsRead, NotificationView, Reporter } from "@veris-health/communication-ms/lib/v1";
import dayjs from "dayjs";
import { MbscCalendarEvent } from "@mobiscroll/react";
import { PatientStatusEnumAPI } from "@veris-health/user-ms/lib/v2";
import { RootState } from "../../store";
import { loadReportAnswersAsync } from "../PatientDetailsMeasurements/api/patientDetailsMeasurementsApi";
import {
  fetchAllNotifications,
  getNotificationDetails,
  loadSymptomsReportedHistoryAsync,
  markNotificationAsRead,
  registerFirebaseToken,
  unregisterFirebaseToken,
} from "./api/notificationsApi";
import { Status } from "../shared/interfaces";
import { fetchCalendarEvents } from "../shared/slices/api/calendarApi";
import { fetchCalendarEventsProps } from "../Calendar/calendarSlice";
import { checkPatientStatus, extractErrorMessage } from "../shared/helpers";
import { localizedLogout, logout } from "../shared/slices/authSlice";
import { terminateTokens } from "../../api/utils/localStorage";

export const enum NotificationFilter {
  ViewAll = "all",
  Patients = "patient",
  CareTeam = "care-team",
  System = "system",
}

export const notificationFilters = [
  { name: "View All", filter: NotificationFilter.ViewAll },
  { name: "Patients", filter: NotificationFilter.Patients },
  { name: "Clinical Care Team", filter: NotificationFilter.CareTeam },
  { name: "System", filter: NotificationFilter.System },
];

export const enum SymptomsReportHistoryFilterEnum {
  Active = "active",
  Resolved = "resolved",
}

export const SymptomsReportHistoryFilter = [
  { name: "Active", filter: SymptomsReportHistoryFilterEnum.Active },
  { name: "Resolved", filter: SymptomsReportHistoryFilterEnum.Resolved },
];

export interface NotificationPayloadData {
  reported_by: string;
  reporter_id: string;
  symptom_report_id: string;
}

export interface NotificationState {
  showNotificationDetails: boolean;
  patientId: string | undefined;
  filter: NotificationFilter;
  notifications: NotificationView[];
  status: Status;
  showQuestionsAnswers: boolean;
  symptomsReport?: QuestionsAnswersModel;
  symptomsReportStatus: Status;
  symptomsReportHistory: ReportItem[];
  symptomsReportHistoryFilter: SymptomsReportHistoryFilterEnum;
  symptomsReportHistoryStatus: Status;
  pastAppointments: Record<string, MbscCalendarEvent[]>;
  pastAppointmentsStatus: Status;
  totalCount: number;
  unreadCount: number;
  notificationsFetchingOffset: number;
}

const initialState: NotificationState = {
  showNotificationDetails: false,
  patientId: undefined,
  filter: NotificationFilter.ViewAll,
  notifications: [],
  pastAppointments: {},
  status: "idle",
  showQuestionsAnswers: false,
  symptomsReportStatus: "idle",
  symptomsReportHistory: [],
  symptomsReportHistoryFilter: SymptomsReportHistoryFilterEnum.Active,
  symptomsReportHistoryStatus: "idle",
  pastAppointmentsStatus: "idle",
  totalCount: 0,
  unreadCount: 0,
  notificationsFetchingOffset: 1,
};

// Action creator

export const appendNewNotification = createAction(
  "notifications/appendNewNotification",
  (notificationView: NotificationView) => ({
    payload: { ...notificationView },
  }),
);

// Async Thunk(s)

export const setupFirebaseToken = createAsyncThunk(
  "notifications/setupFirebaseToken",
  async ({ userId, firebaseMessaging }: { userId: number; firebaseMessaging: Messaging }) => {
    const token = await getToken(firebaseMessaging, {
      vapidKey: import.meta.env.VITE_FIREBASE_VAPID_KEY,
    });
    await registerFirebaseToken(userId, token);
    return token;
  },
);

export const unregisterFirebaseTokenAsync = createAsyncThunk(
  "notifications/unregisterFirebaseToken",
  async (userId: number) => {
    try {
      const response = await unregisterFirebaseToken(userId);
      return response;
    } catch (e) {
      return new Error();
    } finally {
      terminateTokens();
    }
  },
);

export const loadNotificationsDataAsync = createAsyncThunk(
  "notifications/fetchNotificationsData",
  async (
    {
      userId,
      reportedBy,
      patientId,
      offset,
    }: {
      userId: number;
      reportedBy?: Reporter;
      patientId?: number;
      offset?: number;
    },
    { getState },
  ): Promise<{
    notifications: NotificationView[];
    totalCount: number;
    unreadCount: number;
    offset: number;
  }> => {
    const response = await fetchAllNotifications(userId, undefined, reportedBy, patientId, offset);
    const { notifications } = getState() as RootState;
    const new_notifications = response.items?.map((notification) => ({
      ...notification,
      payload: {
        ...notification.payload,
        data: {
          ...notification.payload.data,
          patient: {
            ...("patient" in notification.payload.data && notification.payload.data.patient),
            name: get(notification, "payload.data.patient.full_name", ""),
            picture: get(notification, "payload.data.patient.image", ""),
            diagnosis: {
              cancerType: get(notification, "payload.data.patient.cancer_type", ""),
              cancerStage: get(notification, "payload.data.patient.cancer_stage", ""),
            },
            patientStatus: get(notification, "payload.data.patient.patient_status"),
            inactive: [PatientStatusEnumAPI.Deceased, PatientStatusEnumAPI.Inactive].includes(
              get(notification, "payload.data.patient.patient_status"),
            ),
            observation: [
              PatientStatusEnumAPI.HospitalAtHome,
              PatientStatusEnumAPI.Hospitalized,
              PatientStatusEnumAPI.Survivor,
            ].includes(get(notification, "payload.data.patient.patient_status")),
            type: notification.type,
            hospital_ids: get(notification, "payload.data.patient.hospital_ids", [])[0],
          },
        },
      },
    }));
    const allNotifications = [
      ...notifications.notifications,
      ...(new_notifications as unknown as NotificationView[]),
    ];
    return {
      notifications: offset
        ? (allNotifications as unknown as NotificationView[])
        : (new_notifications as unknown as NotificationView[]),
      totalCount: response.total,
      unreadCount: response.unread_count,
      offset: response.offset,
    };
  },
);

export const loadNotificationReportAnswersAsync = createAsyncThunk(
  "notifications/reportAnswers",
  loadReportAnswersAsync,
);

export const markNotificationAsReadAsync = createAsyncThunk(
  "notifications/markNotificationAsRead",
  async ({ userId, markAsRead }: { userId: number; markAsRead: MarkAsRead }) => {
    const markNotificationAsReadResponse = await markNotificationAsRead(userId, markAsRead);
    return markNotificationAsReadResponse.status;
  },
);

export const fetchNotificationDetailsAsync = createAsyncThunk<
  NotificationView,
  { vrsNotificationId?: string },
  { rejectValue: { vrsNotificationId?: string } }
>(
  "notifications/fetchNotificationDetailsAsync",
  async ({ vrsNotificationId }, { getState, dispatch, rejectWithValue }) => {
    const { auth } = getState() as RootState;
    const { userId } = auth;
    try {
      const notificationDetails = await getNotificationDetails({ userId, vrsNotificationId });
      const new_notification = {
        ...notificationDetails,
        payload: {
          ...notificationDetails.payload,
          data: {
            ...notificationDetails.payload.data,
            patient: {
              ...("patient" in notificationDetails.payload.data &&
                notificationDetails.payload.data.patient),
              name: get(notificationDetails, "payload.data.patient.full_name", ""),
              picture: get(notificationDetails, "payload.data.patient.image", ""),
              diagnosis: {
                cancerType: get(notificationDetails, "payload.data.patient.cancer_type", ""),
                cancerStage: get(notificationDetails, "payload.data.patient.cancer_stage", ""),
              },
              patientStatus: get(notificationDetails, "payload.data.patient.patient_status"),
              isInactiveOrDeceased: checkPatientStatus(
                [PatientStatusEnumAPI.Inactive, PatientStatusEnumAPI.Deceased],
                get(notificationDetails, "payload.data.patient.patient_status"),
              ),
              observation: checkPatientStatus(
                [
                  PatientStatusEnumAPI.HospitalAtHome,
                  PatientStatusEnumAPI.Hospitalized,
                  PatientStatusEnumAPI.Survivor,
                ],
                get(notificationDetails, "payload.data.patient.patient_status"),
              ),
              type: notificationDetails.type,
              hospital_ids: get(notificationDetails, "payload.data.patient.hospital_ids", [])[0],
            },
          },
        },
      };
      dispatch(appendNewNotification(new_notification as unknown as NotificationView));
      return notificationDetails;
    } catch (error) {
      return rejectWithValue({ vrsNotificationId });
    }
  },
);

export const loadLastSystomsReportedAsync = createAsyncThunk(
  "notifications/LastSystomsReported",
  loadSymptomsReportedHistoryAsync,
);

export const loadPastAppointmentsAsync = createAsyncThunk(
  "notifications/pastAppointments",
  async ({ userId, start, end }: fetchCalendarEventsProps, { rejectWithValue }) => {
    try {
      return await fetchCalendarEvents(userId, start, end);
    } catch (err) {
      const errorMsg = extractErrorMessage(err);
      if (errorMsg) return rejectWithValue(errorMsg || "Could not load appointments.");
      throw err;
    }
  },
);

// Reducers

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    increaseUnreadCount: (state) => {
      state.unreadCount += 1;
    },
    setNotificationsFilter: (state, action: PayloadAction<NotificationFilter>) => {
      state.filter = action.payload;
    },
    showNotificationDetails: (state, action: PayloadAction<string>) => {
      state.showNotificationDetails = true;
      state.patientId = action.payload;
    },
    showQuestionsAnswers: (state, action: PayloadAction<boolean>) => {
      state.showQuestionsAnswers = action.payload;
    },
    setSymptomsReport: (state, action: PayloadAction<QuestionsAnswersModel>) => {
      state.symptomsReport = action.payload;
    },
    appendNewNotification: (state, action: PayloadAction<NotificationView>) => {
      state.notifications.unshift(action.payload);
    },
    setSymptomsReportHistoryFilter: (
      state,
      action: PayloadAction<SymptomsReportHistoryFilterEnum>,
    ) => {
      state.symptomsReportHistoryFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(loadNotificationsDataAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(loadNotificationsDataAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.notifications = action.payload.notifications;
        state.totalCount = action.payload.totalCount;
        state.unreadCount = action.payload.unreadCount;
        state.notificationsFetchingOffset = action.payload.offset;
      })
      .addCase(loadPastAppointmentsAsync.rejected, (state) => {
        state.pastAppointmentsStatus = "failed";
      })
      .addCase(loadPastAppointmentsAsync.pending, (state) => {
        state.pastAppointmentsStatus = "loading";
      })
      .addCase(loadPastAppointmentsAsync.fulfilled, (state, action) => {
        state.pastAppointmentsStatus = "idle";
        state.pastAppointments[action.meta.arg.userId] = action.payload as MbscCalendarEvent[];
      })
      .addCase(loadNotificationReportAnswersAsync.pending, (state) => {
        state.symptomsReportStatus = "loading";
      })
      .addCase(loadNotificationReportAnswersAsync.fulfilled, (state, action) => {
        state.symptomsReportStatus = "idle";
        state.symptomsReport = action.payload;
      })
      .addCase(loadNotificationReportAnswersAsync.rejected, (state) => {
        state.symptomsReportStatus = "failed";
      })
      .addCase(markNotificationAsReadAsync.fulfilled, (state, payload) => {
        const readNotificationIndex = state.notifications.findIndex((notification) =>
          payload.meta.arg.markAsRead.notification_ids.includes(notification.notification_id),
        );
        state.notifications[readNotificationIndex].read = true;
        state.unreadCount -= 1;
      })
      .addCase(loadLastSystomsReportedAsync.pending, (state) => {
        state.symptomsReportHistoryStatus = "loading";
      })
      .addCase(loadLastSystomsReportedAsync.fulfilled, (state, action) => {
        state.symptomsReportHistoryStatus = "idle";
        state.symptomsReportHistory = action.payload;
      })
      .addCase(loadLastSystomsReportedAsync.rejected, (state) => {
        state.symptomsReportHistoryStatus = "failed";
      })
      .addCase(unregisterFirebaseTokenAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(unregisterFirebaseTokenAsync.fulfilled, (state) => {
        state.status = "idle";
      })
      .addCase(unregisterFirebaseTokenAsync.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(logout, () => {
        return initialState;
      })
      .addCase(localizedLogout, () => {
        return initialState;
      });
  },
});

// Actions

export const {
  increaseUnreadCount,
  setNotificationsFilter,
  showNotificationDetails,
  showQuestionsAnswers,
  setSymptomsReport,
  setSymptomsReportHistoryFilter,
} = notificationsSlice.actions;

export const selectNotificationsFilter = ({ notifications }: RootState): NotificationFilter =>
  notifications.filter;

export const selectNotificationsData = ({ notifications }: RootState): NotificationView[] =>
  notifications.notifications;

export const selectNotificationDetails = ({ notifications }: RootState): boolean =>
  notifications.showNotificationDetails;

export const selectQuestionsAnswers = ({ notifications }: RootState): boolean =>
  notifications.showQuestionsAnswers;

export const selectPatientId = ({ notifications }: RootState): string | undefined =>
  notifications.patientId;

export const selectSymptomsReport = ({
  notifications,
}: RootState): QuestionsAnswersModel | undefined => notifications.symptomsReport;

export const selectSymptomsReportStatus = ({ notifications }: RootState): Status =>
  notifications.symptomsReportStatus;

export const selectSymptomsReportHistoryStatus = ({ notifications }: RootState): Status =>
  notifications.symptomsReportHistoryStatus;

export const selectPastAppointmentsStatus = ({ notifications }: RootState): Status =>
  notifications.pastAppointmentsStatus;

export const selectNotificationsStatus = ({ notifications }: RootState): Status =>
  notifications.status;

export const selectTotalCount = ({ notifications }: RootState): number => notifications.totalCount;

export const selectUnreadCount = ({ notifications }: RootState): number =>
  notifications.unreadCount;

export const selectNotificationsFetchingOffset = ({ notifications }: RootState): number =>
  notifications.notificationsFetchingOffset;

export const selectSymptomsReportHistoryFilter = ({
  notifications,
}: RootState): SymptomsReportHistoryFilterEnum => notifications.symptomsReportHistoryFilter;

export const selectFilteredNotificationsfromToday = createSelector(
  [selectNotificationsData],
  (notifications) => {
    return notifications.filter((notification: NotificationView) =>
      dayjs(notification.date_created).isSame(dayjs(), "day"),
    );
  },
);

export const selectFilteredNotificationsfromYesterday = createSelector(
  [selectNotificationsData],
  (notifications) => {
    return notifications.filter((notification: NotificationView) =>
      dayjs(notification.date_created).isSame(dayjs().subtract(1, "day"), "day"),
    );
  },
);

export const selectFilteredNotificationsfromEarlier = createSelector(
  [selectNotificationsData],
  (notifications) => {
    return notifications.filter((notification: NotificationView) => {
      return (
        dayjs(notification.date_created).isBefore(dayjs().subtract(1, "day")) &&
        !dayjs(notification.date_created).isSame(dayjs().subtract(1, "day"), "day")
      );
    });
  },
);

export const selectNotifications = ({ notifications }: RootState): NotificationView[] =>
  notifications.notifications;

export const selectActiveNotificationSymptomDetails =
  (activeNotification?: NotificationView) =>
  ({ notifications }: RootState): ReportItem | undefined =>
    notifications.symptomsReportHistory.find(
      (symptomReportItem) =>
        symptomReportItem.id.toString() ===
        (activeNotification?.payload.data as NotificationPayloadData)?.symptom_report_id,
    );

export const selectSymptomsReportHistory = (
  { notifications }: RootState,
  activeNotification?: NotificationView,
): ReportItem[] =>
  notifications.symptomsReportHistory.filter(
    (symptomReportItem) =>
      symptomReportItem.id.toString() !==
      (activeNotification?.payload.data as NotificationPayloadData)?.symptom_report_id,
  );

export const selectFilteredSymptomsReportHistoryActive = (
  activeNotification?: NotificationView,
): ((state: RootState) => ReportItem[]) =>
  createSelector(
    [
      (state) => selectSymptomsReportHistoryFilter(state),
      (state) => selectSymptomsReportHistory(state, activeNotification),
    ],
    (filter, symptomsReport) => {
      return symptomsReport.filter(
        (symptomReport: ReportItem) =>
          filter !== SymptomsReportHistoryFilterEnum.Resolved &&
          symptomReport.status !== ReportStatusEnum.Resolved,
      );
    },
  );

export const selectFilteredSymptomsReportHistoryResolved = createSelector(
  [selectSymptomsReportHistoryFilter, selectSymptomsReportHistory],
  (filter, symptomsReport) => {
    return symptomsReport.filter(
      (symptomReport: ReportItem) =>
        filter === SymptomsReportHistoryFilterEnum.Resolved &&
        symptomReport.status === ReportStatusEnum.Resolved,
    );
  },
);

export default notificationsSlice.reducer;
