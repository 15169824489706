import dayjs from "dayjs";
import React from "react";
import { Box, Typography, styled } from "@mui/material";
import { verisColors, dateFormats } from "@veris-health/web-core";
import { useAppSelector } from "../../../../../hooks/useAppSelector";
import {
  SensorsGraphView,
  selectGeneratedRangeDates,
} from "../../../../PatientDetailsMeasurements/measurementSlice";

const StyledCalendarLabel = styled("div")(({ theme }) => ({
  width: "100%",
  height: "30px",
  padding: theme.spacing(0.25),
  display: "flex",
  alignItems: "center",
  backgroundColor: theme.veris.colors.neutrals["grey-1"],
  flexDirection: "row",
  borderStyle: "solid",
  borderWidth: "2px",
  borderColor: theme.veris.colors.neutrals["grey-light"],
  borderRadius: theme.shape.borderRadius,
}));

interface DateHeaderProps {
  startDate: string;
  endDate: string;
  view: SensorsGraphView;
}

export function DateHeader({ startDate, endDate, view }: DateHeaderProps): JSX.Element {
  const range = useAppSelector(selectGeneratedRangeDates("day"));

  return (
    <Box pt={4} className="break-before">
      <StyledCalendarLabel>
        <Box width={150}>Days</Box>
        <Box display="flex" justifyContent="space-around" width="100%" flex={1} pl={3}>
          {range.map((date) => {
            return (
              <Typography
                variant="subtitle2"
                component="span"
                color={verisColors.neutrals["grey-mid"]}
                minWidth="min-content"
                maxWidth="fit-content"
              >
                {dayjs(date).format(view === "monthly" ? dateFormats.D : dateFormats["ddd MMM D"])}
              </Typography>
            );
          })}
        </Box>
      </StyledCalendarLabel>
      {view === "monthly" && (
        <Box display="flex" justifyContent="space-between" p={1} pl="150px">
          <Typography variant="subtitle2" component="span" color={verisColors.neutrals["grey-mid"]}>
            {dayjs(startDate).format(dateFormats.MMM)}
          </Typography>
          <Typography variant="subtitle2" component="span" color={verisColors.neutrals["grey-mid"]}>
            {dayjs(endDate).format(dateFormats.MMM)}
          </Typography>
        </Box>
      )}
    </Box>
  );
}
