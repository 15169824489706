import { Box, Toolbar, Typography, AppBar, styled, Breadcrumbs, Badge } from "@mui/material";
import React, { useEffect } from "react";
import { matchPath, useHistory, useLocation, Link } from "react-router-dom";
import { VrsIconButton, IconName, VrsDivider, verisColors } from "@veris-health/web-core";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { useHospitals, useProfile } from "../../../../context/profile";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import { DoctorCard } from "../../../../ui/components/DoctorCard";
import { replaceRouteParam, Routes } from "../../../../routes-config";
import { setHospital } from "../../../shared/slices/actions";
import NotificationsContainer from "../../../Notifications/NotificationsContainer";
import {
  selectAllPatientsFilter,
  selectCurrentHospital,
  setAllPatientsFilter,
  resetFilters,
} from "../../../shared/slices/patientsSlice";
import { searchPatientsAsync } from "../../../shared/slices/asyncThunks";
import { StyledActiveNavigationItem, StyledNavigationItem } from "../../../shared/styledComponents";
import {
  fetchChatConfiguration,
  getUnreadPerThreadAsync,
  selectChatUserAccessToken,
  selectHasUnreadMessages,
} from "../../../Communication/store";
import { selectUserId } from "../../../shared/slices/authSlice";
import { useAzureChatClient } from "../../../Communication/hooks/useAzureChatClient";
import { useCurrentPatient } from "../../../../hooks/useCurrentPatient";
import { UnconfirmedBillingNotificationsContainer } from "../../../Billing/containers/UnconfirmedBillingNotificationsContainer/UnconfirmedBillingNotificationsContainer";

export interface NavBarProps {
  title: string | JSX.Element;
  menuItems: string[];
  activeTab: { [tabIndex: string]: number };
  onTabClick: (index: number) => void;
  breadcrumbItems: string[];
}

export const StyledBreadCrumbLink = styled(Link)(({ theme }) => ({
  ...theme.typography.h3,
  margin: theme.spacing(0, 1),
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: 14,
    top: 13,
    background: theme.veris.colors.pink.normal,
    color: theme.veris.colors.neutrals.white,
    width: "8px",
    minWidth: "8px",
    height: "8px",
    paddingTop: "2px",
    cursor: "pointer",
    borderRadius: "50%",
  },
}));

export function NavBarContainer({
  title,
  menuItems,
  activeTab,
  onTabClick,
  breadcrumbItems,
}: NavBarProps): JSX.Element {
  const selectedHospital = useAppSelector(selectCurrentHospital);
  const isAllPatientsChecked = useAppSelector(selectAllPatientsFilter);
  const currentPatient = useCurrentPatient();
  const userId = useAppSelector(selectUserId);
  const hasUnreadMessages = useAppSelector(selectHasUnreadMessages);
  const token = useAppSelector(selectChatUserAccessToken);

  const profile = useProfile();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();
  const hospitals = useHospitals();

  const onHospitalSelect = (hospitalId: string) => {
    dispatch(setHospital(hospitalId));
  };
  const onAllPatientsToggle = (allPatients: boolean) => {
    dispatch(resetFilters());
    dispatch(setAllPatientsFilter({ allPatients }));
  };
  const isCommunication = matchPath(history.location.pathname, { path: Routes.COMMUNICATION });

  useEffect(() => {
    dispatch(searchPatientsAsync({ allPatients: isAllPatientsChecked }));
    dispatch(fetchChatConfiguration({ id: Number(userId) }));
  }, [dispatch, isAllPatientsChecked]);

  useEffect(() => {
    if (userId && token) {
      dispatch(getUnreadPerThreadAsync(Number(userId)));
    }
  }, [userId, token]);

  useAzureChatClient();

  return (
    <Box
      sx={{
        flexGrow: 1,
        backgroundColor: verisColors.neutrals.white,
        position: "relative",
        zIndex: 1,
      }}
    >
      <AppBar
        position="static"
        color="transparent"
        sx={{ boxShadow: `1px 1px ${verisColors.neutrals["grey-2"]}` }}
      >
        <Toolbar sx={{ height: "86px", zIndex: (theme) => theme.zIndex.drawer + 1 }}>
          {menuItems.map((menuTitle, index) => {
            return (
              <Box key={menuTitle}>
                {activeTab.tabIndex === index ? (
                  <StyledActiveNavigationItem variant="h5" m={2} key={menuTitle}>
                    {menuTitle}
                  </StyledActiveNavigationItem>
                ) : (
                  <StyledNavigationItem
                    m={2}
                    variant="h5"
                    key={menuTitle}
                    onClick={() => {
                      onTabClick(index);
                    }}
                  >
                    {menuTitle}
                  </StyledNavigationItem>
                )}
              </Box>
            );
          })}
          {breadcrumbItems.length > 0 && (
            <Breadcrumbs>
              {breadcrumbItems.map((item, i, { length }) => (
                <StyledBreadCrumbLink
                  key={item}
                  to={
                    item === "Patient Overview" && currentPatient
                      ? replaceRouteParam(
                          "PATIENT_DETAILS",
                          ":userId",
                          currentPatient?.id.toString(),
                        )
                      : location.pathname
                  }
                  sx={{
                    color: (theme) =>
                      item === "Patient Overview"
                        ? theme.veris.colors.neutrals["grey-4"]
                        : theme.veris.colors.amethyst.normal,
                    textDecoration: length - 1 === i ? "none" : undefined,
                  }}
                >
                  {item}
                </StyledBreadCrumbLink>
              ))}
            </Breadcrumbs>
          )}

          <Typography
            variant="h3"
            sx={{ margin: (theme) => theme.spacing(1, 1, 0, 0) }}
            color={(theme) => theme.veris.colors.neutrals["grey-4"]}
          >
            {title}
          </Typography>
          <Box sx={{ flexGrow: 1 }} />

          <Box display="flex" gap={1} pr={1}>
            <UnconfirmedBillingNotificationsContainer />
            <StyledBadge invisible={!hasUnreadMessages} variant="dot">
              <VrsIconButton
                onClick={() => {
                  history.push(Routes.COMMUNICATION);
                }}
                data-test-hook="global-chat"
                disabled={Boolean(isCommunication)}
                iconProps={{
                  name: isCommunication ? IconName.ActiveChat : IconName.Chat,
                  size: 36,
                }}
              />
            </StyledBadge>
            <NotificationsContainer />
          </Box>

          <VrsDivider orientation="vertical" height="50%" />
          <DoctorCard
            selectedHospital={selectedHospital}
            onHospitalSelect={onHospitalSelect}
            hospitals={hospitals}
            profile={profile}
            isAllPatientsChecked={isAllPatientsChecked}
            toggleAllPatients={onAllPatientsToggle}
          />
        </Toolbar>
      </AppBar>
    </Box>
  );
}
