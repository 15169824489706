import { SupportTeamMember, CareTeamMemberInfo } from "@veris-health/user-ms/lib/v1";
import { teamsApiV1 } from "../../../../api";

export const getCareTeamForPatient = async (patientId: number): Promise<CareTeamMemberInfo[]> => {
  const response = await teamsApiV1.getPatientCareTeam(patientId);
  return response.data;
};

export const getSupportTeamForPatient = async (patientId: number): Promise<SupportTeamMember[]> => {
  const response = await teamsApiV1.getPatientSupportTeam(patientId);
  return response.data;
};
