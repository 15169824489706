import React from "react";
import { useHistory } from "react-router-dom";
import { Box } from "@mui/material";
import {
  usePropsFor,
  VideoGallery,
  ControlBar,
  DEFAULT_COMPONENT_ICONS,
  CameraButton,
  MicrophoneButton,
  EndCallButton,
} from "@azure/communication-react";
import { VrsIconButton, IconName } from "@veris-health/web-core";
import { selectCallStatus, setWindowedMode } from "../../../shared/slices/voipSlice";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import { PAGE_NAME } from "../../../../constants";
import { replaceRouteParam } from "../../../../routes-config";

export function VideoWindowedUI(): JSX.Element {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const videoGalleryProps = usePropsFor(VideoGallery);
  const cameraProps = usePropsFor(CameraButton);
  const microphoneProps = usePropsFor(MicrophoneButton);
  const endCallProps = usePropsFor(EndCallButton);
  const callStatus = useAppSelector(selectCallStatus);

  const onMaximizeClick = () => {
    if (callStatus.callUrl) {
      history.push({
        pathname: replaceRouteParam("VIDEO_COMMUNICATION", ":userId", callStatus.callUrl.patientId),
        search: callStatus.callUrl.searchParams,
        state: { from: PAGE_NAME },
      });
      dispatch(setWindowedMode(false));
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100%",
      }}
    >
      <Box
        position="absolute"
        sx={{
          right: "5%",
          top: "15px",
          zIndex: 1350,
          backgroundColor: ({ veris }) => veris.colors.amethyst.normal,
        }}
      >
        <VrsIconButton iconProps={{ name: IconName.Maximize }} onClick={onMaximizeClick} />
      </Box>
      {videoGalleryProps && (
        <VideoGallery {...videoGalleryProps} remoteVideoViewOptions={{ scalingMode: "Fit" }} />
      )}
      <Box>
        <ControlBar>
          {cameraProps && (
            <CameraButton
              {...cameraProps}
              onRenderIcon={(props) =>
                props?.checked
                  ? DEFAULT_COMPONENT_ICONS.ControlButtonCameraOn
                  : DEFAULT_COMPONENT_ICONS.ControlButtonCameraOff
              }
            />
          )}
          {microphoneProps && (
            <MicrophoneButton
              {...microphoneProps}
              onRenderIcon={(props) =>
                props?.checked
                  ? DEFAULT_COMPONENT_ICONS.ControlButtonMicOn
                  : DEFAULT_COMPONENT_ICONS.ControlButtonMicOff
              }
            />
          )}
          {endCallProps && (
            <EndCallButton
              {...endCallProps}
              onHangUp={endCallProps.onHangUp}
              onRenderIcon={() => DEFAULT_COMPONENT_ICONS.ControlButtonEndCall}
            />
          )}
        </ControlBar>
      </Box>
    </Box>
  );
}
