import { useTheme, Box, Typography } from "@mui/material";
import React from "react";
import dayjs from "dayjs";
import { verisColors } from "@veris-health/web-core";

interface ReviewRpmHeaderProps {
  date: string;
  totalRpmTime: number;
  systemRpmTime: number;
}

export const ReviewRpmHeader = ({
  date,
  totalRpmTime,
  systemRpmTime,
}: ReviewRpmHeaderProps): JSX.Element => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        backgroundColor: theme.veris.colors.neutrals["soft-white"],
        padding: theme.spacing(2),
        borderRadius: "6px",
        marginY: theme.spacing(3),
      }}
    >
      <Box sx={{ display: "flex", gap: theme.spacing(1) }}>
        <Typography color={verisColors.neutrals["grey-4"]} variant="subtitle1">
          Current month:
        </Typography>
        <Typography color={verisColors.neutrals["grey-4"]} variant="subtitle2">
          {dayjs(date).format("MMMM 1/YY")} -{" "}
          {dayjs(date).format(`MMMM ${dayjs(date).daysInMonth()}/YY`)}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", gap: theme.spacing(1), marginTop: theme.spacing(2) }}>
        <Box sx={{ display: "flex", gap: theme.spacing(1) }}>
          <Typography color={verisColors.neutrals["grey-4"]} variant="subtitle1">
            Total Clinical Time:
          </Typography>
          <Typography color={verisColors.neutrals["grey-4"]} variant="subtitle2">
            {totalRpmTime} minutes
          </Typography>
        </Box>
        <Typography color={verisColors.neutrals["grey-4"]} variant="subtitle1">
          •
        </Typography>
        <Box sx={{ display: "flex", gap: theme.spacing(1) }}>
          <Typography color={verisColors.neutrals["grey-4"]} variant="subtitle1">
            System Automated Clinical Time:
          </Typography>
          <Typography color={verisColors.neutrals["grey-4"]} variant="subtitle2">
            {systemRpmTime} minutes
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
