import React from "react";
import { EditorPlugin } from "@draft-js-plugins/editor";
import { CompositeDecorator, ContentBlock, ContentState } from "draft-js";
import linkifyIt from "linkify-it";
import { verisColors } from "@veris-health/web-core";

const linkify = linkifyIt();
export function useOnClickLink(url: string): (e: React.MouseEvent) => void {
  return React.useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      const match = linkify.match(url);
      if (match && match.length > 0) {
        window.open(match[0].url, "_blank");
      }
    },
    [url],
  );
}

export interface LinkProps {
  contentState: ContentState;
  entityKey: string;
  children: JSX.Element;
}

export const Link = ({ entityKey, contentState, children }: LinkProps): JSX.Element => {
  const { url } = contentState.getEntity(entityKey).getData();

  const onClick = useOnClickLink(url);
  return (
    <a
      style={{ color: verisColors.amethyst.normal }}
      onClick={onClick}
      href={url}
      target="_blank"
      rel="noreferrer"
    >
      {children}
    </a>
  );
};

const linkStrategy = (
  contentBlock: ContentBlock,
  callback: (start: number, end: number) => void,
  contentState: ContentState,
) => {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();

    return entityKey !== null && contentState.getEntity(entityKey).getType() === "LINK";
  }, callback);
};

/**
 * This plugin is responsible for properly rendering link entities.
 *
 * @returns EditorPlugin
 */
export const createLinkPlugin = (): EditorPlugin => ({
  decorators: [
    {
      strategy: linkStrategy,
      component: Link,
    },
  ],
});

export const linkDecorator = new CompositeDecorator([
  {
    strategy: linkStrategy,
    component: Link,
  },
]);
