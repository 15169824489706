import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { NotificationType } from "@veris-health/communication-ms/lib/v1";
import { IconName, VrsIconButton, verisColors } from "@veris-health/web-core";
import { SymptomActionNotificationViewData } from "@veris-health/communication-ms/lib/v1/api";
import { replaceRouteParam, Routes } from "../../../../routes-config";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { VrsPatientInfo } from "../../../shared/interfaces";
import {
  selectQuestionsAnswers,
  showQuestionsAnswers,
  selectSymptomsReport,
  loadNotificationReportAnswersAsync,
  selectActiveNotificationSymptomDetails,
  loadLastSystomsReportedAsync,
  selectSymptomsReportHistoryStatus,
  selectSymptomsReportStatus,
} from "../../notificationsSlice";
import { ReportAnswersList } from "../../ReportAnswersList";
import { NotificationFooter } from "./NotificationFooter";
import { NotificationHeader } from "./NotificationHeader";
import { LastSymptomsReported } from "./LastSymptomsReported";
import { SymptomNotificationDetails } from "./SymptomNotificationDetails";
import { VrsSymptomNotificationDescription } from "../../../../ui/components/VrsSymptomNotificationDescription";
import { useNotification } from "../../hooks/useNotification";
import { setEventCreationVisibility } from "../../../Calendar/calendarSlice";
import { useBilling } from "../../../../hooks/useBilling";
import { isSystemNotification } from "../../../../constants";
import { symptomsApiV1 } from "../../../../api";

export interface SymptomNotificationsProps {
  data: SymptomActionNotificationViewData;
  type: NotificationType;
  date: string;
}

export const SymptomNotificationsContainer = ({
  data,
  type,
  date,
}: SymptomNotificationsProps): JSX.Element => {
  const [subtitle, setSubtitle] = useState("Report Details");
  const seeQuestionsAnswers = useAppSelector(selectQuestionsAnswers);
  const reportQAList = useAppSelector(selectSymptomsReport);
  const reportQAListStatus = useAppSelector(selectSymptomsReportStatus);
  const [selectedReportId, setSelectedReportId] = useState<number | undefined>();
  const activeNotification = useNotification();
  const activeNotificationSymptomDetails = useAppSelector(
    selectActiveNotificationSymptomDetails(activeNotification),
  );
  const symptomsReportHistoryStatus = useAppSelector(selectSymptomsReportHistoryStatus);
  const history = useHistory();
  const dispatch = useAppDispatch();

  useBilling(Number(data.reporter_id));

  const dispatchSymptomsReported = () => {
    dispatch(
      loadLastSystomsReportedAsync({
        reporterId: data.reporter_id,
      }),
    );
  };

  const handleSeeAnswers = (reportId?: string) => {
    dispatch(showQuestionsAnswers(true));
    dispatch(
      loadNotificationReportAnswersAsync({
        reporterId: data.reporter_id,
        reportId: reportId || data.symptom_report_id,
      }),
    );

    if (reportId) {
      setSelectedReportId(Number(reportId));
      setSubtitle("Last Symptoms reported");
    } else {
      setSelectedReportId(undefined);
      setSubtitle("Report Details");
    }
  };

  React.useEffect(() => {
    if (reportQAListStatus === "idle" && reportQAList && !reportQAList.is_reviewed) {
      symptomsApiV1.reviewSymptomReport(
        Number(data.reporter_id),
        selectedReportId || Number(data.symptom_report_id),
      );
    }
  }, [reportQAList, reportQAListStatus]);

  return (
    <>
      <Box minHeight="92%">
        <NotificationHeader
          notificationDetails={data.patient as unknown as VrsPatientInfo}
          date={date}
          systemNotification={isSystemNotification(type)}
        />

        {!seeQuestionsAnswers && (
          <Box flexDirection="column">
            <Box paddingX={0.7}>
              <VrsSymptomNotificationDescription data={data} />
            </Box>
            {data.patient?.id ? (
              <>
                <Box
                  paddingX={1.7}
                  borderBottom={`2px solid ${verisColors.neutrals["grey-light"]}`}
                  my={2}
                >
                  <Box mb={3}>
                    <Typography mb={2.3} variant="h6" fontWeight={600}>
                      Report Details
                    </Typography>
                    <SymptomNotificationDetails
                      activeNotificationSymptomDetails={activeNotificationSymptomDetails}
                      loadReportedSymptoms={dispatchSymptomsReported}
                      symptomsReportHistoryStatus={symptomsReportHistoryStatus}
                    />
                  </Box>
                  <Box my={1.3}>
                    <Typography marginBottom={1} variant="subtitle1">
                      Questions and answers
                    </Typography>
                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                      <Box pr={1} mr={1} maxHeight="43px" overflow="scroll">
                        <Typography variant="body" color={verisColors.neutrals["grey-mid"]}>
                          You are able to see the patient answers to the specific questions of this
                          particular symptom from the symptom survey.
                        </Typography>
                      </Box>
                      <Box
                        sx={{ cursor: "pointer" }}
                        minWidth="fit-content"
                        onClick={() => handleSeeAnswers()}
                      >
                        <Typography
                          style={{ textDecoration: "underline" }}
                          variant="body2"
                          color={verisColors.neutrals.black}
                        >
                          See answers
                          <VrsIconButton
                            iconProps={{
                              name: IconName.ToggleOpen,
                              size: 26,
                              stroke: `${verisColors.neutrals.black}`,
                              rectstroke: "none",
                            }}
                          />
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>

                <LastSymptomsReported
                  handleSeeAnswers={handleSeeAnswers}
                  loadReportedSymptoms={dispatchSymptomsReported}
                  symptomsReportHistoryStatus={symptomsReportHistoryStatus}
                />
              </>
            ) : (
              <Box mb={3} mt={3}>
                <Typography pl={2} variant="h6" fontWeight={600}>
                  This Patient was Deleted
                </Typography>
              </Box>
            )}
          </Box>
        )}
        {seeQuestionsAnswers && (
          <ReportAnswersList
            subtitle={subtitle}
            reportQAListStatus={reportQAListStatus}
            reportQAList={reportQAList?.report}
            loadReportQAList={handleSeeAnswers}
            reportName={reportQAList?.symptom.value}
          />
        )}
      </Box>
      {data.patient?.id && (
        <NotificationFooter
          notificationType={type}
          onSeePatientProfileClick={() =>
            history.push(replaceRouteParam("PATIENT_DETAILS", ":userId", String(data.reporter_id)))
          }
          onScheduleAppointmentClick={() => {
            history.push(Routes.CALENDAR);
            dispatch(
              setEventCreationVisibility({
                isOpen: true,
                patientId: +data.reporter_id,
              }),
            );
          }}
        />
      )}
    </>
  );
};
