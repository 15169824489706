import {
  GetAppointmentResponse as Appointment,
  AppointmentCreateRequest,
} from "@veris-health/med-data-ms/lib/v1";
import { appointmentsApiV1 } from "../../../api";

export const bookAppointment = async (
  userId: number,
  event: AppointmentCreateRequest,
): Promise<Appointment> => {
  const response = await appointmentsApiV1.bookAppointment(userId, event);
  return response.data;
};

export const updateAppointment = async (
  userId: number,
  appointmentId: string,
  updatedAppointment: AppointmentCreateRequest,
): Promise<void> => {
  const response = await appointmentsApiV1.updateAppointment(
    userId,
    appointmentId,
    updatedAppointment,
  );
  return response.data;
};
