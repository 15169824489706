import React from "react";
import {
  Box,
  styled,
  Table,
  TableContainer,
  tableContainerClasses,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { HospitalMedStaff } from "@veris-health/user-ms/lib/v1";
import { Link } from "react-router-dom";
import {
  StyledTableBody,
  StyledTableCell,
  StyledTableRow,
} from "../../../../ui/components/Tables/shared/StyledTableElements";
import { VrsStackedAvatars } from "../../../../ui/components/VrsStackedAvatars";

const StyledLink = styled(Link)(({ theme }) => ({
  ...theme.typography.body,
  color: theme.veris.colors.neutrals.black,
}));

export interface HospitalsTableProps {
  hospitals: HospitalMedStaff[];
}

const enum TableColumnNames {
  name = "Name",
  location = "Location",
  workStatus = "Work Status",
  currentSchedule = "Current Schedule",
  medicalTeam = "Medical Team",
}

interface Column {
  id: string;
  label: TableColumnNames;
}

const columns: Column[] = [
  { id: TableColumnNames.name, label: TableColumnNames.name },
  { id: TableColumnNames.medicalTeam, label: TableColumnNames.medicalTeam },
];

export const StyledTableContainer = styled(TableContainer)(() => ({
  [`&.${tableContainerClasses.root}`]: {
    maxHeight: "155px",
    overflowY: "scroll",
  },
}));

export function HospitalsTable({ hospitals }: HospitalsTableProps): JSX.Element {
  return (
    <StyledTableContainer>
      <Table>
        <TableHead>
          <TableRow
            sx={{ borderBottom: (theme) => `2px solid ${theme.veris.colors.neutrals["grey-2"]}` }}
          >
            {columns.map((column) => (
              <StyledTableCell key={column.id}>
                <Typography
                  variant="subtitle24"
                  component="span"
                  sx={{
                    textTransform: "uppercase",
                    color: (theme) => theme.veris.colors.neutrals["grey-3"],
                    fontWeight: 500,
                  }}
                >
                  {column.label}
                </Typography>
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <StyledTableBody>
          {hospitals.map((hospital: HospitalMedStaff) => (
            <StyledTableRow key={hospital.hospital_name}>
              {columns.map((column) => {
                return (
                  <StyledTableCell key={column.id}>
                    {column.id === TableColumnNames.name && (
                      <Typography
                        variant="subtitle2"
                        component="span"
                        sx={{ textTransform: "unset" }}
                      >
                        {hospital.hospital_name}
                      </Typography>
                    )}

                    {column.label === TableColumnNames.medicalTeam && (
                      <Box display="flex" alignItems="center">
                        <VrsStackedAvatars
                          avatars={hospital.med_staff.map((teamMember) => {
                            return {
                              name: `${teamMember.first_name} ${teamMember.last_name}`,
                              image: "",
                              id: teamMember.id,
                            };
                          })}
                          maxAvatars={10}
                          size={30}
                          stackStep={1}
                        />

                        <StyledLink to={`/doctor-profile/hospitals/${hospital.id}/medical-staff`}>
                          View All
                        </StyledLink>
                      </Box>
                    )}
                  </StyledTableCell>
                );
              })}
            </StyledTableRow>
          ))}
        </StyledTableBody>
      </Table>
      {hospitals.length === 0 && (
        <Typography
          color={(theme) => theme.veris.colors.neutrals["grey-3"]}
          textAlign="center"
          variant="body"
          p={2}
        >
          There are no hospitals at the moment.
        </Typography>
      )}
    </StyledTableContainer>
  );
}
