import {
  AbnormalReadingType,
  ClinicalTaskDataAbnormalReadingTypeEnum,
  ClinicalTaskDataOtherTypeEnum,
  ClinicalTaskDataSymptomReportTypeEnum,
  ClinicalTaskPriorityEnum,
  ClinicalTaskSortingEnum,
  ClinicalTaskStatusEnum,
} from "@veris-health/med-data-ms/lib/v1";
import { IconName, verisColors } from "@veris-health/web-core";
import { uniqBy } from "lodash";

export const sortOptions = [
  {
    label: "Sorted by Due Date",
    value: ClinicalTaskSortingEnum.DueDateAsc,
    icon: IconName.Asc,
  },
  {
    label: "Sorted by Due Date",
    value: ClinicalTaskSortingEnum.DueDateDesc,
    icon: IconName.Desc,
  },
  {
    label: "Sorted by Date Created",
    value: ClinicalTaskSortingEnum.DateCreatedAsc,
    icon: IconName.Asc,
  },
  {
    label: "Sorted by Date Created",
    value: ClinicalTaskSortingEnum.DateCreatedDesc,
    icon: IconName.Desc,
  },
  {
    label: "Sorted by Priority",
    value: ClinicalTaskSortingEnum.PriorityAsc,
    icon: IconName.Asc,
  },
  {
    label: "Sorted by Priority",
    value: ClinicalTaskSortingEnum.PriorityDesc,
    icon: IconName.Desc,
  },
];

export const priorityOptions = [
  {
    label: "High",
    value: ClinicalTaskPriorityEnum.High,
    icon: IconName.PriorityHigh,
    color: verisColors.errors.normal,
  },
  {
    label: "Medium",
    value: ClinicalTaskPriorityEnum.Medium,
    icon: IconName.PriorityMedium,
    color: verisColors.mango.normal,
  },
  {
    label: "Low",
    value: ClinicalTaskPriorityEnum.Low,
    icon: IconName.PriorityLow,
    color: verisColors.amethyst.normal,
  },
];

export const statusOptions = [
  { label: "Unresolved", value: ClinicalTaskStatusEnum.Unresolved },
  { label: "Resolved", value: ClinicalTaskStatusEnum.Resolved },
];

export const taskTypeOptions = [
  { label: "Symptoms", value: ClinicalTaskDataSymptomReportTypeEnum.Symptom },
  { label: "Abnormal Vitals", value: ClinicalTaskDataAbnormalReadingTypeEnum.AbnormalReading },
  { label: "Other", value: ClinicalTaskDataOtherTypeEnum.Other },
];
export type SensorAutocompleteValue = { id: AbnormalReadingType };

export type SensorAutocompleteOption = {
  label: string;
  value: SensorAutocompleteValue;
  icon?: IconName;
};

export const implantOptions: SensorAutocompleteOption[] = [
  {
    label: "Temperature",
    value: { id: AbnormalReadingType.Temperature },
    icon: IconName.Temperature,
  },
  {
    label: "Heart Rate",
    value: { id: AbnormalReadingType.HeartRate },
    icon: IconName.BPM,
  },
  {
    label: "Respiratory Rate",
    value: { id: AbnormalReadingType.RespiratoryRate },
    icon: IconName.RPM,
  },
  {
    label: "Activity",
    value: { id: AbnormalReadingType.Steps },
    icon: IconName.Motion,
  },
  { label: "ECG", value: { id: AbnormalReadingType.Ecg }, icon: IconName.BPM },
];

export const mioOptions: SensorAutocompleteOption[] = [
  {
    label: "Temperature",
    value: { id: AbnormalReadingType.Temperature },
    icon: IconName.Temperature,
  },
  {
    label: "Heart Rate",
    value: { id: AbnormalReadingType.HeartRate },
    icon: IconName.BPM,
  },
  {
    label: "Oxygen Saturation",
    value: { id: AbnormalReadingType.So2 },
    icon: IconName.Oximeter,
  },
  {
    label: "Blood Pressure",
    value: { id: AbnormalReadingType.BloodPressure },
    icon: IconName.BloodPressure,
  },
  { label: "Weight", value: { id: AbnormalReadingType.Weight }, icon: IconName.Weight },
  {
    label: "Activity",
    value: { id: AbnormalReadingType.Steps },
    icon: IconName.Motion,
  },
];

export const gfahkOptions: SensorAutocompleteOption[] = [
  {
    label: "Activity",
    value: { id: AbnormalReadingType.Steps },
    icon: IconName.Motion,
  },
];

export const TaskSubtypeSensorsByDataSource: { [key: string]: SensorAutocompleteOption[] } = {
  mio_hub: mioOptions,
  implant: implantOptions,
  gf_ahk: gfahkOptions,
};

export const sensorOptionsData = uniqBy([...implantOptions, ...mioOptions], "label").reduce(
  (acc, sensor) => ({
    ...acc,
    [sensor.value.id]: { icon: sensor.icon as IconName, label: sensor.label },
  }),
  {} as { [x: string]: { icon: IconName; label: string } },
);
export type filterOptionsEnum = "all" | "assignedToMe" | "createdByMe";

export const filterOptions = [
  {
    label: "View all",
    value: "all",
  },
  {
    label: "Assigned to me",
    value: "assignedToMe",
  },
  {
    label: "Created by me",
    value: "createdByMe",
  },
];

export const emptyOption = { label: "No options", value: "" };
export const placeholderOption = { label: "Choose sub type", value: "" };
