import React from "react";
import { Container } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, ErrorBoundary } from "@veris-health/web-core";
import * as Sentry from "@sentry/react";
import VrsRouter from "./router/Router";
import { SessionContainer } from "./features/Session/SessionContainer";
import { VideoContext } from "./context/video";
import { SnackbarUtilsConfigurator } from "./utils/SnackbarUtils";
import { SnackbarProvider } from "./context/snackbar";

function App(): JSX.Element {
  return (
    <ThemeProvider>
      <ErrorBoundary
        onError={(error, errorInfo) => {
          Sentry.captureException(error, { extra: { errorInfo } });
        }}
      >
        <VideoContext>
          <SnackbarProvider>
            <SnackbarUtilsConfigurator />
            <Container className="App" maxWidth={false} disableGutters>
              <CssBaseline />
              <SessionContainer>
                <VrsRouter />
              </SessionContainer>
            </Container>
          </SnackbarProvider>
        </VideoContext>
      </ErrorBoundary>
    </ThemeProvider>
  );
}

export default App;
