import { orderBy, get } from "lodash";

export type Order = "asc" | "desc";

export function genericSorter<T, K extends keyof T>(array: T[], order: Order, field: K): T[] {
  const { arrayWithValues, arrayWithNulls } = array.reduce(
    (acc, el) => {
      const value = get(el, field, null);
      if (value || value === 0) {
        acc.arrayWithValues.push(el);
      } else {
        acc.arrayWithNulls.push(el);
      }
      return acc;
    },
    { arrayWithValues: [] as T[], arrayWithNulls: [] as T[] },
  );

  if (order === "asc") {
    return [...orderBy(arrayWithValues, field, "asc"), ...arrayWithNulls];
  }
  return [...orderBy(arrayWithValues, field, "desc"), ...arrayWithNulls];
}

export const DASHBOARD_DIRECTION = "dashboardOrderDirection";
export const DASHBOARD_FIELD = "dashboardOrderField";
