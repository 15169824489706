import { Box, styled, Typography } from "@mui/material";
import { verisColors, VrsTooltip } from "@veris-health/web-core";
import React from "react";

interface ActivityItemProps {
  activities: string[];
}

export const Wrapper = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.veris.colors.neutrals["grey-dark"],
  padding: theme.spacing(0.5),
  borderRadius: "4px",
  backgroundColor: theme.veris.colors.neutrals["grey-light"],
  minWidth: "23px",
  textAlign: "center",
}));

export const ActivityItem = ({ activities }: ActivityItemProps): JSX.Element => {
  const [firstActivity, ...rest] = activities;
  return (
    <Box sx={{ display: "flex", gap: (theme) => theme.spacing(0.5) }}>
      <Wrapper>{firstActivity}</Wrapper>
      {activities.length > 1 && (
        <VrsTooltip
          bcgcolor={verisColors.neutrals.black}
          title={
            <>
              {rest.map((item) => (
                <Typography
                  variant="body2"
                  sx={{ color: (theme) => theme.veris.colors.neutrals["soft-white"] }}
                  key={item}
                >
                  • {item}
                </Typography>
              ))}
            </>
          }
        >
          <Wrapper sx={{ cursor: "pointer" }}>+{rest.length}</Wrapper>
        </VrsTooltip>
      )}
    </Box>
  );
};
