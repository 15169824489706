import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { NotificationView } from "@veris-health/communication-ms/lib/v1";
import { useAppSelector } from "../../../hooks/useAppSelector";
import {
  NotificationFilter,
  selectNotifications,
  selectNotificationsFilter,
  selectNotificationsStatus,
} from "../notificationsSlice";
import { useQuery } from "../../../hooks/useQuery";

interface NotificationQueryParams {
  notificationId: string;
  userId?: string;
}

export function useNotification(): NotificationView | undefined {
  const query = useQuery();
  const userId = query.get("userId");
  const [notification, setNotification] = useState<NotificationView>();
  const { notificationId } = useParams<NotificationQueryParams>();
  const activeFilter = useAppSelector(selectNotificationsFilter);
  const allNotifications = useAppSelector(selectNotifications);
  const notificationStatus = useAppSelector(selectNotificationsStatus);

  const isFilteredNotification = !!(
    [
      NotificationFilter.CareTeam,
      NotificationFilter.Patients,
      NotificationFilter.System,
      NotificationFilter.ViewAll,
    ].includes(notificationId as NotificationFilter) && allNotifications[0]
  );

  useEffect(() => {
    if (notificationStatus === "idle" && allNotifications[0]) {
      if (userId && isFilteredNotification) {
        setNotification(allNotifications[0]);

        return;
      }

      if (isFilteredNotification && !userId) {
        setNotification(allNotifications[0]);

        return;
      }

      const selectedNotification = allNotifications.find(
        ({ notification_id: id }) => id === Number(notificationId),
      );

      if (selectedNotification) {
        setNotification(selectedNotification);
      }
    }
  }, [notificationId, activeFilter, allNotifications]);

  return notification;
}
