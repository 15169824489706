import React, { useEffect } from "react";
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  List,
  ListItem,
  Radio,
  Typography,
  styled,
} from "@mui/material";
import {
  IconName,
  VrsButton,
  VrsDivider,
  VrsIcon,
  VrsIconButton,
  verisColors,
} from "@veris-health/web-core";
import { useFormik } from "formik";
import * as yup from "yup";
import dayjs from "dayjs";
import { SubmitManualTCM, TCMBillingCodes } from "@veris-health/communication-ms/lib/v1";
import { VrsPatientInfo } from "../../../shared/interfaces";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import {
  addTCMBillingAsync,
  loadTCMBillingCodes,
  selectTCMBillingCodes,
} from "../../patientDetailsSlice";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import SnackbarUtils from "../../../../utils/SnackbarUtils";
import { dateFormatYearFirst } from "../../../../utils/date";
import { TCMCalendar } from "./TCMDateCalendar";

const StyledListItem = styled(ListItem)(({ theme }) => ({
  component: "li",
  padding: theme.spacing(0),
  margin: theme.spacing(0.5, 0),
}));

const BillingAttestation = () => {
  return (
    <Box
      sx={{
        marginY: (theme) => theme.spacing(2),
        padding: (theme) => theme.spacing(1),
        display: "flex",
        justifyContent: "space-between",
        gap: "10px",
        background: (theme) => theme.veris.colors.mango.light,
        borderRadius: "6px",
      }}
    >
      <Box width={16}>
        <VrsIcon name={IconName.AlertIcon} size={16} />
      </Box>
      <Typography component="p" variant="subtitle2" color={verisColors.neutrals["grey-4"]}>
        By checking this box, I attest that I have completed the following tasks required for
        Transitional Care Management:
        <List sx={{ padding: 0, margin: 0 }}>
          <StyledListItem>1. Contacted the patient within 2 days of discharge</StyledListItem>
          <StyledListItem>
            2. Completed a follow-up visit within 7 (high complexity) or 14 (moderate complexity)
            days from discharge
          </StyledListItem>
          <StyledListItem>3. Medication reconciliation</StyledListItem>
          <StyledListItem>4. Reviewed the discharge instructions</StyledListItem>
          <StyledListItem>
            5. Outpatient care coordination including follow up of pending test results, patient
            education, and outpatient referrals.
          </StyledListItem>
        </List>
      </Typography>
    </Box>
  );
};

export const CodesToDays: Record<TCMBillingCodes, number> = {
  99495: 14,
  99496: 7,
};

interface TCPCodeOptionsProps {
  checked: boolean;
  code: number;
  description: string;
  handleChange: (e: React.ChangeEvent<any>) => void;
}

const TCPCodeOptions = ({ checked, code, description, handleChange }: TCPCodeOptionsProps) => {
  return (
    <Box
      display="flex"
      sx={{
        backgroundColor: (theme) => theme.veris.colors.neutrals["grey-1"],
        border: checked ? (theme) => `1px solid ${theme.veris.colors.amethyst.normal}` : "none",
        borderRadius: (theme) => +theme.shape.borderRadius * 0.5,
      }}
      gap={1}
      alignItems="center"
      my={1}
      p={1}
    >
      <Box display="flex">
        <Radio
          sx={{ color: (theme) => theme.veris.colors.amethyst.normal }}
          checked={checked}
          onClick={handleChange}
          name="code"
          value={code}
        />
        <VrsDivider
          orientation="vertical"
          height="24px"
          sx={{ borderColor: (theme) => theme.veris.colors.neutrals["grey-2"] }}
        />
      </Box>
      <Box>
        <Typography variant="subtitle2">{code}</Typography>
        <Typography variant="caption" color={(theme) => theme.veris.colors.neutrals["grey-3"]}>
          {description}
        </Typography>
      </Box>
    </Box>
  );
};

interface AddTCMProps {
  open: boolean;
  onClose: () => void;
  patient: VrsPatientInfo;
}

const validationSchema = yup.object({
  code: yup.string().required("Code is required."),
  dateOccurred: yup.string().required("Date is required."),
  dischargeDate: yup.string().required("Date is required."),
  attestations: yup.bool().isTrue("Attestations must be checked"),
});

const initialValues = {
  code: undefined,
  dateOccurred: dayjs(),
  dischargeDate: undefined,
  attestations: false,
};

export const AddTCM = ({ open, onClose, patient }: AddTCMProps): JSX.Element => {
  const codes = useAppSelector(selectTCMBillingCodes);
  const dispatch = useAppDispatch();

  const {
    errors,
    isValid,
    setFieldValue,
    values,
    handleChange,
    dirty,
    resetForm,
    handleSubmit,
    isSubmitting,
  } = useFormik({
    validationSchema,
    initialValues,
    onSubmit: ({ dateOccurred, dischargeDate, code }) => {
      const payload: SubmitManualTCM = {
        date_occurred: dayjs(dateOccurred).format(dateFormatYearFirst),
        billing_code: code as unknown as TCMBillingCodes,
        discharge_date: dayjs(dischargeDate).format(dateFormatYearFirst),
      };
      dispatch(addTCMBillingAsync({ id: patient.id, payload })).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          SnackbarUtils.success("TCM billing added successfully!");
          onClose();
        }
        if (res.meta.requestStatus === "rejected") {
          SnackbarUtils.error((res.payload as string) || "Could not add TCM billing.");
        }
      });
    },
  });

  useEffect(() => {
    if (!codes) dispatch(loadTCMBillingCodes());
  }, []);

  function handleClose() {
    resetForm();
    onClose();
  }

  return (
    <Dialog open={open} onClose={() => handleClose()}>
      <Box sx={{ padding: (theme) => theme.spacing(3) }}>
        <form onSubmit={handleSubmit}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box>
              <Typography variant="h3" color={verisColors.neutrals["grey-4"]}>
                Add TCM Billing
              </Typography>
              <Typography variant="body" color={verisColors.neutrals["grey-3"]} pt={1}>
                This is for in-person visits only and not valid for Telemedicine Calls
              </Typography>
            </Box>
            <VrsIconButton onClick={() => handleClose()} iconProps={{ name: IconName.CloseIcon }} />
          </Box>
          <Box
            sx={{
              marginY: (theme) => theme.spacing(3),
              paddingY: (theme) => theme.spacing(2),
              borderBottom: (theme) => `1px solid ${theme.veris.colors.neutrals["grey-light"]}`,
            }}
          >
            <Box sx={{ display: "flex", gap: (theme) => theme.spacing(1) }}>
              <Typography variant="subtitle2" color={verisColors.neutrals["grey-3"]}>
                Patient Name:
              </Typography>
              <Typography
                component="span"
                variant="subtitle1"
                color={verisColors.neutrals["grey-dark"]}
              >
                {patient.name}
              </Typography>
            </Box>
          </Box>
          <Box>
            <Box minHeight={140}>
              <Typography variant="subtitle2" color={verisColors.neutrals["grey-3"]}>
                TCM Code:
                <span style={{ color: verisColors.errors.normal }}>*</span>
              </Typography>
              {codes &&
                Object.keys(codes).map((el, index) => (
                  <TCPCodeOptions
                    checked={values.code === el}
                    code={+el}
                    description={Object.values(codes)[index]}
                    handleChange={handleChange}
                    key={el}
                  />
                ))}
            </Box>
            {errors.code && (
              <Typography variant="subtitle2" color={verisColors.errors.normal} py={1}>
                {errors.code}
              </Typography>
            )}
            <TCMCalendar
              title="Date occurred:"
              value={values.dateOccurred}
              onChange={(date) => setFieldValue("dateOccurred", date)}
              max={dayjs().format()}
            />
            <TCMCalendar
              title="Date of discharge:"
              value={values.dischargeDate}
              onChange={(date) => setFieldValue("dischargeDate", date)}
              min={dayjs(values.dateOccurred)
                .endOf("day")
                .subtract(values.code ? CodesToDays[values.code] - 1 : 13, "days")
                .format()}
              max={dayjs(values.dateOccurred).endOf("day").format()}
            />
            <Box display="flex" alignItems="center">
              <Checkbox
                name="attestations"
                checked={!!values.attestations}
                onClick={handleChange}
                sx={{ color: (theme) => theme.veris.colors.amethyst.normal }}
              />
              <Typography variant="caption">
                Attestations <span style={{ color: verisColors.errors.normal }}>*</span>
              </Typography>
            </Box>
            {errors.attestations && (
              <Typography variant="subtitle2" color={verisColors.errors.normal} py={1}>
                {errors.attestations}
              </Typography>
            )}
            <BillingAttestation />
          </Box>
          <DialogActions>
            <VrsButton buttonType="secondary" onClick={() => handleClose()}>
              Cancel
            </VrsButton>
            <VrsButton
              buttonType="primary"
              type="submit"
              disabled={!isValid || !dirty || isSubmitting}
            >
              Submit TCM Billing
            </VrsButton>
          </DialogActions>
        </form>
      </Box>
    </Dialog>
  );
};
