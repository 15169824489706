import React from "react";
import { Box, CircularProgress } from "@mui/material";
import {
  FluentThemeProvider,
  CallClientProvider,
  CallAgentProvider,
  CallProvider,
} from "@azure/communication-react";
import { useVideo } from "../../../../context/video";
import { VideoWindowedUI } from "../VideoWindowedUI";

export function VideoWindowedSettings(): JSX.Element {
  const videoProps = useVideo();

  if (
    videoProps.callState === "Connecting" ||
    videoProps.callState === "Disconnecting" ||
    !videoProps.statefulCallClient ||
    !videoProps.callAgent ||
    !videoProps.call
  ) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" sx={{ mt: 2 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <FluentThemeProvider>
      <CallClientProvider callClient={videoProps.statefulCallClient}>
        <CallAgentProvider callAgent={videoProps.callAgent}>
          <CallProvider call={videoProps.call}>
            <VideoWindowedUI />
          </CallProvider>
        </CallAgentProvider>
      </CallClientProvider>
    </FluentThemeProvider>
  );
}
