import React from "react";
import { Box, styled, Typography } from "@mui/material";
import dayjs from "dayjs";
import { IconName, VrsIcon, VrsIconButton } from "@veris-health/web-core";
import { VrsFileAttachment } from "../../store";
import { getFileExtentionIcon } from "../../../shared/helpers";

export interface VrsAttachmentListItemProps {
  onDownloadAttachment: () => void;
  sentBy: string;
  attachment: VrsFileAttachment;
  isPrivateThread: boolean;
}

export interface StyledAttachmentItemProps {
  isPrivateThread: boolean;
}

const StyledFileName = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle1,
  textDecoration: "underline",
  color: theme.veris.colors.amethyst.normal,
  cursor: "pointer",
  maxWidth: "320px",
  whiteSpace: "nowrap",
}));

export const attachmentDateFormat = "MMM DD, YYYY";

const StyledAttachmentListItem = styled("div", {
  shouldForwardProp: (prop: string) => prop !== "isPrivateThread",
})<StyledAttachmentItemProps>(({ theme, isPrivateThread }) => ({
  backgroundColor: isPrivateThread
    ? theme.veris.colors.pink.light
    : theme.veris.colors.neutrals["soft-white"],
  borderRadius: theme.shape.borderRadius,
  padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
  height: "48px",
  display: "flex",
  justifyContent: "space-between",
}));

export const VrsAttachmentListItem = ({
  onDownloadAttachment,
  attachment,
  sentBy,
  isPrivateThread,
}: VrsAttachmentListItemProps): JSX.Element => {
  const { file_extension: fileExtension, imageSrc } = attachment;
  return (
    <StyledAttachmentListItem isPrivateThread={isPrivateThread}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: (theme) => theme.spacing(2),
        }}
      >
        {imageSrc ? (
          <Box
            component="img"
            sx={{
              maxHeight: { xs: 30, md: 30 },
              maxWidth: { xs: 50, md: 50 },
            }}
            src={imageSrc}
            alt=""
          />
        ) : (
          <VrsIcon name={getFileExtentionIcon(fileExtension)} />
        )}
        <StyledFileName onClick={onDownloadAttachment} textOverflow="ellipsis" overflow="hidden">
          {attachment.original_file_name}
        </StyledFileName>
        <Typography
          variant="subtitle2"
          sx={{ color: (theme) => theme.veris.colors.neutrals["grey-mid"] }}
        >
          {dayjs(attachment.date_created).format(attachmentDateFormat)} • Sent by {sentBy}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", gap: (theme) => theme.spacing(3) }}>
        <VrsIconButton
          iconProps={{ name: IconName.DownloadActive }}
          onClick={onDownloadAttachment}
        />
      </Box>
    </StyledAttachmentListItem>
  );
};
