import {
  CalendarPrev,
  CalendarNav,
  CalendarNext,
  SegmentedGroup,
  SegmentedItem,
  CalendarToday,
} from "@mobiscroll/react";
import { styled } from "@mui/material";
import { VrsIcon, IconName, VrsIconButton, VrsButton, verisColors } from "@veris-health/web-core";
import React from "react";
import { AgendaTypes, CalendarShownType } from "../../../features/Calendar/calendarSlice";

const StyledHeaderPicker = styled("div")(({ theme }) => ({
  flex: "1 0 auto",
  ".header-button": {
    borderRadius: "6px",
    margin: "0 8px",
    border: `1px solid ${theme.veris.colors.neutrals["grey-mid"]}`,
    ".mbsc-button": {
      ...theme.typography.button,
      color: theme.veris.colors.neutrals["grey-mid"],
      borderRadius: "6px",
      border: "none",
      paddingTop: "7px",
      "&.mbsc-selected": {
        borderRadius: "6px",
        border: `1px solid ${theme.veris.colors.amethyst.normal}`,
      },
    },
    "&:hover, &:focus, &:active": {
      border: `1px solid ${theme.veris.colors.amethyst.normal}`,
      ".mbsc-button": {
        color: theme.veris.colors.amethyst.normal,
        background: "none",
      },
    },
  },
}));

const StyledCalendarNav = styled(CalendarNav)(() => ({
  ".mbsc-icon": {
    display: "none",
  },
}));

export interface VrsCalendarNavigatorProps {
  view: string;
  changeCalendarView: (targetVal: AgendaTypes) => void;
  viewType: string;
  changeView?: (targetVal: CalendarShownType) => void;
  createNewEvent?: () => void;
  disableCreateEvent?: boolean;
}

export function VrsCalendarNavigator({
  viewType,
  view,
  changeCalendarView,
  changeView,
  createNewEvent,
  disableCreateEvent,
}: VrsCalendarNavigatorProps): JSX.Element {
  return (
    <>
      <VrsButton
        buttonType="secondary"
        onClick={createNewEvent}
        data-test-hook="vrsCalendarNavigator-create-button"
        disabled={disableCreateEvent}
      >
        Create new
        <span style={{ marginLeft: "5px", paddingTop: "2px" }}>
          <VrsIcon
            name={IconName.Plus}
            size={18}
            stroke={
              disableCreateEvent ? verisColors.neutrals["grey-3"] : verisColors.amethyst.normal
            }
          />
        </span>
      </VrsButton>
      <CalendarPrev className="cal-header-prev" />
      <StyledCalendarNav className="cal-header-nav" />
      <CalendarNext className="cal-header-next" />
      <StyledHeaderPicker>
        <SegmentedGroup
          value={view}
          onChange={(event: Event) =>
            changeCalendarView((event.target as HTMLInputElement)?.value as AgendaTypes)
          }
        >
          <SegmentedItem className="header-button" value="day">
            Daily
          </SegmentedItem>
          <SegmentedItem className="header-button" value="week">
            Weekly
          </SegmentedItem>
          {viewType === "timeline" && (
            <SegmentedItem className="header-button" value="bi-weekly">
              Bi-Weekly
            </SegmentedItem>
          )}
          <SegmentedItem className="header-button" value="month">
            Monthly
          </SegmentedItem>
          {viewType !== "timeline" && (
            <SegmentedItem className="header-button" value="year" disabled={viewType === "agenda"}>
              Yearly
            </SegmentedItem>
          )}
        </SegmentedGroup>
      </StyledHeaderPicker>
      <CalendarToday className="md-header-filter-today" />
      {viewType !== "timeline" && (
        <div className="md-custom-header-view">
          <VrsIconButton
            iconProps={{
              name: viewType === "agenda" ? IconName.CalendarView : IconName.CalendarViewActive,
            }}
            onClick={() => changeView && changeView("calendar")}
          />
          <VrsIconButton
            iconProps={{
              name: viewType === "agenda" ? IconName.ListIconActive : IconName.ListIcon,
              stroke:
                view === "year" ? verisColors.neutrals["grey-2"] : verisColors.amethyst.normal,
              color: view === "year" ? verisColors.neutrals["grey-2"] : verisColors.amethyst.normal,
            }}
            onClick={() => changeView && changeView("agenda")}
            disabled={view === "year"}
          />
        </div>
      )}
    </>
  );
}
