import React from "react";
import { Box, Typography } from "@mui/material";
import { AttachmentMetadata } from "@veris-health/communication-ms/lib/v1";
import { IconName, VrsIcon, VrsIconButton } from "@veris-health/web-core";
import { VrChatHistoryAttachment, VrsFileAttachment } from "../../store";
import { getFileExtentionIcon } from "../../../shared/helpers";

export interface VrsAttachmentProps {
  onDownload: () => void;
  metadata: AttachmentMetadata;
  attachment?: VrsFileAttachment | VrChatHistoryAttachment;
}

export function VrsAttachment({
  attachment,
  metadata,
  onDownload,
}: VrsAttachmentProps): JSX.Element {
  return (
    <Box
      sx={{
        padding: "0.5rem",
        border: (theme) => `1px solid ${theme.veris.colors.neutrals["grey-light"]}`,
        borderRadius: "0.5rem",
      }}
      width={200}
      minHeight={40}
    >
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        {attachment?.imageSrc ? (
          <Box
            component="img"
            sx={{
              maxHeight: { xs: 100, md: 100 },
              maxWidth: { xs: 150, md: 150 },
            }}
            src={attachment?.imageSrc}
            alt=""
          />
        ) : (
          <>
            <VrsIcon
              name={getFileExtentionIcon(attachment?.file_extension || metadata.attachmentFileType)}
            />
            <Typography
              variant="description"
              textOverflow="ellipsis"
              overflow="hidden"
              maxWidth="50%"
              sx={{ wordBreak: "break-word" }}
            >
              {attachment?.original_file_name || metadata.attachmentName}
            </Typography>
          </>
        )}

        <VrsIconButton iconProps={{ name: IconName.DownloadActive }} onClick={onDownload} />
      </Box>
    </Box>
  );
}
