import { Box, CircularProgress, Typography } from "@mui/material";
import React from "react";
import { DiagnosticReportObservation } from "@veris-health/med-data-ms/lib/v1";
import dayjs from "dayjs";
import { VrsButton, VrsErrorMsg, dateFormats } from "@veris-health/web-core";
import { VrsLabResult } from "../../../../ui/components/VrsLabResult";
import { Status } from "../../../shared/interfaces";
import { EMPTY_DATASET_MESSAGE } from "../../../../constants";

export interface PatientMostRecentLabResultsProps {
  recentLabResults: DiagnosticReportObservation[] | [];
  onLabsClick?: () => void;
  labResultStatus?: Status;
  onViewAllClick: () => void;
}

export function PatientMostRecentLabResults({
  recentLabResults,
  onLabsClick,
  labResultStatus = "idle",
  onViewAllClick,
}: PatientMostRecentLabResultsProps): JSX.Element {
  return (
    <Box p={1.25} maxHeight={250}>
      {labResultStatus === "loading" ? (
        <Box display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box display="flex" alignItems="baseline" justifyContent="space-between">
            <Typography
              variant="body1"
              component="span"
              color={(theme) => theme.veris.colors.neutrals["grey-4"]}
              sx={{ textTransform: "uppercase" }}
            >
              Recent labs
            </Typography>
            {labResultStatus !== "failed" && (
              <Typography
                variant="body"
                component="span"
                color={(theme) => theme.veris.colors.neutrals["grey-4"]}
              >
                • {dayjs(recentLabResults[0]?.date).format(dateFormats["MMM D, YY"])}
              </Typography>
            )}
            {labResultStatus !== "failed" && (
              <VrsButton
                buttonType="quaternary"
                onClick={onViewAllClick}
                sx={{ padding: (theme) => `${theme.spacing(0.5)} ${theme.spacing(1)}` }}
              >
                <Typography variant="caption" pt={0.25}>
                  View all
                </Typography>
              </VrsButton>
            )}
          </Box>
          <Box overflow="scroll" maxHeight={150}>
            {labResultStatus === "idle" &&
              (recentLabResults.length > 0 ? (
                recentLabResults.map((result: DiagnosticReportObservation) => (
                  <VrsLabResult key={result.name} {...result} />
                ))
              ) : (
                <Typography
                  color={(theme) => theme.veris.colors.neutrals["grey-3"]}
                  textAlign="center"
                  variant="body"
                  pt={1}
                >
                  {EMPTY_DATASET_MESSAGE}
                </Typography>
              ))}

            {labResultStatus === "failed" && <VrsErrorMsg size="small" onClick={onLabsClick} />}
          </Box>
        </>
      )}
    </Box>
  );
}
