import { VrsDialog, VrsErrorMsg } from "@veris-health/web-core";
import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Table, TableContainer, Typography } from "@mui/material";
import dayjs from "dayjs";
import { TCMDischargeEntry } from "@veris-health/communication-ms/lib/v1";
import {
  Column,
  TableColumnNames,
  VrsSortedTableHeader,
} from "../../../../ui/components/VrsSortedTableHeader";
import {
  StyledTableBody,
  StyledTableCell,
  StyledTableRow,
} from "../../../../ui/components/Tables/shared/StyledTableElements";
import { genericSorter } from "../../../../utils/sorting";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import {
  loadTCMBillingCodes,
  selectTCMBillingCodes,
  selectTCMReportStatus,
} from "../../patientDetailsSlice";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import { dateFormatMonthFirst } from "../../../../utils/date";

const columns: Column[] = [
  {
    id: TableColumnNames.LastDischargedSigned,
    label: TableColumnNames.LastDischargedSigned,
    orderBy: "discharge_date",
  },
  { id: TableColumnNames.TCMCode, label: TableColumnNames.TCMCode, orderBy: "billing_code" },
  {
    id: TableColumnNames.DateOccurred,
    label: TableColumnNames.DateOccurred,
    orderBy: "date_occurred",
  },
];

export const ReviewTCM = ({
  onClose,
  open,
  TCMData,
}: {
  onClose: () => void;
  open: boolean;
  TCMData: TCMDischargeEntry[];
}): JSX.Element => {
  const [sortedTCM, setSortedTCM] = useState(TCMData);
  const codes = useAppSelector(selectTCMBillingCodes);
  const reportStatus = useAppSelector(selectTCMReportStatus);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setSortedTCM(TCMData);
  }, [TCMData]);

  useEffect(() => {
    if (!codes) dispatch(loadTCMBillingCodes());
  }, []);

  return (
    <VrsDialog open={open} onClose={onClose} width="1200px" title="Review TCM Billing History">
      {reportStatus === "loading" && (
        <Box p={2} display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      )}
      {reportStatus === "failed" && (
        <Box p={2} display="flex" justifyContent="center">
          <VrsErrorMsg size="small" />
        </Box>
      )}
      {reportStatus === "idle" && (
        <TableContainer
          sx={{
            border: (theme) => `2px solid ${theme.veris.colors.neutrals["grey-2"]}`,
            paddingTop: 0,
            borderRadius: "6px",
          }}
        >
          <Table>
            <VrsSortedTableHeader
              columns={columns}
              sortTableData={(order, orderBy) =>
                setSortedTCM(genericSorter(sortedTCM, order, orderBy as keyof TCMDischargeEntry))
              }
            />
            <StyledTableBody>
              {sortedTCM.map((item) => (
                <StyledTableRow key={item.id}>
                  {columns.map((column) => (
                    <StyledTableCell>
                      {column.id === TableColumnNames.LastDischargedSigned && (
                        <Typography
                          variant="body"
                          color={(theme) => theme.veris.colors.neutrals["grey-4"]}
                        >
                          {dayjs(item.discharge_date).format(dateFormatMonthFirst)}
                        </Typography>
                      )}
                      {column.id === TableColumnNames.TCMCode && (
                        <Typography
                          p={1}
                          sx={{
                            backgroundColor: (theme) => theme.veris.colors.neutrals["grey-2"],
                            borderRadius: (theme) => theme.spacing(1),
                          }}
                          variant="body"
                          color={(theme) => theme.veris.colors.neutrals["grey-4"]}
                        >
                          {item.billing_code}
                          {codes && (
                            <Typography
                              component="span"
                              variant="body"
                              color={(theme) => theme.veris.colors.neutrals["grey-3"]}
                            >
                              {" "}
                              | {codes[item.billing_code as keyof object]}
                            </Typography>
                          )}
                        </Typography>
                      )}
                      {column.id === TableColumnNames.DateOccurred && (
                        <Typography
                          variant="body"
                          color={(theme) => theme.veris.colors.neutrals["grey-4"]}
                        >
                          {dayjs(item.date_occurred).format(dateFormatMonthFirst)}
                        </Typography>
                      )}
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              ))}
            </StyledTableBody>
          </Table>
        </TableContainer>
      )}
    </VrsDialog>
  );
};

export default ReviewTCM;
