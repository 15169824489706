import { Grid, Box, CircularProgress } from "@mui/material";
import React from "react";
import { VrsAvatar, VrsErrorMsg } from "@veris-health/web-core";
import { PatientInfoLabel } from "../../../../ui/components/PatientInfoLabel";
import { PatientInfoField } from "../../../../ui/components/PatientInfoField";
import { Status, VrsMedStaffProfileModel } from "../../../shared/interfaces";
import { formatPhoneNumber } from "../../../shared/helpers";

export interface DoctorsDemographicsProps {
  data: VrsMedStaffProfileModel;
  status: Status;
  fetchDoctorData: () => void;
}

export function DoctorsDemographics({
  data,
  status,
  fetchDoctorData,
}: DoctorsDemographicsProps): JSX.Element {
  const { fullName, phoneNumber, emergencyPhoneNumber, email, address, profession } = data;
  const formattedPhoneNumber = phoneNumber && formatPhoneNumber(phoneNumber);
  const formattedSOSNumber = emergencyPhoneNumber && formatPhoneNumber(emergencyPhoneNumber);

  const fieldNames = [
    { fieldName: "Full Name", value: fullName },
    { fieldName: "Speciality", value: profession || "N/A" },
    { fieldName: "Phone Number", value: formattedPhoneNumber || "N/A" },
    { fieldName: "Emergency Phone", value: formattedSOSNumber || "N/A" },
    { fieldName: "Email", value: email || "N/A" },
    { fieldName: "City", value: address?.city || "N/A" },
    { fieldName: "State", value: address?.state || "N/A" },
    { fieldName: "Zip code", value: address?.zipcode || "N/A" },
    { fieldName: "Street", value: address?.street || "N/A" },
  ];

  return (
    <Box>
      <Grid
        container
        sx={{
          backgroundColor: (theme) => theme.veris.colors.neutrals.white,
          border: (theme) => `1px solid ${theme.veris.colors.neutrals["grey-2"]}`,
          borderRadius: "8px",
        }}
        p={2}
      >
        {status === "loading" && (
          <Box display="flex" justifyContent="center" alignItems="center" width="100%">
            <CircularProgress />
          </Box>
        )}
        {status === "idle" && (
          <>
            <Box display="flex" alignItems="center">
              <VrsAvatar variant="rounded" size={120} name={fullName} />
            </Box>
            <Grid item pt={0.5} xs={10}>
              <Grid container>
                {fullName &&
                  fieldNames.map((fieldName) => (
                    <Grid item xs={2.25} p={1} key={fieldName.fieldName + fieldName.value}>
                      <PatientInfoLabel fieldName={fieldName.fieldName} />
                      <PatientInfoField value={fieldName.value} />
                    </Grid>
                  ))}
              </Grid>
            </Grid>
          </>
        )}
        {status === "failed" && (
          <Box display="flex" justifyContent="center" alignItems="center" width="100%">
            <VrsErrorMsg size="small" onClick={fetchDoctorData} />
          </Box>
        )}
      </Grid>
    </Box>
  );
}
