import React, { useEffect } from "react";
import { RichUtils, EditorState } from "draft-js";
import { Popover, styled } from "@mui/material";
import Typography from "@mui/material/Typography";
import DraftJsEditor from "@draft-js-plugins/editor";
import { IconName } from "@veris-health/web-core";
import {
  BlockTypeButton,
  InlineStyleButton,
  ClearInlineStyleButton,
  LinkButton,
} from "./components";
import { clearInlineStyles } from "../../../../utils/editor";
import { LinkForm } from "../Link/LinkForm";

const ToolbarContainer = styled("div")(({ theme }) => ({
  flex: 1,
  alignItems: "center",
  display: "flex",
  height: 48,
  paddingBottom: theme.spacing(0.5),
  borderBottom: `1px solid ${theme.veris.colors.neutrals["grey-2"]}`,
  minWidth: "calc(80% - 13.5rem)",
  marginLeft: "2px",
  marginRight: "2px",

  "span + span": {
    marginLeft: "0.5rem",
  },
}));

const VerticalDivider = styled("div")(({ theme }) => ({
  width: 1,
  height: 20,
  minWidth: 1,
  backgroundColor: theme.veris.colors.neutrals["grey-3"],
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
}));

export interface ToolbarProps {
  editorState: EditorState;
  setEditorState: (editorState: EditorState) => void;
  setReadOnly: (readOnly: boolean) => void;
  editorRef?: React.RefObject<DraftJsEditor>;
}

export const Toolbar = ({
  editorState,
  setEditorState,
  setReadOnly,
}: ToolbarProps): JSX.Element => {
  const [linkAnchor, setLinkAnchor] = React.useState<Element | null>(null);
  const [length, setLength] = React.useState<number>();
  const currentBlockType = editorState ? RichUtils.getCurrentBlockType(editorState) : undefined;
  const disabled = !setEditorState || !editorState;

  const disabledLink = disabled || editorState.getSelection().isCollapsed();
  useEffect(() => {
    const { length: textLength } = editorState.getCurrentContent().getPlainText("");
    setLength(textLength);
  }, [editorState]);

  const inlineStyle = editorState?.getCurrentInlineStyle();

  const convertBlockType = (blockType: string) => {
    if (!setEditorState || !editorState) {
      return;
    }

    const newEditorState = RichUtils.toggleBlockType(editorState, blockType);

    setEditorState(newEditorState);
  };

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const toggleInlineStyle = (inlineStyle: string) => {
    if (!setEditorState || !editorState) {
      return;
    }

    const newEditorState = RichUtils.toggleInlineStyle(editorState, inlineStyle);

    setEditorState(newEditorState);
  };

  const clearInlineStyle = () => {
    if (!setEditorState || !editorState) {
      return;
    }

    const selection = editorState.getSelection();

    setEditorState(clearInlineStyles(editorState, selection));
  };

  const onShowAddLinkFrom = (e: React.MouseEvent) => {
    setReadOnly(true);
    setLinkAnchor(e.currentTarget);
  };

  const onCloseAddLinkFrom = () => {
    setReadOnly(false);
    setLinkAnchor(null);
  };

  const onCreateLink = (url: string) => {
    const contentState = editorState.getCurrentContent();

    const contentStateWithEntity = contentState.createEntity("LINK", "MUTABLE", { url });
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    // Apply entity
    let nextEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity,
    });
    // Apply selection
    nextEditorState = RichUtils.toggleLink(
      nextEditorState,
      nextEditorState.getSelection(),
      entityKey,
    );
    setEditorState(nextEditorState);
    onCloseAddLinkFrom();
  };

  return (
    <ToolbarContainer>
      <InlineStyleButton
        disabled={disabled}
        active={inlineStyle?.has("BOLD")}
        iconName={IconName.Bold}
        inlineStyle="BOLD"
        toggleInlineStyle={toggleInlineStyle}
      />
      <InlineStyleButton
        disabled={disabled}
        active={inlineStyle?.has("ITALIC")}
        iconName={IconName.Italic}
        inlineStyle="ITALIC"
        toggleInlineStyle={toggleInlineStyle}
      />
      <InlineStyleButton
        disabled={disabled}
        active={inlineStyle?.has("UNDERLINE")}
        iconName={IconName.Underline}
        inlineStyle="UNDERLINE"
        toggleInlineStyle={toggleInlineStyle}
      />
      <InlineStyleButton
        disabled={disabled}
        active={inlineStyle?.has("STRIKETHROUGH")}
        iconName={IconName.StrikeThrough}
        inlineStyle="STRIKETHROUGH"
        toggleInlineStyle={toggleInlineStyle}
      />
      <ClearInlineStyleButton
        disabled={disabled}
        active={inlineStyle?.size > 0}
        iconName={IconName.ClearFormat}
        clearInlineStyle={clearInlineStyle}
      />
      <VerticalDivider />
      <BlockTypeButton
        disabled={disabled}
        active={currentBlockType === "header-one"}
        iconName={IconName.H1}
        blockType="header-one"
        convertBlockType={convertBlockType}
      />
      <BlockTypeButton
        disabled={disabled}
        active={currentBlockType === "header-two"}
        iconName={IconName.H2}
        blockType="header-two"
        convertBlockType={convertBlockType}
      />
      <VerticalDivider />
      <LinkButton
        disabled={disabledLink}
        iconName={IconName.EditorLink}
        onAddLink={onShowAddLinkFrom}
      />
      <Popover
        open={!!linkAnchor}
        anchorEl={linkAnchor}
        onClose={onCloseAddLinkFrom}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <LinkForm onClose={onCloseAddLinkFrom} onSubmit={onCreateLink} />
      </Popover>
      <VerticalDivider />
      <BlockTypeButton
        disabled={disabled}
        active={currentBlockType === "unordered-list-item"}
        iconName={IconName.BulletedList}
        blockType="unordered-list-item"
        convertBlockType={convertBlockType}
      />
      <BlockTypeButton
        disabled={disabled}
        active={currentBlockType === "ordered-list-item"}
        iconName={IconName.NumberList}
        blockType="ordered-list-item"
        convertBlockType={convertBlockType}
      />

      <Typography
        variant="body"
        sx={{
          marginLeft: "auto",
          marginRight: (theme) => theme.spacing(1),
          fontWeight: "400",
          color: (theme) => theme.veris.colors.neutrals["grey-3"],
        }}
      >
        {length}/500
      </Typography>
    </ToolbarContainer>
  );
};
