import { MbscCalendarEvent, MbscCalendarEventData } from "@mobiscroll/react";
import { Box, Typography } from "@mui/material";
import { VrsTooltip, dateFormats, verisColors } from "@veris-health/web-core";
import dayjs from "dayjs";
import React from "react";

export interface MedicationEventProps {
  data: MbscCalendarEventData;
}

const MedicationTooltipContent = ({ medication }: { medication: MbscCalendarEvent }) => {
  const eventStartTime = medication.datePeriod
    ? dayjs(medication.datePeriod?.start as string).format(`${dateFormats["h:mm"]}A`)
    : medication.dateTime && dayjs(medication.dateTime as string).format(`${dateFormats["h:mm"]}A`);
  const eventEndTime =
    medication && dayjs(medication.datePeriod?.end as string).format(`${dateFormats["h:mm"]}A`);
  const { datePeriod, dosage } = medication || {};
  const { start, end } = datePeriod || {};
  const { dose, route, text, rate } = dosage || {};

  return (
    <Box p={1}>
      <Typography variant="bodySemibold">{medication?.name || "N/A"}</Typography>

      <Box my={1} display="flex">
        <Typography variant="bodySemibold" minWidth="55px">
          {medication.dateTime ? "Date" : "Start"}:
        </Typography>
        {start && (
          <Typography variant="body">
            {dayjs(start).format(dateFormats["dddd, MMM D"])} • {eventStartTime}
          </Typography>
        )}
        {medication?.dateTime && (
          <Typography variant="body">
            {dayjs(medication?.dateTime).format(dateFormats["ddd, MMM D"])}• {eventStartTime}
          </Typography>
        )}
        {!start && !medication?.dateTime && <Typography variant="body">N/A</Typography>}
      </Box>
      {!medication.dateTime && (
        <Box display="flex">
          <Typography variant="bodySemibold" minWidth="55px">
            End:
          </Typography>
          {end ? (
            <Typography variant="body">
              {dayjs(end).format(dateFormats["dddd, MMM D"])} • {eventEndTime}
            </Typography>
          ) : (
            <Typography variant="body">N/A</Typography>
          )}
        </Box>
      )}
      {(dose || rate || route || text) && (
        <Box display="flex" alignItems="center" my={1}>
          <Typography variant="bodySemibold" minWidth="55px">
            Dosage:
          </Typography>
          <Box>
            <Box display="flex">
              {(dose || rate) && (
                <Typography variant="body">
                  {dose && dose}
                  {rate && rate} &nbsp;
                </Typography>
              )}
              {route && <Typography variant="body"> {route} </Typography>}{" "}
            </Box>
            <Typography variant="body"> {text && text}</Typography>
          </Box>
        </Box>
      )}
      {medication?.status && (
        <Box display="flex" alignItems="center" my={1}>
          <Typography variant="bodySemibold" minWidth="55px">
            Status:
          </Typography>
          <Typography variant="body" sx={{ textTransform: "capitalize" }}>
            {medication?.status}
          </Typography>
        </Box>
      )}

      {medication?.type && (
        <Box display="flex" my={1} alignItems="center">
          <Typography variant="bodySemibold" minWidth="55px">
            Type:
          </Typography>
          <Typography variant="body" sx={{ textTransform: "capitalize" }}>
            {medication?.type}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

const MedicationEvent = ({ data }: MedicationEventProps): JSX.Element => {
  const medication = data.original;
  return (
    <VrsTooltip
      bcgcolor={verisColors.neutrals.black}
      followCursor
      title={medication ? <MedicationTooltipContent medication={medication} /> : ""}
    >
      <Box
        sx={{
          backgroundColor: (theme) => theme.veris.colors.mango.normal,
          borderRadius: "4px",
          textOverflow: "hidden",
          border: (theme) => `1px solid${theme.veris.colors.neutrals["grey-3"]}`,
        }}
      >
        <Box sx={{ height: "100%", width: "100%" }} />
        <Box sx={{ position: "sticky", display: "table", top: 0, left: "200px" }}>
          <Box display="grid">
            <Typography
              variant="description"
              sx={{
                display: "block",
                color: (theme) => theme.veris.colors.neutrals.white,
                fontWeight: 600,
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              {data.title}
            </Typography>
          </Box>
        </Box>
      </Box>
    </VrsTooltip>
  );
};

export default MedicationEvent;
