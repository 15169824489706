import React from "react";
import { EditorBlock, EditorBlockProps } from "draft-js";
import Typography from "@mui/material/Typography/Typography";

export const ListItemBlock = ({ block, children, ...props }: EditorBlockProps): JSX.Element => {
  return React.useMemo(
    () => (
      <Typography variant="body">
        <EditorBlock block={block} {...props}>
          {children}
        </EditorBlock>
      </Typography>
    ),
    [block, props.contentState.getBlocksAsArray().indexOf(block)],
  );
};
