import { Box, Typography } from "@mui/material";
import { IngestionSummary } from "@veris-health/user-ms/lib/v1";
import dayjs from "dayjs";
import { Virtuoso } from "react-virtuoso";
import React from "react";
import { BarChart, XAxis, Bar, ResponsiveContainer, YAxis, Tooltip } from "recharts";
import { verisColors, dateFormats } from "@veris-health/web-core";
import { VrsEmptyDatasetMessage } from "../VrsEmptyDatasetMessage";

export interface AdherenceChartProps {
  ingestionData?: IngestionSummary;
}

const AdherenceChartTooltip = ({
  payload,
}: {
  payload?: [
    {
      payload: {
        total: number;
        daysOfReading?: number;
        currentDayInBillingPeriod: number | false;
        daysLeft: number | false;
        start?: string;
        end?: string;
      };
    },
  ];
}) => {
  if (payload && payload.length) {
    const data = payload[0]?.payload;

    return (
      <Box
        display="flex"
        flexDirection="column"
        sx={{
          flexDirection: "column",
          backgroundColor: (theme) => theme.veris.colors.neutrals.black,
          color: (theme) => theme.veris.colors.neutrals.white,
          padding: (theme) => theme.spacing(1),
          borderRadius: "2px",
        }}
      >
        <Box display="flex" flexDirection="row">
          <Typography
            component="span"
            variant="description"
            color={(theme) => theme.veris.colors.neutrals["grey-mid"]}
            sx={{ width: "42px", marginRight: (theme) => theme.spacing(1), textAlign: "left" }}
          >
            Measured
          </Typography>

          <Typography component="span" variant="description">
            {data.daysOfReading}
            <Typography
              component="span"
              variant="description"
              color={(theme) => theme.veris.colors.neutrals["grey-mid"]}
              sx={{ marginX: (theme) => theme.spacing(0.5) }}
            >
              of
            </Typography>
            {data.currentDayInBillingPeriod}
          </Typography>
        </Box>

        <Box display="flex" flexDirection="row">
          <Typography
            component="span"
            variant="description"
            color={(theme) => theme.veris.colors.neutrals["grey-mid"]}
            sx={{ width: "42px", marginRight: (theme) => theme.spacing(1), textAlign: "left" }}
          >
            From
          </Typography>

          <Typography
            component="span"
            variant="description"
            sx={{
              marginRight: "auto",
            }}
          >
            {dayjs(data.start).format(dateFormats["MM/DD/YYYY"])}
          </Typography>
        </Box>

        <Box display="flex" flexDirection="row">
          <Typography
            component="span"
            variant="description"
            color={(theme) => theme.veris.colors.neutrals["grey-mid"]}
            sx={{ width: "42px", marginRight: (theme) => theme.spacing(1), textAlign: "left" }}
          >
            To
          </Typography>

          <Typography
            component="span"
            variant="description"
            sx={{
              marginRight: "auto",
            }}
          >
            {dayjs(data.end).format(dateFormats["MM/DD/YYYY"])}
          </Typography>
        </Box>
      </Box>
    );
  }
  return null;
};

export function AdherenceChart({ ingestionData }: AdherenceChartProps): JSX.Element {
  const totalDaysOfBilling = 30;
  const formattedData = {
    total: totalDaysOfBilling,
    daysOfReading: ingestionData?.days_of_readings,
    currentDayInBillingPeriod: ingestionData?.current_day_in_billing_period,
    currentDay:
      ingestionData?.current_day_in_billing_period !== undefined &&
      ingestionData?.days_of_readings !== undefined &&
      ingestionData?.current_day_in_billing_period - ingestionData?.days_of_readings,
    daysLeft:
      ingestionData?.current_day_in_billing_period !== undefined &&
      totalDaysOfBilling - ingestionData?.current_day_in_billing_period,
    start: ingestionData?.billing_period_start,
    end: ingestionData?.billing_period_end,
  };

  return (
    <Box>
      {ingestionData ? (
        <>
          <Box display="flex" justifyContent="flex-end" alignItems="center" pr={1.5}>
            <Typography
              color={(theme) => theme.veris.colors.neutrals["grey-3"]}
              variant="captionSemibold"
            >
              {formattedData.daysOfReading}{" "}
            </Typography>
            <Typography color={(theme) => theme.veris.colors.neutrals["grey-3"]} variant="caption">
              &nbsp; / {formattedData.currentDayInBillingPeriod}
            </Typography>
          </Box>
          <Virtuoso
            style={{ height: 40, overflow: "hidden" }}
            totalCount={1}
            itemContent={() => (
              <ResponsiveContainer width="99%" height={40}>
                <BarChart
                  data={[formattedData]}
                  layout="vertical"
                  margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                >
                  <Tooltip
                    content={<AdherenceChartTooltip />}
                    cursor={{ fill: "transparent" }}
                    position={{ x: 5, y: -20 }}
                  />
                  <XAxis dataKey="total" type="number" hide />
                  <YAxis type="category" hide />
                  <Bar
                    dataKey="daysOfReading"
                    fill={verisColors.mango.moderate}
                    stackId="veris"
                    barSize={20}
                    radius={[5, 0, 0, 5]}
                    isAnimationActive={false}
                  />
                  <Bar
                    dataKey="currentDay"
                    fill={verisColors.amethyst.soft}
                    stackId="veris"
                    isAnimationActive={false}
                    radius={ingestionData.days_of_readings ? [0, 0, 0, 0] : [5, 0, 0, 5]}
                  />
                  <Bar
                    dataKey="daysLeft"
                    fill={verisColors.neutrals["grey-2"]}
                    stackId="veris"
                    radius={[0, 5, 5, 0]}
                    isAnimationActive={false}
                  />
                </BarChart>
              </ResponsiveContainer>
            )}
          />{" "}
        </>
      ) : (
        <VrsEmptyDatasetMessage message="No data available." />
      )}
    </Box>
  );
}
