import {
  ChangeCredentialStaffApi as ChangeCredentialStaffApiV1,
  DemographicsApi as DemographicsApiV1,
  HospitalsApi as HospitalsApiV1,
  LoginStaffApi as LoginMedicalStaffApiV1,
  PatientsApi as PatientsApiV1,
  MedStaffProfileInfoApi as ProfileInfoApiV1,
  RefreshTokenApi as RefreshTokenApiV1,
  RegisterStaffApi as RegisterStaffApiV1,
  SearchApi as SearchApiV1,
  TeamsApi as TeamsApiV1,
  TagsApi as TagsApiV1,
  TermsAndConditionsApi as TermsAndConditionsApiV1,
} from "@veris-health/user-ms/lib/v1";
import {
  PatientProfileInfoApi as PatientProfileInfoApiV2,
  SearchApi as SearchApiV2,
} from "@veris-health/user-ms/lib/v2";
import config from "../axios-config";
import { API_ROUTES, getVersion, VERSIONS } from "../helpers";

export const registerMedicalStaffApiV1 = new RegisterStaffApiV1(
  undefined,
  getVersion(API_ROUTES.USER, VERSIONS.v1),
  config,
);
export const loginMedicalStaffApiV1 = new LoginMedicalStaffApiV1(
  undefined,
  getVersion(API_ROUTES.USER, VERSIONS.v1),
  config,
);
export const profileInfoApiV1 = new ProfileInfoApiV1(
  undefined,
  getVersion(API_ROUTES.USER, VERSIONS.v1),
  config,
);
export const searchApiV1 = new SearchApiV1(
  undefined,
  getVersion(API_ROUTES.USER, VERSIONS.v1),
  config,
);
export const searchApiV2 = new SearchApiV2(
  undefined,
  getVersion(API_ROUTES.USER, VERSIONS.v2),
  config,
);
export const termsAndConditionsApiV1 = new TermsAndConditionsApiV1(
  undefined,
  getVersion(API_ROUTES.USER, VERSIONS.v1),
  config,
);
export const refreshTokenApiV1 = new RefreshTokenApiV1(
  undefined,
  getVersion(API_ROUTES.USER, VERSIONS.v1),
  config,
);
export const demographicsApiV1 = new DemographicsApiV1(
  undefined,
  getVersion(API_ROUTES.USER, VERSIONS.v1),
  config,
);
export const teamsApiV1 = new TeamsApiV1(
  undefined,
  getVersion(API_ROUTES.USER, VERSIONS.v1),
  config,
);
export const changeCredentialsStaffApiV1 = new ChangeCredentialStaffApiV1(
  undefined,
  getVersion(API_ROUTES.USER, VERSIONS.v1),
  config,
);
export const hospitalsApiV1 = new HospitalsApiV1(
  undefined,
  getVersion(API_ROUTES.USER, VERSIONS.v1),
  config,
);
export const patientsApiV1 = new PatientsApiV1(
  undefined,
  getVersion(API_ROUTES.USER, VERSIONS.v1),
  config,
);
export const patientProfileApiV2 = new PatientProfileInfoApiV2(
  undefined,
  getVersion(API_ROUTES.USER, VERSIONS.v2),
  config,
);

export const tagsApiV1 = new TagsApiV1(undefined, getVersion(API_ROUTES.USER, VERSIONS.v1), config);
