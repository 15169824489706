import React, { useEffect } from "react";
import { Box, CircularProgress } from "@mui/material";
import { useHistory } from "react-router-dom";
import { VrsErrorMsg } from "@veris-health/web-core";
import { useAppSelector } from "../../hooks/useAppSelector";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import VrsPatientDetailsTable from "../../ui/components/Tables/VrsPatientDetailsTable";
import { searchPatientsAsync } from "../shared/slices/asyncThunks";
import { replaceRouteParam } from "../../routes-config";
import { PatientFilters } from "../Patients/components/PatientFilters/PatientFilters";
import { getSelectOptions } from "../shared/helpers";
import {
  filterByCancerType,
  filterBySymptom,
  filterByTag,
  resetFilters,
  selectCancerTypeFilter,
  selectSymptomFilter,
  selectTagFilter,
  selectPatientStatus,
} from "../shared/slices/patientsSlice";
import {
  selectPatientsDataCancerTypeOptions,
  selectPatientSymptomOptions,
  selectPatientTagOptions,
  selectSortedAndFilteredVrsPatients,
} from "../Patients/patientsSlice";

export function DashboardTableContainer(): JSX.Element {
  const patients = useAppSelector(selectSortedAndFilteredVrsPatients);
  const patientsStatus = useAppSelector(selectPatientStatus);
  const cancerTypes = useAppSelector(selectPatientsDataCancerTypeOptions);
  const patientTags = useAppSelector(selectPatientTagOptions);
  const symptoms = useAppSelector(selectPatientSymptomOptions);
  const cancerTypeFilter = useAppSelector(selectCancerTypeFilter);
  const symptomFilter = useAppSelector(selectSymptomFilter);
  const tagFilter = useAppSelector(selectTagFilter);

  const symptomSelectOptions = React.useMemo(() => getSelectOptions(symptoms), [symptoms]);
  const cancerTypeSelectOptions = React.useMemo(() => getSelectOptions(cancerTypes), [cancerTypes]);
  const patientTagSelectOptions = React.useMemo(() => getSelectOptions(patientTags), [patientTags]);

  const history = useHistory();
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetFilters());
    };
  }, []);

  return (
    <>
      {patientsStatus === "loading" && (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%" p={2}>
          <CircularProgress />
        </Box>
      )}
      {patientsStatus === "failed" && (
        <VrsErrorMsg
          size="small"
          onClick={() => dispatch(searchPatientsAsync({ allPatients: false }))}
        />
      )}
      {patientsStatus === "idle" && (
        <>
          <PatientFilters
            onCancerTypeSelected={(value) => {
              dispatch(filterByCancerType(value));
            }}
            cancerTypeFilter={cancerTypeFilter}
            cancerTypeSelectOptions={cancerTypeSelectOptions}
            symptomFilter={symptomFilter}
            symptomSelectOptions={symptomSelectOptions}
            onSymptomSelected={(value) => {
              dispatch(filterBySymptom(value));
            }}
            tagFilter={tagFilter}
            tagOptions={patientTagSelectOptions}
            onTagSelected={(value) => {
              dispatch(filterByTag(value));
            }}
            onFiltersReset={() => {
              dispatch(resetFilters());
            }}
          />
          <VrsPatientDetailsTable
            data={patients}
            onPatientClick={(id) =>
              history.push(replaceRouteParam("PATIENT_DETAILS", ":userId", id.toString()))
            }
          />
        </>
      )}
    </>
  );
}
