import React from "react";
import { styled, Badge, BadgeProps, badgeClasses } from "@mui/material";
import { VrsAvatar } from "@veris-health/web-core";

export interface VrsBadgeAvatarProps {
  imageUrl?: string;
  name: string;
  size?: number;
  inactive?: boolean;
  badgeContent?: JSX.Element;
}

const StyledBadge = styled(Badge)<BadgeProps>(() => ({
  [`& .${badgeClasses.badge}`]: {
    bottom: "20%",
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      content: '""',
    },
  },
}));

export function VrsBadgeAvatar({
  imageUrl,
  size = 44,
  badgeContent,
  name,
  inactive,
}: VrsBadgeAvatarProps): JSX.Element {
  return (
    <StyledBadge
      data-test-hook="badge-avatar"
      overlap="circular"
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      badgeContent={badgeContent}
    >
      <VrsAvatar imageUrl={imageUrl} size={size} name={name} opacity={inactive ? "70%" : "100%"} />
    </StyledBadge>
  );
}
