import { Box, CircularProgress } from "@mui/material";
import { GetAllergyResponse } from "@veris-health/med-data-ms/lib/v1";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { VrsSelect, Option, VrsErrorMsg } from "@veris-health/web-core";
import { RouteParamsKeysValues } from "../../routes-config";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import { VrsAllergiesTable } from "../../ui/components/Tables/VrsAllergiesTable";
import { AutoCompleteOption, VrsSearchBar } from "../../ui/components/VrsSearchBar";
import {
  loadPatientAllergiesAsync,
  selectFilteredAllergies,
  selectAllergyTypeFilter,
  selectPatientAllergyTypes,
  setTypeFilter,
  setNameFilter,
  selectAllergiesSuccessStatus,
} from "./allergiesSlice";

export const AllergiesContainer = (): JSX.Element => {
  const { userId: currentPatientIdRouteParam } = useParams<Pick<RouteParamsKeysValues, "userId">>();
  const filteredPatientAllergies = useAppSelector((state) =>
    selectFilteredAllergies(state, +currentPatientIdRouteParam),
  );
  const allergyTypeFilter = useAppSelector(selectAllergyTypeFilter);
  const allergyTypes = useAppSelector((state) =>
    selectPatientAllergyTypes(state, +currentPatientIdRouteParam),
  );

  const allergySuccessStatus = useAppSelector(selectAllergiesSuccessStatus);
  const dispatch = useAppDispatch();

  const allergyTypeOptions = React.useMemo(
    () => [
      { label: "All types", value: "" },
      ...allergyTypes.map(
        (allergy) =>
          ({
            value: allergy,
            label: allergy && allergy.charAt(0).toUpperCase() + allergy.slice(1),
          } as Option),
      ),
    ],
    [allergyTypes],
  );

  useEffect(() => {
    dispatch(loadPatientAllergiesAsync(+currentPatientIdRouteParam));
    return () => {
      dispatch(setNameFilter());
    };
  }, [dispatch, currentPatientIdRouteParam]);

  const handleSearchChange = (
    event: React.SyntheticEvent,
    searchValue?: AutoCompleteOption<GetAllergyResponse>,
  ) => {
    dispatch(setNameFilter(searchValue?.value));
  };

  return (
    <Box
      sx={{
        border: (theme) => `1px solid ${theme.veris.colors.neutrals["grey-light"]}`,
        borderRadius: (theme) => +theme.shape.borderRadius * 0.375,
        backgroundColor: (theme) => theme.veris.colors.neutrals.white,
        padding: (theme) => theme.spacing(1.5),
        marginTop: (theme) => theme.spacing(2),
      }}
    >
      {allergySuccessStatus === "loading" && (
        <Box display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      )}
      {allergySuccessStatus === "failed" && (
        <VrsErrorMsg
          size="small"
          onClick={() => dispatch(loadPatientAllergiesAsync(+currentPatientIdRouteParam))}
        />
      )}
      {allergySuccessStatus === "idle" && (
        <>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{ paddingBottom: (theme) => theme.spacing(3), position: "sticky" }}
          >
            <Box width={300}>
              <VrsSearchBar<GetAllergyResponse>
                id="search-box-allergies"
                options={filteredPatientAllergies.map((allergy) => {
                  return { value: allergy, label: allergy.allergen };
                })}
                placeholder="Search by name"
                onChange={handleSearchChange}
              />
            </Box>
            <VrsSelect
              value={allergyTypeFilter}
              options={allergyTypeOptions}
              onSelected={(allergyType) => dispatch(setTypeFilter(allergyType))}
            />
          </Box>
          <VrsAllergiesTable data={filteredPatientAllergies} />
        </>
      )}
    </Box>
  );
};
