import { Typography } from "@mui/material";
import { Reporter } from "@veris-health/communication-ms/lib/v1";
import { PainSeverityEnum } from "@veris-health/virtual-doc-ms/lib/v1";
import React from "react";
import { verisColors } from "@veris-health/web-core";
import {
  SymptomActionNotificationViewData,
  SymptomActionNotificationViewDataTypeEnum,
} from "@veris-health/communication-ms/lib/v1/api";
import { VrsHighlightedTag } from "../VrsTags";

export interface VrsSymptomNotificationDescriptionProps {
  data: SymptomActionNotificationViewData;
}

const NotificationSenderColor: Record<Reporter, React.CSSProperties> = {
  patient: {
    backgroundColor: verisColors.neutrals["grey-2"],
    color: verisColors.neutrals["grey-3"],
  },
  system: { color: verisColors.amethyst.soft, backgroundColor: verisColors.amethyst.light },
  "care-team": {
    backgroundColor: verisColors.neutrals["grey-1"],
    color: verisColors.neutrals["grey-4"],
    border: `1px solid ${verisColors.neutrals["grey-2"]}`,
  },
};

const NotificationStatusColor: Record<PainSeverityEnum, React.CSSProperties> = {
  mild: { backgroundColor: verisColors.mango.mild },
  moderate: { backgroundColor: verisColors.mango.moderate },
  severe: { backgroundColor: verisColors.mango.severe },
  "very severe": { backgroundColor: verisColors.mango.normal },
};

export const Types: Record<SymptomActionNotificationViewDataTypeEnum, string> = {
  "symptom-updated": "updated",
  "symptom-resolved": "resolved",
  "symptom-added": "reported",
};

export function VrsSymptomNotificationDescription({
  data,
}: VrsSymptomNotificationDescriptionProps): JSX.Element {
  return (
    <Typography
      variant="subtitle2"
      px={1}
      py={1.5}
      data-test-hook="vrsSymptomNotificationDesc-element"
    >
      Symptom {Types[data.type]} by
      <VrsHighlightedTag
        component="span"
        variant="subtitle2"
        tagStyles={NotificationSenderColor[data.reported_by as Reporter]}
      >
        {data.reported_by || "N/A"}
      </VrsHighlightedTag>
      {!data.type.includes("resolved") && (
        <>
          and the status is
          {data.severity && (
            <VrsHighlightedTag
              tagStyles={NotificationStatusColor[data.severity as PainSeverityEnum]}
              variant="subtitle2"
            >
              {data.severity || "N/A"}
            </VrsHighlightedTag>
          )}
        </>
      )}
    </Typography>
  );
}
