import { Box, Typography } from "@mui/material";
import { QolScoreItem } from "@veris-health/user-ms/lib/v1";
import { dateFormats } from "@veris-health/web-core";
import dayjs from "dayjs";
import { round } from "lodash";
import React from "react";

export interface VrsQualityOfLifeProps {
  scoreItem?: QolScoreItem;
  inactive?: boolean;
}

export function VrsQualityOfLife({ scoreItem, inactive }: VrsQualityOfLifeProps): JSX.Element {
  const formattedText = scoreItem?.wellbeing_classification.split("_").join(" ");
  const formattedIndex = scoreItem && scoreItem.eq5d_index * 100;

  return (
    <Box display="flex" flexDirection="column">
      {scoreItem?.timestamp && (
        <Typography
          variant="description"
          color={(theme) => theme.veris.colors.neutrals["grey-3"]}
          pb={0.5}
          data-test-hook="qol-score-timestamp"
        >
          {dayjs(scoreItem?.timestamp).format(dateFormats["MMMM DD"])}
        </Typography>
      )}
      <Typography
        variant="subtitle1"
        color={(theme) =>
          inactive ? theme.veris.colors.neutrals["grey-3"] : theme.veris.colors.neutrals["grey-5"]
        }
        sx={{ marginBottom: (theme) => theme.spacing(1 / 2), textTransform: "capitalize" }}
        data-test-hook="qol-score-index"
      >
        {formattedIndex && round(formattedIndex, 1)}
      </Typography>
      <Typography
        variant="description"
        color={(theme) => theme.veris.colors.neutrals["grey-3"]}
        sx={{ textTransform: "capitalize" }}
        data-test-hook="qol-score-classification"
      >
        {formattedText || "N/A"}
      </Typography>
    </Box>
  );
}
