import { Box, Typography } from "@mui/material";
import React from "react";
import { IconName, VrsIconButton, verisColors } from "@veris-health/web-core";

export interface VrsPastAppointmentsOrSymptomsProps {
  onClick?: () => void;
  name?: string;
  date?: string;
  notes?: string;
  actionText?: string;
}

export function VrsPastAppointmentsOrSymptoms({
  name,
  date,
  notes,
  onClick,
  actionText,
}: VrsPastAppointmentsOrSymptomsProps): JSX.Element {
  return (
    <Box
      borderBottom={`1px solid ${verisColors.neutrals["grey-light"]}`}
      py={1}
      display="grid"
      gridTemplateColumns="repeat(8, 1fr)"
      gap={2}
      mt={1.45}
    >
      <Box gridColumn="span 1">
        <Typography variant="body" color={verisColors.neutrals["grey-mid"]}>
          {date}
        </Typography>
      </Box>
      <Box gridColumn="span 5">
        <Typography
          mb={1}
          variant="subtitle2"
          sx={{
            "::first-letter": {
              textTransform: "capitalize",
            },
          }}
        >
          {name?.replaceAll("-", " ")}
        </Typography>
        <Typography
          mb={1}
          variant="body"
          color={verisColors.neutrals["grey-mid"]}
          style={{ textTransform: "capitalize" }}
        >
          {notes}
        </Typography>
      </Box>
      {actionText && (
        <Box gridColumn="span 2" display="flex" justifyContent="end" minWidth="max-content">
          <Box
            sx={{ cursor: "pointer", alignItems: "center" }}
            minWidth="fit-content"
            display="flex"
            onClick={() => onClick && onClick()}
            data-test-hook="vrsPastAppointments-action-element"
          >
            <Typography
              style={{ textDecoration: "underline" }}
              variant="body2"
              color={verisColors.neutrals.black}
            >
              {actionText}
              <VrsIconButton
                iconProps={{
                  name: IconName.ToggleOpen,
                  size: 26,
                  stroke: `${verisColors.neutrals.black}`,
                  rectstroke: "none",
                }}
              />
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
}
