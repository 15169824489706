import { Box, styled, Typography } from "@mui/material";
import { VrsIcon, IconName, VrsDivider, verisColors, dateFormats } from "@veris-health/web-core";
import dayjs from "dayjs";
import React from "react";
import { VrsPatientInfo } from "../../../features/shared/interfaces";
import { VrsPatientInfoCard } from "../VrsPatientInfoCard";

export interface VrsAppointmentCardProps {
  type: string;
  startTime: string | Date;
  endTime: string | Date;
  patient: VrsPatientInfo | undefined;
  onPatientClick: () => void;
  appointmentPatient: { name: string; id: number };
}

const StyledContainer = styled(Box)(() => ({
  flexGrow: 1,
  backgroundColor: `${verisColors.moderate.light}4d`,
  position: "relative",
  zIndex: 1,
  display: "flex",
  border: `1px solid ${verisColors.moderate.normal}`,
  borderRadius: "8px",
  minWidth: "fit-content",
  maxWidth: "fit-content",
}));

export function VrsAppointmentCard({
  type,
  startTime,
  endTime,
  patient,
  onPatientClick,
  appointmentPatient,
}: VrsAppointmentCardProps): JSX.Element {
  return (
    <StyledContainer data-test-hook="appointmentCard-element">
      <Box m={2}>
        <VrsIcon name={IconName[type as keyof typeof IconName]} />
        <Typography variant="h6" sx={{ fontWeight: 600 }}>
          {type}
        </Typography>
        <Typography variant="caption" color={verisColors.neutrals["grey-3"]}>
          1 upcoming
        </Typography>
      </Box>
      <VrsDivider orientation="vertical" height="82px" color={verisColors.neutrals["grey-3"]} />
      <Box m={1} pr={1}>
        {patient ? (
          <VrsPatientInfoCard
            patientInfo={patient}
            backgroundColor="transparent"
            onClick={onPatientClick}
          />
        ) : (
          <Box display="flex" flexDirection="column">
            <VrsPatientInfoCard
              patientInfo={appointmentPatient as unknown as VrsPatientInfo}
              backgroundColor="transparent"
              onClick={onPatientClick}
            />

            <Typography
              variant="description"
              color={(theme) => theme.veris.colors.neutrals["grey-3"]}
              pl={1}
            >
              Toggle All Patients to see details.
            </Typography>
          </Box>
        )}

        <Typography data-test-hook="appointment-time" variant="subtitle1" pt={1} pl={1}>
          {dayjs(startTime).format(`${dateFormats["hh:mm"]} A`)} -{" "}
          {dayjs(endTime).format(`${dateFormats["hh:mm"]} A`)}
        </Typography>
      </Box>
    </StyledContainer>
  );
}
