import { MbscCalendarEvent } from "@mobiscroll/react";
import { Box, CircularProgress, Typography } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";
import { VrsErrorMsg } from "@veris-health/web-core";
import { replaceRouteParam } from "../../routes-config";
import { useAppSelector } from "../../hooks/useAppSelector";
import { VrsAppointmentCard } from "../../ui/components/VrsAppointmentCard";
import { Status } from "../shared/interfaces";
import { selectPatientsMap } from "../shared/slices/patientsSlice";

export interface UpcomingAppointmentsProps {
  todaysAppointments?: MbscCalendarEvent[];
  eventStatus: Status;
  onRetry: () => void;
}

const UpcomingAppointments = ({
  todaysAppointments,
  eventStatus,
  onRetry,
}: UpcomingAppointmentsProps): JSX.Element => {
  const patientsMap = useAppSelector(selectPatientsMap);
  const history = useHistory();
  return (
    <Box
      sx={{
        p: 2,
        backgroundColor: (theme) => theme.veris.colors.neutrals.white,
        border: (theme) => `1px solid ${theme.veris.colors.neutrals["grey-2"]}`,
        borderRadius: "8px",
      }}
    >
      <Box pb={2}>
        <Typography variant="h3"> Upcoming Appointments</Typography>
      </Box>

      <Box minWidth="100%" width="800px">
        {eventStatus === "loading" ? (
          <Box display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        ) : (
          <Box
            sx={{
              pb: 1,
              maxHeight: "112px",
              flexWrap: "nowrap",
              display: "flex",
              width: "100%",
              flexDirection: "row",
              maxWidth: "100%",
              gap: (theme) => theme.spacing(2),
              overflowY: "hidden",
              overflowX: "scroll",
            }}
          >
            {todaysAppointments?.length === 0 && eventStatus === "idle" && (
              <Typography
                color={(theme) => theme.veris.colors.neutrals["grey-3"]}
                textAlign="center"
                variant="body"
              >
                There are no appointments scheduled for today.
              </Typography>
            )}
            {todaysAppointments?.map((appointment) => (
              <VrsAppointmentCard
                key={appointment?.id}
                type={appointment?.type}
                startTime={appointment?.start as Date}
                endTime={appointment?.end as Date}
                patient={patientsMap[appointment.patientId]}
                appointmentPatient={{
                  name: appointment?.patient,
                  id: +appointment?.patientId,
                }}
                onPatientClick={() =>
                  history.push(
                    replaceRouteParam("PATIENT_DETAILS", ":userId", String(appointment.patientId)),
                  )
                }
              />
            ))}
          </Box>
        )}
        {eventStatus === "failed" && (
          <Box display="flex" justifyContent="center">
            <VrsErrorMsg size="small" onClick={onRetry} />{" "}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default UpcomingAppointments;
