import React, { useState } from "react";
import { Box, Button } from "@mui/material";
import { TagItem } from "@veris-health/user-ms/lib/v1";
import { IconName, VrsIcon, verisColors } from "@veris-health/web-core";
import { VrsSingleTag } from "../VrsTags";
import { VrsTagAddon } from "../VrsTagAddon";
import { PatientTagsDialog } from "../../../features/PatientDetails/PatientTagsDialog";

export interface VrsPatientTagsProps {
  patientTags: TagItem[];
}

export interface VrsPatientTagsNewProps {
  patientTags: TagItem[];
  patientId: number;
  patientHospitalId?: number;
  patientName: string;
  inactive?: boolean;
}

export function VrsPatientTags({
  patientTags,
  patientId,
  patientHospitalId,
  patientName,
  inactive,
}: VrsPatientTagsNewProps): JSX.Element {
  const [firstTag, ...rest] = patientTags;
  const [showEdit, setShowEdit] = useState<boolean>(false);

  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent={patientTags.length > 0 ? "space-between" : "flex-end"}
      >
        {firstTag && <VrsSingleTag label={firstTag.label} color={verisColors.neutrals["grey-3"]} />}
        {rest.length > 0 && <VrsTagAddon additionalTags={rest} />}
        {!inactive && (
          <Button
            sx={{
              padding: (theme) => theme.spacing(0, 0.2),
              width: "16px",
              maxWidth: "16px",
              minWidth: "16px",
            }}
            onClick={() => setShowEdit(true)}
            disabled={inactive}
          >
            <VrsIcon name={IconName.Edit} stroke={verisColors.neutrals["grey-3"]} size={16} />
          </Button>
        )}
      </Box>
      {showEdit && (
        <PatientTagsDialog
          patientTags={patientTags}
          patientId={patientId}
          hospitalId={patientHospitalId}
          open={showEdit}
          onClose={() => setShowEdit(false)}
          patientName={patientName}
        />
      )}
    </>
  );
}
