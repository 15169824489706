import { Box, CircularProgress, Typography } from "@mui/material";
import { VrsErrorMsg } from "@veris-health/web-core";
import React from "react";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { selectUserId } from "../../shared/slices/authSlice";
import { HospitalsTable } from "../components/HospitalsTable";
import {
  loadHospitalsTeamAsync,
  selectHospitals,
  selectHospitalsStatus,
} from "../doctorsProfileSlice";

const HospitalsTableContainer = (): JSX.Element => {
  const hospitals = useAppSelector(selectHospitals);
  const hospitalsStatus = useAppSelector(selectHospitalsStatus);
  const currentUserId = useAppSelector(selectUserId);
  const dispatch = useAppDispatch();
  return (
    <Box
      sx={{
        padding: (theme) => theme.spacing(2),
        margin: (theme) => theme.spacing(2, 0),
        border: (theme) => `1px solid ${theme.veris.colors.neutrals["grey-2"]}`,
        borderRadius: (theme) => +theme.shape.borderRadius * 0.375,
        background: (theme) => theme.veris.colors.neutrals.white,
      }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center" pb={2}>
        <Typography variant="h6Semibold">Hospitals</Typography>
      </Box>
      {hospitalsStatus === "loading" && (
        <Box display="flex" alignItems="center" justifyContent="center">
          <CircularProgress />
        </Box>
      )}
      {hospitalsStatus === "failed" && (
        <Box display="flex" justifyContent="center" alignItems="center" width="100%">
          <VrsErrorMsg
            size="small"
            onClick={() => {
              if (currentUserId)
                dispatch(
                  loadHospitalsTeamAsync({
                    id: +currentUserId,
                  }),
                );
            }}
          />
        </Box>
      )}

      {hospitalsStatus === "idle" && <HospitalsTable hospitals={hospitals} />}
    </Box>
  );
};

export default HospitalsTableContainer;
