import { Box, CircularProgress, Typography } from "@mui/material";
import { FormikProvider, useFormik } from "formik";
import React, { useState } from "react";
import * as yup from "yup";
import { VrsButton } from "@veris-health/web-core";
import { VrsFormInputField } from "../../../ui/components/VrsFormInputField";
import { StyledActiveNavigationItem } from "../../shared/styledComponents";
import { validatePassword } from "../../shared/helpers";
import { Status } from "../../shared/interfaces";

const initialValues = {
  newPassword: "",
  repeatPassword: "",
};

const validationSchema = yup.object().shape({
  newPassword: yup
    .string()
    .min(8, "Password should be of minimum 8 characters length")
    .max(64, "Password should be of maximum 64 characters length")
    .test(
      "is-valid",
      "The password does not comply with password complexity requirements",
      (value) => {
        if (value) {
          const isValidPassword = validatePassword(value);
          if (isValidPassword) {
            return false;
          }
        }
        return true;
      },
    )
    .required("New password is required"),
  repeatPassword: yup
    .string()
    .required("Password confirmation is required")
    .oneOf([yup.ref("newPassword"), null], "Passwords must match"),
});

export function ResetForm({
  onSubmit,
  validationStatus,
  clearValidationError,
}: {
  onSubmit: (password: string) => void;
  validationStatus: {
    status: Status;
    errorMessage?: string;
    successMessage?: string;
  };
  clearValidationError: () => void;
}): JSX.Element {
  const [showPassword, setShowPassword] = useState<{ [fieldName: string]: boolean }>({
    newPassword: false,
    repeatPassword: false,
  });
  const { status, errorMessage } = validationStatus;

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: ({ newPassword }) => {
      onSubmit(newPassword);
    },
  });

  const { errors, handleSubmit, handleChange, touched, isValid, dirty } = formik;
  const { newPassword, repeatPassword } = formik.values;

  const fieldsData = [
    {
      label: "Password",
      name: "newPassword",
      value: newPassword,
      helperText: errors.newPassword,
      error: !!errors.newPassword,
      isValidField: !!touched.newPassword && newPassword.length && !errors.newPassword,
      placeholder: "Write your new password",
    },
    {
      label: "Repeat Password",
      name: "repeatPassword",
      value: repeatPassword,
      helperText: errors.repeatPassword,
      error: !!errors.repeatPassword,
      isValidField: !!touched.repeatPassword && repeatPassword.length && !errors.repeatPassword,
      placeholder: "Repeat your new password",
    },
  ];

  return (
    <Box>
      <StyledActiveNavigationItem variant="h3" width="fit-content">
        Change password
      </StyledActiveNavigationItem>

      <FormikProvider value={formik}>
        <form onSubmit={handleSubmit}>
          <Box mt={10}>
            {fieldsData.map((field) => (
              <Box display="flex" key={field.label} alignItems="center" mb={3}>
                <VrsFormInputField
                  name={field.name}
                  label={field.label}
                  type={showPassword[field.name] ? "text" : "password"}
                  showPassword={showPassword[field.name]}
                  value={field.value}
                  helperText={field.helperText}
                  error={!!field.error}
                  isValidField={field.isValidField}
                  onChange={handleChange}
                  handleClickShowPassword={() =>
                    setShowPassword({ ...showPassword, [field.name]: !showPassword[field.name] })
                  }
                  placeholder={field.placeholder}
                  hideWarningIcon
                  onFocus={() => errorMessage && clearValidationError()}
                />
              </Box>
            ))}
          </Box>
          <Typography
            color={(theme) => theme.veris.colors.neutrals["grey-3"]}
            variant="caption"
            component="div"
            my={3}
          >
            Your password must be at least 8 characters long with <br />
            at least 3 out of 4 of lowercase, uppercase, numbers, or symbols.
          </Typography>

          {errorMessage && (
            <Typography
              color={(theme) => theme.veris.colors.errors.normal}
              variant="caption"
              component="div"
              my={1.5}
            >
              {errorMessage}
            </Typography>
          )}
          <VrsButton
            type="submit"
            buttonType="primary"
            disabled={status === "loading" || !isValid || !touched || !dirty}
          >
            Save password
            {status === "loading" && (
              <Box px={1}>
                <CircularProgress size={16} />
              </Box>
            )}
          </VrsButton>
        </form>
      </FormikProvider>
    </Box>
  );
}
