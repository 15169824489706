import React from "react";
import { Box, Dialog } from "@mui/material";
import DOMPurify from "dompurify";
import { VrsButton, dateFormats } from "@veris-health/web-core";
import dayjs from "dayjs";

import { useAppSelector } from "../../hooks/useAppSelector";
import { selectTermsAndConditions } from "../shared/slices/authSlice";

export interface TermsAndConditionsProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export function TermsAndConditions({ open, setOpen }: TermsAndConditionsProps): JSX.Element {
  const termsAndConditions = useAppSelector(selectTermsAndConditions);

  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth="lg">
      <Box m={1} sx={{ maxHeight: "500px", overflow: "scroll" }}>
        <p>
          {termsAndConditions?.date_created && (
            <i>
              Last updated{" "}
              {dayjs(termsAndConditions.date_created).format(dateFormats["MM/DD/YYYY"])}
            </i>
          )}
        </p>
        {termsAndConditions && (
          <div
            /* eslint-disable-next-line react/no-danger */
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(termsAndConditions.formatted_text, {
                ALLOWED_ATTR: ["target", "href"],
              }),
            }}
          />
        )}
      </Box>
      <Box display="flex" justifyContent="center" pb={4}>
        <VrsButton onClick={() => setOpen(false)} buttonType="primary">
          Close
        </VrsButton>
      </Box>
    </Dialog>
  );
}
