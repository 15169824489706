import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Dialog, Grid, Typography } from "@mui/material";
import { VideoCallBillingCodes } from "@veris-health/communication-ms/lib/v1";
import { IconName, VrsIconButton, VrsErrorMsg } from "@veris-health/web-core";
import dayjs from "dayjs";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import {
  getCallDetailsInfo,
  fetchCallBillingCodes,
  selectCallDetails,
  selectCallDetailsStatus,
  setCallBillingCode,
} from "../../../shared/slices/voipSlice";
import { selectUserId } from "../../../shared/slices/authSlice";
import { VideoBillingModalBody } from "../../components/VideoBillingModalBody";
import { dateFormatYearFirst } from "../../../../utils/date";

export interface VideoBillingModalProps {
  onClose: () => void;
  callId: number;
}

export function VideoBillingModal({ onClose, callId }: VideoBillingModalProps): JSX.Element | null {
  const [selectedCode, setSelectedCode] = useState<string | undefined>();
  const [dischargeDate, setDischargeDate] = useState<string>();
  const dispatch = useAppDispatch();
  const callDetails = useAppSelector(selectCallDetails);
  const callDetailsStatus = useAppSelector(selectCallDetailsStatus);
  const currentUserId = useAppSelector(selectUserId);

  useEffect(() => {
    dispatch(getCallDetailsInfo({ userId: Number(currentUserId), callId }));
    dispatch(fetchCallBillingCodes());
  }, []);

  const onConfirmClick = () => {
    if (selectedCode) {
      dispatch(
        setCallBillingCode({
          userId: Number(currentUserId),
          callId,
          billingCode: selectedCode as VideoCallBillingCodes,
          dateDischarged: dayjs(dischargeDate).format(dateFormatYearFirst),
        }),
      ).then((resp) => {
        if (resp.meta.requestStatus === "fulfilled") onClose();
      });
    }
  };

  return (
    <>
      <Dialog open maxWidth="sm" onClose={onClose}>
        <Grid container p={3} justifyContent="center">
          <Grid container item xs={12} justifyContent="space-between" mb={2}>
            <Typography variant="h3">Call ended &#183; Billing information</Typography>
            <VrsIconButton iconProps={{ name: IconName.CloseIcon }} onClick={onClose} />
          </Grid>
          {callDetailsStatus === "loading" && (
            <Box p={2} pb={0} display="flex" justifyContent="center">
              <CircularProgress />
            </Box>
          )}
          {callDetailsStatus === "failed" && (
            <VrsErrorMsg
              size="small"
              onClick={() =>
                dispatch(getCallDetailsInfo({ userId: Number(currentUserId), callId }))
              }
            />
          )}
          {callDetailsStatus === "idle" && callDetails && (
            <VideoBillingModalBody
              callDetails={callDetails}
              onConfirmClick={onConfirmClick}
              selectedCode={selectedCode}
              setSelectedCode={setSelectedCode}
              dischargeDate={dischargeDate}
              setDischargeDate={setDischargeDate}
            />
          )}
        </Grid>
      </Dialog>
    </>
  );
}
