import React from "react";
import { styled, Box, Button } from "@mui/material";
import { FileAttachment, ThreadParticipantInfo } from "@veris-health/communication-ms/lib/v1";
import { VrsButton } from "@veris-health/web-core";
import { VrsAttachmentsTab } from "../components/VrsAttachmentsTab";
import { useAppSelector } from "../../../hooks/useAppSelector";
import {
  AvailableAttachmentFiltering,
  downloadAttachmentSasAsync,
  fetchAttachmentsForThreadAsync,
  selectAttachmentFilteringType,
  selectDocTypeAttachments,
  selectFilteredThreadAttachments,
  selectImageTypeAttachments,
  setAttachmentTypeFiltering,
  VrsFileAttachment,
} from "../store";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { Status } from "../../shared/interfaces";

export interface StyledAttachmentProps {
  isActive: boolean;
  isPrivateThread?: boolean;
}

const AttachmentNavigator = styled(Box)(({ theme }) => ({
  display: "flex",
  backgroundColor: theme.veris.colors.neutrals["soft-white"],
  borderTop: `1px solid ${theme.veris.colors.neutrals["grey-light"]}`,
}));

const StyledTabFilter = styled(Button, {
  shouldForwardProp: (prop: string) => prop !== ("isActive" || "isPrivateThread"),
})<StyledAttachmentProps>(({ theme, isActive, isPrivateThread }) => ({
  backgroundColor:
    isActive && isPrivateThread
      ? theme.veris.colors.pink.light
      : (isActive && theme.veris.colors.neutrals.white) || "transparent",
  color: isActive ? theme.veris.colors.amethyst.normal : theme.veris.colors.neutrals["grey-mid"],
  cursor: "pointer",
  border: "none",
  borderTop: `1px solid ${isActive ? theme.veris.colors.neutrals.white : "transparent"}`,
  padding: `${theme.spacing(2)} ${theme.spacing(1.5)}`,
  position: "relative",
  top: "-1px",
  borderRight: `1px solid ${theme.veris.colors.neutrals["grey-light"]}`,
}));

export interface AttachmentsContainerProps {
  currentThreadId: string;
  myChatDisplayName: string;
  participants: Record<string, ThreadParticipantInfo>;
  threadAttachments?: VrsFileAttachment[];
  attachmentsStatus: Status;
  isPrivateThread: boolean;
  total: number;
}

export const AttachmentsContainer = ({
  currentThreadId,
  threadAttachments,
  attachmentsStatus,
  participants,
  isPrivateThread,
}: AttachmentsContainerProps): JSX.Element => {
  const attachmentFilterType = useAppSelector(selectAttachmentFilteringType);
  const filteredThreadAttachments = useAppSelector((state) =>
    selectFilteredThreadAttachments(state, currentThreadId),
  );
  const imgAttachments = useAppSelector((state) =>
    selectImageTypeAttachments(state, currentThreadId),
  );
  const docAttachments = useAppSelector((state) =>
    selectDocTypeAttachments(state, currentThreadId),
  );

  const dispatch = useAppDispatch();

  const handleAttachmentTypeFiltering = (filterType: AvailableAttachmentFiltering): void => {
    dispatch(setAttachmentTypeFiltering(filterType));
  };

  const fetchAttachments = () => {
    if (attachmentsStatus === "idle")
      dispatch(
        fetchAttachmentsForThreadAsync({
          threadId: currentThreadId,
          onlyLatest: false,
          limit: 10,
        }),
      );
  };

  const onDownloadAttachment = (attachment: FileAttachment) => {
    dispatch(
      downloadAttachmentSasAsync({
        metadata: {
          attachmentName: attachment.original_file_name,
          attachmentUuid: attachment.file_name,
          attachmentUrl: attachment.blob_endpoint,
          attachmentFileType: attachment.file_extension ?? "",
        },
        messageId: attachment.file_name,
        threadId: currentThreadId,
      }),
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "space-between",
      }}
    >
      <VrsAttachmentsTab
        attachments={filteredThreadAttachments || []}
        attachmentsStatus={attachmentsStatus}
        onDownloadAttachment={onDownloadAttachment}
        participants={participants}
        isPrivateThread={isPrivateThread}
      />
      {threadAttachments && threadAttachments.length > 0 && (
        <Box>
          <Box p={1}>
            <VrsButton buttonType="quaternary" onClick={fetchAttachments}>
              Load more
            </VrsButton>
          </Box>
          <AttachmentNavigator>
            <StyledTabFilter
              isActive={attachmentFilterType === "all"}
              onClick={() => handleAttachmentTypeFiltering("all")}
              isPrivateThread={isPrivateThread}
            >
              All ({threadAttachments?.length})
            </StyledTabFilter>
            <StyledTabFilter
              disabled={!docAttachments || docAttachments.length === 0}
              isActive={attachmentFilterType === "doc"}
              onClick={() => handleAttachmentTypeFiltering("doc")}
              isPrivateThread={isPrivateThread}
            >
              Docs ({docAttachments.length || 0})
            </StyledTabFilter>
            <StyledTabFilter
              disabled={!imgAttachments || imgAttachments.length === 0}
              isActive={attachmentFilterType === "img"}
              onClick={() => handleAttachmentTypeFiltering("img")}
              isPrivateThread={isPrivateThread}
            >
              Images ({imgAttachments.length || 0})
            </StyledTabFilter>
          </AttachmentNavigator>
        </Box>
      )}
    </Box>
  );
};
