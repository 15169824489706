import axios from "axios";
import dayjs, { Dayjs } from "dayjs";
import {
  ThreadParticipantInfo,
  SupportedAttachmentTypesEnum as SupportedAttachmentTypes,
} from "@veris-health/communication-ms/lib/v1";
import { CareTeamMemberInfo, SupportTeamMember } from "@veris-health/user-ms/lib/v1";
import { capitalize, times } from "lodash";
import { IconName, Option } from "@veris-health/web-core";
import { PatientStatusEnumAPI } from "@veris-health/user-ms/lib/v2";
import { InfusionTime } from "../../ui/components/Tables/VrsPatientListTable";
import { VrsPatientInfo } from "./interfaces";

export const sortByKeyAsc =
  <T>(key: keyof T) =>
  (a: T, b: T): -1 | 0 | 1 => {
    const first = a[key];
    const second = b[key];
    if (first > second) {
      return 1;
    }
    if (first < second) {
      return -1;
    }
    return 0;
  };

export const sortByKeyDesc =
  <T>(key: keyof T) =>
  (a: T, b: T): -1 | 0 | 1 => {
    const first = a[key];
    const second = b[key];
    if (first > second) {
      return -1;
    }
    if (first < second) {
      return 1;
    }
    return 0;
  };

export const sortVrsPatientByInfusionTimeAsc =
  (key: keyof InfusionTime) =>
  (p1: VrsPatientInfo, p2: VrsPatientInfo): 1 | -1 =>
    dayjs(p1[key]?.timestamp).isBefore(p2[key]?.timestamp) ? -1 : 1;

export const sortVrsPatientByInfusionTimeDesc =
  (key: keyof InfusionTime) =>
  (p1: VrsPatientInfo, p2: VrsPatientInfo): 1 | -1 =>
    dayjs(p1[key]?.timestamp).isBefore(p2[key]?.timestamp) ? 1 : -1;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getMsgFromDetails(details: any): string | undefined {
  if (Array.isArray(details) && details.length > 0) {
    const firstItem = details[0];
    if (typeof firstItem === "object" && "msg" in firstItem) {
      return firstItem.msg;
    }
  }
  return undefined;
}

export const extractErrorMessage = (error: unknown): string => {
  if (axios.isAxiosError(error)) {
    const { response } = error;
    if (!response) throw error;
    const { data } = response;
    if (data && data.message) return typeof data.message === "string" ? data.message : undefined;
    if (!data || !data.detail) throw error;
    return typeof data.detail === "string" ? data.detail : getMsgFromDetails(data.detail);
  }
  throw error;
};

export const getFullPictureFormat = (picture: string): string => {
  return `data:image/png;base64,${picture}`;
};

export const getConcatTextFromObjectArray = (
  arrayVal: ThreadParticipantInfo[],
  fieldName: string,
  transform?: boolean,
): string => {
  const value = arrayVal.reduce((accumulator, current, index, array) => {
    const textField: string = current[fieldName as keyof ThreadParticipantInfo] as string;
    if (textField) {
      if (current.user_type) {
        const formattedField = transform ? capitalize(textField.split("_").join(" ")) : textField;
        accumulator += `${formattedField}${index < array.length - 1 ? ", " : ""}`;
      } else {
        accumulator += `Unknown${index < array.length - 1 ? ", " : ""}`;
      }
    }
    return accumulator;
  }, "");
  return value.trim();
};

export const getFilteredTeamMembers = (
  type: "care" | "support",
  searchPhrase: string,
  threadParticipants?: ThreadParticipantInfo[],
  teams?: { careTeam: CareTeamMemberInfo[]; supportTeam: SupportTeamMember[] },
): { careTeam: CareTeamMemberInfo[]; supportTeam: SupportTeamMember[] } => {
  if (!teams) {
    return { careTeam: [], supportTeam: [] };
  }
  const careTeamWithoutExistingParticipants = threadParticipants
    ? teams.careTeam.filter(
        (member) =>
          !threadParticipants.find((threadParticipant) => threadParticipant.user_id === member.id),
      )
    : teams.careTeam;

  const supportTeamWithoutExistingParticipants = threadParticipants
    ? teams.supportTeam.filter(
        (member) =>
          !threadParticipants.find((threadParticipant) => threadParticipant.user_id === member.id),
      )
    : teams.supportTeam;

  switch (type) {
    case "care": {
      return {
        supportTeam: supportTeamWithoutExistingParticipants,
        careTeam: careTeamWithoutExistingParticipants.filter((member) =>
          member.full_name.toLowerCase().includes(searchPhrase.toLowerCase()),
        ),
      };
    }
    case "support": {
      return {
        careTeam: careTeamWithoutExistingParticipants,
        supportTeam: supportTeamWithoutExistingParticipants.filter((member) =>
          member.full_name.toLowerCase().includes(searchPhrase.toLowerCase()),
        ),
      };
    }
    default: {
      return teams;
    }
  }
};

export const validatePassword = (password: string): boolean => {
  const matchUppercase = "^(?=.*[A-Z])";
  const matchLowercase = "^(?=.*[a-z])";
  const matchSymbols = "^(?=.*[!@#$%^&*+=()\\-_])";
  const matchNumbers = "^(?=.*[0-9])";
  let fulfilledRequirements = 0;

  const passwordRegex = [matchUppercase, matchLowercase, matchSymbols, matchNumbers];

  passwordRegex.forEach((regex) => {
    if (password.match(regex)) fulfilledRequirements += 1;
  });

  return fulfilledRequirements < 3;
};

export const getSelectOptions = (data: string[], allOptionsText?: string): Option[] => {
  const selectOptions = data.map((v) => ({ value: v, label: v }));

  return [{ label: allOptionsText || "All", value: "" }, ...selectOptions];
};

export const supportedExtensionsMapping: Record<SupportedAttachmentTypes, IconName> = {
  [SupportedAttachmentTypes.Jpg]: IconName.ImageThumbnail,
  [SupportedAttachmentTypes.Jpeg]: IconName.ImageThumbnail,
  [SupportedAttachmentTypes.Pdf]: IconName.PDFIcon,
  [SupportedAttachmentTypes.Png]: IconName.ImageThumbnail,
  [SupportedAttachmentTypes.Svg]: IconName.SvgFile,
  [SupportedAttachmentTypes.Docx]: IconName.DocFile,
  [SupportedAttachmentTypes.Doc]: IconName.DocFile,
  [SupportedAttachmentTypes.Xls]: IconName.ChartFile,
  [SupportedAttachmentTypes.Xlsx]: IconName.ChartFile,
  [SupportedAttachmentTypes.Rtf]: IconName.RichTextFile,
  [SupportedAttachmentTypes.Ppt]: IconName.PresentationFile,
  [SupportedAttachmentTypes.Pptx]: IconName.PresentationFile,
  [SupportedAttachmentTypes.Odf]: IconName.RichTextFile,
};

export const getFileExtentionIcon = (fileExtention?: string): IconName =>
  supportedExtensionsMapping[fileExtention as SupportedAttachmentTypes] ||
  IconName.UnknownExtension;

export const formatPhoneNumber = (phoneNumber: string): string => {
  const numbersOnly = `${phoneNumber}`.replace(/\D/g, "");

  if (numbersOnly.charAt(0) === "1" && numbersOnly.length === 11) {
    const prefixRemoved = numbersOnly.substring(1, 11);
    const areaCode = prefixRemoved.substring(0, 3);
    const numberX = prefixRemoved.substring(3, 6);
    const numberY = prefixRemoved.substring(6, 12);

    const formatedNumber = `(${areaCode}) ${numberX}-${numberY}`;
    return formatedNumber;
  }

  return phoneNumber;
};

export const isDateInRange = (timestamp: Dayjs, startDate: Dayjs, endDate: Dayjs): boolean => {
  return (
    (timestamp.isBefore(endDate) && timestamp.isAfter(startDate)) ||
    timestamp.isSame(startDate) ||
    timestamp.isSame(endDate)
  );
};

export const MIN_BILLABLE_APPOINTMENT_DURATION = 15;

export const convertNumberToAbbreviatedString = (n: number) => {
  if (n < 1e3) {
    if (n % 1 !== 0 && n.toString().split(".")[1].length > 1) {
      return n.toFixed(1);
    }
    return n;
  }
  if (n >= 1e3) {
    return `${+(n / 1e3).toFixed(1)}k`;
  }
  return n;
};

export const getAllHoursInDay = (date: dayjs.Dayjs): string[] =>
  times(24, (index) => date.set("hour", index).clone().startOf("hour").format());

export const checkPatientStatus = (
  statusesToCheck: PatientStatusEnumAPI[],
  patientStatus?: PatientStatusEnumAPI,
) => {
  return patientStatus && statusesToCheck.includes(patientStatus);
};
