import { MbscCalendarEventData } from "@mobiscroll/react";
import { Box, Typography } from "@mui/material";
import { VrsIcon, IconName, verisColors, dateFormats } from "@veris-health/web-core";
import dayjs from "dayjs";
import React from "react";
import { CalendarShownType } from "../calendarSlice";
import { AppointmentStatus } from "./AppointmentStatus";
import { getEventBackgroundColor, getEventBorderColor } from "./RenderScheduleEvent";

export const eventBorderImage = `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%236C70F4' stroke-width='2' stroke-dasharray='10, 10, 10, 10' stroke-dashoffset='1'/%3e%3c/svg%3e")`;

interface RenderEventProps {
  data: MbscCalendarEventData;
  viewType: CalendarShownType;
}

export const RenderEvent = ({ data, viewType }: RenderEventProps): JSX.Element => {
  return (
    <Box
      style={{
        backgroundColor: getEventBackgroundColor(
          data.original?.type,
          false,
          data.original?.outOfPlatform,
          data.original?.confirmed,
        ),
        height: "100%",
        width: "100%",
        border: getEventBorderColor(false, data.original?.outOfPlatform, data.original?.confirmed),
        backgroundImage: !data.original?.confirmed ? eventBorderImage : "none",
        boxSizing: "border-box",
        borderRadius: "6px",
      }}
    >
      <Box p={0.5} pl={1}>
        {viewType === "agenda" ? (
          <Box p={0.5}>
            <Box
              display="flex"
              alignItems="center"
              sx={{
                flexGrow: 1,
              }}
              gap={1.5}
              pb={0.5}
            >
              <VrsIcon
                name={IconName[`${data?.original?.type}ListView` as keyof typeof IconName]}
                color={
                  data.original?.outOfPlatform
                    ? verisColors.neutrals["grey-3"]
                    : verisColors.moderate.normal
                }
                size={18}
              />
              <Typography
                style={{ textTransform: "capitalize" }}
                variant="subtitle1"
                color={
                  data.original?.confirmed
                    ? verisColors.neutrals["grey-5"]
                    : verisColors.amethyst.normal
                }
              >
                {data?.original?.patient}
              </Typography>
              <Typography
                style={{ textTransform: "capitalize" }}
                variant="subtitle2"
                color={
                  data.original?.confirmed
                    ? verisColors.neutrals["grey-5"]
                    : verisColors.amethyst.normal
                }
                display="inline-flex"
              >
                [{data?.original?.type} Appointment]
              </Typography>
              <AppointmentStatus status={data.original?.status} />
            </Box>
            {!data.isMultiDay && (
              <Typography variant="subtitle2" color={verisColors.neutrals["grey-5"]}>
                {dayjs(data?.original?.start as string).format(`${dateFormats["h:mm"]} A`)} -{" "}
                {dayjs(data?.original?.end as string).format(`${dateFormats["h:mm"]} A`)}
                {data.original?.outOfPlatform && <VrsIcon name={IconName.OutsidePatient} />}
              </Typography>
            )}
          </Box>
        ) : (
          <Typography
            style={{ textTransform: "capitalize" }}
            variant="caption"
            color={
              data.original?.confirmed
                ? verisColors.neutrals["grey-4"]
                : verisColors.amethyst.normal
            }
          >
            [{data?.original?.type} Appointment] {data?.original?.patient}
            {data?.original?.start &&
              `, ${dayjs(data?.original?.start as string).format(`${dateFormats["h:mm"]} A`)}`}
            {data.original?.outOfPlatform && <VrsIcon name={IconName.OutsidePatient} />}
          </Typography>
        )}
      </Box>
    </Box>
  );
};
