import React from "react";
import { Box, Checkbox, Typography } from "@mui/material";
import { CareTeamMemberInfo, SupportTeamMember } from "@veris-health/user-ms/lib/v1";
import { VrsAvatar } from "@veris-health/web-core";
import { StyledCheckboxContainer } from "../../../features/shared/styledComponents";

export interface VrsMemberWithCheckboxProps {
  member: CareTeamMemberInfo | SupportTeamMember;
  onChange: () => void;
  checked: boolean;
}

export function VrsMemberWithCheckbox({
  member,
  onChange,
  checked,
}: VrsMemberWithCheckboxProps): JSX.Element {
  return (
    <StyledCheckboxContainer key={member.id} py={1} onClick={() => onChange()}>
      <Checkbox
        name={`selectedMembers.values[${member.id}].full_name`}
        checked={checked}
        sx={{ color: (theme) => theme.veris.colors.amethyst.normal }}
      />
      <Box display="flex" alignItems="center">
        <VrsAvatar name={member.full_name} size={30} />
        <Typography variant="body" mx={1}>
          {member.full_name}
        </Typography>
        {"profession" in member && (
          <Typography variant="body" color={(theme) => theme.veris.colors.neutrals["grey-3"]}>
            {member.profession}
          </Typography>
        )}
      </Box>
    </StyledCheckboxContainer>
  );
}
