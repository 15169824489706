import { styled, Typography } from "@mui/material";
import { DiagnosticReportSummary } from "@veris-health/med-data-ms/lib/v1";
import dayjs from "dayjs";
import React from "react";
import { IconName, VrsIcon, VrsButton } from "@veris-health/web-core";
import { attachmentDateFormat } from "../../../Communication/components/VrsAttachmentListItem";
import { ResultEntries } from "../../patientRecordsSlice";
import { StyledRow } from "../PatientRecordsList";

export interface PatientRecordListItemProps {
  title: ResultEntries;
  onShowResultClick: (result?: DiagnosticReportSummary, title?: ResultEntries) => void;
  result: DiagnosticReportSummary;
  selectedRecord?: DiagnosticReportSummary;
}

const StyledSplitToggler = styled(VrsButton)(({ theme }) => ({
  ...theme.typography.button,
  padding: 0,
  paddingBottom: theme.spacing(0.75),
  color: theme.veris.colors.neutrals.black,
  textDecoration: "underline",
  border: "none",
  placeSelf: "start",
  height: "20px",
  width: "20px",
  backgroundColor: "transparent",
  "&:hover, &:focus, &:active": {
    border: "none",
    textDecoration: "underline",
    backgroundColor: "transparent",
  },
}));

export function PatientRecordListItem({
  result,
  selectedRecord,
  onShowResultClick,
  title,
}: PatientRecordListItemProps): JSX.Element {
  return (
    <StyledRow
      key={result.id}
      sx={{
        backgroundColor: (theme) =>
          selectedRecord?.id === result?.id ? theme.veris.colors.amethyst.light : "transparent",
        "&:hover": {
          backgroundColor: (theme) => theme.veris.colors.amethyst.light,
        },
        cursor: "pointer",
        paddingBottom: (theme) => theme.spacing(0.25),
      }}
      onClick={() =>
        selectedRecord?.id === result?.id ? onShowResultClick() : onShowResultClick(result, title)
      }
    >
      <Typography variant="subtitle2" sx={{ color: (theme) => theme.veris.colors.neutrals.black }}>
        {result.reportName}
      </Typography>
      <Typography variant="subtitle2" sx={{ color: (theme) => theme.veris.colors.neutrals.black }}>
        {dayjs(result.date).format(attachmentDateFormat)}
      </Typography>
      {selectedRecord?.id === result?.id ? (
        <StyledSplitToggler
          buttonType="quaternary"
          onClick={(event) => {
            event.stopPropagation();
            onShowResultClick();
          }}
          endIcon={<VrsIcon name={IconName.ArrowLeft} />}
        >
          Hide
        </StyledSplitToggler>
      ) : (
        <StyledSplitToggler
          buttonType="quaternary"
          onClick={(event) => {
            event.stopPropagation();
            onShowResultClick(result, title);
          }}
          endIcon={<VrsIcon name={IconName.ArrowRight} />}
        >
          Show
        </StyledSplitToggler>
      )}
    </StyledRow>
  );
}
