import React, { useCallback, useEffect, useState } from "react";
import { Box, Theme, useMediaQuery, Typography, Snackbar } from "@mui/material";
import { useHistory } from "react-router-dom";
import {
  IconName,
  VrsIcon,
  VrsButton,
  VrsAlert,
  VrsIconButton,
  VrsTooltip,
  verisColors,
} from "@veris-health/web-core";
import {
  loadRecentLabResultsAsync,
  selectLabResults,
  selectLabResultsStatus,
  loadTreatmentPlanAsync,
  selectAppointments,
  selectNextTreatmentsStatus,
  loadSymptomReportAnswers,
  resetSymptomReportAnswers,
  loadPatientConditionsAsync,
  selectPatientConditions,
  selectPatientConditionsStatus,
  fetchPatientActiveMedicationsAsync,
  loadPatientQOLReportsAsync,
  selectQOLReportsStatus,
  selectQOLReports,
  resetQOLReportsPreview,
  selectMedicationAdherence,
  fetchPatientActiveMedicationsAdherenceAsync,
  selectActiveMedAdherenceStatus,
} from "./patientDetailsSidebarSlice";
import { VrsTabsContainer } from "../../ui/components/VrsTabsContainer";
import { VrsTreatmentPlan } from "../../ui/components/VrsTreatmentPlan";
import { PatientMostRecentLabResults } from "./components/PatientMostRecentLabResults/PatientMostRecentLabResults";
import { VrsActiveMeds } from "../../ui/components/VrsActiveMeds";
import { replaceRouteParam, Routes } from "../../routes-config";
import { PatientActiveProblems } from "./components/PatientActiveProblems";
import { ActiveProblemsDialog } from "./components/ActiveProblemsDialog";
import { setEventCreationVisibility } from "../Calendar/calendarSlice";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import {
  addExpandedSymptomDetails,
  selectExpandedSymptomDetails,
  toggleLeftSideBar,
} from "../shared/slices/uiSlice";
import { QOfLSidebarPreview } from "./components/QOfLSidebarPreview";
import { SymptomPreviewSidebar } from "./components/SymptomPreview";
import { ActiveMedsDialog } from "../PatientDetails/components/PatientActiveMedDialog";
import { useCurrentPatient } from "../../hooks/useCurrentPatient";
import { generateClinicalNote } from "../PatientDetails/api/patientDetailsApi";
import { extractErrorMessage } from "../shared/helpers";
import { symptomsApiV1 } from "../../api";
import { SymptomReport } from "./components/SymptomReport/SymptomReport";
import { PDFExportPreview } from "./components/PDFExport/PDFExportPreview";
import {
  fetchUnifiedUserSensorsDataAsync,
  fetchUserSymptomsData,
  resetSensorsGraphView,
} from "../PatientDetailsMeasurements/measurementSlice";

export interface SidebarButtonProps {
  clickHandler: () => void;
  content: string;
  disabled?: boolean;
}

const SidebarButton = ({ clickHandler, content, disabled = false }: SidebarButtonProps) => (
  <VrsButton
    sx={{
      backgroundColor: (theme) => theme.veris.colors.neutrals.white,
      border: (theme) => `1px solid ${theme.veris.colors.neutrals["grey-light"]}`,
      padding: (theme) => theme.spacing(1),
      borderRadius: (theme) => +theme.shape.borderRadius * 0.375,
      width: "100%",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: (theme) => theme.spacing(1),
    }}
    endIcon={<VrsIcon name={IconName.ArrowRight} />}
    buttonType="quaternary"
    onClick={clickHandler}
    disabled={disabled}
  >
    <Typography
      variant="body1"
      component="span"
      color={(theme) => theme.veris.colors.neutrals["grey-dark"]}
      pt={0.5}
    >
      {content}
    </Typography>
  </VrsButton>
);

SidebarButton.defaultProps = {
  disabled: false,
};

export interface PatientDetailsSidebarContainerProps {
  onViewAllLabsResults: () => void;
}

export const PatientDetailsSidebarContainer = ({
  onViewAllLabsResults,
}: PatientDetailsSidebarContainerProps): JSX.Element => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const currentPatient = useCurrentPatient();
  const labResults = useAppSelector((state) => selectLabResults(state, currentPatient?.id));
  const nextTreatments = useAppSelector((state) => selectAppointments(state, currentPatient?.id));
  const labResultStatus = useAppSelector(selectLabResultsStatus);
  const nextTreatmentsStatus = useAppSelector(selectNextTreatmentsStatus);
  const expandedSymptomDetails = useAppSelector(selectExpandedSymptomDetails);
  const activeMeds = useAppSelector((state) =>
    selectMedicationAdherence(state, currentPatient?.id),
  );

  const activeMedicationStatus = useAppSelector(selectActiveMedAdherenceStatus);
  const patientConditions = useAppSelector((state) =>
    selectPatientConditions(state, currentPatient?.id),
  );
  const patientConditionsStatus = useAppSelector(selectPatientConditionsStatus);
  const reportsStatus = useAppSelector(selectQOLReportsStatus);
  const reports = useAppSelector((state) => selectQOLReports(state, currentPatient?.id));
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("xl"));
  const [isActiveProblemsDialogOpen, setActiveProblemsDialogOpen] = useState<boolean>(false);
  const [isActiveMedsDialogOpen, toggleActiveMedsDialog] = useState<boolean>(false);
  const [isQOLOpen, setIsQOLOpen] = useState<boolean>(false);
  const [isReportSymptomOpen, setIsReportSymptomOpen] = useState<boolean>(false);
  const [isPDFExportOpen, setIsPDFExportOpen] = useState<boolean>(false);
  const [generateContentStatus, setGenerateContentStatus] =
    useState<{ content: string; status: "success" | "error" }>();

  useEffect(() => {
    if (expandedSymptomDetails) {
      setIsQOLOpen(false);
    }
  }, [expandedSymptomDetails]);

  useEffect(() => {
    if (!currentPatient) {
      return;
    }
    const { id } = currentPatient;
    dispatch(loadRecentLabResultsAsync({ id }));
    dispatch(loadTreatmentPlanAsync({ id }));
    dispatch(loadPatientConditionsAsync({ id }));
    dispatch(fetchPatientActiveMedicationsAsync({ userId: id }));
    dispatch(fetchPatientActiveMedicationsAdherenceAsync({ userId: id }));
    setIsQOLOpen(false);
    dispatch(resetQOLReportsPreview(String(id)));
  }, [dispatch, currentPatient]);

  const symptomPreviewCloseHandler = () => {
    dispatch(resetSymptomReportAnswers());
    dispatch(addExpandedSymptomDetails(undefined));
    if (!isSmallScreen) dispatch(toggleLeftSideBar(true));
  };

  const toggleQOfLPreviewSidebar = (shouldOpen: boolean) => {
    if (shouldOpen) {
      dispatch(
        loadPatientQOLReportsAsync({
          id: String(currentPatient?.id),
          offset: 0,
        }),
      );
      dispatch(toggleLeftSideBar(!shouldOpen));
      if (expandedSymptomDetails) {
        dispatch(resetSymptomReportAnswers());
        dispatch(addExpandedSymptomDetails(undefined));
      }
    } else {
      dispatch(resetQOLReportsPreview(String(currentPatient?.id)));
    }
    setIsQOLOpen(shouldOpen);
    if (!isSmallScreen) dispatch(toggleLeftSideBar(!shouldOpen));
  };

  const symptomPreviewErrorHandler = () => {
    dispatch(
      loadSymptomReportAnswers({
        reporterId: String(currentPatient?.id),
        reportId: String(expandedSymptomDetails?.report_id),
      }),
    );
  };

  const fetchQOLReports = useCallback(async () => {
    if (reportsStatus === "loading") {
      return;
    }
    dispatch(
      loadPatientQOLReportsAsync({
        id: String(currentPatient?.id),
        offset: 10 + reports.items.length,
      }),
    );
  }, [reports, reportsStatus]);

  const handleGenerateContent = async () => {
    if (currentPatient) {
      try {
        await generateClinicalNote(currentPatient?.id).then((response) => {
          const content = response?.generated_clinical_note?.note_text;
          if (content)
            navigator.clipboard.writeText(content).then(() =>
              setGenerateContentStatus({
                content: "Content copied to clipboard!",
                status: "success",
              }),
            );
        });
      } catch (error) {
        const errorMsg = extractErrorMessage(error);
        setGenerateContentStatus({ content: errorMsg, status: "error" });
      }
    }
  };
  return (
    <>
      <Box ml={1} sx={{ width: "min-content" }}>
        <Box p={1} display="flex" alignItems="center" justifyContent="space-around">
          <VrsButton
            onClick={handleGenerateContent}
            buttonType="secondary"
            sx={{ flexBasis: "70%", borderRadius: (theme) => +theme.shape.borderRadius * 0.5 }}
          >
            Copy History
          </VrsButton>
          <Box p={1} display="flex" alignItems="center" justifyContent="center">
            <VrsTooltip bcgcolor={verisColors.neutrals.black} title="Export Data">
              <Box>
                <VrsIconButton
                  onClick={() => setIsPDFExportOpen(true)}
                  iconProps={{ name: IconName.DownloadActive }}
                  data-test-hook="pdf-export"
                />
              </Box>
            </VrsTooltip>

            {isPDFExportOpen && (
              <PDFExportPreview
                open={isPDFExportOpen}
                onClose={() => {
                  if (currentPatient) {
                    dispatch(resetSensorsGraphView({ view: "weekly" }));
                    dispatch(fetchUnifiedUserSensorsDataAsync({ id: currentPatient?.id }));
                    dispatch(
                      fetchUserSymptomsData({
                        userId: currentPatient?.id.toString(),
                      }),
                    );
                  }
                  setIsPDFExportOpen(false);
                }}
              />
            )}
          </Box>
        </Box>
        <Box p={1} display="flex" alignItems="center" justifyContent="center">
          <VrsButton
            onClick={() => setIsReportSymptomOpen(true)}
            buttonType="secondary"
            sx={{ flexBasis: "100%", borderRadius: (theme) => +theme.shape.borderRadius * 0.5 }}
          >
            Report a symptom
          </VrsButton>
          {isReportSymptomOpen && (
            <SymptomReport
              open={isReportSymptomOpen}
              onClose={() => setIsReportSymptomOpen(false)}
            />
          )}
        </Box>
        {generateContentStatus && (
          <Snackbar
            open={!!generateContentStatus}
            onClose={() => setGenerateContentStatus(undefined)}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            autoHideDuration={3000}
          >
            <VrsAlert
              message={generateContentStatus.content}
              severity={generateContentStatus.status}
              onClose={() => setGenerateContentStatus(undefined)}
            />
          </Snackbar>
        )}
        <VrsTabsContainer
          sx={{
            "& .MuiTabs-indicator": {
              backgroundColor: (theme) => `${theme.veris.colors.amethyst.normal} !important`,
            },
            "& .MuiTab-root:last-child": {
              borderRight: "none",
            },
          }}
          tabsContainerContent={{
            Results: (
              <PatientMostRecentLabResults
                key="patient-sidebar-results"
                recentLabResults={labResults?.observations || []}
                onLabsClick={() =>
                  currentPatient && dispatch(loadRecentLabResultsAsync({ id: currentPatient.id }))
                }
                labResultStatus={labResultStatus}
                onViewAllClick={onViewAllLabsResults}
              />
            ),
            "Active Meds": (
              <VrsActiveMeds
                key="patient-sidebar-active-meds"
                activeMedications={activeMeds}
                activeMedicationStatus={activeMedicationStatus}
                onActiveMedicationFail={() =>
                  currentPatient &&
                  dispatch(fetchPatientActiveMedicationsAsync({ userId: currentPatient.id }))
                }
                onViewAllClick={() => toggleActiveMedsDialog(true)}
              />
            ),
          }}
        />
        {currentPatient && (
          <VrsTreatmentPlan
            patientDetails={currentPatient}
            nextTreatments={nextTreatments || []}
            nextTreatmentsStatus={nextTreatmentsStatus}
            onAppointmentsClick={() =>
              currentPatient && dispatch(loadTreatmentPlanAsync({ id: currentPatient.id }))
            }
            onScheduleClick={() => {
              history.push(Routes.CALENDAR);
              dispatch(setEventCreationVisibility({ isOpen: true, patientId: currentPatient.id }));
            }}
          />
        )}
        <PatientActiveProblems
          conditions={patientConditions}
          status={patientConditionsStatus}
          getPatientConditions={() =>
            currentPatient && dispatch(loadPatientConditionsAsync({ id: currentPatient?.id }))
          }
          onSeeAllClick={() => setActiveProblemsDialogOpen(true)}
        />
        <SidebarButton
          clickHandler={() => toggleQOfLPreviewSidebar(true)}
          content="QUALITY OF LIFE REPORTS"
          disabled={isQOLOpen}
        />
        <SidebarButton
          clickHandler={() => {
            if (currentPatient) {
              history.push(
                `${replaceRouteParam(
                  "PATIENT_DETAILS",
                  ":userId",
                  String(currentPatient.id),
                )}/allergies`,
              );
            }
          }}
          content="ALLERGIES"
        />
        <SidebarButton
          clickHandler={() => {
            if (currentPatient) {
              history.push(
                `${replaceRouteParam(
                  "PATIENT_DETAILS",
                  ":userId",
                  String(currentPatient.id),
                )}/goals`,
              );
            }
          }}
          content="PATIENT GOALS"
        />
        <ActiveProblemsDialog
          conditions={patientConditions}
          openActiveProblemsDialog={isActiveProblemsDialogOpen}
          onClose={() => setActiveProblemsDialogOpen(false)}
          patientName={currentPatient?.name || ""}
        />
        <ActiveMedsDialog
          isOpen={isActiveMedsDialogOpen}
          onClose={() => toggleActiveMedsDialog(false)}
          medications={activeMeds}
          patientName={currentPatient?.name || ""}
        />
      </Box>

      {isQOLOpen && currentPatient && (
        <QOfLSidebarPreview
          isOpen={isQOLOpen}
          onClose={() => toggleQOfLPreviewSidebar(false)}
          fetchQOLReports={fetchQOLReports}
          onScheduleAppointmentClick={() => {
            history.push(Routes.CALENDAR);
            dispatch(setEventCreationVisibility({ isOpen: true, patientId: currentPatient?.id }));
          }}
          disableScheduleAppointment={
            !currentPatient?.inCareTeam || !!currentPatient.isInactiveOrDeceased
          }
          patientId={currentPatient.id}
        />
      )}
      {!!expandedSymptomDetails && (
        <SymptomPreviewSidebar
          isSmallScreen={isSmallScreen}
          isSymptomDetailsExpanded={!!expandedSymptomDetails}
          onCloseHandler={symptomPreviewCloseHandler}
          errorHandler={symptomPreviewErrorHandler}
          onReviewingSymptom={() =>
            currentPatient?.id &&
            symptomsApiV1.reviewSymptomReport(currentPatient?.id, expandedSymptomDetails?.report_id)
          }
        />
      )}
    </>
  );
};
