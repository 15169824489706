import { Box, Typography, useMediaQuery } from "@mui/material";
import { IconName, VrsIcon } from "@veris-health/web-core";
import React from "react";
import { Theme } from "@mui/material/styles";

interface AuthShellProps {
  children: JSX.Element;
}
export function AuthShell({ children }: AuthShellProps): JSX.Element {
  const inXXLScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up("xxl"));
  const isXsScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("xs"));
  const SLOGAN = "A digital health company";
  const getLogoWhiteHeight = () => {
    if (inXXLScreen) return "68.125rem";
    if (isXsScreen) return "15.875rem";
    return "38.5rem";
  };

  const getLogoWhiteWidth = () => {
    if (inXXLScreen) return "80.625rem";
    if (isXsScreen) return "23.313rem";
    return "59.375rem";
  };
  return (
    <Box height={isXsScreen ? "11.063rem" : "100vh"} overflow="hidden">
      <Box
        height={isXsScreen ? "11.063rem" : "100vh"}
        display="flex"
        flexDirection={isXsScreen ? "row" : "column"}
        sx={{
          backgroundColor: (theme) => theme.veris.colors.amethyst.normal,
        }}
        position="relative"
      >
        <VrsIcon
          name={IconName.TextLogoWhite}
          sx={{
            height: isXsScreen ? "20px" : "40px",
            width: isXsScreen ? "70px" : "139px",
            margin: (theme) => (isXsScreen ? theme.spacing(2, 0, 0, 2) : theme.spacing(8, 0, 0, 8)),
            zIndex: 999,
          }}
        />

        <VrsIcon
          name={IconName.LogoWhite}
          sx={{
            height: getLogoWhiteHeight(),
            width: getLogoWhiteWidth(),
            position: "absolute",
            bottom: `${inXXLScreen ? "-16%" : "-9%"}`,
            top: isXsScreen ? -40 : "",
            right: isXsScreen ? -140 : "",
          }}
        />
      </Box>
      <Box
        display="flex"
        flexDirection={isXsScreen ? "column" : "row"}
        height={isXsScreen ? "11.063rem" : "100vh"}
        sx={{ position: "absolute", top: isXsScreen ? "73px" : 0, right: 0, left: 0 }}
      >
        <Box
          display="flex"
          flexDirection="column"
          paddingTop={(theme) => (isXsScreen ? 0 : theme.spacing(40))}
          marginX={(theme) => (isXsScreen ? 1 : theme.spacing(8))}
          marginBottom={(theme) => theme.spacing(2.5)}
          minWidth={isXsScreen ? "none" : "30vw"}
          width={isXsScreen ? "60vw" : ""}
        >
          {isXsScreen ? (
            <Typography
              m={1}
              variant="h4"
              sx={{
                color: (theme) => theme.veris.colors.neutrals.white,
              }}
            >
              {SLOGAN}
            </Typography>
          ) : (
            <Typography
              m={1}
              variant="h1"
              sx={{
                color: (theme) => theme.veris.colors.neutrals.white,
                fontSize: "80px",
                lineHeight: "100px",
                fontWeight: 400,
              }}
            >
              {SLOGAN}
            </Typography>
          )}
        </Box>
        <Box
          width={isXsScreen ? "100vw" : "87vw"}
          sx={{
            backgroundColor: (theme) => theme.veris.colors.neutrals.white,
            padding: (theme) => (isXsScreen ? theme.spacing(8) : 0),
            alignItems: "center",
            display: "flex",
            position: "relative",
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
}
