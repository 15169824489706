import React from "react";
import { Switch, SwitchProps, Typography, styled } from "@mui/material";
import Box from "@mui/material/Box";
import { VrsButton, VrsDivider } from "@veris-health/web-core";
import { StyledAttachmentProps } from "../../containers/AttachmentsContainer";

const StyledSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 52,
  height: 32,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(18px)",
      color: theme.veris.colors.neutrals.white,
      "& + .MuiSwitch-track": {
        opacity: 1,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 28,
    height: 28,
  },
  "& .MuiSwitch-track": {
    borderRadius: 16,
    backgroundColor: theme.veris.colors.neutrals["grey-2"],
    opacity: 1,
  },
}));

export interface VrsChatAttachmentToggleProps {
  showAttachmentsTab: boolean;
  setShowAttachmentsTab: (value: boolean) => void;
  threadAttachmentsLength: number;
  onChatHistoryToggle: () => void;
  activeChatHistory: boolean;
}

const AttachmentToggle = styled(VrsButton, {
  shouldForwardProp: (prop: string) => prop !== "isActive",
})<StyledAttachmentProps>(({ theme, isActive }) => ({
  color: isActive ? theme.veris.colors.neutrals.white : theme.veris.colors.neutrals.black,
  background: isActive ? theme.veris.colors.amethyst.normal : theme.veris.colors.neutrals.white,
}));

export const VrsChatAttachmentToggle = ({
  showAttachmentsTab,
  setShowAttachmentsTab,
  threadAttachmentsLength,
  onChatHistoryToggle,
  activeChatHistory,
}: VrsChatAttachmentToggleProps): JSX.Element => {
  return (
    <Box
      display="flex"
      alignItems="center"
      sx={{
        p: (theme) => theme.spacing(1),
        gap: (theme) => theme.spacing(1),
        backgroundColor: (theme) =>
          activeChatHistory ? theme.veris.colors.amethyst.touch : "unset",
      }}
    >
      <AttachmentToggle
        buttonType="quaternary"
        isActive={!showAttachmentsTab}
        onClick={() => setShowAttachmentsTab(false)}
      >
        Chat
      </AttachmentToggle>
      {!activeChatHistory && (
        <AttachmentToggle
          buttonType="quaternary"
          isActive={showAttachmentsTab}
          onClick={() => setShowAttachmentsTab(true)}
        >
          Links, Docs & Images ({threadAttachmentsLength})
        </AttachmentToggle>
      )}
      <VrsDivider
        height="80%"
        orientation="vertical"
        sx={{ border: (theme) => `1px solid ${theme.veris.colors.neutrals["grey-2"]}` }}
      />
      <Typography variant="subtitle2" pt={0.25}>
        Chat history
      </Typography>
      <StyledSwitch onClick={onChatHistoryToggle} checked={activeChatHistory} />
    </Box>
  );
};
