import styled from "@mui/material/styles/styled";
import React, { useEffect, useRef, useState } from "react";
import { DialogActions, TextField } from "@mui/material";
import { VrsButton } from "@veris-health/web-core";

export const LinkContainer = styled("div")(({ theme }) => ({
  padding: "1.5rem 0.5rem 0 0.5rem",
  boxShadow: `0 1px 3px 0 rgba(220, 220, 220, 1)`,
  width: "19rem",
  display: "flex",
  flexFlow: "column",
  gap: `${theme.spacing(3)}`,
}));

export interface LinkFormProps {
  onSubmit: (link: string) => void;
  onClose: () => void;
}

export function LinkForm({ onSubmit, onClose }: LinkFormProps): JSX.Element {
  const [link, setLink] = useState("");

  const linkRef = useRef<HTMLInputElement>();

  const onKeyDown = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      e.stopPropagation();
      e.preventDefault();
      onSubmit(`${link} `);
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", onKeyDown);
    return () => {
      window.removeEventListener("keydown", onKeyDown);
    };
  }, [link]);

  return (
    <LinkContainer>
      <TextField
        inputRef={(element: HTMLInputElement) => {
          linkRef.current = element;
        }}
        variant="outlined"
        label="Link"
        autoFocus
        value={link}
        onChange={(e) => setLink(e.target.value)}
      />
      <DialogActions>
        <VrsButton buttonType="secondary" size="small" color="error" onClick={onClose}>
          Cancel
        </VrsButton>
        <VrsButton
          buttonType="secondary"
          disabled={link.length === 0}
          onClick={() => onSubmit(link)}
        >
          Save
        </VrsButton>
      </DialogActions>
    </LinkContainer>
  );
}
