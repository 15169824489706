import { convertFromHTML, EntityKey } from "draft-convert";
import { ContentState, DraftEntityMutability } from "draft-js";
import { Record } from "immutable";

const rGoogleBoldMeta = /<b .* id="docs-internal-guid-[^"]+">(.*)<\/b>/g;

export const htmlToStyle = (
  nodeName: string,
  node: HTMLElement,
  currentStyle: Set<string>,
): Set<string> => {
  switch (nodeName) {
    case "em":
      return currentStyle.add("ITALIC");
    case "strong":
      return currentStyle.add("BOLD");
    case "strike":
      return currentStyle.add("STRIKETHROUGH");
    case "u":
      return currentStyle.add("UNDERLINE");
    default:
      break;
  }

  return currentStyle;
};

export const htmlToEntity = (
  nodeName: string,
  node: HTMLElement,
  createEntity: (
    type: string,
    mutability: DraftEntityMutability,
    data: Record<string, unknown>,
  ) => EntityKey,
): string | undefined => {
  if (nodeName === "a" || nodeName === "span") {
    const href =
      (node.nodeName === "a" && (node as HTMLAnchorElement).href) || node.getAttribute("data-link");
    if (!href) {
      return undefined;
    }
    return createEntity("LINK", "MUTABLE", {
      url: href,
      isRemoved: node.className?.includes("removed"),
    });
  }
  return undefined;
};

export const htmlToBlock = (
  nodeName: string,
): { type: string; data: Record<string, unknown> } | undefined => {
  if (nodeName === "h1") {
    return {
      type: "header-one",
      data: {},
    };
  }
  if (nodeName === "h2") {
    return {
      type: "header-two",
      data: {},
    };
  }
  if (nodeName === "h3") {
    return {
      type: "header-three",
      data: {},
    };
  }
  if (nodeName === "p") {
    return {
      type: "unstyled",
      data: {},
    };
  }

  return undefined;
};

export const options = {
  htmlToStyle,
  htmlToEntity,
  htmlToBlock,
};

export default (rawHTML: string, htmlOptions = options): ContentState =>
  convertFromHTML(htmlOptions)(rawHTML.replace(rGoogleBoldMeta, "$1"));
