import React from "react";
import { Typography, Box, Link } from "@mui/material";
import { NewReleaseNotificationViewData } from "@veris-health/communication-ms/lib/v1/api";
import { VrsSystemCard } from "../VrsSystemCard";

export interface SystemNotificationProps {
  title: string;
  text: string;
  data: NewReleaseNotificationViewData;
}

export const SystemNotification = ({ title, text, data }: SystemNotificationProps): JSX.Element => {
  const href = data.link;
  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Box
        mb="4rem"
        py="0.5rem"
        borderBottom={(theme) => `1px solid ${theme.veris.colors.neutrals["grey-light"]}`}
      >
        <VrsSystemCard />
      </Box>
      <Box paddingLeft="0.5rem">
        <Typography mb="1rem" component="h3" variant="h6Semibold">
          {title}
        </Typography>
        {href ? (
          <Link
            href={href}
            variant="body"
            target="_blank"
            sx={{
              color: (theme) => theme.veris.colors.neutrals["grey-5"],
              textDecoration: "underline",
            }}
            pl={0.5}
          >
            {text}
          </Link>
        ) : (
          <Typography component="p" variant="subtitle2">
            {text}
          </Typography>
        )}
      </Box>
    </Box>
  );
};
