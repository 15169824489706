import {
  AbnormalReadingType,
  ClinicalTask,
  ClinicalTaskDataAbnormalReading,
  ClinicalTaskDataAbnormalReadingTypeEnum,
  ClinicalTaskDataOtherTypeEnum,
  ClinicalTaskDataSymptomReport,
  ClinicalTaskDataSymptomReportTypeEnum,
  ClinicalTaskPriorityEnum,
  ClinicalTaskStatusEnum,
} from "@veris-health/med-data-ms/lib/v1";
import { ReportItem } from "@veris-health/virtual-doc-ms/lib/v1";
import { FormikErrors, FormikTouched } from "formik";
import { VrsMedStaffProfileModel, VrsPatientInfo } from "../../shared/interfaces";
import { NotificationTaskData } from "../components/TaskContainer";

export type ValidationSchema = {
  subType?: string | string[];
  title: string;
  shared?: {
    name: string;
    id: number;
  }[];
  assignee?: {
    name: string;
    id: number;
  };
  createdBy?: { name: string; id: number; image?: string; profession?: string };
  status?: ClinicalTaskStatusEnum;
  priority: ClinicalTaskPriorityEnum;
  dateCreated?: string;
  dueDate?: string;
  description?: string;
  taskType?: { type: string; text?: string };
  patient?: {
    name: string;
    id: number;
    hospital?: number | number[];
    image?: string;
  };
  notes?: string[];
};

export const getCreateTaskFieldData = (
  values: ValidationSchema,
  errors: FormikErrors<ValidationSchema>,
  touched: FormikTouched<ValidationSchema>,
  task: boolean,
) => {
  const {
    subType,
    title,
    shared,
    assignee,
    createdBy,
    status,
    priority,
    dateCreated,
    dueDate,
    description,
    taskType,
    patient,
    notes,
  } = values;
  return [
    {
      label: "Task title",
      name: "title",
      value: title,
      helperText: errors.title,
      touched: touched.title,
    },
    {
      label: "Patient",
      name: "patient",
      value: patient,
      helperText: errors.patient,
      touched: touched.patient,
    },
    {
      label: "Status",
      name: "status",
      value: status,
      helperText: errors.status,
      touched: touched.status,
    },
    {
      label: "Priority",
      name: "priority",
      value: priority,
      helperText: errors.priority,
      touched: touched.priority,
    },
    {
      label: task ? "Created By" : "",
      name: "createdBy",
      value: createdBy,
      helperText: errors.createdBy,
      touched: touched.createdBy,
    },

    {
      label: "Date Created",
      name: "dateCreated",
      value: dateCreated,
      helperText: errors.dateCreated,
      touched: touched.dateCreated,
    },
    {
      label: "Due Date",
      name: "dueDate",
      value: dueDate,
      helperText: errors.dueDate,
      touched: touched.dueDate,
    },
    {
      label: "Description",
      name: "description",
      value: description,
      helperText: errors.description,
      touched: touched.description,
    },

    {
      label: "Task type",
      name: "taskType",
      value: taskType,
      helperText: errors.taskType,
      touched: touched.taskType,
    },
    {
      label: "Subtype",
      name: "subType",
      value: subType,
      helperText: errors.subType,
      touched: touched.subType,
    },
    {
      label: "Shared",
      name: "shared",
      value: shared,
      helperText: errors.shared,
      touched: touched.shared,
    },
    {
      label: "Assignee",
      name: "assignee",
      value: assignee,
      helperText: errors.assignee,
      touched: touched.assignee,
    },
    {
      label: "",
      name: "notes",
      value: notes,
      helperText: errors.notes,
      touched: touched.notes,
    },
  ];
};

export const getDataFormatForSubmit = (
  taskType?: {
    type: string;
    text?: string;
  },
  subType?: string | string[],
  reportedSymptoms?: ReportItem[],
) => {
  if (taskType?.type === ClinicalTaskDataSymptomReportTypeEnum.Symptom && subType) {
    const symptom = reportedSymptoms?.find((report) => report.id.toString() === subType);
    return {
      type: ClinicalTaskDataSymptomReportTypeEnum.Symptom,
      symptom_name: symptom?.symptom.label || "",
      symptom_report_id: +subType,
    };
  }
  if (taskType?.type === ClinicalTaskDataAbnormalReadingTypeEnum.AbnormalReading && subType) {
    return {
      type: ClinicalTaskDataAbnormalReadingTypeEnum.AbnormalReading,
      abnormal_readings: subType as AbnormalReadingType[],
    };
  }
  if (taskType?.type === ClinicalTaskDataOtherTypeEnum.Other) {
    return {
      type: ClinicalTaskDataOtherTypeEnum.Other,
      text: taskType?.text || "",
    };
  }
  return {
    type: ClinicalTaskDataOtherTypeEnum.Other,
    text: taskType?.text || "",
  };
};

const getPatient = (
  taskToEdit?: ClinicalTask,
  currentPatient?: VrsPatientInfo,
  notificationTask?: NotificationTaskData,
) => {
  if (taskToEdit) {
    return {
      name: taskToEdit.patient.full_name || "",
      id: taskToEdit.patient.user_id,
      hospital: taskToEdit.patient.hospital_ids[0],
      image: "",
      dataSources: [],
    };
  }
  if (notificationTask && notificationTask.patient) {
    return {
      name: notificationTask.patient.name || "",
      hospital: notificationTask.patient.hospital,
      id: notificationTask.patient.id,
      image: notificationTask.patient.image,
      dataSources: notificationTask.patient.dataSources || [],
    };
  }

  if (currentPatient) {
    return {
      name: currentPatient.name || "",
      id: currentPatient.id,
      hospital: currentPatient.hospital?.id as number,
      image: currentPatient.picture,
      dataSources: currentPatient.dataSources || [],
    };
  }
  return undefined;
};

const getAssignee = (taskToEdit?: ClinicalTask) => {
  if (taskToEdit) {
    return { name: taskToEdit.assignee.full_name || "", id: taskToEdit.assignee.user_id };
  }

  return undefined;
};

const getSharedTeam = (currentUser: VrsMedStaffProfileModel, taskToEdit?: ClinicalTask) => {
  if (taskToEdit) {
    return taskToEdit.shared_team.map((member) => ({
      name: member.full_name || "",
      id: member.user_id,
    }));
  }
  if (currentUser) {
    return [{ name: currentUser.fullName, id: currentUser.id }];
  }
  return [];
};

const getTaskType = (taskToEdit?: ClinicalTask, notificationTask?: NotificationTaskData) => {
  if (taskToEdit) {
    return {
      type: taskToEdit.data.type,
      text:
        taskToEdit.data.type === ClinicalTaskDataOtherTypeEnum.Other
          ? taskToEdit.data.text
          : undefined,
    };
  }
  if (notificationTask) {
    return { type: notificationTask.type };
  }
  return undefined;
};

const getTaskSubType = (taskToEdit?: ClinicalTask, notificationTask?: NotificationTaskData) => {
  if (taskToEdit) {
    if (taskToEdit.data.type === ClinicalTaskDataSymptomReportTypeEnum.Symptom) {
      return (taskToEdit.data as ClinicalTaskDataSymptomReport).symptom_name;
    }
    if (taskToEdit.data.type === ClinicalTaskDataAbnormalReadingTypeEnum.AbnormalReading) {
      return (taskToEdit.data as ClinicalTaskDataAbnormalReading).abnormal_readings;
    }
  }
  if (notificationTask && notificationTask.subType) {
    return notificationTask.subType;
  }
  return undefined;
};
const getDescription = (taskToEdit?: ClinicalTask, notificationTask?: NotificationTaskData) => {
  if (taskToEdit) {
    return taskToEdit.description;
  }
  if (notificationTask && notificationTask.description) {
    return notificationTask.description;
  }
  return "";
};

export const getInitialState = (
  currentUser: VrsMedStaffProfileModel,
  taskToEdit?: ClinicalTask,
  currentPatient?: VrsPatientInfo,
  notificationTask?: NotificationTaskData,
) => {
  const patient = getPatient(taskToEdit, currentPatient, notificationTask);
  const assignee = getAssignee(taskToEdit);
  const sharedTeam = getSharedTeam(currentUser, taskToEdit);
  const type = getTaskType(taskToEdit, notificationTask);
  const subType = getTaskSubType(taskToEdit, notificationTask);
  const description = getDescription(taskToEdit, notificationTask);
  return { patient, assignee, sharedTeam, type, subType, description };
};
