import React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { Button, Typography } from "@mui/material";
import { VrsSelect, Option } from "@veris-health/web-core";
import { VrsMultiselect } from "./VrsMultiselect";

interface PatientFiltersProps {
  onCancerTypeSelected: (value: string[]) => void;
  cancerTypeFilter: string[];
  cancerTypeSelectOptions: Option[];
  symptomFilter: string[];
  symptomSelectOptions: Option[];
  onSymptomSelected: (value: string[]) => void;
  tagFilter: string[];
  tagOptions: Option[];
  onTagSelected: (value: string[]) => void;
  onFiltersReset: () => void;
  predefinedFilters?: string;
  sortByAlphabetValue?: string | undefined;
  onAlphabetSorting?: (value: string) => void;
}

const sortSelectOptions: Option[] = [
  {
    label: "All",
    value: "",
  },
  {
    label: "Sorted by A to Z",
    value: "asc",
  },
  {
    label: "Sorted by Z to A",
    value: "desc",
  },
];

export const PatientFilters = ({
  cancerTypeFilter,
  cancerTypeSelectOptions,
  onCancerTypeSelected,
  symptomFilter,
  onSymptomSelected,
  symptomSelectOptions,
  tagFilter,
  tagOptions,
  onTagSelected,
  onFiltersReset,
  onAlphabetSorting,
  sortByAlphabetValue,
  predefinedFilters,
}: PatientFiltersProps): JSX.Element => {
  const handleResetFilters = () => {
    onFiltersReset();
    onTagSelected(predefinedFilters ? [predefinedFilters] : []);
  };
  return (
    <Box display="flex" my={2.5}>
      <Stack direction="row" spacing={3}>
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography variant="body1" color={(theme) => theme.veris.colors.neutrals["grey-4"]}>
            Cancer Type
          </Typography>
          <VrsMultiselect
            filters={cancerTypeFilter}
            filterOptions={cancerTypeSelectOptions}
            handleChange={onCancerTypeSelected}
          />
        </Box>
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography variant="body1" color={(theme) => theme.veris.colors.neutrals["grey-4"]}>
            Symptoms
          </Typography>
          <VrsMultiselect
            filters={symptomFilter}
            filterOptions={symptomSelectOptions}
            handleChange={onSymptomSelected}
          />
        </Box>
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography variant="body1" color={(theme) => theme.veris.colors.neutrals["grey-4"]}>
            Tags
          </Typography>
          <VrsMultiselect
            filters={tagFilter}
            predefinedFilters={predefinedFilters}
            filterOptions={tagOptions}
            handleChange={onTagSelected}
            disableClear={tagFilter.length === 1 && !!predefinedFilters}
          />
        </Box>
      </Stack>
      {onAlphabetSorting && (
        <Stack ml="auto" direction="row" spacing={3}>
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography variant="body1" color={(theme) => theme.veris.colors.neutrals["grey-4"]}>
              Sort Alphabetically
            </Typography>
            <VrsSelect
              outerSx={{
                width: "150px",
              }}
              options={sortSelectOptions}
              onSelected={onAlphabetSorting}
              value={sortByAlphabetValue}
            />
          </Box>

          <Box display="flex" pt={2}>
            <Button onClick={handleResetFilters}>
              <Typography
                color={(theme) => theme.veris.colors.amethyst.normal}
                sx={{
                  textTransform: "none",
                  textDecoration: "underline",
                  fontWeight: 400,
                }}
              >
                Clear all
              </Typography>
            </Button>
          </Box>
        </Stack>
      )}
    </Box>
  );
};
