import { Dialog, DialogActions, DialogContent, styled, Typography } from "@mui/material";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import React, { useEffect, useState } from "react";
import { VrsButton, dateFormats } from "@veris-health/web-core";

dayjs.extend(duration);
export interface SessionModalProps {
  isOpen: boolean;
  handleLogout: () => void;
  handleContinueSession: () => void;
  countdownTimer: number;
}

const VrsDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: 0,
  },
  "& .MuiDialogActions-root": {
    padding: 0,
  },

  "& .MuiDialog-paper": {
    padding: theme.spacing(5),
    minHeight: "280px",
  },
}));

export const SessionModal = ({
  isOpen,
  handleContinueSession,
  handleLogout,
  countdownTimer,
}: SessionModalProps): JSX.Element => {
  const [remainingTime, setRemainingTime] = useState(countdownTimer);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (remainingTime <= 0 && isOpen) {
        handleLogout();
      } else {
        setRemainingTime(remainingTime - 1000);
      }
    }, 1000);

    return () => clearTimeout(timeout);
  }, [handleLogout, isOpen, remainingTime]);

  return (
    <VrsDialog open={isOpen}>
      <Typography variant="h3" p={0} pb={(theme) => theme.spacing(7)}>
        Your Session is about to expire
      </Typography>
      <DialogContent>
        <Typography variant="subtitle2">
          Your user session is expiring. Would you like to stay connected?
        </Typography>
        <Typography variant="subtitle1" paddingTop={(theme) => theme.spacing(3)}>
          Redirecting in {dayjs.duration(remainingTime).format(dateFormats["m:ss"])}.
        </Typography>
      </DialogContent>
      <DialogActions>
        <VrsButton buttonType="secondary" onClick={handleLogout}>
          Logout
        </VrsButton>
        <VrsButton buttonType="primary" onClick={handleContinueSession}>
          Stay connected
        </VrsButton>
      </DialogActions>
    </VrsDialog>
  );
};
