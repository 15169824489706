import { ChatThreadInfo } from "@veris-health/communication-ms/lib/v1";
import { debounce } from "lodash";
import { useEffect, useCallback } from "react";
import { useProfile } from "../../context/profile";
import {
  startSessionEventActivity,
  endSessionEventActivity,
} from "../../features/shared/slices/activityTrackingSlice";
import { selectCallStatus } from "../../features/shared/slices/voipSlice";
import { useAppDispatch } from "../useAppDispatch";
import { useAppSelector } from "../useAppSelector";
import { getHiddenPropsNames, debounceTime } from "../useBilling";

const hiddenPropsNames = getHiddenPropsNames(document);

const findPatientInThread = (threadToSearch?: ChatThreadInfo) => {
  return (
    threadToSearch &&
    threadToSearch.participants.find((participant) => participant.user_type === "patient")
  );
};

export function useChatBilling(thread?: ChatThreadInfo): void {
  const currentUserData = useProfile();
  const dispatch = useAppDispatch();
  const callStatus = useAppSelector(selectCallStatus);

  const handleDispatchEnd = useCallback(
    debounce(() => {
      dispatch(endSessionEventActivity());
    }, debounceTime),
    [dispatch],
  );

  const handleDispatchStart = useCallback(
    debounce((patient: number, medicId: number, roles) => {
      dispatch(startSessionEventActivity({ patientId: patient, medicId, roles }));
    }, debounceTime),
    [dispatch],
  );

  const onVisibilityChange = () => {
    if (!callStatus.isOnCall && thread) {
      const patientInThread = findPatientInThread(thread);
      if (document.visibilityState === hiddenPropsNames?.hidden) {
        handleDispatchEnd();
      } else if (currentUserData && patientInThread) {
        handleDispatchStart(patientInThread.user_id, currentUserData.id, currentUserData.roles);
      }
    }
  };

  useEffect(() => {
    const patientInThread = findPatientInThread(thread);

    if (patientInThread?.user_id && hiddenPropsNames && patientInThread && !thread?.private) {
      document.addEventListener(hiddenPropsNames.visibilityChange, onVisibilityChange);
    }

    return () => {
      if (patientInThread?.user_id && hiddenPropsNames && patientInThread && !thread?.private) {
        document.removeEventListener(hiddenPropsNames.visibilityChange, onVisibilityChange);
      }
    };
  }, [thread]);

  useEffect(() => {
    const patientInThread = findPatientInThread(thread);

    if (currentUserData && !callStatus.isOnCall && patientInThread && !thread?.private) {
      handleDispatchStart(patientInThread.user_id, currentUserData.id, currentUserData.roles);
    }
    return () => {
      if (patientInThread?.user_id && !callStatus.isOnCall && patientInThread && !thread?.private) {
        handleDispatchEnd();
      }
    };
  }, [callStatus.isOnCall, thread]);
}
