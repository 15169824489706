import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import {
  CareTeamMemberInfo,
  Demographics,
  MedRoleEnum,
  PatientStatusEnumAPI,
  TagItem,
} from "@veris-health/user-ms/lib/v1";
import { get } from "lodash";
import {
  IconName,
  VrsIcon,
  VrsButton,
  verisColors,
  VrsTooltip,
  VrsIconButton,
  dateFormats,
} from "@veris-health/web-core";
import dayjs from "dayjs";
import { PatientInfoField } from "../PatientInfoField";
import { PatientInfoLabel } from "../PatientInfoLabel";
import { VrsSingleTag } from "../VrsTags";
import { VrsPatientInfo } from "../../../features/shared/interfaces";
import { PatientTagsDialog } from "../../../features/PatientDetails/PatientTagsDialog";
import { formatPhoneNumber } from "../../../features/shared/helpers";
import { AddonButton } from "../../../utils/styledComponents";
import { useProfile } from "../../../context/profile";
import { PatientStatusesMap } from "../../../constants";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { selectPatientRpmItemsStatus } from "../../../features/PatientDetails/patientDetailsSlice";

const TCM_ACCESS_ROLES = [
  MedRoleEnum.NursePractitioner,
  MedRoleEnum.Doctor,
  MedRoleEnum.PhysicianAssistant,
];

interface FieldName {
  fieldName: string;
  iconName: string;
  value: string;
}

const fields = [
  {
    label: "Patient Data",
    fields: [
      { fieldName: "Diagnosis", iconName: "Diagnosis", value: "diagnosis.cancerType" },
      { fieldName: "MRN", iconName: "EhrIdIcon", value: "ehr_id" },
      { fieldName: "Language", iconName: "Language", value: "lang" },
      { fieldName: "Gender", iconName: "Gender", value: "gender" },
      { fieldName: "Birth Sex", iconName: "BirthSex", value: "birth_sex" },
      { fieldName: "DoB", iconName: "Calendar", value: "dob" },
      { fieldName: "Nationality", iconName: "Nationality", value: "nationality" },
      { fieldName: "Care Team", iconName: "Stethoscope", value: "name" },
      { fieldName: "Status", iconName: "Info", value: "patientStatus" },
    ],
  },
  {
    label: "Billing Monitoring",
    fields: [
      { fieldName: "Clinical Time", iconName: "PendingActions", value: "rpmTime" },
      { fieldName: "TCM Billing", iconName: "TCM", value: "tcmBilling" },
    ],
  },

  {
    label: "Contact Information",
    fields: [
      { fieldName: "SOS Number", iconName: "PhoneSos", value: "emergency_phone_number" },
      { fieldName: "Phone Number", iconName: "Phone", value: "phone_number" },
      { fieldName: "Email", iconName: "Email", value: "email" },
      { fieldName: "Address", iconName: "Address", value: "" },
      { fieldName: "Tags", iconName: "", value: "tags" },
    ],
  },
];

export interface PatientMoreDetailsInfoProps {
  info: Demographics;
  patient: VrsPatientInfo | undefined;
  editMode: boolean;
  setEditMode: (isEditMode: boolean) => void;
  onLastNotificationsClick: () => void;
  onAddRPMClick: () => void;
  onReviewRPMClick: () => void;
  onAddTCMBilling: () => void;
  onReviewTCMBilling: () => void;
  mainOncologist?: CareTeamMemberInfo;
  careTeam?: CareTeamMemberInfo[];
  disableRpm?: boolean;
  rpmTime?: number;
  onStatusEdit?: () => void;
}

const CareTeamAddon = ({ careTeam }: { careTeam: CareTeamMemberInfo[] }): JSX.Element => (
  <VrsTooltip
    sx={{ width: "300px" }}
    bcgcolor="black"
    title={
      <Box p={1}>
        {careTeam.map((member) => (
          <Box key={member.id} display="flex" my={0.5}>
            <Grid item xs={6}>
              <Typography color={(theme) => theme.veris.colors.neutrals.white} variant="body">
                {member.full_name}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              &nbsp;•&nbsp;
            </Grid>
            <Grid item xs={5}>
              <Typography
                color={(theme) => theme.veris.colors.neutrals["grey-3"]}
                variant="body"
                sx={{ textTransform: "capitalize" }}
              >
                {member.profession || "N/A"}{" "}
              </Typography>
            </Grid>
          </Box>
        ))}
      </Box>
    }
  >
    <AddonButton>+{careTeam.length}</AddonButton>
  </VrsTooltip>
);

export function PatientMoreDetailsInfo({
  info,
  patient,
  editMode,
  setEditMode,
  onLastNotificationsClick,
  mainOncologist,
  onAddRPMClick,
  onReviewRPMClick,
  onAddTCMBilling,
  onReviewTCMBilling,
  careTeam,
  disableRpm,
  rpmTime,
  onStatusEdit,
}: PatientMoreDetailsInfoProps): JSX.Element {
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const isMediumScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("xl"));
  const rpmItemsStatus = useAppSelector(selectPatientRpmItemsStatus);
  const profile = useProfile();
  const color = verisColors.neutrals["grey-3"];

  const currentUserHasTcmAcceess = useMemo(() => {
    const profileRoleNames = profile?.roles?.map((role) => role.role_name) || [];
    return TCM_ACCESS_ROLES.some((item) => profileRoleNames.includes(item));
  }, [profile]);

  return (
    <>
      <Grid
        container
        spacing={1}
        justifyContent="flex-end"
        width="100%"
        data-test-hook="patient-more-details-info-container"
        sx={{
          backgroundColor: verisColors.neutrals["grey-1"],
          marginLeft: 0,
          marginTop: 0,
          borderRadius: (theme) => theme.spacing(0, 0, 1, 1),
          border: `1px solid ${verisColors.neutrals["grey-2"]}`,
          borderTop: "none",
        }}
        p={1.5}
      >
        <Grid item xs={12}>
          <Grid
            container
            spacing={2}
            alignItems="baseline"
            data-test-hook="patient-info-fields-container"
            sx={{ height: "100%" }}
          >
            {fields.map((fieldObj, index) => {
              return (
                <Grid
                  item
                  key={fieldObj.label}
                  xs={6}
                  lg={6}
                  xl={4}
                  mb={2}
                  sx={{
                    borderRight: (theme) =>
                      index !== fields.length - 1
                        ? `1px solid ${theme.veris.colors.neutrals["grey-2"]}`
                        : "none",
                  }}
                >
                  <Typography
                    variant="bodySemibold"
                    sx={{
                      textTransform: "uppercase",
                      color: (theme) => theme.veris.colors.neutrals["grey-4"],
                    }}
                  >
                    {fieldObj.label}
                  </Typography>
                  {fieldObj.fields.map((name: FieldName) => (
                    <Grid item xs={12} display="flex" key={name.fieldName + name.value}>
                      <Grid display="flex" alignItems="center" my={1}>
                        {![
                          "Diagnosis",
                          "Address",
                          "DoB",
                          "MRN",
                          "Clinical Time",
                          "TCM Billing",
                          "Tags",
                        ].includes(name.fieldName) &&
                          !name.fieldName.includes("Number") &&
                          !name.fieldName.includes("Team") &&
                          !name.fieldName.includes("Status") && (
                            <>
                              <PatientInfoLabel
                                fieldName={name.fieldName}
                                fieldNameIcon={name.iconName}
                                sx={{ minWidth: "136px", width: "136px" }}
                              />
                              <PatientInfoField
                                value={get(info, name.value, "N/A") || "N/A"}
                                sx={{ textTransform: "capitalize" }}
                              />
                            </>
                          )}
                        {name.fieldName.includes("Number") && (
                          <>
                            <PatientInfoLabel
                              fieldName={name.fieldName}
                              fieldNameIcon={name.iconName}
                              sx={{ minWidth: "136px", width: "136px" }}
                            />
                            <PatientInfoField
                              value={
                                get(info, name.value)
                                  ? formatPhoneNumber(get(info, name.value))
                                  : "N/A"
                              }
                            />
                          </>
                        )}
                        {name.fieldName === "Care Team" && (
                          <>
                            <PatientInfoLabel
                              fieldName={name.fieldName}
                              fieldNameIcon={name.iconName}
                              sx={{ minWidth: "136px", width: "136px" }}
                            />
                            <Box>
                              <PatientInfoField
                                value={mainOncologist?.full_name || "N/A"}
                                key={mainOncologist?.full_name}
                              />
                              <Typography
                                variant="caption"
                                sx={{
                                  color: (theme) => theme.veris.colors.neutrals["grey-3"],
                                  padding: (theme) => theme.spacing(0.25, 1, 0, 1),
                                  wordBreak: "break-word",
                                }}
                              >
                                Main oncologist
                              </Typography>
                            </Box>
                            {careTeam && careTeam.length > 0 && (
                              <CareTeamAddon careTeam={careTeam} />
                            )}
                          </>
                        )}
                        {name.fieldName === "Diagnosis" && (
                          <>
                            <PatientInfoLabel
                              fieldName="Diagnosis"
                              fieldNameIcon="Diagnosis"
                              sx={{ minWidth: "136px", width: "136px" }}
                            />
                            <PatientInfoField value={patient?.diagnosis?.cancerType} />
                          </>
                        )}

                        {name.fieldName === "Address" && (
                          <>
                            <PatientInfoLabel
                              fieldName="Address"
                              fieldNameIcon="Address"
                              sx={{ minWidth: "136px", width: "136px" }}
                            />
                            <PatientInfoField
                              value={
                                `${info.street ?? ""} ${info.city ?? ""} ${info.state ?? ""} ${
                                  info.zipcode ?? ""
                                }`.trim() || "N/A"
                              }
                            />
                          </>
                        )}
                        {name.fieldName === "DoB" && (
                          <>
                            <PatientInfoLabel
                              fieldName={name.fieldName}
                              fieldNameIcon={name.iconName}
                              sx={{ minWidth: "136px", width: "136px" }}
                              color={verisColors.neutrals["grey-3"]}
                            />
                            <PatientInfoField
                              value={
                                get(patient, name.value)
                                  ? dayjs(get(patient, name.value)).format(
                                      dateFormats["MM/DD/YYYY"],
                                    )
                                  : "N/A"
                              }
                            />
                          </>
                        )}
                        {name.fieldName === "MRN" && (
                          <>
                            <PatientInfoLabel
                              fieldName={name.fieldName}
                              fieldNameIcon={name.iconName}
                              sx={{ minWidth: "136px", width: "136px" }}
                              color={verisColors.neutrals["grey-3"]}
                            />
                            <PatientInfoField value={get(patient, name.value, "N/A") || "N/A"} />
                          </>
                        )}
                        {name.fieldName === "Clinical Time" && (
                          <>
                            <PatientInfoLabel
                              fieldName={name.fieldName}
                              fieldNameIcon={name.iconName}
                              sx={{ minWidth: "136px", width: "136px" }}
                            />
                            {rpmItemsStatus === "loading" ? (
                              <Box mr={1} display="flex">
                                <CircularProgress size={14} />
                              </Box>
                            ) : (
                              <PatientInfoField value={`${rpmTime} min`} key={rpmTime} />
                            )}
                            <VrsTooltip
                              bcgcolor={verisColors.neutrals.black}
                              title="Add Clinical Time"
                              placement="top"
                            >
                              <Box
                                sx={{
                                  border: (theme) =>
                                    `1px solid ${theme.veris.colors.neutrals["grey-light"]}`,
                                  padding: "4px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  borderRadius: "4px",
                                  height: "28px",
                                  width: "28px",
                                }}
                              >
                                <VrsIconButton
                                  onClick={onAddRPMClick}
                                  disabled={disableRpm}
                                  iconProps={{
                                    name: IconName.CalendarAddOn,
                                    color: disableRpm ? verisColors.neutrals["grey-3"] : "default",
                                  }}
                                />
                              </Box>
                            </VrsTooltip>
                            <VrsTooltip
                              bcgcolor={verisColors.neutrals.black}
                              title="Review Clinical Time"
                              placement="top"
                            >
                              <Box
                                sx={{
                                  border: (theme) =>
                                    `1px solid ${theme.veris.colors.neutrals["grey-light"]}`,
                                  cursor: disableRpm ? "initial" : "pointer",
                                  padding: "4px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  borderRadius: "4px",
                                  height: "28px",
                                  width: "28px",
                                  marginLeft: "6px",
                                }}
                              >
                                <VrsIconButton
                                  onClick={onReviewRPMClick}
                                  disabled={disableRpm}
                                  iconProps={{
                                    name: IconName.Update,
                                    color: disableRpm ? verisColors.neutrals["grey-3"] : "default",
                                  }}
                                />
                              </Box>
                            </VrsTooltip>
                          </>
                        )}
                        {name.fieldName === "TCM Billing" && (
                          <>
                            <PatientInfoLabel
                              fieldName={name.fieldName}
                              fieldNameIcon={name.iconName}
                              sx={{ minWidth: "136px", width: "136px" }}
                            />
                            <VrsTooltip
                              bcgcolor={verisColors.neutrals.black}
                              title="Add TCM Billing"
                              placement="top"
                            >
                              <Box
                                sx={{
                                  border: (theme) =>
                                    `1px solid ${theme.veris.colors.neutrals["grey-light"]}`,
                                  padding: "4px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  borderRadius: "4px",
                                  height: "28px",
                                  width: "28px",
                                }}
                              >
                                <VrsIconButton
                                  disabled={disableRpm || !currentUserHasTcmAcceess}
                                  onClick={() => {
                                    onAddTCMBilling();
                                  }}
                                  iconProps={{
                                    name: IconName.CalendarAddOn,
                                    color:
                                      disableRpm || !currentUserHasTcmAcceess
                                        ? verisColors.neutrals["grey-3"]
                                        : "default",
                                  }}
                                />
                              </Box>
                            </VrsTooltip>
                            <VrsTooltip
                              bcgcolor={verisColors.neutrals.black}
                              title="Review TCM Billing"
                              placement="top"
                            >
                              <Box
                                sx={{
                                  border: (theme) =>
                                    `1px solid ${theme.veris.colors.neutrals["grey-light"]}`,
                                  cursor: "initial",
                                  padding: "4px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  borderRadius: "4px",
                                  height: "28px",
                                  width: "28px",
                                  marginLeft: "6px",
                                }}
                              >
                                <VrsIconButton
                                  onClick={onReviewTCMBilling}
                                  disabled={disableRpm}
                                  iconProps={{
                                    name: IconName.Update,
                                    color: disableRpm ? verisColors.neutrals["grey-3"] : "default",
                                  }}
                                />
                              </Box>
                            </VrsTooltip>
                          </>
                        )}
                        {name.fieldName === "Status" && (
                          <>
                            <PatientInfoLabel
                              fieldName={name.fieldName}
                              fieldNameIcon={name.iconName}
                              sx={{ minWidth: "136px", width: "136px" }}
                              color={verisColors.neutrals["grey-3"]}
                            />
                            <Box display="flex" alignItems="center">
                              <PatientInfoField
                                value={
                                  get(patient, name.value)
                                    ? PatientStatusesMap[
                                        get(patient, name.value) as PatientStatusEnumAPI
                                      ]
                                    : "N/A"
                                }
                                key={get(patient, name.value, "N/A")}
                                sx={{ textTransform: "capitalize" }}
                              />
                              <VrsIconButton
                                iconProps={{
                                  name: IconName.Edit,
                                  stroke: verisColors.neutrals["grey-4"],
                                }}
                                sx={{ cursor: "pointer" }}
                                onClick={onStatusEdit}
                              />
                            </Box>{" "}
                          </>
                        )}
                        {name.fieldName === "Tags" && (
                          <Box pt={2}>
                            <Typography
                              variant="bodySemibold"
                              sx={{
                                textTransform: "uppercase",
                                color: (theme) => theme.veris.colors.neutrals["grey-4"],
                              }}
                            >
                              Tags
                            </Typography>
                            <Box data-test-hook="treatment-tags">
                              {patient &&
                              get(patient, name.value) &&
                              get(patient, name.value).length > 0 ? (
                                get(patient, name.value).map((tag: TagItem) => (
                                  <VrsSingleTag label={tag.label} key={tag.id} color={color} />
                                ))
                              ) : (
                                <PatientInfoField value="N/A" />
                              )}
                              {!patient?.isInactiveOrDeceased && (
                                <Box
                                  onClick={() => setShowEdit(true)}
                                  sx={{
                                    borderRadius: (theme) => +theme.shape.borderRadius * 0.375,
                                    color: `${color}`,
                                    border: `1px solid ${color}`,
                                    background: `${verisColors.neutrals.white}`,
                                    padding: (theme) => theme.spacing(0.75, 0.75, 0.5, 0.75),
                                    margin: (theme) => theme.spacing(0.375, 0.75, 0.375, 0),
                                    display: "inline-flex",
                                    maxWidth: isMediumScreen ? "min-content" : "unset",
                                    cursor: "pointer",
                                  }}
                                >
                                  <Typography component="div" variant="bodyMedium" pr={0.5}>
                                    Edit tags
                                  </Typography>
                                  <VrsIcon name={IconName.Edit} stroke={color} />
                                </Box>
                              )}
                            </Box>
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              );
            })}
          </Grid>
        </Grid>

        {editMode ? (
          <>
            <Grid item xs={1} mt={1}>
              <Button
                data-test-hook="edit-mode-cancel-btn"
                onClick={() => setEditMode(false)}
                sx={{ borderRadius: (theme) => +theme.shape.borderRadius * 5 }}
              >
                <Typography
                  variant="body"
                  color={verisColors.amethyst.dark}
                  sx={{
                    textDecoration: "underline",
                    textTransform: "none",
                  }}
                >
                  Cancel
                </Typography>
              </Button>
            </Grid>
            <Grid item xs={1} mt={1}>
              <VrsButton
                buttonType="secondary"
                onClick={() => setEditMode(false)}
                data-test-hook="edit-mode-save-btn"
              >
                Save
              </VrsButton>
            </Grid>
          </>
        ) : (
          <Grid item mt={1}>
            <VrsButton
              data-test-hook="last-notifications-button"
              buttonType="secondary"
              onClick={onLastNotificationsClick}
              sx={{
                float: "right",
              }}
            >
              See last notifications
            </VrsButton>
          </Grid>
        )}
      </Grid>
      {showEdit && patient && (
        <PatientTagsDialog
          patientTags={patient.tags || []}
          patientId={patient.id}
          hospitalId={patient.hospital?.id}
          open={showEdit}
          onClose={() => setShowEdit(false)}
        />
      )}
    </>
  );
}
