import { Box, Typography } from "@mui/material";
import { IconName, VrsIcon } from "@veris-health/web-core";
import React from "react";

export interface TimelineResourceProps {
  id: number;
  name: string;
  img: IconName;
  backgroundColor: string;
  color: string;
}

export const TimelineResource = ({
  name,
  img,
  backgroundColor,
}: TimelineResourceProps): JSX.Element => {
  return (
    <Box
      sx={{
        backgroundColor,
        height: "100%",
        width: "100%",
        position: "absolute",
      }}
    >
      <Box p={1}>
        <VrsIcon name={img} size={32} />
        <Typography variant="h6" fontWeight={600} pt={1}>
          {name}
        </Typography>
      </Box>
    </Box>
  );
};
