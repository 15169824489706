import React, { useState } from "react";
import {
  IconName,
  VrsIconButton,
  VrsDivider,
  VrsButton,
  verisColors,
} from "@veris-health/web-core";

export interface PatientCommunicationButtonProps {
  onStartCall?: () => void;
  onEndCall?: () => void;
  isOnCall: boolean;
  callDisabled?: boolean;
  onScheduleAppointmentClick: () => void;
  disabled?: boolean;
}

export function PatientCommunicationButton({
  onStartCall,
  onEndCall,
  isOnCall,
  callDisabled,
  onScheduleAppointmentClick,
  disabled,
}: PatientCommunicationButtonProps): JSX.Element {
  const [onCallConfirmation, setOnCallConfirmation] = useState<boolean>(false);

  if (isOnCall) {
    return (
      <VrsButton
        buttonType="primary"
        onClick={onEndCall}
        data-test-hook="end-call-button"
        disabled={callDisabled}
      >
        End Call
      </VrsButton>
    );
  }

  if (onCallConfirmation) {
    return (
      <VrsButton
        data-test-hook="start-call-btn"
        buttonType="primary"
        onClick={() => {
          setOnCallConfirmation(false);
          if (onStartCall) onStartCall();
        }}
      >
        Start Call
      </VrsButton>
    );
  }

  return (
    <>
      <VrsButton
        buttonType="primary"
        onClick={onScheduleAppointmentClick}
        disabled={disabled}
        data-test-hook="schedule-appointment-button"
      >
        Schedule Appointment
      </VrsButton>
      <VrsDivider orientation="vertical" height="40px" />
    </>
  );
}
