import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

import {
  Eventcalendar,
  setOptions,
  MbscCellClickEvent,
  EventcalendarBase,
  MbscCalendarEvent,
  MbscEventcalendarView,
  MbscEventCreateEvent,
  MbscPageLoadingEvent,
  MbscCalendarEventData,
} from "@mobiscroll/react";
import "./Calendar.scss";
import dayjs from "dayjs";
import { AppointmentStatusEnum, GetAppointmentResponse } from "@veris-health/med-data-ms/lib/v1";
import { MedStaffProfileItem } from "@veris-health/user-ms/lib/v1";
import { RenderLabel } from "./components/RenderLabel";
import { RenderScheduleEvent } from "./components/RenderScheduleEvent";
import {
  AgendaTypes,
  CalendarShownType,
  resetCalendarEvents,
  selectCreateEventOpenWithPatientId,
  selectEndDate,
  selectIsCreateEventOpen,
  selectIsEventPopupOpen,
  selectStartDate,
  setAgendaView,
  setCalendarStartAndEndDate,
  setCalendarView,
  setEventCreationVisibility,
  setEventPopupVisibility,
} from "./calendarSlice";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { RenderEvent } from "./components/RenderEvent";
import { EventPopup } from "./EventPopup";
import CreateEvent from "./CreateEvent/CreateEvent";
import { useAppSelector } from "../../hooks/useAppSelector";
import { YearlyViewPopup } from "./components/YearlyViewPopup";
import { bookAppointmentAsync, fetchCalendarEventsAsync } from "../shared/slices/asyncThunks";
import { fetchCalendarEvents } from "../shared/slices/api/calendarApi";
import { selectMedStaffPatients } from "../shared/slices/patientsSlice";
import { getPastDates, utcToLocal } from "../../utils/date";
import { VrsCalendarNavigator } from "../../ui/components/VrsCalendarNavigator";
import { VrsMedStaffProfileModel } from "../shared/interfaces";
import SnackbarUtils from "../../utils/SnackbarUtils";

setOptions({
  theme: "material",
  themeVariant: "light",
});

export interface CalendarTimeslotProps {
  date: Date | number;
  start?: Date | number;
  end?: Date;
}
export interface CalendarProps {
  view: AgendaTypes;
  viewType: CalendarShownType;
  calendarEvents: MbscCalendarEvent[];
  calView: MbscEventcalendarView;
  currentUserId: string;
  currentUserProfile?: MedStaffProfileItem | VrsMedStaffProfileModel;
}

const Calendar = ({
  view,
  viewType,
  calendarEvents,
  calView,
  currentUserId,
  currentUserProfile,
}: CalendarProps): JSX.Element => {
  const calendarRef = useRef<Eventcalendar>(null);
  const showModifyAppointment = useRef(true);
  const openCreateEvent = useAppSelector(selectIsCreateEventOpen);
  const openCreateEventWithPatientId = useAppSelector(selectCreateEventOpenWithPatientId);
  const openEventPreview = useAppSelector(selectIsEventPopupOpen);
  const [eventSelected, setEventSelected] = useState<MbscCalendarEvent>();
  const [anchor, setAnchor] = useState<HTMLElement>();
  const [timeSlot, setTimeslot] = useState<CalendarTimeslotProps>();
  const [anchorYearly, setAnchorYearly] = useState<HTMLElement>();
  const [openYearlyPoper, setOpenYearlyPoper] = useState<boolean>(false);
  const [dataYearlyView, setDataYearlyView] = useState<MbscCalendarEventData[]>([]);
  const patients = useAppSelector(selectMedStaffPatients);
  const start = useAppSelector(selectStartDate);
  const end = useAppSelector(selectEndDate);
  const dispatch = useAppDispatch();
  const pastDates = getPastDates();

  const location = useLocation<{ appointment: GetAppointmentResponse }>();

  useEffect(() => {
    const { appointment } = location.state || {};
    if (appointment && calendarRef.current && showModifyAppointment.current) {
      const dateToNavigateTo = dayjs(utcToLocal(appointment.startTime)).toISOString();
      calendarRef.current.navigate(dateToNavigateTo);
      const eventToEdit = calendarRef.current.getEvents().find(({ id }) => id === appointment.id);
      if (eventToEdit) {
        showModifyAppointment.current = false;
        dispatch(setEventPopupVisibility(!openEventPreview));
        setEventSelected(eventToEdit);
        window.history.replaceState({}, document.title);
      }
    }
  }, [location.state, calendarRef.current, calendarEvents]);

  useEffect(() => {
    setOpenYearlyPoper(false);
    if (view === "year") {
      dispatch(resetCalendarEvents());
    }
  }, [view]);

  const onCreateNewEvent = (existingTimeslot?: MbscEventCreateEvent) => {
    const oldEvent = existingTimeslot?.event;
    const startTime = oldEvent && oldEvent.start ? oldEvent.start : null;
    if (startTime && dayjs(startTime as Date).isToday() && view === "month") {
      setTimeslot({
        ...timeSlot,
        date: startTime as Date,
        start: startTime as Date,
        end: oldEvent?.end as Date,
      });
      dispatch(setEventCreationVisibility({ isOpen: true }));
    }
    if (startTime && dayjs().diff(startTime as Date, "minutes") < 45) {
      setTimeslot({
        ...timeSlot,
        date: startTime as Date,
        start: startTime as Date,
        end: oldEvent?.end as Date,
      });
      dispatch(setEventCreationVisibility({ isOpen: true }));
    }
  };

  const onEventCreateFailed = React.useCallback((args) => {
    const invalid = args.invalid && args.invalid.end ? args.invalid.end : null;
    const startTime = args.event && args.event.start ? args.event.start : null;
    if (!invalid || dayjs(invalid).diff(startTime, "minutes") > 45) {
      SnackbarUtils.error("Can't create event in the past");
    }
  }, []);

  const changeCalendarView = (value: AgendaTypes): void => {
    if (viewType === "calendar") {
      dispatch(setCalendarView({ view: value, viewType: "calendar" }));
    } else {
      dispatch(setAgendaView({ view: value, viewType: "agenda" }));
    }
  };

  const onEventClick = (props: MbscCalendarEvent) => {
    dispatch(setEventPopupVisibility(!openEventPreview));
    setEventSelected(props.event);
    setAnchor(props.domEvent.target);
  };

  const onEventClickYearly = (anchorEl: HTMLElement | undefined, event: MbscCalendarEventData) => {
    dispatch(setEventPopupVisibility(!openEventPreview));
    setEventSelected(event);
    setAnchor(anchorEl);
  };

  const changeView = (value: CalendarShownType): void => {
    if (value === "calendar") {
      dispatch(setCalendarView({ view, viewType: value }));
    } else {
      dispatch(setAgendaView({ view, viewType: value }));
    }
  };

  const handleCellClick = (event: MbscCellClickEvent, inst: EventcalendarBase): void => {
    if (["month", "year"].includes(view)) {
      inst.navigate(new Date(event.date));
      changeCalendarView("day");
    }
  };

  const handleCellClickYearly = async (
    event: MbscCellClickEvent,
    inst: EventcalendarBase,
  ): Promise<void> => {
    if (view === "year") {
      inst.setState({
        ...inst.state,
        showPopover: false,
        popoverList: [],
      });
      inst.refresh();

      if (currentUserId) {
        let events = await fetchCalendarEvents(
          +currentUserId,
          event.date.toISOString(),
          dayjs(event.date).add(1, "day").toISOString(),
          [
            AppointmentStatusEnum.Booked,
            AppointmentStatusEnum.Completed,
            AppointmentStatusEnum.Proposed,
          ],
        );
        events = events.map((e) => {
          e.original = { ...e };
          return e;
        });
        if (events.length) {
          setDataYearlyView(JSON.parse(JSON.stringify(events)));
          setAnchorYearly(event.domEvent.target);
          setOpenYearlyPoper(true);
        }
      }
    }
  };

  const loadEventsForPage = (page: MbscPageLoadingEvent) => {
    if (currentUserId && view !== "year") {
      dispatch(
        setCalendarStartAndEndDate({
          start: page.firstDay.toISOString(),
          end: page.lastDay.toISOString(),
        }),
      );
      dispatch(
        fetchCalendarEventsAsync({
          userId: +currentUserId,
          start: page.firstDay.toISOString(),
          end: page.lastDay.toISOString(),
          statuses: [
            AppointmentStatusEnum.Booked,
            AppointmentStatusEnum.Completed,
            AppointmentStatusEnum.Proposed,
          ],
        }),
      );
    }
  };
  const onCreateEventCloseHandler = () => {
    dispatch(setEventCreationVisibility({ isOpen: false }));
    if (currentUserId && view !== "year") {
      dispatch(
        setCalendarStartAndEndDate({
          start,
          end,
        }),
      );
      dispatch(
        fetchCalendarEventsAsync({
          userId: +currentUserId,
          start,
          end,
          statuses: [
            AppointmentStatusEnum.Booked,
            AppointmentStatusEnum.Completed,
            AppointmentStatusEnum.Proposed,
          ],
        }),
      );
    }
  };

  return (
    <>
      <Eventcalendar
        ref={calendarRef}
        invalid={pastDates}
        renderHeader={useCallback(
          () => (
            <VrsCalendarNavigator
              view={view}
              viewType={viewType}
              changeCalendarView={(value: AgendaTypes) => changeCalendarView(value)}
              changeView={(value: CalendarShownType) => changeView(value)}
              createNewEvent={() => {
                if (calendarRef.current?.state.selectedDate) {
                  if (dayjs(calendarRef.current?.state.selectedDate).isBefore(dayjs())) {
                    setTimeslot({
                      date: dayjs().toDate(),
                      start: dayjs().toDate(),
                      end: dayjs().add(1, "hour").toDate(),
                    });
                  } else
                    setTimeslot({
                      date: dayjs(calendarRef.current.state.selectedDate).toDate(),
                      start: dayjs(calendarRef.current.state.selectedDate).toDate(),
                      end: dayjs(calendarRef.current.state.selectedDate).add(1, "hour").toDate(),
                    });
                }
                dispatch(setEventCreationVisibility({ isOpen: true }));
              }}
            />
          ),
          [view, viewType],
        )}
        view={calView}
        data={calendarEvents}
        cssClass="veris-calendar"
        clickToCreate="single"
        onEventCreateFailed={onEventCreateFailed}
        onPageChange={(page) => {
          if (location.state?.appointment) {
            loadEventsForPage(page);
          }
        }}
        onPageLoading={(page) => {
          if (location.state?.appointment) {
            return;
          }
          loadEventsForPage(page);
        }}
        onEventCreate={onCreateNewEvent}
        onEventClick={onEventClick}
        renderScheduleEvent={useCallback(
          (data) => (
            <RenderScheduleEvent data={data} view={view} isCalendar />
          ),
          [view],
        )}
        renderLabel={useCallback(
          (data) => (
            <RenderLabel data={data} />
          ),
          [view],
        )}
        onCellDoubleClick={handleCellClick}
        showEventTooltip={false}
        onCellClick={handleCellClickYearly}
        renderEvent={useCallback(
          (data) => (
            <RenderEvent data={data} viewType={viewType} />
          ),
          [viewType],
        )}
        dateFormatLong="DDD, MMM D YYYY"
        dayNamesShort={[
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ]}
      />
      <EventPopup
        anchor={anchor as HTMLElement}
        open={openEventPreview}
        eventSelected={eventSelected as MbscCalendarEvent}
        currentUserId={currentUserId}
        onClose={() => dispatch(setEventPopupVisibility(false))}
        isCalendar
      />
      {currentUserProfile && openCreateEvent && (
        <CreateEvent
          currentUserProfile={currentUserProfile}
          existingTimeSlot={timeSlot}
          isOpen={openCreateEvent}
          currentUserId={currentUserId}
          patientList={patients}
          patientId={openCreateEventWithPatientId}
          onClose={onCreateEventCloseHandler}
          onEventCreate={({ appointment }) =>
            dispatch(
              bookAppointmentAsync({
                appointment,
                currentDoctorId: +currentUserId,
                start,
                end,
              }),
            )
          }
        />
      )}
      <YearlyViewPopup
        data={dataYearlyView}
        open={openYearlyPoper}
        anchor={anchorYearly}
        closePoper={() => setOpenYearlyPoper(false)}
        viewType={viewType}
        view={view}
        onEventClick={onEventClickYearly}
      />
    </>
  );
};

export default Calendar;
