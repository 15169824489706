import React, { useMemo, useState } from "react";
import {
  ResponsiveContainer,
  Tooltip as RechartsTooltip,
  ReferenceLine,
  YAxis,
  XAxis,
  ComposedChart,
  Line,
  Scatter,
  Cell,
  DotProps,
  Bar,
} from "recharts";
import dayjs from "dayjs";
import "../../../../index.css";
import { Measurement, SensorEnum } from "@veris-health/med-data-ms/lib/v1";
import { verisColors, dateFormats } from "@veris-health/web-core";
import { get } from "lodash";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import {
  BarDataSingleMeasurement,
  BloodPressureNormalRange,
  MioSensorMeasurement,
  ReferenceLine as ReferenceLineType,
  SensorsGraphView,
  selectDateFrom,
  selectDateTo,
  selectSensorsGraphView,
  setReferenceLine,
} from "../../measurementSlice";
import { trendingChartColors } from "../../../../constants";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import {
  ChartTooltipWrapper,
  ChartLabel,
  ChartNormalValueLabel,
  CustomMioTooltip,
} from "../shared/tooltips";
import useSensorDetails from "../../hooks/useSensorDetails";
import {
  calculateDomain,
  getColorForDot,
  getColorForStroke,
  getTooltipDirection,
} from "../shared/utils";
import { useTooltipContext } from "../../context/TooltipContext";

export interface MeasurementGraphProps {
  implantValues: (Measurement | null)[];
  mioValues: MioSensorMeasurement[];
  externalValues: BarDataSingleMeasurement[];
  referenceLine: ReferenceLineType;
  sensorName: SensorEnum;
  normalRange: {
    min: number | BloodPressureNormalRange;
    max: number | BloodPressureNormalRange;
  };
  sensorsGraphView: SensorsGraphView;
  isPreview?: boolean;
  withMeasurementUnit?: string;
  dataKey: string;
  implantKey: string;
  secondaryDataKey?: string;
}

interface CustomDotProps extends DotProps {
  sensorMin: number;
  sensorMax: number;
  payload: { v: number; dt: number | string };
  index: number;
  data: (Measurement | null)[];
  sensorName: string;
}

const CustomDot = (props: CustomDotProps) => {
  const { sensorMin, sensorMax, cx, cy, payload, data, index, sensorName } = props;

  return (
    <>
      {payload && payload.v && ((!data[index - 1] && !data[index + 1]) || data.length === 1) && (
        <circle
          cx={cx}
          cy={cy}
          r={2}
          stroke="none"
          fill={getColorForDot(payload as Measurement, "v", sensorMin, sensorMax, sensorName)}
        />
      )}
    </>
  );
};

export const CombinedGraph = ({
  mioValues,
  implantValues,
  isPreview,
  withMeasurementUnit,
  referenceLine,
  sensorName,
  normalRange,
  dataKey,
  implantKey,
  secondaryDataKey,
  externalValues,
  sensorsGraphView,
}: MeasurementGraphProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const startDate = useAppSelector(selectDateFrom);
  const endDate = useAppSelector(selectDateTo);
  const view = useAppSelector(selectSensorsGraphView);
  const combinedData = [...mioValues, ...implantValues, ...externalValues];
  const clickedIndex = combinedData.findIndex((el) => el?.dt === referenceLine.date);
  const clickedDot = combinedData[clickedIndex];

  const filteredSensorValues = useMemo(() => implantValues.filter((value) => value !== null), []);

  const dateFormatter = (date: number) => {
    return dayjs.unix(date).format(dateFormats["MMMM DD, YYYY"]);
  };

  const sensorDetailsMio = useSensorDetails({
    data: mioValues,
    dataKey,
    normalRange,
    implantKey,
  });
  const sensorDetailsImplant = useSensorDetails({
    data: implantValues,
    dataKey,
    normalRange,
    implantKey,
  });
  const sensorDetails = useSensorDetails({
    data: combinedData,
    dataKey,
    normalRange,
    implantKey,
  });

  const sensorMin = sensorDetails.normalRange.min;
  const sensorMax = sensorDetails.normalRange.max;

  const { openTooltip, closeTooltip } = useTooltipContext();

  const [activeTooltipIndex, setActiveTooltip] = useState<number>();

  const tooltipContent = (e: any) => {
    if (e.payload && e.payload.ldt) {
      const { ldt: timezone, dt, v: value, u: measurementUnit } = e.payload;
      const formattedDt = dayjs.unix(+dt);
      return (
        <CustomMioTooltip
          timezone={timezone}
          datetime={formattedDt}
          value={value}
          measurementUnit={measurementUnit}
          isPreview={isPreview}
          isMotionChart={sensorName === "motion"}
          sensorsGraphView={sensorsGraphView}
        />
      );
    }
    return <></>;
  };

  const getFillForMioChart = () => {
    const key: keyof MioSensorMeasurement = dataKey as keyof MioSensorMeasurement;
    if (mioValues.every((item) => item[key] === mioValues[0][key]))
      return getColorForDot(
        mioValues[0] ? get(mioValues, `[0]`) : undefined,
        key,
        sensorDetailsMio.normalRange.min,
        sensorDetailsMio.normalRange.max,
        sensorName,
        true,
      );
    return `url(#mixed${sensorName})`;
  };

  return (
    <>
      <ResponsiveContainer width="99%" height={110}>
        <ComposedChart
          data={combinedData}
          margin={{
            bottom: 0,
            left: 26,
            right: 0,
            top: 25,
          }}
          onClick={(d1: any) => {
            const dtClicked = d1.activePayload[0].payload.dt;
            if (dtClicked && d1.activePayload[0].payload.data_source === "veris-port") {
              dispatch(
                setReferenceLine({
                  date: dtClicked,
                  valueIndex: dtClicked,
                }),
              );
            }
          }}
          onMouseUp={() => setActiveTooltip(undefined)}
          onMouseLeave={() => closeTooltip()}
        >
          {sensorMin && (
            <ReferenceLine
              y={sensorMin}
              stroke={verisColors.neutrals["grey-2"]}
              isFront
              ifOverflow="extendDomain"
              label={(payload) => {
                return <ChartNormalValueLabel value={sensorMin} viewBox={payload.viewBox} />;
              }}
            />
          )}
          {sensorMax && (
            <ReferenceLine
              y={sensorMax}
              stroke={verisColors.neutrals["grey-2"]}
              ifOverflow="extendDomain"
              isFront
              label={(payload) => (
                <ChartNormalValueLabel value={sensorMax} viewBox={payload.viewBox} />
              )}
            />
          )}
          <XAxis
            axisLine={false}
            dataKey="dt"
            scale="time"
            tickFormatter={dateFormatter}
            type="number"
            domain={[dayjs(startDate).unix(), dayjs(endDate).unix()]}
            allowDataOverflow={false}
            tick={false}
            tickLine={false}
            padding={{ left: 10, right: 10 }}
            hide
          />
          <YAxis
            type="number"
            hide
            domain={calculateDomain(
              sensorName,
              sensorDetails.calculatedRange.max,
              sensorMax,
              sensorMin,
            )}
            padding={{ top: 15, bottom: 10 }}
          />

          <RechartsTooltip
            content={
              <ChartTooltipWrapper
                measurementUnit={withMeasurementUnit}
                isVerisChart
                isPreview={isPreview}
                isMotionChart={sensorName === "motion"}
                showValue
                sensorsGraphView={sensorsGraphView}
              />
            }
            cursor={false}
            filterNull
          />
          {sensorName === "motion" ? (
            <Bar
              barSize={isPreview ? 5 : 10}
              dataKey="sum"
              cursor="pointer"
              fill={trendingChartColors.Good.start}
              onClick={(d1) => {
                dispatch(
                  setReferenceLine({
                    date: d1.dt,
                    valueIndex: clickedIndex,
                  }),
                );
              }}
            />
          ) : (
            <>
              {implantValues && implantValues.length && (
                <Line
                  data={implantValues}
                  dataKey={implantKey}
                  type="monotone"
                  stroke={
                    filteredSensorValues.every(
                      (item) =>
                        implantValues.findIndex((value) => value && value.v === item?.v) === 0,
                    )
                      ? getColorForStroke(
                          sensorDetailsImplant.normalRange.min,
                          sensorDetailsImplant.normalRange.max,
                          implantValues[0] ? implantValues[0].v : 0,
                        )
                      : `url(#${sensorName})`
                  }
                  fill="none"
                  cursor="pointer"
                  strokeWidth={1.8}
                  dot={
                    <CustomDot
                      sensorMin={sensorDetailsImplant.normalRange.min}
                      sensorMax={sensorDetailsImplant.normalRange.max}
                      payload={{
                        v: 0,
                        dt: 0,
                      }}
                      data={implantValues}
                      index={0}
                      sensorName={sensorName}
                    />
                  }
                  isAnimationActive={false}
                />
              )}
              {mioValues && mioValues.length > 0 && (
                <Scatter
                  data={mioValues}
                  dataKey={dataKey}
                  stroke="none"
                  fill={getFillForMioChart()}
                  line
                  animationEasing="ease-in"
                  animationDuration={100}
                  lineJointType="monotoneX"
                  onMouseEnter={(e) => {
                    if (
                      implantValues.length > 0 &&
                      mioValues.length > 0 &&
                      activeTooltipIndex !== e.dt
                    )
                      openTooltip({
                        content: tooltipContent(e),
                        open: true,
                      });
                  }}
                  onMouseLeave={() => closeTooltip()}
                  onMouseOut={() => closeTooltip()}
                  onMouseDown={(d1) => {
                    dispatch(
                      setReferenceLine({
                        date: d1.dt,
                        valueIndex: clickedIndex,
                      }),
                    );
                  }}
                >
                  {mioValues.map((entry) => (
                    <Cell
                      key={entry.dt + sensorName}
                      fill={getColorForDot(
                        entry,
                        dataKey,
                        sensorDetailsMio.normalRange.min || 0,
                        sensorDetailsMio.normalRange.max || 0,
                        sensorName,
                        true,
                      )}
                    />
                  ))}
                </Scatter>
              )}
            </>
          )}
          <defs>
            <linearGradient id={`mixed${sensorName}`} x1="0" y1="0%" x2="0" y2="100%">
              {sensorDetailsMio.abnormalRange.positive > 0 && (
                <>
                  <stop offset={`${0}%`} stopColor={verisColors.mango.dark} />
                  <stop
                    offset={`${Math.abs(sensorDetailsMio.abnormalRange.positive)}%`}
                    stopColor={verisColors.mango.dark}
                  />
                </>
              )}
              {!Number.isNaN(sensorDetailsMio.abnormalRange.positive) && (
                <stop
                  offset={`${Math.abs(sensorDetailsMio.abnormalRange.positive)}%`}
                  stopColor={verisColors.moderate.dark}
                />
              )}
              {!Number.isNaN(sensorDetailsMio.abnormalRange.negative) && (
                <stop
                  offset={`${100 - Math.abs(sensorDetailsMio.abnormalRange.negative)}%`}
                  stopColor={verisColors.moderate.dark}
                />
              )}
              {sensorDetailsMio.abnormalRange.negative > 0 && (
                <>
                  <stop
                    offset={`${100 - Math.abs(sensorDetailsMio.abnormalRange.negative)}%`}
                    stopColor={verisColors.mango.dark}
                  />
                  <stop offset={`${100}%`} stopColor={verisColors.errors.normal} />
                </>
              )}
            </linearGradient>
          </defs>
          <defs>
            <linearGradient id={sensorName} x1="0" y1="0%" x2="0" y2="100%">
              {sensorDetailsImplant.abnormalRange.positive > 0 && (
                <>
                  <stop offset={`${0}%`} stopColor={trendingChartColors.Critical.start} />
                  <stop
                    offset={`${Math.abs(sensorDetailsImplant.abnormalRange.positive)}%`}
                    stopColor={trendingChartColors.Abnormal.start}
                  />
                </>
              )}
              {!Number.isNaN(sensorDetailsImplant.abnormalRange.positive) && (
                <stop
                  offset={`${Math.abs(sensorDetailsImplant.abnormalRange.positive)}%`}
                  stopColor={trendingChartColors.Good.start}
                />
              )}
              {!Number.isNaN(sensorDetailsImplant.abnormalRange.negative) && (
                <stop
                  offset={`${100 - Math.abs(sensorDetailsImplant.abnormalRange.negative)}%`}
                  stopColor={trendingChartColors.Good.start}
                />
              )}
              {sensorDetailsImplant.abnormalRange.negative > 0 && (
                <>
                  <stop
                    offset={`${100 - Math.abs(sensorDetailsImplant.abnormalRange.negative)}%`}
                    stopColor={trendingChartColors.Abnormal.start}
                  />
                  <stop offset={`${100}%`} stopColor={trendingChartColors.Critical.start} />
                </>
              )}
            </linearGradient>
          </defs>
          {clickedDot && (
            <ReferenceLine
              x={referenceLine?.date}
              stroke={verisColors.neutrals["grey-5"]}
              strokeWidth={1}
              isFront
              strokeDasharray="3 3"
              label={(payload) => (
                <ChartLabel
                  value={clickedDot}
                  dataKey={dataKey}
                  secondaryDataKey={secondaryDataKey}
                  dateTime={clickedDot?.dt}
                  measurementUnit={withMeasurementUnit}
                  direction={getTooltipDirection(startDate, endDate, view, referenceLine)}
                  isPreview={isPreview}
                  viewBox={payload.viewBox}
                  isMotionChart={sensorName === "motion"}
                  timezone={clickedDot && get(clickedDot, "ldt", undefined)}
                  sensorsGraphView={sensorsGraphView}
                />
              )}
            />
          )}
        </ComposedChart>
      </ResponsiveContainer>
    </>
  );
};
