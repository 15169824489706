export type BodyPoint = {
  id: number;
  cx: string;
  cy: string;
  name: string;
};
export const anteriorBodyPoints: BodyPoint[] = [
  {
    id: 1,
    cx: "40%",
    cy: "3%",
    name: "face",
  },
  {
    id: 2,
    cx: "20%",
    cy: "15%",
    name: "right shoulder",
  },
  {
    id: 3,
    cx: "60%",
    cy: "15%",
    name: "left shoulder",
  },
  {
    id: 4,
    cx: "40%",
    cy: "22%",
    name: "chest",
  },
  {
    id: 5,
    cx: "10%",
    cy: "33%",
    name: "right arm/elbow",
  },
  {
    id: 6,
    cx: "70%",
    cy: "33%",
    name: "left arm/elbow",
  },
  {
    id: 7,
    cx: "0",
    cy: "47%",
    name: "right hand",
  },
  {
    id: 8,
    cx: "80%",
    cy: "47%",
    name: "left hand",
  },
  {
    id: 9,
    cx: "40%",
    cy: "38%",
    name: "abdomen",
  },
  {
    id: 10,
    cx: "28%",
    cy: "58%",
    name: "right thigh",
  },
  {
    id: 11,
    cx: "52%",
    cy: "58%",
    name: "left thigh",
  },
  {
    id: 12,
    cx: "28%",
    cy: "74%",
    name: "right knee",
  },
  {
    id: 13,
    cx: "52%",
    cy: "74%",
    name: "left knee",
  },
  {
    id: 14,
    cx: "28%",
    cy: "92%",
    name: "right foot",
  },
  {
    id: 15,
    cx: "52%",
    cy: "92%",
    name: "left foot",
  },
];

export const posteriorBodyPoints: BodyPoint[] = [
  {
    id: 16,
    cx: "40.5%",
    cy: "2%",
    name: "head",
  },
  {
    id: 17,
    cx: "20%",
    cy: "15%",
    name: "left posterior shoulder",
  },
  {
    id: 18,
    cx: "60%",
    cy: "15%",
    name: "right posterior shoulder",
  },
  {
    id: 19,
    cx: "40%",
    cy: "28%",
    name: "thoracic spine",
  },
  {
    id: 20,
    cx: "10%",
    cy: "33%",
    name: "left arm/elbow",
  },
  {
    id: 21,
    cx: "70%",
    cy: "33%",
    name: "right arm/elbow",
  },
  {
    id: 22,
    cx: "0%",
    cy: "47%",
    name: "left hand",
  },
  {
    id: 23,
    cx: "80%",
    cy: "47%",
    name: "right hand",
  },
  {
    id: 24,
    cx: "40%",
    cy: "45%",
    name: "lumbosacral spine",
  },
  {
    id: 25,
    cx: "28%",
    cy: "58%",
    name: "left posterior thigh",
  },
  {
    id: 26,
    cx: "52%",
    cy: "58%",
    name: "right posterior thigh",
  },
  {
    id: 27,
    cx: "28%",
    cy: "74%",
    name: "left posterior knee",
  },
  {
    id: 28,
    cx: "52%",
    cy: "74%",
    name: "right posterior knee",
  },
  {
    id: 29,
    cx: "28%",
    cy: "92%",
    name: "left foot",
  },
  {
    id: 30,
    cx: "52%",
    cy: "92%",
    name: "right foot",
  },
];
