import React, { useEffect } from "react";
import { NotificationType, NotificationView } from "@veris-health/communication-ms/lib/v1";
import { useAppSelector } from "../../hooks/useAppSelector";
import { markNotificationAsReadAsync } from "./notificationsSlice";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { selectUserId } from "../shared/slices/authSlice";
import {
  AppointmentNotification,
  OutOfRangeNotification,
  SymptomNotificationsContainer,
  SystemNotification,
} from "./components/VrsNotificationDetails";
import { useNotification } from "./hooks/useNotification";
import { SecretsExpirationReminder } from "./components/VrsNotificationDetails/SecretsExprirationReminder";

export const NotificationsDetailsContainer = (): JSX.Element | null => {
  const activeNotification = useNotification();
  const currentUserId = useAppSelector(selectUserId);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (activeNotification && !activeNotification.read && currentUserId) {
      dispatch(
        markNotificationAsReadAsync({
          userId: Number(currentUserId),
          markAsRead: { notification_ids: [activeNotification.notification_id] },
        }),
      );
    }
  }, [dispatch, activeNotification, currentUserId]);

  if (!activeNotification) {
    return <></>;
  }
  const { payload } = activeNotification as NotificationView;

  switch (payload.data.type) {
    case "release-notes":
      return <SystemNotification title={payload.title} text={payload.text} data={payload.data} />;
    case "out-of-range-patients":
      return (
        <OutOfRangeNotification title={payload.title} text={payload.text} data={payload.data} />
      );
    case "appointment-request":
    case "appointment-confirmation":
    case "appointment-cancellation":
    case "appointment-reminder":
    case "appointment-modification":
      return <AppointmentNotification data={payload.data} />;
    case "secrets-expiration-reminder":
      return <SecretsExpirationReminder />;
    case "symptom-added":
    case "symptom-resolved":
    case "symptom-updated":
      return (
        <SymptomNotificationsContainer
          data={payload.data}
          type={payload.data.type as unknown as NotificationType}
          date={activeNotification.date_created}
        />
      );
    default:
      return null;
  }
};
