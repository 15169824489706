import React from "react";
import { IconName, VrsIconButton, verisColors } from "@veris-health/web-core";

export interface InlineStyleButtonProps {
  active?: boolean;
  disabled?: boolean;
  iconName: IconName;
  inlineStyle: string;
  toggleInlineStyle(inlineStyle: string): void;
}

export const InlineStyleButton = ({
  active,
  disabled,
  inlineStyle,
  iconName,
  toggleInlineStyle,
}: InlineStyleButtonProps): JSX.Element => {
  const onMouseDown = (e: React.MouseEvent) => {
    e.preventDefault();

    toggleInlineStyle(inlineStyle);
  };

  return (
    <VrsIconButton
      disabled={disabled}
      onMouseDown={onMouseDown}
      iconProps={{
        name: iconName,
        color: active ? verisColors.neutrals["grey-dark"] : verisColors.neutrals["grey-mid"],
      }}
    />
  );
};

export interface ClearInlineStyleButtonProps {
  active?: boolean;
  disabled?: boolean;
  iconName: IconName;
  clearInlineStyle(): void;
}

export const ClearInlineStyleButton = ({
  active,
  disabled,
  iconName,
  clearInlineStyle,
}: ClearInlineStyleButtonProps): JSX.Element => {
  const onMouseDown = (e: React.MouseEvent) => {
    e.preventDefault();

    clearInlineStyle();
  };

  return (
    <VrsIconButton
      disabled={disabled}
      onMouseDown={onMouseDown}
      iconProps={{
        name: iconName,
        color: active ? verisColors.neutrals["grey-dark"] : verisColors.neutrals["grey-mid"],
      }}
    />
  );
};
