import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import {
  AppointmentNotificationView,
  CallRecordActivity,
  CallRecordBilling,
  CallRecordResponse,
  CallRecordType,
  CallStatusEnum,
  ListBillingNotificationsResponse,
  SessionEventType,
  UnconfirmedCallInfo,
  VideoCallBillingCodes,
} from "@veris-health/communication-ms/lib/v1";
import { CallEndReason } from "@azure/communication-calling";
import {
  addVideoCallRecord,
  getBillingCodes,
  getUnconfirmedCallDetails,
  getUnconfirmedCallsList,
  setBillingCode,
} from "./api/videoApi";
import { RootState } from "../../../store";
import { Status } from "../interfaces";
import { KEEP_ALIVE_TIMER } from "../../../constants";
import SnackbarUtils from "../../../utils/SnackbarUtils";
import { extractErrorMessage } from "../helpers";

export interface BillCode {
  code: string;
  text: string;
}

interface CallStatus {
  isOnCall: boolean;
  isOnLobby: boolean;
  isWindowed: boolean;
  callEndReason?: CallEndReason;
  callUrl?: {
    patientId: string;
    searchParams?: string;
  };
}

interface VoipState {
  callRecordInfo: {
    callRecord?: CallRecordResponse;
    callPatientName?: string;
    status: Status;
  };
  callList: { data: ListBillingNotificationsResponse; status: Status };
  callDetails: {
    details?: UnconfirmedCallInfo;
    status: Status;
  };
  callSelected?: number;
  callStatus: CallStatus;
  sessionInterval?: NodeJS.Timeout;
  billingCodes: {
    codes: BillCode[];
    status: Status;
  };
}

const initialState: VoipState = {
  callRecordInfo: {
    status: "idle",
  },
  callDetails: {
    status: "idle",
  },
  callList: { data: {} as ListBillingNotificationsResponse, status: "idle" },
  callStatus: {
    isOnLobby: false,
    isWindowed: false,
    isOnCall: false,
  },
  sessionInterval: undefined,
  billingCodes: {
    codes: [],
    status: "idle",
  },
};

export const makeCallRecord = createAsyncThunk<
  CallRecordResponse & { callPatientName: string } & { sessionInterval: NodeJS.Timeout },
  {
    callId: string;
    expectedParticipants: Array<number>;
    callPatientName: string;
    patientId?: number;
    callRecordType: CallRecordType;
    startTime?: string;
    endTime?: string;
  }
>(
  "communication/makeCallRecord",
  async ({
    callId,
    expectedParticipants,
    callPatientName,
    patientId,
    callRecordType,
    startTime,
    endTime,
  }) => {
    const videoCallRecord: CallRecordActivity = {
      call_connection_id: callId,
      patient_id: patientId,
      event_type: SessionEventType.Start,
      timestamp: dayjs().format(),
      expected_participants: expectedParticipants,
      call_record_type: callRecordType,
      appointment_start_time: startTime,
      appointment_end_time: endTime,
    };

    const callRecord = await addVideoCallRecord(videoCallRecord);

    const sessionInterval = setInterval(() => {
      const videoCallRecordKeepAlive = {
        call_id: callRecord.id,
        patient_id: patientId,
        event_type: SessionEventType.KeepAlive,
        timestamp: dayjs().format(),
        expected_participants: expectedParticipants,
        appointment_start_time: startTime,
        appointment_end_time: endTime,
      };

      addVideoCallRecord(videoCallRecordKeepAlive);
    }, KEEP_ALIVE_TIMER);

    return {
      ...callRecord,
      callPatientName,
      sessionInterval,
    };
  },
);

export const finishCallRecord = createAsyncThunk<
  CallRecordResponse | undefined,
  {
    status: CallStatusEnum;
    endTime: string;
    attendees?: string[];
    appointmentStartTime?: string;
    appointmentEndTime?: string;
  }
>(
  "communication/finishCallRecord",
  async (
    { status, endTime, attendees, appointmentStartTime, appointmentEndTime },
    { rejectWithValue, getState },
  ) => {
    try {
      const { sharedVoip, auth } = getState() as RootState;

      if (sharedVoip.sessionInterval) {
        clearInterval(sharedVoip.sessionInterval);
      }

      if (!sharedVoip.callRecordInfo.callRecord || !auth.isLoggedIn) {
        return rejectWithValue("Not allowed");
      }
      const videoCallRecord: CallRecordActivity = {
        call_id: sharedVoip.callRecordInfo.callRecord.id,
        status,
        event_type: SessionEventType.End,
        timestamp: endTime,
        attendees,
        appointment_start_time: appointmentStartTime,
        appointment_end_time: appointmentEndTime,
      };

      const callRecord = await addVideoCallRecord(videoCallRecord);

      return {
        ...callRecord,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getUserUnconfirmedCallsList = createAsyncThunk<
  ListBillingNotificationsResponse,
  { userId: number; offset: number }
>("communication/getUserUnconfirmedCallsList", async ({ userId, offset }, { getState }) => {
  const response = await getUnconfirmedCallsList(userId, offset);
  const { sharedVoip } = getState() as RootState;
  const allNotifications = offset
    ? [
        ...(sharedVoip.callList.data.items as AppointmentNotificationView[]),
        ...(response.items as AppointmentNotificationView[]),
      ]
    : response.items;

  return { ...response, items: allNotifications };
});

export const setCallBillingCode = createAsyncThunk<
  object,
  { billingCode: VideoCallBillingCodes; userId: number; callId: number; dateDischarged?: string },
  { rejectValue: string }
>(
  "communication/setCallBillingCode",
  async ({ billingCode, userId, callId, dateDischarged }, { rejectWithValue, dispatch }) => {
    try {
      const billingObject: CallRecordBilling = {
        billing_code: billingCode,
        discharge_date: dateDischarged,
      };
      const response = await setBillingCode(userId, callId, billingObject);

      dispatch(getUserUnconfirmedCallsList({ userId, offset: 0 }));
      return response;
    } catch (error) {
      const errorMsg = extractErrorMessage(error);
      return rejectWithValue(errorMsg || "Could not add the billing");
    }
  },
);

export const fetchCallBillingCodes = createAsyncThunk(
  "communication/fetchCallBillingCodes",
  async () => {
    const response = await getBillingCodes();

    const entries = Object.entries(response);
    const billValues = entries.map((entry) => {
      return {
        code: entry[0],
        text: entry[1],
      };
    });

    return billValues;
  },
);

export const getCallDetailsInfo = createAsyncThunk<
  UnconfirmedCallInfo,
  { userId: number; callId: number }
>("communication/getCallDetailsInfo", async ({ userId, callId }) => {
  const response = await getUnconfirmedCallDetails(userId, callId);

  return response;
});

export const voipSlice = createSlice({
  name: "communication",
  initialState,
  reducers: {
    resetCallRecord: (state) => {
      state.callRecordInfo.callRecord = undefined;
    },
    setCallStatus: (state, { payload }: PayloadAction<CallStatus>) => {
      state.callStatus = payload;
    },
    setWindowedMode: (state, { payload }: PayloadAction<boolean>) => {
      state.callStatus.isWindowed = payload;
    },
    startCall: (state) => {
      state.callStatus.isOnLobby = false;
      state.callStatus.isOnCall = true;
    },
    setSelectedNotification: (state, { payload }: PayloadAction<number | undefined>) => {
      state.callSelected = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(makeCallRecord.pending, (state) => {
        state.callRecordInfo.status = "loading";
      })
      .addCase(makeCallRecord.fulfilled, (state, action) => {
        state.callRecordInfo.callRecord = action.payload;
        state.callRecordInfo.callPatientName = action.payload.callPatientName;
        state.sessionInterval = action.payload.sessionInterval;
        state.callRecordInfo.status = "idle";
      })
      .addCase(makeCallRecord.rejected, (state) => {
        state.callRecordInfo.status = "failed";
      })
      .addCase(finishCallRecord.pending, (state) => {
        state.callRecordInfo.status = "loading";
      })
      .addCase(finishCallRecord.fulfilled, (state, action) => {
        state.callRecordInfo.callRecord = action.payload;
        state.callRecordInfo.status = "idle";
      })
      .addCase(finishCallRecord.rejected, (state) => {
        state.callRecordInfo.status = "failed";
      })
      .addCase(getCallDetailsInfo.pending, (state) => {
        state.callDetails.status = "loading";
      })
      .addCase(getCallDetailsInfo.fulfilled, (state, action) => {
        state.callDetails.details = action.payload;
        state.callDetails.status = "idle";
      })
      .addCase(getCallDetailsInfo.rejected, (state) => {
        state.callDetails.status = "failed";
      })
      .addCase(getUserUnconfirmedCallsList.pending, (state) => {
        state.callList.status = "loading";
      })
      .addCase(getUserUnconfirmedCallsList.fulfilled, (state, action) => {
        state.callList.data = action.payload;
        state.callList.status = "idle";
      })
      .addCase(getUserUnconfirmedCallsList.rejected, (state) => {
        state.callList.status = "failed";
      })
      .addCase(setCallBillingCode.pending, (state) => {
        state.billingCodes.status = "loading";
      })
      .addCase(setCallBillingCode.fulfilled, (state) => {
        state.billingCodes.status = "idle";
      })
      .addCase(setCallBillingCode.rejected, (state, { payload }) => {
        state.billingCodes.status = "failed";
        SnackbarUtils.error(payload as string);
      })
      .addCase(fetchCallBillingCodes.pending, (state) => {
        state.billingCodes.status = "loading";
      })
      .addCase(fetchCallBillingCodes.rejected, (state) => {
        state.billingCodes.status = "failed";
      })
      .addCase(fetchCallBillingCodes.fulfilled, (state, action) => {
        state.billingCodes.codes = action.payload;
        state.billingCodes.status = "idle";
      });
  },
});

export const {
  resetCallRecord,
  setCallStatus,
  setWindowedMode,
  startCall,
  setSelectedNotification,
} = voipSlice.actions;

// Selectors

export const selectCallRecord = ({ sharedVoip }: RootState): CallRecordResponse | undefined =>
  sharedVoip.callRecordInfo.callRecord;

export const selectCallRecordPatientName = ({ sharedVoip }: RootState): string | undefined =>
  sharedVoip.callRecordInfo.callPatientName;

export const selectCallRecordStatus = ({ sharedVoip }: RootState): string =>
  sharedVoip.callRecordInfo.status;

export const selectCallStatus = ({ sharedVoip }: RootState): CallStatus => sharedVoip.callStatus;

export const selectUnconfirmedCallList = ({
  sharedVoip,
}: RootState): ListBillingNotificationsResponse => sharedVoip.callList.data;

export const selectUnconfirmedCallStatus = ({ sharedVoip }: RootState): Status =>
  sharedVoip.callList.status;

export const selectCallDetails = ({ sharedVoip }: RootState): UnconfirmedCallInfo | undefined =>
  sharedVoip.callDetails.details;

export const selectCallDetailsStatus = ({ sharedVoip }: RootState): Status =>
  sharedVoip.callDetails.status;

export const selectSelectedNotification = ({ sharedVoip }: RootState): number | undefined =>
  sharedVoip.callSelected;

export const selectBillingCodes = ({ sharedVoip }: RootState): BillCode[] =>
  sharedVoip.billingCodes.codes;

export const selectBillingCodesStatus = ({ sharedVoip }: RootState): Status =>
  sharedVoip.billingCodes.status;

export default voipSlice.reducer;
