import React, { useContext, useEffect, useState } from "react";
import { HospitalInfo } from "@veris-health/user-ms/lib/v1";
import * as Sentry from "@sentry/react";
import { isEqual } from "lodash";
import { useAppSelector } from "../hooks/useAppSelector";
import { selectIsLoggedIn, selectUserId } from "../features/shared/slices/authSlice";
import { VrsMedStaffProfileModel } from "../features/shared/interfaces";
import { selectDoctorData } from "../features/DoctorsProfile/doctorsProfileSlice";
import { profileInfoApiV1 } from "../api";
import {
  getRelatedHospitalStaffAsync,
  selectHospitalStaff,
} from "../features/Hospitals/hospitalSlice";
import { useAppDispatch } from "../hooks/useAppDispatch";

export const ProfileContextData = React.createContext<{
  profileInfo?: VrsMedStaffProfileModel;
}>({
  profileInfo: undefined,
});

export const ProfileContext = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const [profileInfo, setProfileInfo] = useState<VrsMedStaffProfileModel>();
  const isLoggedIn = useAppSelector(selectIsLoggedIn);
  const userId = useAppSelector(selectUserId);
  const storeProfileData = useAppSelector(selectDoctorData);
  const medicalStaff = useAppSelector(selectHospitalStaff);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isLoggedIn && userId && !isEqual(storeProfileData, profileInfo)) {
      profileInfoApiV1.getProfileInfo(Number(userId)).then(({ data }) => {
        setSentryContext(userId);
        const formattedData = {
          ...data,
          fullName: `${data.first_name} ${data.last_name}`,
          phoneNumber: data.phone_number,
          emergencyPhoneNumber: data.emergency_phone_number,
        };
        setProfileInfo(formattedData);
      });
      if (!medicalStaff.length) {
        dispatch(getRelatedHospitalStaffAsync(+userId));
      }
    }
  }, [isLoggedIn, userId, storeProfileData]);

  return (
    <ProfileContextData.Provider value={{ profileInfo }}>{children}</ProfileContextData.Provider>
  );
};

export const useProfile = (): VrsMedStaffProfileModel | undefined =>
  useContext(ProfileContextData).profileInfo;

export const useHospitals = (): HospitalInfo[] => {
  const { profileInfo } = useContext(ProfileContextData);
  return profileInfo?.hospitals || [];
};

function setSentryContext(id: string) {
  Sentry.setContext("userId", { id });
}
