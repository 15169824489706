import {
  Avatar,
  Box,
  CircularProgress,
  Grid,
  Menu,
  MenuItem,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import React, { useRef, useState } from "react";
import {
  IconName,
  VrsIconButton,
  VrsDivider,
  VrsButton,
  VrsTooltip,
  verisColors,
} from "@veris-health/web-core";
import { StackedAvatarProps, VrsStackedAvatars } from "../../../../ui/components/VrsStackedAvatars";
import { VrsPatientInfoCard } from "../../../../ui/components/VrsPatientInfoCard";
import { VrsPatientInfo } from "../../../shared/interfaces";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { selectSinglePatientStatus } from "../../../shared/slices/patientsSlice";

export interface VrsChatHeaderProps {
  participantInfo: VrsPatientInfo | undefined;
  publicChat: boolean;
  careTeam: StackedAvatarProps[];
  showInteractions: boolean;
  userId: string;
  onPrivateClick: () => void;
  onPublicClick: () => void;
  onAddCareTeam: () => void;
  onPatientClick: (id: number) => void;
  onAddSupportTeamMember: () => void;
}

const StyledMenu = styled(Menu)(({ theme }) => ({
  "& .MuiPaper-root": {
    backgroundColor: theme.veris.colors.neutrals["grey-1"],
    boxShadow: "none",
    border: `1px solid ${theme.veris.colors.neutrals["grey-2"]}`,
  },
}));

export function VrsChatHeader({
  participantInfo,
  publicChat,
  careTeam,
  showInteractions,
  userId,
  onPrivateClick,
  onPublicClick,
  onAddCareTeam,
  onPatientClick,
  onAddSupportTeamMember,
}: VrsChatHeaderProps): JSX.Element {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState<boolean>(false);
  const patientFetchStatus = useAppSelector(selectSinglePatientStatus);
  const handleClose = () => {
    setOpen(false);
  };
  const isCurrentUserInCareTeam = careTeam.find((member) => +member.id === +userId);
  const disableSwitchMode = (publicChat && careTeam.length < 2) || !isCurrentUserInCareTeam;
  return (
    <Box>
      <Box
        display="flex"
        sx={{
          backgroundColor: verisColors.neutrals.white,
        }}
        alignItems="center"
        p={1}
      >
        <Box display="flex" gap={2}>
          {patientFetchStatus === "loading" && (
            <Box display="flex" justifyContent="center" alignItems="center" width="10vw">
              <CircularProgress />
            </Box>
          )}
          {participantInfo && patientFetchStatus === "idle" && (
            <VrsPatientInfoCard
              patientInfo={participantInfo}
              backgroundColor={verisColors.neutrals.white}
              onClick={onPatientClick}
            />
          )}
          {publicChat && showInteractions && (
            <Stack direction="row" ref={anchorRef} alignItems="center">
              <VrsIconButton
                iconProps={{
                  name: open ? IconName.ActiveDots : IconName.Dots,
                  stroke: participantInfo?.isInactiveOrDeceased
                    ? verisColors.neutrals["grey-3"]
                    : verisColors.amethyst.normal,
                }}
                onClick={() => setOpen(true)}
                disabled={participantInfo?.isInactiveOrDeceased}
              />
              <StyledMenu anchorEl={anchorRef.current} open={open} onClose={handleClose}>
                <MenuItem
                  onClick={() => {
                    if (onAddCareTeam) onAddCareTeam();
                    handleClose();
                  }}
                >
                  <Typography variant="body" color={verisColors.neutrals["grey-4"]}>
                    Add Clinical Care Team
                  </Typography>
                </MenuItem>
                <VrsDivider height="80%" />
                <MenuItem
                  onClick={() => {
                    if (onAddSupportTeamMember) onAddSupportTeamMember();
                    handleClose();
                  }}
                >
                  <Typography variant="body" color={verisColors.neutrals["grey-4"]}>
                    Add Friends and Family Team Member
                  </Typography>
                </MenuItem>
              </StyledMenu>
            </Stack>
          )}
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        <Box display="flex" gap={2}>
          {showInteractions && (
            <VrsTooltip
              bcgcolor={verisColors.neutrals.black}
              title={
                <Box
                  sx={{
                    overflowY: "scroll",
                    maxHeight: "200px",
                    cursor: "default",
                  }}
                  p={0.5}
                >
                  {careTeam.map((avatar) => (
                    <Box key={avatar.id} display="flex" my={0.5}>
                      <Grid item xs={6} mr={1}>
                        <Box display="flex" alignItems="center">
                          <Avatar
                            alt={avatar.name}
                            src={avatar.image}
                            sx={{
                              height: 25,
                              width: 25,
                              marginRight: (theme) => theme.spacing(0.5),
                            }}
                          />
                          <Typography
                            variant="body"
                            color={(theme) => theme.veris.colors.neutrals.white}
                          >
                            {avatar.name}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={1}>
                        &nbsp;•&nbsp;
                      </Grid>
                      <Grid item xs={5}>
                        <Typography
                          variant="body"
                          color={(theme) => theme.veris.colors.neutrals["grey-3"]}
                          sx={{ textTransform: "capitalize" }}
                        >
                          {avatar.profession || "N/A"}
                        </Typography>
                      </Grid>
                    </Box>
                  ))}
                </Box>
              }
            >
              <Box display="flex" sx={{ border: "none" }}>
                <VrsStackedAvatars avatars={careTeam} size={36} maxAvatars={3} />
              </Box>
            </VrsTooltip>
          )}
          <Box display="flex" flexDirection="column">
            {showInteractions && (
              <Typography variant="body2" color={verisColors.neutrals["grey-4"]} pt={1}>
                Patient Clinical Care Team
              </Typography>
            )}
            {showInteractions && (
              <VrsButton
                buttonType="transparent"
                disabled={disableSwitchMode || participantInfo?.isInactiveOrDeceased}
                sx={{
                  textDecoration: "underline",
                  color: (theme) => theme.veris.colors.amethyst.normal,
                  padding: (theme) => theme.spacing(0),
                }}
                onClick={() => {
                  return publicChat
                    ? onPrivateClick && onPrivateClick()
                    : onPublicClick && onPublicClick();
                }}
              >
                {publicChat ? "Go to Private" : "Go to Patient chat"}
              </VrsButton>
            )}
          </Box>
        </Box>
      </Box>
      <VrsDivider height="80%" orientation="horizontal" />
    </Box>
  );
}
