import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { Field, FormikProvider, useFormik } from "formik";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import * as yup from "yup";
import { IconName, verisColors, VrsButton, VrsIconButton } from "@veris-health/web-core";
import { StaffType } from "@veris-health/user-ms/lib/v1";
import { Routes } from "../../routes-config";
import { VrsFormInputField } from "../../ui/components/VrsFormInputField";
import { useAppSelector } from "../../hooks/useAppSelector";
import {
  clearValidationError,
  getTermsAndConditionsAsync,
  registerMedStaffAsync,
  registerMedStaffOtpAsync,
  selectIsLoggedIn,
  selectIsShowPassword,
  selectOtpScreenRegister,
  selectTermsAndConditions,
  selectValidationError,
  setOTPScreenRegister,
  toggleShowPassword,
} from "../shared/slices/authSlice";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { OTPLoginContainer } from "./OTPLoginContainer";
import { StyledActiveNavigationItem } from "../shared/styledComponents";
import { extractErrorMessage, validatePassword } from "../shared/helpers";
import { TermsAndConditions } from "../TermsAndConditions";
import { PhoneFormInputField } from "./PhoneFormInputField";
import { registerProcedureStaff } from "../shared/slices/api/authApi";
import SnackbarUtils from "../../utils/SnackbarUtils";
import { emailValidation } from "../../utils/validations";

const Registration = (): JSX.Element => {
  const passwordError = useAppSelector(selectValidationError);
  const otpScreen = useAppSelector(selectOtpScreenRegister);
  const isLoggedIn = useAppSelector(selectIsLoggedIn);
  const showPassword = useAppSelector(selectIsShowPassword);
  const termsAndConditions = useAppSelector(selectTermsAndConditions);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const history = useHistory();
  const isXsScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("xs"));
  const [openDialog, setOpenDialog] = useState(false);
  const urlFullPhone = (queryString.parse(location.search).phone_number as string)
    ? `+${(queryString.parse(location.search).phone_number as string).replace(/[^0-9]/g, "")}`
    : "";

  const userType = queryString.parse(location.search).profile_type as string;
  const getParsedPhoneNumber = (value: string) => {
    let number = parsePhoneNumberFromString(value);
    if (number?.countryCallingCode === undefined) {
      number = parsePhoneNumberFromString(`+${value}`);
    }
    return number;
  };
  const formattedPhoneNumber =
    urlFullPhone && (getParsedPhoneNumber(urlFullPhone)?.nationalNumber || urlFullPhone);
  const formattedCountryCode =
    urlFullPhone && getParsedPhoneNumber(urlFullPhone)?.countryCallingCode;

  useEffect(() => {
    dispatch(getTermsAndConditionsAsync());
  }, [dispatch]);

  useEffect(() => {
    if (isLoggedIn) {
      if (isXsScreen) {
        history.push(Routes.MOBILE_LANDING_PAGE);
        return;
      }
      history.push(Routes.DASHBOARD);
    }
  }, [isLoggedIn, history, isXsScreen]);

  const initialValues = {
    name: (queryString.parse(location.search).first_name as string) || "",
    surname: (queryString.parse(location.search).last_name as string) || "",
    email: (queryString.parse(location.search).email as string) || "",
    phone: formattedPhoneNumber || "",
    password: "",
    confirmPass: "",
    terms: false,
    token: (queryString.parse(location.search).token as string) || "",
    country: formattedCountryCode || "",
  };

  const validationSchema = yup.object().shape({
    name: yup
      .string()
      .required("First name is required")
      .matches(/^[aA-zZ\s]+$/, "Should contain only letters"),
    surname: yup
      .string()
      .required("Last name is required")
      .matches(/^[aA-zZ\s]+$/, "Should contain only letters"),
    email: emailValidation,
    phone: yup
      .string()
      .required()
      .max(20)
      .when("country", {
        is: (value: string) => value.length >= 2,
        then: yup.string().min(8),
        otherwise: yup.string().min(10),
      })
      .matches(/^[+]?\d+$/, "Should contain only digits"),
    password: yup
      .string()
      .min(8, "Password should be of minimum 8 characters length")
      .max(64, "Password should be of maximum 64 characters length")
      .test(
        "is-valid",
        "The password does not comply with password complexity requirements",
        (value) => {
          if (value) {
            const isValidPassword = validatePassword(value);
            if (isValidPassword) {
              return false;
            }
          }
          return true;
        },
      )
      .required("Password is required"),
    confirmPass: yup
      .string()
      .required("Password confirmation is required")
      .oneOf([yup.ref("password"), null], "Passwords must match"),
    terms: yup.boolean().oneOf([true]),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: ({ name, surname, email, phone, country, token }) => {
      if (userType === StaffType.ProcedureStaff) {
        onRegisterProcedureStaff();
      } else {
        dispatch(
          registerMedStaffAsync({
            first_name: name,
            last_name: surname,
            email,
            phone_number: `+${country}${phone}`,
            email_verification_token: token,
          }),
        );
      }
    },
  });

  const {
    errors,
    handleSubmit,
    handleChange,
    handleBlur,
    isValid,
    dirty,
    validateField,
    touched,
    setFieldValue,
  } = formik;
  const { name, surname, email, phone, country, password, confirmPass, terms, token } =
    formik.values;

  useEffect(() => {
    if (passwordError && (!email || !password)) {
      dispatch(clearValidationError());
    }
  }, [passwordError, dispatch, email, password]);

  useEffect(() => {
    if (showPassword && !password) dispatch(toggleShowPassword());
  }, [showPassword, dispatch, password]);

  function onRegisterProcedureStaff() {
    registerProcedureStaff({
      type: StaffType.ProcedureStaff,
      password,
      email,
      phone_number: `+${country}${phone}`,
      email_verification_token: token,
    })
      .then(() => {
        history.push(Routes.SUCCESSFUL_REGISTRATION);
      })
      .catch((e) => {
        SnackbarUtils.error(extractErrorMessage(e));
      });
  }

  return (
    <>
      {otpScreen ? (
        <Box>
          <Box
            display="flex"
            alignItems="center"
            onClick={() => {
              dispatch(clearValidationError());
              dispatch(setOTPScreenRegister(false));
            }}
            sx={{ cursor: "pointer", position: isXsScreen ? "relative" : "absolute", top: "15%" }}
          >
            <VrsIconButton
              iconProps={{ name: IconName.ArrowLeft, stroke: verisColors.neutrals.black, size: 12 }}
            />
            <Typography
              variant="subtitle2"
              color={(theme) => theme.veris.colors.neutrals["grey-4"]}
            >
              Back
            </Typography>
          </Box>
          <Box mb={5} display="flex">
            <StyledActiveNavigationItem variant="h3" m={2} key="OTP">
              Enter the 4-digit OTP
            </StyledActiveNavigationItem>
          </Box>
          <OTPLoginContainer
            onSubmit={(code) =>
              dispatch(
                registerMedStaffOtpAsync({
                  password,
                  email,
                  phone_number: `+${country}${phone}`,
                  email_verification_token: token,
                  otp: code,
                  terms_and_conditions_id: termsAndConditions?.id,
                }),
              )
            }
            onResendCode={() =>
              dispatch(
                registerMedStaffAsync({
                  first_name: name,
                  last_name: surname,
                  email,
                  phone_number: `+${country}${phone}`,
                  email_verification_token: token,
                }),
              )
            }
          />
        </Box>
      ) : (
        <>
          <FormikProvider value={formik}>
            <form onSubmit={handleSubmit}>
              <Grid item xs={12} container spacing={4}>
                <Grid item xs={12} marginBottom={4}>
                  <Typography
                    m={0}
                    variant="h3"
                    sx={{
                      color: verisColors.amethyst.normal,
                    }}
                  >
                    Register
                  </Typography>
                </Grid>
                <Grid item xs={6} style={{ width: "91.5%" }}>
                  <VrsFormInputField
                    name="name"
                    type="text"
                    label="First Name"
                    value={name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={errors.name || " "}
                    error={Boolean(errors.name)}
                    isValidField={name.length && !errors.name}
                  />
                </Grid>
                <Grid item xs={6} style={{ width: "91.5%" }}>
                  <VrsFormInputField
                    name="surname"
                    type="text"
                    label="Last Name"
                    value={surname}
                    onChange={handleChange}
                    helperText={errors.surname || " "}
                    error={Boolean(errors.surname)}
                    isValidField={surname.length && !errors.surname}
                  />
                </Grid>
                <Grid item xs={6} style={{ width: "91.5%" }}>
                  <VrsFormInputField
                    name="email"
                    label="Email"
                    type="text"
                    value={email}
                    error={Boolean(errors.email)}
                    helperText={errors.email || " "}
                    isValidField={email.length && !errors.email}
                    onChange={() => email}
                  />
                </Grid>
                <Grid item xs={6} style={{ width: "91.5%" }}>
                  <PhoneFormInputField
                    name="phone"
                    label="Phone Number"
                    value={phone}
                    onChange={handleChange}
                    onBlur={() => validateField("phone")}
                    helperText={errors.phone || " "}
                    error={!!errors.phone}
                    isValidField={!!touched.phone && phone.length && !errors.phone}
                    countryCode={country || "1"}
                    onSelected={(value) => setFieldValue("country", value.code.trim())}
                  />
                </Grid>
                <Grid item xs={6} style={{ width: "91.5%" }}>
                  <VrsFormInputField
                    name="password"
                    type={showPassword ? "text" : "password"}
                    label="Password"
                    onFocus={() => passwordError && dispatch(clearValidationError())}
                    value={password}
                    onChange={handleChange}
                    helperText={
                      password
                        ? errors.password || passwordError
                        : "Your password must be at least 8 characters long and include 3 out of 4 of: uppercase, lowercase, number or symbol."
                    }
                    error={Boolean(errors.password) || Boolean(passwordError)}
                    isValidField={password.length && !errors.password && !passwordError}
                    showPassword={showPassword}
                    handleClickShowPassword={() => dispatch(toggleShowPassword())}
                  />
                </Grid>
                <Grid item xs={6} style={{ width: "91.5%" }}>
                  <VrsFormInputField
                    name="confirmPass"
                    type={showPassword ? "text" : "password"}
                    label="Confirm Password"
                    value={confirmPass}
                    onChange={handleChange}
                    helperText={errors.confirmPass || " "}
                    error={Boolean(errors.confirmPass)}
                    isValidField={confirmPass.length && !errors.confirmPass}
                    showPassword={showPassword}
                    handleClickShowPassword={() => dispatch(toggleShowPassword())}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    required
                    sx={{
                      display: "flex",
                      flexDirection: "initial",
                      alignItems: "center",
                      left: (theme) => theme.spacing(-1.75),
                      height: (theme) => theme.spacing(3),
                    }}
                  >
                    <Field
                      name="terms"
                      type="checkbox"
                      size="large"
                      variant="outlined"
                      as={Checkbox}
                      value={terms}
                      onChange={handleChange}
                      checked={terms}
                      style={{ color: verisColors.amethyst.normal }}
                    />
                    <Box height="1rem">
                      <Typography m={0} variant="caption" display="flex" alignItems="center">
                        I agree to the
                        <Typography
                          onClick={() => setOpenDialog(true)}
                          sx={{ cursor: "pointer", textDecoration: "underline" }}
                          variant="caption"
                          mx={1}
                        >
                          Terms and Conditions
                        </Typography>
                      </Typography>
                    </Box>
                  </FormControl>
                </Grid>
                <Grid item xs={12} marginTop={4}>
                  <VrsButton
                    buttonType="primary"
                    disabled={!isValid || !dirty || !!passwordError}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Create Account
                  </VrsButton>
                </Grid>
                <Grid item xs={12}>
                  <Typography marginTop={1} variant="subtitle2">
                    Already have an account?
                    <Button
                      style={{ textTransform: "none", textDecoration: "underline" }}
                      onClick={() => {
                        if (passwordError) dispatch(clearValidationError());
                        history.push(Routes.STAFF_LOGIN);
                      }}
                    >
                      Sign in
                    </Button>
                  </Typography>
                </Grid>
              </Grid>
            </form>
          </FormikProvider>
          {openDialog && <TermsAndConditions open={openDialog} setOpen={setOpenDialog} />}
        </>
      )}
    </>
  );
};

export default Registration;
