import React, { useEffect, useCallback } from "react";
import { styled, Box } from "@mui/material";
import { debounce } from "lodash";
import { VrsTabsContainer } from "../../ui/components/VrsTabsContainer";
import { PatientResultsContainer } from "./containers/PatientResultsContainer";
import { VrsPlainSearch } from "../../ui/components/VrsPlainSearch";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import {
  clearFilteredResults,
  loadFilteredLabResultsAsync,
  selectSearchPhrase,
  setSearchPhrase,
} from "./patientRecordsSlice";
import { useAppSelector } from "../../hooks/useAppSelector";
import { useCurrentPatient } from "../../hooks/useCurrentPatient";

const StyledRecordsContainer = styled(VrsTabsContainer)(({ theme }) => ({
  height: "100%",
  backgroundColor: theme.veris.colors.neutrals.white,
  border: `1px solid ${theme.veris.colors.neutrals["grey-light"]}`,
  marginTop: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  marginBottom: "unset",
}));

const StyledSearchContainer = styled(Box)(({ theme }) => ({
  display: "grid",
  alignItems: "center",
  justifyItems: "flex-end",
  position: "absolute",
  top: "13px",
  right: 0,
  paddingRight: theme.spacing(1.5),
}));

export const PatientRecordsContainer = (): JSX.Element => {
  const searchValue = useAppSelector(selectSearchPhrase);
  const patient = useCurrentPatient();
  const dispatch = useAppDispatch();

  const debouncedFilter = useCallback(
    debounce((args: string) => {
      const removedWhitespace = args.trim();
      if (removedWhitespace.length >= 3 && patient) {
        dispatch(
          loadFilteredLabResultsAsync({ patientId: patient.id, searchQuery: removedWhitespace }),
        );
      } else {
        dispatch(clearFilteredResults());
      }
    }, 500),
    [dispatch, patient],
  );

  const onValueChange = (value: string) => {
    dispatch(setSearchPhrase(value));
    debouncedFilter(value);
  };

  useEffect(() => {
    dispatch(setSearchPhrase(""));
    dispatch(clearFilteredResults());
  }, [patient]);

  return (
    <Box position="relative">
      <StyledRecordsContainer
        withTopMargin
        tabsContainerContent={{
          Documentation: <PatientResultsContainer key="patient-records-results" />,
        }}
      />
      <StyledSearchContainer>
        <VrsPlainSearch onValueChange={onValueChange} value={searchValue} />
      </StyledSearchContainer>
    </Box>
  );
};
