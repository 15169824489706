import { AxiosResponse } from "axios";
import {
  SensorEnum,
  SensorDataBoxViewResponse,
  BoxSizeEnum,
  BaselineSensorDataModel,
  SensorsDataResponse,
  MotionSensorConfigSensorTypeEnum,
  UnifiedSensorDataModel,
  DataSource,
  BPMSensorConfigSensorTypeEnum,
  RPMSensorConfigSensorTypeEnum,
  ECGSensorConfigSensorTypeEnum,
  OximeterSensorConfigSensorTypeEnum,
  TempSensorConfigSensorTypeEnum,
  BloodPressureSensorConfigSensorTypeEnum,
  WeightSensorConfigSensorTypeEnum,
} from "@veris-health/med-data-ms/lib/v1/";
import { QuestionsAnswersModel, SymptomReportsTimeline } from "@veris-health/virtual-doc-ms/lib/v1";
import { sensorDataApiV1, symptomsApiV1 } from "../../../api";

export const fetchUnifiedUserSensorsData = async (
  id: number,
  from: string,
  to: string,
  isDailyView: boolean,
): Promise<UnifiedSensorDataModel> => {
  const response = await sensorDataApiV1.getUnifiedUserSensorData(id, from, to, {
    sensors: [
      { sensor_type: TempSensorConfigSensorTypeEnum.Temp },
      { sensor_type: BPMSensorConfigSensorTypeEnum.Bpm },
      { sensor_type: OximeterSensorConfigSensorTypeEnum.Oximeter },
      { sensor_type: BloodPressureSensorConfigSensorTypeEnum.BloodPressure },
      { sensor_type: WeightSensorConfigSensorTypeEnum.Weight },
      {
        sensor_type: MotionSensorConfigSensorTypeEnum.Motion,
        box_size: isDailyView ? BoxSizeEnum.Hour : BoxSizeEnum.Day,
      },
      { sensor_type: RPMSensorConfigSensorTypeEnum.Rpm },
      { sensor_type: ECGSensorConfigSensorTypeEnum.Ecg },
    ],
  });
  return response.data;
};

export const fetchUserSensorsData = async (
  id: number,
  from: string,
  to: string,
): Promise<AxiosResponse<SensorsDataResponse>> => {
  const response = await sensorDataApiV1.getUserSensorsData(id, from, to, [
    SensorEnum.Bpm,
    SensorEnum.Rpm,
    SensorEnum.Motion,
    SensorEnum.Temp,
  ]);
  return response;
};

export const fetchUserMioSensorsData = async (
  id: number,
  from: string,
  to: string,
): Promise<AxiosResponse<SensorsDataResponse>> => {
  const response = await sensorDataApiV1.getUserSensorsData(
    id,
    from,
    to,
    [
      SensorEnum.Bpm,
      SensorEnum.Oximeter,
      SensorEnum.BloodPressure,
      SensorEnum.Temp,
      SensorEnum.Weight,
    ],
    DataSource.Mio,
  );
  return response;
};

export const fetchUserMioMotionData = async (
  id: number,
  from: string,
  to: string,
  interval: BoxSizeEnum,
): Promise<AxiosResponse<SensorDataBoxViewResponse>> => {
  const response = await sensorDataApiV1.getUserSensorDataView(
    id,
    from,
    to,
    [SensorEnum.Motion],
    DataSource.Mio,
    interval,
  );
  return response;
};

export const fetchUserSymptoms = async (
  userId: string,
  startDate: string,
  endDate: string,
): Promise<AxiosResponse<SymptomReportsTimeline>> => {
  const response = await symptomsApiV1.getUserTimeline(Number(userId), startDate, endDate);
  return response;
};

export const fetchUserSensorsDataExternal = async (
  id: number,
  from: string,
  to: string,
  isDailyView: boolean,
): Promise<AxiosResponse<SensorDataBoxViewResponse>> => {
  const response = await sensorDataApiV1.getUserSensorDataView(
    id,
    from,
    to,
    [SensorEnum.Bpm, SensorEnum.Rpm, SensorEnum.Motion, SensorEnum.Temp],
    DataSource.External,
    isDailyView ? BoxSizeEnum.Hour : BoxSizeEnum.Day,
  );
  return response;
};

export const loadReportAnswersAsync = async (payload: {
  reporterId: string;
  reportId: string;
}): Promise<QuestionsAnswersModel> => {
  const { reporterId, reportId } = payload;
  const reportAnswersResponse = await symptomsApiV1.getReportAnswers(+reporterId, +reportId);
  return reportAnswersResponse.data;
};

export const setUserSensorBaseline = async (
  id: number,
  data: BaselineSensorDataModel[],
): Promise<void> => {
  await sensorDataApiV1.setUserSensorBaseline(id, data);
};
