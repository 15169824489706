import React, { ReactNode } from "react";
import { Box } from "@mui/material";
import VrsLeftSidebarContainer from "./VrsLeftSidebarContainer";
import NavBarLayoutContainer from "./NavBarLayoutContainer";
import { ProfileContext } from "../../context/profile";
import { VideoContainerWindow } from "../VideoCommunicationWindow";

export interface VrsAppContainerProps {
  children: ReactNode;
}

export function VrsAppContainer({ children }: VrsAppContainerProps): JSX.Element {
  return (
    <ProfileContext>
      <VideoContainerWindow />
      <Box
        height="100vh"
        display="flex"
        sx={{
          backgroundColor: ({ veris }) => veris.colors.neutrals["grey-1"],
          overflow: "hidden",
        }}
      >
        <VrsLeftSidebarContainer />
        <Box width="100%" sx={{ overflowX: "auto", overflowY: "hidden" }}>
          <NavBarLayoutContainer />
          <Box height="90%" sx={{ overflowY: "scroll" }} className="AppContainer">
            {children}
          </Box>
        </Box>
      </Box>
    </ProfileContext>
  );
}
