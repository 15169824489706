import { useSnackbar, WithSnackbarProps } from "notistack";
import React from "react";
import { SeverityLevel, VrsAlert } from "@veris-health/web-core";

let useSnackbarRef: WithSnackbarProps;
export const SnackbarUtilsConfigurator = (): null => {
  useSnackbarRef = useSnackbar();

  return null;
};

export default {
  success(msg: string, link?: string, onClick?: () => void): void {
    this.toast(msg, "success", link, onClick);
  },
  warning(msg: string): void {
    this.toast(msg, "warning");
  },
  info(msg: string): void {
    this.toast(msg, "info");
  },
  error(msg: string): void {
    this.toast(msg, "error");
  },
  toast(
    msg: string,
    severity: SeverityLevel = "success",
    link?: string,
    onClick?: () => void,
  ): void {
    useSnackbarRef.enqueueSnackbar(
      <VrsAlert message={msg} severity={severity} link={link} onClick={onClick} />,
    );
  },
};
