import { Box, CircularProgress } from "@mui/material";
import React, { useEffect } from "react";
import { VrsErrorMsg } from "@veris-health/web-core";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { useCurrentPatient } from "../../../hooks/useCurrentPatient";
import { useQuery } from "../../../hooks/useQuery";
import { selectUserId } from "../../shared/slices/authSlice";
import { selectCallStatus } from "../../shared/slices/voipSlice";
import { VideoCallProvider } from "../components/VideoCallProvider";
import { VideoCallEnd } from "../components/VideoCallEnd";
import {
  fetchAppointmentInfo,
  fetchChatPatientInfoConfig,
  fetchVideoConfig,
  selectAppointmentInfo,
  selectAppointmentInfoStatus,
  selectChatUserAccessToken,
  selectPatientChatInfo,
  selectChatUserAccessTokenStatus,
  selectPatientChatInfoStatus,
  resetChatInfo,
} from "../voipSlice";

export interface VideoCallContainerProps {
  callState?: string;
}

export function VideoCallContainer({ callState }: VideoCallContainerProps): JSX.Element | null {
  const dispatch = useAppDispatch();
  const query = useQuery();
  const tokenStatus = useAppSelector(selectChatUserAccessTokenStatus);
  const token = useAppSelector(selectChatUserAccessToken);
  const callStatus = useAppSelector(selectCallStatus);
  const chatPatientInfo = useAppSelector(selectPatientChatInfo);
  const chatPatientInfoStatus = useAppSelector(selectPatientChatInfoStatus);
  const appointmentId = query.get("appointmentId");
  const appointmentInfo = useAppSelector(selectAppointmentInfo);
  const appointmentInfoStatus = useAppSelector(selectAppointmentInfoStatus);
  const userId = useAppSelector(selectUserId);
  const currentPatient = useCurrentPatient();

  useEffect(() => {
    if (userId && !token) {
      dispatch(fetchVideoConfig({ id: Number(userId) }));
    }
  }, []);

  useEffect(() => {
    if (!userId) {
      return;
    }

    if (appointmentId) {
      dispatch(fetchAppointmentInfo({ patientId: Number(userId), appointmentId }));
    } else if (currentPatient) {
      dispatch(fetchChatPatientInfoConfig({ id: currentPatient.id }));
    }
  }, [dispatch, userId, currentPatient]);

  useEffect(
    () => () => {
      dispatch(resetChatInfo());
    },
    [dispatch, resetChatInfo],
  );

  if (callStatus.isWindowed) {
    return null;
  }

  if (chatPatientInfoStatus === "failed") {
    return <VrsErrorMsg size="small" />;
  }

  if (
    tokenStatus === "failed" ||
    !userId ||
    (appointmentId && appointmentInfoStatus === "failed")
  ) {
    return (
      <VrsErrorMsg
        size="small"
        onClick={() => dispatch(fetchVideoConfig({ id: Number(userId) }))}
      />
    );
  }

  if (
    callState === "Disconnecting" ||
    !currentPatient ||
    tokenStatus === "loading" ||
    !token ||
    (appointmentId && !appointmentInfo) ||
    (!appointmentId && !chatPatientInfo)
  ) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" sx={{ mt: 2 }}>
        <CircularProgress />
      </Box>
    );
  }

  if ((!callStatus.isOnCall && !callStatus.isOnLobby) || callState === "Disconnected") {
    return <VideoCallEnd callEndReason={callStatus.callEndReason} />;
  }

  return (
    <VideoCallProvider
      isGroupCall={appointmentId !== null}
      isOnLobby={callStatus.isOnLobby}
      currentPatient={currentPatient}
    />
  );
}
