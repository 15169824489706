import {
  GetDiagnosticReportObsResponse,
  GetDiagnosticReportsResponse,
  GetObservationsToCompareResponse,
} from "@veris-health/med-data-ms/lib/v1";
import { labResultsApiV1 } from "../../../api";
import { stripHtml } from "../../../api/stripHtml";

const labResultsDetailsCache: Map<string, GetDiagnosticReportObsResponse> = new Map();

export const fetchLabResultsDetailedList = async (
  patientId: number,
  reportId: string,
): Promise<GetDiagnosticReportObsResponse> => {
  let data = labResultsDetailsCache.get(reportId);
  if (data) {
    return Promise.resolve(data);
  }
  const response = await labResultsApiV1.getDiagnosticReportDetails(patientId, reportId);
  data = stripHtml(response.data) as GetDiagnosticReportObsResponse;
  labResultsDetailsCache.set(reportId, data as GetDiagnosticReportObsResponse);
  return data;
};

export const fetchDiagnosticReportsList = async (
  patientId: number,
  searchPhrase?: string,
): Promise<GetDiagnosticReportsResponse> => {
  const response = await labResultsApiV1.getDiagnosticReports(patientId, searchPhrase);
  const data = stripHtml(response.data) as GetDiagnosticReportsResponse;
  return data;
};

const labResultsComparisonCache: Map<string, GetObservationsToCompareResponse> = new Map();

export const fetchLabResultsComparisonData = async (
  patientId: number,
  date: string,
  codes: string[],
  numberOfObservations?: number,
): Promise<GetObservationsToCompareResponse> => {
  let data = labResultsComparisonCache.get(`${codes[0]}-${numberOfObservations}`);
  if (data) {
    return Promise.resolve(data);
  }
  const response = await labResultsApiV1.compareObservations(
    patientId,
    date,
    codes,
    numberOfObservations,
  );
  data = stripHtml(response.data) as GetObservationsToCompareResponse;
  labResultsComparisonCache.set(`${codes[0]}-${numberOfObservations}`, data);
  return data;
};
