import { Box, CircularProgress, Theme, useMediaQuery } from "@mui/material";
import React, { useEffect } from "react";
import dayjs from "dayjs";
import { MedStaffProfileItem } from "@veris-health/user-ms/lib/v1";
import { AppointmentStatusEnum } from "@veris-health/med-data-ms/lib/v1";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import Calendar from "./Calendar";
import {
  selectCalendarConfigDetails,
  selectCalendarEvents,
  selectCalendarView,
  selectCalendarViewType,
  selectTodaysEvents,
  selectTodaysEventsState,
  setSelectedMedicalStaffMember,
  selectActiveMedicalStaffMember,
  selectEndDate,
  selectStartDate,
  resetView,
} from "./calendarSlice";
import UpcomingAppointments from "./UpcomingAppointments";
import { selectUserId } from "../shared/slices/authSlice";
import {
  getRelatedHospitalStaffAsync,
  selectedRelatedStaffByHospital,
  selectHospitalStatus,
} from "../Hospitals/hospitalSlice";
import { DoctorPicker } from "./components/DoctorPicker";
import { useProfile } from "../../context/profile";
import { VrsMedStaffProfileModel } from "../shared/interfaces";
import { getMedStaffPatientsAsync } from "../shared/slices/patientsSlice";
import { fetchCalendarEventsAsync } from "../shared/slices/asyncThunks";

const CalendarContainer = (): JSX.Element => {
  const calendarView = useAppSelector(selectCalendarView);
  const calendarViewType = useAppSelector(selectCalendarViewType);
  const calendarConfigView = useAppSelector(selectCalendarConfigDetails);
  const currentUserId = useAppSelector(selectUserId);
  const eventStatus = useAppSelector(selectTodaysEventsState);
  const relatedStaff = useAppSelector(selectedRelatedStaffByHospital);
  const selectedMedicalStaffMember = useAppSelector(selectActiveMedicalStaffMember);
  const todaysAppointments = useAppSelector((state) =>
    selectTodaysEvents(
      state,
      selectedMedicalStaffMember ? selectedMedicalStaffMember?.id : Number(currentUserId),
    ),
  );
  const calendarEvents = useAppSelector((state) =>
    selectCalendarEvents(
      state,
      selectedMedicalStaffMember ? selectedMedicalStaffMember?.id : Number(currentUserId),
    ),
  );
  const hospitalStatus = useAppSelector(selectHospitalStatus);
  const start = useAppSelector(selectStartDate);
  const end = useAppSelector(selectEndDate);
  const currentUserData = useProfile();
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("xl"));
  const dispatch = useAppDispatch();

  const mappedCalendarEvents = JSON.parse(JSON.stringify(calendarEvents));

  useEffect(() => {
    if (selectedMedicalStaffMember) {
      dispatch(getMedStaffPatientsAsync(selectedMedicalStaffMember?.id));
    } else if (currentUserId) {
      dispatch(getMedStaffPatientsAsync(+currentUserId));
    }
  }, [selectedMedicalStaffMember, currentUserId, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(resetView());
    };
  }, []);

  const getTargetUser = (value: string) => {
    let targetUser: MedStaffProfileItem | VrsMedStaffProfileModel | undefined = relatedStaff.find(
      (member) => member.id === Number(value),
    );
    const isCurrentUser = !targetUser && value === currentUserId;

    if (isCurrentUser) {
      targetUser = currentUserData;
    }
    return targetUser;
  };

  const handleTargetUserChange = (value: string) => {
    const foundTargetUser = getTargetUser(value);

    if (foundTargetUser) {
      dispatch(setSelectedMedicalStaffMember(foundTargetUser));
      if (!dayjs(start).isToday())
        dispatch(
          fetchCalendarEventsAsync({
            userId: Number(value),
            start: dayjs().startOf("day").toISOString(),
            end: dayjs().startOf("day").add(1, "day").toISOString(),
            statuses: [
              AppointmentStatusEnum.Booked,
              AppointmentStatusEnum.Completed,
              AppointmentStatusEnum.Proposed,
            ],
          }),
        );
      dispatch(
        fetchCalendarEventsAsync({
          userId: Number(value),
          start,
          end,
          statuses: [
            AppointmentStatusEnum.Booked,
            AppointmentStatusEnum.Completed,
            AppointmentStatusEnum.Proposed,
          ],
        }),
      );
    }
  };

  return (
    <>
      {currentUserId && (
        <DoctorPicker
          medStaff={relatedStaff}
          onSelect={handleTargetUserChange}
          status={hospitalStatus}
          selectedUser={selectedMedicalStaffMember}
          onRetry={() => dispatch(getRelatedHospitalStaffAsync(+currentUserId))}
        />
      )}
      <Box p={2}>
        <UpcomingAppointments
          todaysAppointments={todaysAppointments}
          eventStatus={eventStatus}
          onRetry={() => {
            if (currentUserId)
              dispatch(
                fetchCalendarEventsAsync({
                  userId: selectedMedicalStaffMember
                    ? selectedMedicalStaffMember.id
                    : +currentUserId,
                  start: dayjs().startOf("day").toISOString(),
                  end: dayjs().startOf("day").add(1, "day").toISOString(),
                  statuses: [
                    AppointmentStatusEnum.Booked,
                    AppointmentStatusEnum.Completed,
                    AppointmentStatusEnum.Proposed,
                  ],
                }),
              );
          }}
        />
      </Box>
      <Box
        sx={{
          backgroundColor: (theme) => theme.veris.colors.neutrals.white,
          border: (theme) => `1px solid ${theme.veris.colors.neutrals["grey-2"]}`,
          borderRadius: "8px",
        }}
        mx={2}
      >
        <Box
          padding={(theme) => theme.spacing(0, 1, 1, 1)}
          sx={{ maxHeight: isSmallScreen ? "55vh" : "60vh" }}
        >
          {currentUserId ? (
            <Calendar
              calView={calendarConfigView}
              view={calendarView}
              viewType={calendarViewType}
              calendarEvents={mappedCalendarEvents}
              currentUserId={
                selectedMedicalStaffMember
                  ? selectedMedicalStaffMember.id.toString()
                  : currentUserId
              }
              currentUserProfile={selectedMedicalStaffMember || currentUserData}
            />
          ) : (
            <CircularProgress />
          )}
        </Box>
      </Box>
    </>
  );
};

export default CalendarContainer;
