import { GetDiagnosticReportObsResponse } from "@veris-health/med-data-ms/lib/v1";
import { fetchRecentLabResults } from "../patientDetailsSidebarApi";

export const fetchRecentLabs = async (id: number): Promise<GetDiagnosticReportObsResponse> => {
  const response = await fetchRecentLabResults(id);
  const mappedObservations = response.observations.map((observation) => {
    if (observation.value && !Number.isNaN(Number(observation.value))) {
      return {
        ...observation,
        value: Number(observation.value).toFixed(1),
      };
    }
    return observation;
  });
  return { ...response, observations: mappedObservations } as GetDiagnosticReportObsResponse;
};
