import { SensorEnum } from "@veris-health/med-data-ms/lib/v1";
import dayjs from "dayjs";
import React from "react";
import {
  ResponsiveContainer,
  Scatter,
  Tooltip,
  XAxis,
  ReferenceLine,
  YAxis,
  Cell,
  ComposedChart,
  Bar,
} from "recharts";
import { verisColors, dateFormats } from "@veris-health/web-core";
import { get } from "lodash";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import useSensorDetails from "../../hooks/useSensorDetails";
import {
  BloodPressureNormalRange,
  MioSensorMeasurement,
  ReferenceLine as ReferenceLineType,
  SensorDataKeys,
  SensorsGraphView,
  selectDateFrom,
  selectDateTo,
  selectSensorsGraphView,
  setReferenceLine,
} from "../../measurementSlice";
import { ChartLabel, ChartNormalValueLabel, ChartTooltipWrapper } from "../shared/tooltips";
import { trendingChartColors } from "../../../../constants";
import {
  calculateDomain,
  getColorForDot,
  getColorForStroke,
  getTooltipDirection,
} from "../shared/utils";

export interface MioGraphProps {
  data: MioSensorMeasurement[];
  dataKey: string;
  referenceLine: ReferenceLineType;
  sensorName: SensorEnum;
  normalRange: {
    min: number | BloodPressureNormalRange;
    max: number | BloodPressureNormalRange;
  };
  sensorsGraphView: SensorsGraphView;
  withMeasurementUnit?: string;
  isPreview?: boolean;
  secondaryDataKey?: string;
}

const MioGraph = ({
  data,
  dataKey,
  withMeasurementUnit,
  isPreview,
  referenceLine,
  sensorName,
  secondaryDataKey,
  normalRange,
  sensorsGraphView,
}: MioGraphProps): JSX.Element => {
  const startDate = useAppSelector(selectDateFrom);
  const endDate = useAppSelector(selectDateTo);
  const view = useAppSelector(selectSensorsGraphView);
  const dispatch = useAppDispatch();
  const dateFormatter = (date: number) => {
    return dayjs.unix(date).format(dateFormats["ddd MMM D"]);
  };
  const valueKey = SensorDataKeys[sensorName] as keyof MioSensorMeasurement;

  const clickedIndex = data.findIndex((el) => el.dt === referenceLine.date);
  const clickedMeasurement = data[clickedIndex];
  const sensorDetails = useSensorDetails({
    data,
    dataKey,
    normalRange,
  });
  const diastolicSensorDetails = useSensorDetails({
    data,
    dataKey: secondaryDataKey,
    normalRange,
  });

  const sensorMin = secondaryDataKey
    ? diastolicSensorDetails.normalRange.min
    : sensorDetails.normalRange.min;
  const sensorMax = sensorDetails.normalRange.max;

  const getFillForChart = (secondChart?: boolean) => {
    if (sensorName === "weight") return verisColors.moderate.normal;

    const key: keyof MioSensorMeasurement = secondChart
      ? (secondaryDataKey as keyof MioSensorMeasurement)
      : valueKey;
    if (data.every((item) => item[key] === data[0][key]))
      return getColorForStroke(
        secondChart ? diastolicSensorDetails.normalRange.min : sensorDetails.normalRange.min,
        secondChart ? diastolicSensorDetails.normalRange.max : sensorDetails.normalRange.max,
        data[0] ? get(data, `[0][${key}]`) : 0,
      );
    return secondChart ? `url(#${sensorName}dia)` : `url(#${sensorName}mio)`;
  };

  return (
    <ResponsiveContainer width="99%" height={110}>
      <ComposedChart
        data={data}
        margin={{
          bottom: 0,
          left: 26,
          right: 0,
          top: 25,
        }}
      >
        {sensorMin && (
          <ReferenceLine
            y={sensorMin}
            stroke={verisColors.neutrals["grey-2"]}
            isFront
            ifOverflow="extendDomain"
            label={(payload) => {
              return <ChartNormalValueLabel value={sensorMin} viewBox={payload.viewBox} />;
            }}
          />
        )}
        {sensorMax && (
          <ReferenceLine
            y={sensorMax}
            stroke={verisColors.neutrals["grey-2"]}
            ifOverflow="extendDomain"
            isFront
            label={(payload) => (
              <ChartNormalValueLabel value={sensorMax} viewBox={payload.viewBox} />
            )}
          />
        )}
        {secondaryDataKey && (
          <>
            <ReferenceLine
              y={sensorDetails.normalRange.min}
              stroke={verisColors.neutrals["grey-2"]}
              ifOverflow="extendDomain"
              isFront
              label={(payload) => (
                <ChartNormalValueLabel
                  value={sensorDetails.normalRange.min}
                  viewBox={payload.viewBox}
                />
              )}
            />
            <ReferenceLine
              y={diastolicSensorDetails.normalRange.max}
              stroke={verisColors.neutrals["grey-2"]}
              ifOverflow="extendDomain"
              isFront
              label={(payload) => (
                <ChartNormalValueLabel
                  value={diastolicSensorDetails.normalRange.max}
                  viewBox={payload.viewBox}
                />
              )}
            />
          </>
        )}
        <Tooltip
          content={
            <ChartTooltipWrapper
              isMioChart
              measurementUnit={withMeasurementUnit}
              isPreview={isPreview}
              lineValue={data[clickedIndex]?.dt}
              isMotionChart={sensorName === "motion"}
              showValue
              sensorsGraphView={sensorsGraphView}
            />
          }
          cursor={false}
          filterNull
        />
        <XAxis
          dataKey="dt"
          scale="time"
          tickFormatter={dateFormatter}
          type="number"
          domain={[dayjs(startDate).unix(), dayjs(endDate).unix()]}
          allowDataOverflow={false}
          padding={{ left: 10, right: 10 }}
          hide
        />
        <YAxis
          type="number"
          hide
          domain={calculateDomain(
            sensorName,
            sensorDetails.calculatedRange.max,
            sensorMax,
            sensorMin,
          )}
          padding={{ top: 10, bottom: 10 }}
        />
        {sensorName === "motion" ? (
          <Bar
            barSize={isPreview ? 5 : 10}
            dataKey="sum"
            cursor="pointer"
            fill={trendingChartColors.Good.start}
            onClick={(d1) => {
              dispatch(
                setReferenceLine({
                  date: d1.dt,
                  valueIndex: clickedIndex,
                }),
              );
            }}
          />
        ) : (
          <Scatter
            dataKey={dataKey}
            onClick={(d1) => {
              dispatch(
                setReferenceLine({
                  date: d1.dt,
                  valueIndex: clickedIndex,
                }),
              );
            }}
            stroke="none"
            fill={getFillForChart()}
            line
            animationEasing="ease-in"
            animationDuration={100}
            lineJointType="monotoneX"
          >
            {data.map((entry) => (
              <Cell
                key={entry.dt + sensorName}
                fill={getColorForDot(
                  entry,
                  dataKey,
                  sensorDetails.normalRange.min || 0,
                  sensorDetails.normalRange.max || 0,
                  sensorName,
                )}
              />
            ))}
          </Scatter>
        )}
        {secondaryDataKey && (
          <Scatter
            dataKey={secondaryDataKey}
            onClick={(d1) => {
              dispatch(
                setReferenceLine({
                  date: d1.dt,
                  valueIndex: clickedIndex,
                }),
              );
            }}
            stroke="none"
            fill={getFillForChart(true)}
            line
            lineJointType="monotoneX"
            animationEasing="ease-in"
            animationDuration={100}
          >
            {data.map((entry) => (
              <Cell
                key={entry.dt + sensorName}
                fill={getColorForDot(
                  entry,
                  secondaryDataKey,
                  diastolicSensorDetails.normalRange.min || 0,
                  diastolicSensorDetails.normalRange.max || 0,
                  sensorName,
                )}
              />
            ))}
          </Scatter>
        )}
        <defs>
          <linearGradient id={`${sensorName}mio`} x1="0" y1="0%" x2="0" y2="100%">
            {sensorDetails.abnormalRange.positive > 0 && (
              <>
                <stop offset={`${0}%`} stopColor={trendingChartColors.Critical.start} />
                <stop
                  offset={`${sensorDetails?.abnormalRange.positive}%`}
                  stopColor={trendingChartColors.Abnormal.start}
                />
                <stop
                  offset={`${sensorDetails.abnormalRange.positive}%`}
                  stopColor={trendingChartColors.Good.start}
                />
              </>
            )}
            {!Number.isNaN(sensorDetails.abnormalRange.negative) && (
              <stop
                offset={`${100 - Math.abs(sensorDetails.abnormalRange.negative)}%`}
                stopColor={trendingChartColors.Good.start}
              />
            )}
            {sensorDetails.abnormalRange.negative > 0 && (
              <>
                <stop
                  offset={`${100 - sensorDetails.abnormalRange.negative}%`}
                  stopColor={trendingChartColors.Abnormal.start}
                />
                <stop offset={`${100}%`} stopColor={trendingChartColors.Critical.start} />
              </>
            )}
          </linearGradient>
        </defs>
        {secondaryDataKey && (
          <defs>
            <linearGradient id={`${sensorName}dia`} x1="0" y1="0%" x2="0" y2="100%">
              {diastolicSensorDetails.abnormalRange.positive > 0 && (
                <>
                  <stop offset={`${0}%`} stopColor={trendingChartColors.Critical.start} />
                  <stop
                    offset={`${diastolicSensorDetails.abnormalRange.positive}%`}
                    stopColor={trendingChartColors.Abnormal.start}
                  />
                  <stop
                    offset={`${diastolicSensorDetails.abnormalRange.positive}%`}
                    stopColor={trendingChartColors.Good.start}
                  />
                </>
              )}
              {!Number.isNaN(diastolicSensorDetails.abnormalRange.negative) && (
                <stop
                  offset={`${100 - Math.abs(diastolicSensorDetails.abnormalRange.negative)}%`}
                  stopColor={trendingChartColors.Good.start}
                />
              )}
              {diastolicSensorDetails?.abnormalRange.negative > 0 && (
                <>
                  <stop
                    offset={`${100 - diastolicSensorDetails?.abnormalRange.negative}%`}
                    stopColor={trendingChartColors.Abnormal.start}
                  />
                  <stop offset={`${100}%`} stopColor={trendingChartColors.Critical.start} />
                </>
              )}
            </linearGradient>
          </defs>
        )}

        {referenceLine && clickedMeasurement && (
          <ReferenceLine
            x={referenceLine?.date}
            stroke={verisColors.neutrals["grey-5"]}
            strokeWidth={1}
            isFront
            strokeDasharray="3 3"
            label={(payload) => (
              <ChartLabel
                value={clickedMeasurement}
                dataKey={dataKey}
                secondaryDataKey={secondaryDataKey}
                dateTime={clickedMeasurement && clickedMeasurement.dt}
                measurementUnit={withMeasurementUnit}
                isPreview={isPreview}
                direction={getTooltipDirection(startDate, endDate, view, referenceLine)}
                viewBox={payload.viewBox}
                isMotionChart={sensorName === "motion"}
                timezone={clickedMeasurement && clickedMeasurement.ldt}
                sensorsGraphView={sensorsGraphView}
              />
            )}
          />
        )}
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default MioGraph;
