import { Table, TableContainer, Typography } from "@mui/material";
import { ConditionResponse } from "@veris-health/med-data-ms/lib/v1";
import dayjs from "dayjs";
import React from "react";
import { verisColors, dateFormats } from "@veris-health/web-core";
import { EMPTY_DATASET_MESSAGE } from "../../../../constants";
import {
  StyledTableBody,
  StyledTableCell,
  StyledTableRow,
} from "../../../../ui/components/Tables/shared/StyledTableElements";
import {
  Column,
  TableColumnNames,
  VrsSortedTableHeader,
} from "../../../../ui/components/VrsSortedTableHeader";
import { genericSorter } from "../../../../utils/sorting";

const columns: Column[] = [
  { id: TableColumnNames.Problem, label: TableColumnNames.Problem, orderBy: "condition" },
  {
    id: TableColumnNames.ICD,
    label: TableColumnNames.ICD,
    orderBy: "icd10Reference",
  },
  { id: TableColumnNames.AllStatus, label: TableColumnNames.AllStatus, orderBy: "clinicalStatus" },
  {
    id: TableColumnNames.ActiveSince,
    label: TableColumnNames.ActiveSince,
    orderBy: "diagnosisDateTime",
  },
];

function ConditionCell({ condition, column }: { condition: ConditionResponse; column: Column }) {
  return (
    <StyledTableCell key={column.id}>
      {column.id === TableColumnNames.Problem && (
        <Typography variant="subtitle1" color={verisColors.neutrals["grey-4"]} pt={2} pb={2}>
          {condition.condition}
        </Typography>
      )}
      {column.id === TableColumnNames.ICD && (
        <Typography variant="subtitle1" color={verisColors.neutrals["grey-4"]} pt={2} pb={2}>
          {condition.icd10Reference || "N/A"}
        </Typography>
      )}
      {column.id === TableColumnNames.AllStatus && (
        <Typography
          variant="button"
          component="div"
          pt={2}
          pb={2}
          sx={{
            textTransform: "capitalize",
            backgroundColor: (theme) => theme.veris.colors.amethyst.light,
            padding: (theme) => theme.spacing(0.625, 0.75),
            width: "4.125rem",
            display: "flex",
            justifyContent: "center",
            color: (theme) => theme.veris.colors.neutrals["grey-4"],
            borderRadius: (theme) => +theme.shape.borderRadius * 0.25,
          }}
        >
          {condition.clinicalStatus || "N/A"}
        </Typography>
      )}
      {column.id === TableColumnNames.ActiveSince && (
        <Typography variant="button" component="span" sx={{ textTransform: "unset" }} pt={2} pb={2}>
          {condition.diagnosisDateTime
            ? dayjs(condition.diagnosisDateTime).format(dateFormats["MMM D, YYYY"])
            : "N/A"}
        </Typography>
      )}
    </StyledTableCell>
  );
}
export interface PatientActiveProblemsTableProps {
  conditions: ConditionResponse[];
}

export function PatientActiveProblemsTable({
  conditions,
}: PatientActiveProblemsTableProps): JSX.Element {
  const [sortedConditions, setSortedConditions] = React.useState(conditions);

  React.useEffect(() => {
    setSortedConditions(conditions);
  }, [conditions]);

  return (
    <TableContainer
      sx={{
        height: "330px",
        width: "655px",
        border: (theme) => `2px solid ${theme.veris.colors.neutrals["grey-2"]}`,
        padding: (theme) => theme.spacing(2),
      }}
    >
      <Table>
        <VrsSortedTableHeader
          columns={columns}
          sortTableData={(order, orderBy) =>
            setSortedConditions(
              genericSorter(conditions, order, orderBy as keyof ConditionResponse),
            )
          }
        />
        <StyledTableBody sx={{ maxWidth: "330px", overflow: "scroll" }}>
          {conditions.length === 0 && (
            <Typography variant="subtitle1" color={verisColors.neutrals["grey-4"]} pt={2} pb={2}>
              {EMPTY_DATASET_MESSAGE}
            </Typography>
          )}
          {sortedConditions.map((condition: ConditionResponse) => (
            <StyledTableRow key={condition.id}>
              {columns.map((column) => {
                return <ConditionCell column={column} condition={condition} key={column.id} />;
              })}
            </StyledTableRow>
          ))}
        </StyledTableBody>
      </Table>
    </TableContainer>
  );
}
