import { Box, Dialog, DialogActions, Typography, useTheme } from "@mui/material";
import React from "react";
import {
  verisColors,
  VrsIconButton,
  IconName,
  VrsButton,
  dateFormats,
} from "@veris-health/web-core";
import dayjs from "dayjs";
import { Wrapper } from "./ActivityItem";

interface ConfirmDeletionProps {
  open: boolean;
  onClose: () => void;
  duration: number;
  dateOccurred: string;
  dateEntered: string;
  staffName: string;
  activities: string[];
  onDelete: () => void;
}

export const ConfirmDeletion = ({
  open,
  onClose,
  duration,
  dateEntered,
  dateOccurred,
  staffName,
  activities,
  onDelete,
}: ConfirmDeletionProps): JSX.Element => {
  const theme = useTheme();
  return (
    <Dialog open={open} onClose={onClose}>
      <Box sx={{ padding: theme.spacing(3), minWidth: "560px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: theme.spacing(2),
            borderBottom: `1px solid ${theme.veris.colors.neutrals["grey-light"]}`,
          }}
        >
          <Typography variant="h3" color={verisColors.neutrals["grey-4"]}>
            Delete Manual Added Billing
          </Typography>
          <VrsIconButton onClick={onClose} iconProps={{ name: IconName.CloseIcon }} />
        </Box>
        <Box
          sx={{
            marginTop: theme.spacing(3),
            paddingBottom: theme.spacing(3.5),
            borderBottom: `1px solid ${theme.veris.colors.neutrals["grey-light"]}`,
          }}
        >
          <Typography variant="subtitle2">
            Are you sure you want to delete the following information?
          </Typography>
          <Box sx={{ display: "flex", gap: theme.spacing(1), marginY: theme.spacing(3) }}>
            <Typography variant="subtitle2" color={theme.veris.colors.neutrals["grey-mid"]}>
              Duration (in minutes):
            </Typography>
            <Typography variant="subtitle2">{duration} min</Typography>
          </Box>
          <Box sx={{ display: "flex", gap: theme.spacing(1) }}>
            <Typography variant="subtitle2" color={theme.veris.colors.neutrals["grey-mid"]}>
              Date occurred:
            </Typography>
            <Typography variant="subtitle2">
              {dayjs(dateOccurred).format(dateFormats["ddd MMM D, YYYY"])}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", gap: theme.spacing(1), marginY: theme.spacing(3) }}>
            <Typography variant="subtitle2" color={theme.veris.colors.neutrals["grey-mid"]}>
              Date entered:
            </Typography>
            <Typography variant="subtitle2">
              {dayjs(dateEntered).format(dateFormats["ddd MMM D, YYYY"])}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", gap: theme.spacing(1) }}>
            <Typography variant="subtitle2" color={theme.veris.colors.neutrals["grey-mid"]}>
              Entered by:
            </Typography>
            <Typography variant="subtitle2">{staffName}</Typography>
          </Box>
          <Typography
            variant="subtitle2"
            color={theme.veris.colors.neutrals["grey-mid"]}
            marginY={theme.spacing(3)}
          >
            Activities:
          </Typography>
          {activities.map((activity) => (
            <Wrapper key={activity} sx={{ marginBottom: theme.spacing(1.5), width: "fit-content" }}>
              {activity}
            </Wrapper>
          ))}
        </Box>
        <DialogActions sx={{ marginTop: theme.spacing(3.5) }}>
          <VrsButton buttonType="secondary" onClick={onClose}>
            Cancel
          </VrsButton>
          <VrsButton buttonType="primary" onClick={onDelete}>
            Yes, delete
          </VrsButton>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
