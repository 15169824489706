import {
  QuestionType,
  ReportAnswer,
  ReportAnswerOption,
  BodyPartAnswerItemView,
} from "@veris-health/virtual-doc-ms/lib/v1";
import { get, isArray } from "lodash";
import { VrsAvatar } from "@veris-health/web-core";
import React from "react";
import {
  ClinicalTaskDataAbnormalReadingTypeEnum,
  ClinicalTaskDataSymptomReportTypeEnum,
} from "@veris-health/med-data-ms/lib/v1";
import {
  NotificationType,
  NotificationView,
  OutOfRangePatientsNotificationViewData,
  SymptomReviewNotificationViewData,
} from "@veris-health/communication-ms/lib/v1";
import { renderTimestampString, renderYearstampString } from "../../../utils/date";
import { getFullPictureFormat } from "../../shared/helpers";

export function formatReportAnswer(
  response: ReportAnswer["answer"],
  typeQuestion: QuestionType,
): string | JSX.Element | undefined {
  if (typeof response === "string") {
    switch (typeQuestion) {
      case QuestionType.Datetime:
        return `${renderYearstampString(response)} at ${renderTimestampString(response)}`;
      case QuestionType.Photo: {
        const dataType = response.split("base64:")[1];
        const imageUrl = getFullPictureFormat(dataType);

        // TODO: Pass the name property to VrsAvatar
        return <VrsAvatar variant="square" size={42} imageUrl={imageUrl} name="" />;
      }
      default:
        return response;
    }
  }

  if (isBodyPartResponse(response)) {
    return response.map(({ body_part: bodyPart }) => bodyPart.value).join(", ");
  }

  if (isReportAnswerResponse(response)) {
    return response.map(({ value }) => value).join(", ");
  }

  if (isArray(response)) return response.join(", ");

  if (isArray(response?.value)) return response?.value.join(", ");

  return response?.value;
}

function isBodyPartResponse(
  response: ReportAnswer["answer"],
): response is BodyPartAnswerItemView[] {
  return (
    isArray(response) &&
    response.length > 0 &&
    "body_part" in (response as BodyPartAnswerItemView[])[0]
  );
}

function isReportAnswerResponse(
  response: ReportAnswer["answer"],
): response is ReportAnswerOption[] {
  return (
    isArray(response) && response.length > 0 && "value" in (response as ReportAnswerOption[])[0]
  );
}

export const isSymptomNotification = (notification: NotificationView) => {
  return (
    notification.type === NotificationType.SymptomAdded ||
    notification.type === NotificationType.SymptomReminder ||
    notification.type === NotificationType.SymptomReviewed ||
    notification.type === NotificationType.SymptomResolved ||
    notification.type === NotificationType.SymptomUpdated
  );
};

export const isOutOfRangeNotification = (notification: NotificationView) => {
  return notification.type === NotificationType.OutOfRangePatients;
};

const getPatientFromSymptomNotification = (data: SymptomReviewNotificationViewData) => {
  if (data.patient)
    return {
      patient: {
        name: data.patient.full_name,
        id: data.patient.id,
        image: data.patient.image,
        hospital: data.patient.hospital_ids,
      },
    };
  return { patient: undefined };
};

export const getTaskDetailsFromNotification = (notificationForTask?: NotificationView) => {
  if (notificationForTask && isSymptomNotification(notificationForTask)) {
    return {
      description: notificationForTask.payload.text,
      ...getPatientFromSymptomNotification(
        notificationForTask.payload.data as SymptomReviewNotificationViewData,
      ),
      type: ClinicalTaskDataSymptomReportTypeEnum.Symptom,
      subType: (notificationForTask?.payload?.data as SymptomReviewNotificationViewData)
        ?.symptom_report_id,
    };
  }
  if (notificationForTask && isOutOfRangeNotification(notificationForTask)) {
    return {
      type: ClinicalTaskDataAbnormalReadingTypeEnum.AbnormalReading,
      patient: (
        notificationForTask?.payload?.data as OutOfRangePatientsNotificationViewData
      ).patient_tags.map((patient) => ({
        name: get(patient, "patient_name"),
        id: get(patient, "patient_id"),
        hospital: get(patient, "patient_hospital_id"),
      }))[0],
      // [0] will be removed when BE change the type of the model
    };
  }
};
