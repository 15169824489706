import React from "react";
import { Theme, Typography, useMediaQuery } from "@mui/material";

export interface SideBarSectionTitleProps {
  sectionTitle: string;
}

export function SideBarSectionTitle({ sectionTitle }: SideBarSectionTitleProps): JSX.Element {
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));
  return (
    <Typography
      variant="button"
      component="h6"
      sx={{
        textTransform: "uppercase",
        color: ({ veris }) => veris.colors.neutrals["grey-3"],
        paddingTop: (theme) => (sectionTitle === "" && isSmallScreen ? 0 : theme.spacing(1)),
      }}
    >
      {sectionTitle}
    </Typography>
  );
}
