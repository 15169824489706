import React, { useCallback, useRef, useState } from "react";
import {
  Eventcalendar,
  MbscCalendarEvent,
  MbscEventcalendarView,
  MbscEventCreateEvent,
  MbscPageLoadingEvent,
  setOptions,
} from "@mobiscroll/react";
import dayjs from "dayjs";
import {
  TimelineTypes,
  selectEndDate,
  selectIsCreateEventOpen,
  selectStartDate,
  selectTimelineEvents,
  setEventCreationVisibility,
  setTimelineStartAndEndDate,
  setTimelineView,
  fetchTreatmentPlanEventsAsync,
  selectMedicationsCalendar,
  fetchPatientMedicationsAsync,
} from "./treatmentPlanSlice";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import "./Timeline.scss";
import { TimelineResource, TimelineResourceProps } from "./components/TimelineResource";
import { ResourceEnum, timelineColors, timelineResources } from "./components/timelineConstants";
import { VrsCalendarNavigator } from "../../ui/components/VrsCalendarNavigator";
import { useAppSelector } from "../../hooks/useAppSelector";
import { RenderScheduleEvent } from "../Calendar/components/RenderScheduleEvent";
import { selectAllPatients } from "../shared/slices/patientsSlice";
import { bookAppointmentAsync } from "../shared/slices/asyncThunks";
import CreateEvent from "../Calendar/CreateEvent/CreateEvent";
import { selectUserId } from "../shared/slices/authSlice";
import { EventPopup } from "../Calendar/EventPopup";
import { CalendarTimeslotProps } from "../Calendar/Calendar";
import MedicationEvent from "./components/MedicationEvent";
import { VrsPatientInfo } from "../shared/interfaces";
import { useProfile } from "../../context/profile";

setOptions({
  theme: "material",
  themeVariant: "light",
});
interface TimelineProps {
  view: TimelineTypes;
  viewConfig: MbscEventcalendarView;
  currentPatient: VrsPatientInfo;
}

const Timeline = ({ view, viewConfig, currentPatient }: TimelineProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const [anchor, setAnchor] = useState<HTMLElement>();
  const [eventPopUpVisibility, setEventPopupVisibility] = useState(false);
  const [eventSelected, setEventSelected] = useState<MbscCalendarEvent>();
  const [timeSlot, setTimeslot] = useState<CalendarTimeslotProps>();
  const events = JSON.parse(
    JSON.stringify(useAppSelector((state) => selectTimelineEvents(state, currentPatient.id))),
  );
  const instance = useRef<Eventcalendar>(null);
  const start = useAppSelector(selectStartDate);
  const end = useAppSelector(selectEndDate);
  const openCreateEvent = useAppSelector(selectIsCreateEventOpen);
  const currentUser = useAppSelector(selectUserId);
  const rawMedications = useAppSelector((state) =>
    selectMedicationsCalendar(state, currentPatient.id),
  );

  const medications = JSON.parse(JSON.stringify(rawMedications));
  const patients = useAppSelector(selectAllPatients);
  const selectedPatient = patients.find((patient) => patient.id === currentPatient?.id);
  const currentUserData = useProfile();

  const onCreateNewEvent = (existingTimeslot?: MbscEventCreateEvent) => {
    if (existingTimeslot && existingTimeslot?.event?.resource === ResourceEnum.Appointments) {
      setTimeslot({
        ...timeSlot,
        date: existingTimeslot.event?.start as Date,
        start: existingTimeslot.event?.start as Date,
        end: existingTimeslot.event?.end as Date,
      });
      dispatch(setEventCreationVisibility({ isOpen: true }));
    }
  };

  const changeCalendarView = (value: TimelineTypes): void => {
    dispatch(setTimelineView({ view: value }));
    if (instance.current?.state.activeDate)
      instance.current.navigate(instance.current.state.activeDate);
    else instance?.current?.navigate(dayjs().format());
  };

  const loadEventsForPage = (page: MbscPageLoadingEvent) => {
    if (currentPatient) {
      dispatch(
        setTimelineStartAndEndDate({
          start: page.firstDay.toISOString(),
          end: page.lastDay.toISOString(),
        }),
      );
      dispatch(
        fetchTreatmentPlanEventsAsync({
          userId: currentPatient.id,
          start: page.firstDay.toISOString(),
          end: page.lastDay.toISOString(),
        }),
      );
      dispatch(
        fetchPatientMedicationsAsync({
          userId: currentPatient.id,
          start: page.firstDay.toISOString(),
          end: page.lastDay.toISOString(),
        }),
      );
    }
  };

  const displayEvent = useCallback(
    (data) => {
      switch (data.currentResource.id) {
        case ResourceEnum.Appointments:
          return <RenderScheduleEvent data={data} view={view} />;
        case ResourceEnum.Medication:
          return <MedicationEvent data={data} />;
        default:
          return <MedicationEvent data={data} />;
      }
    },
    [view],
  );

  const onEventClick = (props: MbscCalendarEvent) => {
    if (props.resource === ResourceEnum.Appointments) {
      setEventPopupVisibility(!eventPopUpVisibility);
      setEventSelected(props.event);
      setAnchor(props.domEvent.target);
    }
  };
  const RenderHeader = useCallback(
    () => (
      <VrsCalendarNavigator
        view={view ?? "day"}
        viewType="timeline"
        changeCalendarView={(value: TimelineTypes) => changeCalendarView(value)}
        createNewEvent={() => {
          if (instance.current?.state.selectedDate) {
            setTimeslot({
              date: instance.current.state.selectedDate,
              start: instance.current.state.selectedDate,
            });
          }
          if (currentPatient) {
            dispatch(setEventCreationVisibility({ isOpen: true }));
          }
        }}
        disableCreateEvent={currentPatient.isInactiveOrDeceased || !currentPatient.inCareTeam}
      />
    ),
    [view],
  );

  return (
    <>
      <Eventcalendar
        ref={instance}
        invalid={[
          {
            allDay: true,
            resource: [ResourceEnum.Medication],
            recurring: { repeat: "weekly", weekDays: "MO,TU,WE,TH,FR,SA,SU" },
          },
        ]}
        resources={timelineResources}
        renderHeader={RenderHeader}
        onEventClick={onEventClick}
        view={viewConfig}
        data={[...events, ...medications]}
        renderResource={useCallback(
          (resources: TimelineResourceProps) => (
            <TimelineResource {...resources} />
          ),
          [],
        )}
        cssClass="veris-timeline"
        colors={timelineColors}
        clickToCreate={
          currentPatient.isInactiveOrDeceased || !currentPatient.inCareTeam ? false : "single"
        }
        dateFormatLong="DDD, MMM D YYYY"
        showEventTooltip={false}
        onEventCreate={onCreateNewEvent}
        renderScheduleEvent={displayEvent}
        onPageLoading={(page: MbscPageLoadingEvent) => {
          loadEventsForPage(page);
        }}
      />
      {currentUser && selectedPatient?.inCareTeam && (
        <CreateEvent
          existingTimeSlot={timeSlot}
          isOpen={openCreateEvent}
          patientId={currentPatient?.id}
          timelinePatient={
            selectedPatient && {
              full_name: selectedPatient?.name,
              hospital_id: selectedPatient?.hospital?.id,
              id: selectedPatient?.id,
              active: true,
            }
          }
          onClose={() => {
            dispatch(setEventCreationVisibility({ isOpen: false }));
            dispatch(
              setTimelineStartAndEndDate({
                start,
                end,
              }),
            );
            if (currentPatient) {
              dispatch(
                fetchTreatmentPlanEventsAsync({
                  userId: currentPatient.id,
                  start,
                  end,
                }),
              );
            }
          }}
          currentUserId={currentUser}
          currentUserProfile={currentUserData}
          onEventCreate={({ appointment }) => {
            dispatch(
              bookAppointmentAsync({
                appointment,
                start,
                end,
                currentPatientId: currentPatient?.id,
              }),
            );
            dispatch(setEventCreationVisibility({ isOpen: false }));
          }}
        />
      )}
      <EventPopup
        anchor={anchor as HTMLElement}
        open={eventPopUpVisibility}
        eventSelected={eventSelected as MbscCalendarEvent}
        currentUserId={String(currentPatient?.id)}
        onClose={() => setEventPopupVisibility(false)}
      />
    </>
  );
};

export default Timeline;
