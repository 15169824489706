import { getMessaging, Messaging, onMessage, MessagePayload } from "firebase/messaging";
import { useEffect } from "react";
import { get } from "lodash";
import { initializeApp } from "firebase/app";
import { firebaseConfig } from "../../../api/firebase-config";
import SnackbarUtils from "../../../utils/SnackbarUtils";
import { Routes } from "../../../routes-config";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { selectCallStatus } from "../../shared/slices/voipSlice";

interface MessageEventData {
  type: "backgroundMessage";
  payload: MessagePayload;
}

export const useFirebaseMessaging = ({
  onFirebaseMessage,
}: {
  onFirebaseMessage: (id: string | undefined, type: string, isOnCall?: boolean) => void;
}): {
  firebaseMessaging: Messaging;
} => {
  const firebaseInstance = initializeApp(firebaseConfig);
  const firebaseMessaging = getMessaging(firebaseInstance);
  const callStatus = useAppSelector(selectCallStatus);

  const handleBackgroundMessages = (event: MessageEvent<MessageEventData>) => {
    if (event.data.type === "backgroundMessage") {
      const notificationType = get(event, "data.payload.data.type");
      if (notificationType === "unconfirmed_calls") {
        const notificationId = get(event, "data.payload.data.unconfirmed_call_id");
        if (notificationId) {
          onFirebaseMessage(notificationId, "unconfirmed_calls", callStatus.isOnCall);
        }
      } else {
        const notificationId = get(event, "data.payload.data.notification_id");
        if (notificationId) {
          onFirebaseMessage(notificationId, "backgroundMessage");
        }
      }
    }
  };

  useEffect(() => {
    const unsubscribe = onMessage(firebaseMessaging, ({ data }) => {
      const type = data && data.type;
      const id =
        type && ["backgroundMessage", "release-notes", "out-of-range-patients"].includes(type)
          ? data && data.notification_id
          : data && data.unconfirmed_call_id;
      if (type && id) {
        onFirebaseMessage(id, type, callStatus.isOnCall);
      } else {
        onNewMessageNotification();
      }
    });

    navigator.serviceWorker.addEventListener("message", handleBackgroundMessages);

    return () => {
      unsubscribe();
      navigator.serviceWorker.removeEventListener("message", handleBackgroundMessages);
    };
  }, [callStatus]);

  return {
    firebaseMessaging,
  };
};

function onNewMessageNotification() {
  if (!window.location.pathname.includes(Routes.COMMUNICATION)) {
    SnackbarUtils.success("New chat message received!");
  }
}
