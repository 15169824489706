import React, { useEffect, useState } from "react";
import { ContentState, convertFromHTML, convertFromRaw, EditorState } from "draft-js";
import { Box, Typography } from "@mui/material";
import dayjs from "dayjs";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import rtfToHTML from "@iarna/rtf-to-html";
import { ClinicalNote } from "@veris-health/med-data-ms/lib/v1";
import { VrsAvatar, dateFormats } from "@veris-health/web-core";
import { linkDecorator } from "../../../../ui/components/Editor/Link/Link";
import { Editor } from "../../../../ui/components/Editor";

interface ClinicalNoteItem {
  note: ClinicalNote;
  open: boolean;
  onClick: () => void;
}

export function ClinicalNoteItem({ note, open, onClick }: ClinicalNoteItem): JSX.Element {
  const { time_created: timeCreated, author } = note;
  const [editorState, setEditorState] = useState<EditorState | null>(null);

  const [html, setHtml] = useState<string>();

  useEffect(() => {
    if (note.payload_mime_type === "application/rtf" || note.payload_mime_type === "text/rtf") {
      rtfToHTML.fromString(note.payload, (err: string, parsedHtml: string) => {
        setHtml(parsedHtml);
      });
    } else {
      try {
        const parsed = JSON.parse(note?.payload || "");
        setEditorState(EditorState.createWithContent(convertFromRaw(parsed), linkDecorator));
      } catch (e) {
        setEditorState(
          EditorState.createWithContent(
            ContentState.createFromText(note?.payload || ""),
            linkDecorator,
          ),
        );
      }
    }
  }, [note]);

  useEffect(() => {
    if (html) {
      try {
        const { contentBlocks, entityMap } = convertFromHTML(html);
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        setEditorState(EditorState.createWithContent(contentState, linkDecorator));
      } catch (e) {
        setEditorState(
          EditorState.createWithContent(ContentState.createFromText(html), linkDecorator),
        );
      }
    }
  }, [html]);
  return (
    <Box
      sx={{
        flexGrow: 0,
        display: "flex",
        border: (theme) =>
          open
            ? `2px solid ${theme.veris.colors.amethyst.normal}`
            : `1px solid ${theme.veris.colors.neutrals["grey-2"]}`,
        borderRadius: "8px",
        flexDirection: "column",
        background: open ? "rgba(231, 232, 253, 0.5)" : "inherit",
        cursor: "pointer",
      }}
      p={2.3}
      mb={2.3}
      onClick={() => onClick && onClick()}
    >
      <Box mb={1}>
        <Typography variant="body" color={(theme) => theme.veris.colors.neutrals["grey-3"]}>
          {dayjs(timeCreated).format(dateFormats["MMM DD, YYYY"])}
        </Typography>
      </Box>
      <Box mb={1} style={{ maxHeight: "45px", overflow: "hidden" }}>
        {editorState && <Editor editorState={editorState} backgroundColor="transparent" readOnly />}
      </Box>
      <Box gridColumn="span 2">
        <Typography variant="caption" color={(theme) => theme.veris.colors.neutrals["grey-mid"]}>
          Added by
        </Typography>
      </Box>
      {author?.length && (
        <Box gridColumn="span 6" display="flex" style={{ alignItems: "center" }}>
          <VrsAvatar size={25} name={author[0].name} />
          <Typography
            variant="subtitle2"
            style={{ textTransform: "capitalize", marginLeft: "0.5rem" }}
          >
            {author[0].name}
          </Typography>
        </Box>
      )}
    </Box>
  );
}
