import { Box, Typography } from "@mui/material";
import { OtpTypePassword } from "@veris-health/user-ms/lib/v1";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { VrsIconButton, IconName, verisColors } from "@veris-health/web-core";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import { Routes } from "../../routes-config";
import { StyledActiveNavigationItem } from "../shared/styledComponents";
import {
  clearChangePasswordMessages,
  getOTPAsync,
  resetPasswordAsync,
  selectChangePasswordStatus,
  validateOTPAsync,
} from "../shared/slices/authSlice";
import { EmailForm } from "./ForgotPassword/EmailForm";
import { ResetForm } from "./ForgotPassword/ResetForm";
import { OTPLoginContainer } from "./OTPLoginContainer";

enum ForgotPasswordScreen {
  EMAIL = "Email",
  OTP = "OTP",
  RESET = "Reset Password",
}

export function ForgotPasswordContainer(): JSX.Element {
  const [activeScreen, setActiveScreen] = useState<ForgotPasswordScreen>(
    ForgotPasswordScreen.EMAIL,
  );
  const [OTPCode, setOTPCode] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const resetPasswordValidation = useAppSelector(selectChangePasswordStatus);
  const dispatch = useAppDispatch();
  const history = useHistory();

  const onEmailSubmit = (userEmail: string) => {
    dispatch(getOTPAsync({ email: userEmail })).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        setEmail(userEmail);
        setActiveScreen(ForgotPasswordScreen.OTP);
      }
    });
  };

  const onPasswordResetSubmit = (password: string) => {
    dispatch(
      resetPasswordAsync({
        newData: {
          verification_code: OTPCode,
          password,
          email,
        },
      }),
    ).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        history.push(Routes.STAFF_LOGIN);
      }
    });
  };

  const onOTPSubmit = (code: string) => {
    dispatch(
      validateOTPAsync({
        OTPData: {
          verification_code: code,
          email,
          verification_code_type: OtpTypePassword.ResetPasswordMedStaff,
        },
      }),
    ).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        setOTPCode(code);
        setActiveScreen(ForgotPasswordScreen.RESET);
      }
    });
  };

  const clearValidationError = () => {
    dispatch(clearChangePasswordMessages());
  };

  const renderActiveScreen = (screenName: ForgotPasswordScreen) => {
    switch (screenName) {
      case ForgotPasswordScreen.EMAIL:
        return (
          <EmailForm
            onSubmit={onEmailSubmit}
            validationStatus={resetPasswordValidation}
            clearValidationError={clearValidationError}
          />
        );
      case ForgotPasswordScreen.OTP:
        return (
          <>
            <StyledActiveNavigationItem variant="h3" my={5} width="fit-content">
              Enter the verification code
            </StyledActiveNavigationItem>
            <Typography
              variant="h6"
              pl={1}
              pb={2}
              color={(theme) => theme.veris.colors.amethyst.normal}
            >
              OTP code sent via sms.
            </Typography>
            <OTPLoginContainer onSubmit={onOTPSubmit} onResendCode={() => onEmailSubmit(email)} />
          </>
        );
      case ForgotPasswordScreen.RESET:
        return (
          <ResetForm
            onSubmit={onPasswordResetSubmit}
            validationStatus={resetPasswordValidation}
            clearValidationError={clearValidationError}
          />
        );
      default:
        return (
          <EmailForm
            onSubmit={() => setActiveScreen(ForgotPasswordScreen.OTP)}
            validationStatus={resetPasswordValidation}
            clearValidationError={clearValidationError}
          />
        );
    }
  };

  const backButtonLogic = (screenName: string) => {
    switch (screenName) {
      case ForgotPasswordScreen.EMAIL:
        history.push(Routes.STAFF_LOGIN);
        break;
      case ForgotPasswordScreen.OTP:
        setActiveScreen(ForgotPasswordScreen.EMAIL);
        break;
      case ForgotPasswordScreen.RESET:
        setActiveScreen(ForgotPasswordScreen.OTP);
        break;
      default:
        history.push(Routes.STAFF_LOGIN);
    }
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        sx={{ cursor: "pointer", position: "absolute", top: "15%" }}
        onClick={() => {
          backButtonLogic(activeScreen);
        }}
      >
        <VrsIconButton
          iconProps={{ name: IconName.ArrowLeft, stroke: verisColors.neutrals.black, size: 12 }}
        />
        <Typography variant="subtitle2" color={(theme) => theme.veris.colors.neutrals["grey-4"]}>
          Back
        </Typography>
      </Box>
      <Box display="flex" alignItems="baseline" flexDirection="column">
        {renderActiveScreen(activeScreen)}
      </Box>
    </>
  );
}
