import React, { useMemo } from "react";
import {
  Box,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import {
  IconName,
  Option,
  VrsButton,
  VrsIcon,
  VrsIconButton,
  dateFormats,
  verisColors,
} from "@veris-health/web-core";
import { useFormik } from "formik";
import * as yup from "yup";
import { Datepicker } from "@mobiscroll/react";
import dayjs from "dayjs";
import { get } from "lodash";
import { VrsPatientInfo } from "../../../../shared/interfaces";
import { addRPMEntry } from "../../../api/patientDetailsApi";
import SnackbarUtils from "../../../../../utils/SnackbarUtils";
import { useAppDispatch } from "../../../../../hooks/useAppDispatch";
import { loadRpmReviewItemsAsync } from "../../../patientDetailsSlice";
import { getPatientByIdAsync } from "../../../../shared/slices/asyncThunks";
import { extractErrorMessage } from "../../../../shared/helpers";

interface AddRPMProps {
  open: boolean;
  onClose: () => void;
  patient: VrsPatientInfo;
  rpmTimeCurrentMonth: number;
  rpmTimePreviousMonth: number;
}

const validationSchema = yup.object({
  minutes: yup.number().min(1).max(1440).required("Amount of minutes is required."),
  activities: yup.array().min(1).required("At least one activity is required."),
  dateRecorded: yup.string().required("Date is required."),
  other: yup.string().when("activities", {
    is: (value: string) => value.includes("Other"),
    then: yup.string().required("Field is required."),
    otherwise: yup.string(),
  }),
});

const initialValues = {
  minutes: undefined,
  activities: [] as string[],
  dateRecorded: dayjs().format(dateFormats["ddd MMM D, YYYY"]),
  other: "",
};

const activitiesOptions: Option[] = [
  {
    value: "Remote physiologic data review",
    label: "Remote physiologic data review",
  },
  {
    value: "Direct patient communication",
    label: "Direct patient communication",
  },
  {
    value: "Care management and coordination",
    label: "Care management and coordination",
  },
  {
    value: "Other",
    label: "Other",
  },
];

const DatepickerInput = styled(TextField)(({ theme }) => ({
  "& .MuiInput-root": {
    textAlign: "center",
    gap: theme.spacing(1),
    fontWeight: theme.typography.body2.fontWeight,
    color: theme.veris.colors.amethyst.normal,
    "&::before": {
      border: "none",
    },
    "&::after": {
      border: "none",
    },
    "&:hover:before": {
      border: "none !important",
    },
  },

  "& .MuiInput-input": {
    paddingBottom: 0,
  },
}));

export const AddRPM = ({
  open,
  onClose,
  patient,
  rpmTimeCurrentMonth,
  rpmTimePreviousMonth,
}: AddRPMProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const {
    errors,
    isValid,
    setFieldValue,
    values,
    handleChange,
    dirty,
    isSubmitting,
    resetForm,
    handleSubmit,
    touched,
    handleBlur,
    setTouched,
  } = useFormik({
    validationSchema,
    initialValues,
    onSubmit: (formValues) => {
      const replacedActivities = formValues.activities.map((activity) => {
        if (activity === "Other") return formValues.other;
        return activity;
      });

      const constructedDate = dayjs()
        .set("date", dayjs(values.dateRecorded).get("date"))
        .set("month", dayjs(values.dateRecorded).get("month"))
        .set("year", dayjs(values.dateRecorded).get("year"));
      addRPMEntry(patient?.id, {
        activities: replacedActivities,
        date_occurred: dayjs.utc(constructedDate).toISOString(),
        minutes: Number(formValues.minutes),
        text: formValues.other
          ? formValues.activities.toString()
          : `${formValues.activities.toString()}, ${formValues.other}`,
      })
        .then(() => {
          SnackbarUtils.success("Manual RPM entry added.");
          dispatch(loadRpmReviewItemsAsync(patient.id));
          dispatch(getPatientByIdAsync({ userId: patient.id, ignoreLocalState: true }));
        })
        .catch((err) => {
          const errorMsg = extractErrorMessage(err) || get(err, "response.data.detail[0].msg");

          SnackbarUtils.error(errorMsg || "Could not add manual RPM entry.");
        })
        .finally(() => {
          handleClose();
        });
    },
  });

  const shouldOtherAppear = useMemo(() => {
    return values.activities.includes("Other");
  }, [values.activities]);

  const handleSelection = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    setTouched({ activities: true });
    setFieldValue("activities", typeof value === "string" ? value.split(",") : value);
  };

  function handleClose() {
    resetForm();
    onClose();
  }

  return (
    <Dialog open={open} onClose={() => handleClose()}>
      <Box sx={{ padding: (theme) => theme.spacing(3) }}>
        <form onSubmit={handleSubmit}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h3" color={verisColors.neutrals["grey-4"]}>
              Add Clinical Time
            </Typography>
            <VrsIconButton onClick={() => handleClose()} iconProps={{ name: IconName.CloseIcon }} />
          </Box>
          <Box
            sx={{
              marginY: (theme) => theme.spacing(3),
              paddingY: (theme) => theme.spacing(3),
              borderTop: (theme) => `1px solid ${theme.veris.colors.neutrals["grey-light"]}`,
              borderBottom: (theme) => `1px solid ${theme.veris.colors.neutrals["grey-light"]}`,
            }}
          >
            <Box sx={{ display: "flex", gap: (theme) => theme.spacing(1) }}>
              <Typography variant="subtitle2" color={verisColors.neutrals["grey-3"]}>
                Patient Name:
              </Typography>
              <Typography
                component="span"
                variant="subtitle1"
                color={verisColors.neutrals["grey-dark"]}
              >
                {patient.name}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: (theme) => theme.spacing(1),
                marginY: (theme) => theme.spacing(2),
              }}
            >
              <Typography variant="subtitle2" color={verisColors.neutrals["grey-3"]}>
                Clinical Time Current month:
              </Typography>
              <Typography variant="subtitle2">{rpmTimeCurrentMonth} min</Typography>
            </Box>
            <Box sx={{ display: "flex", gap: (theme) => theme.spacing(1) }}>
              <Typography variant="subtitle2" color={verisColors.neutrals["grey-3"]}>
                Clinical Time Previous month:
              </Typography>
              <Typography variant="subtitle2">{rpmTimePreviousMonth} min</Typography>
            </Box>
          </Box>
          <Box>
            <Box sx={{ display: "flex", gap: (theme) => theme.spacing(1.5), alignItems: "center" }}>
              <Typography variant="subtitle2" color={verisColors.neutrals["grey-3"]}>
                Duration (in minutes):
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center", gap: (theme) => theme.spacing(1) }}>
                <Box>
                  <TextField
                    autoComplete="off"
                    name="minutes"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={!!errors.minutes && touched.minutes}
                    value={values.minutes}
                    placeholder="Number"
                    sx={{
                      "& .MuiInputBase-root": {
                        width: "71px",
                        height: "36px",
                      },
                      "& .MuiInputBase-input": {
                        padding: (theme) => theme.spacing(1),
                        textAlign: "center",
                      },
                    }}
                  />
                </Box>
                <Typography variant="subtitle2" color={verisColors.neutrals["grey-4"]}>
                  min
                </Typography>
              </Box>
            </Box>
            {errors.minutes && (
              <Typography variant="subtitle2" color={verisColors.errors.normal} py={1}>
                {errors.minutes}
              </Typography>
            )}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: (theme) => theme.spacing(1),
                marginY: (theme) => theme.spacing(4),
              }}
            >
              <Typography variant="subtitle2" color={verisColors.neutrals["grey-3"]}>
                Date occurred:
              </Typography>
              <Box>
                <Datepicker
                  inputComponent={DatepickerInput}
                  inputProps={{
                    value: dayjs(values.dateRecorded).format(dateFormats["ddd MMM D, YYYY"]),
                    variant: "standard",
                    error: !!errors.dateRecorded,
                    InputProps: {
                      startAdornment: (
                        <VrsIcon name={IconName.Calendar} stroke={verisColors.neutrals["grey-3"]} />
                      ),
                    },
                  }}
                  onChange={(event) => {
                    setFieldValue("dateRecorded", event.value);
                  }}
                  controls={["date"]}
                  min={dayjs().subtract(1, "month").startOf("month").add(1, "day").format()}
                  max={dayjs().format()}
                />
              </Box>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: (theme) => theme.spacing(1) }}>
              <Typography variant="subtitle2" color={verisColors.neutrals["grey-3"]}>
                Activity:
              </Typography>
              <FormControl
                sx={{ minWidth: "195px" }}
                error={!!errors.activities && touched.activities}
              >
                <InputLabel sx={{ top: (theme) => theme.spacing(-1) }}>
                  Choose Activities
                </InputLabel>
                <Select
                  fullWidth
                  placeholder="Choose Activities"
                  multiple
                  onBlur={handleBlur}
                  value={values.activities}
                  onOpen={() => {
                    setTouched({ activities: true });
                  }}
                  sx={{
                    "& .MuiSelect-select": {
                      padding: (theme) => theme.spacing(1),
                    },
                  }}
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip
                          key={value}
                          label={value}
                          sx={{
                            borderRadius: (theme) => theme.spacing(1),
                            color: (theme) => theme.veris.colors.neutrals["grey-4"],
                            fontWeight: (theme) => theme.typography.body.fontWeight,
                          }}
                        />
                      ))}
                    </Box>
                  )}
                  onChange={handleSelection}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        margin: "0",
                        padding: "0 8px",
                      },
                    },
                  }}
                >
                  {activitiesOptions.map((option) => (
                    <MenuItem
                      key={option.value}
                      value={option.value}
                      sx={{
                        borderBottom: (theme) =>
                          `1px solid ${theme.veris.colors.neutrals["grey-2"]}`,
                      }}
                    >
                      <Checkbox
                        sx={{ color: (theme) => theme.veris.colors.amethyst.normal }}
                        checked={values.activities.indexOf(option.value) > -1}
                        color="primary"
                      />
                      <ListItemText primary={option.label} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            {shouldOtherAppear && (
              <Box sx={{ marginY: (theme) => theme.spacing(4) }}>
                <Typography variant="subtitle2" color={verisColors.neutrals["grey-3"]}>
                  You selected &quot;Other&quot;. Please specify.
                </Typography>
                <TextField
                  autoComplete="off"
                  onBlur={handleBlur}
                  error={!!errors.other && touched.other}
                  placeholder="Write down activity name"
                  fullWidth
                  name="other"
                  value={values.other}
                  onChange={handleChange}
                  sx={{
                    marginTop: (theme) => theme.spacing(1),
                    "& .MuiInputBase-input": {
                      fontWeight: (theme) => theme.typography.body2.fontWeight,
                      padding: (theme) => theme.spacing(1),
                    },
                  }}
                />
              </Box>
            )}
            <Box
              sx={{
                marginY: (theme) => theme.spacing(4),
                padding: (theme) => theme.spacing(1),
                display: "flex",
                justifyContent: "space-between",
                gap: "10px",
                background: (theme) => theme.veris.colors.mango.light,
                borderRadius: "6px",
              }}
            >
              <Box width={16}>
                <VrsIcon name={IconName.AlertIcon} size={16} />
              </Box>
              <Typography component="p" variant="subtitle2" color={verisColors.neutrals["grey-4"]}>
                By clicking submit, I certify that this time was spent performing remote physiologic
                monitoring, patient communication, and/or care management/coordination. Please
                double check the accuracy.
              </Typography>
            </Box>
          </Box>
          <DialogActions>
            <VrsButton buttonType="secondary" onClick={() => handleClose()}>
              Cancel
            </VrsButton>
            <VrsButton
              buttonType="primary"
              type="submit"
              disabled={!isValid || !dirty || isSubmitting}
            >
              Submit Clinical Time
            </VrsButton>
          </DialogActions>
        </form>
      </Box>
    </Dialog>
  );
};
