import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
  PayloadAction,
} from "@reduxjs/toolkit";
import dayjs, { OpUnitType, QUnitType } from "dayjs";
import { meanBy, mapValues, get } from "lodash";
import {
  Measurement as SingleMeasurement,
  SensorEnum,
  SensorDataBox,
  SensorDataBoxPlot,
  FrequencyPeriod,
  BloodPressureMinMax,
  DataSource,
  BPMImplantMeasurement,
  BPMMioMeasurement,
  WeightMioMeasurement,
  TempImplantMeasurement,
  TempMioMeasurement,
  OximeterMioMeasurement,
  BloodPressureMioMeasurement,
  RPMImplantMeasurement,
  MotionImplantMeasurement,
  MotionMioMeasurement,
  MotionExternalMeasurement,
  ECGImplantMeasurement,
} from "@veris-health/med-data-ms/lib/v1";
import {
  ReportStatusEnum,
  SymptomEvent,
  SymptomReportsTimeline,
} from "@veris-health/virtual-doc-ms/lib/v1";
import { dateFormats } from "@veris-health/web-core";
import {
  fetchUserSymptoms,
  fetchUserSensorsDataExternal,
  fetchUnifiedUserSensorsData,
} from "./api/patientDetailsMeasurementsApi";
import { RootState } from "../../store";
import { Status } from "../shared/interfaces";
import { localizedLogout, logout } from "../shared/slices/authSlice";
import {
  configureSymptomsData,
  SymptomReport,
  symptomTimelineEventsCalculation,
} from "../../utils/symptoms";
import { isDateInRange } from "../shared/helpers";

export const sensorsGraphView = ["monthly", "weekly", "3-days", "daily"] as const;

export type SensorsGraphView = typeof sensorsGraphView[number];

export type SensorsGraphNavigationDirection = "previous" | "next";

export const dayJsNavigationMap: {
  [key in SensorsGraphView]: { value: number; unit: OpUnitType };
} = {
  daily: {
    value: 1,
    unit: "day",
  },
  "3-days": {
    value: 3,
    unit: "days",
  },
  weekly: {
    value: 1,
    unit: "week",
  },
  monthly: {
    value: 4,
    unit: "weeks",
  },
};

export type BarDataSingleMeasurement = SensorDataBox & {
  v: number;
  minMax: number[];
};

const generateNavigationDates = (n: number, unit: OpUnitType, date: dayjs.Dayjs) =>
  new Array(n)
    .fill(undefined)
    .map((_, i) => date.endOf("day").subtract(i, unit))
    .reverse();

export type ReferenceLine = {
  date: string | number;
  valueIndex: number;
};

export type MioSensorMeasurement =
  | BPMMioMeasurement
  | BloodPressureMioMeasurement
  | TempMioMeasurement
  | OximeterMioMeasurement
  | WeightMioMeasurement
  | MotionMioMeasurement;

export const SensorDataKeys: { [key: string]: string } = {
  temp: "v",
  bpm: "v",
  motion: "sum",
  blood_pressure: "sys",
  bp_dia: "dia",
  oximeter: "v",
  weight: "v",
};

export type BloodPressureNormalRange = {
  sys: number;
  dia: number;
};

export type SensorNormalRange = {
  min: number;
  max: number;
};

export type MioMotionMeasurement = {
  dt: string;
  steps: number;
  ldt: string;
};

export type MioSensors = {
  temp: { data: TempMioMeasurement[]; normalRange: SensorNormalRange };
  bpm: { data: BPMMioMeasurement[]; normalRange: SensorNormalRange };
  oximeter: { data: OximeterMioMeasurement[]; normalRange: SensorNormalRange };
  weight: { data: WeightMioMeasurement[]; normalRange: SensorNormalRange };
  ["blood_pressure"]: {
    data: BloodPressureMioMeasurement[];
    normalRange: { min: BloodPressureNormalRange; max: BloodPressureNormalRange };
  };
  motion: { data: MotionMioMeasurement[]; normalRange: SensorNormalRange };
};

export type BarSensors = {
  [key in SensorEnum]: { data: BarDataSingleMeasurement[]; normalRange: SensorNormalRange };
};

export type ImplantSensors = {
  [key in SensorEnum]: {
    data: (
      | BPMImplantMeasurement
      | TempImplantMeasurement
      | RPMImplantMeasurement
      | MotionImplantMeasurement
      | ECGImplantMeasurement
      | null
    )[];
    normalRange: SensorNormalRange;
    frequencies: FrequencyPeriod[];
  };
};

interface PatientDetailsMeasurementInitialState {
  dateTo: string;
  dateFrom: string;
  sensorsGraphView: SensorsGraphView;
  implantSensors: EntityState<{ sensors: ImplantSensors }>;
  barSensors: EntityState<{ sensors: BarSensors }>;
  mioSensors: EntityState<{ sensors: MioSensors }>;
  referenceLine: ReferenceLine;
  symptoms: EntityState<{ symptoms: SymptomReportsTimeline }>;
  status: Status;
  symptomsStatus: Status;
}

const mioSensorsAdapter = createEntityAdapter<{ sensors: MioSensors }>();
const barSensorsAdapter = createEntityAdapter<{ sensors: BarSensors }>();
const implantSensorsAdapter = createEntityAdapter<{ sensors: ImplantSensors }>();
const symptomsAdapter = createEntityAdapter<{ symptoms: SymptomReportsTimeline }>();

export const sensorInitialData = { data: [], normalRange: { min: 0, max: 0 } };

const initialState: PatientDetailsMeasurementInitialState = {
  dateTo: dayjs().endOf("day").format(),
  dateFrom: dayjs().subtract(1, "week").endOf("day").format(),
  sensorsGraphView: "weekly",
  implantSensors: implantSensorsAdapter.getInitialState(),
  mioSensors: mioSensorsAdapter.getInitialState(),
  barSensors: barSensorsAdapter.getInitialState(),
  referenceLine: { date: "", valueIndex: -1 },
  symptoms: symptomsAdapter.getInitialState(),
  status: "idle",
  symptomsStatus: "idle",
};

// Async Thunk(s)

export const fetchUnifiedUserSensorsDataAsync = createAsyncThunk(
  "measurementSlice/fetchUnifiedUserSensorsData",
  async (payload: { id: number }, { getState }) => {
    const { detailsMeasurements } = getState() as RootState;
    const { dateFrom, dateTo } = detailsMeasurements;
    const { id } = payload;

    const response = await fetchUnifiedUserSensorsData(
      id,
      dateFrom,
      dateTo,
      detailsMeasurements.sensorsGraphView === "daily",
    );

    interface UnifiedSensor {
      data:
        | Array<BPMImplantMeasurement | BPMMioMeasurement>
        | Array<WeightMioMeasurement>
        | Array<TempImplantMeasurement | TempMioMeasurement>
        | Array<OximeterMioMeasurement>
        | Array<BloodPressureMioMeasurement>
        | Array<RPMImplantMeasurement>
        | Array<MotionImplantMeasurement | MotionMioMeasurement | MotionExternalMeasurement>
        | Array<ECGImplantMeasurement>;
      normalRange: {
        min?: BloodPressureMinMax | number;
        max?: BloodPressureMinMax | number;
      };
      frequencies?: FrequencyPeriod[];
    }
    const groupedData: {
      [key: string]: {
        [key: string]: UnifiedSensor;
      };
    } = {};

    const SensorsBySourceMap = {
      [SensorEnum.Temp]: [DataSource.VerisPort, DataSource.Mio],
      [SensorEnum.Bpm]: [DataSource.VerisPort, DataSource.Mio],
      [SensorEnum.Rpm]: [DataSource.VerisPort],
      [SensorEnum.Motion]: [DataSource.VerisPort, DataSource.Mio, DataSource.External],
      [SensorEnum.Ecg]: [DataSource.VerisPort],
      [SensorEnum.BloodPressure]: [DataSource.Mio],
      [SensorEnum.Weight]: [DataSource.VerisPort],
      [SensorEnum.Oximeter]: [DataSource.Mio],
    };

    const formattedData = response.sensors.reduce((acc, sensor) => {
      const { sensor_type: sensorType } = sensor;
      if (sensor.readings.length > 0) {
        sensor.readings.forEach((reading) => {
          const { data_source: dataSource } = reading;

          if (!(dataSource in acc)) {
            acc[dataSource] = {};
          }

          if (!(sensorType in acc[dataSource])) {
            acc[dataSource][sensorType] = {
              data: [],
              normalRange: { min: 0, max: 0 },
            };
          }
          if (sensorType === "ecg")
            acc[dataSource][sensorType]?.data?.push({
              ...reading,
              ["dt" as keyof ECGImplantMeasurement]: dayjs(reading?.dt).unix(),
              v: 1,
            } as never);

          if (acc[dataSource][sensorType].data)
            acc[dataSource][sensorType].data.push({
              ...reading,
              dt: dayjs(reading?.dt).unix(),
            } as never);
          acc[dataSource][sensorType].normalRange = {
            min: "min" in sensor ? sensor.min : undefined,
            max: "max" in sensor ? sensor.max : undefined,
          };
          if (dataSource === "veris-port")
            acc[dataSource][sensorType].frequencies = sensor.frequencies;
        });
      } else {
        const dataSources = SensorsBySourceMap[sensorType];

        dataSources.forEach((source) => {
          if (!(source in acc)) {
            acc[source] = {};
          }
          if (!(sensorType in acc[source])) {
            acc[source][sensorType] = {
              data: [],
              normalRange: { min: 0, max: 0 },
            };
          }
          acc[source][sensorType].normalRange = {
            min: "min" in sensor ? sensor.min : undefined,
            max: "max" in sensor ? sensor.max : undefined,
          };
          if (source === "veris-port") acc[source][sensorType].frequencies = sensor.frequencies;
        });
      }

      return acc;
    }, groupedData);
    return {
      id,
      data: {
        mio: { id, sensors: formattedData.mio as MioSensors },
        implant: { id, sensors: formattedData["veris-port"] as ImplantSensors },
        external: { id, sensors: formattedData.external as unknown as BarSensors },
      },
      range: { dateFrom, dateTo },
    };
  },
);

export const fetchUserSymptomsData = createAsyncThunk(
  "measurementSlice/fetchUserSymptoms",
  async (payload: { userId: string }, { getState }) => {
    const { userId } = payload;
    const { detailsMeasurements } = getState() as RootState;
    const { dateFrom, dateTo } = detailsMeasurements;
    const response = await fetchUserSymptoms(userId, dateFrom, dateTo);
    return { data: { id: userId, symptoms: response.data }, range: { dateFrom, dateTo } };
  },
);

export const fetchUserBarChartsDataAsync = createAsyncThunk(
  "measurementSlice/fetchUserBarChartData",
  async (payload: { id: number }, { getState }) => {
    const { detailsMeasurements } = getState() as RootState;
    const { dateFrom, dateTo } = detailsMeasurements;
    const { id } = payload;

    const response = await fetchUserSensorsDataExternal(
      id,
      dateFrom,
      dateTo,
      detailsMeasurements.sensorsGraphView === "daily",
    );

    const sensorsData: BarSensors = response.data.sensors.reduce(
      (acc, { sensor_type: sensorType, data, min, max }: SensorDataBoxPlot) => ({
        ...acc,
        [sensorType]: {
          data: [
            ...data.map((reading) => {
              return {
                ...reading,
                v: reading.median ?? reading.sum,
                minMax: [reading.max, reading.min],
                dt: dayjs(get(reading, "dt")).unix(),
              };
            }),
          ],
          normalRange: { min, max },
        },
      }),
      {} as BarSensors,
    );

    return { data: { id, sensors: sensorsData }, range: { dateFrom, dateTo } };
  },
);

const detailsMeasurementsSlice = createSlice({
  name: "Patient Details Measurements",
  initialState,
  reducers: {
    setSensorsGraphView: (state, action: PayloadAction<{ view: SensorsGraphView }>) => {
      const { view } = action.payload;
      const { dateTo } = state;
      state.dateFrom = dayjs(dateTo)
        .endOf("day")
        .subtract(dayJsNavigationMap[view].value, dayJsNavigationMap[view].unit)
        .format();
      state.sensorsGraphView = view;
    },
    resetSensorsGraphView: (state, action: PayloadAction<{ view?: SensorsGraphView }>) => {
      const { view } = action.payload;
      state.dateTo = dayjs().endOf("day").format();
      state.dateFrom = dayjs()
        .endOf("day")
        .subtract(
          dayJsNavigationMap[view || state.sensorsGraphView].value,
          dayJsNavigationMap[view || state.sensorsGraphView].unit,
        )
        .format();
      if (view) {
        state.sensorsGraphView = view;
      }
      state.referenceLine = { date: "", valueIndex: -1 };
    },
    switchSensorsGraphView: (state, action: PayloadAction<string>) => {
      state.dateTo = action.payload;
      switch (state.sensorsGraphView) {
        case "daily":
        case "3-days":
        case "weekly":
          state.dateFrom = dayjs(action.payload).subtract(1, "day").endOf("day").format();
          state.sensorsGraphView = "daily";
          break;
        case "monthly":
          state.dateFrom = dayjs(action.payload).subtract(1, "week").endOf("day").format();
          state.sensorsGraphView = "weekly";
          break;
        default:
          break;
      }
    },
    navigateSensorsGraphView: (
      state,
      action: PayloadAction<{ direction: SensorsGraphNavigationDirection }>,
    ) => {
      const { dateFrom, dateTo } = state;
      const view = state.sensorsGraphView;
      const { direction } = action.payload;
      switch (direction) {
        case "previous":
          state.dateTo = dayjs(dateTo)
            .subtract(dayJsNavigationMap[view].value, dayJsNavigationMap[view].unit)
            .format();
          state.dateFrom = dayjs(dateFrom)
            .subtract(dayJsNavigationMap[view].value, dayJsNavigationMap[view].unit)
            .format();
          break;
        case "next": {
          if (
            dayjs(dateTo)
              .add(dayJsNavigationMap[view].value, dayJsNavigationMap[view].unit)
              .isAfter(dayjs(), "date")
          ) {
            state.dateTo = dayjs().format();
            state.dateFrom = dayjs()
              .subtract(dayJsNavigationMap[view].value, dayJsNavigationMap[view].unit)
              .format();
          } else {
            state.dateTo = dayjs(dateTo)
              .add(dayJsNavigationMap[view].value, dayJsNavigationMap[view].unit)
              .format();
            state.dateFrom = dayjs(dateFrom)
              .add(dayJsNavigationMap[view].value, dayJsNavigationMap[view].unit)
              .format();
          }
          break;
        }
        default:
          break;
      }
    },
    setNavigationDate: (state, action: PayloadAction<string>) => {
      state.dateTo = action.payload;
    },

    setReferenceLine: (state, action) => {
      state.referenceLine = action.payload;
    },
    setFetchRange: (state, action: PayloadAction<{ startDate: string; endDate: string }>) => {
      state.dateFrom = action.payload.startDate;
      state.dateTo = action.payload.endDate;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUnifiedUserSensorsDataAsync.pending, (state) => {
        state.implantSensors = implantSensorsAdapter.getInitialState();
        state.mioSensors = mioSensorsAdapter.getInitialState();
        state.status = "loading";
      })
      .addCase(fetchUnifiedUserSensorsDataAsync.fulfilled, (state, action) => {
        const currentStart = state.dateFrom;
        const currentEnd = state.dateTo;
        const fetchedStart = action.payload.range.dateFrom;
        const fetchedEnd = action.payload.range.dateTo;

        const currentImplantData = implantSensorsAdapter
          .getSelectors()
          .selectById(state.implantSensors, action.payload.id);

        const currentMioData = mioSensorsAdapter
          .getSelectors()
          .selectById(state.mioSensors, action.payload.id);

        const currentBarData = barSensorsAdapter
          .getSelectors()
          .selectById(state.barSensors, action.payload.id);

        if (
          dayjs(currentStart).isSame(dayjs(fetchedStart)) &&
          dayjs(currentEnd).isSame(dayjs(fetchedEnd))
        ) {
          if (currentMioData) {
            mioSensorsAdapter.updateOne(state.mioSensors, {
              id: action.payload.id,
              changes: {
                sensors: action.payload.data.mio.sensors,
              },
            });
          } else mioSensorsAdapter.addOne(state.mioSensors, action.payload.data.mio);

          if (currentImplantData) {
            implantSensorsAdapter.updateOne(state.implantSensors, {
              id: action.payload.id,
              changes: {
                sensors: action.payload.data.implant.sensors,
              },
            });
          } else implantSensorsAdapter.addOne(state.implantSensors, action.payload.data.implant);
        }

        if (
          dayjs(currentStart).isSame(dayjs(fetchedStart)) &&
          dayjs(currentEnd).isSame(dayjs(fetchedEnd))
        ) {
          if (currentBarData) {
            barSensorsAdapter.updateOne(state.barSensors, {
              id: action.payload.id,
              changes: {
                sensors: action.payload.data.external.sensors,
              },
            });
          } else barSensorsAdapter.addOne(state.barSensors, action.payload.data.external);
        }

        state.status = "idle";
      })
      .addCase(fetchUnifiedUserSensorsDataAsync.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(fetchUserSymptomsData.pending, (state) => {
        state.symptomsStatus = "loading";
      })
      .addCase(fetchUserSymptomsData.fulfilled, (state, action) => {
        const currentStart = state.dateFrom;
        const currentEnd = state.dateTo;
        const fetchedStart = action.payload.range.dateFrom;
        const fetchedEnd = action.payload.range.dateTo;

        const currentSymptoms = symptomsAdapter
          .getSelectors()
          .selectById(state.symptoms, action.payload.data.id);

        if (
          dayjs(currentStart).isSame(dayjs(fetchedStart)) &&
          dayjs(currentEnd).isSame(dayjs(fetchedEnd))
        ) {
          if (currentSymptoms) {
            symptomsAdapter.updateOne(state.symptoms, {
              id: action.payload.data.id,
              changes: {
                symptoms: action.payload.data.symptoms,
              },
            });
          } else symptomsAdapter.addOne(state.symptoms, action.payload.data);
        }
        state.symptomsStatus = "idle";
      })
      .addCase(fetchUserSymptomsData.rejected, (state) => {
        state.symptoms = symptomsAdapter.getInitialState();
        state.symptomsStatus = "failed";
      })
      .addCase(fetchUserBarChartsDataAsync.pending, (state) => {
        state.barSensors = barSensorsAdapter.getInitialState();
        state.status = "loading";
      })
      .addCase(fetchUserBarChartsDataAsync.fulfilled, (state, action) => {
        const currentStart = state.dateFrom;
        const currentEnd = state.dateTo;
        const fetchedStart = action.payload.range.dateFrom;
        const fetchedEnd = action.payload.range.dateTo;

        const currentData = barSensorsAdapter
          .getSelectors()
          .selectById(state.barSensors, action.payload.data.id);
        if (
          dayjs(currentStart).isSame(dayjs(fetchedStart)) &&
          dayjs(currentEnd).isSame(dayjs(fetchedEnd))
        ) {
          if (currentData) {
            barSensorsAdapter.updateOne(state.barSensors, {
              id: action.payload.data.id,
              changes: {
                sensors: action.payload.data.sensors,
              },
            });
          } else barSensorsAdapter.addOne(state.barSensors, action.payload.data);
        }
        state.status = "idle";
      })
      .addCase(fetchUserBarChartsDataAsync.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(logout, () => {
        return initialState;
      })
      .addCase(localizedLogout, () => {
        return initialState;
      });
  },
});

// Actions

export const {
  setSensorsGraphView,
  resetSensorsGraphView,
  switchSensorsGraphView,
  navigateSensorsGraphView,
  setNavigationDate,
  setReferenceLine,
  setFetchRange,
} = detailsMeasurementsSlice.actions;

// Selectors

export const selectSensorsGraphView = ({ detailsMeasurements }: RootState): SensorsGraphView =>
  detailsMeasurements.sensorsGraphView;

export const selectDateTo = ({ detailsMeasurements }: RootState): string =>
  detailsMeasurements.dateTo;

export const selectDateFrom = ({ detailsMeasurements }: RootState): string =>
  detailsMeasurements.dateFrom;

export const selectImplantSensors = (
  rootState: RootState,
  id: number,
): ImplantSensors | undefined => {
  return implantSensorsAdapter
    .getSelectors<RootState>((state) => state.detailsMeasurements.implantSensors)
    .selectById(rootState, id)?.sensors;
};

export const selectMioSensors = (rootState: RootState, id: number): MioSensors | undefined => {
  return mioSensorsAdapter
    .getSelectors<RootState>((state) => state.detailsMeasurements.mioSensors)
    .selectById(rootState, id)?.sensors;
};

export const selectReferenceLine = ({ detailsMeasurements }: RootState): ReferenceLine =>
  detailsMeasurements.referenceLine;

export const selectMeasurementsStatus = ({ detailsMeasurements }: RootState): Status => {
  return detailsMeasurements.status;
};

export const selectUserSymptoms = (
  rootState: RootState,
  id: number,
): SymptomReportsTimeline | undefined => {
  return symptomsAdapter
    .getSelectors<RootState>((state) => state.detailsMeasurements.symptoms)
    .selectById(rootState, id)?.symptoms;
};

export const selectUserSymptomsStatus = ({ detailsMeasurements }: RootState): Status =>
  detailsMeasurements.symptomsStatus;

export const selectBarSensorsData = (
  rootState: RootState,
  id: number | undefined,
): BarSensors | undefined => {
  if (id) {
    return barSensorsAdapter
      .getSelectors<RootState>((state) => state.detailsMeasurements.barSensors)
      .selectById(rootState, id)?.sensors;
  }
  return undefined;
};

export const selectFormattedSensorsData = createSelector(
  [(state, id: number) => selectImplantSensors(state, id)],
  (sensorValues) => {
    const mappedValues = mapValues(sensorValues, (values) => {
      const sensorFrequencies = values.frequencies;
      const newValues = (values?.data as SingleMeasurement[])
        .reduce(
          (previousValue: (SingleMeasurement | null)[], currentValue) => {
            const prevObj = previousValue[previousValue.length - 1];

            if (!prevObj) {
              previousValue.push(currentValue);
            }
            if (prevObj) {
              const frequenciesToCompare: {
                previousFrequency?: number;
                currentFrequency?: number;
              } = {};
              const prevDate = dayjs.unix(+prevObj.dt);
              const currentDate = dayjs.unix(+currentValue.dt);

              sensorFrequencies?.forEach((currentFrequency) => {
                const isPreviousMeasurementInRange = isDateInRange(
                  prevDate,
                  dayjs(currentFrequency?.from_datetime),
                  dayjs(currentFrequency?.to_datetime),
                );
                const isCurrentMeasurementInRange = isDateInRange(
                  currentDate,
                  dayjs(currentFrequency?.from_datetime),
                  dayjs(currentFrequency?.to_datetime),
                );

                if (isPreviousMeasurementInRange) {
                  frequenciesToCompare.previousFrequency = currentFrequency.minutes;
                }
                if (isCurrentMeasurementInRange) {
                  frequenciesToCompare.currentFrequency = currentFrequency.minutes;
                }
              });

              const diff = currentDate.diff(prevDate, "minutes");

              const minutes =
                frequenciesToCompare.previousFrequency === frequenciesToCompare.currentFrequency
                  ? frequenciesToCompare.previousFrequency
                  : frequenciesToCompare.currentFrequency;

              if (diff !== minutes) {
                previousValue.push(null);
              }
              previousValue.push(currentValue);
            }
            return previousValue;
          },

          [],
        )
        .map((v: SingleMeasurement | null) => {
          if (v) return v;
          return null;
        });
      return {
        data: newValues as (SingleMeasurement | null)[],
        normalRange: { min: values.normalRange.min, max: values.normalRange.max },
      };
    });

    return mappedValues;
  },
);

export const selectNavigationDates = createSelector(
  [selectDateTo, selectSensorsGraphView],
  (date, view): dayjs.Dayjs[] => {
    const fromDate = dayjs(date);
    switch (view) {
      case "daily":
        return [dayjs(date)];
      case "3-days":
        return generateNavigationDates(3, "days", fromDate);
      case "weekly":
        return generateNavigationDates(7, "days", fromDate);
      case "monthly":
        return generateNavigationDates(4, "weeks", fromDate);
      default:
        return generateNavigationDates(7, "days", fromDate);
    }
  },
);

export const selectSensorValuesAvgReadings = createSelector(
  [(state, id: number) => selectImplantSensors(state, id)],
  (sensors) => {
    return {
      temp: +meanBy(sensors?.temp?.data as TempImplantMeasurement[], (value) => value?.v).toFixed(
        1,
      ),
      bpm: +meanBy(sensors?.bpm?.data as BPMImplantMeasurement[], (value) => value?.v).toFixed(0),
      rpm: +meanBy(sensors?.rpm?.data as RPMImplantMeasurement[], (value) => value?.v).toFixed(0),
      motion: +meanBy(
        sensors?.motion?.data as MotionImplantMeasurement[],
        (value) => value?.sum,
      ).toFixed(0),
    };
  },
);

export const selectRangeDates = createSelector(
  [selectNavigationDates],
  (navigationDates): { startDate: string; endDate: string } => ({
    startDate: dayjs(navigationDates[0]).format(dateFormats["MMM DD"]),
    endDate: dayjs(navigationDates[navigationDates.length - 1]).format(dateFormats["MMM DD"]),
  }),
);

export const selectGeneratedRangeDates = (
  unit: QUnitType | OpUnitType,
): ((_: RootState) => string[]) => {
  const generatedRangeDays = createSelector(
    [selectDateFrom, selectDateTo],
    (startDate, endDate) => {
      const days = dayjs(endDate).diff(startDate, unit);

      return Array.from({ length: days }, (_, i) =>
        dayjs(startDate)
          .add(i + 1, unit)
          .format(),
      );
    },
  );
  return generatedRangeDays;
};

type CalculatedSymptomStatus = "incomplete" | "empty" | "no_update" | "incomplete_no_update";
export type SymptomStatusEnum = ReportStatusEnum | CalculatedSymptomStatus;
export type SymptomStyle = {
  fillColor?: string;
  fillValue?: number | string;
  label?: string;
};

export interface ExpandedSymptomEvent extends SymptomEvent {
  calculatedStatus: SymptomStatusEnum;
  generalStatus: ReportStatusEnum;
  key: string;
  style?: SymptomStyle;
}

export const selectSymptomsTimeline = createSelector(
  [selectGeneratedRangeDates("day"), (state, id: number) => selectUserSymptoms(state, id)],
  (daysArray, userSymptoms) => {
    const activeSymptoms = userSymptoms?.active_symptoms?.map((symptomData) =>
      symptomTimelineEventsCalculation(daysArray, {
        name: symptomData.symptom_name.label,
        events: symptomData.events as ExpandedSymptomEvent[],
      }),
    );
    return activeSymptoms;
  },
);

export const selectConfiguredSymptomsData = createSelector(
  [(state, id: number) => selectSymptomsTimeline(state, id)],
  (symptomsData): SymptomReport[] | undefined => {
    return symptomsData && configureSymptomsData(symptomsData);
  },
);

export default detailsMeasurementsSlice.reducer;
