import { styled } from "@mui/material";
import TableContainer, { tableContainerClasses } from "@mui/material/TableContainer";
import TableHead, { tableHeadClasses } from "@mui/material/TableHead";
import TableBody, { tableBodyClasses } from "@mui/material/TableBody";
import TableRow, { tableRowClasses } from "@mui/material/TableRow";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

export const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  [`&.${tableContainerClasses.root}`]: {
    border: `1px solid ${theme.veris.colors.neutrals["grey-light"]}`,
    borderRadius: theme.shape.borderRadius,
  },
}));

export const StyledTableHead = styled(TableHead)(({ theme }) => ({
  [`&.${tableHeadClasses.root}`]: {
    backgroundColor: theme.veris.colors.neutrals.white,
  },
}));

export const StyledTableBody = styled(TableBody)(({ theme }) => ({
  [`&.${tableBodyClasses.root}`]: {
    backgroundColor: theme.veris.colors.neutrals.white,
  },
  "& > tr:last-child": {
    borderBottom: "none",
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  [`&.${tableRowClasses.root}`]: {
    borderBottom: `1px solid ${theme.veris.colors.neutrals["grey-light"]}`,
  },
}));

export const StyledTableCell = styled(TableCell, {
  shouldForwardProp: (prop: string) => prop !== "cellPadding",
})<{ cellPadding?: number }>(({ theme, cellPadding }) => ({
  [`&.${tableCellClasses.root}`]: {
    padding: cellPadding !== undefined ? theme.spacing(cellPadding) : theme.spacing(1, 2, 0.5, 2),
    border: "none",
  },
}));

export default {
  StyledTableContainer,
  StyledTableHead,
  StyledTableBody,
  StyledTableRow,
  StyledTableCell,
};
