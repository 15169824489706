import { CommunicationUserView } from "@veris-health/communication-ms/lib/v1";
import { MedStaffProfileModel } from "@veris-health/user-ms/lib/v1";
import { chatApiV1, profileInfoApiV1 } from "../../../../api";

export const fetchUserChatInfo = async (id: number): Promise<CommunicationUserView> => {
  const response = await chatApiV1.communicationUserInfo(id);
  return response.data;
};

export const fetchProfileInfo = async (id: number): Promise<MedStaffProfileModel> => {
  const response = await profileInfoApiV1.getProfileInfo(id);
  return response.data;
};
