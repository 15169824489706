import { Box, styled, Tab, TabProps, Tabs, TabsProps } from "@mui/material";
import React from "react";
import { verisColors } from "@veris-health/web-core";

export interface VrsTabsContainerProps extends TabsProps {
  tabsContainerContent: { [label: string]: JSX.Element };
  children?: React.ReactNode;
  withTopMargin?: boolean;
  tabValue?: number;
  containerOverrides?: React.CSSProperties;
}

interface TabPanelProps {
  children: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vrs-tabpanel-${index}`}
      aria-labelledby={`vrs-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
};

const StyledTab = styled(Tab, {
  shouldForwardProp: (prop: string) => prop !== "clicked",
})<TabProps & { clicked: boolean }>(({ theme, clicked }) => ({
  ...theme.typography.subtitle2,
  padding: theme.spacing(1.75, 1.25),
  minWidth: "130px",
  whiteSpace: "nowrap",
  textAlign: "center",
  color: theme.veris.colors.neutrals["grey-3"],
  textTransform: "capitalize",
  backgroundColor: clicked
    ? theme.veris.colors.neutrals.white
    : theme.veris.colors.neutrals["soft-white"],
  borderRight: `1px solid ${theme.veris.colors.neutrals["grey-light"]}`,
  "&:focus, &:active": {
    color: theme.veris.colors.amethyst.normal,
    backgroundColor: theme.veris.colors.neutrals.white,
  },
  "&.Mui-selected": {
    ...theme.typography.subtitle1,
  },
}));

export function VrsTabsContainer({
  tabsContainerContent,
  children,
  withTopMargin,
  tabValue,
  sx,
  containerOverrides,
}: VrsTabsContainerProps): JSX.Element {
  const [value, setValue] = React.useState(tabValue || 0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const tabProps = (index: number) => {
    return {
      id: `vrs-tab-${index}`,
      "aria-controls": `vrs-tabpanel-${index}`,
    };
  };

  return (
    <Box
      mb={1}
      sx={{
        borderRadius: (theme) => +theme.shape.borderRadius * 0.375,
        border: (theme) => `1px solid ${theme.veris.colors.neutrals["grey-light"]}`,
        background: (theme) => theme.veris.colors.neutrals.white,
        marginTop: (theme) => (withTopMargin ? theme.spacing(1) : 0),
        ...containerOverrides,
      }}
      data-test-hook="vrsTabsContainer-container-element"
    >
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="vrs tabs"
        TabIndicatorProps={{ style: { backgroundColor: verisColors.neutrals.white } }}
        sx={{
          width: "100%",
          borderBottom: (theme) => `1px solid ${theme.veris.colors.neutrals["grey-light"]}`,
          ...sx,
        }}
      >
        {Object.keys(tabsContainerContent).map((label, index) => (
          <StyledTab label={label} {...tabProps(index)} key={label} clicked={value === index} />
        ))}
        {children}
      </Tabs>
      {Object.values(tabsContainerContent).map((content, index) => (
        <TabPanel value={value} index={index} key={content.key}>
          {content}
        </TabPanel>
      ))}
    </Box>
  );
}
