import React from "react";
import Draggable from "react-draggable";
import { Box } from "@mui/material";
import { VideoWindowedSettings } from "./components/VideoWindowedSettings";
import { selectCallStatus } from "../shared/slices/voipSlice";
import { useAppSelector } from "../../hooks/useAppSelector";

function VideoContainerWindow(): JSX.Element | null {
  const callStatus = useAppSelector(selectCallStatus);

  if (!callStatus.isOnCall || !callStatus.isWindowed) {
    return null;
  }
  return (
    <Draggable>
      <Box
        position="absolute"
        zIndex={1300}
        width={300}
        height={300}
        sx={{
          border: (theme) => `1px solid ${theme.veris.colors.neutrals["grey-light"]}`,
        }}
      >
        <VideoWindowedSettings />
      </Box>
    </Draggable>
  );
}

export default VideoContainerWindow;
