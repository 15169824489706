import React, { useCallback, useEffect, useRef, useState } from "react";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import InfiniteScroll from "react-infinite-scroll-component";
import { CommunicationUserIdentifier } from "@azure/communication-common";
import { throttle } from "lodash";
import { AttachmentMetadata, ThreadParticipantInfo } from "@veris-health/communication-ms/lib/v1";
import {
  downloadAttachmentSasAsync,
  getChatThreadMessagesAsync,
  selectMessages,
  selectNewMessageNotification,
  selectThreadAttachments,
  selectTypingIndicator,
  sendChatThreadMessage,
  uploadAttachmentAsync,
  setNewMessageNotification,
  selectChatHistoryThreadMessages,
  getChatHistoryThreadMessagesAsync,
  selectChatHistoryAttachments,
  VrsFileAttachment,
  VrChatHistoryAttachment,
  downloadChatHistoryAttachmentSasAsync,
} from "../store";
import { VrsChatInputArea } from "../components/VrsChatInputArea";
import { getChatClient } from "../utils/AzureChatClient";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { ChatMessage } from "../components/ChatMessage";
import { Status } from "../../shared/interfaces";
import { NewMessageNotification } from "../components/NewMessageNotification/NewMessageNotification";

interface ThreadMessagesContainerProps {
  currentThreadId: string;
  endpoint?: string;
  token?: string;
  myAzureChatUserId: string;
  participantsMap: Record<string, ThreadParticipantInfo>;
  messagesStatus: Status;
  isPrivateThread: boolean;
  showInteractions: boolean;
  chatHistoryPatientId?: number;
}

type VrsChatAttachmentsUnified = VrChatHistoryAttachment | VrsFileAttachment;

export const ThreadMessagesContainer = ({
  currentThreadId,
  endpoint,
  token,
  myAzureChatUserId,
  participantsMap,
  messagesStatus,
  isPrivateThread,
  showInteractions,
  chatHistoryPatientId,
}: ThreadMessagesContainerProps): JSX.Element => {
  const typingIndicator = useAppSelector(selectTypingIndicator);
  const messagesRef = useRef<HTMLDivElement>(null);

  const { messages, isLastPage, continuationToken } = useAppSelector((state) => {
    if (chatHistoryPatientId) {
      return selectChatHistoryThreadMessages(state, currentThreadId);
    }
    return selectMessages(state, currentThreadId);
  });

  const attachments: VrsChatAttachmentsUnified[] | undefined = useAppSelector((state) =>
    chatHistoryPatientId
      ? selectChatHistoryAttachments(state).attachments
      : selectThreadAttachments(state, currentThreadId)?.attachments,
  );

  const newMessageNotification = useAppSelector(selectNewMessageNotification);
  const [showReceivedMessageNotification, setShowReceivedMessageNotification] = useState(false);
  const dispatch = useAppDispatch();

  const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    if (!file) {
      return;
    }
    dispatch(
      uploadAttachmentAsync({
        file,
      }),
    );
  };

  const handleDownload = ({
    metadata,
    messageId,
  }: {
    metadata: AttachmentMetadata;
    messageId: string;
  }) => {
    if (chatHistoryPatientId) {
      dispatch(
        downloadChatHistoryAttachmentSasAsync({
          metadata,
          messageId,
          threadId: currentThreadId,
          patientId: chatHistoryPatientId,
        }),
      );
    } else {
      dispatch(downloadAttachmentSasAsync({ metadata, messageId, threadId: currentThreadId }));
    }
  };

  const sendThrottledChatMessage = useCallback(
    throttle((text) => {
      dispatch(
        sendChatThreadMessage({
          threadId: currentThreadId,
          message: { text },
        }),
      ).then(() => {
        setTimeout(() => {
          if (messagesRef.current)
            messagesRef?.current?.scrollTo(0, messagesRef?.current?.scrollHeight);
        }, 1500);
      });
    }, 500),

    [currentThreadId],
  );

  useEffect(() => {
    if (
      newMessageNotification &&
      newMessageNotification.threadId === currentThreadId &&
      messagesRef?.current &&
      messagesRef?.current?.scrollTop < 0
    ) {
      setShowReceivedMessageNotification(true);
    } else {
      dispatch(setNewMessageNotification({ threadId: undefined }));
    }
  }, [newMessageNotification.threadId]);

  const handleFetchMoreMessages = () => {
    if (messagesStatus === "idle") {
      if (chatHistoryPatientId) {
        dispatch(
          getChatHistoryThreadMessagesAsync({
            userId: chatHistoryPatientId,
            threadId: currentThreadId,
            items: 10,
            nextLink: continuationToken,
          }),
        );
      } else if (endpoint && token)
        dispatch(
          getChatThreadMessagesAsync({
            endpoint,
            token,
            threadId: currentThreadId,
            continuationToken,
          }),
        );
    }
  };

  return (
    <>
      {messagesStatus !== "loading" && messages.length === 0 && (
        <Typography
          component="div"
          variant="body"
          color={(theme) => theme.veris.colors.neutrals["grey-3"]}
          textAlign="center"
          pt={2}
          sx={{
            backgroundColor: (theme) =>
              chatHistoryPatientId ? theme.veris.colors.amethyst.touch : "unset",
          }}
        >
          There are no messages in this chat.
        </Typography>
      )}
      {chatHistoryPatientId && (
        <Box
          display="flex"
          justifyContent="center"
          sx={{
            backgroundColor: (theme) =>
              chatHistoryPatientId ? theme.veris.colors.amethyst.touch : "unset",
          }}
        >
          <Typography variant="caption" color={(theme) => theme.veris.colors.neutrals["grey-3"]}>
            Chat history is available in read mode only.
          </Typography>
        </Box>
      )}
      <>
        {showReceivedMessageNotification && (
          <NewMessageNotification
            onClick={() => {
              messagesRef?.current?.scrollTo(0, messagesRef?.current?.scrollHeight);
              setShowReceivedMessageNotification(false);
              dispatch(setNewMessageNotification({ threadId: undefined }));
            }}
            text="New message received. Click to scroll."
          />
        )}

        <Box
          sx={{
            marginTop: "auto",
            overflowY: "scroll",
            height: chatHistoryPatientId ? "85%" : "70%",
            backgroundColor: (theme) =>
              chatHistoryPatientId ? theme.veris.colors.amethyst.touch : "unset",
          }}
          display="flex"
          flexDirection="column-reverse"
          id="messagesScrollContainer"
          ref={messagesRef}
        >
          <Box />
          <InfiniteScroll
            dataLength={messages.length}
            inverse
            scrollableTarget="messagesScrollContainer"
            endMessage={
              messagesStatus === "idle" && (
                <Box display="flex" justifyContent="center">
                  <Typography
                    variant="caption"
                    color={(theme) => theme.veris.colors.neutrals["grey-3"]}
                  >
                    This is the beginning of this chat&apos;s history.
                  </Typography>
                </Box>
              )
            }
            loader={
              <Box display="flex" justifyContent="center">
                <Typography variant="caption">Loading messages...</Typography>
              </Box>
            }
            style={{ display: "flex", flexDirection: "column-reverse", overflow: "hidden" }}
            hasMore={!!(continuationToken && !isLastPage)}
            next={handleFetchMoreMessages}
          >
            {messages &&
              messages.map((message) => (
                <ChatMessage
                  key={message.id}
                  message={message}
                  myAzureChatUserId={myAzureChatUserId}
                  messageSender={
                    participantsMap[
                      (message.sender as CommunicationUserIdentifier).communicationUserId
                    ]
                  }
                  handleDownload={handleDownload}
                  attachment={
                    message.metadata &&
                    attachments?.find((att) => att.file_name === message.metadata?.attachmentUuid)
                  }
                />
              ))}
          </InfiniteScroll>
        </Box>
        {showInteractions && (
          <>
            {" "}
            <Box
              minHeight="20px"
              sx={{
                backgroundColor: (theme) =>
                  isPrivateThread
                    ? theme.veris.colors.pink.light
                    : theme.veris.colors.neutrals.white,
              }}
            >
              {messagesStatus === "idle" &&
                typingIndicator &&
                typingIndicator.threadId === currentThreadId &&
                "communicationUserId" in typingIndicator.sender &&
                typingIndicator.sender.communicationUserId !== myAzureChatUserId && (
                  <Typography
                    pl={2}
                    ml={2}
                    variant="caption"
                    sx={{
                      fontStyle: "italic",
                      color: (theme) => theme.veris.colors.neutrals["grey-mid"],
                    }}
                  >
                    {participantsMap[
                      (typingIndicator.sender as CommunicationUserIdentifier).communicationUserId
                    ]?.display_name || "Unknown"}{" "}
                    is typing...
                  </Typography>
                )}
            </Box>
            {(messagesStatus === "idle" ||
              (messagesStatus === "loading" && messages.length > 0)) && (
              <Box p={2}>
                <VrsChatInputArea
                  onSendMessage={(text) => sendThrottledChatMessage(text)}
                  onKeyPress={() => {
                    if (endpoint && token) {
                      const chatClient = getChatClient({ endpoint, token });
                      chatClient.getChatThreadClient(currentThreadId).sendTypingNotification();
                    }
                  }}
                  onUploadFromComputer={handleFileInput}
                />
              </Box>
            )}{" "}
          </>
        )}
      </>
    </>
  );
};
