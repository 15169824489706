import { Box, CircularProgress, Grid, styled, Typography } from "@mui/material";
import { GetSpokenLanguage, SpokenLanguage } from "@veris-health/user-ms/lib/v1";
import { VrsIcon, IconName, VrsButton, VrsErrorMsg } from "@veris-health/web-core";
import React, { useEffect, useState } from "react";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { Status } from "../../shared/interfaces";
import { selectUserId } from "../../shared/slices/authSlice";
import { setDoctorsLanguagesAsync } from "../doctorsProfileSlice";

const StyledEditableLanguage = styled(Typography, {
  shouldForwardProp: (prop: string) => prop !== "checked",
})<{ checked: boolean }>(({ theme, checked }) => ({
  color: checked ? theme.veris.colors.amethyst.normal : "black",
  paddingLeft: checked ? "normal" : theme.spacing(3),
  fontWeight: checked ? 600 : 400,
  cursor: "pointer",
  "&:hover": {
    color: theme.veris.colors.amethyst.normal,
    fontWeight: 600,
  },
}));

const StyledLanguage = styled(Typography, {
  shouldForwardProp: (prop: string) => prop !== "checked",
})<{ checked: boolean }>(({ theme, checked }) => ({
  color: checked ? theme.veris.colors.amethyst.normal : "black",
  paddingLeft: checked ? "normal" : theme.spacing(3),
  fontWeight: checked ? 600 : 400,
  cursor: "default",
}));

interface CheckedLanguage {
  language: GetSpokenLanguage;
  checked: boolean;
}

export interface LanguageSpokenProps {
  doctorsLanguages: SpokenLanguage[];
  languages: GetSpokenLanguage[];
  languagesStatus: Status;
  onRetry: () => void;
}

export function LanguageSpoken({
  doctorsLanguages,
  languages,
  languagesStatus,
  onRetry,
}: LanguageSpokenProps): JSX.Element {
  const currentUser = useAppSelector(selectUserId);
  const dispatch = useAppDispatch();
  const [editMode, setEditMode] = useState<boolean>(false);
  const [allLanguages, setAllLanguages] = useState<CheckedLanguage[]>([]);
  const disabled = allLanguages.filter((lan) => lan.checked).length === 0;

  useEffect(() => {
    setAllLanguages(
      languages.map((language) => {
        if (doctorsLanguages.map((ob) => ob.name).includes(language.name))
          return { language, checked: true };
        return { language, checked: false };
      }),
    );
  }, [doctorsLanguages, languages]);

  const handleSave = () => {
    const newLanguages = allLanguages.filter((lan) => lan.checked);

    if (currentUser)
      dispatch(
        setDoctorsLanguagesAsync({
          id: +currentUser,
          data: { languages_ids: newLanguages.map((lan) => lan.language.id) },
        }),
      ).then(() => setEditMode(false));
  };

  return (
    <Box sx={{ padding: (theme) => theme.spacing(4, 2) }}>
      {languagesStatus === "loading" && (
        <Box display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      )}

      {languagesStatus === "failed" && (
        <Box display="flex" justifyContent="center" alignItems="center">
          <VrsErrorMsg onClick={onRetry} />
        </Box>
      )}
      <Grid container width="50%" p={1.5} spacing={3}>
        {languagesStatus === "idle" &&
          allLanguages.map((language) => (
            <Grid item xs={4} key={language.language.name}>
              <Box display="flex" alignItems="center" gap={1.5}>
                {language.checked && <VrsIcon name={IconName.Check} size={10} />}
                {editMode ? (
                  <StyledEditableLanguage
                    variant="body"
                    checked={language.checked}
                    onClick={() => {
                      setAllLanguages(
                        allLanguages.map((lg) => {
                          if (lg.language === language.language)
                            return { language: lg.language, checked: !lg.checked };
                          return { language: lg.language, checked: lg.checked };
                        }),
                      );
                    }}
                  >
                    {language.language.name}
                  </StyledEditableLanguage>
                ) : (
                  <StyledLanguage variant="body" checked={language.checked}>
                    {language.language.name}
                  </StyledLanguage>
                )}
              </Box>
            </Grid>
          ))}
      </Grid>
      {languagesStatus === "idle" && (
        <Box display="flex" justifyContent="flex-end" p={2}>
          {editMode ? (
            <Box alignSelf="flex-end" display="flex">
              <VrsButton
                onClick={() => {
                  setEditMode(false);
                }}
                buttonType="transparent"
              >
                <Typography
                  variant="body"
                  sx={{
                    textDecoration: "underline",
                    textTransform: "none",
                  }}
                  onClick={() => {
                    setAllLanguages(
                      languages.map((language) => {
                        if (doctorsLanguages.map((ob) => ob.name).includes(language.name))
                          return { language, checked: true };
                        return { language, checked: false };
                      }),
                    );
                  }}
                >
                  Cancel
                </Typography>
              </VrsButton>
              <VrsButton
                buttonType="secondary"
                type="submit"
                onClick={handleSave}
                disabled={disabled}
              >
                Save
              </VrsButton>
            </Box>
          ) : (
            <Box alignSelf="flex-end">
              <VrsButton buttonType="secondary" onClick={() => setEditMode(true)}>
                Edit Details
              </VrsButton>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
}
