import React, { useCallback } from "react";
import { mergeStyles, Stack } from "@fluentui/react";
import {
  usePropsFor,
  VideoGallery,
  ControlBar,
  CameraButton,
  DevicesButton,
  MicrophoneButton,
  EndCallButton,
  DEFAULT_COMPONENT_ICONS,
  VideoStreamOptions,
} from "@azure/communication-react";
import { Box } from "@mui/material";
import { IconName, VrsIconButton } from "@veris-health/web-core";

export interface VideoCallUIProps {
  onMinimizeClick: () => void;
}

const videoViewOption: VideoStreamOptions = { scalingMode: "Fit" };

export function VideoCallUI({ onMinimizeClick }: VideoCallUIProps): JSX.Element {
  const videoGalleryProps = usePropsFor(VideoGallery);
  const cameraProps = usePropsFor(CameraButton);
  const microphoneProps = usePropsFor(MicrophoneButton);
  const endCallProps = usePropsFor(EndCallButton);
  const devicesProps = usePropsFor(DevicesButton);

  const onHangup = useCallback(async (): Promise<void> => {
    await endCallProps.onHangUp();
  }, [endCallProps]);

  return (
    <Stack className={mergeStyles({ height: "80vh" })}>
      <div style={{ width: "100%", height: "100%", position: "relative" }}>
        {videoGalleryProps && (
          <VideoGallery
            {...videoGalleryProps}
            layout="floatingLocalVideo"
            remoteVideoViewOptions={videoViewOption}
            localVideoViewOptions={videoViewOption}
          />
        )}
        <Box
          position="absolute"
          sx={{
            right: "2%",
            top: "2%",
            backgroundColor: ({ veris }) => veris.colors.amethyst.normal,
            zIndex: "2",
          }}
        >
          <VrsIconButton iconProps={{ name: IconName.Minimize }} onClick={onMinimizeClick} />
        </Box>
        <Box
          position="absolute"
          sx={{
            left: "50%",
            bottom: "3%",
            transform: "translateX(-50%)",
            zIndex: "2",
          }}
        >
          <ControlBar>
            {cameraProps && (
              <CameraButton
                {...cameraProps}
                onRenderIcon={(props) =>
                  props?.checked
                    ? DEFAULT_COMPONENT_ICONS.ControlButtonCameraOn
                    : DEFAULT_COMPONENT_ICONS.ControlButtonCameraOff
                }
              />
            )}
            {microphoneProps && (
              <MicrophoneButton
                {...microphoneProps}
                onRenderIcon={(props) =>
                  props?.checked
                    ? DEFAULT_COMPONENT_ICONS.ControlButtonMicOn
                    : DEFAULT_COMPONENT_ICONS.ControlButtonMicOff
                }
              />
            )}
            {devicesProps && <DevicesButton {...devicesProps} />}
            {endCallProps && (
              <EndCallButton
                {...endCallProps}
                onHangUp={onHangup}
                onRenderIcon={() => DEFAULT_COMPONENT_ICONS.ControlButtonEndCall}
              />
            )}
          </ControlBar>
        </Box>
      </div>
    </Stack>
  );
}
