import React, { useContext, useEffect, useState } from "react";
import dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";
import { union } from "lodash";
import { createStatefulCallClient, StatefulCallClient } from "@azure/communication-react";
import {
  AzureCommunicationTokenCredential,
  CommunicationTokenCredential,
} from "@azure/communication-common";
import {
  Call,
  CallAgent,
  CallState,
  JoinCallOptions,
  RemoteParticipant,
} from "@azure/communication-calling";
import {
  CallRecordType,
  CallStatusEnum,
  CommunicationUserView,
} from "@veris-health/communication-ms/lib/v1";
import { GetAppointmentResponse } from "@veris-health/med-data-ms/lib/v1";
import { useAppSelector } from "../hooks/useAppSelector";
import { selectUserId, selectIsLoggedIn } from "../features/shared/slices/authSlice";
import { useAppDispatch } from "../hooks/useAppDispatch";
import { useStateRef } from "../hooks/useStateRef/useStateRef";
import {
  makeCallRecord,
  finishCallRecord,
  setCallStatus,
} from "../features/shared/slices/voipSlice";
import { VrsPatientInfo } from "../features/shared/interfaces";

const getRemoteParticipantIdentifier = (participant: RemoteParticipant): string => {
  switch (participant.identifier.kind) {
    case "communicationUser":
      return participant.identifier.communicationUserId;
    case "phoneNumber":
      return participant.identifier.phoneNumber;
    case "microsoftTeamsUser":
      return participant.identifier.microsoftTeamsUserId;
    case "unknown":
      return participant.identifier.id;
    default:
      return "unknown";
  }
};

interface VideoProps {
  statefulCallClient?: StatefulCallClient;
  callAgent?: CallAgent;
  call?: Call;
  callState?: CallState;
  videoClientError?: Error;
  createCallAgent(tokenCredential: AzureCommunicationTokenCredential, displayName: string): void;
  startVideoCall: (
    currentPatient: VrsPatientInfo,
    appointmentInfo?: GetAppointmentResponse,
    chatUserInfo?: CommunicationUserView,
    startCallOptions?: JoinCallOptions,
  ) => void;
}

export const VideoContextData = React.createContext<{
  statefulCallClient?: StatefulCallClient;
  callAgent?: CallAgent;
  call?: Call;
  callState?: CallState;
  videoClientError?: Error;
  createCallAgent: (tokenCredential: CommunicationTokenCredential, displayName: string) => void;
  startVideoCall: (
    currentPatient: VrsPatientInfo,
    appointmentInfo?: GetAppointmentResponse,
    chatUserInfo?: CommunicationUserView,
    startCallOptions?: JoinCallOptions,
  ) => void;
}>({
  statefulCallClient: undefined,
  callAgent: undefined,
  call: undefined,
  callState: undefined,
  videoClientError: undefined,
  createCallAgent: () => undefined,
  startVideoCall: () => undefined,
});

export const VideoContext = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const dispatch = useAppDispatch();
  const [statefulCallClient, setStatefulCallClient] = useState<StatefulCallClient>();
  const [callAgent, setCallAgent] = useState<CallAgent | undefined>(undefined);
  const [call, setCall] = useState<Call>();
  const [callState, setCallState] = useState<CallState>();
  const [videoClientError, setVideoClientError] = useState<Error>();
  const [participantsListRef, setParticipantsList] = useStateRef<string[]>([]);
  const userId = useAppSelector(selectUserId);
  const isLoggedIn = useAppSelector(selectIsLoggedIn);

  const endVideoCall = () => {
    setParticipantsList([]);
    setCall(undefined);
    setCallState(undefined);
  };

  const createCallAgent = async (
    tokenCredential: CommunicationTokenCredential,
    displayName: string,
  ) => {
    if (statefulCallClient && callAgent === undefined) {
      const newCallAgent = await statefulCallClient.createCallAgent(tokenCredential, {
        displayName,
      });
      setCallAgent(newCallAgent);
    }
  };

  const startVideoCall = async (
    currentPatient: VrsPatientInfo,
    appointmentInfo?: GetAppointmentResponse,
    chatUserInfo?: CommunicationUserView,
    startCallOptions?: JoinCallOptions,
  ) => {
    if (statefulCallClient && callAgent && !call) {
      let newCall: Call | undefined;
      if (appointmentInfo && appointmentInfo.videoLink) {
        newCall = callAgent.join({ groupId: appointmentInfo.videoLink }, startCallOptions);

        dispatch(
          makeCallRecord({
            callId: appointmentInfo.videoLink,
            patientId: currentPatient?.id,
            callPatientName: currentPatient ? currentPatient.name : "N/A",
            callRecordType: CallRecordType.Appointment,
            expectedParticipants: appointmentInfo.attendees.reduce(
              (results: number[], attendee) => {
                if (attendee.userId && userId && attendee.userId !== +userId)
                  results.push(attendee.userId);
                return results;
              },
              [],
            ),
            startTime: dayjs(appointmentInfo?.startTime).format(),
            endTime: dayjs(appointmentInfo?.endTime).format(),
          }),
        );

        newCall.on("remoteParticipantsUpdated", (participants) => {
          const addedParticipantsIdentifiers = participants.added.map((p) =>
            getRemoteParticipantIdentifier(p),
          );

          setParticipantsList((oldList) => {
            return union(oldList, addedParticipantsIdentifiers);
          });
        });
      } else if (chatUserInfo) {
        newCall = callAgent.startCall(
          [{ communicationUserId: chatUserInfo.communication_user_id }],
          startCallOptions,
        );

        newCall.remoteParticipants[0].on("stateChanged", () => {
          if (newCall?.remoteParticipants[0].state === "Connected") {
            dispatch(
              makeCallRecord({
                callId: uuidv4(),
                patientId: currentPatient?.id,
                callPatientName: currentPatient ? currentPatient.name : "N/A",
                callRecordType: CallRecordType.DirectCall,
                expectedParticipants: [chatUserInfo.user_id],
                startTime: dayjs(appointmentInfo?.startTime).format(),
                endTime: dayjs(appointmentInfo?.endTime).format(),
              }),
            );
            setParticipantsList([chatUserInfo.communication_user_id]);
          }
        });
      }

      if (newCall) {
        newCall.on("stateChanged", () => {
          setCallState(newCall?.state);
          if (newCall?.state === "Disconnected") {
            dispatch(
              setCallStatus({
                isOnCall: false,
                callEndReason: newCall.callEndReason,
                isWindowed: false,
                isOnLobby: false,
              }),
            );
            dispatch(
              finishCallRecord({
                status: CallStatusEnum.Unconfirmed,
                endTime: dayjs().format(),
                attendees: participantsListRef.current,
                appointmentStartTime: dayjs(appointmentInfo?.startTime).format(),
                appointmentEndTime: dayjs(appointmentInfo?.endTime).format(),
              }),
            );
            endVideoCall();
          }
        });
      }
      setCall(newCall);
    }
  };

  useEffect(() => {
    try {
      if (userId) {
        const newClient = createStatefulCallClient({
          userId: { communicationUserId: userId },
        });
        setStatefulCallClient(newClient);
      } else {
        setStatefulCallClient(undefined);
      }
    } catch (e) {
      const error = e as Error;
      setVideoClientError(error);
    }
  }, [userId]);

  useEffect(() => {
    if (!isLoggedIn) {
      if (call) call.hangUp();
      if (callAgent) callAgent.dispose();
      setCallAgent(undefined);
    }
  }, [isLoggedIn]);

  return (
    <VideoContextData.Provider
      value={{
        statefulCallClient,
        videoClientError,
        callAgent,
        call,
        callState,
        createCallAgent,
        startVideoCall,
      }}
    >
      {children}
    </VideoContextData.Provider>
  );
};

export const useVideo = (): VideoProps => useContext(VideoContextData);
