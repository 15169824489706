import { alpha, Dialog, Badge, Box, styled, Typography } from "@mui/material";
import { verisColors } from "@veris-health/web-core";

export const StyledActiveNavigationItem = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  marginBottom: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  cursor: "pointer",
  borderBottom: `${theme.spacing(0.75)} solid ${theme.veris.colors.amethyst.normal}`,
  color: theme.veris.colors.amethyst.normal,
}));

export const StyledNavigationItem = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  paddingBottom: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  cursor: "pointer",
  color: theme.veris.colors.neutrals["grey-3"],
  "&:hover": {
    color: theme.veris.colors.amethyst.normal,
  },
}));

export const StyledSensorLabel = styled("div", {
  shouldForwardProp: (prop: string) => prop !== "isFirst" && prop !== "isLast",
})<{ isFirst: boolean; isLast: boolean }>(({ theme, isFirst, isLast }) => ({
  height: "110px",
  width: "150px",
  padding: theme.spacing(1),
  backgroundColor: theme.veris.colors.neutrals["grey-1"],
  borderStyle: "solid",
  borderWidth: "2px",
  borderColor: theme.veris.colors.neutrals["grey-light"],
  borderTopLeftRadius: isFirst ? "6px" : 0,
  borderBottomLeftRadius: isLast ? "6px" : 0,
  borderBottomWidth: isLast ? "2px" : "1px",
  borderTopWidth: isFirst ? "2px" : "1px",
}));

export const StyledPreviewSensorLabel = styled("div")(({ theme }) => ({
  borderRadius: "6px",
  border: `1px solid ${theme.veris.colors.neutrals["grey-light"]}`,
  padding: theme.spacing(0.5),
  display: "flex",
  alignItems: "center",
  backgroundColor: theme.veris.colors.neutrals["grey-1"],
  margin: theme.spacing(1, 0),
  zIndex: -1,
  cursor: "default",
}));

export const StyledSensorDetails = styled("div")(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(1),
  padding: theme.spacing(0.5),
}));

export const StyledDialogContents = styled(Box)(({ theme }) => ({
  padding: theme.spacing(5),
  background: theme.veris.colors.neutrals.white,
  maxHeight: "550px",
  minWidth: "530px",
}));

export const StyledCheckboxContainer = styled(Box)(({ theme }) => ({
  borderBottom: `1px solid ${theme.veris.colors.neutrals["grey-2"]}`,
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
}));

export const StyledNotificationBadge = styled(Badge)(({ theme }) => ({
  ...theme.typography.caption,
  "& .MuiBadge-badge": {
    right: 14,
    top: 12,
    background: theme.veris.colors.pink.normal,
    color: theme.veris.colors.neutrals.white,
    width: "8px",
    minWidth: "8px",
    height: "8px",
    paddingTop: "2px",
    cursor: "pointer",
    borderRadiis: "50%",
  },
}));

const getBackgroundColor = (isActive: boolean, isUnread: boolean) => {
  if (isActive) return alpha(verisColors.amethyst.light, 0.5);
  if (isUnread) return verisColors.pink.light;
  return verisColors.neutrals.white;
};

export const StyledNotificationWrapper = styled("div", {
  shouldForwardProp: (prop) =>
    prop !== "isActive" && prop !== "isUnread" && prop !== "showBorder" && prop !== "cursor",
})<{ isActive: boolean; isUnread: boolean; showBorder: boolean; cursor: string }>(
  ({ theme, isActive, isUnread, showBorder, cursor }) => ({
    backgroundColor: getBackgroundColor(isActive, isUnread),
    display: "flex",
    flexDirection: "column",
    cursor,
    border: isActive && showBorder ? `1px solid ${theme.veris.colors.amethyst.light}` : "",
    borderRadius: isActive || isUnread ? +theme.shape.borderRadius + 2 : 0,
    margin: theme.spacing(0.7, 0),
  }),
);
export const VrsDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: 0,
  },
  "& .MuiDialogActions-root": {
    padding: 0,
  },

  "& .MuiDialog-paper": {
    padding: theme.spacing(4),
    minHeight: "280px",
    maxWidth: "442px",
    width: "100%",
  },
}));
