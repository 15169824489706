import React from "react";
import { styled } from "@mui/material";
import Typography, { TypographyProps } from "@mui/material/Typography";

export interface VrsHighlightedTagProps extends TypographyProps {
  component?: React.ElementType;
  tagStyles: Pick<React.CSSProperties, "backgroundColor" | "color">;
}

export const VrsHighlightedTag = styled(
  ({ children, component = "span", ...props }: VrsHighlightedTagProps) => (
    <Typography component={component} {...props} data-test-hook="highlighted-tag">
      {children}
    </Typography>
  ),
  {
    name: "VrsHighlightedTag",
    label: "HighlightedTag",
    slot: "Root",
    shouldForwardProp: (prop) => prop !== "tagStyles",
  },
)(({ theme, tagStyles }) => ({
  ...tagStyles,
  padding: theme.spacing(0.25, 0.5, 0.25, 0.5),
  margin: theme.spacing(0, 0.5, 0, 0.5),
  textTransform: "lowercase",
  borderRadius: 4,
}));
