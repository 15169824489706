import { MbscCalendarEvent } from "@mobiscroll/react";
import { Box, Typography, Popover } from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect, useMemo, useState } from "react";
import { Form, Formik, FormikValues } from "formik";
import {
  AppointmentAttendeeStatusChangeEnum,
  AppointmentAttendeeResponse as Attendee,
  AppointmentTypeEnum,
  AppointmentAttendeeResponse,
} from "@veris-health/med-data-ms/lib/v1";
import { useHistory } from "react-router-dom";
import {
  VrsIconButton,
  IconName,
  VrsIcon,
  VrsButton,
  VrsSelect,
  VrsConfirmationModals,
  verisColors,
  dateFormats,
} from "@veris-health/web-core";
import { orderBy } from "lodash";
import { EventFooter } from "./components/EventFooter";
import { EventHeader } from "./components/EventHeader";
import "./Calendar.scss";
import { CombinedDatepicker } from "./components/CombinedDatepicker";
import { EventContents } from "./CreateEvent/EventContents";
import { eventValidationSchema } from "./CreateEvent/EventValidationSchema";
import { constructDate } from "./CreateEvent/CreateEvent";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { updateAppointmentAsync } from "./calendarSlice";
import { changeAppointmentStatusAsync } from "../shared/slices/asyncThunks";
import { replaceRouteParam } from "../../routes-config";
import { PAGE_NAME } from "../../constants";

const AppointmentTypeOptions = [
  {
    value: "Video",
    label: "Video",
  },
  {
    value: "Clinic",
    label: "Clinic",
  },
];

export interface EventPopupProps {
  eventSelected: MbscCalendarEvent;
  open: boolean;
  anchor: HTMLElement;
  currentUserId: string;
  onClose: () => void;
  isCalendar?: boolean;
}

export const EventPopup = ({
  eventSelected,
  open,
  anchor,
  currentUserId,
  onClose,
  isCalendar,
}: EventPopupProps): JSX.Element => {
  const history = useHistory();
  const isDatePassed = dayjs(eventSelected?.end as string | Date).isBefore(dayjs());
  const [editMode, setEditMode] = useState<boolean>(false);
  const [confirmCancelModal, setConfirmCancelModal] = useState<boolean>(false);
  const eventStartDate =
    eventSelected && dayjs(eventSelected?.start as string).format(dateFormats["dddd, MMM D"]);
  const eventStartTime =
    eventSelected && dayjs(eventSelected?.start as string).format(`${dateFormats["h:mm"]}A`);
  const eventEndTime =
    eventSelected && dayjs(eventSelected?.end as string).format(`${dateFormats["h:mm"]}A`);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [openTimePicker, setOpenTimePicker] = useState(false);
  const orderedParticipants = orderBy(
    eventSelected?.attendees,
    (info: Attendee) => info.userType,
    "desc",
  );

  const userStatus = useMemo(() => {
    return (
      eventSelected &&
      eventSelected.attendees?.find(
        (attendee: AppointmentAttendeeResponse) => attendee.userId === +currentUserId,
      ).status
    );
  }, [eventSelected]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    setEditMode(false);
  }, [eventSelected]);

  const redirectToAppointment = () => {
    history.push({
      pathname: replaceRouteParam(
        "VIDEO_COMMUNICATION",
        ":userId",
        eventSelected.patientId.toString(),
      ),
      search: `?appointmentId=${eventSelected.id}`,
      state: { from: PAGE_NAME },
    });
    onClose();
  };

  const changeAppointmentStatus = (appointmentResponse: AppointmentAttendeeStatusChangeEnum) => {
    dispatch(
      changeAppointmentStatusAsync({
        userId: +currentUserId,
        appointmentId: `${eventSelected.id}`,
        appointmentStatus: appointmentResponse,
      }),
    );
  };

  const onUpdatedEventSubmission = (values: FormikValues) => {
    const constructedStartDate = constructDate(values.date as Date, values.startTime as Date);
    const constructedEndDate = constructDate(values.date as Date, values.endTime as Date);
    const appointmentType =
      values.type === "Video"
        ? AppointmentTypeEnum.VideoAppointment
        : AppointmentTypeEnum.ClinicAppointment;

    dispatch(
      updateAppointmentAsync({
        userId: +currentUserId,
        appointmentId: `${eventSelected.id}`,
        appointment: {
          startTime: constructedStartDate,
          endTime: constructedEndDate,
          attendees: eventSelected?.attendees?.map((attendee: Attendee) => attendee.userId),
          title: values.title ?? "Updated appointment.",
          type: appointmentType,
          videoLink: eventSelected?.videoLink ?? undefined,
        },
      }),
    );
  };

  return (
    <Popover
      anchorOrigin={{
        vertical: "center",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "center",
        horizontal: "center",
      }}
      onClose={onClose}
      open={open}
      anchorEl={anchor}
    >
      <Formik
        initialValues={{
          type: eventSelected?.type ?? "",
          date: eventSelected?.start ?? dayjs().toISOString(),
          startTime: eventSelected?.start ?? "",
          endTime: eventSelected?.end ?? "",
          title: eventSelected?.title ?? "",
        }}
        validationSchema={eventValidationSchema}
        onSubmit={onUpdatedEventSubmission}
      >
        {({ values, setFieldValue, isValid }) => (
          <Form>
            <Box style={{ minWidth: "442px", maxWidth: "442px" }}>
              <VrsConfirmationModals
                handleClose={() => setConfirmCancelModal(false)}
                isOpen={confirmCancelModal}
                dialogHeader="Cancel appointment"
                dialogSecondContentLine="Are you sure you want to cancel this appointment?"
                dialogContent={`You are going to cancel the following appoinment: ${
                  eventSelected?.type
                } Appointment ${
                  eventSelected?.title
                } on ${`${eventStartDate} ( ${eventStartTime}-${eventEndTime} ).`}`}
                onCancel={() => setConfirmCancelModal(false)}
                onConfirm={() => {
                  changeAppointmentStatus(AppointmentAttendeeStatusChangeEnum.Declined);
                  setConfirmCancelModal(false);
                }}
                cancelButtonText="No, don't cancel it"
                confirmButtonText="Yes, cancel it"
              />
              <Box style={{ backgroundColor: verisColors.neutrals.white }} p={3}>
                <Box display="flex" justifyContent="space-between">
                  <EventHeader isPassedDate={isDatePassed} confirmed={eventSelected?.confirmed} />
                  <VrsIconButton iconProps={{ name: IconName.CloseIcon }} onClick={onClose} />
                </Box>
                {editMode ? (
                  <CombinedDatepicker
                    formValues={values as EventContents}
                    openDatePicker={openDatePicker}
                    openTimePicker={openTimePicker}
                    setFieldValue={setFieldValue}
                    setOpenDatePicker={(value) => setOpenDatePicker(value)}
                    setOpenTimePicker={(value) => setOpenTimePicker(value)}
                  />
                ) : (
                  <Box display="flex" alignItems="baseline" pt={3} pb={2}>
                    <Typography variant="body">{eventStartDate}</Typography>
                    <Typography variant="body" marginX={(theme) => theme.spacing(1)}>
                      •
                    </Typography>
                    <Typography variant="body">
                      {eventStartTime} - {eventEndTime}
                    </Typography>
                  </Box>
                )}
                <Box display="flex" pt={1} pb={3} gap={5} alignItems="center">
                  <VrsIcon name={IconName.ClinicOrVideo} />
                  {editMode ? (
                    <VrsSelect
                      options={AppointmentTypeOptions}
                      initialValue={values.type}
                      onSelected={(selected) => {
                        setFieldValue("type", selected);
                      }}
                    />
                  ) : (
                    <Typography variant="body">{eventSelected?.type}</Typography>
                  )}
                </Box>
                <Box display="flex" pb={3} gap={5} alignItems="center">
                  <VrsIcon name={IconName.AllPatients} color={verisColors.neutrals["grey-3"]} />
                  <Box gap={1}>
                    {orderedParticipants.map((attendee: Attendee, index: number) => (
                      <Typography variant="body" key={attendee.userId}>
                        {attendee?.name}
                        {index < eventSelected?.attendees?.length - 1 && ","}
                      </Typography>
                    ))}
                  </Box>
                </Box>
                {((editMode && values?.type === "Video") ||
                  (!editMode && eventSelected.type === "Video")) && (
                  <Box display="flex" pb={3} gap={5} alignItems="center">
                    <VrsIcon name={IconName.Link} />

                    <VrsButton buttonType="primary" onClick={redirectToAppointment}>
                      Video Appointment
                    </VrsButton>
                  </Box>
                )}
              </Box>
              {isCalendar && (
                <Box
                  display="flex"
                  justifyContent={editMode ? "flex-end" : "flex-start"}
                  p={3}
                  sx={{ background: (theme) => theme.veris.colors.neutrals["soft-white"] }}
                >
                  <EventFooter
                    isPassedDate={isDatePassed}
                    editMode={editMode}
                    editable={!!eventSelected.editable}
                    onClose={onClose}
                    cancelEditMode={() => setEditMode(false)}
                    onCancel={() => setConfirmCancelModal(true)}
                    onAcceptProposedEvent={() => {
                      changeAppointmentStatus(AppointmentAttendeeStatusChangeEnum.Accepted);
                    }}
                    onDeclineProposedEvent={() => {
                      changeAppointmentStatus(AppointmentAttendeeStatusChangeEnum.Declined);
                    }}
                    disableEditButton={!isValid}
                    confirmed={eventSelected?.confirmed}
                    setEditMode={() => setEditMode(true)}
                    needsAction={userStatus === "needs-action"}
                  />
                </Box>
              )}
            </Box>
          </Form>
        )}
      </Formik>
    </Popover>
  );
};
