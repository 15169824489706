import { createAsyncThunk, createSlice, createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import {
  CareTeamNote,
  SetCareTeamNote,
  ClinicalNote,
  NewClinicalNote,
  WeightTrendPeriodView,
  WeightTrendLookbackPeriod,
  WeightTrendAggregationWindowSize,
} from "@veris-health/med-data-ms/lib/v1";
import { Demographics, Language, UserInfoBaseResponse } from "@veris-health/user-ms/lib/v1";
import { GoalFiniteResponse, GoalRecurringResponse } from "@veris-health/virtual-doc-ms/lib/v1";
import {
  RPMReport,
  SubmitManualTCM,
  TCMCodesResponse,
  TCMDischargeEntry,
  TCMReport,
} from "@veris-health/communication-ms/lib/v1";
import axios from "axios";
import dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";
import { RootState } from "../../store";
import { Status } from "../shared/interfaces";
import {
  addCareTeamNote,
  addClinicalNote,
  fetchCareTeamNotes,
  fetchClinicalNotes,
  fetchPatientDetails,
  fetchPatientGoals,
  getWeightTrend,
  getLatestWeight,
  updateCareTeamNote,
  getPatientsMainOncologist,
  getRPMEntries,
  listTCMBillings,
  listTCMCodes,
  addTCMBilling,
} from "./api/patientDetailsApi";
import { extractErrorMessage } from "../shared/helpers";

type PatientFiniteGoal = GoalFiniteResponse & { uuid: string };
type PatientRecurringGoal = GoalRecurringResponse & { uuid: string };

export type GoalsType = PatientFiniteGoal | PatientRecurringGoal;

export interface PatientDetailsState {
  patientDetails: {
    details: EntityState<Demographics>;
    status: Status;
  };
  patientMainOncologist: {
    mainOncologist: EntityState<{ doctor: UserInfoBaseResponse }>;
    status: Status;
  };
  patientCareTeamNotes: {
    careTeamNotes: EntityState<{ notes: CareTeamNote[] }>;
    status: Status;
  };
  clinicalNotes: {
    notes: EntityState<{ notes: ClinicalNote[] }>;
    status: Status;
    nextPageToken?: string;
  };
  patientGoals: {
    data: EntityState<{ goals: GoalsType[] }>;
    status: Status;
  };
  patientWeightTrend: {
    data: EntityState<{ weight: WeightTrendPeriodView[] }>;
    status: Status;
    errorMessage?: string;
  };
  patientRpmList: {
    data: EntityState<{ previousMonthReport: RPMReport; currentMonthReport: RPMReport }>;
    status: Status;
  };
  patientTCMReport: {
    data: EntityState<TCMReport>;
    status: Status;
  };
  TCMBillingCodes: TCMCodesResponse;
  status: Status;
}

const patientsDetailsAdapter = createEntityAdapter<Demographics>();
const mainOncologistAdapter = createEntityAdapter<{ doctor: UserInfoBaseResponse }>();
const patientCareTeamNotesAdapter = createEntityAdapter<{ notes: CareTeamNote[] }>();
const patientClinicalNotesAdapter = createEntityAdapter<{ notes: ClinicalNote[] }>();
const patientGoalsAdapter = createEntityAdapter<{ goals: GoalsType[] }>();
const patientWeightTrendAdapter = createEntityAdapter<{ weight: WeightTrendPeriodView[] }>();
const rpmItemsAdapter =
  createEntityAdapter<{ previousMonthReport: RPMReport; currentMonthReport: RPMReport }>();
const tcmReportAdapter = createEntityAdapter<TCMReport>();

const initialState: PatientDetailsState = {
  patientDetails: {
    details: patientsDetailsAdapter.getInitialState(),
    status: "idle",
  },
  patientMainOncologist: {
    mainOncologist: mainOncologistAdapter.getInitialState(),
    status: "idle",
  },
  patientCareTeamNotes: {
    careTeamNotes: patientCareTeamNotesAdapter.getInitialState(),
    status: "idle",
  },
  clinicalNotes: {
    notes: patientClinicalNotesAdapter.getInitialState(),
    status: "idle",
  },
  patientGoals: {
    data: patientGoalsAdapter.getInitialState(),
    status: "idle",
  },
  patientWeightTrend: {
    data: patientWeightTrendAdapter.getInitialState(),
    status: "idle",
  },
  patientRpmList: {
    status: "idle",
    data: rpmItemsAdapter.getInitialState(),
  },
  patientTCMReport: {
    status: "idle",
    data: tcmReportAdapter.getInitialState(),
  },
  TCMBillingCodes: {},
  status: "loading",
};

export const loadPatientLatestWeightAsync = createAsyncThunk(
  "patientDetails/loadPatientLatestWeightAsync",
  async ({ id }: { id: number }) => {
    try {
      const response = await getLatestWeight(id);
      return { id, weight: response };
    } catch (err) {
      if (axios.isAxiosError(err)) {
        throw err.response?.data?.detail[0]?.msg;
      }
      throw err;
    }
  },
);

export const loadPatientDetailsAsync = createAsyncThunk(
  "patientDetails/fetchPatientDetails",
  async ({ id, isMioUser }: { id: number; isMioUser?: boolean }, { dispatch }) => {
    const languageLabel = {
      [Language.En]: "English",
      [Language.Es]: "Spanish",
    };
    const response = await fetchPatientDetails(id);
    const formattedResponse: Demographics = {
      ...response,
      lang: response.lang ? (languageLabel[response.lang] as Language) : undefined,
    };
    if (isMioUser) {
      dispatch(loadPatientLatestWeightAsync({ id }));
    }
    return {
      id,
      ...formattedResponse,
    };
  },
);

export const loadCareTeamNotesAsync = createAsyncThunk(
  "patientDetails/fetchCareTeamNotes",
  async ({ id }: { id: number }) => {
    const response = await fetchCareTeamNotes(id);
    return { id, notes: response };
  },
);
export const loadClinicalNotesAsync = createAsyncThunk(
  "patientDetails/fetchClinicalNotes",
  async ({ id }: { id: number }) => {
    const response = await fetchClinicalNotes(id);
    return { data: { id, notes: response.notes }, token: response.next_page_token };
  },
);
export const updateClinicalNoteAsync = createAsyncThunk(
  "patientDetails/updateClinicalNote",
  async ({ id, noteId, note }: { id: number; noteId: number; note: SetCareTeamNote }) =>
    updateCareTeamNote(id, noteId, note),
);

export const addClinicalNoteAsync = createAsyncThunk(
  "patientDetails/addClinicalNote",
  async ({ id, note }: { id: number; note: NewClinicalNote }) => {
    const response = await addClinicalNote(id, note);
    return { id, note: response };
  },
);

export const addCareTeamNoteAsync = createAsyncThunk(
  "patientDetails/addCareTeamNote",
  async ({ id, note }: { id: number; note: SetCareTeamNote }) => {
    const response = await addCareTeamNote(id, note);
    return { id, note: response };
  },
);
export const updateCareTeamNoteAsync = createAsyncThunk(
  "patientDetails/updateCareTeamNote",
  async ({ id, noteId, note }: { id: number; noteId: number; note: SetCareTeamNote }) =>
    updateCareTeamNote(id, noteId, note),
);

export const loadRpmReviewItemsAsync = createAsyncThunk(
  "patientDetails/loadRpmReviewItemsAsync",
  async (id: number) => {
    const currentMonthReport = await getRPMEntries(id, new Date().toUTCString(), true);
    const previousMonth = dayjs().subtract(1, "month").toISOString();
    const previousMonthReport = await getRPMEntries(id, previousMonth, true);
    return { id, currentMonthReport, previousMonthReport };
  },
);

export const loadPatientGoalsAsync = createAsyncThunk(
  "patientDetails/fetchPatientGoals",
  async ({ id }: { id: number }) => {
    const response = await fetchPatientGoals(id);
    const formattedData = response.map((goal) => {
      return {
        ...goal,
        uuid: uuidv4(),
      };
    });
    return { id, goals: formattedData };
  },
);

export const loadPatientWeightTrendAsync = createAsyncThunk(
  "patientDetails/fetchWeightTrend",
  async ({
    id,
    period,
    size,
  }: {
    id: number;
    period: WeightTrendLookbackPeriod;
    size: WeightTrendAggregationWindowSize;
  }) => {
    try {
      const response = await getWeightTrend(id, period, size);

      return { id, weight: response.data || [] };
    } catch (err) {
      if (axios.isAxiosError(err)) {
        throw err.response?.data?.detail[0]?.msg;
      }
      throw err;
    }
  },
);

export const getPatientsMainOncologistAsync = createAsyncThunk(
  "patientDetails/getMainOncologist",
  async ({ id }: { id: number }) => {
    const response = await getPatientsMainOncologist(id);
    return { id, doctor: response };
  },
);

export const loadTCMBillingReportAsync = createAsyncThunk(
  "patientDetails/loadTCMBillingReportAsync",
  async ({ id }: { id: number }) => {
    const response = await listTCMBillings(id);
    return { id, ...response };
  },
);

export const loadTCMBillingCodes = createAsyncThunk(
  "patientDetails/loadTCMBillingCodes",
  async () => {
    const response = await listTCMCodes();
    return response;
  },
);

export const addTCMBillingAsync = createAsyncThunk(
  "patientDetails/addTCMBillingAsync",
  async ({ id, payload }: { id: number; payload: SubmitManualTCM }, { rejectWithValue }) => {
    try {
      const response = await addTCMBilling(id, payload);
      return response;
    } catch (err) {
      const errorMsg = extractErrorMessage(err);
      return rejectWithValue(errorMsg || "Could not create task.");
    }
  },
);

export const patientDetailsSlice = createSlice({
  name: "patientDetails",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadPatientDetailsAsync.pending, (state) => {
        state.patientDetails.status = "loading";
      })
      .addCase(loadPatientDetailsAsync.fulfilled, (state, action) => {
        patientsDetailsAdapter.upsertOne(state.patientDetails.details, action.payload);
        state.patientDetails.status = "idle";
      })
      .addCase(loadPatientDetailsAsync.rejected, (state) => {
        state.patientDetails.status = "failed";
      })
      .addCase(loadCareTeamNotesAsync.pending, (state) => {
        state.patientCareTeamNotes.status = "loading";
      })
      .addCase(loadCareTeamNotesAsync.fulfilled, (state, action) => {
        state.patientCareTeamNotes.status = "idle";
        patientCareTeamNotesAdapter.upsertOne(
          state.patientCareTeamNotes.careTeamNotes,
          action.payload,
        );
      })
      .addCase(loadCareTeamNotesAsync.rejected, (state) => {
        state.patientCareTeamNotes.status = "failed";
      })
      .addCase(loadClinicalNotesAsync.pending, (state) => {
        state.clinicalNotes.status = "loading";
      })
      .addCase(loadClinicalNotesAsync.fulfilled, (state, action) => {
        state.clinicalNotes.status = "idle";
        patientClinicalNotesAdapter.upsertOne(state.clinicalNotes.notes, action.payload.data);
        state.clinicalNotes.nextPageToken = action.payload.token;
      })
      .addCase(loadClinicalNotesAsync.rejected, (state) => {
        state.clinicalNotes.status = "failed";
      })
      .addCase(addClinicalNoteAsync.fulfilled, (state, action) => {
        const existingNotes = patientClinicalNotesAdapter
          .getSelectors()
          .selectById(state.clinicalNotes.notes, action.payload.id);
        if (existingNotes) {
          patientClinicalNotesAdapter.updateOne(state.clinicalNotes.notes, {
            id: action.payload.id,
            changes: {
              notes: [action.payload.note, ...existingNotes.notes],
            },
          });
        }
      })
      .addCase(addCareTeamNoteAsync.fulfilled, (state, action) => {
        const existingNotes = patientCareTeamNotesAdapter
          .getSelectors()
          .selectById(state.patientCareTeamNotes.careTeamNotes, action.payload.id);
        if (existingNotes) {
          patientCareTeamNotesAdapter.updateOne(state.patientCareTeamNotes.careTeamNotes, {
            id: action.payload.id,
            changes: {
              notes: [action.payload.note, ...existingNotes.notes],
            },
          });
        }
      })
      .addCase(updateCareTeamNoteAsync.fulfilled, (state, action) => {
        const existingNotes = patientCareTeamNotesAdapter
          .getSelectors()
          .selectById(state.patientCareTeamNotes.careTeamNotes, action.payload.patient_id);

        if (existingNotes) {
          const updatedArray = [
            action.payload,
            ...existingNotes.notes.filter((note) => action.payload.id !== note.id),
          ];

          patientCareTeamNotesAdapter.updateOne(state.patientCareTeamNotes.careTeamNotes, {
            id: action.payload.patient_id,
            changes: {
              notes: updatedArray,
            },
          });
        }
      })
      .addCase(loadPatientGoalsAsync.pending, (state) => {
        state.patientGoals.status = "loading";
      })
      .addCase(loadPatientGoalsAsync.fulfilled, (state, action) => {
        state.patientGoals.status = "idle";
        patientGoalsAdapter.upsertOne(state.patientGoals.data, action.payload);
      })
      .addCase(loadPatientGoalsAsync.rejected, (state) => {
        state.patientGoals.status = "failed";
      })
      .addCase(getPatientsMainOncologistAsync.pending, (state) => {
        state.patientMainOncologist.status = "loading";
      })
      .addCase(getPatientsMainOncologistAsync.fulfilled, (state, action) => {
        mainOncologistAdapter.upsertOne(state.patientMainOncologist.mainOncologist, action.payload);
        state.patientMainOncologist.status = "idle";
      })
      .addCase(getPatientsMainOncologistAsync.rejected, (state) => {
        state.patientMainOncologist.status = "failed";
      })
      .addCase(loadPatientWeightTrendAsync.pending, (state) => {
        state.patientWeightTrend.status = "loading";
        state.patientWeightTrend.errorMessage = undefined;
      })
      .addCase(loadPatientLatestWeightAsync.fulfilled, (state, { payload }) => {
        if (state.patientDetails.details.ids.includes(payload.id))
          patientsDetailsAdapter.updateOne(state.patientDetails.details, {
            id: payload.id,
            changes: {
              weight: payload.weight.wt ? `${payload.weight.wt}` : undefined,
            },
          });
      })
      .addCase(loadPatientWeightTrendAsync.fulfilled, (state, action) => {
        state.patientWeightTrend.status = "idle";
        const currentWeight = patientWeightTrendAdapter
          .getSelectors()
          .selectById(state.patientWeightTrend.data, action.payload.id);

        if (action.payload.weight)
          if (currentWeight) {
            patientWeightTrendAdapter.updateOne(state.patientWeightTrend.data, {
              id: action.payload.id,
              changes: {
                weight: action.payload.weight,
              },
            });
          } else patientWeightTrendAdapter.addOne(state.patientWeightTrend.data, action.payload);
      })
      .addCase(loadPatientWeightTrendAsync.rejected, (state, { error }) => {
        state.patientWeightTrend.status = "failed";
        // BE returning 404 for [] weight trend
        if (error.message === "Missing weight data") {
          state.patientWeightTrend.errorMessage = error.message;
        } else {
          state.patientWeightTrend.errorMessage = undefined;
        }
      })
      .addCase(loadRpmReviewItemsAsync.pending, (state) => {
        state.patientRpmList.status = "loading";
      })
      .addCase(loadRpmReviewItemsAsync.fulfilled, (state, { payload }) => {
        state.patientRpmList.status = "idle";
        if (state.patientRpmList.data.ids.includes(payload.id))
          rpmItemsAdapter.updateOne(state.patientRpmList.data, {
            id: payload.id,
            changes: payload,
          });
        else rpmItemsAdapter.setOne(state.patientRpmList.data, payload);
      })
      .addCase(loadRpmReviewItemsAsync.rejected, (state) => {
        state.patientRpmList.status = "failed";
      })
      .addCase(loadTCMBillingReportAsync.pending, (state) => {
        state.patientTCMReport.status = "loading";
      })
      .addCase(loadTCMBillingReportAsync.fulfilled, (state, { payload }) => {
        if (state.patientTCMReport.data.ids.includes(payload.id)) {
          tcmReportAdapter.updateOne(state.patientTCMReport.data, {
            id: payload.id,
            changes: payload,
          });
        } else {
          tcmReportAdapter.setOne(state.patientTCMReport.data, payload);
        }
        state.patientTCMReport.status = "idle";
      })
      .addCase(loadTCMBillingReportAsync.rejected, (state) => {
        state.patientTCMReport.status = "failed";
      })

      .addCase(loadTCMBillingCodes.pending, (state) => {
        state.patientTCMReport.status = "loading";
      })
      .addCase(loadTCMBillingCodes.fulfilled, (state, { payload }) => {
        state.TCMBillingCodes = payload;
        state.patientTCMReport.status = "idle";
      })
      .addCase(loadTCMBillingCodes.rejected, (state) => {
        state.patientTCMReport.status = "failed";
      });
  },
});

export const selectPatientDetails = (
  rootState: RootState,
  id: number | undefined,
): Demographics | undefined => {
  if (id) {
    return patientsDetailsAdapter
      .getSelectors<RootState>((state) => state.patientDetails.patientDetails.details)
      .selectById(rootState, id);
  }
  return undefined;
};

export const selectPatientMainOncologist = (
  rootState: RootState,
  id: number | undefined,
): UserInfoBaseResponse | undefined => {
  if (id) {
    return mainOncologistAdapter
      .getSelectors<RootState>((state) => state.patientDetails.patientMainOncologist.mainOncologist)
      .selectById(rootState, id)?.doctor;
  }
  return undefined;
};

export const selectPatientDetailsStatus = ({ patientDetails }: RootState): Status =>
  patientDetails.patientDetails.status;

export const selectCareTeamNotes = (
  rootState: RootState,
  id: number | undefined,
): CareTeamNote[] | undefined => {
  if (id) {
    return (
      patientCareTeamNotesAdapter
        .getSelectors<RootState>((state) => state.patientDetails.patientCareTeamNotes.careTeamNotes)
        .selectById(rootState, id)?.notes || []
    );
  }
  return undefined;
};

export const selectClinicalNotes = (
  rootState: RootState,
  id: number | undefined,
): ClinicalNote[] | undefined => {
  if (id) {
    return (
      patientClinicalNotesAdapter
        .getSelectors<RootState>((state) => state.patientDetails.clinicalNotes.notes)
        .selectById(rootState, id)?.notes || []
    );
  }
  return undefined;
};

export const selectClinicalNotesStatus = ({ patientDetails }: RootState): Status =>
  patientDetails.clinicalNotes.status;

export const selectCareTeamNotesStatus = ({ patientDetails }: RootState): Status =>
  patientDetails.patientCareTeamNotes.status;

export const selectPatientGoals = (
  rootState: RootState,
  id: number | undefined,
): GoalsType[] | undefined => {
  if (id) {
    return (
      patientGoalsAdapter
        .getSelectors<RootState>((state) => state.patientDetails.patientGoals.data)
        .selectById(rootState, id)?.goals || []
    );
  }
  return undefined;
};

export const selectPatientWeightTrend = (
  rootState: RootState,
  id: number,
): WeightTrendPeriodView[] | [] => {
  return (
    patientWeightTrendAdapter
      .getSelectors<RootState>((state) => state.patientDetails.patientWeightTrend.data)
      .selectById(rootState, id)?.weight || []
  );
};

export const selectWeightTrendStatus = ({ patientDetails }: RootState): Status =>
  patientDetails.patientWeightTrend.status;

export const selectWeightTrendErrorMessage = ({ patientDetails }: RootState): string | undefined =>
  patientDetails.patientWeightTrend.errorMessage;

export const selectPatientGoalsStatus = ({ patientDetails }: RootState): Status =>
  patientDetails.patientGoals.status;

export const selectPatientRpmItems = (rootState: RootState, id: number) =>
  rpmItemsAdapter
    .getSelectors<RootState>((state) => state.patientDetails.patientRpmList.data)
    .selectById(rootState, id);

export const selectPatientRpmItemsStatus = ({ patientDetails }: RootState): Status =>
  patientDetails.patientRpmList.status;

export const selectPatientTcmReport = (rootState: RootState, id: number): TCMDischargeEntry[] => {
  const report = tcmReportAdapter
    .getSelectors<RootState>((state) => state.patientDetails.patientTCMReport.data)
    .selectById(rootState, id);

  return [...(report?.manual || []), ...(report?.system || [])];
};

export const selectTCMBillingCodes = ({ patientDetails }: RootState) =>
  patientDetails.TCMBillingCodes.tcm_codes;

export const selectTCMReportStatus = ({ patientDetails }: RootState) =>
  patientDetails.patientTCMReport.status;

export default patientDetailsSlice.reducer;
