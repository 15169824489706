import {
  DeviceManager,
  LocalVideoStream,
  VideoStreamRendererView,
} from "@azure/communication-calling";
import {
  StreamMedia,
  VideoTile,
  ControlBar,
  CameraButton,
  MicrophoneButton,
  FluentThemeProvider,
  useTheme,
  VideoTileStylesProps,
  usePropsFor,
  DevicesButton,
} from "@azure/communication-react";
import { Stack, mergeStyles, Text, ITheme } from "@fluentui/react";
import { Grid, Typography } from "@mui/material";
import { VrsButton, VrsSelect } from "@veris-health/web-core";
import React, { useEffect, useState } from "react";
import { useVideo } from "../../../../context/video";

export interface LocalPreviewProps {
  startCall: ({ audio, video }: { audio: boolean; video?: LocalVideoStream }) => void;
  isVideoAvailable?: boolean;
  isCameraEnabled?: boolean;
  isMicrophoneEnabled?: boolean;
}

const cameraOffLabelMergedStyles = (theme: ITheme): string =>
  mergeStyles({
    fontFamily: "Segoe UI Regular",
    fontSize: "0.625rem",
    color: theme.palette.neutralTertiary,
  });

const CameraOffPlaceholder = (): JSX.Element => {
  const theme = useTheme();
  return (
    <Stack style={{ width: "100\u0025", height: "100\u0025" }} verticalAlign="center">
      <Stack.Item align="center">
        <Text className={cameraOffLabelMergedStyles(theme)}>Your camera is turned off</Text>
      </Stack.Item>
    </Stack>
  );
};

const videoTileStyle: VideoTileStylesProps = {
  root: {
    minHeight: "14rem",
  },
};

const LocalVideoElement = ({
  localStream,
}: {
  localStream: LocalVideoStream;
}): JSX.Element | null => {
  const videoProps = useVideo();
  const [videoStream, setVideoStream] = useState<VideoStreamRendererView | undefined>(undefined);

  useEffect(() => {
    const handleStartVideo = async () => {
      if (videoProps.statefulCallClient) {
        const view = await videoProps.statefulCallClient.createView(
          undefined,
          undefined,
          localStream,
        );
        setVideoStream(view?.view);
      }
    };
    handleStartVideo();
  }, [localStream]);

  useEffect(
    () => () => {
      if (videoProps.statefulCallClient && localStream) {
        videoProps.statefulCallClient.disposeView(undefined, undefined, localStream);
      }
    },
    [localStream],
  );

  if (videoStream) {
    return <StreamMedia videoStreamElement={videoStream.target} />;
  }
  return null;
};

export const VideoLobby = ({ startCall }: LocalPreviewProps): JSX.Element => {
  const [deviceManager, setDeviceManager] = useState<DeviceManager>();
  const [isMicOn, setIsMicOne] = useState(true);
  const [isCameraOn, setIsCameraOn] = useState(true);
  const theme = useTheme();
  const videoProps = useVideo();
  const devicesProps = usePropsFor(DevicesButton);
  const [localStream, setLocalStream] = useState<LocalVideoStream | undefined>();

  useEffect(() => {
    const getDevices = async () => {
      if (videoProps.statefulCallClient) {
        const newDeviceManager = await videoProps.statefulCallClient.getDeviceManager();
        await newDeviceManager.askDevicePermission({
          audio: true,
          video: true,
        });
        setDeviceManager(newDeviceManager);
      }
    };
    getDevices();
  }, []);

  useEffect(() => {
    if (devicesProps.selectedCamera) {
      const newLocalStream = new LocalVideoStream(devicesProps.selectedCamera);
      setLocalStream(newLocalStream);
    }
  }, [devicesProps.selectedCamera]);

  const micOptions = devicesProps.microphones.map((cam) => {
    return {
      label: cam.name,
      value: cam.name,
    };
  });
  const speakerOptions = devicesProps.speakers.map((cam) => {
    return {
      label: cam.name,
      value: cam.name,
    };
  });

  return (
    <FluentThemeProvider fluentTheme={theme}>
      <Grid
        container
        sx={{ padding: "10px", justifyContent: "space-evenly", alignItems: "center" }}
      >
        <Grid item sx={{ width: "400px", height: "250px" }}>
          <VideoTile
            styles={videoTileStyle}
            renderElement={
              localStream && isCameraOn ? <LocalVideoElement localStream={localStream} /> : null
            }
            onRenderPlaceholder={() => <CameraOffPlaceholder />}
          >
            <ControlBar layout="floatingBottom">
              <CameraButton
                disabled={false}
                checked={isCameraOn}
                onClick={() => setIsCameraOn(!isCameraOn)}
              />
              <MicrophoneButton
                disabled={false}
                checked={isMicOn}
                onClick={() => setIsMicOne(!isMicOn)}
              />
            </ControlBar>
          </VideoTile>
        </Grid>
        <Grid item>
          <Typography sx={{ marginTop: "10px" }}>Sound</Typography>
          <VrsSelect
            options={micOptions}
            onSelected={(value) => {
              if (deviceManager) {
                const newMic = devicesProps.microphones.find((speaker) => speaker.name === value);
                if (newMic) deviceManager.selectMicrophone(newMic);
              }
            }}
            value={devicesProps.selectedMicrophone?.name}
            outerSx={{ marginTop: "5px" }}
            innerSx={{ marginTop: "5px" }}
          />
          <VrsSelect
            options={speakerOptions}
            onSelected={(value) => {
              if (deviceManager) {
                const newSpeaker = devicesProps.speakers.find((speaker) => speaker.name === value);
                if (newSpeaker) deviceManager.selectSpeaker(newSpeaker);
              }
            }}
            value={devicesProps.selectedSpeaker?.name}
            outerSx={{ marginTop: "5px" }}
            innerSx={{ marginTop: "5px" }}
          />
          <VrsButton
            buttonType="primary"
            onClick={() =>
              startCall({ video: isCameraOn ? localStream : undefined, audio: !isMicOn })
            }
            sx={{ marginTop: "10px" }}
          >
            Start Call
          </VrsButton>
        </Grid>
      </Grid>
    </FluentThemeProvider>
  );
};
