import * as React from "react";
import dayjs from "dayjs";
import { Box, CircularProgress, Typography } from "@mui/material";
import { VrsIconButton, IconName, VrsErrorMsg, dateFormats } from "@veris-health/web-core";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { ReportAnswersList } from "../../Notifications/ReportAnswersList";
import { selectSymptomDetails, selectSymptomDetailsStatus } from "../patientDetailsSidebarSlice";
import { selectExpandedSymptomDetails } from "../../shared/slices/uiSlice";
import { VrsDrawer } from "../../../ui/components/VrsDrawer";
import { formatReportAnswer } from "../../Notifications/utils";
import { utcToLocal } from "../../../utils/date";

export interface SymptomPreviewSidebarProps {
  isSmallScreen: boolean;
  onCloseHandler: () => void;
  isSymptomDetailsExpanded: boolean;
  errorHandler: () => void;
  onReviewingSymptom: () => void;
}

export function SymptomPreviewSidebar({
  onCloseHandler,
  isSymptomDetailsExpanded,
  errorHandler,
  onReviewingSymptom,
}: SymptomPreviewSidebarProps): JSX.Element {
  const symptomDetails = useAppSelector(selectSymptomDetails);
  const symptomDetailsStatus = useAppSelector(selectSymptomDetailsStatus);
  const expandedSymptomDetails = useAppSelector(selectExpandedSymptomDetails);

  React.useEffect(() => {
    if (symptomDetailsStatus === "idle" && symptomDetails && !symptomDetails.is_reviewed) {
      onReviewingSymptom();
    }
  }, [symptomDetails, symptomDetailsStatus]);

  return (
    <VrsDrawer open={isSymptomDetailsExpanded} onClose={onCloseHandler}>
      {symptomDetailsStatus === "loading" && (
        <Box display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      )}
      {symptomDetailsStatus === "idle" && (
        <>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            mb={3}
          >
            {symptomDetails.is_user_defined ? (
              <Typography
                variant="h6Semibold"
                color={(theme) => theme.veris.colors.amethyst.normal}
                sx={{
                  textTransform: "capitalize",
                  wordBreak: "break-word",
                  whiteSpace: "break-spaces",
                }}
              >
                Details • {symptomDetails.symptom.label} •{" "}
                {dayjs(expandedSymptomDetails?.timestamp).format(dateFormats["ddd MMM D, YYYY"])}
              </Typography>
            ) : (
              <Typography
                variant="h6Semibold"
                color={(theme) => theme.veris.colors.amethyst.normal}
                sx={{
                  textTransform: "capitalize",
                  wordBreak: "break-word",
                  whiteSpace: "break-spaces",
                }}
              >
                Q&A • {symptomDetails.symptom.value?.replaceAll("-", " ")}-{" "}
                {expandedSymptomDetails?.severity} •{" "}
                {expandedSymptomDetails?.timestamp &&
                  utcToLocal(expandedSymptomDetails?.timestamp).format(
                    dateFormats["ddd MMM D, YYYY"],
                  )}
              </Typography>
            )}
            <VrsIconButton iconProps={{ name: IconName.CloseIcon }} onClick={onCloseHandler} />
          </Box>
          {symptomDetails.is_user_defined ? (
            <Box>
              <Box mb={3}>
                <Typography
                  mb={1}
                  variant="subtitle1"
                  color={(theme) => theme.veris.colors.neutrals["grey-mid"]}
                >
                  Severity
                </Typography>
                <Typography variant="body" color={(theme) => theme.veris.colors.neutrals["grey-4"]}>
                  {formatReportAnswer(
                    symptomDetails.report[2].answer,
                    symptomDetails.report[2].questionType,
                  )}
                </Typography>
              </Box>
              <Box mb={3}>
                <Typography
                  mb={1}
                  variant="subtitle1"
                  color={(theme) => theme.veris.colors.neutrals["grey-mid"]}
                >
                  Description
                </Typography>
                <Typography
                  variant="body"
                  color={(theme) => theme.veris.colors.neutrals["grey-4"]}
                  sx={{ wordBreak: "break-word", whiteSpace: "break-spaces" }}
                >
                  {formatReportAnswer(
                    symptomDetails.report[1].answer,
                    symptomDetails.report[1].questionType,
                  )}
                </Typography>
              </Box>
            </Box>
          ) : (
            <ReportAnswersList
              reportQAList={symptomDetails.report}
              isSymptomPreview
              reportName={symptomDetails.symptom.value}
            />
          )}
        </>
      )}
      {symptomDetailsStatus === "failed" && (
        <>
          <Box display="flex" justifyContent="end">
            <VrsIconButton iconProps={{ name: IconName.CloseIcon }} onClick={onCloseHandler} />
          </Box>

          <VrsErrorMsg size="small" onClick={errorHandler} />
        </>
      )}
    </VrsDrawer>
  );
}
