import React from "react";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { GetAppointmentResponse } from "@veris-health/med-data-ms/lib/v1";
import { VrsButton, VrsErrorMsg } from "@veris-health/web-core";
import { VrsPatientAppointmentDetails } from "../VrsPatientAppointmentDetails";
import { appointmentEnumToDisplay } from "../../../features/shared/slices/api/calendarApi";
import { Status, VrsPatientInfo } from "../../../features/shared/interfaces";

export interface VrsTreatmentPlanProps {
  patientDetails: VrsPatientInfo;
  nextTreatments: GetAppointmentResponse[];
  width?: string;
  onAppointmentsClick?: () => void;
  nextTreatmentsStatus?: Status;
  onScheduleClick?: () => void;
}

export function VrsTreatmentPlan({
  width,
  patientDetails,
  nextTreatments,
  onAppointmentsClick,
  nextTreatmentsStatus,
  onScheduleClick,
}: VrsTreatmentPlanProps): JSX.Element {
  const [nextAppointment] = nextTreatments;
  return (
    <Box
      mb={1}
      sx={{
        borderRadius: 2,
        border: (theme) => `1px solid ${theme.veris.colors.neutrals["grey-light"]}`,
        flexGrow: 1,
        background: (theme) => theme.veris.colors.neutrals.white,
      }}
      padding={(theme) => theme.spacing(0.5)}
      width={width}
      data-test-hook="vrsTreatmentPlan-box"
    >
      <Grid
        display="flex"
        justifyContent="space-between"
        alignItems="flex-end"
        padding={(theme) => theme.spacing(0.75, 0.5)}
      >
        <Typography
          color={(theme) => theme.veris.colors.neutrals["grey-dark"]}
          height="1.25rem"
          variant="body1"
        >
          TREATMENT PLAN
        </Typography>
        <VrsButton
          buttonType="quaternary"
          onClick={onScheduleClick}
          sx={{ padding: (theme) => `${theme.spacing(0.5)} ${theme.spacing(1)}` }}
          data-test-hook="vrsTreatmentPlan-schedule-button"
          disabled={patientDetails.isInactiveOrDeceased}
        >
          <Typography variant="caption" pt={0.25}>
            Schedule
          </Typography>
        </VrsButton>
      </Grid>
      <Box padding={(theme) => theme.spacing(0.75, 1)} overflow="auto">
        <Box paddingY={(theme) => theme.spacing(1.25)}>
          {nextTreatmentsStatus === "loading" && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              data-test-hook="vrsTreatmentPlan-loading"
            >
              <CircularProgress />
            </Box>
          )}
          {nextTreatmentsStatus === "failed" && (
            <VrsErrorMsg size="small" onClick={onAppointmentsClick} />
          )}
          {nextTreatmentsStatus === "idle" && (
            <Box paddingBottom={(theme) => theme.spacing(1)}>
              <Typography
                variant="subtitle1"
                color={(theme) => theme.veris.colors.neutrals["grey-dark"]}
              >
                Next Appointment
              </Typography>
            </Box>
          )}
          {nextTreatmentsStatus === "idle" && !nextAppointment && (
            <Typography
              variant="body"
              color={(theme) => theme.veris.colors.neutrals["grey-3"]}
              p={2}
              sx={{
                borderColor: (theme) => theme.veris.colors.neutrals["grey-light"],
                borderStyle: "solid",
                borderWidth: "1px 0px 0px 0px",
                paddingLeft: 0,
                textAlign: "left",
              }}
            >
              There are no scheduled appointments
            </Typography>
          )}
          {nextTreatmentsStatus === "idle" && nextAppointment && (
            <VrsPatientAppointmentDetails
              appointmentDateTime={nextAppointment.startTime || ""}
              appointmentLocation={
                nextAppointment.type && appointmentEnumToDisplay[nextAppointment.type]
              }
              {...patientDetails}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
}
