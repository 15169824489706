import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { HospitalMedStaff, TagItem } from "@veris-health/user-ms/lib/v1";
import { flatten, uniqBy } from "lodash";
import { Status } from "../shared/interfaces";
import { getHospitalTags, getRelatedStaff } from "./api/hospitalsApi";
import { RootState } from "../../store";
import { selectCurrentHospital } from "../shared/slices/patientsSlice";

interface HospitalState {
  status: Status;
  hospitalMedStaff: HospitalMedStaff[];
  hospitalTags: TagItem[];
}

const initialState: HospitalState = {
  status: "idle",
  hospitalMedStaff: [],
  hospitalTags: [],
};

export const getRelatedHospitalStaffAsync = createAsyncThunk(
  "hospitals/getRelatedStaff",
  async (userId: number) => {
    const response = await getRelatedStaff(userId);
    return response;
  },
);

export const getHospitalTagsAsync = createAsyncThunk(
  "hospitals/getHospitalTags",
  async (hospitalId?: number) => {
    const response = await getHospitalTags(hospitalId);
    return response;
  },
);

const hospitalSlice = createSlice({
  name: "Hospitals",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getRelatedHospitalStaffAsync.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(getRelatedHospitalStaffAsync.fulfilled, (state, { payload }) => {
      state.status = "idle";
      state.hospitalMedStaff = payload;
    });
    builder.addCase(getRelatedHospitalStaffAsync.rejected, (state) => {
      state.status = "failed";
    });
    builder.addCase(getHospitalTagsAsync.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(getHospitalTagsAsync.fulfilled, (state, { payload }) => {
      state.status = "idle";
      state.hospitalTags = payload.items;
    });
    builder.addCase(getHospitalTagsAsync.rejected, (state) => {
      state.status = "failed";
    });
  },
});

export const selectHospitalStatus = ({ hospitals }: RootState): Status => hospitals.status;

export const selectHospitalStaff = ({ hospitals }: RootState): HospitalMedStaff[] =>
  hospitals.hospitalMedStaff;

export const selectedRelatedStaffByHospital = createSelector(
  [selectHospitalStaff, selectCurrentHospital],
  (hospitals, activeHospital) => {
    const activeHospitals =
      activeHospital && activeHospital !== "All Hospitals"
        ? hospitals.filter((hospital) => hospital.id === +activeHospital)
        : hospitals;
    return uniqBy(
      flatten(activeHospitals.map((hospital) => hospital.med_staff)),
      (staff) => staff.id,
    );
  },
);

export const selectHospitalTags = ({ hospitals }: RootState): TagItem[] => hospitals.hospitalTags;

export default hospitalSlice.reducer;
