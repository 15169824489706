import React, { useEffect, useState } from "react";
import { styled, Theme, CSSObject } from "@mui/material/styles";
import { useHistory, useLocation } from "react-router-dom";
import MuiDrawer from "@mui/material/Drawer";
import { drawerClasses, ListItem, Typography, useMediaQuery, Link } from "@mui/material";
import Box from "@mui/material/Box";
import { get } from "lodash";
import { VrsIconButton, IconName, VrsLogo, VrsAvatar, VrsIcon } from "@veris-health/web-core";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import {
  toggleLeftSideBar,
  selectToggleLeftSideBar,
  selectTogglePreviewBar,
} from "../shared/slices/uiSlice";
import { replaceRouteParam } from "../../routes-config";
import { sideBarMenu } from "../../constants";
import { SideBarNavigationItem, SideBarSectionTitle, SidebarToggle } from "./components";
import { AutoCompleteOption, VrsSearchBar } from "../../ui/components/VrsSearchBar";
import { selectAvatarById, selectVrsPatientsSearchResult } from "../shared/slices/patientsSlice";
import { VrsPatientInfo } from "../shared/interfaces";
import { useCurrentPatient } from "../../hooks/useCurrentPatient";

export interface VrsLeftSidebarContainerProps {
  openPreview?: boolean;
  path?: string;
}

type LeftSidebarItemProps = {
  expanded: boolean;
};

const sidebarDrawerWidth = 300;

const openedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: sidebarDrawerWidth,
  padding: theme.spacing(3.75, 2.25),
  overflow: "hidden",
  ":hover": {
    overflowY: "auto",
  },
  [theme.breakpoints.down("lg")]: {
    position: "fixed",
    zIndex: 1203,
    width: sidebarDrawerWidth,
  },
});

const openedPreviewMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: sidebarDrawerWidth,
  position: "fixed",
  zIndex: 1203,
  padding: theme.spacing(3.75, 2.25),
  overflow: "hidden",
  ":hover": {
    overflowY: "auto",
  },
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: `calc(${theme.spacing(10.5)} + 1px)`,
  [theme.breakpoints.down("lg")]: {
    width: `calc(${theme.spacing(12.9)} + 1px)`,
  },
  padding: theme.spacing(3.75, 2.25),
  overflow: "hidden",
  ":hover": {
    overflowY: "auto",
  },
});

const DrawerFooter = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0),
  ...theme.mixins.toolbar,
  [theme.breakpoints.down("xl")]: {
    minHeight: "40px",
  },
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop: string) => prop !== "openPreview",
})<VrsLeftSidebarContainerProps>(({ theme, open, openPreview, path }) => ({
  width: sidebarDrawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    [`& .${drawerClasses.paper}`]: openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    [`& .${drawerClasses.paper}`]: closedMixin(theme),
  }),
  ...(open &&
    openPreview &&
    (path?.split("/").includes("patients") || path?.includes("video-communication")) && {
      ...openedPreviewMixin(theme),
      [`& .${drawerClasses.paper}`]: openedPreviewMixin(theme),
    }),
}));

interface SearchPatientOptionProps {
  optionProps: React.HTMLAttributes<HTMLLIElement>;
  option: AutoCompleteOption<VrsPatientInfo>;
}

function SearchPatientOption({
  optionProps,
  option: { value: patient, label },
}: SearchPatientOptionProps) {
  const avatar = useAppSelector((state) => selectAvatarById(state, patient.id));

  return (
    <ListItem key={`${patient.id}`} dense disablePadding {...optionProps} sx={{ left: -5 }}>
      <Box display="flex" alignItems="center" justifyContent="space-between" flex={1} gap={1}>
        <Box display="flex" alignItems="center" gap={1}>
          <VrsAvatar
            imageUrl={avatar?.picture}
            name={patient.name}
            size={30}
            bottomRightIcon={patient.observation && <VrsIcon name={IconName.Observation} />}
            opacity={patient.isInactiveOrDeceased ? "70%" : "100%"}
          />
          <Typography my={0.5} variant="body" component="span">
            {label}
          </Typography>
        </Box>
        <Typography
          variant="caption"
          color={(theme) => theme.veris.colors.neutrals["grey-3"]}
          style={{ marginLeft: "auto", wordBreak: "break-word" }}
        >
          {`${patient.diagnosis?.cancerType}, St.${patient.diagnosis?.cancerStage}`}
        </Typography>
      </Box>
    </ListItem>
  );
}

export function VrsLeftSidebarContainer(): JSX.Element {
  const dispatch = useAppDispatch();
  const leftSideBarExpanded = useAppSelector(selectToggleLeftSideBar);
  const previewExpanded = useAppSelector(selectTogglePreviewBar);
  const location = useLocation();
  const isBigScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));

  useEffect(() => {
    dispatch(toggleLeftSideBar(isBigScreen));
  }, [dispatch, isBigScreen]);

  return (
    <Drawer
      variant="permanent"
      open={leftSideBarExpanded}
      openPreview={previewExpanded || location.pathname.includes("video-communication")}
      path={location.pathname}
    >
      <VrsLogo expanded={leftSideBarExpanded} />
      <SearchBarContainer expanded={leftSideBarExpanded} />
      <NavigationItems expanded={leftSideBarExpanded} />
      <Box minHeight="40px" px={1.5} pt={2}>
        {leftSideBarExpanded && (
          <>
            <Link href="https://www.nccn.org/home" variant="h6" target="_blank">
              Go to NCCN
            </Link>
            <Link
              href={`mailto:${import.meta.env.VITE_SUPPORT_EMAIL}`}
              target="_blank"
              rel="noopener noreferrer"
              variant="h6"
              mb={2}
              mt={2}
              display="block"
            >
              Contact support
            </Link>
          </>
        )}
      </Box>

      <DrawerFooter
        sx={{
          justifyContent: leftSideBarExpanded ? "flex-start" : "center",
          alignItems: "center",
        }}
      >
        <SidebarToggle
          expanded={leftSideBarExpanded}
          onClick={() => {
            dispatch(toggleLeftSideBar(!leftSideBarExpanded));
          }}
        />
      </DrawerFooter>
    </Drawer>
  );
}

function NavigationItems({ expanded }: LeftSidebarItemProps): JSX.Element {
  const history = useHistory();
  const leftSideBarExpanded = useAppSelector(selectToggleLeftSideBar);
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));
  const isMediumScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("xl"));
  return (
    <Box
      sx={{
        overflowY: "auto",
        overflowX: "hidden",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        flexGrow: 1,
        paddingRight: "4px",
      }}
    >
      {Object.keys(sideBarMenu).map((name) => (
        <React.Fragment key={name}>
          <Box display="flex" flexDirection="column" key={name} mt={isMediumScreen ? 0 : 1}>
            {expanded ? (
              <SideBarSectionTitle sectionTitle={name} />
            ) : (
              isSmallScreen && (
                <Box
                  sx={{
                    height: isSmallScreen && name === "" ? 0 : "8px",
                  }}
                />
              )
            )}
            {!expanded && !isSmallScreen && <Box sx={{ height: name === "" ? "12px" : "27px" }} />}
            {sideBarMenu[name].map((item) => (
              <SideBarNavigationItem
                title={item.title}
                iconName={item.iconName}
                active={history.location.pathname === item.path}
                expanded={leftSideBarExpanded}
                key={item.title}
                onClick={() => history.push(item.path)}
              />
            ))}
          </Box>
        </React.Fragment>
      ))}
    </Box>
  );
}

function SearchBarContainer({ expanded }: LeftSidebarItemProps) {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [inputValue, setInputValue] = useState<string>("");
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));
  const leftSideBarExpanded = useAppSelector(selectToggleLeftSideBar);
  const data = useAppSelector(selectVrsPatientsSearchResult);
  const currentPatient = useCurrentPatient();

  useEffect(() => {
    setInputValue("");
  }, [currentPatient]);

  const onChange = (event: React.SyntheticEvent, value?: AutoCompleteOption<VrsPatientInfo>) => {
    const id = get(value, "value.id");
    if (id) {
      history.push(replaceRouteParam("PATIENT_DETAILS", ":userId", id));
    }
  };

  return (
    <Box
      sx={{
        padding: (theme) =>
          expanded ? theme.spacing(4, 0, 0.5, 0) : theme.spacing(3.8, 0, 0.5, 0),
        display: "flex",
        justifyContent: expanded ? "flex-start" : "center",
        minHeight: !expanded && isSmallScreen ? "53px" : "inherit",
      }}
    >
      {expanded ? (
        <VrsSearchBar<VrsPatientInfo>
          id="search-box-patient"
          placeholder="Search patient by name"
          inputValue={inputValue}
          onChange={onChange}
          onInputChange={(v) => setInputValue(v)}
          options={data.patients.map((patient) => ({
            value: patient,
            label: patient.name,
          }))}
          renderOption={(props, option) => (
            <SearchPatientOption optionProps={props} option={option} />
          )}
          clearIcon={<></>}
          freeSolo
        />
      ) : (
        <VrsIconButton
          iconProps={{ name: IconName.Search }}
          onClick={() => {
            dispatch(toggleLeftSideBar(!leftSideBarExpanded));
          }}
        />
      )}
    </Box>
  );
}

export default VrsLeftSidebarContainer;
