import React, { useEffect } from "react";
import { Box, Grid, Theme, Typography, useMediaQuery } from "@mui/material";
import { useHistory } from "react-router-dom";
import { VrsIconButton, IconName, verisColors } from "@veris-health/web-core";
import { topBarMenuItems } from "../../constants";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import Login from "./Login";
import { clearValidationError, selectOtpScreen, setOTPScreen } from "../shared/slices/authSlice";
import { StyledActiveNavigationItem } from "../shared/styledComponents";
import { Routes } from "../../routes-config";

const LoginContainer = (): JSX.Element => {
  const otpScreen = useAppSelector(selectOtpScreen);
  const dispatch = useAppDispatch();
  const isXsScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("xs"));

  const history = useHistory();

  useEffect(() => {
    if (isXsScreen) {
      history.push(Routes.MOBILE_LANDING_PAGE);
    }
  }, [history, isXsScreen]);

  return (
    <>
      {otpScreen && (
        <Box
          display="flex"
          alignItems="center"
          onClick={() => {
            dispatch(setOTPScreen(false));
            dispatch(clearValidationError());
          }}
          sx={{ cursor: "pointer", position: "absolute", top: "15%" }}
        >
          <VrsIconButton
            iconProps={{ name: IconName.ArrowLeft, stroke: verisColors.neutrals.black, size: 12 }}
          />
          <Typography variant="subtitle2" color={(theme) => theme.veris.colors.neutrals["grey-4"]}>
            Back
          </Typography>
        </Box>
      )}
      <>
        <Grid xs={6} display="flex" mb={10} item>
          {otpScreen ? (
            <StyledActiveNavigationItem variant="h3" m={2} key="OTP">
              Enter the 4-digit OTP
            </StyledActiveNavigationItem>
          ) : (
            <>
              {topBarMenuItems["/staff-login"] &&
                topBarMenuItems["/staff-login"].map((menuTitle) => {
                  return (
                    <Box key={menuTitle}>
                      <StyledActiveNavigationItem variant="h3" mr={3} key={menuTitle}>
                        {menuTitle}
                      </StyledActiveNavigationItem>
                    </Box>
                  );
                })}
            </>
          )}
        </Grid>
        <Box>
          <Login />
        </Box>
      </>
    </>
  );
};

export default LoginContainer;
