import { Box, Typography, useTheme } from "@mui/material";
import { Option, VrsButton, dateFormats } from "@veris-health/web-core";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { get, groupBy, isArray, isEmpty, isEqual, isNumber } from "lodash";
import { ReportItem } from "@veris-health/virtual-doc-ms/lib/v1";
import { FormikProvider, useFormik } from "formik";
import dayjs from "dayjs";
import {
  ClinicalTask,
  ClinicalTaskComment,
  ClinicalTaskDataAbnormalReadingTypeEnum,
  ClinicalTaskDataSymptomReportTypeEnum,
  ClinicalTaskPriorityEnum,
  ClinicalTaskStatusEnum,
} from "@veris-health/med-data-ms/lib/v1";
import { useHistory } from "react-router-dom";
import { DataSourceModel } from "@veris-health/user-ms/lib/v2";
import { PatientInfoLabel } from "../../../ui/components/PatientInfoLabel";
import {
  SensorAutocompleteOption,
  TaskSubtypeSensorsByDataSource,
  emptyOption,
  placeholderOption,
} from "../utils/constants";
import { useCurrentPatient } from "../../../hooks/useCurrentPatient";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { getReports } from "../../PatientDetailsSidebar/components/SymptomReport/api/symptomReportApi";
import { selectHospitalStaff } from "../../Hospitals/hospitalSlice";
import {
  ValidationSchema,
  getCreateTaskFieldData,
  getDataFormatForSubmit,
  getInitialState,
} from "../utils/helpers";
import { addCommentToTask, getCommentsPerTask } from "../api/tasksApi";
import { VrsMedStaffProfileModel } from "../../shared/interfaces";
import { createTaskAsync, updateTaskAsync } from "../tasksSlice";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import SnackbarUtils from "../../../utils/SnackbarUtils";
import TaskFormFields from "./TaskFormFields";
import { useBilling } from "../../../hooks/useBilling";
import { NotificationTaskData } from "./TaskContainer";
import { replaceRouteParam } from "../../../routes-config";
import { ConfirmCloseModal } from "./Modals";
import useModal from "../../../hooks/useModal";
import { utcToLocal } from "../../../utils/date";
import { getSensorsPerSource } from "../../../utils/helpers";
import { VrsBanner } from "../../../ui/components/VrsBanner";

const validationSchema = yup.object().shape({
  title: yup.string().required("Title is required."),
  createdBy: yup.object().required("Created by is required."),
  status: yup.string().required("Status is required."),
  priority: yup.string().required("Priority is required."),
  dueDate: yup.date().required("Due date is required."),
  description: yup.string().notRequired(),
  patient: yup.object().required("Patient is required."),
  taskType: yup.object().required("Task type is required."),
  subType: yup
    .mixed()
    .test("subType", "Invalid subType", (value) => {
      return typeof value === "string" || Array.isArray(value) || value === undefined;
    })
    .when("taskType", (taskType, schema) => {
      if (taskType?.type === "other") {
        return schema.notRequired();
      }
      return schema.required("Subtype is required");
    }),
  shared: yup.array().notRequired(),
  assignee: yup.object().required("Assignee is required."),
  notes: yup.array().notRequired(),
});

const CreateTask = ({
  onClose,
  task,
  currentUser,
  viewMode = false,
  currentPatientId,
  notificationTask,
  fetchTasks,
}: {
  onClose: () => void;
  fetchTasks?: () => void;
  currentUser: VrsMedStaffProfileModel;
  task?: ClinicalTask;
  viewMode?: boolean;
  currentPatientId?: number;
  notificationTask?: NotificationTaskData;
}): JSX.Element => {
  const currentPatient = useCurrentPatient();
  const medicalStaff = useAppSelector(selectHospitalStaff);
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [reportedSymptoms, setReportedSymptoms] = useState<ReportItem[]>();
  const [subTypeOptions, setSubTypeOptions] = useState<Option[] | SensorAutocompleteOption[]>();
  const [comments, setComments] = useState<ClinicalTaskComment[]>();
  const [hospitalStaff, setHospitalStaff] = useState<{ name: string; id: number }[]>();
  const [viewOnlyMode, setViewOnlyMode] = useState<boolean>(viewMode);
  const [viewOnlyMessage, setViewOnlyMessage] = useState<string>("");
  const createdBy = {
    name: task ? task.reporter.full_name || "" : currentUser.fullName || "",
    id: task ? task.reporter.user_id : currentUser.id,
    image: task ? undefined : currentUser.picture,
    profession: task ? undefined : currentUser.profession,
  };
  const [modal, showModal] = useModal();

  const initialFieldValues = getInitialState(currentUser, task, currentPatient, notificationTask);

  useEffect(() => {
    if (task) {
      getCommentsPerTask(task.patient.user_id, task.id).then((res) => setComments(res));
      setViewOnlyMode(viewMode);
    }
  }, [task]);

  const initialValues = {
    title: task ? task.title : "",
    createdBy,
    status: task ? task.status : ClinicalTaskStatusEnum.Unresolved,
    priority: task ? task.priority : ClinicalTaskPriorityEnum.Medium,
    dateCreated: task
      ? utcToLocal(task.date_created).format()
      : dayjs().format(dateFormats["MMM DD YYYY"]),
    dueDate: task
      ? dayjs(task.due_date).format()
      : dayjs().add(1, "week").format(dateFormats["MMM DD YYYY"]),
    description: initialFieldValues.description,
    patient: initialFieldValues.patient,
    taskType: initialFieldValues.type,
    subType: initialFieldValues.subType,
    shared: initialFieldValues.sharedTeam,
    assignee: initialFieldValues.assignee,
    notes: undefined,
  };

  const getSharedTeam = (
    shared: {
      name: string;
      id: number;
    }[],
    currentAssignee: {
      name: string;
      id: number;
    },
  ) => {
    if (initialFieldValues.assignee) {
      const initialAssigneeId = initialFieldValues.assignee.id;
      const sharedTeamIds = shared.map((member) => member.id);

      if (
        !sharedTeamIds.includes(initialAssigneeId) &&
        createdBy.id !== initialAssigneeId &&
        currentAssignee.id !== initialAssigneeId
      ) {
        sharedTeamIds.push(initialAssigneeId);
      }

      return sharedTeamIds;
    }
  };

  const updateTask = (values: ValidationSchema) => {
    if (values.patient && values.shared && values.assignee && task) {
      const updatedTask = {
        title: values.title,
        description: values.description,
        assignee_med_staff_id: values.assignee?.id,
        shared_team_ids: getSharedTeam(values.shared, values.assignee),
        due_date: dayjs(values.dueDate).endOf("day").utc(true).format(),
        priority: values.priority,
        status: values.status,
      };
      dispatch(
        updateTaskAsync({
          patientId: task?.patient.user_id,
          taskId: task?.id,
          data: updatedTask,
        }),
      ).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          SnackbarUtils.success("Task updated successfully!");
          if (fetchTasks) fetchTasks();
          onClose();
        }
        if (res.meta.requestStatus === "rejected") {
          SnackbarUtils.error((res.payload as string) || "Could not update the task.");
        }
      });
    }
  };

  const addTask = (values: ValidationSchema) => {
    if (values.patient && values.shared && values.assignee) {
      const newTask = {
        title: values.title,
        description: values.description || "",
        assignee_med_staff_id: values.assignee?.id,
        shared_team_ids: values.shared?.map((member) => member.id),
        data: getDataFormatForSubmit(values.taskType, values.subType, reportedSymptoms),
        due_date: dayjs(values.dueDate).endOf("day").utc(true).format(),
        priority: values.priority,
        comments: values.notes ? values.notes.map((note) => ({ text: note })) : [],
      };
      dispatch(createTaskAsync({ patientId: +values?.patient?.id, data: newTask })).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          if (notificationTask)
            SnackbarUtils.success(
              "A new task has been added successfully to the patient",
              "View task",
              () => {
                if (values.patient)
                  history.push({
                    pathname: replaceRouteParam(
                      "PATIENT_DETAILS",
                      ":userId",
                      values.patient?.id.toString(),
                    ),
                    state: { view: 4, taskId: (res?.payload as ClinicalTask)?.id },
                  });
              },
            );
          else SnackbarUtils.success("A new task has been added successfully to the patient");
          onClose();
        }
        if (res.meta.requestStatus === "rejected") {
          SnackbarUtils.error((res.payload as string) || "Could not create new task.");
        }
      });
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      if (task) {
        updateTask(values);
      } else {
        addTask(values);
      }
    },
  });
  const { errors, handleSubmit, setFieldValue, touched, values } = formik;

  const { taskType, patient, notes } = formik.values;

  // if you're in patientDetails view don't bill the patient since he already has billing started
  useBilling(patient?.id, !currentPatientId);

  const getSubTypeOptions = (symptoms?: ReportItem[]) => {
    if (taskType?.type === ClinicalTaskDataSymptomReportTypeEnum.Symptom) {
      const symptomsArr =
        symptoms?.map((report) => ({
          label: report.symptom.label.replaceAll("-", " "),
          value: task ? report.symptom.label : report.id.toString(),
        })) || [];

      return [placeholderOption, ...symptomsArr];
    }
    if (taskType?.type === ClinicalTaskDataAbnormalReadingTypeEnum.AbnormalReading) {
      return getSensorsPerSource(
        (values.patient?.dataSources as DataSourceModel[]) || [],
        TaskSubtypeSensorsByDataSource,
        "label",
      );
    }
    return [emptyOption];
  };

  useEffect(() => {
    if (task && notes && patient) {
      addCommentToTask(patient.id, task.id, { text: notes[(notes as string[]).length - 1] }).then(
        (res) => {
          setComments(comments ? [res, ...comments] : [res]);
          setFieldValue("notes", undefined);
        },
      );
    }
  }, [notes]);

  useEffect(() => {
    if (patient && taskType?.type === ClinicalTaskDataSymptomReportTypeEnum.Symptom) {
      getReports(patient.id).then((res) => {
        const groupedSymptoms = groupBy(res, "status");
        if (groupedSymptoms.active) {
          const subTypes = getSubTypeOptions(groupedSymptoms.active);
          if (
            values.subType &&
            notificationTask &&
            taskType?.type === ClinicalTaskDataSymptomReportTypeEnum.Symptom
          ) {
            const notifSymptom = groupedSymptoms.active.find(
              (report) => values.subType && report.id === +values.subType,
            );
            if (!notifSymptom) {
              formik.setFieldValue("subType", undefined);
              setViewOnlyMode(true);
              setViewOnlyMessage(
                "The symptom is not active anymore so a task cannot be generated from here. Please use the Tasks Module to create a new task.",
              );
              return;
            }
          }

          setSubTypeOptions(subTypes);
          setReportedSymptoms(groupedSymptoms.active || []);
        } else {
          setSubTypeOptions([emptyOption]);
        }
      });
    }

    if (taskType?.type !== ClinicalTaskDataSymptomReportTypeEnum.Symptom) {
      setReportedSymptoms([]);
      const subTypes = getSubTypeOptions();
      setSubTypeOptions(subTypes);
    }
  }, [taskType, patient]);

  useEffect(() => {
    if (patient) {
      const patientHospital = get(patient, "hospital");

      const filteredMedStaffByHospital = medicalStaff.filter((hospital) => {
        if (isNumber(patientHospital)) {
          return patientHospital === hospital.id;
        }
        if (isArray(patientHospital) && !isEmpty(patientHospital)) {
          return patientHospital[0] === hospital.id;
        }
        return false;
      });
      if (filteredMedStaffByHospital.length > 0) {
        const currentUserOption = {
          name: `${currentUser.first_name} ${currentUser.last_name}`,
          id: currentUser.id,
        };
        const mappedMedicalStaff = filteredMedStaffByHospital[0].med_staff.map((member) => ({
          name: `${member.first_name} ${member.last_name}`,
          id: member.id,
        }));
        setHospitalStaff([currentUserOption, ...mappedMedicalStaff]);
      }
    } else {
      setHospitalStaff([]);
    }
  }, [patient]);

  const fieldData = getCreateTaskFieldData(formik.values, errors, touched, !!task);

  const isSubtypeDisabled = () => {
    if (viewOnlyMode || !!task) return true;
    if (taskType?.type === ClinicalTaskDataSymptomReportTypeEnum.Symptom && patient) return false;
    if (taskType?.type === ClinicalTaskDataAbnormalReadingTypeEnum.AbnormalReading) return false;

    return true;
  };

  return (
    <Box my={2}>
      {viewOnlyMessage && (
        <VrsBanner
          bcgcolor={theme.veris.colors.pink.light}
          bordercolor={theme.veris.colors.errors.normal}
        >
          <Typography color={theme.veris.colors.errors.normal} variant="body">
            {viewOnlyMessage}
          </Typography>
        </VrsBanner>
      )}
      <FormikProvider value={formik}>
        <form>
          {fieldData.map((field) => {
            return (
              <Box display="flex" flexDirection="column" my={2} key={field.name}>
                {field.label && (
                  <PatientInfoLabel
                    fieldName={field.label}
                    variant="subtitle24"
                    sx={{
                      minWidth: "150px",
                      marginLeft: theme.spacing(-1),
                    }}
                    color={theme.veris.colors.neutrals["grey-4"]}
                  />
                )}

                <TaskFormFields
                  field={field}
                  currentPatient={currentPatient}
                  currentUser={currentUser}
                  isSubtypeDisabled={isSubtypeDisabled()}
                  hospitalStaff={hospitalStaff}
                  comments={comments}
                  subTypeOptions={subTypeOptions}
                  viewMode={viewOnlyMode}
                  task={task}
                  notificationTask={notificationTask}
                />
              </Box>
            );
          })}
        </form>
        {modal}
        {!viewOnlyMode && (
          <Box display="flex" justifyContent="space-around" my={2}>
            <VrsButton
              buttonType="secondary"
              onClick={() => {
                if (isEqual(initialValues, values)) onClose();
                else
                  showModal("Changes to this task are unsaved. Close anyway?", () => (
                    <ConfirmCloseModal onClose={onClose} onConfirm={handleSubmit} />
                  ));
              }}
            >
              Cancel
            </VrsButton>
            <VrsButton
              buttonType="primary"
              onClick={() => handleSubmit()}
              disabled={!isEmpty(errors)}
            >
              Save task
            </VrsButton>
          </Box>
        )}
      </FormikProvider>
    </Box>
  );
};

export default CreateTask;
