import { Box, DialogActions, DialogContent, Typography } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import React, { useEffect } from "react";
import { VrsButton, VrsInput } from "@veris-health/web-core";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { numberRegexpPattern } from "../../../constants";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { VrsDialog } from "../../shared/styledComponents";
import { clearOtpError, selectDoctorEditingValidation } from "../doctorsProfileSlice";

export interface OtpLoginContainerProps {
  onOTPSubmit: (code: string) => void;
  onResendCode: () => void;
  onClose: () => void;
  open: boolean;
}

const initialValues = {
  otpCode: "",
};

const validationSchema = yup.object().shape({
  otpCode: yup.string().max(4).min(4).required(""),
});

export const OTPhoneEditContainer = ({
  onOTPSubmit,
  onResendCode,
  open,
  onClose,
}: OtpLoginContainerProps): JSX.Element => {
  const validationError = useAppSelector(selectDoctorEditingValidation);
  const dispatch = useAppDispatch();

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: ({ otpCode }) => {
      onOTPSubmit(otpCode);
    },
  });

  useEffect(() => {
    if (validationError && !formik.values.otpCode) dispatch(clearOtpError());
  }, [validationError, formik.values, dispatch]);

  return (
    <VrsDialog
      open={open}
      onClose={() => {
        dispatch(clearOtpError);
        onClose();
      }}
    >
      <Typography variant="h3" p={0} pb={(theme) => theme.spacing(4)}>
        Verification code
      </Typography>
      <DialogContent>
        <Typography variant="subtitle2" mt={1}>
          To protect the security of your account, we require a verification code to update your
          phone number information. A code has been sent to your edited phone number. Please enter
          the code below to proceed.
        </Typography>
        <Box py={2}>
          <form onSubmit={formik.handleSubmit}>
            <Box display="flex" m={2}>
              <VrsInput
                name="otpCode"
                onChange={(e) => {
                  if (e.target.value === "" || numberRegexpPattern.test(e.target.value))
                    formik.handleChange(e);
                }}
                value={formik.values.otpCode}
                error={Boolean(validationError)}
                isValid={
                  !formik.errors.otpCode && formik.values.otpCode.length === 4 && !validationError
                }
                onFocus={() => dispatch(clearOtpError())}
                padding={{ left: "15px", top: "22px" }}
              />
            </Box>
            <Box m={2} gap={1} display="flex">
              {validationError && (
                <Typography variant="caption" color={(theme) => theme.veris.colors.errors.normal}>
                  {validationError}
                </Typography>
              )}
              <Typography
                variant="caption"
                sx={{ textDecoration: "underline", cursor: "pointer" }}
                color={(theme) => theme.veris.colors.amethyst.normal}
                onClick={() => onResendCode()}
              >
                {validationError ? "Didn't get the code?" : "Resend OTP code?"}
              </Typography>
            </Box>
          </form>
        </Box>
      </DialogContent>
      <DialogActions>
        <VrsButton onClick={onClose} buttonType="secondary">
          <Typography variant="body">Cancel</Typography>
        </VrsButton>
        <VrsButton
          buttonType="primary"
          disabled={!!formik.errors.otpCode || formik.values.otpCode.length < 4}
          onClick={formik.submitForm}
        >
          Confirm
        </VrsButton>
      </DialogActions>
    </VrsDialog>
  );
};
