import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  PayloadAction,
  EntityState,
} from "@reduxjs/toolkit";
import { PatientBasicInfo, PatientInfoAvatar } from "@veris-health/user-ms/lib/v1";
import { PatientStatusEnumAPI } from "@veris-health/user-ms/lib/v2";
import { localizedLogout, logout } from "./authSlice";
import { RootState } from "../../../store";
import { Status, VrsPatientInfo } from "../interfaces";
import { getPatientAvatarsAsync, getPatientByIdAsync, searchPatientsAsync } from "./asyncThunks";
import { ALL_HOSPITALS } from "../../../constants";
import { getMedStaffPatients } from "./api/userMsApi";
import { SortByAlphabet, SortByLastInfusion } from "../../Patients/patientsSlice";
import { checkPatientStatus, getFullPictureFormat } from "../helpers";
import { updatePatientStatus } from "../../PatientDetails/api/patientDetailsApi";

const PATIENTS_SLICE_NAME = "patients";

export interface PatientInfoMap {
  [id: number]: VrsPatientInfo | undefined;
}
interface PatientsState {
  data: VrsPatientsSearchResult;
  status: Status;
  hospital: string;
  allPatientsFilter: boolean;
  medStaffPatients: { patients: PatientBasicInfo[]; status: Status };
  cancerTypeFilter: string[];
  sortByAlphabet?: SortByAlphabet;
  sortByLastInfusion?: SortByLastInfusion;
  tagFilter: string[];
  symptomFilter: string[];
  avatars: EntityState<PatientInfoAvatar>;
  singlePatientStatus: Status;
}

export const patientsAdapter = createEntityAdapter<VrsPatientInfo>();
export const avatarsAdapter = createEntityAdapter<PatientInfoAvatar>();

export interface VrsPatientsSearchResult {
  totalCount: number;
  patients: EntityState<VrsPatientInfo>;
}

const initialState: PatientsState = {
  data: { totalCount: -1, patients: patientsAdapter.getInitialState() },
  hospital: ALL_HOSPITALS,
  status: "idle",
  allPatientsFilter: false,
  medStaffPatients: {
    patients: [],
    status: "idle",
  },
  avatars: avatarsAdapter.getInitialState(),
  cancerTypeFilter: [],
  tagFilter: [],
  symptomFilter: [],
  singlePatientStatus: "idle",
};

export const getMedStaffPatientsAsync = createAsyncThunk(
  "patients/getMedStaffPatients",
  async (medStaffId: number) => {
    const response = await getMedStaffPatients(medStaffId);
    return response;
  },
);

export const updatePatientStatusAsync = createAsyncThunk(
  "patients/updatePatientStatus",
  async ({ patientId, status }: { patientId: number; status: PatientStatusEnumAPI }) => {
    await updatePatientStatus(patientId, status);
    return { patientId, status };
  },
);

const patientsSlice = createSlice({
  name: PATIENTS_SLICE_NAME,
  initialState,
  reducers: {
    setHospital: (state, { payload }: PayloadAction<{ id: string }>) => {
      state.hospital = payload.id;
    },
    setAllPatientsFilter: (state, { payload }: PayloadAction<{ allPatients: boolean }>) => {
      state.allPatientsFilter = payload.allPatients;
    },
    filterByCancerType: (state, action: PayloadAction<string[]>) => {
      state.cancerTypeFilter = action.payload;
    },
    filterByTag: (state, action: PayloadAction<string[]>) => {
      state.tagFilter = action.payload;
    },
    filterBySymptom: (state, action: PayloadAction<string[]>) => {
      state.symptomFilter = action.payload;
    },
    sortByAlphabet: (state, action: PayloadAction<SortByAlphabet>) => {
      state.sortByLastInfusion = undefined;
      state.sortByAlphabet = action.payload;
    },
    sortByLastInfusion: (state, action: PayloadAction<SortByLastInfusion | undefined>) => {
      state.sortByAlphabet = undefined;
      state.sortByLastInfusion = action.payload;
    },

    resetFilters: (state) => {
      state.sortByAlphabet = undefined;
      state.sortByLastInfusion = undefined;
      state.tagFilter = [];
      state.cancerTypeFilter = [];
      state.symptomFilter = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(logout, () => {
        return initialState;
      })
      .addCase(searchPatientsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(searchPatientsAsync.fulfilled, (state, { payload }) => {
        state.data.patients = patientsAdapter.setAll(state.data.patients, payload.patients);
        state.data.totalCount = payload.totalCount;
        state.status = "idle";
      })
      .addCase(searchPatientsAsync.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(getPatientByIdAsync.pending, (state) => {
        state.singlePatientStatus = "loading";
      })
      .addCase(getPatientByIdAsync.fulfilled, (state, { payload }) => {
        const { id, picture } = payload.patient;
        if (state.data.patients.ids.includes(id)) {
          patientsAdapter.updateOne(state.data.patients, {
            id,
            changes: payload.patient,
          });
        } else {
          state.data.patients = patientsAdapter.upsertOne(state.data.patients, {
            ...payload.patient,
          });
        }

        state.avatars = avatarsAdapter.addOne(state.avatars, {
          id,
          picture: picture && getFullPictureFormat(picture),
        });
        state.singlePatientStatus = "idle";
      })
      .addCase(getPatientByIdAsync.rejected, (state) => {
        state.singlePatientStatus = "failed";
      })
      .addCase(getMedStaffPatientsAsync.pending, (state) => {
        state.medStaffPatients.status = "loading";
      })
      .addCase(getMedStaffPatientsAsync.fulfilled, (state, { payload }) => {
        state.medStaffPatients.patients = payload;
        state.medStaffPatients.status = "idle";
      })
      .addCase(getMedStaffPatientsAsync.rejected, (state) => {
        state.medStaffPatients.status = "failed";
      })
      .addCase(getPatientAvatarsAsync.fulfilled, (state, { payload }) => {
        payload.avatars
          .filter(({ picture }) => picture)
          .forEach(({ id, picture }) => {
            avatarsAdapter.upsertOne(state.avatars, {
              id,
              picture: picture && getFullPictureFormat(picture),
            });
          });
      })

      .addCase(updatePatientStatusAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updatePatientStatusAsync.fulfilled, (state, { payload }) => {
        const patientToUpdate = patientsAdapter
          .getSelectors()
          .selectById(state.data.patients, payload.patientId) as VrsPatientInfo;
        const updatedPatient = {
          ...patientToUpdate,
          inCareTeam: checkPatientStatus(
            [PatientStatusEnumAPI.Inactive, PatientStatusEnumAPI.Deceased],
            payload.status,
          )
            ? false
            : patientToUpdate.inCareTeam,
          patientStatus: payload.status,
          patient_status: payload.status,
          isInactiveOrDeceased: checkPatientStatus(
            [PatientStatusEnumAPI.Inactive, PatientStatusEnumAPI.Deceased],
            payload.status,
          ),
          observation: checkPatientStatus(
            [
              PatientStatusEnumAPI.HospitalAtHome,
              PatientStatusEnumAPI.Hospitalized,
              PatientStatusEnumAPI.Survivor,
            ],
            payload.status,
          ),
        } as VrsPatientInfo;
        patientsAdapter.updateOne(state.data.patients, {
          id: payload.patientId,
          changes: updatedPatient,
        });
        state.status = "idle";
      })
      .addCase(updatePatientStatusAsync.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(localizedLogout, () => {
        return initialState;
      });
  },
});

export const selectAllPatientsFilter = ({ sharedPatients }: RootState): boolean =>
  sharedPatients.allPatientsFilter;

export const selectCurrentHospital = ({ sharedPatients }: RootState): string =>
  sharedPatients.hospital;

export const selectAllPatients = createSelector(
  [({ sharedPatients }: RootState) => sharedPatients.data.patients],
  (data): VrsPatientInfo[] => {
    return Object.values(data.entities) as VrsPatientInfo[];
  },
);

export const selectPatients = createSelector(
  [selectAllPatients, selectCurrentHospital, selectAllPatientsFilter],
  (sharedPatients, hospital, allPatientsFilter) => {
    return sharedPatients
      .filter((patient) => hospital === ALL_HOSPITALS || `${patient.hospital?.id}` === hospital)
      .filter((patient) => allPatientsFilter || !!patient.inCareTeam);
  },
);

export const selectInpatients = createSelector([selectPatients], (patients): VrsPatientInfo[] =>
  patients.filter((patient) =>
    patient.tags?.some((patientTag) => patientTag.label === "inpatient"),
  ),
);

export const selectPatientStatus = ({ sharedPatients }: RootState): Status => sharedPatients.status;

export const selectMedStaffPatients = ({ sharedPatients }: RootState): PatientBasicInfo[] =>
  sharedPatients.medStaffPatients.patients;

export const selectVrsPatientsSearchResult = createSelector(
  [selectPatients],
  (patients): { patients: VrsPatientInfo[]; totalCount: number } => ({
    patients,
    totalCount: patients.length,
  }),
);

export const selectPatientsMap = createSelector(
  [selectAllPatients],
  (patients): PatientInfoMap =>
    patients.reduce(
      (acc, patient) => ({
        ...acc,
        [patient.id]: {
          ...patient,
        },
      }),
      {},
    ),
);

export const selectCancerTypeFilter = ({ sharedPatients }: RootState): string[] =>
  sharedPatients.cancerTypeFilter;

export const selectSymptomFilter = ({ sharedPatients }: RootState): string[] =>
  sharedPatients.symptomFilter;

export const selectSortByAlphabet = ({ sharedPatients }: RootState): SortByAlphabet | undefined =>
  sharedPatients.sortByAlphabet;

export const selectSortByLastInfusion = ({
  sharedPatients,
}: RootState): SortByLastInfusion | undefined => sharedPatients.sortByLastInfusion;

export const selectTagFilter = ({ sharedPatients }: RootState): string[] =>
  sharedPatients.tagFilter;

export const { selectById: selectAvatarById } = avatarsAdapter.getSelectors(
  ({ sharedPatients }: RootState) => sharedPatients.avatars,
);

export const selectSinglePatientStatus = ({ sharedPatients }: RootState): Status =>
  sharedPatients.singlePatientStatus;

export const {
  setAllPatientsFilter,
  filterByTag,
  filterByCancerType,
  filterBySymptom,
  resetFilters,
  sortByAlphabet,
  sortByLastInfusion,
  setHospital,
} = patientsSlice.actions;

export default patientsSlice.reducer;
