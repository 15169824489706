import { useState, useEffect, useRef, SetStateAction, Dispatch, MutableRefObject } from "react";

export const useStateRef = <T>(
  initialState: T,
): readonly [MutableRefObject<T>, Dispatch<SetStateAction<T>>, T] => {
  const [state, setState] = useState(initialState);
  const ref = useRef(initialState);

  useEffect(() => {
    ref.current = state;
  }, [state]);

  return [ref, setState, state] as const;
};
