import { ObservationToCompare } from "@veris-health/med-data-ms/lib/v1";
import dayjs from "dayjs";
import React from "react";
import { Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { verisColors, dateFormats } from "@veris-health/web-core";

export interface VrsLabResultsComparisonChartProps {
  data: ObservationToCompare[];
}

const colors = [
  verisColors.amethyst.strong,
  verisColors.amethyst.medium,
  verisColors.amethyst.normal,
  verisColors.amethyst.soft,
  verisColors.amethyst.light,
];

export interface CustomDotProps {
  cx: number;
  cy: number;
  index: number;
  dotColors: string[];
}
const CustomDot = ({ cx, cy, index, dotColors }: CustomDotProps) => {
  return <circle cx={cx} cy={cy} r={3} strokeWidth={3} fill={dotColors[index]} />;
};

export interface CustomTickProps {
  x: number;
  y: number;
  index: number;
  payload: { value: string | Date };
  dotColors: string[];
}
const CustomTick = ({ x, y, index, payload, dotColors }: CustomTickProps) => {
  return (
    <>
      <text
        x={x}
        y={y + 10}
        dy={-4}
        fill={verisColors.neutrals["grey-4"]}
        fontSize={10}
        textAnchor="middle"
      >
        {dayjs(payload.value).format(dateFormats["MMM DD"])}
      </text>
      <circle
        cx={x + 20}
        cy={y + 3}
        r={3}
        strokeWidth={3}
        fill={dotColors[index]}
        textAnchor="top"
      />
    </>
  );
};

export function VrsLabResultsComparisonChart({
  data,
}: VrsLabResultsComparisonChartProps): JSX.Element {
  const dotColorsSplice = colors.slice(5 - data.length, 5);
  return (
    <ResponsiveContainer width="99%" height={190}>
      <LineChart data={data} margin={{ right: 30, top: 10, bottom: 10, left: 10 }}>
        <Line
          dataKey="value"
          type="monotone"
          strokeWidth={1}
          stroke={verisColors.neutrals["grey-3"]}
          dot={<CustomDot cx={0} cy={0} index={0} dotColors={dotColorsSplice} />}
        />
        <Tooltip
          labelStyle={{ display: "none" }}
          cursor={false}
          itemStyle={{ fontSize: "12px" }}
          contentStyle={{ padding: "0 5px" }}
          formatter={(value: string) => [value, "Result"]}
        />
        <XAxis
          dataKey="date"
          tickFormatter={(d) => dayjs(d).format(dateFormats["MMM D"])}
          padding="no-gap"
          axisLine={false}
          tickLine={false}
          tick={
            <CustomTick
              x={0}
              y={0}
              dotColors={dotColorsSplice}
              index={0}
              payload={{
                value: "",
              }}
            />
          }
        />
        <YAxis
          type="number"
          // needed type casting because of the BE model
          dataKey={(v: ObservationToCompare) => Number(v.value)}
          axisLine={false}
          tickLine={false}
          dx={-15}
          tick={{ fontSize: 12 }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
}
