import React from "react";
import { Box, styled, Typography } from "@mui/material";
import {
  DiagnosticReportObsComponent,
  DiagnosticReportObservation,
  GetDiagnosticReportObsResponse,
} from "@veris-health/med-data-ms/lib/v1";
import { verisColors } from "@veris-health/web-core";

export interface VrsLabResultsTableProps {
  labResults?: GetDiagnosticReportObsResponse;
  selectedResult?: DiagnosticReportObservation;
  onClick: (result: DiagnosticReportObservation) => void;
}

const enum LabResultsColumnNames {
  Value = "Value",
  Results = "Results",
  Unit = "Unit",
  Reference = "Reference",
  Last = "Last",
}

interface Column {
  id: LabResultsColumnNames;
  label: LabResultsColumnNames;
}

const columns: Column[] = [
  { id: LabResultsColumnNames.Value, label: LabResultsColumnNames.Value },
  { id: LabResultsColumnNames.Results, label: LabResultsColumnNames.Results },
  { id: LabResultsColumnNames.Unit, label: LabResultsColumnNames.Unit },
  { id: LabResultsColumnNames.Reference, label: LabResultsColumnNames.Reference },
  { id: LabResultsColumnNames.Last, label: LabResultsColumnNames.Last },
];

const StyledRow = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "40% repeat(4, 15%)",
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
}));

export function VrsLabResultsTable({
  labResults,
  onClick,
  selectedResult,
}: VrsLabResultsTableProps): JSX.Element {
  return (
    <Box p={1} sx={{ maxHeight: "30vh", overflow: "hidden" }}>
      <StyledRow>
        {columns.map((column) => (
          <Box key={column.id}>
            <Typography color={(theme) => theme.veris.colors.neutrals["grey-4"]} variant="body1">
              {column.label}
            </Typography>
          </Box>
        ))}
      </StyledRow>

      <Box sx={{ maxHeight: "25vh", overflowY: "scroll" }}>
        {labResults &&
          labResults.observations.length > 0 &&
          labResults.observations.map((result) => (
            <StyledRow
              onClick={() => onClick(result)}
              sx={{
                backgroundColor: (theme) =>
                  selectedResult?.name === result?.name
                    ? theme.veris.colors.amethyst.light
                    : "transparent",
                "&:hover": {
                  backgroundColor: (theme) => theme.veris.colors.amethyst.light,
                  cursor: "pointer",
                },
              }}
              key={result.name}
              data-test-hook="vrsLabResultsTable-column"
            >
              {columns.map((column) => {
                return (
                  <Box key={column.id}>
                    {column.id === LabResultsColumnNames.Value && (
                      <Typography
                        variant="body"
                        color={(theme) => theme.veris.colors.neutrals.black}
                      >
                        {/* Splitting the name since we have long string that cannot fit
                        into one column, we always have the '[' character provided */}
                        {result.name.split("[")[0]}
                      </Typography>
                    )}
                    {column.id === LabResultsColumnNames.Results && (
                      <Typography
                        variant="body1"
                        color={colorValueByReference(
                          result.value,
                          result.referenceRange?.low,
                          result.referenceRange?.high,
                        )}
                      >
                        {result.value}
                      </Typography>
                    )}
                    {column.id === LabResultsColumnNames.Unit && (
                      <Typography variant="body">{result.unit}</Typography>
                    )}
                    {column.id === LabResultsColumnNames.Reference && (
                      <Typography variant="body">
                        {result.referenceRange?.low} - {result.referenceRange?.high}
                      </Typography>
                    )}
                    {column.id === LabResultsColumnNames.Last && (
                      <Typography variant="body">{result.lastValue}</Typography>
                    )}
                  </Box>
                );
              })}
            </StyledRow>
          ))}
      </Box>
    </Box>
  );
}

export function colorValueByReference(
  value?: string | number | boolean | DiagnosticReportObsComponent[],
  low?: string,
  high?: string,
): string {
  if ((!high && !low) || !value) return verisColors.neutrals.black;
  if ((low && +value < +low) || (high && +value > +high)) {
    return verisColors.mango.normal;
  }
  return verisColors.neutrals.black;
}
