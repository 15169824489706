import React from "react";
import { ContentBlock, EditorBlock, EditorBlockProps } from "draft-js";
import { PluginFunctions } from "@draft-js-plugins/editor";
import Typography from "@mui/material/Typography/Typography";

export interface TextBlockProps {
  pluginFunctions: PluginFunctions;
}

const renderEditorBlock = (
  block: ContentBlock,
  props: Omit<EditorBlockProps<TextBlockProps>, "block">,
) => <EditorBlock block={block} {...props} />;

export const TextBlock = ({ block, ...props }: EditorBlockProps<TextBlockProps>): JSX.Element => {
  const type = block.getType();

  return React.useMemo(() => {
    switch (type) {
      case "unstyled":
      default:
        return (
          <Typography style={{ display: "block" }} variant="body">
            {renderEditorBlock(block, props)}
          </Typography>
        );
      case "header-one":
        return (
          <Typography style={{ display: "block" }} variant="h1">
            {renderEditorBlock(block, props)}
          </Typography>
        );
      case "header-two":
        return (
          <Typography style={{ display: "block" }} variant="h2">
            {renderEditorBlock(block, props)}
          </Typography>
        );
    }
  }, [block, props.contentState.getBlocksAsArray().indexOf(block)]);
};
