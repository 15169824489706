import {
  CharacterMetadata,
  ContentBlock,
  ContentState,
  EditorState,
  genKey,
  Modifier,
  SelectionState,
} from "draft-js";
import { List, OrderedSet } from "immutable";

export function clearInlineStyles(
  editorState: EditorState,
  selection: SelectionState,
): EditorState {
  let newContentState = editorState.getCurrentContent();

  let betweenSelections = false;
  let blockMap = newContentState.getBlockMap();
  newContentState.getBlocksAsArray().forEach((contentBlock) => {
    if (!betweenSelections && contentBlock.getKey() === selection.getStartKey()) {
      betweenSelections = true;
    }
    blockMap = blockMap.set(
      contentBlock.getKey(),
      contentBlock.set(
        "characterList",
        contentBlock.getCharacterList().map((character, index) => {
          if (betweenSelections && index !== undefined) {
            if (contentBlock.getKey() === selection.getStartKey()) {
              if (contentBlock.getKey() === selection.getEndKey()) {
                if (index >= selection.getStartOffset() && index <= selection.getEndOffset()) {
                  return CharacterMetadata.create({ style: OrderedSet.of() });
                }
              } else if (index >= selection.getStartOffset()) {
                return CharacterMetadata.create({ style: OrderedSet.of() });
              }
            } else if (contentBlock.getKey() === selection.getEndKey()) {
              if (index <= selection.getEndOffset()) {
                return CharacterMetadata.create({ style: OrderedSet.of() });
              }
            } else {
              return CharacterMetadata.create({ style: OrderedSet.of() });
            }
          }
          return character;
        }),
      ) as ContentBlock,
    );
    if (betweenSelections && contentBlock.getKey() === selection.getEndKey()) {
      betweenSelections = false;
    }
  });
  newContentState = newContentState.set("blockMap", blockMap) as ContentState;

  const newEditorState = EditorState.push(editorState, newContentState, "change-inline-style");

  return EditorState.set(newEditorState, { selection });
}

export const addEmptyBlock = (editorState: EditorState): EditorState => {
  const key = genKey();
  const newBlock = new ContentBlock({
    key,
    type: "unstyled",
    text: "",
    characterList: List(),
  });

  const contentState = editorState.getCurrentContent();
  const newBlockMap = contentState.getBlockMap().set(key, newBlock);

  return EditorState.push(
    editorState,
    ContentState.createFromBlockArray(newBlockMap.toArray()),
    "insert-fragment",
  );
};

export const appendText = (editorState: EditorState, text: string): EditorState => {
  // get current editor state
  const currentContent = editorState.getCurrentContent();

  // create new selection state where focus is at the end
  const blockMap = currentContent.getBlockMap();
  const key = blockMap.last().getKey();
  const length = blockMap.last().getLength();
  const selection = new SelectionState({
    anchorKey: key,
    anchorOffset: length,
    focusKey: key,
    focusOffset: length,
  });

  // insert text at the selection created above
  const textWithInsert = Modifier.insertText(currentContent, selection, text, undefined);
  return EditorState.push(editorState, textWithInsert, "insert-characters");
};
