import React from "react";
import { alpha, Badge, Stack, styled, Typography, Box } from "@mui/material";
import { verisColors } from "@veris-health/web-core";
import { PatientType } from "@veris-health/user-ms/lib/v1";
import { ThreadParticipantInfo } from "@veris-health/communication-ms/lib/v1";
import { VrsPatientInfoCard } from "../../../../ui/components/VrsPatientInfoCard";
import { VrsPatientInfo } from "../../../shared/interfaces";
import { VrsGroupThreadContent } from "../VrsGroupThreadContent";
import { useChatLabels } from "../../hooks/useChatParticipants";
import { DoctorTreadCard } from "./DoctorThreadCard";

const StyledThreadWrapper = styled("div", {
  shouldForwardProp: (prop) => prop !== "isActive",
})<{ isActive: boolean }>(({ theme, isActive }) => ({
  backgroundColor: isActive ? alpha(verisColors.amethyst.light, 0.5) : verisColors.neutrals.white,
  display: "flex",
  flexDirection: "column",
  cursor: "pointer",
  border: isActive ? `1px solid ${theme.veris.colors.amethyst.soft}` : "",
  borderRadius: isActive ? +theme.shape.borderRadius + 2 : 0,
  overflow: "hidden",
  "&:hover": {
    backgroundColor: verisColors.neutrals["grey-1"],
    zIndex: 1,
  },
}));

export interface VrsThreadPreviewProps {
  participantInfo?: VrsPatientInfo;
  threadPatient: ThreadParticipantInfo;
  msgTime?: string | Date;
  hasUnread: boolean;
  isActive: boolean;
  isGroupChat?: boolean;
  threadLabel?: string;
  groupChatParticipants?: ThreadParticipantInfo[];
  handleClick: () => void;
  doctorThread?: boolean;
}

export function VrsThreadPreview({
  participantInfo,
  threadPatient,
  msgTime,
  hasUnread,
  isActive,
  handleClick,
  isGroupChat,
  groupChatParticipants,
  doctorThread,
}: VrsThreadPreviewProps): JSX.Element {
  const { caption, label } = useChatLabels(groupChatParticipants, doctorThread);
  const currentDoctor = groupChatParticipants?.find((el) => el.user_type !== "patient");
  const showGroupThreadContent = isGroupChat && groupChatParticipants;
  let patientInfo;
  if (!isGroupChat) {
    patientInfo =
      participantInfo ||
      ({
        id: threadPatient?.user_id,
        name: threadPatient.user_type ? threadPatient?.display_name : "Unknown",
        type: PatientType.Veris,
        gender: "N/A",
        diagnosis: {
          cancerType: threadPatient?.cancer_type,
          cancerStage: threadPatient?.cancer_stage,
        },
      } as VrsPatientInfo);
  }

  return (
    <StyledThreadWrapper isActive={isActive} onClick={handleClick}>
      <Stack direction="row" paddingRight={1} py={1.5} justifyContent="space-between">
        {doctorThread ? (
          <>
            {showGroupThreadContent ? (
              <VrsGroupThreadContent
                groupChatParticipants={groupChatParticipants}
                caption={caption}
                label={label}
              />
            ) : (
              <DoctorTreadCard doctor={currentDoctor} />
            )}
          </>
        ) : (
          <>
            {patientInfo && (
              <VrsPatientInfoCard patientInfo={patientInfo} backgroundColor="transparent" />
            )}
            {showGroupThreadContent && (
              <VrsGroupThreadContent
                groupChatParticipants={groupChatParticipants}
                caption={caption}
                label={label}
              />
            )}
          </>
        )}

        {msgTime && (
          <Box display="flex" flexDirection="column">
            <Typography
              variant="body"
              color={verisColors.neutrals["grey-mid"]}
              pt={1}
              sx={{ minWidth: "50px" }}
            >
              {msgTime}
            </Typography>
            {hasUnread && (
              <Box
                sx={{
                  display: "grid",
                  placeContent: "center",
                  placeItems: "center",
                  height: "40%",
                }}
              >
                <Badge
                  color="info"
                  variant="dot"
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  sx={{ ".MuiBadge-dot": { width: "12px", height: "12px", borderRadius: "100%" } }}
                />
              </Box>
            )}
          </Box>
        )}
      </Stack>
    </StyledThreadWrapper>
  );
}
