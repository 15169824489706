import { Box, Typography, Link } from "@mui/material";
import React from "react";
import { verisTypography } from "@veris-health/web-core";

export function QofLTrendExplanation(): JSX.Element {
  return (
    <Box p={1}>
      <Typography variant="caption">Quality of Life trend data (last two months).</Typography>
    </Box>
  );
}

export function QofLScoreExplanation(): JSX.Element {
  return (
    <Box p={1}>
      <Typography variant="caption">
        This Quality of Life index is based on EQ-5D methodology. EQ-5D is a standardized measure of
        health-related quality of life developed by the
        <Link href="https://euroqol.org/euroqol/" variant="body" target="_blank">
          {" "}
          EuroQol Group{" "}
        </Link>
        to provide a simple 5 question clinical survey. The index reported here uses the D1
        Valuation Model validated in the US population [multiplied by 100 for a scale ranging from
        -10.9 to 100 (poorest to best quality of life)]. EQ-5D has been validated in numerous
        oncology clinical trials, and Pickard, et al, characterized minimally important differences
        in the quality of life index for cancer patients.
      </Typography>
      <ol
        type="1"
        style={{
          paddingLeft: "16px",
          ...verisTypography.caption,
        }}
      >
        <li>
          Shaw JW, Johnson JA, Coons SJ. US valuation of the EQ-5D health states: development and
          testing of the D1 valuation model. Med Care. 2005 Mar;43(3):203-20.
        </li>
        <li>
          Pickard AS, Neary MP, Cella D. Estimation of minimally important differences in EQ-5D
          utility and VAS scores in cancer. Health Qual Life Outcomes. 2007 Dec 21;5:70.
        </li>
      </ol>
    </Box>
  );
}
