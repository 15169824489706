import { Grid, Typography } from "@mui/material";
import { UnconfirmedCallParticipantInfo } from "@veris-health/communication-ms/lib/v1";
import { IconName, VrsIcon, verisColors } from "@veris-health/web-core";
import { orderBy } from "lodash";
import React from "react";

export interface VideoBillingParticipantsProps {
  participants: UnconfirmedCallParticipantInfo[];
}

export function VideoBillingParticipants({
  participants,
}: VideoBillingParticipantsProps): JSX.Element | null {
  const orderedParticipants = orderBy(participants, (info) => info.role, "desc");
  return (
    <Grid item container mb={3}>
      {orderedParticipants.map((participant) => (
        <React.Fragment key={participant.id}>
          <Grid item xs={1} />
          <Grid xs={11} item container alignItems="center" mb={1}>
            <VrsIcon name={IconName.User} stroke={verisColors.neutrals["grey-4"]} />
            <Typography
              variant="subtitle1"
              mx={1}
              sx={{ color: (theme) => `${theme.veris.colors.neutrals["grey-4"]}` }}
            >
              {participant.full_name}
            </Typography>
            &#183;
            <Typography
              variant="subtitle2"
              ml={1}
              sx={{ color: (theme) => `${theme.veris.colors.neutrals["grey-3"]}` }}
            >
              {participant.role}
            </Typography>
          </Grid>
        </React.Fragment>
      ))}
    </Grid>
  );
}
