import React from "react";
import { Alert, Box, Typography } from "@mui/material";
import { IconName, VrsIcon, verisColors } from "@veris-health/web-core";

export const VrsInactivePatientAlert = ({
  style,
}: {
  style?: React.CSSProperties;
}): JSX.Element => (
  <Alert
    icon={<VrsIcon name={IconName.Info} stroke={verisColors.amethyst.dark} />}
    sx={{
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: (theme) => theme.veris.colors.amethyst.light,
      border: (theme) => `1px solid ${theme.veris.colors.amethyst.normal}`,
      height: "40px",
      mt: (theme) => theme.spacing(1),
      ...style,
    }}
  >
    <Box display="flex" alignItems="center" gap={0.5}>
      <Typography
        pt={0.5}
        variant="subtitle2"
        color={(theme) => theme.veris.colors.neutrals["grey-4"]}
      >
        This patient is inactive.
      </Typography>

      <Typography
        variant="subtitle1"
        pt={0.5}
        color={(theme) => theme.veris.colors.neutrals["grey-4"]}
      >
        There is no updated data available.
      </Typography>
    </Box>
  </Alert>
);

export const VrsDeceasedPatientAlert = ({
  style,
}: {
  style?: React.CSSProperties;
}): JSX.Element => (
  <Alert
    icon={<VrsIcon name={IconName.Info} stroke={verisColors.mango.dark} />}
    sx={{
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: (theme) => theme.veris.colors.mango.light,
      border: (theme) => `1px solid ${theme.veris.colors.mango.normal}`,
      height: "40px",
      mt: (theme) => theme.spacing(1),
      ...style,
    }}
  >
    <Box display="flex" alignItems="center" gap={0.5}>
      <Typography
        pt={0.5}
        variant="subtitle2"
        color={(theme) => theme.veris.colors.neutrals["grey-4"]}
      >
        Patient Deceased
      </Typography>
    </Box>
  </Alert>
);
