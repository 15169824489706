import React, { useEffect, useMemo } from "react";
import { IconName, VrsIconButton } from "@veris-health/web-core";
import { sortBy } from "lodash";
import { StyledNotificationBadge } from "../../../shared/styledComponents";
import { BillingNotificationsPopup } from "../../components/BillingNotificationsPopup/BillingNotificationsPopup";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import {
  selectUnconfirmedCallList,
  getUserUnconfirmedCallsList,
  selectSelectedNotification,
  setSelectedNotification,
} from "../../../shared/slices/voipSlice";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import { selectUserId } from "../../../shared/slices/authSlice";
import { NOTIFICATIONS_OFFSET } from "../../../../constants";
import { VideoBillingModal } from "../VideoBillingModal";

export const UnconfirmedBillingNotificationsContainer = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | undefined>(undefined);
  const notifications = useAppSelector(selectUnconfirmedCallList);
  const currentUserId = useAppSelector(selectUserId);
  const selectedNotification = useAppSelector(selectSelectedNotification);

  const sortedNotificationItems = useMemo(
    () => sortBy(notifications.items || [], "read"),
    [notifications.items],
  );

  useEffect(() => {
    dispatch(getUserUnconfirmedCallsList({ userId: Number(currentUserId), offset: 0 }));
  }, []);

  const handleUnconfirmedBillingClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      {selectedNotification && (
        <VideoBillingModal
          callId={selectedNotification}
          onClose={() => dispatch(setSelectedNotification(undefined))}
        />
      )}
      <StyledNotificationBadge
        invisible={!notifications.unread_count}
        variant="dot"
        onClick={(event) =>
          handleUnconfirmedBillingClick(event as React.MouseEvent<HTMLButtonElement>)
        }
      >
        <VrsIconButton
          data-test-hook="unconfirmed-billing-icon"
          iconProps={{
            name: anchorEl ? IconName.UnconfirmedBillingActive : IconName.UnconfirmedBilling,
            size: 36,
          }}
        />
      </StyledNotificationBadge>

      {!!anchorEl && currentUserId && (
        <BillingNotificationsPopup
          notifications={sortedNotificationItems || []}
          totalCount={notifications.total_count}
          anchorEl={anchorEl}
          handleClose={() => setAnchorEl(undefined)}
          selectNotification={(number) => {
            setAnchorEl(undefined);
            dispatch(setSelectedNotification(number));
          }}
          fetchNotifications={() =>
            dispatch(
              getUserUnconfirmedCallsList({
                userId: Number(currentUserId),
                offset: notifications.offset + NOTIFICATIONS_OFFSET,
              }),
            )
          }
        />
      )}
    </>
  );
};
