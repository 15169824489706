import { Box, BoxProps, Typography, TypographyVariant } from "@mui/material";
import { VrsIcon, IconName, verisColors } from "@veris-health/web-core";
import React from "react";

export interface PatientInfoLabelProps extends BoxProps {
  fieldName: string;
  fieldNameIcon?: string;
  color?: string;
  variant?: string;
}

export function PatientInfoLabel({
  fieldName,
  fieldNameIcon,
  color,
  sx,
  variant = "body",
}: PatientInfoLabelProps): JSX.Element {
  return (
    <Box display="flex" alignItems="center" sx={{ ...sx }}>
      {fieldNameIcon && (
        <VrsIcon name={IconName[fieldNameIcon as keyof typeof IconName]} size={20} stroke={color} />
      )}
      <Typography
        variant={variant as TypographyVariant}
        color={color ?? verisColors.neutrals["grey-3"]}
        pr={2}
        pl={1}
        sx={{ fontWeight: color ? 500 : 400 }}
      >
        {fieldName}
      </Typography>
    </Box>
  );
}
