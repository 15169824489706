import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  SessionEventType,
  StartSessionEventResponse,
  AppType,
} from "@veris-health/communication-ms/lib/v1";
import { MedRoleGroupEnum, ProfileRole } from "@veris-health/user-ms/lib/v1";
import dayjs from "dayjs";
import { Status } from "../interfaces";
import { addSessionEventActivity } from "./api/activityTrackingApi";
import { RootState } from "../../../store";
import { KEEP_ALIVE_TIMER } from "../../../constants";

interface ActivityTrackingState {
  sessionId: string;
  sessionInterval?: NodeJS.Timeout;
  status: Status;
  statusEndSession: Status;
}

const initialState: ActivityTrackingState = {
  sessionId: "",
  sessionInterval: undefined,
  status: "idle",
  statusEndSession: "idle",
};

export const endSessionEventActivity = createAsyncThunk<StartSessionEventResponse>(
  "communication/endSessionEventActivity",
  async (arg, { getState }) => {
    const { activityTracking } = getState() as RootState;

    if (activityTracking.sessionInterval) {
      clearInterval(activityTracking.sessionInterval);
    }

    const sessionEventActivity = {
      session_id: activityTracking.sessionId,
      event_type: SessionEventType.End,
      timestamp: dayjs().format(),
      app_type: AppType.Web,
    };

    const response = await addSessionEventActivity(sessionEventActivity);

    return response;
  },
  {
    condition: (_params, { getState }) => {
      const { activityTracking } = getState() as RootState;
      if (activityTracking.sessionId) {
        return true;
      }
      return false;
    },
  },
);

export const addNewSessionEventActivity = createAsyncThunk<
  StartSessionEventResponse,
  { eventType: SessionEventType }
>(
  "communication/addNewSessionEventActivity",
  async ({ eventType }, { getState }) => {
    const { activityTracking } = getState() as RootState;

    if (eventType === SessionEventType.End && activityTracking.sessionInterval) {
      clearInterval(activityTracking.sessionInterval);
    }

    const sessionEventActivity = {
      session_id: activityTracking.sessionId,
      event_type: eventType,
      timestamp: dayjs().format(),
      app_type: AppType.Web,
    };

    const response = await addSessionEventActivity(sessionEventActivity);

    return response;
  },
  {
    condition: (_params, { getState }) => {
      const { activityTracking } = getState() as RootState;
      if (activityTracking.sessionId) {
        return true;
      }
      return false;
    },
  },
);

export const startSessionEventActivity = createAsyncThunk<
  StartSessionEventResponse & { sessionInterval: NodeJS.Timeout },
  { patientId: number; medicId: number; roles: ProfileRole[] | undefined }
>(
  "communication/startSessionEventActivity",
  async ({ patientId, medicId }, { dispatch }) => {
    const sessionEventActivity = {
      patient_id: patientId,
      med_staff_id: medicId,
      event_type: SessionEventType.Start,
      timestamp: dayjs().format(),
      app_type: AppType.Web,
    };

    const response = await addSessionEventActivity(sessionEventActivity);

    const sessionInterval = setInterval(() => {
      dispatch(addNewSessionEventActivity({ eventType: SessionEventType.KeepAlive }));
    }, KEEP_ALIVE_TIMER);

    return { sessionInterval, ...response };
  },
  {
    condition: ({ roles }) => {
      if (roles && roles.find((role) => role.role_group === MedRoleGroupEnum.Billable)) {
        return true;
      }
      return false;
    },
  },
);

export const activityTrackingSlice = createSlice({
  name: "activityTracking",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(startSessionEventActivity.pending, (state) => {
        state.status = "loading";
      })
      .addCase(startSessionEventActivity.fulfilled, (state, action) => {
        state.status = "idle";
        state.sessionId = action.payload.session_id;
        state.sessionInterval = action.payload.sessionInterval;
      })
      .addCase(startSessionEventActivity.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(endSessionEventActivity.pending, (state) => {
        state.statusEndSession = "loading";
      })
      .addCase(endSessionEventActivity.fulfilled, (state) => {
        state.statusEndSession = "idle";
        state.sessionId = "";
        state.sessionInterval = undefined;
      })
      .addCase(endSessionEventActivity.rejected, (state) => {
        state.statusEndSession = "failed";
      });
  },
});

export default activityTrackingSlice.reducer;
