import { MbscCalendarColor } from "@mobiscroll/react/dist/src/core/shared/calendar-view/calendar-view";
import { alpha } from "@mui/material";
import { IconName, verisColors } from "@veris-health/web-core";

export const enum ResourceEnum {
  Appointments = 2,
  Medication = 3,
}

export const timelineResources = [
  {
    id: ResourceEnum.Appointments,
    name: "Appointments",
    img: IconName.CalendarAppointment,
    color: verisColors.moderate.light,
    backgroundColor: alpha(verisColors.moderate.light, 0.2),
  },
  {
    id: ResourceEnum.Medication,
    name: "Medication",
    img: IconName.Medication,
    color: verisColors.mango.normal,
    backgroundColor: alpha(verisColors.mango.light, 0.2),
  },
];

export const timelineColors: MbscCalendarColor[] = [
  {
    background: alpha(verisColors.moderate.light, 0.2),
    resource: ResourceEnum.Appointments,
    recurring: {
      repeat: "weekly",
      weekDays: "MO,TU,WE,TH,FR,SA,SU",
    },
  },
  {
    background: alpha(verisColors.mango.light, 0.2),
    resource: ResourceEnum.Medication,
    recurring: {
      repeat: "weekly",
      weekDays: "MO,TU,WE,TH,FR,SA,SU",
    },
  },
];

export const timelineConfigs = {
  day: {
    timeline: {
      type: "day",
      timeCellStep: 30,
      timeLabelStep: 30,
    },
  },
  week: {
    timeline: {
      type: "week",
      eventList: true,
    },
  },
  "bi-weekly": {
    timeline: {
      type: "week",
      size: 2,
      eventList: true,
    },
  },
  month: {
    timeline: { type: "month", eventList: true },
  },
  year: {
    timeline: { type: "month", eventList: true },
  },
};
