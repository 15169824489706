import React, { useRef, useState } from "react";
import {
  Badge,
  Box,
  Typography,
  styled,
  BadgeProps,
  Stack,
  MenuItem,
  Menu,
  CircularProgress,
} from "@mui/material";
import { ThreadParticipantInfo } from "@veris-health/communication-ms/lib/v1";
import {
  VrsIconButton,
  IconName,
  VrsAvatar,
  VrsDivider,
  verisColors,
} from "@veris-health/web-core";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { selectSingleThreadStatus } from "../../store";

export interface VrsGroupChatHeaderProps {
  participants: ThreadParticipantInfo[];
  onAddCareTeamMembers: (patient?: ThreadParticipantInfo) => void;
  onAddSupportTeamMembers: (patient?: ThreadParticipantInfo) => void;
  caption: string;
  label: string;
}

export const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: 11,
    top: 20,
    padding: "3px 4px 0",
    border: `1px solid ${theme.palette.background.paper}`,
    backgroundColor: theme.veris.colors.neutrals["grey-mid"],
    color: theme.veris.colors.neutrals.white,
  },
}));

const StyledMenu = styled(Menu)(({ theme }) => ({
  "& .MuiPaper-root": {
    backgroundColor: theme.veris.colors.neutrals["grey-1"],
    boxShadow: "none",
    border: `1px solid ${theme.veris.colors.neutrals["grey-2"]}`,
  },
}));

export const VrsGroupChatHeader = ({
  participants,
  onAddCareTeamMembers,
  onAddSupportTeamMembers,
  caption,
  label,
}: VrsGroupChatHeaderProps): JSX.Element => {
  const patient = participants.find((participant) => participant.user_type === "patient");
  const anchorRef = useRef(null);
  const [open, setOpen] = useState<boolean>(false);
  const threadStatus = useAppSelector(selectSingleThreadStatus);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box overflow="visible">
      <Box
        display="flex"
        sx={{
          backgroundColor: (theme) => theme.veris.colors.neutrals.white,
        }}
        alignItems="center"
        p={1}
      >
        {threadStatus === "loading" && (
          <Box display="flex" justifyContent="center" alignItems="center" width="10vw">
            <CircularProgress />
          </Box>
        )}
        {threadStatus === "idle" && (
          <>
            <StyledBadge
              badgeContent={participants ? participants.length : 2}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
            >
              <VrsAvatar
                name={patient?.display_name ?? "Unknown"}
                imageUrl={patient && patient.image && `data:image/png;base64,${patient.image}`}
              />
            </StyledBadge>
            <Box
              sx={{
                marginLeft: (theme) => theme.spacing(1),
                marginRight: (theme) => theme.spacing(3),
              }}
            >
              <Typography variant="subtitle2">{label}</Typography>
              <Typography
                variant="caption"
                sx={{ color: (theme) => theme.veris.colors.neutrals["grey-dark"] }}
              >
                {caption}
              </Typography>
            </Box>
          </>
        )}
        <Stack direction="row" ref={anchorRef}>
          <VrsIconButton
            iconProps={{ name: open ? IconName.ActiveDots : IconName.Dots }}
            onClick={() => setOpen(true)}
          />
          <StyledMenu anchorEl={anchorRef.current} open={open} onClose={handleClose}>
            <MenuItem
              onClick={() => {
                onAddCareTeamMembers(patient);
                handleClose();
              }}
            >
              <Typography variant="body" color={verisColors.neutrals["grey-4"]}>
                Add Clinical Care Team
              </Typography>
            </MenuItem>
            <VrsDivider height="80%" />
            <MenuItem
              onClick={() => {
                onAddSupportTeamMembers(patient);
                handleClose();
              }}
            >
              <Typography variant="body" color={verisColors.neutrals["grey-4"]}>
                Add Friends and Family Team Member
              </Typography>
            </MenuItem>
          </StyledMenu>
        </Stack>
      </Box>
      <VrsDivider orientation="horizontal" height="auto" />
    </Box>
  );
};
