import {
  ActivityTrackingApi as ActivityTrackingApiV1,
  ChatApi as ChatApiV1,
  ChatAttachmentsApi as ChatAttachmentsApiV1,
  NotificationsApi as NotificationsApiV1,
  VoipApi as VoipApiV1,
  ChatHistoryApi as ChatHistoryApiApiV1,
} from "@veris-health/communication-ms/lib/v1";
import config from "../axios-config";
import { API_ROUTES, getVersion, VERSIONS } from "../helpers";

export const chatApiV1 = new ChatApiV1(
  undefined,
  getVersion(API_ROUTES.COMMUNICATION, VERSIONS.v1),
  config,
);
export const chatAttachmentsApiV1 = new ChatAttachmentsApiV1(
  undefined,
  getVersion(API_ROUTES.COMMUNICATION, VERSIONS.v1),
  config,
);
export const chatHistoryApiV1 = new ChatHistoryApiApiV1(
  undefined,
  getVersion(API_ROUTES.COMMUNICATION, VERSIONS.v1),
  config,
);
export const notificationsApiV1 = new NotificationsApiV1(
  undefined,
  getVersion(API_ROUTES.COMMUNICATION, VERSIONS.v1),
  config,
);
export const voipApiV1 = new VoipApiV1(
  undefined,
  getVersion(API_ROUTES.COMMUNICATION, VERSIONS.v1),
  config,
);
export const activityTrackingApiV1 = new ActivityTrackingApiV1(
  undefined,
  getVersion(API_ROUTES.COMMUNICATION, VERSIONS.v1),
  config,
);
