import { Box, Typography } from "@mui/material";
import { VrsAvatar, VrsButton } from "@veris-health/web-core";
import React, { useMemo, useState } from "react";
import { ClinicalTask, ClinicalTaskComment } from "@veris-health/med-data-ms/lib/v1";
import dayjs from "dayjs";
import { VrsMedStaffProfileModel } from "../../shared/interfaces";
import { StyledTextField } from "../../PatientDetailsSidebar/components/SymptomReport/components/QuestionnaireAnswers";
import { calculateTimeFromNow, utcToLocal } from "../../../utils/date";

interface TaskCommentsProps {
  currentUser: VrsMedStaffProfileModel;
  comments: ClinicalTaskComment[];
  onChange: (comments: string[]) => void;
  patient?: {
    name: string;
    id: number;
    hospital?: number | number[];
    image?: string;
  };
  viewMode?: boolean;
  task?: ClinicalTask;
}
const TaskComment = ({
  text,
  dateCreated,
  creator,
}: {
  text: string;
  dateCreated: string;
  creator: string;
}): JSX.Element => {
  const timeFormat = useMemo(() => {
    return calculateTimeFromNow(utcToLocal(dateCreated).format());
  }, [dateCreated]);

  return (
    <Box display="flex" my={1}>
      <VrsAvatar name={creator} size={32} />
      <Box ml={3}>
        <Box display="flex" flexDirection="row">
          <Typography variant="bodySemibold">{creator}</Typography>
          <Typography
            variant="body"
            color={(theme) => theme.veris.colors.neutrals["grey-3"]}
            mx={1}
          >
            {timeFormat}
          </Typography>
        </Box>
        <Typography color={(theme) => theme.veris.colors.neutrals["grey-4"]} variant="body">
          {text}
        </Typography>
      </Box>
    </Box>
  );
};

const TaskComments = ({
  currentUser,
  comments,
  onChange,
  patient,
  task,
  viewMode,
}: TaskCommentsProps): JSX.Element => {
  const [inputValue, setInputValue] = useState("");
  const [newComments, setNewComments] = useState<string[]>([]);
  const onSubmit = () => {
    if (inputValue.trim()) {
      if (!task) setNewComments([...newComments, inputValue]);
      onChange([...newComments, inputValue]);
      setInputValue("");
    }
  };
  return (
    <Box width="100%">
      <Typography
        variant="subtitle1"
        color={(theme) => theme.veris.colors.neutrals["grey-4"]}
        my={3}
      >
        Notes
      </Typography>
      <Box display="flex" justifyContent="space-between" alignItems="center" my={2}>
        {" "}
        <VrsAvatar name={currentUser.fullName} size={32} />
        <StyledTextField
          name="new-comment"
          value={inputValue}
          onChange={(e) => {
            setInputValue(e.target.value);
          }}
          multiline
          fullWidth
          placeholder="Add a note here..."
          maxHeight="500px"
          sx={{
            marginLeft: (theme) => theme.spacing(2),
            ".MuiOutlinedInput-root": {
              display: "flex",
              flexDirection: "column",
              alignItems: " flex-end",
            },
          }}
          InputProps={{
            endAdornment: (
              <Box>
                <VrsButton
                  buttonType="secondary"
                  onClick={() => {
                    setInputValue("");
                    onChange([]);
                  }}
                  sx={{ marginX: 1 }}
                >
                  Cancel{" "}
                </VrsButton>
                <VrsButton buttonType="primary" disabled={!patient} onClick={onSubmit}>
                  Save{" "}
                </VrsButton>
              </Box>
            ),
          }}
          disabled={viewMode}
        />
      </Box>

      <Box my={4}>
        {newComments.length > 0 &&
          newComments.map((comment) => (
            <TaskComment
              key={`${comment}-${dayjs()}`}
              text={comment}
              creator={currentUser.fullName}
              dateCreated={dayjs().format()}
            />
          ))}
        {comments.length > 0 &&
          comments.map((comment) => (
            <TaskComment
              text={comment.text}
              creator={comment.created_by.full_name || ""}
              dateCreated={comment.date_created}
              key={comment.id}
            />
          ))}
      </Box>
    </Box>
  );
};

export default TaskComments;
