import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  AppApiV1ModelsChatModelsStatusResponse,
  AttachmentMetadata,
  ChatThreadInfo,
  CommunicationIdentityTokenResponse,
  Permissions,
  SupportedAttachmentTypesEnum,
  ThreadParticipant,
  URLResponse,
} from "@veris-health/communication-ms/lib/v1";
import { ChatMessage, ChatThreadItem } from "@azure/communication-chat";
import dayjs from "dayjs";
import * as Sentry from "@sentry/react";
import { RootState } from "../../../store";
import {
  createChatThread,
  downloadFile,
  fetchAllChatAttachmentsForThread,
  fetchChatApiEndpoint,
  fetchChatUserAccessToken,
  generateChatHistoryAttachmentSas,
  generateSas,
  getChatHistoryThreadMessages,
  getChatHistoryThreads,
  getChatThreadDetails,
  getPrivateChatForPatient,
  getThreadsWithUnreadInfo,
  joinChatThread,
  searchChatThreads,
  uploadFileApi,
} from "../api/chatApi";
import { fetchProfileInfo } from "../../shared/slices/api/chatApi";
import { getChatClient } from "../utils/AzureChatClient";
import downloadBlob from "../utils/downloadBlob";
import {
  AzureChatThreadItem,
  NormalizedAttachments,
  NormalizedChatMessages,
  VrChatHistoryAttachment,
  VrsChatHistoryMessage,
  VrsChatMessage,
  VrsChatMessageContent,
  VrsChatThread,
} from "./interfaces";
import { MESSAGES_PAGE_SIZE } from "../../../constants";

export const fetchAttachmentsForThreadAsync = createAsyncThunk(
  "communication/fetchAttachmentsForThread",
  async (
    { threadId, onlyLatest, limit }: { threadId: string; onlyLatest?: boolean; limit?: number },
    { getState },
  ) => {
    const { auth, communication } = getState() as RootState;
    const { userId } = auth;
    const { attachments } = communication;

    const existingItems = attachments.items.entities[threadId];

    const fetchedAttachments = await fetchAllChatAttachmentsForThread(
      Number(userId),
      threadId,
      onlyLatest ? 0 : existingItems?.attachments.length,
      onlyLatest ? 1 : limit,
    );
    const selectedAttachments = onlyLatest
      ? [fetchedAttachments.items[0]]
      : fetchedAttachments.items;

    const expandedItemsList = await Promise.all(
      selectedAttachments.map(async (attachment) => {
        if (
          (attachment.file_extension === SupportedAttachmentTypesEnum.Jpg ||
            attachment.file_extension === SupportedAttachmentTypesEnum.Jpeg ||
            attachment.file_extension === SupportedAttachmentTypesEnum.Png) &&
          userId
        ) {
          let imageSrc = "";
          await generateSas(+userId, attachment.file_name, Permissions.Read).then((response) => {
            const { blobEndpoint, containerName, blobName, sharedAccessSigniture } = response;
            imageSrc = `${blobEndpoint}/${containerName}/${blobName}?${sharedAccessSigniture}`;
          });
          return { ...attachment, imageSrc };
        }
        return attachment;
      }),
    );
    const response: NormalizedAttachments = {
      id: threadId,
      attachments: [...(existingItems?.attachments || []), ...expandedItemsList],
      offset: fetchedAttachments.offset,
      total: fetchedAttachments.total,
      limit: fetchedAttachments.limit,
    };
    return response;
  },
);

export const fetchChatConfiguration = createAsyncThunk<
  URLResponse & CommunicationIdentityTokenResponse & { chatDisplayName: string },
  { id: number }
>("communication/fetchChatConfig", async ({ id }, { rejectWithValue }) => {
  try {
    const { first_name: firstName, last_name: lastName } = await fetchProfileInfo(id);
    const chatDisplayName = `${firstName} ${lastName}`;
    const [chatApiEndpoint, chatUserAccessToken] = await Promise.all([
      fetchChatApiEndpoint(),
      fetchChatUserAccessToken(id),
    ]);
    return {
      ...chatApiEndpoint,
      ...chatUserAccessToken,
      chatDisplayName,
    };
  } catch (error) {
    Sentry.captureException(error);
    return rejectWithValue(error);
  }
});

export const getUnreadPerThreadAsync = createAsyncThunk(
  "communication/unreadPerThread",
  async (userId: number) => {
    const response = await getThreadsWithUnreadInfo(userId);
    return response.threads;
  },
);

export const fetchChatThreadsCombinedAsync = createAsyncThunk<
  { azureChatThreads: AzureChatThreadItem[]; vrsChatThreads: ChatThreadInfo[] },
  { userId: string }
>(
  "communication/fetchChatThreadsCombinedAsync",
  async ({ userId }, { rejectWithValue, getState }) => {
    const { communication } = getState() as RootState;
    const { chatApiEndpoint, chatUserAccessToken } = communication;
    const azureChatClient = getChatClient({
      endpoint: chatApiEndpoint,
      token: chatUserAccessToken,
    });
    try {
      const [azureChatThreads, vrsChatThreads] = await Promise.all([
        (await azureChatClient.listChatThreads().byPage().next()).value as ChatThreadItem[],
        searchChatThreads(userId),
      ]);
      return {
        azureChatThreads: azureChatThreads
          .filter(({ deletedOn }) => !deletedOn)
          .map(({ id, topic, lastMessageReceivedOn }) => ({
            id,
            topic,
            lastMessageReceivedOn:
              lastMessageReceivedOn && dayjs(lastMessageReceivedOn).toISOString(),
          })),
        vrsChatThreads,
      };
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue(error);
    }
  },
);

export const addChatThreadAsync = createAsyncThunk<
  VrsChatThread,
  { threadId: string; topic: string }
>(
  "communication/addChatThreadAsync",
  async ({ threadId, topic }, { getState, rejectWithValue }) => {
    try {
      const { auth, communication } = getState() as RootState;
      const { userId } = auth;
      const currentThreadDetails = communication.chatThreads.threads.find(
        (thread) => thread.id === threadId,
      );
      if (currentThreadDetails) {
        return { ...currentThreadDetails, topic };
      }
      const chatThread = await getChatThreadDetails(userId, threadId);
      return { ...chatThread, topic };
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      return rejectWithValue(error);
    }
  },
);

export const joinChatThreadAsync = createAsyncThunk<
  { response: AppApiV1ModelsChatModelsStatusResponse; threadId: string },
  { id: number; threadId: string; participants: ThreadParticipant[] }
>("communication/joinChatThread", async ({ id, threadId, participants }) => {
  const response = await joinChatThread(id, threadId, participants);
  return { response, threadId };
});

export const getChatThreadMessagesAsync = createAsyncThunk<
  NormalizedChatMessages,
  { threadId: string; endpoint: string; token: string; continuationToken?: string },
  { rejectValue: string }
>(
  "communication/getChatThreadMessagesAsync",
  async ({ threadId, endpoint, token, continuationToken }, { dispatch }) => {
    const chatClient = getChatClient({ endpoint, token });
    const chatThreadClient = chatClient.getChatThreadClient(threadId);
    let newContToken;
    const threadClientResponse = await chatThreadClient
      .listMessages({
        maxPageSize: MESSAGES_PAGE_SIZE,
        onResponse: (response) => {
          newContToken = response.parsedBody.nextLink;
        },
      })
      .byPage({ continuationToken })
      .next();
    const messages = threadClientResponse.value as ChatMessage[];
    const [lastMessage] = messages;
    await chatThreadClient.sendReadReceipt({ chatMessageId: lastMessage.id });

    const { serializedMessages, attachmentsToFetch } = messages.reduce(
      (acc: { serializedMessages: VrsChatMessage[]; attachmentsToFetch: number }, message) => {
        if (message.type === "text") {
          const extendedMsgObj = {
            ...message,
            createdOn: dayjs(message.createdOn).toISOString(),
            editedOn: message.editedOn && dayjs(message.editedOn).toISOString(),
            deletedOn: message.deletedOn && dayjs(message.deletedOn).toISOString(),
          };
          acc.serializedMessages.push(extendedMsgObj);
          if (message.metadata) {
            acc.attachmentsToFetch += 1;
          }
        }
        return acc;
      },
      { serializedMessages: [], attachmentsToFetch: 0 },
    );
    if (attachmentsToFetch > 0) {
      await dispatch(
        fetchAttachmentsForThreadAsync({
          threadId,
          onlyLatest: false,
          limit: attachmentsToFetch,
        }),
      );
    }
    return {
      id: threadId,
      continuationToken: newContToken,
      messages: serializedMessages,
      isLastPage: !!threadClientResponse.done,
    };
  },
);

export const createChatThreadAsync = createAsyncThunk<
  ChatThreadInfo,
  { id: number; participants: ThreadParticipant[] }
>(
  "communication/createChatThreadAsync",
  async ({ id, participants }, { rejectWithValue, getState }) => {
    try {
      const { thread_id: threadId } = await createChatThread(id, [...participants]);
      const { communication } = getState() as RootState;
      const currentThreadDetails = communication.chatThreads.threads.find(
        (thread) => thread.id === threadId,
      );
      if (currentThreadDetails) {
        return currentThreadDetails;
      }
      const response = await getChatThreadDetails(String(id), threadId);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const sendChatThreadMessage = createAsyncThunk(
  "communication/sendChatThreadMessage",
  async (
    { threadId, message }: { threadId: string; message: VrsChatMessageContent },
    { getState },
  ) => {
    const { communication } = getState() as RootState;
    const { chatApiEndpoint, chatUserAccessToken, chatDisplayName } = communication;
    const chatClient = getChatClient({ endpoint: chatApiEndpoint, token: chatUserAccessToken });
    try {
      const chatThreadClient = chatClient.getChatThreadClient(threadId);
      const response = await chatThreadClient.sendMessage(
        {
          content: message.text,
        },
        {
          senderDisplayName: chatDisplayName,
          metadata: message.attachment && { ...message.attachment },
        },
      );
      return response.id;
    } catch (error) {
      // TODO: handle error
      // eslint-disable-next-line no-console
      console.error(error);
      return error;
    }
  },
);

export const uploadAttachmentAsync = createAsyncThunk(
  "communication/uploadAttachmentAsync",
  async (
    {
      file,
    }: {
      file: File;
    },
    { getState, dispatch },
  ) => {
    const { communication, auth } = getState() as RootState;
    const { userId } = auth;
    const { currentThreadId } = communication;
    try {
      const {
        blob_container: containerName,
        original_file_name: originalFileName,
        file_name: fileName,
        file_extension: fileExtension,
        blob_endpoint: blobEndpoint,
      } = await uploadFileApi(Number(userId), String(currentThreadId), file);

      dispatch(
        sendChatThreadMessage({
          threadId: String(currentThreadId),
          message: {
            text: "",
            attachment: {
              attachmentUuid: fileName,
              attachmentName: originalFileName,
              attachmentUrl: `${blobEndpoint}${containerName}/${fileName}`,
              attachmentFileType: fileExtension ?? "unknown",
            },
          },
        }),
      );
      dispatch(
        fetchAttachmentsForThreadAsync({ threadId: String(currentThreadId), onlyLatest: true }),
      );
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  },
);

export const downloadAttachmentSasAsync = createAsyncThunk<
  { objectUrl: string; messageId: string; threadId: string } | undefined,
  { metadata: AttachmentMetadata; messageId: string; threadId: string }
>(
  "communication/downloadAttachmentSasAsync",
  async ({ metadata, messageId, threadId }, { getState, rejectWithValue }) => {
    const { auth } = getState() as RootState;
    const { userId } = auth;
    const { attachmentUuid, attachmentName } = metadata;
    try {
      const { blobEndpoint, containerName, blobName, sharedAccessSigniture } = await generateSas(
        Number(userId),
        attachmentUuid,
        Permissions.Read,
      );

      const blob = await downloadFile({
        blobEndpoint,
        containerName,
        blobName,
        sharedAccessSigniture,
      });

      if (!blob) {
        return undefined;
      }

      downloadBlob(blob, attachmentName);

      return { objectUrl: URL.createObjectURL(blob), messageId, threadId };
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      return rejectWithValue(error);
    }
  },
);

export const downloadChatHistoryAttachmentSasAsync = createAsyncThunk<
  { objectUrl: string; messageId: string; threadId: string } | undefined,
  { metadata: AttachmentMetadata; messageId: string; threadId: string; patientId: number }
>(
  "communication/downloadChatHistoryAttachmentSasAsync",
  async ({ metadata, messageId, threadId, patientId }, { rejectWithValue }) => {
    const { attachmentUuid, attachmentName } = metadata;
    try {
      const { blobEndpoint, containerName, blobName, sharedAccessSigniture } =
        await generateChatHistoryAttachmentSas(
          Number(patientId),
          attachmentUuid,
          Permissions.Read,
          threadId,
        );

      const blob = await downloadFile({
        blobEndpoint,
        containerName,
        blobName,
        sharedAccessSigniture,
      });

      if (!blob) {
        return undefined;
      }

      downloadBlob(blob, attachmentName);

      return { objectUrl: URL.createObjectURL(blob), messageId, threadId };
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      return rejectWithValue(error);
    }
  },
);

export const getPrivateChatAsync = createAsyncThunk(
  "communication/getPrivateChat",
  async ({ patientId, userId }: { patientId: number; userId: string }) => {
    const { thread_id: privateThreadId } = await getPrivateChatForPatient(patientId);
    const threadDetails = await getChatThreadDetails(userId, privateThreadId);
    return threadDetails;
  },
);

export const updateThreadAsync = createAsyncThunk(
  "communication/updateThread",
  async ({ userId, threadId }: { userId: string; threadId: string }) => {
    const thread = await getChatThreadDetails(userId, threadId);
    return thread;
  },
);

export const getChatHistoryThreadsAsync = createAsyncThunk(
  "communication/getChatHistoryThreads",
  async ({ userId }: { userId: number }) => {
    const response = await getChatHistoryThreads(userId);
    return { id: userId, threads: response };
  },
);

export const fetchAttachmentsForChatHistoryThreadAsync = createAsyncThunk(
  "communication/fetchAttachmentsForChatHistoryThread",
  async (
    {
      threadId,
      userId,
      attachments,
    }: {
      threadId: string;
      userId: number;
      limit?: number;
      attachments: VrChatHistoryAttachment[];
    },
    { getState },
  ) => {
    const { communication } = getState() as RootState;
    const { chatHistoryAttachments } = communication;

    const existingItems = chatHistoryAttachments.attachments;
    const expandedItemsList = await Promise.all(
      attachments.map(async (attachment) => {
        if (
          (attachment.attachmentFileType === SupportedAttachmentTypesEnum.Jpg ||
            attachment.attachmentFileType === SupportedAttachmentTypesEnum.Jpeg ||
            attachment.attachmentFileType === SupportedAttachmentTypesEnum.Png) &&
          userId
        ) {
          let imageSrc = "";
          await generateChatHistoryAttachmentSas(
            +userId,
            attachment.attachmentUuid,
            Permissions.Read,
            threadId,
          ).then((response) => {
            const { blobEndpoint, containerName, blobName, sharedAccessSigniture } = response;
            imageSrc = `${blobEndpoint}/${containerName}/${blobName}?${sharedAccessSigniture}`;
          });
          return {
            ...attachment,
            file_name: attachment.attachmentUuid,
            original_file_name: attachment.attachmentName,
            file_extension: attachment.attachmentFileType,
            imageSrc,
          };
        }
        return attachment;
      }),
    );
    const response = {
      id: threadId,
      attachments: [...(existingItems || []), ...expandedItemsList],
    };
    return response;
  },
);

export const getChatHistoryThreadMessagesAsync = createAsyncThunk(
  "communication/getChatHistoryThreadMessages",
  async (
    {
      userId,
      threadId,
      items,
      nextLink,
    }: {
      userId: number;
      threadId: string;
      items: number;
      nextLink?: string;
    },
    { dispatch },
  ) => {
    const data = await getChatHistoryThreadMessages(userId, threadId, items, nextLink);

    const { serializedMessages, attachmentsToFetch } = data.value.reduce(
      (
        acc: {
          serializedMessages: VrsChatHistoryMessage[];
          attachmentsToFetch: VrChatHistoryAttachment[];
        },
        message,
      ) => {
        if (message.type === "text") {
          const extendedMsgObj = {
            ...message,
            sender: {
              communicationUserId: message.senderCommunicationIdentifier?.communicationUser.id,
            },
          };
          acc.serializedMessages.push(extendedMsgObj);
          if (message.metadata) {
            acc.attachmentsToFetch.push(message.metadata as unknown as VrChatHistoryAttachment);
          }
        }
        return acc;
      },
      { serializedMessages: [], attachmentsToFetch: [] },
    );

    if (attachmentsToFetch.length > 0) {
      await dispatch(
        fetchAttachmentsForChatHistoryThreadAsync({
          threadId,
          userId,
          attachments: attachmentsToFetch,
        }),
      );
    }

    const mappedData = {
      ...data,
      value: serializedMessages,
    };

    return { id: userId, data: mappedData, threadId };
  },
);
