import { ThreadParticipantInfo } from "@veris-health/communication-ms/lib/v1";
import { useMemo } from "react";
import { getConcatTextFromObjectArray } from "../../shared/helpers";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { selectUserId } from "../../shared/slices/authSlice";

export const useChatLabels = (
  chatParticipants: ThreadParticipantInfo[] = [],
  doctorThread = false,
): { label: string; caption: string } => {
  const userId = useAppSelector(selectUserId);
  return useMemo(() => {
    const participants = chatParticipants
      .filter((participant) => participant.user_id !== Number(userId))
      .filter((part) => {
        if (doctorThread) return part.user_type !== "patient";
        return true;
      })
      .sort((a, b) => a.display_name.localeCompare(b.display_name));
    const label = getConcatTextFromObjectArray(participants, "display_name");
    const caption = getConcatTextFromObjectArray(participants, "user_type", true);
    return { label, caption };
  }, [chatParticipants]);
};
