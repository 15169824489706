import { IconName, verisColors } from "@veris-health/web-core";
import { PainSeverityEnum, ReportStatusEnum } from "@veris-health/user-ms/lib/v1";
import { Box, Typography, styled } from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { ExportSection, ExportSectionProps } from "../ExportSection";
import { symptomSeverityColor, symptomStatusColor } from "../../../../../utils/symptoms";
import {
  SensorsGraphView,
  SymptomStatusEnum,
  selectDateFrom,
  selectDateTo,
} from "../../../../PatientDetailsMeasurements/measurementSlice";
import SymptomsViewContainer from "../../../../PatientDetailsMeasurements/containers/SymptomsViewContainer";
import { useAppSelector } from "../../../../../hooks/useAppSelector";
import { VrsPatientInfo } from "../../../../shared/interfaces";
import { DateHeader } from "./DateHeader";

const StyledCircle = styled(Box)(() => ({
  justifyContent: "center",
  display: "flex",
  borderRadius: "100%",
  height: "22px",
  width: "22px",
}));

const symptomStatus: SymptomStatusEnum[] = [
  ReportStatusEnum.Resolved,
  ReportStatusEnum.Incomplete,
  ReportStatusEnum.Deleted,
  "empty",
  "no_update",
  "incomplete_no_update",
];

interface SymptomSummariesProps extends ExportSectionProps {
  currentPatient: VrsPatientInfo;
}

export function SymptomSummaries({
  title,
  include,
  onInclude,
  currentPatient,
}: SymptomSummariesProps): JSX.Element {
  const startDate = useAppSelector(selectDateFrom);
  const endDate = useAppSelector(selectDateTo);
  const [view, setView] = useState<SensorsGraphView>("weekly");

  useEffect(() => {
    const diff = dayjs(endDate).diff(startDate, "days");

    switch (true) {
      case diff < 1:
        setView("daily");
        break;
      case diff === 3:
        setView("3-days");
        break;
      case diff > 4 && diff <= 10:
        setView("weekly");
        break;
      case diff > 11:
        setView("monthly");
        break;
      default:
        setView("weekly");
        break;
    }
  }, [startDate, endDate]);

  return (
    <ExportSection
      title={title}
      iconName={IconName.Stethoscope}
      include={include}
      onInclude={onInclude}
    >
      <Box display="flex" gap={3} pt={1} className="break-before">
        {Object.values(PainSeverityEnum).map((el) => {
          return (
            <Box display="flex" gap={1}>
              <StyledCircle
                sx={{
                  backgroundColor: symptomSeverityColor[el].fillColor,
                }}
              >
                <Typography
                  color={verisColors.neutrals["grey-5"]}
                  variant="body"
                  sx={{ position: "relative", top: "1.5px" }}
                >
                  {symptomSeverityColor[el].fillValue}
                </Typography>
              </StyledCircle>
              <Typography
                color={verisColors.neutrals["grey-5"]}
                variant="body"
                sx={{ position: "relative", top: "1.5px", textTransform: "capitalize" }}
              >
                {el}
              </Typography>
            </Box>
          );
        })}
        {symptomStatus.map((el) => {
          return (
            <Box display="flex" gap={1}>
              <StyledCircle
                sx={{
                  backgroundColor: symptomStatusColor[el].fillColor,
                  border: `1px solid ${
                    el === "empty"
                      ? verisColors.neutrals["grey-2"]
                      : symptomStatusColor[el].fillColor
                  }`,
                }}
              >
                <Typography
                  color={verisColors.neutrals["grey-5"]}
                  variant="body"
                  sx={{ position: "relative", top: "1.5px" }}
                >
                  {symptomStatusColor[el].fillValue}
                </Typography>
              </StyledCircle>
              <Typography
                color={verisColors.neutrals["grey-5"]}
                variant="body"
                sx={{ position: "relative", top: "1.5px", textTransform: "capitalize" }}
              >
                {el.replaceAll("_", " ")}
              </Typography>
            </Box>
          );
        })}
      </Box>

      <DateHeader startDate={startDate} endDate={endDate} view={view} />

      <SymptomsViewContainer
        isPreview={false}
        view={view}
        currentPatient={currentPatient}
        summary
      />
    </ExportSection>
  );
}
