import React, { ChangeEvent } from "react";
import {
  Box,
  styled,
  Typography,
  CircularProgress,
  InputAdornment,
  inputBaseClasses,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { CareTeamMemberInfo, SupportTeamMember } from "@veris-health/user-ms/lib/v1";
import { Formik, FieldArray, Form } from "formik";
import { IconName, VrsIcon, VrsButton, VrsDialog, VrsErrorMsg } from "@veris-health/web-core";
import { VrsMemberWithCheckbox } from "../../../../ui/components/VrsMemberWithCheckbox";
import { Status } from "../../../shared/interfaces";

export interface VrsTeamMemberSelectionProps {
  type: "care" | "support";
  patientCareTeam: { supportTeam: SupportTeamMember[]; careTeam: CareTeamMemberInfo[] };
  onAddMembers: (members: SupportTeamMember[] | CareTeamMemberInfo[]) => void;
  onClose: () => void;
  open: boolean;
  searchValue: string;
  onSearchValueChange: (value: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  status: Status;
}

export const StyledTextField = styled(TextField)<TextFieldProps>(({ theme }) => ({
  [`& .${inputBaseClasses.input}`]: {
    ...theme.typography.body,
    padding: theme.spacing(1, 0),
  },
}));

export const VrsTeamMemberSelection = ({
  type,
  patientCareTeam,
  onAddMembers,
  onClose,
  open,
  searchValue,
  onSearchValueChange,
  status,
}: VrsTeamMemberSelectionProps): JSX.Element => {
  return (
    <VrsDialog
      open={open}
      onClose={onClose}
      title={type === "care" ? "Clinical Care Team member" : "Friends and Family Team member"}
      subtitle="Select a member from the list to add to the chat"
      height="100px"
      width="530px"
    >
      <Box pt={4}>
        {status === "idle" && (
          <>
            <StyledTextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <VrsIcon name={IconName.Search} />
                  </InputAdornment>
                ),
              }}
              type="text"
              fullWidth
              placeholder="Search by name"
              onChange={onSearchValueChange}
              value={searchValue}
              autoFocus
            />
            <Box>
              <Formik
                initialValues={{ selectedMembers: [] }}
                onSubmit={(values: {
                  selectedMembers: CareTeamMemberInfo[] | SupportTeamMember[];
                }) => {
                  onAddMembers(values.selectedMembers);
                }}
              >
                {({ values }) => (
                  <Form>
                    <FieldArray name="selectedMembers">
                      {(arrayHelpers) => (
                        <Box
                          sx={{
                            margin: (theme) => `${theme.spacing(4)} 0`,
                            overflowY: "scroll",
                            maxHeight: "240px",
                          }}
                        >
                          {type === "care" &&
                            patientCareTeam.careTeam.length > 0 &&
                            patientCareTeam.careTeam.map((member) => {
                              const selectedMember = values.selectedMembers.find(
                                (el: CareTeamMemberInfo | SupportTeamMember) =>
                                  el?.id === member.id,
                              );
                              return (
                                <VrsMemberWithCheckbox
                                  member={member}
                                  onChange={() => {
                                    const value = !selectedMember;
                                    if (value) {
                                      arrayHelpers.push(member);
                                    } else {
                                      const indexToRemove = values.selectedMembers.findIndex(
                                        ({ id }) => id === member.id,
                                      );
                                      arrayHelpers.remove(indexToRemove);
                                    }
                                  }}
                                  checked={!!selectedMember}
                                  key={member.id}
                                />
                              );
                            })}
                          {type === "care" && patientCareTeam.careTeam.length === 0 && (
                            <Typography variant="subtitle1">No Members.</Typography>
                          )}
                          {type === "support" &&
                            patientCareTeam.supportTeam.length > 0 &&
                            patientCareTeam.supportTeam.map((member) => {
                              const selectedMember = values.selectedMembers.find(
                                (el: CareTeamMemberInfo | SupportTeamMember) =>
                                  el?.id === member.id,
                              );
                              return (
                                <VrsMemberWithCheckbox
                                  member={member}
                                  onChange={() => {
                                    const value = !selectedMember;
                                    if (value) {
                                      arrayHelpers.push(member);
                                    } else {
                                      const indexToRemove = values.selectedMembers.findIndex(
                                        ({ id }) => id === member.id,
                                      );
                                      arrayHelpers.remove(indexToRemove);
                                    }
                                  }}
                                  checked={!!selectedMember}
                                  key={member.id}
                                />
                              );
                            })}
                          {type === "support" && patientCareTeam.supportTeam.length === 0 && (
                            <Typography
                              pt={2}
                              color={(theme) => theme.veris.colors.neutrals["grey-3"]}
                              textAlign="center"
                              variant="body"
                            >
                              No Members.
                            </Typography>
                          )}
                        </Box>
                      )}
                    </FieldArray>
                    <Box display="flex" justifyContent="space-around">
                      <VrsButton
                        buttonType="primary"
                        type="submit"
                        sx={{ width: "150px", alignSelf: "center" }}
                        disabled={values.selectedMembers.length < 1}
                      >
                        Add members
                      </VrsButton>
                    </Box>
                  </Form>
                )}
              </Formik>
            </Box>
          </>
        )}

        {status === "loading" && (
          <Box display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        )}
        {status === "failed" && <VrsErrorMsg />}
      </Box>
    </VrsDialog>
  );
};
