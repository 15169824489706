import {
  ClinicalTask,
  CreateClinicalTaskAPI,
  ClinicalTaskPriorityEnum,
  ClinicalTaskSortingEnum,
  ListTasksResponse,
  ClinicalTaskComment,
  UpdateClinicalTaskAPI,
  CreateClinicalTaskCommentAPI,
} from "@veris-health/med-data-ms/lib/v1";
import { tasksApi } from "../../../api";

export const fetchTasks = async (
  sorting?: ClinicalTaskSortingEnum[],
  assigneeId?: number,
  patientId?: number,
  taskTeamMemberId?: number,
  reporterId?: number,
  priority?: ClinicalTaskPriorityEnum,
  limit?: number,
  offset?: number,
): Promise<ListTasksResponse> => {
  const response = await tasksApi.getClinicalTasks(
    sorting,
    undefined,
    assigneeId,
    patientId,
    reporterId,
    taskTeamMemberId,
    undefined,
    priority,
    undefined,
    limit,
    offset,
  );
  return response.data;
};

export const createTask = async (
  patientId: number,
  data: CreateClinicalTaskAPI,
): Promise<ClinicalTask> => {
  const response = await tasksApi.createClinicalTask(patientId, data);
  return response.data;
};

export const getCommentsPerTask = async (
  patientId: number,
  taskId: number,
): Promise<ClinicalTaskComment[]> => {
  const response = await tasksApi.getClinicalTaskComments(patientId, taskId);
  return response.data;
};

export const editTask = async (
  patientId: number,
  taskId: number,
  data: UpdateClinicalTaskAPI,
): Promise<ClinicalTask> => {
  const response = await tasksApi.updateClinicalTask(patientId, taskId, data);
  return response.data;
};

export const addCommentToTask = async (
  patientId: number,
  taskId: number,
  data: CreateClinicalTaskCommentAPI,
): Promise<ClinicalTaskComment> => {
  const response = await tasksApi.addClinicalTaskComment(patientId, taskId, data);
  return response.data;
};
