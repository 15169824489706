import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  AppointmentCreateRequest,
  AppointmentAttendeeStatusChangeEnum,
  GetAppointmentResponse as Appointment,
  AppointmentStatusEnum,
} from "@veris-health/med-data-ms/lib/v1";
import { chunk } from "lodash";
import { RootState } from "../../../store";
import { fetchCalendarEventsProps } from "../../Calendar/calendarSlice";
import { extractErrorMessage } from "../helpers";
import { bookAppointment, fetchCalendarEvents, respondToAppointment } from "./api/calendarApi";
import { fetchTreatmentPlanEventsAsync } from "../../TreatmentPlan/treatmentPlanSlice";
import { getPatientAvatars, getPatientDetails, searchPatients } from "./api/userMsApi";
import { PATIENT_DETAILS_PER_PAGE } from "../../../constants";
import { VrsPatientInfo } from "../interfaces";

export const getPatientAvatarsAsync = createAsyncThunk(
  "shared/getPatientAvatarsAsync",
  async (ids: number[]) => {
    const response = await getPatientAvatars(ids);
    return response;
  },
);

export const searchPatientsAsync = createAsyncThunk(
  "shared/searchPatientsAsync",
  async (allPatients: { allPatients?: boolean } = { allPatients: false }, { dispatch }) => {
    const response = await searchPatients(allPatients);

    const ids = response.patients.map(({ id }) => id);

    const chunks = chunk(ids, PATIENT_DETAILS_PER_PAGE);

    chunks.forEach((batch) => {
      dispatch(getPatientAvatarsAsync(batch));
    });

    return response;
  },
);

export const getPatientByIdAsync = createAsyncThunk<
  { patient: VrsPatientInfo },
  { userId: number; ignoreLocalState?: boolean },
  { rejectValue: string }
>(
  "sharedSlice/getPatientByIdAsync",
  async ({ userId }, { rejectWithValue }) => {
    try {
      const patient = await getPatientDetails(userId);

      return { patient };
    } catch (err) {
      const errorMsg = extractErrorMessage(err);
      if (errorMsg) return rejectWithValue(errorMsg || "Could not load patient data.");
      throw err;
    }
  },
  {
    condition: (
      { userId, ignoreLocalState }: { userId: number; ignoreLocalState?: boolean },
      { getState },
    ) => {
      const { sharedPatients } = getState() as RootState;
      return (
        ignoreLocalState ||
        (sharedPatients.data.totalCount !== -1 && !sharedPatients.data.patients.entities[userId])
      );
    },
  },
);

export const fetchCalendarEventsAsync = createAsyncThunk(
  "sharedSlice/fetchCalendarEventsAsync",
  async ({ userId, start, end, statuses }: fetchCalendarEventsProps) => {
    const events = await fetchCalendarEvents(userId, start, end, statuses);
    return { id: userId, events };
  },
);

export const bookAppointmentAsync = createAsyncThunk<
  Appointment | Error,
  {
    appointment: AppointmentCreateRequest;
    currentPatientId?: number;
    currentDoctorId?: number;
    start: string;
    end: string;
  },
  { rejectValue: string }
>(
  "sharedSlice/bookAppointment",
  async (
    { appointment, currentPatientId, currentDoctorId, start, end },
    { rejectWithValue, dispatch, getState },
  ) => {
    try {
      const { auth } = getState() as RootState;
      // Note: booking is always with the doctor's id (users)
      const response = await bookAppointment(Number(currentDoctorId ?? auth.userId), appointment);
      // Note: fetching of the events can be either doctor's or patient's appointments
      if (currentPatientId)
        dispatch(
          fetchTreatmentPlanEventsAsync({
            userId: +currentPatientId,
            start,
            end,
          }),
        );
      else if (currentDoctorId)
        dispatch(
          fetchCalendarEventsAsync({
            userId: +currentDoctorId,
            start,
            end,
            statuses: [
              AppointmentStatusEnum.Booked,
              AppointmentStatusEnum.Completed,
              AppointmentStatusEnum.Proposed,
            ],
          }),
        );
      else if (auth.userId)
        dispatch(
          fetchCalendarEventsAsync({
            userId: +auth.userId,
            start,
            end,
            statuses: [
              AppointmentStatusEnum.Booked,
              AppointmentStatusEnum.Completed,
              AppointmentStatusEnum.Proposed,
            ],
          }),
        );
      return response;
    } catch (err) {
      const errorMsg = extractErrorMessage(err);
      return rejectWithValue(errorMsg || "Could not book appointment.");
    }
  },
);
export const changeAppointmentStatusAsync = createAsyncThunk<
  void | Error,
  {
    userId: number;
    appointmentId: string;
    appointmentStatus: AppointmentAttendeeStatusChangeEnum;
  },
  { rejectValue: string }
>(
  "sharedSlice/changeAppointmentStatus",
  async ({ userId, appointmentId, appointmentStatus }, { rejectWithValue, dispatch, getState }) => {
    try {
      const response = await respondToAppointment(userId, appointmentId, appointmentStatus);
      const { calendar } = getState() as RootState;
      dispatch(
        fetchCalendarEventsAsync({
          userId,
          start: calendar.start,
          end: calendar.end,
          statuses: [
            AppointmentStatusEnum.Booked,
            AppointmentStatusEnum.Completed,
            AppointmentStatusEnum.Proposed,
          ],
        }),
      );
      return response;
    } catch (err) {
      const errorMsg = extractErrorMessage(err);
      return rejectWithValue(errorMsg || "Could not change appointment's status.");
    }
  },
);
