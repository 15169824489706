import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import relativeTime from "dayjs/plugin/relativeTime";
import { dateFormats } from "@veris-health/web-core";

dayjs.extend(relativeTime);
dayjs.extend(utc);

export const calculateTimeFromNow = (date: Date | string): string => {
  try {
    if (dayjs(dayjs()).diff(date, "days") >= 7) return dayjs(date).format(dateFormats["MMM DD"]);
    return dayjs(date).fromNow();
  } catch (e) {
    return dayjs(date).format(dateFormats["MMM DD"]);
  }
};

export const calculateDueDate = (date: Date | string): string => {
  const today = dayjs();
  const tomorrow = today.add(1, "day");
  const yesterday = today.subtract(1, "day");

  if (dayjs(date).isToday()) return "Today";
  if (dayjs(date).isSame(tomorrow, "date")) return "Tomorrow";
  if (dayjs(date).isSame(yesterday, "date")) return "Yesterday";

  return dayjs(date).format(dateFormats["MMM DD"]);
};

export const renderTimestampString = (timestamp: Date | string): string => {
  if (!timestamp) {
    return "N/A";
  }
  const d = new Date(timestamp);
  return dayjs(d).format(`${dateFormats["hh:mm"]}a`);
};

export const renderDatestampString = (timestamp: Date | string): string => {
  if (!timestamp) {
    return "N/A";
  }
  const d = new Date(timestamp);
  return dayjs(d).format(dateFormats["MMM, DD"]);
};

export const renderYearstampString = (timestamp: Date | string): string => {
  if (!timestamp) {
    return "N/A";
  }
  const d = new Date(timestamp);
  return dayjs(d).format(dateFormats["MMM DD, YYYY"]);
};

export const roundToNearest15 = (date = dayjs()): string => {
  const minutes = 15;
  const ms = 1000 * 60 * minutes;

  return dayjs(Math.ceil(date.valueOf() / ms) * ms).format();
};

export const getPastDates = (excludeToday?: boolean): unknown[] => {
  const end = excludeToday ? dayjs().add(1, "day").format() : dayjs().format();
  const yesterday = dayjs(end).subtract(1, "day").format();

  return [
    {
      recurring: {
        repeat: "daily",
        until: yesterday,
      },
    },
    {
      start: yesterday,
      end,
    },
  ];
};

export const getFutureDays = (): unknown[] => {
  const endOfDayToday = dayjs().add(1, "day").startOf("day").format();
  const endOfDayTomorrow = dayjs().add(2, "day").endOf("day").format();
  return [
    {
      recurring: {
        repeat: "daily",
        from: endOfDayTomorrow,
      },
    },
    {
      start: endOfDayToday,
      end: endOfDayTomorrow,
    },
  ];
};

export function utcToLocal(date: string): Dayjs {
  return dayjs.utc(date).local();
}

export function getLast3days(): string[] {
  return Array.from({ length: 3 }, (_, i) => dayjs().subtract(i, "day").toISOString()).reverse();
}

export function dayjsRange(from: string, to: string, step: dayjs.ManipulateType) {
  const list = [];
  let current = dayjs(from);
  while (current.isBefore(to)) {
    list.push(current);
    current = current.add(1, step);
  }
  return list;
}

export const dateFormatYearFirst = "YYYY-MM-DD";
export const dateFormatMonthFirst = "MMM D, YYYY";
