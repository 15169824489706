import React from "react";
import { InputAdornment, styled, TextField, TextFieldProps } from "@mui/material";
import { VrsIcon, IconName, VrsIconButton } from "@veris-health/web-core";

export interface VrsPlainSearchProps {
  onValueChange: (value: string) => void;
  value: string;
}

const StyledTextField = styled(TextField)<TextFieldProps>(({ theme }) => ({
  marginLeft: theme.spacing(1),
  width: "272px",
  ".MuiOutlinedInput-input": {
    paddingBottom: theme.spacing(0.75),
    ...theme.typography.body,
  },

  ".MuiInput-root.MuiInputBase-sizeSmall": {
    ".MuiInput-input": {
      padding: theme.spacing(0.429, 0.58, 0, 0),
      height: "20px",
      [theme.breakpoints.down("lg")]: {
        padding: theme.spacing(0.725, 0.58, 0, 0),
      },
    },
  },
}));

export function VrsPlainSearch({
  onValueChange,
  value,
  sx,
}: VrsPlainSearchProps & TextFieldProps): JSX.Element {
  return (
    <StyledTextField
      variant="outlined"
      size="small"
      placeholder="Search"
      sx={sx}
      onChange={(event) => onValueChange(event.target.value)}
      value={value}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <VrsIcon name={IconName.Search} />
          </InputAdornment>
        ),
        endAdornment: value && (
          <InputAdornment position="end">
            <VrsIconButton
              iconProps={{ name: IconName.CloseIcon }}
              onClick={() => onValueChange("")}
              data-test-hook="vrsPlainSearch-close"
            />
          </InputAdornment>
        ),
      }}
    />
  );
}
