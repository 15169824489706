import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CareTeamMemberInfo, Demographics } from "@veris-health/user-ms/lib/v1";
import { PatientInfoHeader } from "../../ui/components/PatientInfoHeader";
import { PatientMoreDetailsInfo } from "../../ui/components/PatientMoreDetailsInfo";
import { VrsPatientInfo } from "../shared/interfaces";
import { PatientCommunicationButton } from "../../ui/components/PatientCommunicationButton";

export interface PatientDemographicsProps {
  patientDetails: Demographics;
  mainOncologist?: CareTeamMemberInfo;
  startCall?: () => void;
  endCall?: () => void;
  isOnCall: boolean;
  callDisabled?: boolean;
  patient: VrsPatientInfo;
  onScheduleAppointmentClick: () => void;
  onLastNotificationsClick: () => void;
  careTeam?: CareTeamMemberInfo[];
  onAddRPMClick: () => void;
  onReviewRPMClick: () => void;
  onAddTCMBilling: () => void;
  onReviewTCMBilling: () => void;
  disableRpm?: boolean;
  rpmTime?: number;
  onStatusEdit?: () => void;
}

export function PatientDemographics({
  patientDetails,
  mainOncologist,
  startCall,
  endCall,
  isOnCall,
  callDisabled,
  patient,
  onScheduleAppointmentClick,
  onLastNotificationsClick,
  onAddRPMClick,
  onReviewRPMClick,
  onAddTCMBilling,
  onReviewTCMBilling,
  careTeam,
  disableRpm,
  rpmTime,
  onStatusEdit,
}: PatientDemographicsProps): JSX.Element {
  const [open, setOpen] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);

  useEffect(() => {
    if (editMode) setOpen(true);
  }, [editMode]);
  return (
    <Grid container>
      <Grid item xs={12} data-test-hook="demographics-inner-container">
        <PatientInfoHeader
          data-test-hook="demographics-info-header"
          info={patientDetails}
          onToggle={() => setOpen(!open)}
          open={open}
          patient={patient}
          endComponent={
            <PatientCommunicationButton
              onStartCall={startCall}
              onEndCall={endCall}
              isOnCall={isOnCall}
              callDisabled={callDisabled}
              onScheduleAppointmentClick={onScheduleAppointmentClick}
              disabled={!patient?.inCareTeam || patient.isInactiveOrDeceased}
            />
          }
        />
        {open && (
          <PatientMoreDetailsInfo
            disableRpm={disableRpm}
            data-test-hook="demographics-more-details"
            info={patientDetails}
            patient={patient}
            editMode={editMode}
            setEditMode={setEditMode}
            onLastNotificationsClick={onLastNotificationsClick}
            mainOncologist={mainOncologist}
            careTeam={careTeam}
            onAddRPMClick={onAddRPMClick}
            onReviewRPMClick={onReviewRPMClick}
            onAddTCMBilling={onAddTCMBilling}
            onReviewTCMBilling={onReviewTCMBilling}
            rpmTime={rpmTime}
            onStatusEdit={onStatusEdit}
          />
        )}
      </Grid>
    </Grid>
  );
}
