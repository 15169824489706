import { Datepicker } from "@mobiscroll/react";
import { Box } from "@mui/material";
import { VrsIcon, IconName, VrsDivider, verisColors } from "@veris-health/web-core";
import React, { useState } from "react";
import { getPastDates, roundToNearest15 } from "../../../utils/date";
import { constructDate } from "../CreateEvent/CreateEvent";
import { EventContents } from "../CreateEvent/EventContents";

interface CombinedDatepickerProps {
  openDatePicker: boolean;
  setOpenDatePicker: (value: boolean) => void;
  formValues: EventContents;
  setFieldValue: (fieldName: string, fieldValue: string | Date) => void;
  openTimePicker: boolean;
  setOpenTimePicker: (value: boolean) => void;
}

export const CombinedDatepicker = ({
  setOpenDatePicker,
  openDatePicker,
  formValues,
  setFieldValue,
  openTimePicker,
  setOpenTimePicker,
}: CombinedDatepickerProps): JSX.Element => {
  const [datePicked, setDatePicked] = useState<string | Date>(formValues.date as Date);

  const pastDates = getPastDates();
  return (
    <Box pb={2}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          "& input:disabled": {
            background: "none",
            color: verisColors.amethyst.normal,
          },
        }}
        pt={3}
        pb={1}
      >
        <VrsIcon name={IconName.Calendar} stroke={verisColors.neutrals["grey-3"]} />
        <Datepicker
          controls={["calendar"]}
          invalid={pastDates}
          dateFormat="DDD MMM D, YYYY"
          dateWheels="|DDD MMM D, YYYY|"
          theme="material"
          showOnClick={false}
          showOnFocus={false}
          inputComponent="input"
          inputProps={{
            placeholder: "Choose date",
            readOnly: true,
            className: "vrs-datepicker",
            onClick: () => {
              setOpenDatePicker(true);
            },
          }}
          isOpen={openDatePicker}
          onClose={() => setOpenDatePicker(false)}
          touchUi
          value={formValues.date}
          onChange={(picker) => {
            setDatePicked(formValues.date as Date);
            setFieldValue("date", picker.value);
            setFieldValue("startTime", constructDate(picker.value, formValues.startTime as Date));
            setFieldValue("endTime", constructDate(picker.value, formValues.endTime as Date));
          }}
          onCancel={() => {
            setFieldValue("date", datePicked as Date);
          }}
          onCellClick={(cellValue) => {
            setFieldValue("date", cellValue.date);
          }}
        />
        <VrsIcon name={IconName.TimePicker} stroke={verisColors.neutrals["grey-3"]} />
        <Datepicker
          controls={["timegrid"]}
          theme="material"
          timeFormat="h:mm A"
          stepMinute={15}
          min={roundToNearest15()}
          minRange={15 * 60 * 1000}
          showOnClick={false}
          showOnFocus={false}
          inputComponent="input"
          inputProps={{
            placeholder: "Choose time",
            readOnly: true,
            className: "vrs-datepicker",
            onClick: () => setOpenTimePicker(true),
          }}
          isOpen={openTimePicker}
          onClose={() => setOpenTimePicker(false)}
          touchUi
          select="range"
          value={[formValues.startTime, formValues.endTime]}
          onChange={(picker) => {
            const [startTime, endTime] = picker.value;
            setFieldValue("startTime", startTime);
            setFieldValue("endTime", endTime);
          }}
        />
      </Box>
      <VrsDivider height="80%" orientation="horizontal" />
    </Box>
  );
};
