import React, { useEffect, useState } from "react";
import { Box, Button, Typography, Link } from "@mui/material";
import { ChangePasswordRequest } from "@veris-health/user-ms/lib/v1";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { VrsIcon, IconName, VrsConfirmationModals } from "@veris-health/web-core";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import {
  changePasswordAsync,
  clearChangePasswordMessages,
  getTermsAndConditionsAsync,
  logout,
  selectUserId,
} from "../shared/slices/authSlice";
import { VrsTabsContainer } from "../../ui/components/VrsTabsContainer";
import { DoctorsDemographics } from "./components/Demographics/DoctorsDemographics";
import {
  fetchDoctorProfileDataAsync,
  fetchLanguagesAsync,
  selectDoctorData,
  selectDoctorDataStatus,
  fetchDoctorSpokenLanguagesAsync,
  selectLanguages,
  selectDoctorSpokenLanguages,
  selectDoctorSpokenLanguagesStatus,
  loadHospitalsTeamAsync,
} from "./doctorsProfileSlice";
import HospitalsTableContainer from "./containers/HospitalsTableContainer";
import { MedicalStaffTableContainer } from "./containers/MedicalStaffTableContainer";
import { DoctorAccountComponent } from "./components/BottomTabs/DoctorAccountComponent";
import { ChangePassword } from "./components/BottomTabs/ChangePassword";
import { unregisterFirebaseTokenAsync } from "../Notifications/notificationsSlice";
import { LanguageSpoken } from "./components/LanguageSpoken";
import { TermsAndConditions } from "../TermsAndConditions";
import { DASHBOARD_DIRECTION, DASHBOARD_FIELD } from "../../utils/sorting";

export function DoctorProfileContainer(): JSX.Element {
  const doctorData = useAppSelector(selectDoctorData);
  const doctorDataStatus = useAppSelector(selectDoctorDataStatus);
  const currentUserId = useAppSelector(selectUserId);
  const languages = useAppSelector(selectLanguages);
  const spokenLanguages = useAppSelector(selectDoctorSpokenLanguages);
  const spokenLanguagesStatus = useAppSelector(selectDoctorSpokenLanguagesStatus);
  const dispatch = useAppDispatch();
  const { path } = useRouteMatch();
  const [openDialog, setOpenDialog] = useState(false);

  const [showLogoutModal, setShowLogoutModal] = useState(false);

  useEffect(() => {
    dispatch(getTermsAndConditionsAsync());
    if (currentUserId)
      dispatch(
        loadHospitalsTeamAsync({
          id: +currentUserId,
        }),
      );
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchDoctorProfileDataAsync({ id: Number(currentUserId) }));
    dispatch(fetchLanguagesAsync());
    dispatch(fetchDoctorSpokenLanguagesAsync({ id: Number(currentUserId) }));
  }, [dispatch]);

  const submitPasswordChange = (data: ChangePasswordRequest) => {
    dispatch(
      changePasswordAsync({
        id: Number(currentUserId),
        newData: data,
      }),
    );
  };

  const handleLogout = (): void => {
    dispatch(unregisterFirebaseTokenAsync(Number(currentUserId)));
    localStorage.removeItem(DASHBOARD_DIRECTION);
    localStorage.removeItem(DASHBOARD_FIELD);
    dispatch(logout());
  };

  return (
    <Box p={2}>
      <DoctorsDemographics
        data={doctorData}
        status={doctorDataStatus}
        fetchDoctorData={() => dispatch(fetchDoctorProfileDataAsync({ id: Number(currentUserId) }))}
      />
      <Switch>
        <Route path={path} exact>
          {doctorData.hospitals && <HospitalsTableContainer />}
        </Route>
        <Route path={`${path}/hospitals/:hospitalId/medical-staff`}>
          <MedicalStaffTableContainer />
        </Route>
      </Switch>
      <VrsTabsContainer
        tabsContainerContent={{
          Account: (
            <DoctorAccountComponent
              data={doctorData}
              userId={Number(currentUserId)}
              status={doctorDataStatus}
              fetchDoctorData={() =>
                dispatch(fetchDoctorProfileDataAsync({ id: Number(currentUserId) }))
              }
              key="account"
            />
          ),
          "Language Spoken": (
            <LanguageSpoken
              doctorsLanguages={spokenLanguages}
              languages={languages}
              languagesStatus={spokenLanguagesStatus}
              onRetry={() => {
                dispatch(fetchLanguagesAsync());
                dispatch(fetchDoctorSpokenLanguagesAsync({ id: Number(currentUserId) }));
              }}
              key="language"
            />
          ),
          Security: (
            <ChangePassword
              onSubmit={submitPasswordChange}
              clearValidationMessages={() => dispatch(clearChangePasswordMessages())}
              key="security"
            />
          ),
        }}
      >
        <Box marginLeft="auto">
          <Button
            size="small"
            sx={{
              color: (theme) => theme.veris.colors.neutrals.black,
              padding: (theme) => theme.spacing(2, 2.5),
            }}
            onClick={() => setShowLogoutModal(true)}
          >
            <VrsIcon name={IconName.Logout} size={18} />
            <Typography
              variant="subtitle2"
              sx={{ padding: (theme) => theme.spacing(0.25, 1, 0, 1) }}
            >
              Logout
            </Typography>
          </Button>
          <VrsConfirmationModals
            handleClose={() => setShowLogoutModal(false)}
            isOpen={showLogoutModal}
            dialogHeader="Log out"
            dialogContent="You are about to log out."
            dialogSecondContentLine="Are you sure you want to logout now?"
            onCancel={() => setShowLogoutModal(false)}
            onConfirm={() => handleLogout()}
            cancelButtonText="Cancel"
            confirmButtonText="Logout"
            confirmButtonVariant="primary"
            dialogContentSpacing={1}
            sx={{
              dialogContent: { display: "flex", flexDirection: "column", justifyContent: "center" },
            }}
          />
        </Box>
      </VrsTabsContainer>
      <Box display="flex" pt={1} pb={2} flexDirection="column">
        <Link
          href={import.meta.env.VITE_RELEASENOTES_FILE_LINK || ""}
          variant="body"
          target="_blank"
          sx={{
            color: (theme) => theme.veris.colors.neutrals["grey-5"],
            textDecoration: "underline",
          }}
          pl={0.5}
        >
          Release Notes
        </Link>
        <Typography
          onClick={() => setOpenDialog(true)}
          sx={{ cursor: "pointer", textDecoration: "underline" }}
          variant="body"
          pl={0.5}
        >
          Terms and Conditions
        </Typography>
      </Box>
      {openDialog && <TermsAndConditions open={openDialog} setOpen={setOpenDialog} />}
    </Box>
  );
}
