import { Box, Chip, Typography } from "@mui/material";
import { QuestionnaireViewLabel, ReportItem } from "@veris-health/virtual-doc-ms/lib/v1";
import { verisColors } from "@veris-health/web-core";
import React from "react";

interface SymptomOptionsWrapperProps {
  filteredSymptoms: QuestionnaireViewLabel[];
  setSelectedSymptom: (symptom: string) => void;
  reportedSymptoms?: { [key: string]: ReportItem[] };
  selectedSymptom?: string;
}

const getChipColor = (
  symptom: string,
  isSymptomReported: (symptom: string) => boolean,
  selectedSymptom?: string,
) => {
  if (isSymptomReported(symptom))
    return {
      background: verisColors.neutrals["grey-2"],
      color: verisColors.neutrals["grey-3"],
      hover: verisColors.neutrals["grey-3"],
    };
  if (selectedSymptom === symptom)
    return {
      background: verisColors.mango.normal,
      color: verisColors.mango.light,
      hover: verisColors.mango.normal,
    };
  return {
    background: verisColors.mango.light,
    color: verisColors.mango.normal,
    hover: verisColors.mango.normal,
  };
};

const SymptomOptionsWrapper = ({
  filteredSymptoms,
  reportedSymptoms,
  setSelectedSymptom,
  selectedSymptom,
}: SymptomOptionsWrapperProps): JSX.Element => {
  const isSymptomReported = (symptom: string) =>
    !!(
      reportedSymptoms?.active &&
      reportedSymptoms?.active.find((reportedSymptom) => reportedSymptom.symptom.label === symptom)
    ) ||
    !!(
      reportedSymptoms?.new &&
      reportedSymptoms?.new.find((reportedSymptom) => reportedSymptom.symptom.label === symptom)
    );

  return (
    <Box>
      <Typography my={1} variant="body2" color={(theme) => theme.veris.colors.neutrals["grey-3"]}>
        Please, tell us your symptom
      </Typography>
      <Box display="flex" gap={1} flexWrap="wrap">
        {filteredSymptoms.map((el) => (
          <Chip
            key={el.label}
            label={
              <Typography variant="caption" className="symptomLabel">
                <span>{el.label?.replaceAll("-", " ")}</span>
              </Typography>
            }
            onClick={() => !isSymptomReported(el.value) && setSelectedSymptom(el.value)}
            sx={{
              cursor: isSymptomReported(el.value) ? "not-allowed" : "pointer",
              backgroundColor: getChipColor(el.value, isSymptomReported, selectedSymptom)
                .background,
              borderColor: (theme) => theme.veris.colors.mango.normal,
              color: getChipColor(el.value, isSymptomReported, selectedSymptom).color,
              "span::first-letter": {
                textTransform: "capitalize",
              },
              "&:hover": {
                backgroundColor: getChipColor(el.value, isSymptomReported, selectedSymptom).hover,
                color: (theme) => theme.veris.colors.mango.light,
              },
            }}
          />
        ))}
      </Box>
    </Box>
  );
};

export default SymptomOptionsWrapper;
