import React, { useEffect, useMemo, useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import { Route, Switch, useHistory, useLocation, useParams, useRouteMatch } from "react-router-dom";
import { VrsErrorMsg } from "@veris-health/web-core";
import { PatientStatusEnumAPI } from "@veris-health/user-ms/lib/v2";
import { useAppSelector } from "../../hooks/useAppSelector";
import { selectActiveNavBarTab, setActiveNavBarTab } from "../shared/slices/uiSlice";
import { PatientDemographics } from "../PatientDemographics";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import {
  loadPatientDetailsAsync,
  selectPatientDetailsStatus,
  loadPatientGoalsAsync,
  selectPatientDetails,
  loadRpmReviewItemsAsync,
  selectPatientRpmItems,
  loadTCMBillingReportAsync,
  selectPatientTcmReport,
} from "./patientDetailsSlice";
import { PatientDetailsMeasurementContainer } from "../PatientDetailsMeasurements/PatientDetailsMeasurementContainer";
import { Routes, replaceRouteParam } from "../../routes-config";
import { PatientRecordsContainer } from "../PatientRecords/PatientRecordsContainer";
import TimelineContainer from "../TreatmentPlan/TreatmentPlanContainer";
import { PatientAllNotesContainer } from "./containers/PatientAllNotesContainer";
import { PatientTreatmentGoals } from "./components/PatientTreatmentGoals";
import { PatientDetailsSidebarContainer } from "../PatientDetailsSidebar/PatientDetailsSidebarContainer";
import { setEventCreationVisibility } from "../Calendar/calendarSlice";
import { useCurrentPatient } from "../../hooks/useCurrentPatient";
import { NotificationFilter } from "../Notifications/notificationsSlice";
import { getPatientByIdAsync } from "../shared/slices/asyncThunks";
import { selectPatientsMap, updatePatientStatusAsync } from "../shared/slices/patientsSlice";
import { useBilling } from "../../hooks/useBilling";
import { PAGE_NAME } from "../../constants";
import {
  getPatientCareAndSupportTeamsAsync,
  selectCareTeams,
} from "../shared/slices/careTeamSlice";
import { AddRPM } from "./components/ManualRPM/AddRPM/AddRPM";
import { ReviewRPM } from "./components/ManualRPM/ReviewRPM/ReviewRPM";
import {
  VrsDeceasedPatientAlert,
  VrsInactivePatientAlert,
} from "../../ui/components/VrsInactivePatientAlert/VrsInactivePatientAlert";
import StatusEdit from "./components/StatusEdit/StatusEdit";
import { TasksContainer } from "../Tasks/TasksContainer";
import PatientAllergiesContent from "./containers/PatientAllergiesContent";
import { UserQueryParams } from "../shared/interfaces";
import { AddTCM } from "./components/TCMTime/AddTCM";
import ReviewTCM from "./components/TCMTime/ReviewTCM";

const PatientDetailsContainer = (): JSX.Element => {
  const history = useHistory();
  const location = useLocation<{ view: number }>();
  const dispatch = useAppDispatch();
  const [openAddRpm, setOpenAddRpm] = useState(false);
  const [openReviewRpm, setOpenReviewRpm] = useState(false);
  const [openAddTCM, setOpenAddTCM] = useState(false);
  const [openReviewTCM, setOpenReviewTCM] = useState(false);
  const [openStatusEdit, setOpenStatusEdit] = useState(false);
  const currentPatient = useCurrentPatient();
  const activeTab = useAppSelector(selectActiveNavBarTab);
  const patientDetailsStatus = useAppSelector(selectPatientDetailsStatus);
  const patientDetails = useAppSelector((state) => selectPatientDetails(state, currentPatient?.id));
  const patientCareTeam = useAppSelector((state) => selectCareTeams(state, currentPatient?.id));
  const rpmItems = useAppSelector((state) =>
    currentPatient ? selectPatientRpmItems(state, currentPatient.id) : undefined,
  );
  const TCMReport = useAppSelector(
    (state) => currentPatient && selectPatientTcmReport(state, currentPatient?.id),
  );

  const careTeam = useMemo(
    () => patientCareTeam?.careTeam.filter((member) => !member.is_main_oncologist),
    [patientCareTeam],
  );
  const mainOncologist = useMemo(
    () => patientCareTeam?.careTeam.find((member) => member.is_main_oncologist),
    [patientCareTeam],
  );

  const { userId } = useParams<UserQueryParams>();
  const { path } = useRouteMatch();
  const patients = useAppSelector(selectPatientsMap);

  useBilling(currentPatient?.id);

  const handleAddRPM = () => {
    setOpenReviewRpm(false);
    setOpenAddRpm(true);
  };

  const handleReviewRPM = () => {
    setOpenReviewRpm(true);
    setOpenAddRpm(false);
  };
  const closeRPMDialogs = () => {
    setOpenReviewRpm(false);
    setOpenAddRpm(false);
  };
  const onAddTCMBilling = () => {
    setOpenReviewTCM(false);
    setOpenAddTCM(true);
  };

  const onReviewTCMBilling = () => {
    if (currentPatient) {
      dispatch(loadTCMBillingReportAsync({ id: currentPatient?.id }));
      setOpenReviewTCM(true);
    }
    setOpenAddTCM(false);
  };
  const closeTCMDialogs = () => {
    setOpenReviewTCM(false);
    setOpenAddTCM(false);
  };

  useEffect(() => {
    if (!currentPatient) {
      dispatch(getPatientByIdAsync({ userId: +userId }));
      return;
    }
    if (currentPatient.id !== +userId) {
      return;
    }
    const { id } = currentPatient;
    dispatch(loadPatientDetailsAsync({ id, isMioUser: currentPatient.type === "mio" }));
    dispatch(loadPatientGoalsAsync({ id }));
    dispatch(getPatientCareAndSupportTeamsAsync(id));
    dispatch(loadRpmReviewItemsAsync(id));
  }, [dispatch, currentPatient, userId, patients]);

  useEffect(() => {
    if (location.state && location.state?.view)
      dispatch(setActiveNavBarTab(Number(location.state.view)));
    return () => {
      dispatch(setActiveNavBarTab(0));
    };
  }, [dispatch, currentPatient]);

  const renderLogic = (tabIndex: number) => {
    switch (tabIndex) {
      case 0:
        return <PatientDetailsMeasurementContainer />;
      case 1:
        return <TimelineContainer />;
      case 2:
        return <PatientRecordsContainer />;
      case 3:
        return <PatientAllNotesContainer />;
      case 4:
        return <TasksContainer />;
      default:
        return <> </>;
    }
  };

  return (
    <>
      <Box
        display="flex"
        sx={{
          p: 1,
          my: 1,
          backgroundColor: (theme) => theme.veris.colors.neutrals["grey-1"],
        }}
      >
        <Box display="flex" flexDirection="column" sx={{ width: "100%" }}>
          {patientDetailsStatus === "loading" && (
            <Box display="flex" justifyContent="center">
              <CircularProgress />
            </Box>
          )}
          {currentPatient && patientDetails && (
            <>
              <PatientDemographics
                startCall={() =>
                  currentPatient &&
                  history.push({
                    pathname: replaceRouteParam(
                      "VIDEO_COMMUNICATION",
                      ":userId",
                      currentPatient.id.toString(),
                    ),
                    state: { from: PAGE_NAME },
                  })
                }
                mainOncologist={mainOncologist}
                patientDetails={patientDetails}
                patient={currentPatient}
                isOnCall={false}
                onScheduleAppointmentClick={() => {
                  history.push(Routes.CALENDAR);
                  dispatch(
                    setEventCreationVisibility({ isOpen: true, patientId: currentPatient.id }),
                  );
                }}
                onLastNotificationsClick={() => {
                  history.push({
                    pathname: replaceRouteParam(
                      "NOTIFICATIONS_DETAILS",
                      ":notificationId",
                      NotificationFilter.ViewAll,
                    ),
                    search: `?userId=${currentPatient.id}`,
                  });
                }}
                disableRpm={currentPatient.isInactiveOrDeceased}
                onAddRPMClick={handleAddRPM}
                onReviewRPMClick={handleReviewRPM}
                careTeam={careTeam}
                rpmTime={rpmItems ? rpmItems.currentMonthReport.total : 0}
                onStatusEdit={() => setOpenStatusEdit(true)}
                onAddTCMBilling={onAddTCMBilling}
                onReviewTCMBilling={onReviewTCMBilling}
              />
              {currentPatient.isInactiveOrDeceased && (
                <>
                  {currentPatient.patientStatus === PatientStatusEnumAPI.Inactive ? (
                    <VrsInactivePatientAlert />
                  ) : (
                    <VrsDeceasedPatientAlert />
                  )}
                </>
              )}
              {openAddTCM && (
                <AddTCM open={openAddTCM} onClose={closeTCMDialogs} patient={currentPatient} />
              )}
              {openAddRpm && (
                <AddRPM
                  open={openAddRpm}
                  onClose={closeRPMDialogs}
                  patient={currentPatient}
                  rpmTimeCurrentMonth={rpmItems ? rpmItems.currentMonthReport.total : 0}
                  rpmTimePreviousMonth={rpmItems ? rpmItems.previousMonthReport.total : 0}
                />
              )}
              <ReviewRPM
                open={openReviewRpm}
                onClose={closeRPMDialogs}
                patient={currentPatient}
                previousMonthReport={rpmItems?.previousMonthReport}
                currentMonthReport={rpmItems?.currentMonthReport}
              />
              {openReviewTCM && (
                <ReviewTCM
                  open={openReviewTCM}
                  onClose={() => setOpenReviewTCM(false)}
                  TCMData={TCMReport || []}
                />
              )}
              {openStatusEdit && (
                <StatusEdit
                  open={openStatusEdit}
                  onClose={() => setOpenStatusEdit(false)}
                  onSave={(status) =>
                    dispatch(
                      updatePatientStatusAsync({
                        patientId: currentPatient.id,
                        status,
                      }),
                    ).then(() => setOpenStatusEdit(false))
                  }
                  currentStatus={currentPatient.patientStatus}
                />
              )}
            </>
          )}
          {patientDetailsStatus === "failed" && (
            <VrsErrorMsg
              size="small"
              onClick={() =>
                currentPatient && dispatch(loadPatientDetailsAsync({ id: currentPatient.id }))
              }
            />
          )}

          <Switch>
            <Route path={path} exact>
              {renderLogic(activeTab.tabIndex)}
            </Route>
            <Route path={Routes.PATIENT_GOALS}>
              <PatientTreatmentGoals
                fetchTreatmentGoals={() =>
                  currentPatient && dispatch(loadPatientGoalsAsync({ id: currentPatient.id }))
                }
              />
            </Route>
            <Route path={Routes.PATIENT_ALLERGIES}>
              <PatientAllergiesContent />
            </Route>
          </Switch>
        </Box>
        {activeTab.tabIndex !== 2 && (
          <PatientDetailsSidebarContainer
            onViewAllLabsResults={() => {
              if (
                history.location.pathname.includes("goals") ||
                history.location.pathname.includes("allergies")
              )
                history.push(
                  replaceRouteParam("PATIENT_DETAILS", ":userId", String(currentPatient?.id)),
                );
              dispatch(setActiveNavBarTab(2));
            }}
          />
        )}
      </Box>
    </>
  );
};

export default PatientDetailsContainer;
