import { MbscCalendarEvent, MbscEventcalendarView } from "@mobiscroll/react";
import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  EntityState,
  PayloadAction,
} from "@reduxjs/toolkit";
import { AppointmentCreateRequest, AppointmentStatusEnum } from "@veris-health/med-data-ms/lib/v1";
import dayjs from "dayjs";
import { MedStaffProfileItem } from "@veris-health/user-ms/lib/v1";
import { RootState } from "../../store";
import { extractErrorMessage } from "../shared/helpers";
import { Status, VrsMedStaffProfileModel } from "../shared/interfaces";
import {
  bookAppointmentAsync,
  fetchCalendarEventsAsync,
  changeAppointmentStatusAsync,
} from "../shared/slices/asyncThunks";
import { localizedLogout, logout } from "../shared/slices/authSlice";
import { updateAppointment } from "./api/calendarApi";

export type AgendaTypes = "day" | "week" | "month" | "year";

export type CalendarShownType = "agenda" | "calendar";

export interface NormalizedCalendarEvents {
  events: MbscCalendarEvent[];
}

interface TodaysEventsProps {
  events: EntityState<NormalizedCalendarEvents>;
  status: Status;
}
interface CalendarState {
  view: AgendaTypes;
  viewType: CalendarShownType;
  events: EntityState<NormalizedCalendarEvents>;
  todaysEvents: TodaysEventsProps;
  calendarView: MbscEventcalendarView;
  loadingEvents: Status;
  isCreateEventOpen: boolean;
  isCreateEventOpenWithPatientId?: number;
  isEventPopupOpen: boolean;
  start: string;
  end: string;
  selectedMedicalStaffMember?: MedStaffProfileItem | VrsMedStaffProfileModel;
}

const calendarEventsAdapter = createEntityAdapter<NormalizedCalendarEvents>();
const todaysAppointmentsAdapter = createEntityAdapter<NormalizedCalendarEvents>();

export interface CalendarActionPayload {
  view: AgendaTypes;
  viewType: CalendarShownType;
}

const calendarConfigs = {
  day: {
    schedule: {
      type: "day",
      allDay: false,
    },
  },
  week: {
    schedule: {
      type: "week",
      allDay: false,
    },
  },
  month: {
    calendar: { labels: 1, type: "month" },
  },
  year: {
    calendar: { type: "year" },
  },
};

const initialState: CalendarState = {
  view: "day",
  viewType: "calendar",
  events: calendarEventsAdapter.getInitialState(),
  todaysEvents: {
    events: todaysAppointmentsAdapter.getInitialState(),
    status: "idle",
  },
  calendarView: {
    schedule: {
      type: "day",
      allDay: false,
    },
  },
  loadingEvents: "idle",
  isCreateEventOpen: false,
  isCreateEventOpenWithPatientId: undefined,
  isEventPopupOpen: false,
  start: dayjs().startOf("day").toISOString(),
  end: dayjs().add(1, "day").toISOString(),
};

export interface fetchCalendarEventsProps {
  userId: number;
  start?: string;
  end?: string;
  statuses?: AppointmentStatusEnum[];
}

export const updateAppointmentAsync = createAsyncThunk<
  void | Error,
  { userId: number; appointmentId: string; appointment: AppointmentCreateRequest },
  { rejectValue: string }
>(
  "calendarSlice/updateAppointment",
  async ({ userId, appointmentId, appointment }, { rejectWithValue, dispatch, getState }) => {
    try {
      const response = await updateAppointment(userId, appointmentId, appointment);
      const { calendar } = getState() as RootState;
      dispatch(
        fetchCalendarEventsAsync({
          userId,
          start: calendar.start,
          end: calendar.end,
          statuses: [
            AppointmentStatusEnum.Booked,
            AppointmentStatusEnum.Completed,
            AppointmentStatusEnum.Proposed,
          ],
        }),
      );
      return response;
    } catch (err) {
      const errorMsg = extractErrorMessage(err);
      return rejectWithValue(errorMsg || "Could not update appointment.");
    }
  },
);

const calendarSlice = createSlice({
  initialState,
  name: "Calendar",
  reducers: {
    setCalendarView: (state, action: PayloadAction<CalendarActionPayload>) => {
      state.view = action.payload.view;
      state.viewType = action.payload.viewType;
      state.calendarView = calendarConfigs[action.payload.view] as MbscEventcalendarView;
    },
    setAgendaView: (state, action: PayloadAction<CalendarActionPayload>) => {
      state.view = action.payload.view;
      state.viewType = action.payload.viewType;
      state.calendarView = {
        agenda: { type: action.payload.view, scrollable: true },
      };
    },
    resetView: () => {
      return initialState;
    },
    setEventCreationVisibility: (
      state,
      action: PayloadAction<{ isOpen: boolean; patientId?: number }>,
    ) => {
      state.isCreateEventOpen = action.payload.isOpen;
      state.isCreateEventOpenWithPatientId = action.payload.patientId || undefined;
    },
    setEventPopupVisibility: (state, action: PayloadAction<boolean>) => {
      state.isEventPopupOpen = action.payload;
    },
    setCalendarStartAndEndDate: (state, action: PayloadAction<{ start: string; end: string }>) => {
      state.start = action.payload.start;
      state.end = action.payload.end;
    },
    resetCalendarEvents: (state) => {
      calendarEventsAdapter.removeAll(state.events);
    },
    setSelectedMedicalStaffMember: (
      state,
      { payload }: PayloadAction<MedStaffProfileItem | VrsMedStaffProfileModel | undefined>,
    ) => {
      state.selectedMedicalStaffMember = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCalendarEventsAsync.pending, (state) => {
      state.loadingEvents = "loading";
      if (
        dayjs(state.start).isToday() &&
        dayjs(state.end).subtract(1, "day").isToday() &&
        !state.todaysEvents.events.entities.length
      )
        state.todaysEvents.status = "loading";
    });
    builder.addCase(fetchCalendarEventsAsync.fulfilled, (state, action) => {
      state.loadingEvents = "idle";
      if (
        action.meta.arg.start &&
        dayjs(action.meta.arg.start).isToday() &&
        action.meta.arg.end &&
        dayjs(action.meta.arg.end).subtract(1, "day").isToday()
      ) {
        if (state.todaysEvents.events.ids.includes(action.payload.id))
          todaysAppointmentsAdapter.updateOne(state.todaysEvents.events, {
            id: action.payload.id,
            changes: { ...action.payload },
          });
        else
          todaysAppointmentsAdapter.addOne(
            state.todaysEvents.events,
            action.payload as NormalizedCalendarEvents,
          );
      }
      state.todaysEvents.status = "idle";
      if (state.events.ids.includes(action.payload.id))
        calendarEventsAdapter.updateOne(state.events, {
          id: action.payload.id,
          changes: { ...action.payload },
        });
      else calendarEventsAdapter.addOne(state.events, action.payload as NormalizedCalendarEvents);
    });
    builder.addCase(fetchCalendarEventsAsync.rejected, (state) => {
      state.loadingEvents = "failed";
      if (
        dayjs(state.start).isToday() &&
        dayjs(state.end).subtract(1, "day").isToday() &&
        !state.todaysEvents.events.entities.length
      ) {
        state.todaysEvents.status = "failed";
      }
    });
    builder.addCase(bookAppointmentAsync.pending, (state) => {
      state.loadingEvents = "loading";
    });
    builder.addCase(bookAppointmentAsync.fulfilled, (state) => {
      state.loadingEvents = "idle";
      state.isCreateEventOpen = false;
      state.isCreateEventOpenWithPatientId = undefined;
    });
    builder.addCase(bookAppointmentAsync.rejected, (state) => {
      state.loadingEvents = "failed";
      state.isCreateEventOpen = false;
      state.isCreateEventOpenWithPatientId = undefined;
    });
    builder.addCase(changeAppointmentStatusAsync.pending, (state) => {
      state.loadingEvents = "loading";
    });
    builder.addCase(changeAppointmentStatusAsync.fulfilled, (state) => {
      state.loadingEvents = "idle";
      state.isEventPopupOpen = false;
    });
    builder.addCase(changeAppointmentStatusAsync.rejected, (state) => {
      state.loadingEvents = "failed";
      state.isEventPopupOpen = false;
    });
    builder.addCase(updateAppointmentAsync.pending, (state) => {
      state.loadingEvents = "loading";
    });
    builder.addCase(updateAppointmentAsync.fulfilled, (state) => {
      state.loadingEvents = "idle";
      state.isEventPopupOpen = false;
    });
    builder.addCase(updateAppointmentAsync.rejected, (state) => {
      state.loadingEvents = "failed";
      state.isEventPopupOpen = false;
    });
    builder.addCase(logout, () => {
      return initialState;
    });
    builder.addCase(localizedLogout, () => {
      return initialState;
    });
  },
});

export default calendarSlice.reducer;

export const {
  setAgendaView,
  setCalendarView,
  setEventCreationVisibility,
  setEventPopupVisibility,
  setCalendarStartAndEndDate,
  resetCalendarEvents,
  setSelectedMedicalStaffMember,
  resetView,
} = calendarSlice.actions;

export const selectCalendarView = (state: RootState): AgendaTypes => state.calendar.view;

export const selectCalendarViewType = (state: RootState): CalendarShownType =>
  state.calendar.viewType;

export const selectCalendarEvents = (
  rootState: RootState,
  id: number | undefined,
): MbscCalendarEvent[] | undefined => {
  if (id) {
    return (
      calendarEventsAdapter
        .getSelectors<RootState>((state) => state.calendar.events)
        .selectById(rootState, id)?.events || []
    );
  }
  return undefined;
};

export const selectTodaysEvents = (
  rootState: RootState,
  id: number | undefined,
): MbscCalendarEvent[] | undefined => {
  if (id) {
    return (
      todaysAppointmentsAdapter
        .getSelectors<RootState>((state) => state.calendar.todaysEvents.events)
        .selectById(rootState, id)?.events || []
    ).filter((event) => event.status !== "completed");
  }
  return undefined;
};

export const selectCalendarConfigDetails = (state: RootState): MbscEventcalendarView =>
  state.calendar.calendarView;

export const selectTodaysEventsState = (state: RootState): Status =>
  state.calendar.todaysEvents.status;

export const selectIsCreateEventOpen = (state: RootState): boolean =>
  state.calendar.isCreateEventOpen;

export const selectIsEventPopupOpen = (state: RootState): boolean =>
  state.calendar.isEventPopupOpen;

export const selectCreateEventOpenWithPatientId = (state: RootState): number | undefined =>
  state.calendar.isCreateEventOpenWithPatientId;

export const selectStartDate = (state: RootState): string => state.calendar.start;
export const selectEndDate = (state: RootState): string => state.calendar.end;

export const selectActiveMedicalStaffMember = ({
  calendar,
}: RootState): MedStaffProfileItem | VrsMedStaffProfileModel | undefined =>
  calendar.selectedMedicalStaffMember;
