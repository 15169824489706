import React from "react";
import { IconName, VrsIconButton, verisColors } from "@veris-health/web-core";

export interface BlockTypeProps {
  active?: boolean;
  disabled?: boolean;
  blockType: string;
  convertBlockType(newType: string): void;
}

export interface BlockTypeButtonProps extends BlockTypeProps {
  iconName: IconName;
}
export interface LinkButtonProps {
  iconName: IconName;
  disabled?: boolean;
  onAddLink: (e: React.MouseEvent) => void;
}

export const BlockTypeButton = ({
  active,
  disabled,
  blockType,
  iconName,
  convertBlockType,
}: BlockTypeButtonProps): JSX.Element => {
  const onMouseDown = (e: React.MouseEvent) => {
    e.preventDefault();
    convertBlockType(blockType);
  };

  return (
    <VrsIconButton
      disabled={disabled}
      onMouseDown={onMouseDown}
      iconProps={{
        name: iconName,
        color: active ? verisColors.neutrals["grey-dark"] : verisColors.neutrals["grey-mid"],
      }}
    />
  );
};

export const LinkButton = ({ disabled, iconName, onAddLink }: LinkButtonProps): JSX.Element => {
  const onMouseDown = (e: React.MouseEvent) => {
    e.preventDefault();

    onAddLink(e);
  };
  return (
    <VrsIconButton
      onMouseDown={onMouseDown}
      disabled={disabled}
      iconProps={{
        name: iconName,
        color: verisColors.neutrals["grey-mid"],
      }}
    />
  );
};
