import { HospitalMedStaff, LimitOffsetPageTagItem } from "@veris-health/user-ms/lib/v1";
import { hospitalsApiV1, tagsApiV1 } from "../../../api";

export const getRelatedStaff = async (
  userId: number,
  hospitalId?: number,
): Promise<HospitalMedStaff[]> => {
  const { data } = await hospitalsApiV1.getHospitalStaff(userId, hospitalId);
  return data.hospitals_med_staff;
};

export const getHospitalTags = async (hospitalId?: number): Promise<LimitOffsetPageTagItem> => {
  const response = await tagsApiV1.getTags(hospitalId);
  return response.data;
};
