import React from "react";
import { Box, styled, Typography } from "@mui/material";
import { DiagnosticReportSummary } from "@veris-health/med-data-ms/lib/v1";
import { ResultEntries, selectSelectedRecord } from "../../patientRecordsSlice";
import { PatientRecordListItem } from "../PatientRecordListItem";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { EMPTY_DATASET_MESSAGE } from "../../../../constants";

export interface PatientRecordsListProps {
  title: ResultEntries;
  resultList: DiagnosticReportSummary[];
  onShowResultClick: (result?: DiagnosticReportSummary, title?: ResultEntries) => void;
}

export const StyledRow = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "60% 20% 20%",
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  marginTop: theme.spacing(0.25),
}));

const StyledHeader = styled(StyledRow)(({ theme }) => ({
  borderBottom: `1px solid ${theme.veris.colors.neutrals["grey-light"]}`,
  borderRadius: 0,
}));

export const PatientRecordsList = ({
  title,
  resultList,
  onShowResultClick,
}: PatientRecordsListProps): JSX.Element => {
  const selectedRecord = useAppSelector(selectSelectedRecord);
  return (
    <Box>
      <StyledHeader>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: (theme) => theme.spacing(1),
          }}
        >
          <Typography variant="body1">{title}</Typography>
          <Typography
            variant="button"
            sx={{ color: (theme) => theme.veris.colors.neutrals["grey-dark"] }}
          >
            ({resultList.length})
          </Typography>
        </Box>
        <Typography variant="body1">Date</Typography>
        <Typography variant="body1">Results</Typography>
      </StyledHeader>

      <Box sx={{ overflowY: "scroll", minHeight: "75px", maxHeight: "80%" }}>
        {resultList.length < 1 && (
          <Typography
            variant="body"
            color={(theme) => theme.veris.colors.neutrals["grey-3"]}
            textAlign="center"
            pt={2}
          >
            {EMPTY_DATASET_MESSAGE}
          </Typography>
        )}
        {resultList.map((result) => (
          <PatientRecordListItem
            result={result}
            selectedRecord={selectedRecord}
            onShowResultClick={onShowResultClick}
            title={title}
            key={result.id}
          />
        ))}
      </Box>
    </Box>
  );
};
