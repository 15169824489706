import React from "react";
import { Alert, Grid } from "@mui/material";
import { AuthShell } from "./AuthShell";

export function SuccessfulRegistration() {
  return (
    <AuthShell>
      <Grid container style={{ margin: "0 auto" }} display="block" xs={10} item>
        <Alert>Successfully registered! To log in, open the Procedure App.</Alert>
      </Grid>
    </AuthShell>
  );
}
