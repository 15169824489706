import React, { useEffect } from "react";
import { Box, Typography, Stack, CircularProgress } from "@mui/material";
import { ReportAnswer } from "@veris-health/virtual-doc-ms/lib/v1";
import { VrsErrorMsg } from "@veris-health/web-core";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { showQuestionsAnswers } from "./notificationsSlice";
import { VrsReportQADetails } from "./components/VrsReportQADetails";
import { Status } from "../shared/interfaces";

export interface VrsReportAnswersProps {
  subtitle?: string;
  reportQAList?: ReportAnswer[];
  isSymptomPreview?: boolean;
  reportQAListStatus?: Status;
  loadReportQAList?: () => void;
  reportName?: string;
}

export const ReportAnswersList = ({
  subtitle,
  reportQAList,
  reportQAListStatus,
  isSymptomPreview = false,
  loadReportQAList,
  reportName,
}: VrsReportAnswersProps): JSX.Element => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(showQuestionsAnswers(false));
    };
  }, []);

  return (
    <>
      <Box flexDirection="column" mb={3}>
        {!isSymptomPreview && (
          <Box paddingX={1.7} marginBottom={2}>
            <Typography mb={2.8} pt={1} component="div" variant="h6">
              <Box display="flex" flexDirection="row">
                <Box
                  sx={{
                    color: (theme) => theme.veris.colors.neutrals["grey-dark"],
                    padding: (theme) => theme.spacing(0, 1),
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                  onClick={() => dispatch(showQuestionsAnswers(false))}
                >
                  {subtitle}
                </Box>
                <Box component="span">/</Box>
                <Box
                  component="span"
                  sx={{
                    padding: (theme) => theme.spacing(0, 1),
                    color: (theme) => theme.veris.colors.amethyst.normal,
                  }}
                >
                  Questions & Answers
                </Box>
              </Box>
            </Typography>
          </Box>
        )}
        {reportQAListStatus === "loading" && !isSymptomPreview && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{
              paddingBlock: "2rem",
            }}
          >
            <CircularProgress />
          </Box>
        )}
        {reportQAListStatus === "failed" && loadReportQAList && !isSymptomPreview && (
          <Box
            display="flex"
            sx={{
              justifyContent: "center",
              paddingBlock: "6.25rem",
              backgroundColor: (theme) => theme.veris.colors.neutrals.white,
            }}
          >
            <VrsErrorMsg
              size="large"
              onClick={() => {
                loadReportQAList();
              }}
            />
          </Box>
        )}
        {(reportQAListStatus === "idle" || isSymptomPreview) && (
          <Stack
            borderRadius={2}
            maxHeight={isSymptomPreview ? "unset" : "589px"}
            overflow="scroll"
          >
            {reportQAList &&
              reportQAList.map((report) => {
                return (
                  <Box
                    p={2.28}
                    mb={2.28}
                    mx={isSymptomPreview ? 0 : 1}
                    key={report.questionId}
                    sx={{
                      backgroundColor: (theme) =>
                        theme.veris.colors.neutrals[isSymptomPreview ? "grey-1" : "white"],
                      borderRadius: (theme) => +theme.shape.borderRadius * 0.375,
                    }}
                  >
                    <VrsReportQADetails reportAnswer={report} reportName={reportName} />
                  </Box>
                );
              })}
            {!reportQAList &&
              "No answers to the specific questions of this particular symptom found"}
          </Stack>
        )}
      </Box>
    </>
  );
};
