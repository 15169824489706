import React, { useEffect } from "react";
import { Box, CircularProgress } from "@mui/material";
import { CallProvider } from "@azure/communication-react";
import { JoinCallOptions } from "@azure/communication-calling";
import { VideoCallUI } from "../VideoCallUI";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import { selectPatientChatInfo, selectAppointmentInfo } from "../../voipSlice";
import { setWindowedMode } from "../../../shared/slices/voipSlice";
import { useVideo } from "../../../../context/video";
import { VrsPatientInfo } from "../../../shared/interfaces";

export interface VideoCallSettingsComponentProps {
  isGroupCall: boolean;
  currentPatient: VrsPatientInfo;
  startCallOptions?: JoinCallOptions;
}

export function VideoCallSettingsComponent({
  isGroupCall,
  currentPatient,
  startCallOptions,
}: VideoCallSettingsComponentProps): JSX.Element {
  const dispatch = useAppDispatch();
  const chatUserInfo = useAppSelector(selectPatientChatInfo);
  const appointmentInfo = useAppSelector(selectAppointmentInfo);
  const videoProps = useVideo();

  useEffect(() => {
    if (isGroupCall && appointmentInfo) {
      videoProps.startVideoCall(currentPatient, appointmentInfo, undefined, startCallOptions);
    } else if (chatUserInfo) {
      videoProps.startVideoCall(currentPatient, undefined, chatUserInfo, startCallOptions);
    }
  }, []);

  if (!videoProps.call) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" sx={{ mt: 2 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <CallProvider call={videoProps.call}>
        <VideoCallUI onMinimizeClick={() => dispatch(setWindowedMode(true))} />
      </CallProvider>
    </>
  );
}
