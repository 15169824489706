import { Box, Theme, useMediaQuery } from "@mui/material";
import React from "react";
import { Demographics } from "@veris-health/user-ms/lib/v1";
import { VrsIconButton, IconName, verisColors } from "@veris-health/web-core";
import { VrsSingleTag } from "../VrsTags";
import { VrsTagAddon } from "../VrsTagAddon";
import { VrsPatientInfoCard } from "../VrsPatientInfoCard";
import { VrsPatientInfo } from "../../../features/shared/interfaces";
import { PatientInfoLabel } from "../PatientInfoLabel";
import { PatientInfoField } from "../PatientInfoField";
import { WeightTrendDialog } from "../WeightTrendDialog/WeightTrendDialog";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { selectToggleLeftSideBar } from "../../../features/shared/slices/uiSlice";

export interface PatientInfoHeaderProps {
  info: Demographics;
  patient: VrsPatientInfo;
  onToggle?: () => void;
  open?: boolean;
  endComponent?: JSX.Element;
}

export function PatientInfoHeader({
  onToggle,
  open,
  patient,
  info,
  endComponent,
}: PatientInfoHeaderProps): JSX.Element {
  const [firstTag, ...rest] = patient?.tags || [];
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("xl"));
  const leftSideBarExpanded = useAppSelector(selectToggleLeftSideBar);
  return (
    <Box
      sx={{
        flexGrow: 1,
        backgroundColor: verisColors.neutrals.white,
        borderRadius: (theme) => +theme.shape.borderRadius * 0.375,
        borderBottomLeftRadius: (theme) => (open ? 0 : +theme.shape.borderRadius),
        borderBottomRightRadius: (theme) => (open ? 0 : +theme.shape.borderRadius),
        padding: (theme) => theme.spacing(0.5, 1),
        border: (theme) => `1px solid ${theme.veris.colors.neutrals["grey-light"]}`,
      }}
      display="flex"
      alignItems="center"
      data-test-hook="patient-info-header"
    >
      <Box pr={1} data-test-hook="info-header-patient-container">
        {patient && (
          <VrsPatientInfoCard
            patientInfo={patient}
            backgroundColor="transparent"
            displayBattery
            styles={{
              diagnosis: {
                maxWidth: "170px",
              },
            }}
          />
        )}
      </Box>
      <Box pr={1}>
        <Box display="flex" data-test-hook="info-header-tags-container">
          {firstTag && (
            <VrsSingleTag label={firstTag.label} color={verisColors.neutrals["grey-3"]} small />
          )}
          {rest.length > 0 && <VrsTagAddon additionalTags={rest} small />}
        </Box>
      </Box>
      {patient?.type === "mio" && (
        <Box pr={2}>
          <Box
            display="flex"
            flexDirection={isSmallScreen && leftSideBarExpanded ? "column" : "row"}
            m={1}
            alignItems="center"
            onClick={(event: React.MouseEvent<HTMLDivElement>) => setAnchorEl(event.currentTarget)}
            sx={{ cursor: "pointer" }}
          >
            <PatientInfoLabel
              fieldName="Weight"
              fieldNameIcon="Weight"
              color={anchorEl ? verisColors.amethyst.normal : verisColors.neutrals["grey-3"]}
            />

            <PatientInfoField
              value={info?.weight ? `${info.weight}lb` : "N/A"}
              mask="999 lb"
              key={info.weight}
            />
          </Box>
          {!!anchorEl && (
            <WeightTrendDialog
              open={!!anchorEl}
              toggleDialog={() => setAnchorEl(null)}
              anchorEl={anchorEl}
              patient={patient}
              currentValue={info?.weight}
            />
          )}
        </Box>
      )}
      <Box sx={{ flexGrow: 1 }} m={1} />
      <VrsIconButton
        data-test-hook="treatment-arrow"
        iconProps={{ name: open ? IconName.ArrowUp : IconName.ArrowDownHeader }}
        onClick={onToggle}
        sx={{ margin: (theme) => theme.spacing(1) }}
      />
      {endComponent}
    </Box>
  );
}
