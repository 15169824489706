import React from "react";
import dayjs, { Dayjs } from "dayjs";
import { Box, Theme, useMediaQuery } from "@mui/material";
import isToday from "dayjs/plugin/isToday";
import advancedFormat from "dayjs/plugin/advancedFormat";
import weekOfYear from "dayjs/plugin/weekOfYear";
import Typography from "@mui/material/Typography";
import { verisColors, dateFormats } from "@veris-health/web-core";
import { SensorsGraphView } from "../measurementSlice";

dayjs.extend(isToday);
dayjs.extend(weekOfYear);
dayjs.extend(advancedFormat);

export default interface VrsGraphNavigatorItemProps {
  date: dayjs.Dayjs;
  view: SensorsGraphView;
  onClick?: (d: dayjs.Dayjs) => void;
  isPreview?: boolean;
}

const dayjsFormatMap: Record<SensorsGraphView, string> = {
  daily: "ddd MMM D",
  "3-days": "ddd MMM D",
  weekly: "ddd MMM D",
  monthly: `MMM D`,
};

export function VrsGraphNavigatorItem({
  date,
  view,
  onClick,
  isPreview,
}: VrsGraphNavigatorItemProps): JSX.Element {
  const handleClick = (d: dayjs.Dayjs) => () => onClick && onClick(d);
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("xl"));

  const getTodayLabel = () => {
    if (view === "daily") return `Today, ${date.format(dayjsFormatMap[view])}`;
    return "Today";
  };

  return isPreview ? (
    <Box
      mt={1}
      onClick={handleClick(date)}
      sx={{
        cursor: "pointer",
      }}
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      {view === "daily" && (
        <Typography variant="description" color={verisColors.neutrals["grey-mid"]}>
          {date.isToday() && "Today, "}
          {date.format(dayjsFormatMap[view])}
        </Typography>
      )}
      {(view === "3-days" || view === "weekly") && (
        <>
          <Typography variant="description" color={verisColors.neutrals["grey-mid"]}>
            {date.format(dayjsFormatMap[view]).charAt(0)}
          </Typography>
          <Typography variant="captionSemibold" color={verisColors.neutrals["grey-mid"]}>
            {date.format(dateFormats.D)}
          </Typography>
        </>
      )}
    </Box>
  ) : (
    <Typography
      sx={{
        cursor: "pointer",
      }}
      variant="subtitle2"
      component="span"
      color={verisColors.neutrals["grey-mid"]}
      onClick={handleClick(date)}
      minWidth="min-content"
      maxWidth={isSmallScreen && view !== "daily" ? 70 : "fit-content"}
    >
      {(view === "daily" || view === "3-days" || view === "weekly") && (
        <>{date.isToday() ? getTodayLabel() : date.format(dayjsFormatMap[view])}</>
      )}
      {view === "monthly" && (
        <>
          {`${date.subtract(6, "days").format(dayjsFormatMap[view])} - ${date.format(
            dayjsFormatMap[view],
          )}`}
        </>
      )}
    </Typography>
  );
}
