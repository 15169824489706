import Box from "@mui/material/Box";
import React, { useEffect, useRef } from "react";
import { Typography } from "@mui/material";
import { ClinicalTask, ClinicalTaskStatusEnum } from "@veris-health/med-data-ms/lib/v1";
import { IconName, VrsIconButton } from "@veris-health/web-core";
import CreateTask from "./CreateTask";
import { VrsMedStaffProfileModel } from "../../shared/interfaces";
import TaskContainer from "./TaskContainer";

export interface TaskPreviewProps {
  patientDetails?: boolean;
  task?: ClinicalTask;
  currentUser?: VrsMedStaffProfileModel;
  onClose: () => void;
  fetchTasks: () => void;
  userId?: string;
}

export function TaskPreview({
  patientDetails,
  task,
  currentUser,
  onClose,
  userId,
  fetchTasks,
}: TaskPreviewProps): JSX.Element {
  const previewRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (previewRef.current?.scrollTo) previewRef.current.scrollTo({ top: 0, behavior: "auto" });
  }, [task]);

  return (
    <>
      {patientDetails ? (
        <>
          {task && currentUser && (
            <TaskContainer
              open={!!task}
              onClose={onClose}
              task={task}
              currentUser={currentUser}
              currentPatientId={userId ? +userId : undefined}
              fetchTasks={fetchTasks}
            />
          )}
        </>
      ) : (
        <Box
          sx={{ backgroundColor: (theme) => theme.veris.colors.neutrals.white, overflow: "scroll" }}
          my={2.5}
          mr={2}
          border={(theme) => `1px solid ${theme.veris.colors.neutrals["grey-2"]}`}
          borderRadius={(theme) => +theme.shape.borderRadius * 0.5}
          mb={0}
          height="84vh"
          flexBasis="30%"
          ref={previewRef}
        >
          {task && currentUser ? (
            <Box px={2}>
              <Box display="flex" justifyContent="space-between" alignItems="center" py={2}>
                <Typography variant="h3">{task.title}</Typography>
                <VrsIconButton iconProps={{ name: IconName.CloseIcon }} onClick={onClose} />
              </Box>
              <CreateTask
                onClose={onClose}
                task={task}
                currentUser={currentUser}
                currentPatientId={userId ? +userId : undefined}
                viewMode={task?.status === ClinicalTaskStatusEnum.Resolved}
                fetchTasks={fetchTasks}
              />
            </Box>
          ) : (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              height="100%"
              sx={{ textAlign: "center" }}
            >
              <Box p={2}>
                <Typography color={(theme) => theme.veris.colors.neutrals["grey-4"]} variant="h3">
                  There are no tasks selected
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      )}
    </>
  );
}
