import {
  Box,
  ListItem,
  Typography,
  useTheme,
  Chip,
  ChipProps,
  chipClasses,
  styled,
} from "@mui/material";
import {
  IconName,
  Option,
  VrsAvatar,
  VrsIcon,
  verisColors,
  VrsSelect,
  VrsInput,
} from "@veris-health/web-core";
import React, { HTMLAttributes } from "react";
import { Datepicker } from "@mobiscroll/react";
import {
  AbnormalReadingType,
  ClinicalTask,
  ClinicalTaskComment,
  ClinicalTaskDataAbnormalReadingTypeEnum,
  ClinicalTaskDataOtherTypeEnum,
  ClinicalTaskStatusEnum,
  ClinicalTaskTypeEnum,
} from "@veris-health/med-data-ms/lib/v1";

import { useFormikContext } from "formik";
import dayjs from "dayjs";
import { DataSourceModel } from "@veris-health/user-ms/lib/v2";
import {
  SensorAutocompleteOption,
  SensorAutocompleteValue,
  priorityOptions,
  sensorOptionsData,
  statusOptions,
  taskTypeOptions,
} from "../utils/constants";
import { StyledTextField } from "../../PatientDetailsSidebar/components/SymptomReport/components/QuestionnaireAnswers";
import { AutoCompleteOption } from "../../../ui/components/VrsSearchBar";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { selectVrsPatientsSearchResult } from "../../shared/slices/patientsSlice";
import {
  AutoCompleteMultiselectOption,
  VrsChipMultiselect,
} from "../../../ui/components/VrsChipMultiselect";
import TaskComments from "./TaskComments";
import {
  VrsMedStaffProfileModel,
  VrsPatientDiagnosis,
  VrsPatientInfo,
} from "../../shared/interfaces";
import VrsRadioGroup from "../../../ui/components/VrsRadioGroup/VrsRadioGroup";
import { ValidationSchema } from "../utils/helpers";
import VrsMembersSearch from "../../../ui/components/VrsMembersSearch/VrsMembersSearch";
import { NotificationTaskData } from "./TaskContainer";
import { getPastDates } from "../../../utils/date";

const renderOption = (
  { onClick, ...rest }: HTMLAttributes<HTMLLIElement | HTMLDivElement>,
  { label, value: medStaffMember }: AutoCompleteOption<{ name: string; id: number }>,
) => {
  return (
    <ListItem
      onClick={onClick}
      dense
      disablePadding
      {...rest}
      sx={{ left: -5 }}
      key={`${medStaffMember.id}`}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between" flex={1} gap={1}>
        <Box display="flex" alignItems="center" gap={1}>
          <VrsAvatar name={medStaffMember.name} size={30} />
          <Typography my={0.5} variant="body" component="span">
            {label}
          </Typography>
        </Box>
      </Box>
    </ListItem>
  );
};

const PatientDemographics = ({ diagnosis }: { diagnosis: VrsPatientDiagnosis }) => (
  <Typography
    variant="caption"
    color={(theme) => theme.veris.colors.neutrals["grey-3"]}
    style={{ marginLeft: "auto", wordBreak: "break-word" }}
  >
    {`${diagnosis?.cancerType}, St.${diagnosis?.cancerStage}`}
  </Typography>
);

const renderPatientOption = (
  { onClick, ...rest }: HTMLAttributes<HTMLLIElement | HTMLDivElement>,
  {
    label,
    value: patient,
  }: AutoCompleteOption<VrsPatientInfo | { name: string; id: number; hospital: { id: number } }>,
) => {
  return (
    <ListItem
      key={`${patient.id}`}
      onClick={onClick}
      dense
      disablePadding
      {...rest}
      sx={{ left: -5 }}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between" flex={1} gap={1}>
        <Box display="flex" alignItems="center" gap={1}>
          <VrsAvatar
            imageUrl={"picture" in patient && patient.picture ? patient.picture : ""}
            name={patient.name}
            size={30}
            bottomRightIcon={
              "observation" in patient &&
              patient.observation && <VrsIcon name={IconName.Observation} />
            }
            opacity={
              "isInactiveOrDeceased" in patient && patient.isInactiveOrDeceased ? "70%" : "100%"
            }
          />
          <Typography my={0.5} variant="body" component="span">
            {label}
          </Typography>
        </Box>
        {"diagnosis" in patient && patient.diagnosis && (
          <PatientDemographics diagnosis={patient.diagnosis} />
        )}
      </Box>
    </ListItem>
  );
};

const renderVitalSignOption = (
  { onClick, ...rest }: HTMLAttributes<HTMLLIElement | HTMLDivElement>,
  { label, icon }: AutoCompleteMultiselectOption<SensorAutocompleteValue>,
) => {
  return (
    <ListItem key={`${label}`} onClick={onClick} dense disablePadding {...rest} sx={{ left: -5 }}>
      <Box display="flex" alignItems="center" flex={1} gap={1}>
        {icon && <VrsIcon name={icon} stroke={verisColors.neutrals["grey-4"]} />}
        <Typography my={0.5} variant="body" component="span">
          {label}
        </Typography>
      </Box>
    </ListItem>
  );
};

const StyledOptionWrapper = styled(Chip, {
  shouldForwardProp: (prop: string) => prop !== "backgroundColor",
})<ChipProps & { backgroundColor?: string; padding?: { y?: number; x?: number } }>(
  ({ theme, backgroundColor, padding }) => ({
    [`.${chipClasses.label}`]: {
      padding: 0,
    },
    display: "flex",
    alignItems: "center",
    width: "fit-content",
    backgroundColor: backgroundColor ?? "transparent",
    padding: theme.spacing(padding?.y || 0.25, padding?.x || 2),
    borderRadius: theme.spacing(2),
    height: "unset",
    margin: theme.spacing(0.5, 0),
  }),
);

const TaskFormFields = ({
  field,
  currentPatient,
  currentUser,
  isSubtypeDisabled,
  hospitalStaff,
  comments,
  subTypeOptions,
  viewMode,
  task,
  notificationTask,
}: {
  currentUser: VrsMedStaffProfileModel;
  field: any;
  subTypeOptions?: Option[] | SensorAutocompleteOption[];
  viewMode?: boolean;
  currentPatient?: VrsPatientInfo;
  task?: ClinicalTask;
  isSubtypeDisabled?: boolean;
  comments?: ClinicalTaskComment[];
  hospitalStaff?: {
    name: string;
    id: number;
  }[];
  notificationTask?: NotificationTaskData;
}): JSX.Element => {
  const data = useAppSelector(selectVrsPatientsSearchResult);
  const formik = useFormikContext<ValidationSchema>();
  const theme = useTheme();
  const pastDates = getPastDates();

  const { handleChange, setFieldValue, handleBlur } = formik;
  const { patient, title, createdBy, shared, taskType, subType } = formik.values;
  const disabledOption = (subTypeOptions as Option[])?.find((option) => option.value === "");
  const subTypeInitialValue = Array.isArray(subType)
    ? subType?.map((sensor) => ({
        label: sensorOptionsData[sensor].label,
        value: {
          id: sensor as AbnormalReadingType,
          icon: sensorOptionsData[sensor].icon,
        },
      }))
    : [];

  return (
    <Box width="100%" display="flex" flexDirection="column" alignItems="baseline" my={1}>
      {" "}
      {field.name === "title" && (
        <VrsInput
          placeholder="Write a title for this task"
          variant="outlined"
          value={title}
          onChange={(e) => setFieldValue(field.name, e.target.value)}
          onBlur={handleBlur}
          name={field.name}
          typography="body1"
          maxInputLength={200}
          fullWidth
          height="40px"
          disabled={viewMode || !!task}
        />
      )}
      {field.label === "Patient" && (
        <>
          {currentPatient && !notificationTask ? (
            <StyledOptionWrapper
              backgroundColor={verisColors.neutrals["smoke-white"]}
              label={
                <Box gap={1} display="flex" alignItems="center">
                  <VrsAvatar
                    imageUrl={currentPatient?.picture}
                    name={currentPatient?.name}
                    size={24}
                  />
                  <Typography variant="subtitle24">{currentPatient?.name}</Typography>
                </Box>
              }
            />
          ) : (
            <VrsMembersSearch<
              | VrsPatientInfo
              | {
                  name: string;
                  id: number;
                  hospital: { id: number };
                  dataSources: DataSourceModel[];
                }
            >
              data={data.patients}
              onChange={(_, value) =>
                setFieldValue(field.name, {
                  name: value?.value.name,
                  id: value?.value.id,
                  hospital: value?.value.hospital?.id,
                  image: value?.value && "picture" in value?.value ? value?.value.picture : "",
                  dataSources: value?.value.dataSources,
                })
              }
              id={field.name}
              onBlur={handleBlur}
              predefinedValue={
                field.value
                  ? {
                      label: field.value?.name,
                      value: field.value as unknown as VrsPatientInfo,
                    }
                  : undefined
              }
              disabled={!!task || viewMode || !!notificationTask?.patient}
              placeholder="Search patient by name"
              renderOption={renderPatientOption}
            />
          )}
        </>
      )}
      {field.label === "Status" && (
        <VrsRadioGroup
          options={
            task
              ? statusOptions
              : [{ label: "Unresolved", value: ClinicalTaskStatusEnum.Unresolved }]
          }
          onChange={(val) => setFieldValue(field.name, val)}
          value={field.value}
          name={field.name}
          disabled={viewMode}
        />
      )}
      {field.label === "Priority" && (
        <VrsRadioGroup
          options={priorityOptions}
          onChange={(val) => setFieldValue(field.name, val)}
          value={field.value}
          name={field.name}
          disabled={viewMode}
        />
      )}
      {field.label === "Created By" && task && (
        <StyledOptionWrapper
          backgroundColor={verisColors.amethyst.light}
          label={
            <Box gap={1} display="flex" alignItems="center" py={0.5}>
              <VrsAvatar imageUrl={createdBy?.image} name={createdBy?.name} size={24} />
              <Typography variant="subtitle24">{createdBy?.name}</Typography>
              {createdBy?.profession && (
                <Typography variant="subtitle24" color={theme.veris.colors.neutrals["grey-4"]}>
                  • {createdBy?.profession}
                </Typography>
              )}
            </Box>
          }
        />
      )}
      {field.label === "Date Created" && (
        <Box display="flex">
          <VrsIcon name={IconName.Calendar} stroke={verisColors.neutrals["grey-3"]} />
          <Datepicker
            dateFormat="MMM DD YYYY"
            inputComponent="input"
            inputProps={{
              readOnly: true,
              className: "vrs-datepicker",
            }}
            value={field.value}
            name={field.name}
            disabled
          />
        </Box>
      )}
      {field.label === "Due Date" && (
        <Box display="flex">
          <VrsIcon name={IconName.Calendar} stroke={verisColors.neutrals["grey-3"]} />
          <Datepicker
            anchorAlign="end"
            controls={["calendar"]}
            dateFormat="MMM DD YYYY"
            dateWheels="|DDD MMM D, YYYY|"
            inputComponent="input"
            inputProps={{
              placeholder: "Choose due date",
              readOnly: true,
              className: "vrs-datepicker",
            }}
            display="anchored"
            onChange={(picker) => setFieldValue(field.name, picker.value)}
            anchored
            value={field.value}
            id={field.value}
            name={field.name}
            onBlur={handleBlur}
            disabled={viewMode}
            invalid={pastDates}
            valid={task ? [dayjs(task.due_date).format()] : undefined}
          />
        </Box>
      )}
      {field.label === "Description" && (
        <StyledTextField
          onChange={handleChange}
          multiline
          maxHeight="500px"
          fullWidth
          name={field.name}
          value={field.value}
          onBlur={handleBlur}
          disabled={viewMode || !!task}
        />
      )}
      {field.label === "Task type" && (
        <VrsRadioGroup
          options={taskTypeOptions}
          onChange={(val) => setFieldValue(field.name, { type: val })}
          value={field.value?.type}
          name={field.name}
          disabled={!!task || viewMode}
          predefined={
            task && task.type === ClinicalTaskTypeEnum.Other && field.value?.text
              ? field.value.text
              : undefined
          }
          onInputChange={(val) =>
            setFieldValue(field.name, { type: ClinicalTaskDataOtherTypeEnum.Other, text: val })
          }
          isInputDisabled={!!task || field.value?.text || (!task && viewMode)}
        />
      )}{" "}
      {field.label === "Subtype" && (
        <>
          {taskType?.type === ClinicalTaskDataAbnormalReadingTypeEnum.AbnormalReading ? (
            <VrsChipMultiselect<SensorAutocompleteValue>
              options={(subTypeOptions as SensorAutocompleteOption[]) || []}
              onChange={(_, value) =>
                Array.isArray(value) &&
                setFieldValue(
                  field.name,
                  value.map((v) => v.value.id),
                )
              }
              id={field.name}
              placeholder="Search for vital sign"
              renderOption={renderVitalSignOption}
              disabled={isSubtypeDisabled}
              disableClearable={isSubtypeDisabled}
              initialValue={subTypeInitialValue}
              onBlur={handleBlur}
              fullWidth
              backgroundColor={theme.veris.colors.amethyst.light}
            />
          ) : (
            <VrsSelect
              options={(subTypeOptions as Option[]) || []}
              onSelected={(value) => setFieldValue(field.name, value)}
              name={field.name}
              value={field.value}
              variant="outlined"
              outerSx={{
                width: "100%",
              }}
              disabled={isSubtypeDisabled}
              innerSx={{ backgroundColor: theme.veris.colors.neutrals.white }}
              fullWidth
              onBlur={handleBlur}
              disabledOption={disabledOption}
            />
          )}
        </>
      )}
      {field.label === "Shared" && (
        <VrsChipMultiselect<{ name: string; id: number }>
          options={
            hospitalStaff?.map((member) => ({
              label: member.name,
              value: member,
            })) || []
          }
          onChange={(_, value) =>
            Array.isArray(value) &&
            setFieldValue(
              field.name,
              value.map((v) => v.value),
            )
          }
          id={field.name}
          placeholder="Search for a person"
          renderOption={renderOption}
          disabled={!hospitalStaff || viewMode}
          disableClearable={!hospitalStaff || viewMode}
          initialValue={
            Array.isArray(shared)
              ? shared?.map((member) => ({
                  label: member.name,
                  value: member,
                }))
              : []
          }
          onBlur={handleBlur}
          fullWidth
          backgroundColor={theme.veris.colors.amethyst.light}
        />
      )}
      {field.label === "Assignee" && (
        <VrsMembersSearch
          data={hospitalStaff || []}
          onChange={(event, value) => setFieldValue(field.name, value?.value)}
          placeholder="Search for a person"
          renderOption={renderOption}
          disabled={!hospitalStaff || viewMode}
          predefinedValue={
            field.value
              ? {
                  label: field.value?.name,
                  value: field.value,
                }
              : undefined
          }
          id={field.name}
          onBlur={handleBlur}
        />
      )}
      {field.name === "notes" && (
        <TaskComments
          currentUser={currentUser}
          onChange={(value) => setFieldValue(field.name, value)}
          comments={comments || []}
          patient={patient}
          viewMode={!task && viewMode}
          task={task}
        />
      )}
      {field.helperText && field.touched && (
        <Typography variant="description" color={theme.veris.colors.errors.normal} my={1}>
          {field.helperText}
        </Typography>
      )}
    </Box>
  );
};

export default TaskFormFields;
