import { styled, Button } from "@mui/material";
import { verisColors } from "@veris-health/web-core";

export const AddonButton = styled(Button)(({ theme }) => ({
  color: verisColors.neutrals["grey-3"],
  backgroundColor: verisColors.neutrals.white,
  border: `1px solid ${verisColors.neutrals["grey-3"]}`,
  margin: theme.spacing(0.375, 0.75, 0.375, 0),
  width: "fit-content",
  minWidth: "1.875rem",
  flexDirection: "column",
  "&:hover": {
    color: verisColors.amethyst.normal,
    border: `1px solid ${verisColors.amethyst.soft}`,
  },
}));
