import { Box, CircularProgress, Stack, Theme, Typography, useMediaQuery } from "@mui/material";
import "./NotificationsDropDown.scss";
import InfiniteScroll from "react-infinite-scroll-component";
import React, { useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Popup } from "@mobiscroll/react";
import { NotificationView, Reporter } from "@veris-health/communication-ms/lib/v1";
import { IconName, VrsIconButton, VrsDivider, VrsButton } from "@veris-health/web-core";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import { NotificationListItem } from "../../ui/components/NotificationListItem";
import {
  loadNotificationsDataAsync,
  NotificationFilter,
  notificationFilters,
  selectNotifications,
  selectFilteredNotificationsfromEarlier,
  selectFilteredNotificationsfromToday,
  selectFilteredNotificationsfromYesterday,
  selectNotificationsFetchingOffset,
  selectNotificationsFilter,
  selectNotificationsStatus,
  setNotificationsFilter,
  selectTotalCount,
} from "./notificationsSlice";
import { selectUserId } from "../shared/slices/authSlice";
import { replaceRouteParam } from "../../routes-config";
import { NOTIFICATIONS_OFFSET } from "../../constants";

export const NotificationsDropdownContainer = ({
  anchorEl,
  handleClose,
  setNotificationForTask,
}: {
  anchorEl: HTMLButtonElement;
  handleClose: () => void;
  setNotificationForTask: (notification: NotificationView) => void;
}): JSX.Element => {
  const isMediumScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("xl"));
  const filteredNotificationsToday = useAppSelector(selectFilteredNotificationsfromToday);
  const filteredNotificationsYesterday = useAppSelector(selectFilteredNotificationsfromYesterday);
  const filteredNotificationsEarlier = useAppSelector(selectFilteredNotificationsfromEarlier);
  const currentUserId = useAppSelector(selectUserId);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const activeFilter = useAppSelector(selectNotificationsFilter);
  const open = !!anchorEl;
  const offset = useAppSelector(selectNotificationsFetchingOffset);
  const totalCount = useAppSelector(selectTotalCount);
  const notificationStatus = useAppSelector(selectNotificationsStatus);
  const notifications = useAppSelector(selectNotifications);

  useEffect(() => {
    dispatch(
      loadNotificationsDataAsync({
        userId: Number(currentUserId),
        offset: 0,
        reportedBy:
          activeFilter === NotificationFilter.ViewAll
            ? undefined
            : (activeFilter as unknown as Reporter),
      }),
    );
  }, [activeFilter]);

  const fetchNotifications = useCallback(async () => {
    if (notificationStatus === "loading") {
      return;
    }
    dispatch(
      loadNotificationsDataAsync({
        userId: Number(currentUserId),
        offset: offset + NOTIFICATIONS_OFFSET,
        reportedBy:
          activeFilter === NotificationFilter.ViewAll
            ? undefined
            : (activeFilter as unknown as Reporter),
      }),
    );
  }, [notificationStatus]);

  const handleNotificationClick = (notification: NotificationView) => {
    history.push(
      replaceRouteParam(
        "NOTIFICATIONS_DETAILS",
        ":notificationId",
        notification.notification_id.toString(),
      ),
    );
    handleClose();
  };

  return (
    <>
      <Popup
        isOpen={open}
        anchor={anchorEl}
        onClose={handleClose}
        scrollLock={false}
        display="anchored"
        css={{
          width: isMediumScreen ? "450px" : "550px",
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          marginTop: 2.1,
          padding: 0,
        }}
        contentPadding={false}
        touchUi={false}
      >
        <Stack
          direction="row"
          mx={2}
          my={1}
          position="static"
          overflow="hidden"
          justifyContent="space-between"
        >
          <Typography variant="h5" pt={1}>
            Notifications
          </Typography>
          <Box pt={0.5}>
            <VrsIconButton iconProps={{ name: IconName.CloseIcon }} onClick={handleClose} />
          </Box>
        </Stack>
        <Stack direction="row" spacing={2} mx={2} my={1.5}>
          {notificationFilters.map((filterButton) => {
            return (
              <VrsButton
                key={filterButton.name}
                buttonType={activeFilter === filterButton.filter ? "primary" : "quaternary"}
                onClick={() => dispatch(setNotificationsFilter(filterButton.filter))}
                sx={{
                  borderRadius: "0.5rem",
                }}
              >
                {filterButton.name}
              </VrsButton>
            );
          })}
        </Stack>
        <VrsDivider orientation="horizontal" height="100%" />
        <Stack
          pl={1}
          pr={1.5}
          overflow="auto"
          sx={{ maxHeight: "50vh", maxWidth: "550px", minHeight: "60px" }}
          id="notifications-dropdown-scroll-container"
        >
          <InfiniteScroll
            scrollableTarget="notifications-dropdown-scroll-container"
            hasMore={notifications.length < totalCount}
            style={{ overflow: "hidden" }}
            dataLength={notifications.length}
            next={() => {
              if (notificationStatus === "idle") fetchNotifications();
            }}
            loader={
              <Box display="flex" justifyContent="center" alignItems="center" p={1}>
                <CircularProgress />
              </Box>
            }
          >
            {notifications.length === 0 && (
              <Typography
                variant="body"
                paddingY={1}
                mt={2}
                color={(theme) => theme.veris.colors.neutrals["grey-3"]}
              >
                There are no notifications available for this filter.
              </Typography>
            )}
            {filteredNotificationsToday.length > 0 && (
              <>
                <Typography
                  variant="body1"
                  p={1}
                  mt={2}
                  color={(theme) => theme.veris.colors.neutrals["grey-3"]}
                  sx={{ textTransform: "uppercase" }}
                >
                  Today
                </Typography>
                {filteredNotificationsToday.map((notification, index, array) => (
                  <NotificationListItem
                    key={notification.notification_id}
                    notification={notification}
                    onClick={() => {
                      handleNotificationClick(notification);
                      handleClose();
                    }}
                    isLast={array.length - 1 === index}
                    onCreateTask={setNotificationForTask}
                  />
                ))}
              </>
            )}
            {filteredNotificationsYesterday.length > 0 && (
              <>
                <Typography
                  variant="body1"
                  p={1}
                  mt={2}
                  color={(theme) => theme.veris.colors.neutrals["grey-3"]}
                  sx={{ textTransform: "uppercase" }}
                >
                  Yesterday
                </Typography>
                {filteredNotificationsYesterday.map((notification, index, array) => (
                  <NotificationListItem
                    key={notification.notification_id}
                    notification={notification}
                    onClick={() => {
                      handleNotificationClick(notification);
                      handleClose();
                    }}
                    isLast={array.length - 1 === index}
                    onCreateTask={setNotificationForTask}
                  />
                ))}
              </>
            )}
            {filteredNotificationsEarlier.length > 0 && (
              <>
                <Typography
                  variant="body1"
                  p={1}
                  mt={2}
                  color={(theme) => theme.veris.colors.neutrals["grey-3"]}
                  sx={{ textTransform: "uppercase" }}
                >
                  Older
                </Typography>
                {filteredNotificationsEarlier.map((notification, index, array) => (
                  <NotificationListItem
                    key={notification.notification_id}
                    notification={notification}
                    onClick={() => {
                      handleNotificationClick(notification);
                      handleClose();
                    }}
                    isLast={array.length - 1 === index}
                    onCreateTask={setNotificationForTask}
                  />
                ))}
              </>
            )}
          </InfiniteScroll>
        </Stack>
        <Stack
          sx={{
            backgroundColor: (theme) => theme.veris.colors.neutrals["smoke-white"],
            padding: (theme) => theme.spacing(2),
            boxShadow: (theme) => `2px 0px 8px ${theme.veris.colors.neutrals["grey-1"]}`,
          }}
          alignItems="end"
        >
          <VrsIconButton
            data-test-hook="notifications-max-btn"
            onClick={() => {
              history.push(
                replaceRouteParam("NOTIFICATIONS_DETAILS", ":notificationId", activeFilter),
              );
              handleClose();
            }}
            sx={{ marginLeft: "auto", cursor: "pointer" }}
            iconProps={{ name: IconName.ExpandIcon }}
          />
        </Stack>
      </Popup>
    </>
  );
};
