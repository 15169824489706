import React, { useEffect } from "react";
import { Box, Collapse } from "@mui/material";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";
import { AppointmentStatusEnum } from "@veris-health/med-data-ms/lib/v1";
import { VrsAlert } from "@veris-health/web-core";
import { DashboardTableContainer } from "./DashboardTableContainer";
import { VrsTodaysAppointments } from "../../ui/components/VrsTodaysAppointments";
import { useAppSelector } from "../../hooks/useAppSelector";
import { selectAppointments, selectAppointmentsStatus } from "./dashboardSlice";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { VrsInpatients } from "../../ui/components/VrsInpatients";
import { searchPatientsAsync } from "../shared/slices/asyncThunks";
import { selectInpatients, selectPatientStatus } from "../shared/slices/patientsSlice";
import { setCalendarView } from "../Calendar/calendarSlice";
import { replaceRouteParam, Routes } from "../../routes-config";
import {
  toggleLoginBanner,
  selectIsLoginBannerOpen,
  selectUserId,
} from "../shared/slices/authSlice";
import { fetchCalendarEventsAsync } from "./api/dashboardApi";

const VrsLoginBanner = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const showBanner = useAppSelector(selectIsLoginBannerOpen);

  useEffect(() => {
    if (showBanner)
      setTimeout(() => {
        dispatch(toggleLoginBanner(false));
      }, 15000);
    return () => {
      dispatch(toggleLoginBanner(false));
    };
  }, [showBanner, dispatch]);
  return (
    <>
      {showBanner && (
        <Box m={1}>
          <Collapse in={showBanner}>
            <VrsAlert
              message="You will remain logged in for additional 60 minutes after your last action on the portal, even if you close the browser. Please logout via the Profile section for immediate termination of your session"
              severity="warning"
              onClose={() => dispatch(toggleLoginBanner(false))}
            />
          </Collapse>
        </Box>
      )}
    </>
  );
};

export function DashboardContainer(): JSX.Element {
  const inpatients = useAppSelector(selectInpatients);
  const appointments = useAppSelector(selectAppointments);
  const patientsStatus = useAppSelector(selectPatientStatus);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const currentUser = useAppSelector(selectUserId);
  const appointmentsStatus = useAppSelector(selectAppointmentsStatus);

  useEffect(() => {
    if (currentUser)
      dispatch(
        fetchCalendarEventsAsync({
          userId: +currentUser,
          start: dayjs().toISOString(),
          end: dayjs().endOf("day").toISOString(),
          statuses: [AppointmentStatusEnum.Booked, AppointmentStatusEnum.Proposed],
        }),
      );
  }, [dispatch, currentUser]);

  return (
    <>
      <VrsLoginBanner />
      <Box
        display="flex"
        flexDirection="row"
        sx={{
          flexGrow: 1,
          backgroundColor: (theme) => theme.veris.colors.neutrals["grey-1"],
          flexBasis: "80%",
        }}
        py={2.5}
        px={2}
        justifyContent="space-between"
      >
        <Box mr={1} display="flex" justifyContent="stretch" flexDirection="column" sx={{ flex: 1 }}>
          <DashboardTableContainer />
        </Box>
        <Box display="flex" flexDirection="column" ml={1} flexBasis="20%">
          <VrsInpatients
            inpatients={inpatients}
            inpatientsStatus={patientsStatus}
            onInpatientsClick={() => dispatch(searchPatientsAsync({ allPatients: false }))}
            onPatientClick={(id) =>
              history.push(replaceRouteParam("PATIENT_DETAILS", ":userId", id.toString()))
            }
          />
          <VrsTodaysAppointments
            todaysAppointmentsList={appointments}
            appointmentsStatus={appointmentsStatus}
            onTodaysAppointmentsClick={() => {
              if (currentUser)
                dispatch(
                  fetchCalendarEventsAsync({
                    userId: +currentUser,
                    start: dayjs().startOf("day").toISOString(),
                    end: dayjs().startOf("day").add(1, "day").toISOString(),
                    statuses: [AppointmentStatusEnum.Booked, AppointmentStatusEnum.Proposed],
                  }),
                );
            }}
            onPatientClick={(id: string) => {
              if (id) history.push(replaceRouteParam("PATIENT_DETAILS", ":userId", id));
            }}
            onViewAllClick={() => {
              history.push(Routes.CALENDAR);
              dispatch(setCalendarView({ view: "day", viewType: "calendar" }));
            }}
          />
        </Box>
      </Box>
    </>
  );
}
