import React, { useEffect, useState } from "react";
import { Box, CircularProgress, styled } from "@mui/material";
import { DiagnosticReportSummary } from "@veris-health/med-data-ms/lib/v1";
import { VrsErrorMsg } from "@veris-health/web-core";
import { PatientRecordsList } from "../components/PatientRecordsList";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import {
  ResultEntries,
  loadDiagnosticReportsAsync,
  selectDiagnosticReports,
  loadLabResultsDetailedListAsync,
  selectDiagnosticReportsStatus,
  selectSelectedRecord,
  setSelectedRecord,
  clearFilteredResults,
  setSearchPhrase,
  selectFilteredLabResultsStatus,
  selectSearchPhrase,
  loadFilteredLabResultsAsync,
} from "../patientRecordsSlice";
import { useAppSelector } from "../../../hooks/useAppSelector";
import LabResultPreviewContainer from "./LabResultPreviewContainer";
import { useCurrentPatient } from "../../../hooks/useCurrentPatient";

const StyledContainer = styled("div")(({ theme }) => ({
  height: "100%",
  padding: theme.spacing(1),
  width: "100%",
  maxHeight: "70vh",
  overflowY: "scroll",
}));

export const PatientResultsContainer = (): JSX.Element => {
  const currentPatient = useCurrentPatient();
  const diagnosticReports = useAppSelector((state) =>
    selectDiagnosticReports(state, currentPatient?.id),
  );
  const diagnosticReportsStatus = useAppSelector(selectDiagnosticReportsStatus);
  const filteredDiagnosticReportStatus = useAppSelector(selectFilteredLabResultsStatus);
  const selectedRecord = useAppSelector(selectSelectedRecord);
  const searchPhrase = useAppSelector(selectSearchPhrase);
  const dispatch = useAppDispatch();
  const [activePreviewSection, setActivePreviewSection] = useState<ResultEntries>();

  useEffect(() => {
    if (currentPatient) dispatch(loadDiagnosticReportsAsync(currentPatient?.id));
  }, [dispatch, currentPatient]);

  useEffect(() => {
    return () => {
      dispatch(setSelectedRecord());
    };
  }, []);

  const handleRecordClick = (result?: DiagnosticReportSummary, activeSection?: ResultEntries) => {
    setActivePreviewSection(activeSection);
    dispatch(setSelectedRecord(result));
    if (currentPatient && result)
      dispatch(
        loadLabResultsDetailedListAsync({ patientId: currentPatient?.id, reportId: result.id }),
      );
    dispatch(setSearchPhrase(""));
    dispatch(clearFilteredResults());
  };

  const selectActivePreview = () => {
    switch (activePreviewSection) {
      case ResultEntries.LabTests:
        return (
          <LabResultPreviewContainer
            onCloseClickHandler={() => {
              setActivePreviewSection(undefined);
              dispatch(setSelectedRecord(undefined));
            }}
          />
        );
      default:
        return <> </>;
    }
  };

  return (
    <Box sx={{ display: "flex", overflow: "hidden" }}>
      {(diagnosticReportsStatus === "loading" || filteredDiagnosticReportStatus === "loading") && (
        <Box display="flex" justifyContent="center" alignItems="center" height="70vh" width="100%">
          <CircularProgress />
        </Box>
      )}
      {diagnosticReportsStatus === "failed" && (
        <VrsErrorMsg
          size="small"
          onClick={() => {
            if (currentPatient) dispatch(loadDiagnosticReportsAsync(currentPatient?.id));
          }}
        />
      )}
      {filteredDiagnosticReportStatus === "failed" && (
        <VrsErrorMsg
          size="small"
          onClick={() => {
            if (currentPatient && searchPhrase.length >= 3)
              dispatch(
                loadFilteredLabResultsAsync({
                  patientId: currentPatient.id,
                  searchQuery: searchPhrase,
                }),
              );
          }}
        />
      )}
      {diagnosticReportsStatus === "idle" && filteredDiagnosticReportStatus === "idle" && (
        <StyledContainer>
          <PatientRecordsList
            title={ResultEntries.LabTests}
            resultList={diagnosticReports?.lab || []}
            onShowResultClick={handleRecordClick}
          />
        </StyledContainer>
      )}

      {activePreviewSection &&
        selectedRecord &&
        diagnosticReports?.lab &&
        diagnosticReports?.lab.length > 0 &&
        selectActivePreview()}
    </Box>
  );
};
