import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { VrsButton, VrsDialog, verisColors } from "@veris-health/web-core";
import {
  QuestionnaireView,
  QuestionnaireViewLabel,
  ReportItem,
} from "@veris-health/virtual-doc-ms/lib/v1";
import { groupBy } from "lodash";
import { VrsSingleTag } from "../../../../ui/components/VrsTags";
import {
  VrsReportedSymptom,
  fetchAllSymptoms,
  fetchSymptomQuestionnare,
  getReports,
} from "./api/symptomReportApi";
import SymptomQuestionnaire from "./components/SymptomQuestionnaire";
import { useCurrentPatient } from "../../../../hooks/useCurrentPatient";
import { StyledTextField } from "./components/QuestionnaireAnswers";
import SymptomOptionsWrapper from "./components/SymptomOptionsWrapper";

export const SymptomReport = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}): JSX.Element => {
  const [symptoms, setSymptoms] = useState<VrsReportedSymptom>();
  const [selectedSymptom, setSelectedSymptom] = useState<string>();
  const [selectedPart, setSelectedPart] = useState<string>();
  const [questionnaire, setQuestionnaire] = useState<QuestionnaireView>();
  const [filteredSymptoms, setFilteredSymptoms] = useState<QuestionnaireViewLabel[]>();
  const [reportedSymptoms, setReportedSymptoms] = useState<{ [key: string]: ReportItem[] }>();
  const [toggleConfirmationModal, setToggleConfirmationModal] = useState<boolean>(false);
  const currentPatient = useCurrentPatient();

  const filterSymptoms = (value: string) => {
    const newArr =
      symptoms &&
      symptoms[selectedPart ?? "general"].filter((symptom) =>
        symptom.label.replaceAll("-", " ").includes(value.toLowerCase()),
      );
    setFilteredSymptoms(newArr || []);
  };

  useEffect(() => {
    if (symptoms) {
      const newArr = symptoms[selectedPart ?? "general"];
      setFilteredSymptoms(newArr);
    }
  }, [selectedPart]);

  useEffect(() => {
    fetchAllSymptoms().then((res) => {
      setSymptoms(res);
      setFilteredSymptoms(res?.general);
    });
    if (currentPatient)
      getReports(currentPatient.id).then((res) => {
        const groupedSymptoms = groupBy(res, "status");
        setReportedSymptoms(groupedSymptoms);
      });
  }, [currentPatient]);

  useEffect(() => {
    if (selectedSymptom)
      fetchSymptomQuestionnare(selectedSymptom).then((res) => setQuestionnaire(res));
  }, [selectedSymptom]);

  return (
    <>
      <VrsDialog
        open={open}
        onClose={() => setToggleConfirmationModal(true)}
        title="Symptom report"
        width="60%"
      >
        {questionnaire ? (
          <SymptomQuestionnaire
            questionnaire={questionnaire}
            closeModal={onClose}
            reportedSymptoms={reportedSymptoms?.active}
          />
        ) : (
          <>
            <Box my={1}>
              <Typography variant="h4" my={1}>
                Please select a symptom or a body part
              </Typography>
              <Box my={2}>
                <Typography
                  variant="body2"
                  color={(theme) => theme.veris.colors.neutrals["grey-3"]}
                >
                  Body part
                </Typography>
                <Box display="flex" gap={1} flexWrap="wrap">
                  {symptoms &&
                    Object.keys(symptoms)
                      .slice(1, Object.keys(symptoms).length - 1)
                      .map((el) => (
                        <VrsSingleTag
                          label={el?.replaceAll("-", " ")}
                          color={
                            selectedPart === el
                              ? verisColors.amethyst.normal
                              : verisColors.neutrals["grey-3"]
                          }
                          onClick={() => {
                            setSelectedPart(selectedPart === el ? undefined : el);
                          }}
                          selected={selectedPart === el}
                          key={el}
                        />
                      ))}
                </Box>
              </Box>
              <StyledTextField
                value={selectedSymptom?.replaceAll("-", " ")}
                onChange={(e) => {
                  filterSymptoms(e.target.value || "");
                }}
                variant="outlined"
                fullWidth
                placeholder="Search your symptom"
                maxHeight="45px"
              />

              <Box my={2}>
                <Box>
                  {filteredSymptoms && filteredSymptoms?.length > 0 ? (
                    <SymptomOptionsWrapper
                      filteredSymptoms={filteredSymptoms}
                      setSelectedSymptom={setSelectedSymptom}
                      reportedSymptoms={reportedSymptoms}
                      selectedSymptom={selectedSymptom}
                    />
                  ) : (
                    <Box
                      my={3}
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Typography
                        variant="body1"
                        color={(theme) => theme.veris.colors.neutrals["grey-3"]}
                        my={1}
                      >
                        No symptoms found. Create a new user defined symptom.
                      </Typography>
                      <VrsButton
                        buttonType="secondary"
                        onClick={() => setSelectedSymptom("user-defined-symptom")}
                      >
                        Create user defined symptom
                      </VrsButton>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </>
        )}
      </VrsDialog>
      {toggleConfirmationModal && (
        <VrsDialog open={toggleConfirmationModal} onClose={onClose}>
          <Typography
            variant="h4"
            textAlign="center"
            color={(theme) => theme.veris.colors.neutrals["grey-4"]}
          >
            You have unfinished changes.
            <br /> Are you sure you want to stop submitting the symptom report ?
          </Typography>
          <Box display="flex" justifyContent="space-around" my={2}>
            <VrsButton buttonType="secondary" onClick={onClose}>
              Close anyway
            </VrsButton>
            <VrsButton buttonType="primary" onClick={() => setToggleConfirmationModal(false)}>
              Proceed with symptom report
            </VrsButton>
          </Box>
        </VrsDialog>
      )}
    </>
  );
};
