import { Typography, styled, Box, ClickAwayListener, Popover } from "@mui/material";
import { IconName, VrsIconButton, verisColors, dateFormats } from "@veris-health/web-core";
import dayjs, { Dayjs } from "dayjs";
import { get } from "lodash";
import React, { useState } from "react";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import { SensorsGraphView, setReferenceLine } from "../../measurementSlice";
import { MeasurementGraphProps } from "../Sensors/MeasurementGraph";

export interface TooltipContentProps {
  sensorsGraphView: SensorsGraphView;
  measurementUnit?: string;
  value?: number;
  value2?: number;
  dt: Dayjs;
  isPreview?: boolean;
  isMotionChart?: boolean;
  timezone?: string;
  showValue?: boolean;
}

const shouldShowTime = (sensorsGraphView: SensorsGraphView, isMotionChart?: boolean) => {
  if (isMotionChart) {
    return sensorsGraphView === "daily";
  }
  return true;
};

const extractTimezoneFromTheFormat = (showTime: boolean, ldt: string, isPreview?: boolean) => {
  const sensorsFormat = showTime ? "h:mm a" : `MMM DD${isPreview ? "" : ", YY"}`;
  const originalTimezone = ldt.slice(-6);
  const formattedDate = dayjs(ldt).utcOffset(originalTimezone).format(sensorsFormat);
  return formattedDate;
};

export const ChartTooltipContent = ({
  measurementUnit,
  value,
  value2,
  dt,
  isPreview,
  isMotionChart,
  timezone,
  showValue,
  sensorsGraphView,
}: TooltipContentProps): JSX.Element => {
  return (
    <>
      {value2 ? (
        <>
          <Box display="flex">
            {!isPreview && (
              <Typography
                component="span"
                variant="caption"
                color={verisColors.neutrals["grey-mid"]}
                sx={{ width: "60px", marginRight: (theme) => theme.spacing(1), textAlign: "left" }}
              >
                Systolic
              </Typography>
            )}

            <Typography
              component="span"
              variant="caption"
              sx={{
                marginRight: "auto",
              }}
            >
              {value}
            </Typography>
          </Box>
          <Box display="flex">
            {!isPreview && (
              <Typography
                component="span"
                variant="caption"
                color={verisColors.neutrals["grey-mid"]}
                sx={{ width: "60px", marginRight: (theme) => theme.spacing(1), textAlign: "left" }}
              >
                Diastolic
              </Typography>
            )}

            <Typography
              component="span"
              variant="caption"
              sx={{
                marginRight: "auto",
              }}
            >
              {value2}
            </Typography>
          </Box>
        </>
      ) : (
        <>
          {showValue && (
            <Box display="flex">
              {!isPreview && (
                <Typography
                  component="span"
                  variant="caption"
                  color={verisColors.neutrals["grey-mid"]}
                  sx={{
                    width: "60px",
                    marginRight: (theme) => theme.spacing(1),
                    textAlign: "left",
                  }}
                >
                  {isMotionChart ? "Steps" : "Current"}
                </Typography>
              )}

              <Typography
                component="span"
                variant="caption"
                sx={{
                  marginRight: "auto",
                }}
              >
                {measurementUnit ? `${value?.toFixed(1)} ${measurementUnit}` : value?.toFixed(0)}
              </Typography>
            </Box>
          )}
        </>
      )}
      <Box display="flex">
        {!isPreview && (
          <Typography
            component="span"
            variant="caption"
            color={verisColors.neutrals["grey-mid"]}
            sx={{ width: "60px", marginRight: (theme) => theme.spacing(1), textAlign: "left" }}
          >
            Date
          </Typography>
        )}
        <Typography
          component="span"
          variant="caption"
          sx={{
            marginRight: "auto",
          }}
        >
          {dt.format(isPreview ? "MMM DD" : "MMM DD, YY")}
        </Typography>
      </Box>
      {shouldShowTime(sensorsGraphView, isMotionChart) && (
        <Box display="flex">
          {!isPreview && (
            <Typography
              component="span"
              variant="caption"
              color={verisColors.neutrals["grey-mid"]}
              sx={{ width: "60px", marginRight: (theme) => theme.spacing(1), textAlign: "left" }}
            >
              Time
            </Typography>
          )}
          <Typography
            component="span"
            variant="caption"
            sx={{
              marginRight: "auto",
            }}
          >
            {dt.format(`${dateFormats["h:mm"]} a`)}
          </Typography>
        </Box>
      )}
      {timezone && (
        <>
          <Box display="flex">
            {!isPreview && (
              <Typography
                component="span"
                variant="caption"
                color={verisColors.neutrals["grey-mid"]}
                sx={{
                  width: "60px",
                  marginRight: (theme) => theme.spacing(1),
                  textAlign: "left",
                }}
              >
                Local Date
              </Typography>
            )}
            <Typography
              component="span"
              variant="caption"
              sx={{
                marginRight: "auto",
                width: "60px",
                textAlign: "left",
              }}
            >
              {extractTimezoneFromTheFormat(false, timezone, isPreview)}
            </Typography>
          </Box>
          {shouldShowTime(sensorsGraphView, isMotionChart) && (
            <Box display="flex">
              {!isPreview && (
                <Typography
                  component="span"
                  variant="caption"
                  color={verisColors.neutrals["grey-mid"]}
                  sx={{
                    width: "60px",
                    marginRight: (theme) => theme.spacing(1),
                    textAlign: "left",
                  }}
                >
                  Local Time
                </Typography>
              )}
              <Typography
                component="span"
                variant="caption"
                sx={{
                  marginRight: "auto",
                  width: "60px",
                  textAlign: "left",
                }}
              >
                {extractTimezoneFromTheFormat(true, timezone, isPreview)}
              </Typography>
            </Box>
          )}
        </>
      )}
    </>
  );
};

export const ChartTooltip = styled("div", {
  shouldForwardProp: (prop) => prop !== "isPreview",
})<Pick<MeasurementGraphProps, "isPreview">>(({ theme, isPreview }) => ({
  minWidth: isPreview ? "60px" : "150px",
  display: "flex",
  flexDirection: "column",
  backgroundColor: theme.veris.colors.neutrals.black,
  color: theme.veris.colors.neutrals.white,
  padding: theme.spacing(1),
  borderRadius: "2px",
}));

export interface TooltipWrapperProps {
  sensorsGraphView: SensorsGraphView;
  lineValue?: string | number;
  measurementUnit?: string;
  isPreview?: boolean;
  payload?:
    | [
        {
          payload: {
            value: number;
            dt: string | number;
            sys?: number;
            dia?: number;
            // eslint-disable-next-line camelcase
            eq5d_index?: number;
            timestamp?: string;
            // eslint-disable-next-line camelcase
            median_value?: number;
            start?: string;
          };
          value: number;
        },
        {
          value: number;
        },
      ]
    | undefined;
  isMioChart?: boolean;
  isVerisChart?: boolean;
  isMotionChart?: boolean;
  showValue?: boolean;
}

export const ChartTooltipWrapper = ({
  payload,
  lineValue,
  measurementUnit,
  isPreview,
  isMioChart,
  isVerisChart,
  isMotionChart,
  showValue,
  sensorsGraphView,
}: TooltipWrapperProps): JSX.Element | null => {
  const { dt, ldt, data_source: dataSource } = get(payload, `[0].payload`, {});
  const getMioVisibility = () => {
    if (isMioChart) {
      return true;
    }
    if (isMotionChart && dataSource === "mio") {
      return true;
    }
    return dataSource !== "mio";
  };

  const showMioValues = getMioVisibility();
  if (payload && lineValue !== dt && showMioValues) {
    const formattedDt = isMioChart || isVerisChart ? dayjs.unix(+dt) : dayjs(dt);

    return (
      <ChartTooltip className="tooltip" isPreview={isPreview}>
        <ChartTooltipContent
          measurementUnit={measurementUnit}
          value={payload[0]?.value}
          value2={isMioChart ? payload[1]?.value : undefined}
          dt={formattedDt}
          isPreview={isPreview}
          isMotionChart={isMotionChart}
          timezone={ldt}
          showValue={showValue}
          sensorsGraphView={sensorsGraphView}
        />
      </ChartTooltip>
    );
  }
  return null;
};

export const StyledLabelWrapper = styled("div")(({ theme }) => ({
  ...theme.typography.caption,
  width: "155px",
  backgroundColor: verisColors.neutrals["grey-2"],
  padding: theme.spacing(0.5, 1),
  borderRadius: "2px",
  textAlign: "center",
}));

export interface ChartLabelProps {
  viewBox: { x: number; y: number; offset: number };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
  direction: boolean;
  dateTime: string | Date | number;
  sensorsGraphView: SensorsGraphView;
  dataKey?: string;
  secondaryDataKey?: string;
  isPreview?: boolean;
  measurementUnit?: string;
  isImplantChart?: boolean;
  isMotionChart?: boolean;
  timezone?: string;
}

export const ChartLabel = ({
  viewBox,
  value,
  measurementUnit,
  direction,
  dateTime,
  isPreview,
  dataKey,
  secondaryDataKey,
  isMotionChart,
  timezone,
  sensorsGraphView,
}: ChartLabelProps): JSX.Element => {
  const dt = dayjs.unix(+dateTime);
  const tooltipPosition = isPreview ? -65 : -145;
  const dispatch = useAppDispatch();
  const getValue = () => {
    if (value.data_source === "mio" && dataKey) {
      return value[dataKey];
    }
    if (isMotionChart) return value.sum;
    return value.v;
  };

  return (
    <ClickAwayListener
      onClickAway={() =>
        dispatch(
          setReferenceLine({
            date: "",
            valueIndex: -1,
          }),
        )
      }
    >
      <foreignObject
        x={viewBox.x + (direction ? 5 : tooltipPosition)}
        y={viewBox.y}
        dy={viewBox.offset}
        width={isPreview ? "60" : "155"}
        height={100}
      >
        <StyledLabelWrapper>
          <ChartTooltipContent
            measurementUnit={measurementUnit}
            value={getValue()}
            value2={secondaryDataKey && value[secondaryDataKey]}
            dt={dt}
            isPreview={isPreview}
            isMotionChart={isMotionChart}
            timezone={timezone}
            showValue
            sensorsGraphView={sensorsGraphView}
          />
        </StyledLabelWrapper>
        {!isPreview && (
          <Box sx={{ position: "fixed", right: 0, top: 0 }}>
            <VrsIconButton
              iconProps={{ name: IconName.CloseIcon, size: 15 }}
              onClick={(event) => {
                event.stopPropagation();

                dispatch(
                  setReferenceLine({
                    date: "",
                    valueIndex: -1,
                  }),
                );
              }}
            />
          </Box>
        )}
      </foreignObject>
    </ClickAwayListener>
  );
};

export const ChartNormalValueLabel = ({
  value,
  viewBox,
}: {
  value: number | undefined;
  viewBox: { x: number; y: number; width: number; height: number };
}): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  return (
    <>
      <foreignObject
        x={Number(viewBox.x) ? viewBox.x - 26 : undefined}
        y={Number(viewBox.y) ? viewBox.y - 10 : undefined}
        width={value && value > 999 ? 33 : 25}
        height={20}
      >
        <Box
          sx={{
            backgroundColor: verisColors.neutrals["grey-2"],
            borderRadius: "2px",
            textAlign: "center",
            "&:hover": {
              cursor: "pointer",
            },
          }}
          onClick={(event) => setAnchorEl(event.currentTarget)}
        >
          <Typography variant="description"> {value}</Typography>
        </Box>
      </foreignObject>
      {anchorEl && (
        <Popover
          open={!!anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          anchorEl={anchorEl}
          PaperProps={{
            sx: {
              backgroundColor: verisColors.neutrals["grey-2"],
              padding: (theme) => theme.spacing(0.5, 1),
              overflow: "visible",
            },
          }}
        >
          <Typography variant="body2"> {value}</Typography>
        </Popover>
      )}
    </>
  );
};

export const CustomMioTooltip = ({
  timezone,
  datetime,
  value,
  measurementUnit,
  isPreview,
  isMotionChart,
  sensorsGraphView,
}: {
  timezone: string;
  datetime: Dayjs;
  value: number;
  sensorsGraphView: SensorsGraphView;
  measurementUnit?: string;
  isPreview?: boolean;
  isMotionChart?: boolean;
}) => (
  <ChartTooltip>
    <>
      <Box display="flex">
        {!isPreview && (
          <Typography
            component="span"
            variant="caption"
            color={verisColors.neutrals["grey-mid"]}
            sx={{
              width: "65px",
              marginRight: (theme) => theme.spacing(1),
              textAlign: "left",
            }}
          >
            {isMotionChart ? "Steps" : "Current"}
          </Typography>
        )}

        <Typography
          component="span"
          variant="caption"
          sx={{
            marginRight: "auto",
          }}
        >
          {measurementUnit ? `${value?.toFixed(1)} ${measurementUnit}` : value?.toFixed(0)}
        </Typography>
      </Box>

      <Box display="flex">
        {!isPreview && (
          <Typography
            component="span"
            variant="caption"
            color={verisColors.neutrals["grey-mid"]}
            sx={{ width: "65px", marginRight: (theme) => theme.spacing(1), textAlign: "left" }}
          >
            Date
          </Typography>
        )}
        <Typography
          component="span"
          variant="caption"
          sx={{
            marginRight: "auto",
          }}
        >
          {datetime.format(isPreview ? "MMM DD" : "MMM DD, YY")}
        </Typography>
      </Box>
      {shouldShowTime(sensorsGraphView, isMotionChart) && (
        <Box display="flex">
          {!isPreview && (
            <Typography
              component="span"
              variant="caption"
              color={verisColors.neutrals["grey-mid"]}
              sx={{
                width: "65px",
                marginRight: (theme) => theme.spacing(1),
                textAlign: "left",
              }}
            >
              Time
            </Typography>
          )}
          <Typography
            component="span"
            variant="caption"
            sx={{
              marginRight: "auto",
            }}
          >
            {datetime.format(`${dateFormats["h:mm"]} a`)}
          </Typography>
        </Box>
      )}
      {timezone && (
        <>
          <Box display="flex">
            {!isPreview && (
              <Typography
                component="span"
                variant="caption"
                color={verisColors.neutrals["grey-mid"]}
                sx={{
                  width: "65px",
                  marginRight: (theme) => theme.spacing(1),
                  textAlign: "left",
                }}
              >
                Local Date
              </Typography>
            )}
            <Typography
              component="span"
              variant="caption"
              sx={{
                marginRight: "auto",
              }}
            >
              {extractTimezoneFromTheFormat(false, timezone, isPreview)}
            </Typography>
          </Box>
          {shouldShowTime(sensorsGraphView, isMotionChart) && (
            <Box display="flex">
              {!isPreview && (
                <Typography
                  component="span"
                  variant="caption"
                  color={verisColors.neutrals["grey-mid"]}
                  sx={{
                    width: "65px",
                    marginRight: (theme) => theme.spacing(1),
                    textAlign: "left",
                  }}
                >
                  Local Time
                </Typography>
              )}
              <Typography
                component="span"
                variant="caption"
                sx={{
                  marginRight: "auto",
                }}
              >
                {extractTimezoneFromTheFormat(true, timezone, isPreview)}
              </Typography>
            </Box>
          )}
        </>
      )}
    </>
  </ChartTooltip>
);
