import {
  AppointmentTypeEnum,
  AppointmentStatusEnum,
  GetAppointmentResponse as Appointment,
  AppointmentCreateRequest,
  AppointmentAttendeeStatusChangeEnum,
} from "@veris-health/med-data-ms/lib/v1";
import dayjs from "dayjs";
import { MbscCalendarEvent } from "@mobiscroll/react";
import { appointmentsApiV1 } from "../../../../api";

export const appointmentEnumToDisplay: Record<AppointmentTypeEnum, string> = {
  "video-appointment": "Video",
  "clinic-appointment": "Clinic",
};

export const fetchCalendarEvents = async (
  doctorId: number,
  startTime?: string,
  endTime?: string,
  statuses?: AppointmentStatusEnum[],
): Promise<MbscCalendarEvent[]> => {
  const response = await appointmentsApiV1.getAppointments(doctorId, startTime, endTime, statuses);
  return response.data?.appointments?.map((appointment) => ({
    id: appointment.id,
    start: dayjs.utc(appointment.startTime).local().format(),
    end: dayjs.utc(appointment.endTime).local().format(),
    date: dayjs.utc(appointment.startTime).local().format(),
    type: appointmentEnumToDisplay[appointment.type],
    outOfPlatform: !appointment.isVerisAppointment,
    title: appointment.title,
    attendees: appointment.attendees.map(({ name, userId, ...rest }) => ({
      ...rest,
      userId,
      name,
    })),
    status: appointment.status,
    confirmed: appointment.status === AppointmentStatusEnum.Booked,
    videoLink: appointment.videoLink,
    patient: appointment.attendees.find((attendee) => attendee.userType === "patient")?.name,
    patientId: appointment.attendees.find((attendee) => attendee.userType === "patient")?.userId,
    editable: appointment.editable,
  }));
};

export const getAppointmentDetails = async (
  userId: number,
  appointmentId: string,
): Promise<Appointment> => {
  const response = await appointmentsApiV1.getAppointment(userId, appointmentId);
  return response.data;
};

export const bookAppointment = async (
  userId: number,
  event: AppointmentCreateRequest,
): Promise<Appointment> => {
  const response = await appointmentsApiV1.bookAppointment(userId, event);
  return response.data;
};

export const respondToAppointment = async (
  userId: number,
  appointmentId: string,
  appointmentResponse: AppointmentAttendeeStatusChangeEnum,
): Promise<void> => {
  const response = await appointmentsApiV1.rsvpAppointment(
    userId,
    appointmentId,
    appointmentResponse,
  );
  return response.data;
};
