import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import { socketMiddleware } from "./features/shared/middleware/socketMiddleware";
import { crashReporterMiddleware } from "./features/shared/middleware/crashReporter";
import { snackbarMiddleware } from "./features/shared/middleware/snackbarMiddleware";
import { getReducers } from "./store-reducers";

const reducers = getReducers();

export const store = configureStore({
  reducer: {
    ...reducers,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat([
      socketMiddleware,
      snackbarMiddleware,
      crashReporterMiddleware,
    ]);
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
