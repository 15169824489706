import React from "react";
import { Box, TextField, Typography, styled } from "@mui/material";
import { IconName, VrsIcon, dateFormats, verisColors } from "@veris-health/web-core";
import dayjs from "dayjs";
import { Datepicker } from "@mobiscroll/react";

interface TCMCalendarProps {
  title: string;
  value?: string | dayjs.Dayjs;
  onChange: (date: string) => void;
  min?: string;
  max?: string;
}

const DatepickerInput = styled(TextField)(({ theme }) => ({
  "& .MuiInput-root": {
    textAlign: "center",
    gap: theme.spacing(1),
    ...theme.typography.body2,
    color: theme.veris.colors.amethyst.normal,

    "&::before": {
      border: "none",
    },
    "&::after": {
      border: "none",
    },
    "&:hover:before": {
      border: "none !important",
    },
  },
  "& .MuiInput-input": {
    paddingBottom: 0,
    cursor: "pointer",
  },
}));

export const TCMCalendar = ({
  title,
  value,
  onChange,
  min,
  max,
}: TCMCalendarProps): JSX.Element => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: (theme) => theme.spacing(1),
        marginY: (theme) => theme.spacing(4),
      }}
    >
      <Typography variant="subtitle2" color={verisColors.neutrals["grey-3"]}>
        {title}
        <span style={{ color: verisColors.errors.normal }}>*</span>
      </Typography>
      <VrsIcon name={IconName.Calendar} stroke={verisColors.neutrals["grey-3"]} />
      <Box>
        <Datepicker
          inputComponent={DatepickerInput}
          inputProps={{
            value: value && dayjs(value).format(dateFormats["ddd MMM D, YYYY"]),
            variant: "standard",
            placeholder: "DD/ MM / YYYY",
          }}
          onChange={(event) => {
            onChange(event.value);
          }}
          controls={["calendar"]}
          min={min}
          max={max}
        />
      </Box>
    </Box>
  );
};
