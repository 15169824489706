import { debounce } from "lodash";
import { useEffect, useCallback } from "react";
import { useProfile } from "../../context/profile";
import {
  startSessionEventActivity,
  endSessionEventActivity,
} from "../../features/shared/slices/activityTrackingSlice";
import { selectCallStatus } from "../../features/shared/slices/voipSlice";
import { useAppDispatch } from "../useAppDispatch";
import { useAppSelector } from "../useAppSelector";

export const debounceTime = 2000;

interface HiddenProps {
  hidden: string;
  visibilityChange: string;
}

export const getHiddenPropsNames = (
  document: Document & { msHidden?: boolean; webkitHidden?: boolean },
): HiddenProps | undefined => {
  if (typeof document.hidden !== "undefined") {
    return { hidden: "hidden", visibilityChange: "visibilitychange" };
  }
  if (typeof document.msHidden !== "undefined") {
    return { hidden: "msHidden", visibilityChange: "msvisibilitychange" };
  }
  if (typeof document.webkitHidden !== "undefined") {
    return { hidden: "webkitHidden", visibilityChange: "webkitvisibilitychange" };
  }
  return undefined;
};

const hiddenPropsNames = getHiddenPropsNames(document);

export function useBilling(patientId?: number, shouldBill = true): void {
  const currentUserData = useProfile();
  const dispatch = useAppDispatch();
  const callStatus = useAppSelector(selectCallStatus);

  const handleDispatchEnd = useCallback(
    debounce(() => {
      if (shouldBill) {
        dispatch(endSessionEventActivity());
      }
    }, debounceTime),
    [dispatch, shouldBill],
  );

  const handleDispatchStart = useCallback(
    debounce((patient: number, medicId: number, roles) => {
      dispatch(startSessionEventActivity({ patientId: patient, medicId, roles }));
    }, debounceTime),
    [dispatch],
  );

  const onVisibilityChange = () => {
    if (!callStatus.isOnCall && patientId && shouldBill) {
      if (document.visibilityState === hiddenPropsNames?.hidden) {
        handleDispatchEnd();
      } else if (currentUserData) {
        handleDispatchStart(patientId, currentUserData.id, currentUserData.roles);
      }
    }
  };

  useEffect(() => {
    if (patientId && hiddenPropsNames && shouldBill) {
      document.addEventListener(hiddenPropsNames.visibilityChange, onVisibilityChange);
    }

    return () => {
      if (patientId && hiddenPropsNames && shouldBill) {
        document.removeEventListener(hiddenPropsNames.visibilityChange, onVisibilityChange);
      }
    };
  }, [patientId, shouldBill]);

  useEffect(() => {
    if (currentUserData && patientId && !callStatus.isOnCall && shouldBill) {
      handleDispatchStart(patientId, currentUserData.id, currentUserData.roles);
    }
    return () => {
      if (patientId && !callStatus.isOnCall) {
        handleDispatchEnd();
      }
    };
  }, [patientId, callStatus.isOnCall, shouldBill]);
}
