import React, { useEffect } from "react";
import { Box, Theme, useMediaQuery } from "@mui/material";
import { useAppSelector } from "../../hooks/useAppSelector";
import { selectTimelineView, selectTimelineViewConfig } from "./treatmentPlanSlice";
import Timeline from "./Timeline";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { toggleLeftSideBar } from "../shared/slices/uiSlice";
import { useCurrentPatient } from "../../hooks/useCurrentPatient";

const TimelineContainer = (): JSX.Element => {
  const calendarView = useAppSelector(selectTimelineView);
  const viewConfig = useAppSelector(selectTimelineViewConfig);
  const currentPatient = useCurrentPatient();
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("xl"));
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isSmallScreen) dispatch(toggleLeftSideBar(false));
  }, [dispatch, isSmallScreen]);

  return (
    <Box
      sx={{
        display: "grid",
        placeItems: "stretch",
        maxWidth: "100%",
        border: (theme) => `1px solid ${theme.veris.colors.neutrals["grey-2"]}`,
        borderRadius: (theme) => +theme.shape.borderRadius / 2,
        marginTop: (theme) => theme.spacing(1),
        padding: (theme) => theme.spacing(1),
        backgroundColor: (theme) => theme.veris.colors.neutrals.white,
      }}
    >
      {currentPatient && (
        <Timeline view={calendarView} viewConfig={viewConfig} currentPatient={currentPatient} />
      )}
    </Box>
  );
};

export default TimelineContainer;
