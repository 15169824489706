import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  CommunicationIdentityTokenResponse,
  CommunicationUserView,
} from "@veris-health/communication-ms/lib/v1";
import { GetAppointmentResponse as Appointment } from "@veris-health/med-data-ms/lib/v1";
import { fetchVoipUserAccessToken } from "./api/videoApi";
import { fetchUserChatInfo, fetchProfileInfo } from "../shared/slices/api/chatApi";
import { getAppointmentDetails } from "../shared/slices/api/calendarApi";
import { RootState } from "../../store";
import { Status } from "../shared/interfaces";
import { setCallStatus } from "../shared/slices/voipSlice";
import { logout } from "../shared/slices/authSlice";

interface VoipState {
  chatUserAccessToken: string;
  chatUserId: string;
  chatDisplayName: string;
  status: Status;
  chatPatientInfo: {
    patientInfo?: CommunicationUserView;
    status: Status;
  };
  appointmentInfo: {
    appointment?: Appointment;
    status: Status;
  };
}

const initialState: VoipState = {
  chatUserAccessToken: "",
  chatUserId: "",
  chatDisplayName: "",
  status: "idle",
  chatPatientInfo: {
    status: "idle",
  },
  appointmentInfo: {
    status: "idle",
  },
};

export const fetchVideoConfig = createAsyncThunk<
  CommunicationIdentityTokenResponse & { chatDisplayName: string },
  { id: number }
>("communication/fetchVideoConfig", async ({ id }, { rejectWithValue, dispatch }) => {
  try {
    const { first_name: firstName, last_name: lastName } = await fetchProfileInfo(id);
    const chatDisplayName = `${firstName} ${lastName}`;
    const voipUserAccessToken = await fetchVoipUserAccessToken(id);
    return {
      ...voipUserAccessToken,
      chatDisplayName,
    };
  } catch (error) {
    dispatch(
      setCallStatus({
        isOnCall: false,
        isOnLobby: false,
        callEndReason: undefined,
        isWindowed: false,
        callUrl: undefined,
      }),
    );
    return rejectWithValue("Something went wrong");
  }
});

export const fetchChatPatientInfoConfig = createAsyncThunk<
  CommunicationUserView,
  { id: number },
  { rejectValue: string }
>("communication/fetchChatUserInfo", async ({ id }, { rejectWithValue, dispatch }) => {
  try {
    const chatUserInfo = await fetchUserChatInfo(id);
    return {
      ...chatUserInfo,
    };
  } catch (error) {
    dispatch(
      setCallStatus({
        isOnCall: false,
        isOnLobby: false,
        callEndReason: undefined,
        isWindowed: false,
        callUrl: undefined,
      }),
    );
    return rejectWithValue("Something went wrong");
  }
});

export const fetchAppointmentInfo = createAsyncThunk<
  Appointment,
  { patientId: number; appointmentId: string }
>(
  "communication/fetchAppointmentInfo",
  async ({ patientId, appointmentId }, { rejectWithValue, dispatch }) => {
    try {
      const appointmentInfo = await getAppointmentDetails(patientId, appointmentId);
      return {
        ...appointmentInfo,
      };
    } catch (error) {
      dispatch(
        setCallStatus({
          isOnCall: false,
          isOnLobby: false,
          callEndReason: undefined,
          isWindowed: false,
          callUrl: undefined,
        }),
      );
      return rejectWithValue(error);
    }
  },
);

export const voipSlice = createSlice({
  name: "communication",
  initialState,
  reducers: {
    resetToken: (state) => {
      state.chatUserAccessToken = "";
    },
    resetChatInfo: (state) => {
      state.chatPatientInfo.patientInfo = undefined;
      state.appointmentInfo.appointment = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchVideoConfig.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchVideoConfig.fulfilled, (state, { payload }) => {
        state.chatUserAccessToken = payload.token;
        state.chatUserId = payload.user_id;
        state.chatDisplayName = payload.chatDisplayName;
        state.status = "idle";
      })
      .addCase(fetchVideoConfig.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(fetchChatPatientInfoConfig.pending, (state) => {
        state.chatPatientInfo.status = "loading";
      })
      .addCase(fetchChatPatientInfoConfig.fulfilled, (state, action) => {
        state.chatPatientInfo.patientInfo = action.payload;
        state.chatPatientInfo.status = "idle";
      })
      .addCase(fetchChatPatientInfoConfig.rejected, (state) => {
        state.chatPatientInfo.status = "failed";
      })
      .addCase(fetchAppointmentInfo.pending, (state) => {
        state.appointmentInfo.status = "loading";
      })
      .addCase(fetchAppointmentInfo.fulfilled, (state, action) => {
        state.appointmentInfo.appointment = action.payload;
        state.appointmentInfo.status = "idle";
      })
      .addCase(fetchAppointmentInfo.rejected, (state) => {
        state.appointmentInfo.status = "failed";
      })
      .addCase(logout, () => {
        return initialState;
      });
  },
});

// Selectors

export const selectChatUserAccessToken = ({ voip }: RootState): string => voip.chatUserAccessToken;

export const selectChatUserAccessTokenStatus = ({ voip }: RootState): string => voip.status;

export const selectAppointmentInfo = ({ voip }: RootState): Appointment | undefined =>
  voip.appointmentInfo.appointment;

export const selectAppointmentInfoStatus = ({ voip }: RootState): string =>
  voip.appointmentInfo.status;

export const selectMyChatDisplayName = ({ voip }: RootState): string => voip.chatDisplayName;

export const selectPatientChatInfo = ({ voip }: RootState): CommunicationUserView | undefined =>
  voip.chatPatientInfo.patientInfo;

export const selectPatientChatInfoStatus = ({ voip }: RootState): string =>
  voip.chatPatientInfo.status;

// Actions

export const { resetToken, resetChatInfo } = voipSlice.actions;

export default voipSlice.reducer;
