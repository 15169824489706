import {
  EntityState,
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import {
  ClinicalTask,
  ClinicalTaskSortingEnum,
  CreateClinicalTaskAPI,
  ListTasksResponse,
  UpdateClinicalTaskAPI,
} from "@veris-health/med-data-ms/lib/v1";
import { RootState } from "../../store";
import { extractErrorMessage } from "../shared/helpers";
import { localizedLogout, logout } from "../shared/slices/authSlice";
import { createTask, editTask, fetchTasks } from "./api/tasksApi";
import { Status } from "../shared/interfaces";

interface TasksState {
  tasks: ListTasksResponse;
  patientTasks: { data: EntityState<ListTasksResponse>; status: Status };
  status: Status;
  order: ClinicalTaskSortingEnum;
}

const patientTasksAdapter = createEntityAdapter<ListTasksResponse>();

const initialState: TasksState = {
  tasks: {
    items: [],
    total: 0,
  },
  patientTasks: {
    data: patientTasksAdapter.getInitialState(),
    status: "idle",
  },
  status: "idle",
  order: ClinicalTaskSortingEnum.DueDateAsc,
};

interface fetchTasksResponse extends ListTasksResponse {
  id?: number;
}

export const fetchTasksAsync = createAsyncThunk<
  fetchTasksResponse,
  {
    assignee?: number;
    createdBy?: number;
    sorting?: ClinicalTaskSortingEnum[];
    patientId?: number;
    taskTeamMemberId?: number;
    offset?: number;
  },
  { rejectValue: string }
>(
  "tasksSlice/fetchTasks",
  async (
    { assignee, createdBy, sorting, patientId, taskTeamMemberId, offset },
    { rejectWithValue, getState },
  ) => {
    try {
      const response = await fetchTasks(
        sorting,
        assignee,
        patientId,
        taskTeamMemberId,
        createdBy,
        undefined,
        10,
        offset,
      );
      const rootState = getState() as RootState;
      if (offset && offset > 0) {
        if (patientId) {
          const oldTasks = patientTasksAdapter
            .getSelectors<RootState>((state) => state.tasks.patientTasks.data)
            .selectById(rootState, patientId)?.items;
          return {
            id: patientId,
            ...response,
            items: [...(oldTasks || []), ...(response?.items || [])],
          };
        }

        return {
          ...response,
          items: [...(rootState.tasks?.tasks?.items || []), ...(response?.items || [])],
        };
      }
      if (patientId) return { id: patientId, ...response };
      return response;
    } catch (err) {
      const errorMsg = extractErrorMessage(err);
      return rejectWithValue(errorMsg || "Could not fetch tasks.");
    }
  },
);

export const updateTaskAsync = createAsyncThunk<
  ClinicalTask,
  {
    patientId: number;
    taskId: number;
    data: UpdateClinicalTaskAPI;
  },
  { rejectValue: string }
>("tasksSlice/updateTasks", async ({ patientId, taskId, data }, { rejectWithValue }) => {
  try {
    const response = await editTask(patientId, taskId, data);
    return response;
  } catch (err) {
    const errorMsg = extractErrorMessage(err);
    return rejectWithValue(errorMsg || "Could not update task.");
  }
});

export const createTaskAsync = createAsyncThunk<
  ClinicalTask,
  {
    patientId: number;
    data: CreateClinicalTaskAPI;
  },
  { rejectValue: string }
>("tasksSlice/createTasks", async ({ patientId, data }, { rejectWithValue }) => {
  try {
    const response = await createTask(patientId, data);
    return response;
  } catch (err) {
    const errorMsg = extractErrorMessage(err);
    return rejectWithValue(errorMsg || "Could not create task.");
  }
});

const tasksSlice = createSlice({
  initialState,
  name: "tasks",
  reducers: {
    setSortOrder: (state, action: PayloadAction<ClinicalTaskSortingEnum>) => {
      state.order = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTasksAsync.pending, (state, action) => {
      if (action.meta.arg.patientId) state.patientTasks.status = "loading";
      else state.status = "loading";
    });
    builder.addCase(fetchTasksAsync.fulfilled, (state, action) => {
      if (action.meta.arg.patientId) {
        if (state.patientTasks.data.ids.includes(action.meta.arg.patientId))
          patientTasksAdapter.updateOne(state.patientTasks.data, {
            id: action.meta.arg.patientId,
            changes: {
              items: action.payload.items,
              offset: action.payload.offset,
              total: action.payload.total,
            },
          });
        patientTasksAdapter.addOne(state.patientTasks.data, action.payload);
        state.patientTasks.status = "idle";
      } else {
        state.tasks = action.payload;
        state.status = "idle";
      }
    });
    builder.addCase(fetchTasksAsync.rejected, (state, action) => {
      if (action.meta.arg.patientId) state.patientTasks.status = "failed";
      else state.status = "failed";
    });
    builder.addCase(updateTaskAsync.fulfilled, (state, { payload }) => {
      const { patient, id } = payload;
      if (state.tasks.items) {
        const taskToUpdate = state.tasks.items.findIndex((task) => task.id === id);
        state.tasks.items[taskToUpdate] = payload;
      }
      if (state.patientTasks.data.ids.includes(patient.user_id)) {
        const allItems = state.patientTasks.data.entities[patient.user_id]?.items;
        const indexToUpdate = allItems?.findIndex((task) => task.id === id);
        if (indexToUpdate !== -1 && indexToUpdate !== undefined && allItems) {
          allItems[indexToUpdate] = payload;
          patientTasksAdapter.updateOne(state.patientTasks.data, {
            id: patient.user_id,
            changes: {
              items: allItems,
            },
          });
        }
      }
    });
    builder.addCase(createTaskAsync.pending, (state, action) => {
      if (action.meta.arg.patientId) state.patientTasks.status = "loading";
      else state.status = "loading";
    });
    builder.addCase(createTaskAsync.fulfilled, (state, action) => {
      state.tasks.items = state.tasks.items
        ? [action.payload, ...state.tasks.items]
        : [action.payload];
      state.tasks.total += 1;
      if (state.patientTasks.data.ids.includes(action.payload.patient.user_id)) {
        const allItems =
          state.patientTasks.data.entities[action.payload.patient.user_id]?.items || [];

        patientTasksAdapter.updateOne(state.patientTasks.data, {
          id: action.payload.patient.user_id,
          changes: {
            items: [action.payload, ...allItems],
            total: allItems.length + 1,
          },
        });
      } else {
        patientTasksAdapter.addOne(state.patientTasks.data, {
          items: [action.payload],
          total: 1,
        });
      }
      if (action.meta.arg.patientId) state.patientTasks.status = "idle";
      else state.status = "idle";
    });
    builder.addCase(createTaskAsync.rejected, (state, action) => {
      if (action.meta.arg.patientId) state.patientTasks.status = "failed";
      else state.status = "failed";
    });

    builder.addCase(logout, () => {
      return initialState;
    });
    builder.addCase(localizedLogout, () => {
      return initialState;
    });
  },
});

export default tasksSlice.reducer;

export const { setSortOrder } = tasksSlice.actions;

export const selectTasksStatus = (state: RootState): Status => state.tasks.status;

export const selectPatientTasksStatus = (state: RootState): Status =>
  state.tasks.patientTasks.status;

export const selectTasksOrder = (state: RootState): ClinicalTaskSortingEnum => state.tasks.order;

export const selectPatientTasks = (
  rootState: RootState,
  id: number | undefined,
): ListTasksResponse | undefined => {
  if (id) {
    return patientTasksAdapter
      .getSelectors<RootState>((state) => state.tasks.patientTasks.data)
      .selectById(rootState, id);
  }
  return rootState.tasks.tasks;
};
