import React, { HTMLAttributes, useEffect } from "react";
import { Box, Breadcrumbs, styled, Typography } from "@mui/material";
import { MedStaffProfileItem } from "@veris-health/user-ms/lib/v1";
import { Link, useParams } from "react-router-dom";
import { VrsSelect, VrsAvatar } from "@veris-health/web-core";
import { useAppSelector } from "../../../hooks/useAppSelector";
import {
  selectMedicalTeamRolesOptions,
  setRoleFilter,
  selectFilteredTeamMembers,
  setMedicalStaffNameFilter,
  setHospitalFilter,
} from "../doctorsProfileSlice";
import { MedicalStaffTable } from "../components/MedicalStaffTable";
import { AutoCompleteOption, VrsSearchBar } from "../../../ui/components/VrsSearchBar";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { RouteParamsKeysValues, Routes } from "../../../routes-config";
import { getSelectOptions } from "../../shared/helpers";

const StyledBreadCrumbLink = styled(Link)(({ theme }) => ({
  ...theme.typography.h6Underline,
  color: theme.veris.colors.neutrals["grey-dark"],
}));

const renderOption = (
  props: HTMLAttributes<HTMLLIElement | HTMLDivElement>,
  option: AutoCompleteOption<MedStaffProfileItem>,
) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      sx={{
        borderBottom: (theme) => `1px solid ${theme.veris.colors.neutrals["grey-2"]}`,
        cursor: "pointer",
      }}
      onClick={props.onClick}
      {...props}
    >
      <VrsAvatar size={24} name={`${option.value.first_name} ${option.value.last_name}`} />
      <Typography
        variant="subtitle1"
        ml={1}
        mr={1}
        color={(theme) => theme.veris.colors.neutrals["grey-dark"]}
      >
        {`${option.value.first_name} ${option.value.last_name}`}
      </Typography>
      <Typography variant="caption" color={(theme) => theme.veris.colors.neutrals["grey-dark"]}>
        {option.value.profession}
      </Typography>
    </Box>
  );
};

export const MedicalStaffTableContainer = (): JSX.Element => {
  const medicalTeamRoles = useAppSelector(selectMedicalTeamRolesOptions);
  const medicalStaff = useAppSelector(selectFilteredTeamMembers);
  const { hospitalId } = useParams<Pick<RouteParamsKeysValues, "hospitalId">>();
  const dispatch = useAppDispatch();

  const roleOptions = React.useMemo(
    () => getSelectOptions(medicalTeamRoles as string[], "All Professions"),
    [medicalTeamRoles],
  );

  const handleFilterByRole = (role: string) => {
    dispatch(setRoleFilter(role));
  };

  useEffect(() => {
    dispatch(setHospitalFilter(+hospitalId));
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(setMedicalStaffNameFilter());
      dispatch(setRoleFilter(""));
    };
  }, [dispatch]);

  const handleSearchChange = (
    event: React.SyntheticEvent,
    searchValue?: AutoCompleteOption<MedStaffProfileItem>,
  ) => {
    dispatch(setMedicalStaffNameFilter(searchValue?.value));
  };

  return (
    <Box
      sx={{
        padding: (theme) => theme.spacing(2),
        margin: (theme) => theme.spacing(2, 0),
        border: (theme) => `1px solid ${theme.veris.colors.neutrals["grey-2"]}`,
        borderRadius: (theme) => +theme.shape.borderRadius * 0.375,
        background: (theme) => theme.veris.colors.neutrals.white,
      }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center" pb={2}>
        <Box display="flex">
          <Breadcrumbs>
            <StyledBreadCrumbLink to={Routes.DOCTOR_PROFILE}>Hospitals</StyledBreadCrumbLink>
            <Typography
              variant="h6Semibold"
              sx={{ color: (theme) => theme.veris.colors.amethyst.normal }}
            >
              Medical Team
            </Typography>
          </Breadcrumbs>
        </Box>
        <Box width={350}>
          <VrsSearchBar<MedStaffProfileItem>
            id="search-box-medical-team-member"
            options={
              medicalStaff?.map((teamMember) => {
                return {
                  value: teamMember,
                  label: `${teamMember.first_name} ${teamMember.last_name}`,
                };
              }) as AutoCompleteOption<MedStaffProfileItem>[]
            }
            placeholder="Search by name"
            onChange={handleSearchChange}
            renderOption={renderOption}
          />
        </Box>
        <Box>
          <VrsSelect options={roleOptions} onSelected={handleFilterByRole} />
        </Box>
      </Box>
      {medicalStaff && <MedicalStaffTable medicalStaff={medicalStaff} />}
    </Box>
  );
};
