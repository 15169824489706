import { Box, Link, Typography } from "@mui/material";
import React from "react";
import {
  OutOfRangePatientsNotificationViewData,
  OutOfRangePatientTagItem,
} from "@veris-health/communication-ms/lib/v1/api";
import { VrsSystemCard } from "../VrsSystemCard";
import { replaceRouteParam } from "../../../../routes-config";
import { useBilling } from "../../../../hooks/useBilling";

export interface OutOfRangeNotificationProps {
  title: string;
  text: string;
  data?: OutOfRangePatientsNotificationViewData;
}

export function OutOfRangeNotification({
  title,
  text,
  data,
}: OutOfRangeNotificationProps): JSX.Element {
  useBilling(data?.id);
  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Box
        mb="4rem"
        py="0.5rem"
        borderBottom={(theme) => `1px solid ${theme.veris.colors.neutrals["grey-light"]}`}
      >
        <VrsSystemCard />
      </Box>
      <Box paddingLeft="0.5rem">
        <Typography mb="1rem" component="h3" variant="h6Semibold">
          {title}
        </Typography>
        <Typography component="p" variant="subtitle2">
          {text}
        </Typography>
      </Box>
      <Box paddingLeft="0.5rem">
        {data?.id ? (
          <>
            {data?.patient_tags.map((patientTag) => (
              <OutOfRangeNotificationDetails patientTag={patientTag} />
            ))}
          </>
        ) : (
          <Typography mb={2.3} variant="h6" fontWeight={600} pt={2}>
            This Patient was Deleted
          </Typography>
        )}
      </Box>
    </Box>
  );
}

interface OutOfRangeNotificationDetailsProps {
  patientTag: OutOfRangePatientTagItem;
}

function OutOfRangeNotificationDetails({
  patientTag,
}: OutOfRangeNotificationDetailsProps): JSX.Element {
  return (
    <Box sx={{ display: "flex", alignItems: "center", marginTop: "2rem" }}>
      <Typography component="p" variant="subtitle2" mr="1rem" width="9rem">
        <Link
          href={replaceRouteParam("PATIENT_DETAILS", ":userId", String(patientTag.patient_id))}
          variant="body"
          target="_blank"
        >
          {patientTag.patient_name}:
        </Link>
      </Typography>
      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        {patientTag.tags.map((tag) => (
          <Typography
            sx={{
              borderRadius: (theme) => +theme.shape.borderRadius * 0.375,
              color: (theme) => theme.veris.colors.neutrals["grey-3"],
              border: (theme) => `1px solid ${theme.veris.colors.neutrals["grey-3"]}`,
              background: (theme) => `${theme.veris.colors.neutrals.white}`,
              padding: (theme) => theme.spacing(0.75, 0.75, 0.5, 0.75),
              display: "inline-flex",
              maxWidth: "unset",
              marginRight: "0.5rem",
              marginBottom: "0.5rem",
            }}
            component="div"
            variant="bodyMedium"
            data-test-hook="single-tag"
          >
            {tag}
          </Typography>
        ))}
      </Box>
    </Box>
  );
}
