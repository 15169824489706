import { AvatarGroup, styled, AvatarGroupProps } from "@mui/material";
import React from "react";
import { VrsAvatar } from "@veris-health/web-core";

export interface StackedAvatarProps {
  name: string;
  image?: string;
  id: string | number;
  profession?: string;
}

const StyledAvatarGroup = styled(AvatarGroup, {
  shouldForwardProp: (prop) => prop !== "size" && prop !== "stackStep",
})<AvatarGroupProps & { size: number; stackStep: number }>(({ size, theme, stackStep }) => ({
  "& .MuiAvatarGroup-avatar": {
    width: size,
    height: size,
    fontSize: "1rem",
    backgroundColor: theme.veris.colors.neutrals["grey-3"],
    textAlign: "right",
    fontWeight: 400,
  },
  "& .MuiAvatarGroup-avatar:first-of-type": {
    color: theme.veris.colors.neutrals["grey-2"],
  },
  "& .MuiAvatar-root": {
    marginLeft: `${stackStep}px`,
  },
  alignItems: "center",
}));

export interface VrsStackedAvatarsProps {
  avatars: StackedAvatarProps[];
  size: number;
  maxAvatars: number;
  stackStep?: number;
}

export function VrsStackedAvatars({
  avatars,
  size,
  maxAvatars,
  stackStep = -8,
}: VrsStackedAvatarsProps): JSX.Element {
  return (
    <StyledAvatarGroup max={maxAvatars} size={size} stackStep={stackStep}>
      {avatars.map((avatar: StackedAvatarProps) => (
        <VrsAvatar key={avatar.id} size={size} name={avatar.name} />
      ))}
    </StyledAvatarGroup>
  );
}
