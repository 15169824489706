import { Typography, Box } from "@mui/material";
import React from "react";

interface EventHeaderProps {
  isPassedDate: boolean;
  confirmed: boolean;
}

export const EventHeader = ({ isPassedDate, confirmed }: EventHeaderProps): JSX.Element => {
  if (isPassedDate)
    return (
      <Typography variant="h3" pt={1} color={(theme) => theme.veris.colors.neutrals["grey-5"]}>
        Previous Event
      </Typography>
    );
  if (!confirmed)
    return (
      <Typography variant="h3" pt={1} color={(theme) => theme.veris.colors.neutrals["grey-5"]}>
        Pending Event
      </Typography>
    );
  return (
    <Box display="flex" gap={1} alignItems="center">
      <Typography variant="h3" pt={1} color={(theme) => theme.veris.colors.neutrals["grey-5"]}>
        Upcoming Event
      </Typography>
    </Box>
  );
};
