import { EditorState, DraftEditorCommand, RichUtils } from "draft-js";
import { EditorPlugin } from "@draft-js-plugins/editor";

export interface PluginStore {
  getEditorState?: () => EditorState;
  setEditorState?: (state: EditorState) => void;
}

const handleShortcutCommand = (store: PluginStore, command: DraftEditorCommand | string) => {
  if (["bold", "italic", "underline", "strikethrough"].includes(command)) {
    if (store.getEditorState && store.setEditorState) {
      const newState = RichUtils.toggleInlineStyle(store.getEditorState(), command.toUpperCase());
      if (newState) {
        store.setEditorState(newState);
        return true;
      }
    }
  }
  return false;
};

export function createShortcutsPlugin(): EditorPlugin {
  const store: PluginStore = {
    getEditorState: undefined,
    setEditorState: undefined,
  };
  return {
    initialize: ({ getEditorState, setEditorState }: PluginStore) => {
      store.getEditorState = getEditorState;
      store.setEditorState = setEditorState;
    },

    handleKeyCommand: (command: string) => {
      const handled = handleShortcutCommand(store, command);
      return handled ? "handled" : "not-handled";
    },
  };
}
