import * as React from "react";
import { styled, Theme, CSSObject } from "@mui/material/styles";
import MuiDrawer, { DrawerProps } from "@mui/material/Drawer";
import { drawerClasses, useMediaQuery } from "@mui/material";

export interface VrsDrawerProps {
  onClose: () => void;
  open: boolean;
  children: React.ReactNode;
  anchor?: DrawerProps["anchor"];
  variant?: DrawerProps["variant"];
  width?: number;
}

const sidebarDrawerWidth = 500;

export const openedMixin = (theme: Theme, width?: number): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: width ?? sidebarDrawerWidth,
  padding: theme.spacing(3.75, 2.25),
  overflow: "hidden",
  ":hover": {
    overflowY: "auto",
  },
  [theme.breakpoints.down("lg")]: {
    position: "fixed",
    zIndex: 1203,
    width: width ?? sidebarDrawerWidth,
  },
});

export const openedPreviewMixin = (theme: Theme, width?: number): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: width ?? sidebarDrawerWidth,
  position: "fixed",
  zIndex: 1203,
  padding: theme.spacing(3.75, 2.25),
  overflow: "hidden",
  ":hover": {
    overflowY: "auto",
  },
});

export const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: 0,
  overflow: "hidden",
});

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop: string) => prop !== "isSmallScreen" && prop !== "width",
})<VrsDrawerProps & { isSmallScreen: boolean }>(({ theme, open, isSmallScreen, width }) => ({
  width: sidebarDrawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme, width),
    [`& .${drawerClasses.paper}`]: openedMixin(theme, width),
  }),
  ...(!open && {
    ...closedMixin(theme),
    [`& .${drawerClasses.paper}`]: closedMixin(theme),
  }),
  ...(isSmallScreen && {
    ...openedPreviewMixin(theme, width),
    [`& .${drawerClasses.paper}`]: openedPreviewMixin(theme, width),
  }),
}));

export function VrsDrawer({
  onClose,
  open,
  children,
  anchor,
  variant,
  width,
}: VrsDrawerProps): JSX.Element {
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("xl"));
  return (
    <Drawer
      anchor={anchor || "right"}
      open={open}
      variant={variant || "persistent"}
      isSmallScreen={isSmallScreen}
      onClose={onClose}
      width={width}
    >
      {children}
    </Drawer>
  );
}
