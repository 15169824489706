import { Box, Typography } from "@mui/material";
import { DiagnosticReportObservation } from "@veris-health/med-data-ms/lib/v1";
import React from "react";
import { colorValueByReference } from "../Tables/VrsLabResultsTable";

export function VrsLabResult({
  name,
  value,
  unit,
  referenceRange,
}: DiagnosticReportObservation): JSX.Element {
  return (
    <Box
      display="flex"
      flexDirection="row"
      sx={{
        justifyContent: "space-between",
        margin: (theme) => theme.spacing(1.25, 0),
      }}
      key={name}
      gap={1}
    >
      <Box sx={{ display: "flex" }}>
        {name && (
          <Typography
            variant="body1"
            color={(theme) => theme.veris.colors.neutrals["grey-4"]}
            sx={{ fontWeight: "400" }}
            data-test-hook="lab-result-name"
          >
            {name.split("[")[0]}
          </Typography>
        )}
      </Box>
      <Typography
        variant="body1"
        color={colorValueByReference(value, referenceRange?.low, referenceRange?.high)}
        sx={{ fontWeight: "400" }}
        data-test-hook="lab-result-value"
      >
        {value} {unit}
      </Typography>
    </Box>
  );
}
