import Box from "@mui/material/Box";
import { VrsButton, VrsSelect } from "@veris-health/web-core";
import React from "react";
import { filterOptions, filterOptionsEnum, sortOptions } from "../utils/constants";

export interface TasksFiltersProps {
  inPatientDetails?: boolean;
  filter: filterOptionsEnum;
  onFilterChange: (filter: filterOptionsEnum) => void;
  order: string;
  onSort: (order: string) => void;
}

export function TasksFilters({
  inPatientDetails,
  filter,
  onFilterChange,
  onSort,
  order,
}: TasksFiltersProps): JSX.Element {
  return (
    <Box display="flex" justifyContent="space-between">
      <Box display="flex" gap={2}>
        {inPatientDetails ? (
          <VrsSelect
            options={filterOptions}
            onSelected={(selected) => onFilterChange(selected as filterOptionsEnum)}
            value={filter}
          />
        ) : (
          <>
            {filterOptions.map((filterOption) => (
              <VrsButton
                key={filterOption.value}
                buttonType={filter === filterOption.value ? "primary" : "quaternary"}
                onClick={() => onFilterChange(filterOption.value as filterOptionsEnum)}
                sx={{
                  borderRadius: "0.5rem",
                }}
              >
                {filterOption.label}
              </VrsButton>
            ))}
          </>
        )}

        <VrsSelect options={sortOptions} onSelected={onSort} value={order} />
      </Box>
    </Box>
  );
}
