import { useEffect, useMemo, useState } from "react";
import { NotificationView } from "@veris-health/communication-ms/lib/v1";
import { NotificationTaskData } from "../../Tasks/components/TaskContainer";
import { getTaskDetailsFromNotification } from "../utils";
import { useCurrentPatient } from "../../../hooks/useCurrentPatient";

export function useTaskNotification(): {
  notificationTask?: NotificationTaskData;
  setNotification: React.Dispatch<React.SetStateAction<NotificationView | undefined>>;
} {
  const [notification, setNotification] = useState<NotificationView | undefined>();
  const [notificationTask, setNotificationTask] = useState<NotificationTaskData | undefined>();

  const notificationTaskDetails = useMemo(
    () => getTaskDetailsFromNotification(notification),
    [notification],
  );
  const patient = useCurrentPatient(notificationTaskDetails?.patient?.id);

  useEffect(() => {
    if (notificationTaskDetails && notificationTaskDetails.patient) {
      const task = {
        ...notificationTaskDetails,
        patient: {
          ...notificationTaskDetails.patient,
          dataSources: patient?.dataSources,
        },
      };
      setNotificationTask(task);
    } else {
      setNotificationTask(undefined);
    }
  }, [notificationTaskDetails, patient]);

  return { notificationTask, setNotification };
}
