import { IconName, dateFormats } from "@veris-health/web-core";
import React, { useEffect, useState } from "react";
import { Box, Table, TableBody, TableHead, TableRow, Typography } from "@mui/material";
import { uniq } from "lodash";
import { QuestionsAnswersModel } from "@veris-health/virtual-doc-ms/lib/v1";
import dayjs from "dayjs";
import { ExportSection, ExportSectionProps } from "../ExportSection";
import { StyledTableCell } from "../../../../../ui/components/Tables/shared/StyledTableElements";
import { useAppSelector } from "../../../../../hooks/useAppSelector";
import { selectUserSymptoms } from "../../../../PatientDetailsMeasurements/measurementSlice";
import { VrsPatientInfo } from "../../../../shared/interfaces";
import { loadReportAnswersAsync } from "../../../../PatientDetailsMeasurements/api/patientDetailsMeasurementsApi";
import { formatReportAnswer } from "../../../../Notifications/utils";

interface SymptomDetailsProps extends ExportSectionProps {
  currentPatient: VrsPatientInfo;
  setLoadingSymptomDetails: (loading: boolean) => void;
}

const tableColumns = [
  { value: "Date" },
  {
    value: "Symptom",
  },
  { value: "Question" },
  { value: "Answer" },
];

const TableHeader = ({
  columns,
}: {
  columns: {
    value: string;
  }[];
}): JSX.Element => (
  <TableRow
    sx={{
      backgroundColor: (theme) => theme.veris.colors.neutrals["grey-1"],
      borderRadius: (theme) => theme.spacing(1),
    }}
  >
    {columns.map((column) => (
      <StyledTableCell>
        <Box display="flex" flexDirection="column">
          <Typography variant="subtitle2" color={(theme) => theme.veris.colors.neutrals["grey-3"]}>
            {column.value}
          </Typography>
        </Box>
      </StyledTableCell>
    ))}
  </TableRow>
);

const TableCell = ({
  columns,
  report,
  isLast,
  date,
}: {
  columns: {
    value: string;
  }[];
  report: QuestionsAnswersModel;
  isLast: boolean;
  date?: string;
}): JSX.Element => {
  return (
    <>
      {report.report.map((el, index) => {
        return (
          <TableRow
            sx={{
              borderBottom: (theme) =>
                index === report.report.length - 1 && !isLast
                  ? `2px solid ${theme.veris.colors.neutrals["grey-2"]}`
                  : "none",
              outline: "5px solid transparent",
            }}
          >
            {columns.map((column) => {
              return (
                <>
                  {column.value === "Date" && (
                    <StyledTableCell key={column.value}>
                      <Typography
                        variant="body"
                        color={(theme) => theme.veris.colors.neutrals["grey-3"]}
                      >
                        {index === 0 && date && dayjs(date).format(dateFormats["MM/DD/YYYY"])}
                      </Typography>
                    </StyledTableCell>
                  )}
                  {column.value === "Symptom" && (
                    <StyledTableCell key={column.value}>
                      <Typography
                        variant="subtitle1"
                        color={(theme) => theme.veris.colors.neutrals["grey-4"]}
                        sx={{
                          "::first-letter": {
                            textTransform: "capitalize",
                          },
                        }}
                      >
                        {index === 0 && report.symptom.label?.replaceAll("-", " ")}
                      </Typography>
                    </StyledTableCell>
                  )}
                  {column.value === "Question" && (
                    <StyledTableCell key={column.value}>
                      <Typography
                        variant="body"
                        color={(theme) => theme.veris.colors.neutrals["grey-5"]}
                      >
                        {el.question}
                      </Typography>
                    </StyledTableCell>
                  )}
                  {column.value === "Answer" && (
                    <StyledTableCell key={column.value}>
                      <Typography
                        variant="body"
                        color={(theme) => theme.veris.colors.neutrals["grey-3"]}
                      >
                        {el.answer ? formatReportAnswer(el.answer, el.questionType) : "No answer"}
                      </Typography>
                    </StyledTableCell>
                  )}
                </>
              );
            })}
          </TableRow>
        );
      })}
    </>
  );
};

export function SymptomDetails({
  title,
  include,
  onInclude,
  currentPatient,
  setLoadingSymptomDetails,
}: SymptomDetailsProps): JSX.Element {
  const symptoms = useAppSelector((state) => selectUserSymptoms(state, +currentPatient.id));
  const [reports, setReports] = useState<QuestionsAnswersModel[]>([]);

  const getAllReportIds = uniq(
    symptoms?.active_symptoms.map((el) => el.events?.findLast((event) => event.report_id)),
  ).filter((value) => value);

  useEffect(() => {
    setLoadingSymptomDetails(true);
    Promise.all(
      getAllReportIds.map((report) =>
        loadReportAnswersAsync({
          reporterId: String(currentPatient.id),
          reportId: String(report?.report_id),
        }),
      ),
    )
      .then((res) => {
        setReports(res);
      })
      .finally(() => {
        setLoadingSymptomDetails(false);
      });
  }, [symptoms]);

  return (
    <ExportSection
      title={title}
      iconName={IconName.Stethoscope}
      include={include}
      onInclude={onInclude}
    >
      <Box display="flex" gap={3} pt={1} pb={2}>
        <Table>
          <TableHead
            sx={{
              "&::after": {
                content: `"@"`,
                display: "block",
                visibility: "hidden",
                lineHeight: (theme) => theme.spacing(1),
              },
            }}
            className="break-before"
          >
            <TableHeader columns={tableColumns} />
          </TableHead>
          <TableBody
            sx={{
              borderRadius: (theme) => +theme.shape.borderRadius,
              border: (theme) => `1px solid ${theme.veris.colors.neutrals["grey-2"]}`,
            }}
          >
            {reports?.map((el, index) => (
              <TableCell
                columns={tableColumns}
                report={el}
                isLast={index === reports.length - 1}
                date={getAllReportIds[index]?.timestamp}
              />
            ))}
          </TableBody>
        </Table>
      </Box>
    </ExportSection>
  );
}
