import { styled, Table, TableContainer, tableContainerClasses, Typography } from "@mui/material";
import { GoalStatusResponse as GoalStatus } from "@veris-health/virtual-doc-ms/lib/v1";
import dayjs from "dayjs";
import { capitalize } from "lodash";
import React from "react";
import { verisColors, dateFormats } from "@veris-health/web-core";
import {
  StyledTableBody,
  StyledTableCell,
  StyledTableRow,
} from "../../../../ui/components/Tables/shared/StyledTableElements";
import {
  TableColumnNames,
  Column,
  VrsSortedTableHeader,
} from "../../../../ui/components/VrsSortedTableHeader";
import { genericSorter } from "../../../../utils/sorting";
import { GoalsType } from "../../patientDetailsSlice";

export interface TreatmentGoalsTableProps {
  goals: GoalsType[];
}

const statusColor = {
  [GoalStatus.Completed]: {
    background: verisColors.moderate.light,
    border: verisColors.moderate.soft,
  },
  [GoalStatus.InProgress]: {
    background: verisColors.amethyst.light,
    border: verisColors.amethyst.soft,
  },
};

const allColumns: Column[] = [
  { id: TableColumnNames.GoalName, label: TableColumnNames.GoalName, orderBy: "name" },
  {
    id: TableColumnNames.GoalCategory,
    label: TableColumnNames.GoalCategory,
    orderBy: "category.name",
  },
  {
    id: TableColumnNames.GoalDateCreated,
    label: TableColumnNames.GoalDateCreated,
    orderBy: "created_at",
  },
  {
    id: TableColumnNames.GoalDueDate,
    label: TableColumnNames.GoalDueDate,
    orderBy: "due_date",
  },
  {
    id: TableColumnNames.GoalStatus,
    label: TableColumnNames.GoalStatus,
    orderBy: "status",
  },
  {
    id: TableColumnNames.GoalType,
    label: TableColumnNames.GoalType,
    orderBy: "goal_type",
  },
];

const isOther = (goal: GoalsType) => !goal.category?.group?.includes("Therapy");

const StyledTableColumn = ({ column, goal }: { column: Column; goal: GoalsType }) => (
  <StyledTableCell key={column.id}>
    {column.id === TableColumnNames.GoalName && (
      <Typography variant="subtitle1" color={verisColors.neutrals["grey-4"]} py={2}>
        {goal.name || "N/A"}
      </Typography>
    )}
    {column.id === TableColumnNames.GoalCategory && (
      <Typography variant="subtitle2" color={verisColors.neutrals["grey-4"]}>
        {goal.category?.name || "N/A"}
      </Typography>
    )}
    {column.id === TableColumnNames.GoalDateCreated && (
      <Typography variant="subtitle2" color={verisColors.neutrals["grey-4"]}>
        {dayjs(goal.created_at).format(dateFormats["MMM DD, YYYY"]) || "N/A"}
      </Typography>
    )}
    {isOther(goal) && column.id === TableColumnNames.GoalDueDate && (
      <Typography variant="subtitle2" color={verisColors.neutrals["grey-4"]}>
        {goal.due_date ? dayjs(goal.due_date).format(dateFormats["MMM DD, YYYY"]) : "N/A"}
      </Typography>
    )}
    {column.id === TableColumnNames.GoalStatus && (
      <Typography
        variant="button"
        component="div"
        sx={{
          textTransform: "unset",
          backgroundColor: statusColor[goal.status || GoalStatus.InProgress].background,
          padding: (theme) => theme.spacing(0.75, 1, 0.5, 1),
          width: "90px",
          display: "flex",
          justifyContent: "center",
          color: verisColors.neutrals["grey-4"],
          border: `1px solid ${statusColor[goal.status || GoalStatus.InProgress].border}`,
          borderRadius: (theme) => +theme.shape.borderRadius / 2,
        }}
      >
        {capitalize(goal.status?.split("_").join(" ")) || "N/A"}
      </Typography>
    )}
    {column.id === TableColumnNames.GoalType && (
      <Typography
        variant="subtitle2"
        color={verisColors.neutrals["grey-4"]}
        sx={{ textTransform: "capitalize" }}
      >
        {goal.goal_type}
      </Typography>
    )}
  </StyledTableCell>
);

const StyledTableContainer = styled(TableContainer)(() => ({
  [`&.${tableContainerClasses.root}`]: {
    maxHeight: "30vh",
    overflowY: "scroll",
  },
}));

export const TreatmentGoalsTable = ({ goals }: TreatmentGoalsTableProps): JSX.Element => {
  const [sortedGoals, setSortedGoals] = React.useState(goals);

  const columns = isOther(goals[0])
    ? allColumns
    : allColumns.filter((item) => item.id !== TableColumnNames.GoalDueDate);

  React.useEffect(() => {
    setSortedGoals(goals);
  }, [goals]);

  return (
    <StyledTableContainer>
      <Table>
        <VrsSortedTableHeader
          styles={{ top: 0 }}
          columns={columns}
          sortTableData={(order, orderBy) =>
            setSortedGoals(genericSorter(goals, order, orderBy as keyof GoalsType))
          }
        />
        <StyledTableBody>
          {sortedGoals.map((goal) => (
            <StyledTableRow key={goal.uuid}>
              {columns.map((column) => (
                <StyledTableColumn column={column} goal={goal} key={column.id} />
              ))}
            </StyledTableRow>
          ))}
        </StyledTableBody>
      </Table>
    </StyledTableContainer>
  );
};
