import { Middleware } from "@reduxjs/toolkit";
import SnackbarUtils from "../../../utils/SnackbarUtils";
import { updateAppointmentAsync } from "../../Calendar/calendarSlice";
import {
  createChatThreadAsync,
  getPrivateChatAsync,
  joinChatThreadAsync,
} from "../../Communication/store";
import {
  setupFirebaseToken,
  fetchNotificationDetailsAsync,
  markNotificationAsReadAsync,
} from "../../Notifications/notificationsSlice";
import {
  bookAppointmentAsync,
  changeAppointmentStatusAsync,
  fetchCalendarEventsAsync,
} from "../slices/asyncThunks";

export const snackbarMiddleware: Middleware = () => {
  return (next) => (action) => {
    if (setupFirebaseToken.rejected.match(action)) {
      SnackbarUtils.error(
        "Could not connect to notifications service. Real time notifications are disabled.",
      );
    }
    if (fetchNotificationDetailsAsync.rejected.match(action)) {
      SnackbarUtils.error("Failed to load the notification. Please reload the page.");
    }
    if (markNotificationAsReadAsync.rejected.match(action)) {
      SnackbarUtils.error("Could not mark notification as read.");
    }
    if (fetchCalendarEventsAsync.rejected.match(action)) {
      SnackbarUtils.error((action.payload as string) || "Could not load appointments.");
    }
    if (bookAppointmentAsync.rejected.match(action)) {
      SnackbarUtils.error((action.payload as string) || "Could not book appointment.");
    }
    if (changeAppointmentStatusAsync.rejected.match(action)) {
      SnackbarUtils.error((action.payload as string) || "Could not change appointment.");
    }
    if (updateAppointmentAsync.rejected.match(action)) {
      SnackbarUtils.error((action.payload as string) || "Could not update appointment.");
    }
    if (
      createChatThreadAsync.rejected.match(action) ||
      joinChatThreadAsync.rejected.match(action) ||
      getPrivateChatAsync.rejected.match(action)
    ) {
      SnackbarUtils.error("Unable to create a new chat thread. Please try again.");
    }
    next(action);
  };
};
