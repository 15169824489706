import { MbscCalendarEventData } from "@mobiscroll/react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { Theme } from "@mui/material/styles/createTheme";
import { AppointmentAttendeeResponse } from "@veris-health/med-data-ms/lib/v1";
import dayjs from "dayjs";
import React from "react";
import { VrsIcon, IconName, VrsTooltip, verisColors } from "@veris-health/web-core";
import { TimelineTypes } from "../../TreatmentPlan/treatmentPlanSlice";
import { AgendaTypes } from "../calendarSlice";
import { eventBorderImage } from "./RenderEvent";
import { AppointmentType } from "./RenderLabel";
import { AppointmentStatus } from "./AppointmentStatus";
import { getShortName } from "../../../utils/helpers";
import { MIN_BILLABLE_APPOINTMENT_DURATION } from "../../shared/helpers";

export const eventToColorMap: Record<AppointmentType, string> = {
  Video: verisColors.neutrals.white,
  Clinic: verisColors.moderate.light,
};
export const passedEventToColorMap: Record<AppointmentType, string> = {
  Video: verisColors.neutrals["grey-0"],
  Clinic: verisColors.neutrals["grey-1"],
};
export const eventToBorderColorMap: Record<AppointmentType | "OutOfPlatform", string> = {
  Video: verisColors.moderate.soft,
  Clinic: verisColors.moderate.soft,
  OutOfPlatform: verisColors.neutrals["grey-3"],
};
const getTextColor = (isPassedDate: boolean, confirmed: boolean) => {
  if (isPassedDate) return verisColors.neutrals["grey-3"];
  if (!confirmed) return verisColors.amethyst.normal;
  return verisColors.neutrals["grey-4"];
};
export const getEventBackgroundColor = (
  type: string,
  isPassDate: boolean,
  outOfPlatform: boolean,
  confirmed: boolean,
): string => {
  if (isPassDate) return passedEventToColorMap[type as AppointmentType];
  if (!confirmed) return verisColors.neutrals["grey-1"];
  if (outOfPlatform) return verisColors.neutrals["grey-2"];
  return eventToColorMap[type as AppointmentType];
};

export const getEventBorderColor = (
  isPassDate: boolean,
  outOfPlatform: boolean,
  confirmed: boolean,
  monthlyView?: boolean,
): string => {
  if (isPassDate)
    return `1px solid ${
      monthlyView ? verisColors.neutrals["grey-3"] : verisColors.neutrals["grey-2"]
    }`;
  if (outOfPlatform) return `1px solid ${verisColors.neutrals["grey-3"]}`;
  if (!confirmed) return "none";
  return `1px solid ${verisColors.moderate.normal}`;
};

export interface RenderScheduleEventProps {
  data: MbscCalendarEventData;
  view: AgendaTypes | TimelineTypes;
  isCalendar?: boolean;
}

export const RenderScheduleEvent = ({
  data,
  view,
  isCalendar,
}: RenderScheduleEventProps): JSX.Element => {
  const isPassDate = dayjs(data?.original?.end as string | Date).isBefore(dayjs());
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("xl"));
  const appointmentDuration = dayjs(data.original?.end as string | Date).diff(
    data.original?.start as string | Date,
    "minute",
  );
  const eventParticipant = data?.original?.attendees?.find(
    (attendee: AppointmentAttendeeResponse) => attendee.userType !== "patient",
  )?.name;
  const appointmentType = data?.original?.type;
  const outOfPlatform = data?.original?.outOfPlatform;

  return (
    <VrsTooltip
      title={`${appointmentType} Appointment ${data?.start} - ${data?.end}`}
      bcgcolor={verisColors.neutrals.black}
    >
      <Box
        style={{
          backgroundColor: getEventBackgroundColor(
            appointmentType,
            isPassDate,
            outOfPlatform,
            data.original?.confirmed,
          ),
          height: "99%",
          width: "99%",
          maxHeight: "100%",
          border: getEventBorderColor(isPassDate, outOfPlatform, data.original?.confirmed),
          backgroundImage: !data.original?.confirmed && !isPassDate ? eventBorderImage : "none",
          boxSizing: "border-box",
          borderRadius: "6px",
          wordBreak: "break-word",
          overflow: "hidden",
          strokeDasharray: 19,
        }}
        mt={0.2}
      >
        {isCalendar ? (
          <Box
            p={appointmentDuration > 30 ? 0.5 : 0.125}
            pl={appointmentDuration > 30 ? 1 : 0.25}
            display="flex"
            flexDirection={appointmentDuration >= 30 ? "column" : "row"}
          >
            {view === "day" && (
              <>
                <Typography
                  style={{ textTransform: "capitalize" }}
                  variant="body"
                  color={getTextColor(isPassDate, data.original?.confirmed)}
                  mr={1}
                >
                  [{appointmentType} Appointment] {data?.original?.patient}
                  {`, ${data.start}`}
                  {outOfPlatform && <VrsIcon name={IconName.OutsidePatient} />}
                </Typography>
                <AppointmentStatus status={data.original?.status} />
              </>
            )}
            {view === "week" && (
              <>
                {appointmentDuration <= 30 ? (
                  <Box display="flex" alignItems="baseline">
                    <Typography
                      style={{ textTransform: "capitalize", minWidth: "fit-content" }}
                      variant="bodySemibold"
                      color={getTextColor(isPassDate, data.original?.confirmed)}
                    >
                      {appointmentType}
                      {!isSmallScreen && appointmentDuration > 30 && "Appointment"}
                    </Typography>
                    <Typography
                      variant="bodySemibold"
                      marginX={(theme) => theme.spacing(0.5)}
                      color={getTextColor(isPassDate, data.original?.confirmed)}
                    >
                      •
                    </Typography>
                    <Typography
                      style={{
                        textTransform: "capitalize",
                        flex: 1,
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}
                      variant="body"
                      color={getTextColor(isPassDate, data.original?.confirmed)}
                    >
                      {getShortName(data?.original?.patient)}
                    </Typography>
                    {outOfPlatform && <VrsIcon name={IconName.OutsidePatient} />}
                  </Box>
                ) : (
                  <>
                    <Typography
                      style={{ textTransform: "capitalize", minWidth: "fit-content" }}
                      variant="bodySemibold"
                      color={getTextColor(isPassDate, data.original?.confirmed)}
                    >
                      {appointmentType} {!isSmallScreen && "Appointment"}
                    </Typography>

                    <Typography
                      style={{ textTransform: "capitalize", flex: 1 }}
                      variant="body"
                      display="inline-block"
                      color={getTextColor(isPassDate, data.original?.confirmed)}
                    >
                      {data?.original?.patient}
                      {outOfPlatform && <VrsIcon name={IconName.OutsidePatient} />}
                    </Typography>
                  </>
                )}
                {appointmentDuration > MIN_BILLABLE_APPOINTMENT_DURATION && (
                  <AppointmentStatus status={data.original?.status} />
                )}
              </>
            )}
          </Box>
        ) : (
          <>
            {(view === "week" || view === "day") && (
              <Box px={0.5} pt={0.9} pb={0.2}>
                <Typography
                  style={{ textTransform: "capitalize" }}
                  variant="body1"
                  color={getTextColor(isPassDate, data.original?.confirmed)}
                >
                  {view === "day" && appointmentDuration < 30
                    ? eventParticipant.split(" ")[0]
                    : eventParticipant}
                </Typography>
                <Typography
                  variant="description"
                  color={getTextColor(isPassDate, data.original?.confirmed)}
                >
                  {view === "week" && data.start}
                  {data.original?.outOfPlatform && <VrsIcon name={IconName.OutsidePatient} />}
                </Typography>
              </Box>
            )}
            {(view === "bi-weekly" || view === "month") && (
              <Box p={0.5}>
                <Typography
                  variant="description"
                  color={getTextColor(isPassDate, data.original?.confirmed)}
                >
                  {data.start}
                </Typography>
              </Box>
            )}
          </>
        )}
      </Box>
    </VrsTooltip>
  );
};
