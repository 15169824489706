import { styled, Typography, Box, useMediaQuery, Theme } from "@mui/material";
import React from "react";
import { verisColors } from "@veris-health/web-core";
import { SensorsGraphView } from "../../measurementSlice";

export interface SensorLabelProps {
  symptomType: string;
  isPreview?: boolean;
  symptomStatus: string;
  isFirst?: boolean;
  isLast?: boolean;
  view: SensorsGraphView;
}

const StyledSensorLabel = styled("div", {
  shouldForwardProp: (prop: string) => prop !== "isFirst" && prop !== "isLast",
})<{ isFirst: boolean; isLast: boolean }>(({ theme, isFirst, isLast }) => ({
  height: "70px",
  width: "150px",
  padding: theme.spacing(1),
  backgroundColor: theme.veris.colors.neutrals["grey-1"],
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  overflow: "auto",
  borderStyle: "solid",
  borderWidth: "2px",
  borderColor: theme.veris.colors.neutrals["grey-light"],
  borderTopLeftRadius: isFirst ? "6px" : 0,
  borderBottomLeftRadius: isLast ? "6px" : 0,
  borderBottomWidth: isLast ? "2px" : "1px",
  borderTopWidth: isFirst ? "2px" : "1px",
}));

const StyledSensorDetails = styled("div")(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(1.5),
  padding: theme.spacing(0.5, 0),
}));

export const SymptomLabel = ({
  symptomType,
  symptomStatus,
  isPreview,
  isFirst,
  isLast,
  view,
}: SensorLabelProps): JSX.Element => {
  const symptomTypeFormated = symptomType.replaceAll("-", " ");
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));
  return isPreview ? (
    <Box
      sx={{
        height: "60px",
        width: "95px",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "baseline",
        pt: 2.3,
      }}
    >
      <Typography
        mr={1}
        variant="captionSemibold"
        sx={{
          "::first-letter": {
            textTransform: "capitalize",
          },
          whiteSpace: view === "daily" && !isSmallScreen ? "nowrap" : "normal",
        }}
      >
        {symptomTypeFormated}
      </Typography>
      <Typography
        variant="caption"
        color={verisColors.neutrals["grey-3"]}
        pt={0}
        sx={{
          textAlign: "right",
          flex: 1,
          textTransform: "capitalize",
        }}
      >
        {symptomStatus}
      </Typography>
    </Box>
  ) : (
    <StyledSensorLabel isFirst={!!isFirst} isLast={!!isLast}>
      <Typography
        color={verisColors.neutrals["grey-5"]}
        sx={{
          "::first-letter": {
            textTransform: "capitalize",
          },
        }}
      >
        {symptomTypeFormated}
      </Typography>
      <StyledSensorDetails>
        <Typography
          variant="description"
          color={verisColors.neutrals["grey-3"]}
          pt={0}
          sx={{ textTransform: "capitalize" }}
        >
          {symptomStatus}
        </Typography>
      </StyledSensorDetails>
    </StyledSensorLabel>
  );
};
