import React from "react";
import { matchPath, useLocation } from "react-router-dom";
import { Box, Theme, useMediaQuery } from "@mui/material";
import { VrsIcon, IconName } from "@veris-health/web-core";
import { Routes } from "../../routes-config";
import { topBarBreadcrumbItems, topBarMenuItems, topBarTitles } from "../../constants";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import { selectActiveNavBarTab, setActiveNavBarTab } from "../shared/slices/uiSlice";
import { useProfile } from "../../context/profile";
import { NavBarContainer } from "./containters/NavBarContainer";
import {
  selectChatHistoryPatientId,
  selectCurrentChatHistoryThread,
  selectCurrentThread,
} from "../Communication/store";
import { ChatTitle } from "./components/ChatTitle";

export enum ChatType {
  PRIVATE = "Private",
  GROUP = "Group",
  PATIENT = "Patient",
  CHATHISTORY = "Chat History",
}

export interface ChatTitleProps {
  name?: string;
  type: ChatType;
}

const ChatNavBarTitle = (): JSX.Element => {
  const currentThreadAll = useAppSelector(selectCurrentThread);
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));
  const chatHistoryPatientId = useAppSelector(selectChatHistoryPatientId);

  const currentThreadChatHistory = useAppSelector((state) =>
    // https://github.com/reduxjs/reselect/issues/550
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    selectCurrentChatHistoryThread(state, chatHistoryPatientId),
  );

  const currentThread = currentThreadAll || currentThreadChatHistory;

  const patientName =
    currentThread &&
    (currentThread.participants.find((participant) => participant.user_type === "patient")
      ?.display_name ||
      currentThread.for_patient?.display_name);

  return (
    <>
      {currentThread && (
        <Box display="flex" sx={{ position: "relative", marginLeft: -2 }}>
          {chatHistoryPatientId ? (
            <>
              <VrsIcon name={IconName.ChatHistory} size={isSmallScreen ? 30 : undefined} />
              <ChatTitle type={ChatType.CHATHISTORY} name={patientName} />
            </>
          ) : (
            <>
              {currentThread?.private === true ? (
                <>
                  <VrsIcon name={IconName.Lock} size={isSmallScreen ? 30 : undefined} />
                  <ChatTitle type={ChatType.PRIVATE} name={patientName} />
                </>
              ) : (
                currentThread.participants?.length > 2 && (
                  <ChatTitle type={ChatType.GROUP} name={patientName} />
                )
              )}

              <>
                {currentThread?.participants?.length < 3 && !currentThread.private && (
                  <ChatTitle type={ChatType.PATIENT} name={patientName} />
                )}
              </>
            </>
          )}
        </Box>
      )}
    </>
  );
};

const NavBarLayoutContainer = (): JSX.Element => {
  const activeTab = useAppSelector(selectActiveNavBarTab);
  const dispatch = useAppDispatch();
  const location = useLocation();

  const profile = useProfile();

  const match = Object.values(Routes).find((route) =>
    matchPath(location.pathname, { path: route, exact: true }),
  );

  const onTabClick = (index: number) => {
    dispatch(setActiveNavBarTab(index));
  };

  const getTitle = () => {
    switch (true) {
      case match === Routes.DOCTOR_PROFILE || match === Routes.DOCTOR_PROFILE_HOSPITAL_STAFF:
        return `Welcome back, ${profile?.first_name}`;
      case !!(match && topBarTitles[match]):
        return match && topBarTitles[match];
      case location.pathname.includes(Routes.COMMUNICATION):
        return <ChatNavBarTitle />;
      default:
        return "";
    }
  };

  return (
    <NavBarContainer
      title={getTitle() || ""}
      menuItems={(match && topBarMenuItems[match]) || []}
      activeTab={activeTab}
      onTabClick={onTabClick}
      breadcrumbItems={(match && topBarBreadcrumbItems[match]) || []}
    />
  );
};

export default NavBarLayoutContainer;
