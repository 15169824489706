import {
  Box,
  Checkbox,
  ListItemText,
  MenuItem,
  Select,
  Typography,
  outlinedInputClasses,
  selectClasses,
} from "@mui/material";
import { IconName, Option, VrsIcon, VrsIconButton, verisColors } from "@veris-health/web-core";
import React from "react";

interface VrsMultiselectProps {
  filterOptions: Option[];
  handleChange: (value: string[]) => void;
  filters: string[];
  predefinedFilters?: string;
  disableClear?: boolean;
  emptyFilterValue?: string;
}

const ValueTag = ({ value }: { value: string }): JSX.Element => (
  <Box
    sx={{
      borderRadius: (theme) => theme.spacing(1),
      padding: (theme) => theme.spacing(0.5, 1),
      backgroundColor: (theme) => theme.veris.colors.neutrals["grey-2"],
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    }}
    mx={0.5}
  >
    <Typography color={(theme) => theme.veris.colors.neutrals["grey-4"]} variant="caption">
      {value}
    </Typography>
  </Box>
);

export const VrsMultiselect = ({
  filters,
  handleChange,
  filterOptions,
  predefinedFilters,
  disableClear,
  emptyFilterValue = "All",
}: VrsMultiselectProps): JSX.Element => (
  <Select
    displayEmpty
    placeholder="Choose filters"
    value={filters}
    onChange={(event) => typeof event.target.value !== "string" && handleChange(event.target.value)}
    IconComponent={(props) => <VrsIcon {...props} name={IconName.ArrowDown} />}
    startAdornment={
      filters.length > 0 && (
        <VrsIconButton
          onClick={() => {
            handleChange(predefinedFilters ? [predefinedFilters] : []);
          }}
          iconProps={{
            name: IconName.CloseIcon,
            size: 12,
            stroke: verisColors.neutrals[disableClear ? "grey-3" : "grey-4"],
          }}
          disabled={disableClear}
        />
      )
    }
    multiple
    sx={{
      "& .MuiSelect-select": {
        padding: (theme) => theme.spacing(1),
      },
      "&.MuiOutlinedInput-root": {
        backgroundColor: (theme) => theme.veris.colors.neutrals.white,
        borderRadius: (theme) => theme.spacing(1),
        [`& .${outlinedInputClasses.notchedOutline} `]: {
          borderColor: (theme) => theme.veris.colors.neutrals["grey-2"],
          borderWidth: "1px",
        },
      },
      [`.${selectClasses.icon}`]: {
        top: "unset",
      },
    }}
    renderValue={(selected) => (
      <Box display="flex" flexDirection="row">
        {selected.length > 0 ? (
          selected.map((value) => <ValueTag value={value} />)
        ) : (
          <ValueTag value={emptyFilterValue} />
        )}
      </Box>
    )}
    MenuProps={{
      PaperProps: {
        style: {
          margin: "0",
          padding: "0 8px",
          left: 0,
          maxHeight: "300px",
        },
      },
    }}
  >
    {filterOptions.map((option) => (
      <MenuItem
        key={option.value}
        value={option.value}
        disabled={option.value === ""}
        sx={{
          borderBottom: (theme) => `1px solid ${theme.veris.colors.neutrals["grey-2"]}`,
          padding: (theme) => theme.spacing(1),
        }}
      >
        {option.value !== "" && (
          <Checkbox
            sx={{
              color: (theme) => theme.veris.colors.amethyst.normal,
              padding: (theme) => theme.spacing(0.5),
            }}
            checked={!!filters && filters.indexOf(option.value) > -1}
            color="primary"
          />
        )}
        <ListItemText primary={<Typography variant="caption">{option.label}</Typography>} />
      </MenuItem>
    ))}
  </Select>
);
