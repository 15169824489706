import { useCallback, useEffect, useState } from "react";
import {
  GetAppointmentResponse,
  AppointmentAttendeeStatusChangeEnum,
} from "@veris-health/med-data-ms/lib/v1";
import { getAppointmentDetails } from "../../shared/slices/api/calendarApi";
import { useProfile } from "../../../context/profile";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { changeAppointmentStatusAsync } from "../../shared/slices/asyncThunks";

export function useAppointment(id: string): {
  appointment?: GetAppointmentResponse;
  onStatusChange: (status: AppointmentAttendeeStatusChangeEnum) => void;
} {
  const dispatch = useAppDispatch();
  const [appointment, setAppointment] = useState<GetAppointmentResponse>();
  const profile = useProfile();

  useEffect(() => {
    if (id && profile) {
      getAppointmentDetails(profile.id, id).then((data) => {
        setAppointment(data);
      });
    }
  }, [id, profile]);

  const onStatusChange = useCallback(
    (status: AppointmentAttendeeStatusChangeEnum) => {
      if (profile && appointment) {
        dispatch(
          changeAppointmentStatusAsync({
            userId: +profile.id,
            appointmentId: appointment.id,
            appointmentStatus: status,
          }),
        );
      }
    },
    [profile, appointment],
  );

  return { appointment, onStatusChange };
}
