import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import {
  ClinicalNote,
  ClinicalNotePayloadMimeType,
  ClinicalNoteTypeEnum,
} from "@veris-health/med-data-ms/lib/v1";
import { convertToRaw, EditorState } from "draft-js";
import { IconName, VrsIcon, VrsErrorMsg } from "@veris-health/web-core";

import { useAppSelector } from "../../../hooks/useAppSelector";
import {
  addClinicalNoteAsync,
  loadCareTeamNotesAsync,
  loadClinicalNotesAsync,
  selectClinicalNotes,
  selectClinicalNotesStatus,
} from "../patientDetailsSlice";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { AddNewNote } from "../components/AddNote";
import { ClinicalNoteItem } from "../components/ClinicalNotes";
import { ClinicalNoteEditor } from "../components/ClinicalNotes/ClinicalNoteEditor";
import { useCurrentPatient } from "../../../hooks/useCurrentPatient";

export function ClinicalNotes(): JSX.Element {
  const currentPatient = useCurrentPatient();
  const clinicalNotes = useAppSelector((state) =>
    selectClinicalNotes(state, currentPatient && +currentPatient?.id),
  );
  const clinicalNotesStatus = useAppSelector(selectClinicalNotesStatus);
  const [selectedNote, setSelectedNote] = useState<ClinicalNote>();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (currentPatient) dispatch(loadClinicalNotesAsync({ id: currentPatient?.id }));
  }, [dispatch, currentPatient]);

  useEffect(() => {
    if (!selectedNote && clinicalNotes) {
      setSelectedNote(clinicalNotes[0]);
    } else {
      const note = clinicalNotes?.find(({ id }) => id === selectedNote?.id);
      if (note) {
        setSelectedNote(note);
      }
    }
  }, [dispatch, clinicalNotes, selectedNote]);

  useEffect(() => {
    setSelectedNote(clinicalNotes && clinicalNotes[0]);
  }, [clinicalNotes]);

  const onCreateNewNote = (editorState: EditorState) => {
    if (currentPatient) {
      const rawContentState = convertToRaw(editorState.getCurrentContent());

      dispatch(
        addClinicalNoteAsync({
          id: currentPatient.id,
          note: {
            payload_mime_type: ClinicalNotePayloadMimeType.TextPlain,
            payload: JSON.stringify(rawContentState),
            note_type: ClinicalNoteTypeEnum.Progress,
          },
        }),
      );
    }
  };

  return (
    <Box sx={{ display: "flex", overflow: "hidden", height: "70vh" }}>
      {clinicalNotesStatus === "loading" && (
        <Box display="flex" justifyContent="center" alignItems="center" width="100%">
          <CircularProgress />
        </Box>
      )}
      {clinicalNotesStatus === "failed" && (
        <VrsErrorMsg
          size="small"
          onClick={() => {
            if (currentPatient) dispatch(loadCareTeamNotesAsync({ id: currentPatient?.id }));
          }}
        />
      )}
      {clinicalNotesStatus === "idle" && clinicalNotes && clinicalNotes.length === 0 && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          marginX="auto"
          marginY="2rem"
          height="70vh"
        >
          <VrsIcon name={IconName.NoNotes} />
          <Typography
            variant="h6"
            color={(theme) => theme.veris.colors.neutrals["grey-3"]}
            mt={5}
            textAlign="center"
          >
            There are no clinical notes for this patient.
          </Typography>
          <AddNewNote
            generateDocumentationAssistance
            onSave={onCreateNewNote}
            showTitle={false}
            showToolbar={false}
          />
        </Box>
      )}
      {clinicalNotesStatus === "idle" &&
        currentPatient &&
        clinicalNotes &&
        clinicalNotes.length > 0 &&
        !!selectedNote && (
          <>
            <Box width="18rem">
              <Stack
                height="58vh"
                sx={{ overflowY: "scroll" }}
                padding={(theme) => theme.spacing(2.75, 2.75, 0, 2.75)}
              >
                {clinicalNotes.map((note) => (
                  <ClinicalNoteItem
                    key={note.id}
                    note={note}
                    open={selectedNote?.id === note.id}
                    onClick={() => setSelectedNote(note)}
                  />
                ))}
              </Stack>
              <AddNewNote
                generateDocumentationAssistance
                onSave={onCreateNewNote}
                showTitle={false}
                showToolbar={false}
              />
            </Box>
            <ClinicalNoteEditor note={selectedNote} />
          </>
        )}
    </Box>
  );
}
