import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import React, { useState } from "react";
import { capitalize } from "lodash";
import { VrsErrorMsg, VrsButton } from "@veris-health/web-core";
import { PatientInfoLabel } from "../../../../ui/components/PatientInfoLabel";
import { PatientInfoField } from "../../../../ui/components/PatientInfoField";
import { Status, VrsMedStaffProfileModel } from "../../../shared/interfaces";
import { formatPhoneNumber } from "../../../shared/helpers";
import { PhoneFormInputField } from "../../../LoginRegistration/PhoneFormInputField";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import { OTPhoneEditContainer } from "../OTPhoneEditContainer";
import SnackbarUtils from "../../../../utils/SnackbarUtils";
import { confirmEditedPhoneNumberAsync, editPhoneNumberAsync } from "../../doctorsProfileSlice";

const accountValidationSchema = yup.object().shape({
  phone: yup
    .string()
    .required()
    .max(20)
    .when("country", {
      is: (value: string) => value.length >= 2,
      then: yup.string().min(8),
      otherwise: yup.string().min(10),
    })
    .matches(/^[+]?\d+$/, "Should contain only digits"),
});

export interface DoctorAccountComponentProps {
  data: VrsMedStaffProfileModel;
  userId: number;
  status: Status;
  fetchDoctorData: () => void;
}

export const DoctorAccountComponent = ({
  data,
  userId,
  status,
  fetchDoctorData,
}: DoctorAccountComponentProps): JSX.Element => {
  const [editMode, setEditMode] = useState<boolean>(false);
  const [isOtpScreen, setIsOtpScreen] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { fullName, phoneNumber, emergencyPhoneNumber, email, address, profession } = data;
  const formattedSOSNumber = emergencyPhoneNumber && formatPhoneNumber(emergencyPhoneNumber);
  const getParsedPhoneNumber = (value: string) => {
    let number = parsePhoneNumberFromString(value);
    if (number?.countryCallingCode === undefined) {
      number = parsePhoneNumberFromString(`+${value}`);
    }
    return number;
  };
  const formattedPhoneNumber =
    phoneNumber && (getParsedPhoneNumber(phoneNumber)?.nationalNumber || phoneNumber);
  const getValue = (value: string | undefined) => (value && value.length > 0 ? value : "N/A");

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: fullName,
      speciality: profession || "",
      phone: formattedPhoneNumber || "",
      emergencyPhone: formattedSOSNumber || "",
      eMail: email || "",
      city: address?.city || "",
      state: address?.state || "",
      zipCode: address?.zipcode || "",
      street: address?.street || "",
      country: (phoneNumber && getParsedPhoneNumber(phoneNumber)?.countryCallingCode) || "",
    },
    validationSchema: accountValidationSchema,
    onSubmit: (values, { setFieldError }) => {
      dispatch(
        editPhoneNumberAsync({
          id: userId,
          phoneNumber: `+${values.country}${values.phone}`,
        }),
      ).then((res) => {
        if (res.meta.requestStatus === "fulfilled") setIsOtpScreen(true);
        else if (res.meta.requestStatus === "rejected") {
          setFieldError("phone", (res?.payload as { message: string }).message);
        }
      });
    },
  });

  const {
    errors,
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    setFieldValue,
    submitForm,
    resetForm,
    dirty,
    isValid,
  } = formik;

  const { phone, country, name, eMail, speciality, emergencyPhone, city, state, zipCode, street } =
    formik.values;

  const fieldsData = [
    {
      label: "Name",
      name: "name",
      value: name,
    },
    {
      label: "Speciality",
      name: "speciality",
      value: speciality,
    },
    {
      label: "Phone Number",
      name: "phone",
      value: phone,
      helperText: capitalize(errors.phone) || " ",
      error: !!errors.phone,
      isValidField: !!touched.phone && phone.length && !errors.phone,
    },
    {
      label: "Emergency Phone",
      name: "emergencyPhone",
      value: emergencyPhone,
    },
    {
      label: "Email",
      name: "email",
      value: eMail,
    },
    {
      label: "City",
      name: "city",
      value: city,
    },
    {
      label: "State",
      name: "state",
      value: state,
    },
    {
      label: "Zip Code",
      name: "zipCode",
      value: zipCode,
    },
    {
      label: "Street",
      name: "street",
      value: street,
    },
  ];

  const onOTPSubmit = (code: string) => {
    dispatch(
      confirmEditedPhoneNumberAsync({
        id: userId,
        phoneNumber: `+${country}${phone}`,
        otpCode: code,
      }),
    ).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        setEditMode(false);
        setIsOtpScreen(false);
        SnackbarUtils.success("Your verification was successful.");
      }
    });
  };

  return (
    <>
      {status === "loading" && (
        <Box display="flex" justifyContent="center" alignItems="center" p={2}>
          <CircularProgress />
        </Box>
      )}
      {status === "failed" && (
        <Box display="flex" justifyContent="center" alignItems="center" width="100%">
          <VrsErrorMsg size="small" onClick={fetchDoctorData} />
        </Box>
      )}
      {status === "idle" && (
        <>
          <FormikProvider value={formik}>
            <form onSubmit={handleSubmit}>
              <Box margin={2.3} display="flex">
                <Grid item container xs={12} spacing={1} display="flex">
                  {fieldsData.map((item) => (
                    <Grid
                      item
                      xs={4}
                      display="flex"
                      minHeight="57px"
                      sx={{ alignItems: "center" }}
                      key={item.label}
                      paddingRight={4}
                    >
                      <Grid item xs={3} pt={1}>
                        <PatientInfoLabel fieldName={item.label} />
                      </Grid>
                      <Grid item xs={8} key={item.label}>
                        {editMode && item.name === "phone" ? (
                          <PhoneFormInputField
                            name={item.name}
                            value={item.value || ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={item.helperText}
                            error={item.error || false}
                            isValidField={item.isValidField || false}
                            countryCode={country || "1"}
                            onSelected={(value) => setFieldValue("country", value.code.trim())}
                          />
                        ) : (
                          <PatientInfoField
                            key={item.value?.length > 0 ? item.value : "N/A"}
                            value={
                              item.name === "phone" && phone !== ""
                                ? formatPhoneNumber(`+${country}${phone}`)
                                : getValue(item.value)
                            }
                          />
                        )}
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Box>
              <Box display="flex" justifyContent="flex-end" pb={2} px={2}>
                {editMode ? (
                  <Box alignSelf="flex-end" display="flex">
                    <VrsButton
                      onClick={() => {
                        setEditMode(false);
                        resetForm();
                      }}
                      buttonType="transparent"
                    >
                      <Typography
                        variant="body"
                        sx={{
                          textDecoration: "underline",
                          textTransform: "none",
                        }}
                      >
                        Cancel
                      </Typography>
                    </VrsButton>
                    <VrsButton
                      buttonType="secondary"
                      disabled={
                        !isValid || !touched || !dirty || phoneNumber === `+${country}${phone}`
                      }
                      type="submit"
                    >
                      Save
                    </VrsButton>
                  </Box>
                ) : (
                  <Box alignSelf="flex-end">
                    <VrsButton buttonType="secondary" onClick={() => setEditMode(true)}>
                      Edit Details
                    </VrsButton>
                  </Box>
                )}
              </Box>
            </form>
          </FormikProvider>
          {isOtpScreen && (
            <OTPhoneEditContainer
              onOTPSubmit={(value) => onOTPSubmit(value)}
              onResendCode={() => submitForm()}
              open={!!isOtpScreen}
              onClose={() => setIsOtpScreen(false)}
            />
          )}
        </>
      )}
    </>
  );
};
