import React, { useState } from "react";
import {
  Box,
  Checkbox,
  Input,
  List,
  ListItem,
  Radio,
  Slider,
  TextField,
  TextFieldProps,
  Theme,
  Typography,
  inputBaseClasses,
  styled,
} from "@mui/material";
import { IconName, VrsButton, VrsIcon, VrsTooltip, verisColors } from "@veris-health/web-core";
import { BodyPartAnswerItem, QuestionnaireViewLabel } from "@veris-health/virtual-doc-ms/lib/v1";
import { Datepicker } from "@mobiscroll/react";
import dayjs from "dayjs";
import { BodyPoint, anteriorBodyPoints, posteriorBodyPoints } from "../utils/constants";

export const StyledTextField = styled(TextField, {
  shouldForwardProp: (prop: string) => prop !== "maxHeight",
})<TextFieldProps & { maxHeight: string }>(({ theme, maxHeight }) => ({
  ".MuiOutlinedInput-notchedOutline": {
    borderColor: theme.veris.colors.neutrals["grey-2"],
  },
  [`& .${inputBaseClasses.root}`]: {
    ...theme.typography.body,
    maxHeight,
    color: theme.veris.colors.neutrals["grey-4"],
    "&:hover fieldset": {
      border: `1px solid ${theme.veris.colors.neutrals["grey-2"]}`,
    },
    "&.Mui-disabled": {
      fieldset: { border: `1px solid ${theme.veris.colors.neutrals["grey-2"]}` },
    },
  },
}));

export const ButtonAnswer = ({
  handleClick,
}: {
  handleClick: (answer: string) => void;
}): JSX.Element => (
  <Box display="flex" justifyContent="space-around">
    <VrsButton buttonType="primary" onClick={() => handleClick("Yes")} sx={{ width: "200px" }}>
      Yes
    </VrsButton>
    <VrsButton buttonType="secondary" onClick={() => handleClick("No")} sx={{ width: "200px" }}>
      No
    </VrsButton>
  </Box>
);

export const PainSlider = ({
  handleChange,
}: {
  handleChange: (answer: number) => void;
}): JSX.Element => {
  const [answer, setAnswer] = useState<number>();
  const handleContinueClick = () => {
    if (answer) handleChange(answer);
    setAnswer(0);
  };
  return (
    <Box display="flex" flexDirection="column" justifyContent="center">
      <Slider
        min={1}
        max={10}
        marks
        valueLabelDisplay="on"
        onChange={(e, value) => setAnswer(value as number)}
      />
      <Box display="flex" justifyContent="flex-end">
        <VrsButton buttonType="primary" onClick={handleContinueClick} disabled={!answer}>
          Continue
        </VrsButton>
      </Box>
    </Box>
  );
};

export const DateTimePicker = ({
  handleChange,
}: {
  handleChange: (answer: string) => void;
}): JSX.Element => {
  const [answer, setAnswer] = useState<string>();
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const handleContinueClick = () => {
    if (answer) handleChange(answer);
    setAnswer("");
    setOpenDatePicker(false);
  };
  return (
    <Box display="flex" flexDirection="column" justifyContent="center">
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
        pt={3}
        pb={1}
      >
        <VrsIcon name={IconName.Calendar} stroke={verisColors.neutrals["grey-3"]} />
        <Datepicker
          controls={["calendar", "time"]}
          dateFormat="DDD MMM D, YYYY"
          dateWheels="|DDD MMM D, YYYY|"
          theme="material"
          showOnClick={false}
          showOnFocus={false}
          inputComponent="input"
          inputProps={{
            placeholder: "Choose date and time",
            readOnly: true,
            className: "vrs-datepicker fontSize",
            onClick: () => {
              setOpenDatePicker(true);
            },
          }}
          isOpen={openDatePicker}
          onClose={() => setOpenDatePicker(false)}
          onChange={(picker) => setAnswer(dayjs(picker.value).utc().format())}
          touchUi
        />
      </Box>
      <Box display="flex" justifyContent="flex-end">
        <VrsButton buttonType="primary" onClick={handleContinueClick} disabled={!answer}>
          Continue
        </VrsButton>
      </Box>
    </Box>
  );
};

export const NumberInputAnswer = ({
  handleChange,
}: {
  handleChange: (answer: number) => void;
}): JSX.Element => {
  const [answer, setAnswer] = useState<number>(0);
  const handleContinueClick = () => {
    if (answer) handleChange(answer);
    setAnswer(0);
  };

  return (
    <Box display="flex" flexDirection="column" justifyContent="center">
      <Input
        type="text"
        onChange={(e) => setAnswer(+e.target.value)}
        value={answer}
        sx={{
          alignSelf: "center",
          [`& .${inputBaseClasses.input}`]: { textAlign: "center" },
        }}
      />
      <Box display="flex" justifyContent="flex-end" my={1}>
        <VrsButton buttonType="primary" onClick={handleContinueClick} disabled={!answer}>
          Continue
        </VrsButton>
      </Box>
    </Box>
  );
};

export const TextAnswer = ({
  handleChange,
}: {
  handleChange: (answer: string) => void;
}): JSX.Element => {
  const [answer, setAnswer] = useState<string>();
  const handleContinueClick = () => {
    if (answer?.trim()) handleChange(answer);
    setAnswer("");
  };
  return (
    <Box>
      <StyledTextField
        onChange={(e) => setAnswer(e.target.value)}
        value={answer}
        rows={4}
        multiline
        fullWidth
        placeholder="Please answer the question."
        maxHeight="auto"
      />
      <Box display="flex" justifyContent="flex-end" my={1}>
        <VrsButton buttonType="primary" onClick={handleContinueClick} disabled={!answer?.trim()}>
          Continue
        </VrsButton>
      </Box>
    </Box>
  );
};

export const MultiChoiceAnswer = ({
  handleChange,
  options,
  isMultiChoice,
}: {
  handleChange: (answer: string | string[]) => void;
  options: QuestionnaireViewLabel[];
  isMultiChoice: boolean;
}): JSX.Element => {
  const [answers, setAnswers] = useState<string[] | string>("");
  const handleContinueClick = () => {
    handleChange(answers);
    setAnswers("");
  };
  return (
    <Box>
      <List>
        {options.map((option) => (
          <ListItem key={option.value}>
            {isMultiChoice ? (
              <Checkbox
                aria-multiselectable
                edge="start"
                checked={answers.includes(option.value)}
                tabIndex={-1}
                disableRipple
                sx={{
                  color: (theme) => theme.veris.colors.amethyst.normal,
                  "&.Mui-checked": {
                    color: (theme) => theme.veris.colors.amethyst.normal,
                  },
                }}
                onChange={() => {
                  if (answers.includes(option.value))
                    setAnswers([...(answers as string[]).filter((el) => el !== option.value)]);
                  else
                    setAnswers(
                      answers.length > 0
                        ? [...(answers as string[]), option.value]
                        : [option.value],
                    );
                }}
              />
            ) : (
              <Radio
                aria-multiselectable={false}
                onChange={() => setAnswers(option.value)}
                checked={answers === option.value}
              />
            )}
            <Typography
              variant="h6"
              color={(theme) => theme.veris.colors.neutrals["grey-3"]}
              sx={{ cursor: "pointer" }}
              onClick={() => {
                if (isMultiChoice) {
                  if (answers.includes(option.value))
                    setAnswers([...(answers as string[]).filter((el) => el !== option.value)]);
                  else
                    setAnswers(
                      answers.length > 0
                        ? [...(answers as string[]), option.value]
                        : [option.value],
                    );
                } else setAnswers(option.value);
              }}
            >
              {option.label}{" "}
            </Typography>
          </ListItem>
        ))}
      </List>
      <Box display="flex" justifyContent="flex-end" my={1}>
        <VrsButton buttonType="primary" onClick={handleContinueClick} disabled={!answers}>
          Continue
        </VrsButton>
      </Box>
    </Box>
  );
};

const StyledBodyPoint = ({
  point,
  onClick,
  selected,
}: {
  point: BodyPoint;
  onClick: (shouldSet: boolean, point: BodyPoint) => void;
  selected: boolean;
}): JSX.Element => (
  <VrsTooltip
    title={<Typography variant="body2">{point.name} </Typography>}
    bcgcolor={`${(theme: Theme) => theme.veris.colors.neutrals.black}`}
  >
    <Box
      onClick={() => onClick(!selected, point)}
      sx={{
        position: "absolute",
        top: point.cy,
        left: point.cx,
        backgroundColor: (theme) =>
          selected ? theme.veris.colors.errors.normal : theme.veris.colors.neutrals["grey-3"],
        opacity: 0.5,
        borderRadius: "50%",
        width: "30px",
        height: "30px",
        "&:hover": {
          backgroundColor: (theme) => theme.veris.colors.amethyst.normal,
          cursor: "pointer",
          transition: "0.5ms",
        },
      }}
    />
  </VrsTooltip>
);

export const BodyPartsAnswer = ({
  handleChange,
}: {
  handleChange: (answer: BodyPartAnswerItem[]) => void;
}): JSX.Element => {
  const [answers, setAnswers] = useState<BodyPartAnswerItem[]>([]);
  const isSelected = (point: BodyPoint) =>
    !!answers.find((option) => option.body_part === point.name && option.id === point.id);

  const handleClick = (shouldSet: boolean, point: BodyPoint) => {
    const updatedBodyParts = shouldSet
      ? [...answers, { id: point.id, body_part: point.name, x: 0, y: 0 }]
      : answers.filter((option) => option.id !== point.id && option.body_part !== point.name);
    setAnswers(updatedBodyParts);
  };
  const handleContinueClick = () => {
    handleChange(answers);
    setAnswers([]);
  };
  return (
    <Box>
      <Box display="flex" justifyContent="space-around" alignItems="center">
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <Typography variant="h4" color={(theme) => theme.veris.colors.neutrals["grey-3"]}>
            Front
          </Typography>
          <Box sx={{ position: "relative" }}>
            <VrsIcon name={IconName.FrontBody} />{" "}
            {anteriorBodyPoints.map((point) => (
              <StyledBodyPoint
                point={point}
                onClick={handleClick}
                selected={isSelected(point)}
                key={`${point.name}-${point.id}`}
              />
            ))}
          </Box>
        </Box>

        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <Typography variant="h4" color={(theme) => theme.veris.colors.neutrals["grey-3"]}>
            Back
          </Typography>
          <Box sx={{ position: "relative" }}>
            <VrsIcon name={IconName.BackBody} />{" "}
            {posteriorBodyPoints.map((point) => (
              <StyledBodyPoint
                point={point}
                onClick={handleClick}
                selected={isSelected(point)}
                key={`${point.name}-${point.id}`}
              />
            ))}
          </Box>
        </Box>
      </Box>
      <Box display="flex" justifyContent="flex-end" my={1}>
        <VrsButton
          buttonType="primary"
          onClick={handleContinueClick}
          disabled={answers.length === 0}
        >
          Continue
        </VrsButton>
      </Box>{" "}
    </Box>
  );
};
