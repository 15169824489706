import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import dayjs from "dayjs";
import { IconName, dateFormats } from "@veris-health/web-core";
import {
  BloodPressureNormalRange,
  MioSensorMeasurement,
  MioSensors,
  SensorDataKeys,
  SensorNormalRange,
  selectDateFrom,
  selectDateTo,
  selectMioSensors,
} from "../../../../PatientDetailsMeasurements/measurementSlice";
import { useAppSelector } from "../../../../../hooks/useAppSelector";
import { VrsPatientInfo } from "../../../../shared/interfaces";
import { convertNumberToAbbreviatedString } from "../../../../shared/helpers";
import { ExportSection, ExportSectionProps } from "../ExportSection";
import { genericSorter } from "../../../../../utils/sorting";
import { colorValueByReference } from "../../../../../ui/components/Tables/VrsLabResultsTable";
import { MeasurementUnits } from "../../../../../constants";

interface FormattedData {
  date: string;
  data: {
    [key: string]: MioSensorMeasurement[];
  };
  normalRange: {
    [key: string]:
      | SensorNormalRange
      | { min: BloodPressureNormalRange; max: BloodPressureNormalRange };
  };
}

const SensorLabels: { [key: string]: string } = {
  temp: "Temperature",
  bpm: "Heart Rate",
  motion: "Activity",
  blood_pressure: "Blood Pressure",
  oximeter: "Oxygen saturation",
  weight: "Weight",
};

interface RawDataTableProps extends ExportSectionProps {
  currentPatient: VrsPatientInfo;
}

const SensorEntry = ({
  sensor,
  values,
  normalRange,
}: {
  sensor: string;
  values: MioSensorMeasurement[];
  normalRange:
    | SensorNormalRange
    | {
        min: BloodPressureNormalRange;
        max: BloodPressureNormalRange;
      };
}) => {
  const valueKey = SensorDataKeys[sensor] as keyof MioSensorMeasurement;

  return (
    <Box key={sensor} mr={5}>
      <Typography
        variant="subtitle1"
        color={(theme) => theme.veris.colors.neutrals["grey-4"]}
        mb={2}
      >
        {SensorLabels[sensor]}
      </Typography>

      {values?.map((v) => {
        const isBloodPressure = sensor === "blood_pressure";
        const diastolicKey = SensorDataKeys.bp_dia as keyof MioSensorMeasurement;

        return (
          <Box key={v.dt} display="flex" my={1} alignItems="center">
            {sensor in SensorDataKeys && (
              <>
                {isBloodPressure &&
                typeof normalRange.min !== "number" &&
                typeof normalRange.max !== "number" ? (
                  <>
                    <Typography
                      variant="subtitle2"
                      sx={{ fontWeight: 500 }}
                      color={colorValueByReference(
                        convertNumberToAbbreviatedString(Number(v[valueKey])),
                        normalRange.min.sys.toString(),
                        normalRange.max.sys.toString(),
                      )}
                    >
                      {v[valueKey]}/
                    </Typography>
                    <Typography
                      component="span"
                      variant="subtitle2"
                      sx={{ fontWeight: 500 }}
                      color={colorValueByReference(
                        convertNumberToAbbreviatedString(Number(v[diastolicKey])),
                        normalRange.min.dia.toString(),
                        normalRange.max.dia.toString(),
                      )}
                    >
                      {v[diastolicKey]}&nbsp;
                    </Typography>
                  </>
                ) : (
                  <Typography
                    variant="subtitle2"
                    sx={{ fontWeight: 500 }}
                    color={colorValueByReference(
                      convertNumberToAbbreviatedString(Number(v[valueKey])),
                      normalRange.min?.toString(),
                      normalRange.max?.toString(),
                    )}
                  >
                    {convertNumberToAbbreviatedString(Number(v[valueKey]))}
                    <Typography variant="caption" sx={{ fontWeight: 500 }}>
                      {MeasurementUnits[sensor]}&nbsp;{" "}
                    </Typography>
                  </Typography>
                )}

                <Typography
                  color={(theme) => theme.veris.colors.neutrals["grey-3"]}
                  variant="subtitle2"
                >
                  at {dayjs.unix(+v.dt).format("hh:mm a")}
                </Typography>
              </>
            )}
          </Box>
        );
      })}
    </Box>
  );
};

const SensorGroup = ({ sensorObject }: { sensorObject: FormattedData }) => {
  const { date, data, normalRange } = sensorObject;

  return (
    <Box
      key={date}
      sx={{
        width: "100%",
        border: (theme) => `1px solid ${theme.veris.colors.neutrals["grey-2"]}`,
        borderRadius: "6px",
      }}
      className="break-before"
      p={2}
    >
      <Typography
        variant="subtitle1"
        color={(theme) => theme.veris.colors.neutrals["grey-3"]}
        pb={2}
        mb={2}
        sx={{
          borderBottom: (theme) => `1px solid ${theme.veris.colors.neutrals["grey-2"]}`,
        }}
      >
        {dayjs(date).format(dateFormats["MMM D/YYYY"])}
      </Typography>
      <Box display="flex" flexDirection="row">
        {Object.entries(data || {}).map(([sensor, values]) => (
          <SensorEntry
            key={sensor}
            sensor={sensor}
            values={values}
            normalRange={normalRange[sensor]}
          />
        ))}
      </Box>
    </Box>
  );
};

const RawDataTable = ({ currentPatient, title, include, onInclude }: RawDataTableProps) => {
  const sensorsData = useAppSelector((state) => selectMioSensors(state, +currentPatient.id));
  const startDate = useAppSelector(selectDateFrom);
  const endDate = useAppSelector(selectDateTo);
  const [groupedData, setGroupedData] = useState<FormattedData[]>([]);

  const formatData = () => {
    const formattedData: FormattedData[] = [];

    if (sensorsData) {
      Object.keys(sensorsData).forEach((key) => {
        const sensorData = sensorsData[key as keyof MioSensors];

        sensorData.data.forEach((item) => {
          const { dt } = item;
          const dateKey = dayjs.unix(+dt).format(dateFormats["MM/DD/YYYY"]);

          let dateEntry = formattedData.find((entry) => entry.date === dateKey);

          if (!dateEntry) {
            dateEntry = { date: dateKey, data: {}, normalRange: {} };
            formattedData.push(dateEntry);
          }

          if (!dateEntry.data[key]) {
            dateEntry.data[key] = [];
          }

          if (!dateEntry.normalRange[key]) {
            dateEntry.normalRange[key] = sensorData.normalRange;
          }

          dateEntry.data[key].push(item);
        });
      });
    }
    const sortedData = genericSorter(formattedData, "asc", "date");

    return sortedData;
  };

  useEffect(() => {
    setGroupedData(formatData());
  }, [startDate, endDate, sensorsData]);

  return (
    <ExportSection
      title={title}
      iconName={IconName.RawDataIcon}
      include={include}
      onInclude={onInclude}
    >
      {groupedData && groupedData.length > 0 && (
        <Box display="flex" gap={3} alignItems="center" flexDirection="column">
          {groupedData.map((sensorObject) => (
            <SensorGroup key={sensorObject.date} sensorObject={sensorObject} />
          ))}
        </Box>
      )}
    </ExportSection>
  );
};
export default RawDataTable;
