import { MbscCalendarEventData, Popup } from "@mobiscroll/react";
import { Box, styled } from "@mui/material";
import React from "react";
import { CalendarShownType } from "../calendarSlice";
import { RenderEvent } from "./RenderEvent";

interface YearlyViewPopupProps {
  data: MbscCalendarEventData[];
  open: boolean;
  anchor: HTMLElement | undefined;
  closePoper: () => void;
  viewType: CalendarShownType;
  view: string;
  onEventClick: (anchor: HTMLElement | undefined, event: MbscCalendarEventData) => void;
}
const StyledBox = styled(Box)(({ theme }) => ({
  "&:hover": {
    backgroundColor: theme.veris.colors.neutrals["grey-1"],
  },
}));

export const YearlyViewPopup = ({
  data,
  open,
  anchor,
  closePoper,
  viewType,
  view,
  onEventClick,
}: YearlyViewPopupProps): JSX.Element => {
  return (
    <Popup
      display="anchored"
      isOpen={open && view === "year"}
      anchor={anchor}
      touchUi={false}
      onClose={closePoper}
      contentPadding={false}
    >
      <Box className="mbsc-material mbsc-popover-list">
        {data.map((event: MbscCalendarEventData) => (
          <StyledBox
            className="mbsc-event mbsc-list-item mbsc-material mbsc-ltr mbsc-list-item-actionable"
            onClick={() => {
              closePoper();
              onEventClick(anchor, event);
            }}
            key={event?.original?.id ?? ""}
          >
            <RenderEvent data={event} viewType={viewType} />
          </StyledBox>
        ))}
      </Box>
    </Popup>
  );
};
