import React, { useEffect } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import { VrsButton, VrsInput } from "@veris-health/web-core";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import {
  selectValidationError,
  selectAuthStatus,
  clearValidationError,
} from "../shared/slices/authSlice";
import { numberRegexpPattern } from "../../constants";

export interface OtpLoginContainerProps {
  onSubmit: (code: string) => void;
  onResendCode: () => void;
}

const initialValues = {
  otpCode: "",
};

const validationSchema = yup.object().shape({
  otpCode: yup.string().max(4).min(4).required(""),
});

export const OTPLoginContainer = ({
  onSubmit,
  onResendCode,
}: OtpLoginContainerProps): JSX.Element => {
  const validationError = useAppSelector(selectValidationError);
  const authStatus = useAppSelector(selectAuthStatus);
  const dispatch = useAppDispatch();

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: ({ otpCode }) => {
      onSubmit(otpCode);
    },
  });

  useEffect(() => {
    if (validationError && !formik.values.otpCode) dispatch(clearValidationError());
  }, [validationError, formik.values, dispatch]);

  return (
    <Box mr={5}>
      <form onSubmit={formik.handleSubmit}>
        <Box display="flex" m={2}>
          <VrsInput
            name="otpCode"
            onChange={(e) => {
              if (e.target.value === "" || numberRegexpPattern.test(e.target.value))
                formik.handleChange(e);
            }}
            value={formik.values.otpCode}
            error={Boolean(validationError)}
            isValid={
              !formik.errors.otpCode && formik.values.otpCode.length === 4 && !validationError
            }
            onFocus={() => dispatch(clearValidationError())}
            padding={{ left: "15px", top: "22px" }}
          />
        </Box>
        <Box m={2} gap={1} display="flex">
          {validationError && (
            <Typography variant="caption" color={(theme) => theme.veris.colors.errors.normal}>
              {validationError}
            </Typography>
          )}
          <Typography
            variant="caption"
            sx={{ textDecoration: "underline", cursor: "pointer" }}
            color={(theme) => theme.veris.colors.amethyst.normal}
            onClick={() => onResendCode()}
          >
            {validationError ? "Didn't get the code?" : "Resend OTP code?"}
          </Typography>
        </Box>
        <Box mt={5} ml={2}>
          <VrsButton
            buttonType="primary"
            type="submit"
            variant="contained"
            color="primary"
            onClick={formik.submitForm}
            disabled={
              !formik.isValid || !formik.touched || !formik.dirty || authStatus === "loading"
            }
          >
            Submit
            {authStatus === "loading" && (
              <CircularProgress sx={{ marginLeft: (theme) => theme.spacing(2) }} size={16} />
            )}
          </VrsButton>
        </Box>
      </form>
    </Box>
  );
};
