import React from "react";
import { Grid, Typography } from "@mui/material";
import {
  IconName,
  VrsButton,
  VrsDivider,
  VrsIcon,
  verisColors,
  dateFormats,
} from "@veris-health/web-core";
import dayjs from "dayjs";
import { TCMBillingCodes, UnconfirmedCallInfo } from "@veris-health/communication-ms/lib/v1";
import { utcToLocal } from "../../../../utils/date";
import { VideoBillingOptionSelector } from "../VideoBillingOptionSelector";
import { VideoBillingParticipants } from "../VideoBillingParticipants";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { selectBillingCodes, selectBillingCodesStatus } from "../../../shared/slices/voipSlice";
import { TCMCalendar } from "../../../PatientDetails/components/TCMTime/TCMDateCalendar";
import { CodesToDays } from "../../../PatientDetails/components/TCMTime/AddTCM";

export interface VideoBillingModalBodyProps {
  callDetails: UnconfirmedCallInfo;
  setSelectedCode: (code: string) => void;
  selectedCode?: string;
  setDischargeDate: (date: string) => void;
  dischargeDate?: string;
  onConfirmClick: () => void;
}
const getCallDuration = (duration: string) => {
  const callDurationHours = dayjs.duration(+duration, "minutes").hours();

  if (callDurationHours > 0) {
    const callDurationFormatted = dayjs
      .duration(+duration, "minutes")
      .format(dateFormats["HH:mm:ss"]);
    return (
      <>
        <Typography variant="subtitle1">&nbsp; {callDurationFormatted} &nbsp; </Typography>
        <Typography variant="subtitle2">hours.</Typography>
      </>
    );
  }
  const callDurationFormatted = dayjs.duration(+duration, "minutes").format(dateFormats["mm:ss"]);
  return (
    <>
      <Typography variant="subtitle1">&nbsp; {callDurationFormatted} &nbsp; </Typography>
      <Typography variant="subtitle2">minutes.</Typography>
    </>
  );
};
export function VideoBillingModalBody({
  callDetails,
  selectedCode,
  setSelectedCode,
  onConfirmClick,
  dischargeDate,
  setDischargeDate,
}: VideoBillingModalBodyProps): JSX.Element | null {
  const billingCodes = useAppSelector(selectBillingCodes);
  const billingCodesStatus = useAppSelector(selectBillingCodesStatus);

  const initialDate = utcToLocal(callDetails.start_time);
  const endDate = utcToLocal(callDetails.end_time);

  const disableSubmit =
    !selectedCode ||
    billingCodesStatus === "loading" ||
    callDetails.confirmed_status ||
    (Object.values(TCMBillingCodes).includes(selectedCode as TCMBillingCodes) && !dischargeDate);

  return (
    <>
      <Grid item xs={12} mb={3}>
        <Typography variant="subtitle24">
          Your video appointment with patient {callDetails.patient?.full_name} ended. <br />
          Here are below the details of the call.
        </Typography>
      </Grid>

      <Grid item container xs={12} mb={3} alignItems="center">
        <Grid item xs={1}>
          <VrsIcon
            name={IconName.Calendar}
            stroke={verisColors.neutrals["grey-4"]}
            sx={{ strokeWidth: 1 }}
          />
        </Grid>
        <Grid
          item
          container
          xs={10}
          sx={{
            backgroundColor: (theme) => `${theme.veris.colors.neutrals["grey-1"]}`,
            p: 2,
            borderRadius: (theme) => theme.spacing(1),
          }}
        >
          <Typography
            variant="subtitle2"
            mr={1}
            sx={{
              color: (theme) => `${theme.veris.colors.neutrals["grey-3"]}`,
            }}
          >
            Date
          </Typography>
          <Typography
            variant="subtitle2"
            mr={1}
            sx={{
              ml: 1,
            }}
          >
            {initialDate.format(dateFormats["dddd, MMMM DD, YYYY"])}
          </Typography>
        </Grid>
      </Grid>

      <Grid container item mb={3} alignItems="center">
        <Grid item xs={1}>
          <VrsIcon name={IconName.CallDuration} color={verisColors.neutrals["grey-4"]} />
        </Grid>
        <Grid
          container
          item
          sx={{
            backgroundColor: (theme) => `${theme.veris.colors.neutrals["grey-1"]}`,
            p: 2,
            borderRadius: (theme) => theme.spacing(1),
          }}
          xs={10}
        >
          <Grid item display="flex">
            <Typography
              variant="subtitle2"
              mr={1}
              sx={{ color: (theme) => `${theme.veris.colors.neutrals["grey-3"]}` }}
            >
              Start time:
            </Typography>
            <Typography variant="subtitle2">
              {initialDate.format(`${dateFormats["h:mm"]} A`)}
            </Typography>
          </Grid>
          <Grid item display="flex">
            <Typography
              variant="subtitle2"
              mr={1}
              ml={2}
              sx={{ color: (theme) => `${theme.veris.colors.neutrals["grey-3"]}` }}
            >
              End time:
            </Typography>
            <Typography variant="subtitle2">
              {endDate.format(`${dateFormats["h:mm"]} A`)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item container xs={12} mb={3}>
        <Grid item xs={1} />
        <Grid item xs={10} display="flex">
          <Typography variant="subtitle2">Your call had a duration of </Typography>
          {getCallDuration(callDetails.duration)}
        </Grid>
      </Grid>

      <Grid item container xs={12} alignItems="center" mb={2}>
        <Grid item xs={1}>
          <VrsIcon name={IconName.Participants} color={verisColors.neutrals["grey-4"]} />
        </Grid>
        <Grid item xs={10}>
          <Typography
            variant="body"
            color={verisColors.neutrals["grey-4"]}
          >{`${callDetails.participants.length} Participants`}</Typography>
        </Grid>
      </Grid>

      <VideoBillingParticipants participants={callDetails.participants} />

      <Grid item xs={12} mb={3}>
        <VrsDivider height="2px" orientation="horizontal" />
      </Grid>

      <VideoBillingOptionSelector
        selectedCode={selectedCode}
        setSelectedCode={setSelectedCode}
        billingCodes={billingCodes}
      />

      {selectedCode && Object.values(TCMBillingCodes).includes(selectedCode as TCMBillingCodes) && (
        <Grid item xs={12}>
          <TCMCalendar
            title="Date of discharge:"
            value={dischargeDate}
            onChange={(date) => setDischargeDate(date)}
            min={dayjs(initialDate)
              .endOf("day")
              .subtract(
                selectedCode ? CodesToDays[selectedCode as TCMBillingCodes] - 1 : 13,
                "days",
              )
              .format()}
            max={dayjs(initialDate).endOf("day").format()}
          />
        </Grid>
      )}

      <Grid item mt={8}>
        <VrsButton buttonType="primary" onClick={onConfirmClick} disabled={disableSubmit}>
          Confirm billing
        </VrsButton>
      </Grid>
    </>
  );
}
