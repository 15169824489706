import { TableHead, TableRow, TableSortLabel, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { VrsTooltip, verisColors } from "@veris-health/web-core";
import { Order } from "../../../utils/sorting";
import { StyledTableCell } from "../Tables/shared/StyledTableElements";

export const enum TableColumnNames {
  Problem = "Problem",
  AllStatus = "All Status",
  MedicationName = "Medication Name",
  Dose = "Dose",
  Schedule = "Schedule",
  GoalName = "Goal Name",
  GoalCategory = "Category",
  GoalDateCreated = "Date Created",
  GoalDueDate = "Due Date",
  RouteOfAdministration = "Route Of Administration",
  Prescriber = "Prescriber",
  WhenPrescribed = "When Prescribed",
  NumberDispensed = "Number Dispensed",
  NumberOfRefills = "Number of Refills",
  GoalStatus = "All Status",
  GoalType = "Type",
  PatientDetails = "Patient Details",
  QOL = "Quality Of Life",
  Trend = "Trend",
  Symptoms = "Symptoms",
  Tags = "Tags",
  LastInfusion = "LastInfusion",
  ICD = "ICD 10 Code",
  QofLTrend = "QoL Trend",
  MemberFirstName = "First Name",
  MemberLastName = "Last Name",
  MemberProfession = "Profession",
  HospitalName = "Hospital",
  Placeholder = "",
  AdherenceChart = "Adherence",
  ClinicalTime = "Clinical Time",
  ActiveSince = "Active Since",
  LastDischargedSigned = "Last Discharged Signed",
  TCMCode = "TCM Code",
  DateOccurred = "Date Occurred",
}

export interface Column {
  id: TableColumnNames;
  label: TableColumnNames;
  orderBy?: string;
  hideOnSmallScreen?: boolean;
  width?: string;
}

export interface VrsSortedTableHeaderProps {
  columns: Column[];
  sortTableData: (order: Order, orderBy: string) => void;
  styles?: React.CSSProperties;
  tooltipContent?: { id: TableColumnNames; content: string | JSX.Element }[];
  isSmallScreen?: boolean;
}

export function VrsSortedTableHeader({
  columns,
  sortTableData,
  styles,
  tooltipContent,
  isSmallScreen,
}: VrsSortedTableHeaderProps): JSX.Element {
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState("");

  useEffect(() => {
    sortTableData(order, orderBy);
  }, [order, orderBy]);

  return (
    <TableHead
      sx={{
        position: "sticky",
        top: (theme) => theme.spacing(-2),
        backgroundColor: (theme) => theme.veris.colors.neutrals.white,
        borderBottom: (theme) => `1px solid ${theme.veris.colors.neutrals["grey-2"]}`,
        ...styles,
      }}
      data-test-hook="vrsSortedTableHeader-element"
    >
      <TableRow>
        {columns.map((column) => (
          <React.Fragment key={column.id}>
            {column.hideOnSmallScreen && isSmallScreen ? (
              <></>
            ) : (
              <StyledTableCell>
                <TableSortLabel
                  hideSortIcon={!column.orderBy}
                  active={orderBy === column.orderBy}
                  direction={orderBy === column.orderBy ? order : "asc"}
                  onClick={() => {
                    if (column.orderBy) {
                      setOrderBy(column.orderBy);
                      setOrder(order === "asc" ? "desc" : "asc");
                    }
                  }}
                  style={{
                    cursor: column.orderBy ? "pointer" : "default",
                  }}
                >
                  <VrsTooltip
                    bcgcolor={verisColors.neutrals.black}
                    title={
                      (tooltipContent?.find((data) => data.id === column.id) &&
                        tooltipContent?.find((data) => data.id === column.id)?.content) ||
                      ""
                    }
                    placement="bottom"
                  >
                    <Typography
                      variant="subtitle24"
                      component="span"
                      pt={0.5}
                      sx={{
                        textTransform: "uppercase",
                        fontWeight: 500,
                        color: (theme) => theme.veris.colors.neutrals["grey-3"],
                      }}
                    >
                      {column.label}
                    </Typography>
                  </VrsTooltip>
                </TableSortLabel>
              </StyledTableCell>
            )}
          </React.Fragment>
        ))}
      </TableRow>
    </TableHead>
  );
}
