import { Box, Checkbox, Typography } from "@mui/material";
import { IconName, VrsIcon } from "@veris-health/web-core";
import React from "react";

export interface ExportSectionProps {
  iconName: IconName;
  title: string;
  include: boolean;
  onInclude: () => void;
  children?: React.ReactNode;
}

export function ExportSection({
  iconName,
  title,
  include,
  onInclude,
  children,
}: ExportSectionProps): JSX.Element {
  return (
    <Box pt={5}>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" alignItems="center" gap={2}>
          <VrsIcon name={iconName} size={24} />
          <Typography variant="h6Semibold">{title}</Typography>
        </Box>

        <Box display="flex" alignItems="center" gap={1}>
          <Typography variant="body" onClick={onInclude}>
            Include
          </Typography>
          <Checkbox checked={include} onClick={onInclude} />
        </Box>
      </Box>
      {children}
    </Box>
  );
}
