import React from "react";
import { EditorProps as DraftJsEditorProps, DraftHandleValue, EditorState } from "draft-js";
import DraftJsEditor, { EditorPlugin } from "@draft-js-plugins/editor";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import createMarkdownShortcutsPlugin from "draft-js-markdown-shortcuts-plugin";
import createLinkifyPlugin from "@draft-js-plugins/linkify";

import createLinkDetectionPlugin from "draft-js-link-detection-plugin";
import "@draft-js-plugins/linkify/lib/plugin.css";
import { styled, Typography } from "@mui/material";
import { ComponentProps } from "@draft-js-plugins/linkify/lib/Link/Link";
import { verisColors } from "@veris-health/web-core";
import createTextBlockPlugin from "./TextBlock";
import createListItemBlockPlugin from "./ListItemBlock";
import "draft-js/dist/Draft.css";
import EditorUtils from "./EditorUtils";
import { createShortcutsPlugin } from "./Shortcuts";
import { createLinkPlugin } from "./Link/Link";
import { handleRichPastedText } from "../../../utils/cnp";

interface EditorContainerProps {
  backgroundColor?: string;
}

export const EditorContainer = styled("div", {
  shouldForwardProp: (prop: string) => prop !== "backgroundColor",
})<EditorContainerProps>(({ theme, backgroundColor }) => ({
  height: "294px",
  position: "relative",
  overflowY: "auto",
  marginTop: theme.spacing(1),
  paddingRight: theme.spacing(0.5),
  ".DraftEditor-editorContainer": {
    backgroundColor: backgroundColor || theme.veris.colors.neutrals.white,
  },
  ".public-DraftEditor-content": {
    paddingBottom: "1rem",
    color: theme.veris.colors.neutrals.black,
  },
  a: {
    color: verisColors.amethyst.normal,
  },
}));

export interface EditorProps extends Omit<DraftJsEditorProps, "onChange">, EditorContainerProps {
  readOnly: boolean;
  editorRef?: React.RefObject<DraftJsEditor>;
  onChange?: (editorState: EditorState) => void;
  maxLength?: number;
}

export class Editor extends React.Component<EditorProps> {
  plugins: EditorPlugin[];

  constructor(props: EditorProps) {
    super(props);
    this.plugins = [
      {
        handlePastedText: handleRichPastedText,
      },
      createLinkDetectionPlugin(),
      createLinkifyPlugin({
        // eslint-disable-next-line @typescript-eslint/no-shadow
        component: ({ href, children }: ComponentProps) => {
          return (
            <a
              style={{ color: verisColors.amethyst.normal }}
              href={href}
              onClick={() => {
                window.open(href, "_blank");
              }}
              rel="noreferrer"
            >
              {children}
            </a>
          );
        },
      }),
      createTextBlockPlugin(),
      createListItemBlockPlugin(),
      createMarkdownShortcutsPlugin(),
      createShortcutsPlugin(),
      createLinkPlugin(),
    ];
  }

  componentDidCatch(): void {
    this.forceUpdate();
  }

  handleBeforeInput = (): DraftHandleValue => {
    const { editorState, maxLength } = this.props;
    if (maxLength) {
      const currentContent = editorState.getCurrentContent();
      const currentContentLength = currentContent.getPlainText("").length;
      const selectedTextLength = EditorUtils.getLengthOfSelectedText(editorState);

      if (currentContentLength - selectedTextLength > maxLength - 1) {
        return "handled";
      }
    }

    return "not-handled";
  };

  handlePastedText = (pastedText: string): DraftHandleValue => {
    const { editorState, maxLength } = this.props;
    if (maxLength) {
      const currentContent = editorState.getCurrentContent();
      const currentContentLength = currentContent.getPlainText("").length;
      const selectedTextLength = EditorUtils.getLengthOfSelectedText(editorState);

      if (currentContentLength + pastedText.length - selectedTextLength > maxLength) {
        return "handled";
      }
    }

    return "not-handled";
  };

  render(): JSX.Element {
    const { editorState, readOnly, editorRef, backgroundColor, ...rest } = this.props;
    const currentContent = editorState?.getCurrentContent();
    const isEmpty =
      !currentContent ||
      (currentContent.getBlocksAsArray().length <= 1 &&
        (currentContent.getFirstBlock()?.getText().length ?? 0) === 0 &&
        currentContent.getFirstBlock()?.getType() === "unstyled");
    return (
      <EditorContainer backgroundColor={backgroundColor}>
        {editorState && (
          <DraftJsEditor
            spellCheck
            ref={editorRef}
            readOnly={readOnly}
            editorState={editorState}
            handlePastedText={this.handlePastedText}
            handleBeforeInput={this.handleBeforeInput}
            plugins={this.plugins}
            {...rest}
          />
        )}
        {isEmpty && (
          <Typography
            color={verisColors.neutrals["grey-2"]}
            variant="body"
            sx={{
              position: "absolute",
              display: "block",
              top: 2,
              userSelect: "none",
              pointerEvents: "none",
              zIndex: 1000,
              fontSize: "18px",
              marginLeft: "2px",
            }}
          >
            Write your note here...
          </Typography>
        )}
      </EditorContainer>
    );
  }
}
