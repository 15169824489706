import { Box, Typography } from "@mui/material";
import {
  AnswerItem,
  BodyPartAnswerItem,
  QuestionType,
  QuestionViewType,
  QuestionnaireView,
  ReportAnswer,
} from "@veris-health/virtual-doc-ms/lib/v1";
import React from "react";
import { VrsButton, dateFormats } from "@veris-health/web-core";
import { isArray } from "lodash";
import dayjs from "dayjs";
import { VrsReportQADetails } from "../../../../Notifications/components/VrsReportQADetails";

interface SymptomReportReviewProps {
  questions: { [key: string]: QuestionnaireView };
  answers?: { [key: string]: AnswerItem[] };
  handleSymptomSubmit: () => void;
}

export const SymptomReportReview = ({
  questions,
  answers,
  handleSymptomSubmit,
}: SymptomReportReviewProps): JSX.Element => {
  const formatAnswer = (
    answer: string | number | string[] | BodyPartAnswerItem[],
    type?: QuestionType,
  ) => {
    if (isArray(answer) && typeof answer[0] !== "string") {
      if ("body_part" in (answer as BodyPartAnswerItem[])[0])
        return answer.map((bodyPart) => {
          if (typeof bodyPart !== "string") return bodyPart.body_part;
          return bodyPart;
        });
    }

    if (type === QuestionType.Datetime && typeof answer === "string") {
      return dayjs(answer).format(`${dateFormats["ddd MMM D, YYYY"]} ${dateFormats["hh:mm"]} A`);
    }

    return answer;
  };
  return (
    <Box>
      <Box display="flex">
        <Typography variant="h6" color={(theme) => theme.veris.colors.neutrals["grey-3"]} my={1}>
          Your symptom report is ready to submit{" "}
        </Typography>{" "}
        <Box display="flex" gap={2} pb={2}>
          {Object.keys(questions).map((value) => (
            <Typography
              key={value}
              variant="h6"
              color={(theme) => theme.veris.colors.amethyst.normal}
              py={1}
              px={2}
              mx={1}
              sx={{
                backgroundColor: (theme) => theme.veris.colors.amethyst.light,
                width: "fit-content",
                borderRadius: (theme) => theme.spacing(2),
                "&:first-letter": { textTransform: "capitalize" },
              }}
            >
              {value.replaceAll("-", " ")}
            </Typography>
          ))}
        </Box>
      </Box>
      <Box>
        {Object.keys(questions).map((key) => {
          return (
            answers &&
            answers[key].map((answer) => {
              const question = questions[key].questions.questions.find(
                (q) => q.id === answer.question_id,
              );

              return (
                <VrsReportQADetails
                  key={answer.question_id}
                  reportAnswer={
                    {
                      questionId: answer.question_id,
                      questionType: question?.type || QuestionType.FreeTextField,
                      view: question?.view || QuestionViewType.Button,
                      question: question?.question ?? "",
                      answer: {
                        value: formatAnswer(answer.answer, question?.type),
                        label: formatAnswer(answer.answer, question?.type),
                      },
                    } as ReportAnswer
                  }
                />
              );
            })
          );
        })}
      </Box>
      <VrsButton
        buttonType="primary"
        sx={{ float: "right", marginTop: (theme) => theme.spacing(2) }}
        onClick={handleSymptomSubmit}
      >
        Submit
      </VrsButton>
    </Box>
  );
};
