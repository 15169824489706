import React from "react";
import { Box, Chip, Grid, Typography } from "@mui/material";
import {
  IconName,
  VrsAvatar,
  VrsIcon,
  VrsIconButton,
  VrsTooltip,
  dateFormats,
  verisColors,
} from "@veris-health/web-core";
import {
  ClinicalTask,
  ClinicalTaskPriorityEnum,
  ClinicalTaskStatusEnum,
  ClinicalTaskTypeEnum,
  ClinicalTaskUserInfo,
} from "@veris-health/med-data-ms/lib/v1";
import dayjs from "dayjs";
import { VrsSingleTag } from "../../../../ui/components/VrsTags";
import { VrsStackedAvatars } from "../../../../ui/components/VrsStackedAvatars";
import { calculateTimeFromNow, utcToLocal } from "../../../../utils/date";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { selectAvatarById } from "../../../shared/slices/patientsSlice";

const StackedAvatarsWithTooltip = ({ sharedTeam }: { sharedTeam: ClinicalTaskUserInfo[] }) => {
  return (
    <VrsTooltip
      bcgcolor={verisColors.neutrals.black}
      title={
        <Box
          sx={{
            overflowY: "scroll",
            maxHeight: "200px",
            cursor: "default",
          }}
          p={0.5}
        >
          {sharedTeam.map((member) => (
            <Box display="flex" alignItems="center" gap={1} pb={1} key={member.user_id} pt={1}>
              <Typography variant="subtitle2" color={(theme) => theme.veris.colors.neutrals.white}>
                {member.full_name}
              </Typography>
            </Box>
          ))}
        </Box>
      }
    >
      <Box display="flex" sx={{ border: "none" }}>
        <VrsStackedAvatars
          avatars={
            sharedTeam?.map((el) => {
              return { name: el.full_name || "", id: el.user_id || -1 };
            }) || []
          }
          size={32}
          maxAvatars={4}
        />{" "}
      </Box>
    </VrsTooltip>
  );
};

export const PriorityIcons: Record<
  ClinicalTaskPriorityEnum,
  { name: IconName; stroke: string; fill: string }
> = {
  low: {
    name: IconName.PriorityLow,
    stroke: verisColors.amethyst.normal,
    fill: verisColors.amethyst.light,
  },
  medium: {
    name: IconName.PriorityMedium,
    stroke: verisColors.mango.normal,
    fill: verisColors.mango.light,
  },
  high: {
    name: IconName.PriorityHigh,
    stroke: verisColors.errors.normal,
    fill: verisColors.errors.soft,
  },
};

export interface TaskDetailsProps extends ClinicalTask {
  onClick: () => void;
  onResolve?: () => void;
  isActive?: boolean;
  onPatientClick?: () => void;
  currentUserId?: number;
}

export const TaskDetails = ({
  title,
  type,
  status,
  priority,
  assignee,
  patient,
  description,
  date_created: dateCreated,
  shared_team: sharedTeam,
  due_date: dueDate,
  onClick,
  onResolve,
  isActive,
  onPatientClick,
  currentUserId,
  reporter,
}: TaskDetailsProps): JSX.Element => {
  const avatar = useAppSelector((state) => selectAvatarById(state, patient.user_id));
  const isPast = dayjs(dueDate).isBefore(dayjs());
  const viewMode =
    currentUserId &&
    !sharedTeam.map((el) => el.user_id).includes(currentUserId) &&
    assignee.user_id !== currentUserId &&
    reporter.user_id !== currentUserId;

  return (
    <Box
      sx={{
        border: (theme) =>
          `1px solid ${
            isActive ? theme.veris.colors.amethyst.normal : theme.veris.colors.neutrals["grey-2"]
          }`,
        borderRadius: "8px",
        backgroundColor: (theme) => (isActive ? theme.veris.colors.amethyst.light : "unset"),
        "&:hover": {
          cursor: "pointer",
        },
      }}
      p={1}
      display="flex"
      alignItems="baseline"
      gap={2}
      mb={2}
      onClick={onClick}
    >
      {status === ClinicalTaskStatusEnum.Resolved ? (
        <VrsIconButton
          iconProps={{ name: IconName.Check, size: 15 }}
          sx={{
            border: (theme) => `3px solid ${theme.veris.colors.amethyst.normal}`,
            borderRadius: "50%",
          }}
        />
      ) : (
        <VrsIconButton
          iconProps={{ name: IconName.EmptyCircle }}
          sx={{
            circle: {
              stroke: (theme) =>
                viewMode
                  ? theme.veris.colors.neutrals["grey-2"]
                  : theme.veris.colors.amethyst.normal,
            },
          }}
          onClick={(e) => {
            e.stopPropagation();
            if (onResolve) onResolve();
          }}
          disabled={!!viewMode}
        />
      )}
      <Box pb={2} width="100%">
        <Box display="flex" justifyContent="space-between" alignItems="baseline" pb={1}>
          <Box display="flex" alignItems="baseline" gap={2}>
            <Typography variant="subtitle1">{title}</Typography>
            <VrsSingleTag
              color={verisColors.neutrals["grey-3"]}
              small
              label={type === ClinicalTaskTypeEnum.AbnormalReading ? "Abnormal Vitals" : type}
            />
          </Box>
          <Box display="flex" gap={2}>
            <Box display="flex" alignItems="center" gap={1}>
              <VrsIcon
                name={IconName.Calendar}
                size={16}
                stroke={isPast ? verisColors.errors.normal : verisColors.neutrals["grey-3"]}
              />
              <Typography
                variant="caption"
                color={(theme) =>
                  isPast ? theme.veris.colors.errors.normal : theme.veris.colors.neutrals["grey-3"]
                }
              >
                {dayjs(dueDate).format(dateFormats["MMM DD"])}
              </Typography>
            </Box>

            <Chip
              label={
                <Typography variant="caption" sx={{ textTransform: "capitalize" }}>
                  {status}
                </Typography>
              }
              sx={{
                backgroundColor: (theme) =>
                  status === ClinicalTaskStatusEnum.Resolved
                    ? theme.veris.colors.moderate.light
                    : theme.veris.colors.mango.light,
                color: (theme) =>
                  status === ClinicalTaskStatusEnum.Resolved
                    ? theme.veris.colors.moderate.normal
                    : theme.veris.colors.mango.normal,
                border: (theme) =>
                  status === ClinicalTaskStatusEnum.Resolved
                    ? `1px solid ${theme.veris.colors.moderate.normal}`
                    : `1px solid ${theme.veris.colors.mango.normal}`,
                borderRadius: (theme) => `${theme.shape.borderRadius}px`,
                pt: (theme) => theme.spacing(0.5),
              }}
            />
          </Box>
        </Box>
        <Typography variant="body">{description}</Typography>
        <Box display="flex" py={1} alignItems="center" pr={1}>
          <Grid container item>
            <Grid item xs={3}>
              <Box display="flex" alignItems="center">
                <VrsIcon
                  {...PriorityIcons[priority]}
                  size={18}
                  sx={{
                    border: `1px solid ${PriorityIcons[priority].stroke}`,
                    borderRadius: "50%",
                    backgroundColor: PriorityIcons[priority].fill,
                    width: "32px",
                    height: "32px",
                    padding: (theme) => theme.spacing(1),
                  }}
                />
                <Box pl={2}>
                  <Typography
                    variant="caption"
                    sx={{ textTransform: "uppercase" }}
                    color={(theme) => theme.veris.colors.neutrals["grey-3"]}
                  >
                    Priority
                  </Typography>
                  <Typography
                    variant="body2"
                    color={(theme) => theme.veris.colors.neutrals["grey-4"]}
                    sx={{ textTransform: "capitalize" }}
                  >
                    {priority}
                  </Typography>
                </Box>
              </Box>{" "}
            </Grid>
            <Grid item xs={3}>
              <Box display="flex" alignItems="center">
                <VrsAvatar size={32} name={assignee.full_name} />
                <Box pl={2}>
                  <Typography
                    variant="caption"
                    sx={{ textTransform: "uppercase" }}
                    color={(theme) => theme.veris.colors.neutrals["grey-3"]}
                  >
                    Assigned to
                  </Typography>
                  <Typography
                    variant="body2"
                    color={(theme) => theme.veris.colors.neutrals["grey-4"]}
                  >
                    {assignee.full_name}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box
                display="flex"
                alignItems="center"
                onClick={onPatientClick}
                sx={{
                  "&:hover": {
                    borderRadius: (theme) => theme.spacing(1),
                    cursor: "pointer",
                  },
                }}
                px={2}
              >
                <VrsAvatar size={32} name={patient.full_name} imageUrl={avatar?.picture} />
                <Box pl={2}>
                  <Typography
                    variant="caption"
                    sx={{ textTransform: "uppercase" }}
                    color={(theme) => theme.veris.colors.neutrals["grey-3"]}
                  >
                    Patient
                  </Typography>
                  <Typography
                    variant="body2"
                    color={(theme) => theme.veris.colors.neutrals["grey-4"]}
                  >
                    {patient.full_name}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <StackedAvatarsWithTooltip sharedTeam={sharedTeam} />
            </Grid>
            <Grid item xs={1}>
              <Typography variant="body" color={(theme) => theme.veris.colors.neutrals["grey-3"]}>
                {calculateTimeFromNow(utcToLocal(dateCreated).format())}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};
