import { Table, TableContainer, Typography } from "@mui/material";
import { ActiveMedication } from "@veris-health/med-data-ms/lib/v1";
import dayjs from "dayjs";
import React, { useEffect, useMemo, useState } from "react";
import { dateFormats } from "@veris-health/web-core";
import { EMPTY_DATASET_MESSAGE } from "../../../constants";
import {
  StyledTableBody,
  StyledTableCell,
  StyledTableRow,
} from "../../../ui/components/Tables/shared/StyledTableElements";
import { VrsPatientDetailsDialog } from "../../../ui/components/VrsPatientDetailsDialog";
import {
  Column,
  TableColumnNames,
  VrsSortedTableHeader,
} from "../../../ui/components/VrsSortedTableHeader";
import { genericSorter } from "../../../utils/sorting";

function MedicationCell({ medication, column }: { medication: ActiveMedication; column: Column }) {
  return (
    <StyledTableCell key={column.id}>
      {column.id === TableColumnNames.MedicationName && (
        <Typography
          variant="subtitle1"
          color={(theme) => theme.veris.colors.neutrals["grey-4"]}
          pt={2}
          pb={2}
        >
          {medication.name || "N/A"}
        </Typography>
      )}
      {column.id === TableColumnNames.WhenPrescribed && (
        <Typography
          variant="button"
          component="span"
          sx={{ textTransform: "unset" }}
          color={(theme) => theme.veris.colors.neutrals["grey-4"]}
        >
          {dayjs(medication.prescribedAt).format(dateFormats["MMM D, YYYY"])}
        </Typography>
      )}
      {column.id === TableColumnNames.Dose && (
        <Typography
          variant="button"
          component="span"
          sx={{ textTransform: "unset" }}
          color={(theme) => theme.veris.colors.neutrals["grey-4"]}
        >
          {medication.doses
            ?.map((dose) => (dose?.value && dose.unit && `${dose?.value} ${dose?.unit}`) || "N/A")
            .join(", ") || "N/A"}
        </Typography>
      )}
      {column.id === TableColumnNames.Schedule && (
        <Typography
          variant="button"
          component="span"
          sx={{ textTransform: "unset" }}
          color={(theme) => theme.veris.colors.neutrals["grey-4"]}
        >
          {medication.schedules?.join(", ") || "N/A"}
        </Typography>
      )}
      {column.id === TableColumnNames.RouteOfAdministration && (
        <Typography
          variant="button"
          component="span"
          sx={{ textTransform: "unset" }}
          color={(theme) => theme.veris.colors.neutrals["grey-4"]}
        >
          {medication.routes?.join(", ") || "N/A"}
        </Typography>
      )}
      {column.id === TableColumnNames.Prescriber && (
        <Typography
          variant="button"
          component="span"
          sx={{ textTransform: "unset" }}
          color={(theme) => theme.veris.colors.neutrals["grey-4"]}
        >
          {medication.prescriber || "N/A"}
        </Typography>
      )}
      {column.id === TableColumnNames.NumberDispensed && (
        <Typography
          variant="button"
          component="span"
          sx={{ textTransform: "unset" }}
          color={(theme) => theme.veris.colors.neutrals["grey-4"]}
        >
          {medication.numberDispensed || "N/A"}
        </Typography>
      )}
      {column.id === TableColumnNames.NumberOfRefills && (
        <Typography
          variant="button"
          component="span"
          sx={{ textTransform: "unset" }}
          color={(theme) => theme.veris.colors.neutrals["grey-4"]}
        >
          {medication.numberOfRefillsAllowed || "N/A"}
        </Typography>
      )}
    </StyledTableCell>
  );
}

const columns: Column[] = [
  { id: TableColumnNames.MedicationName, label: TableColumnNames.MedicationName, orderBy: "name" },
  { id: TableColumnNames.Dose, label: TableColumnNames.Dose, orderBy: "doses[0].value" },
  { id: TableColumnNames.Schedule, label: TableColumnNames.Schedule, orderBy: "schedules[0]" },
  {
    id: TableColumnNames.RouteOfAdministration,
    label: TableColumnNames.RouteOfAdministration,
    orderBy: "routes[0]",
  },
  { id: TableColumnNames.Prescriber, label: TableColumnNames.Prescriber, orderBy: "prescriber" },
  {
    id: TableColumnNames.WhenPrescribed,
    label: TableColumnNames.WhenPrescribed,
    orderBy: "prescribedAt",
  },
  {
    id: TableColumnNames.NumberDispensed,
    label: TableColumnNames.NumberDispensed,
    orderBy: "numberDispensed",
  },
  {
    id: TableColumnNames.NumberOfRefills,
    label: TableColumnNames.NumberOfRefills,
    orderBy: "numberOfRefillsAllowed",
  },
];

export interface ActiveMedsDialogProps {
  medications: ActiveMedication[];
  isOpen: boolean;
  patientName: string;
  onClose: () => void;
}

export function ActiveMedsDialog({
  medications,
  isOpen,
  patientName,
  onClose,
}: ActiveMedsDialogProps): JSX.Element {
  const [searchPhase, setSearchPhase] = useState<string>("");
  const [sortedMedications, setSortedMedications] = useState(medications);
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchPhase(event.target.value);
  };
  const filteredMedications = useMemo(() => {
    return searchPhase
      ? medications.filter((medication) =>
          medication?.name?.trim()?.toLowerCase()?.includes(searchPhase.trim().toLowerCase()),
        )
      : medications;
  }, [searchPhase, medications]);

  useEffect(() => {
    setSortedMedications(filteredMedications);
  }, [filteredMedications]);

  return (
    <VrsPatientDetailsDialog
      isOpen={isOpen}
      onClose={onClose}
      title="active medication(s)"
      patientName={patientName}
      searchPhase={searchPhase}
      setSearchPhase={setSearchPhase}
      handleInputChange={handleInputChange}
    >
      <TableContainer
        sx={{
          border: (theme) => `2px solid ${theme.veris.colors.neutrals["grey-2"]}`,
          padding: (theme) => theme.spacing(2),
          paddingTop: 0,
          borderRadius: "6px",
        }}
      >
        <Table>
          <VrsSortedTableHeader
            columns={columns}
            sortTableData={(order, orderBy) =>
              setSortedMedications(
                genericSorter(filteredMedications, order, orderBy as keyof ActiveMedication),
              )
            }
          />
          <StyledTableBody sx={{ maxWidth: "330px", overflow: "scroll" }}>
            {sortedMedications.length === 0 && (
              <StyledTableRow>
                <StyledTableCell colSpan={8}>
                  <Typography
                    variant="body"
                    color={(theme) => theme.veris.colors.neutrals["grey-3"]}
                    pt={2}
                    pb={2}
                    textAlign="center"
                  >
                    {medications.length === 0 ? EMPTY_DATASET_MESSAGE : "No results found"}
                  </Typography>
                </StyledTableCell>
              </StyledTableRow>
            )}
            {sortedMedications.map((medication: ActiveMedication, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <StyledTableRow key={`${medication?.name || ""} ${index}`}>
                {columns.map((column) => {
                  return <MedicationCell column={column} medication={medication} key={column.id} />;
                })}
              </StyledTableRow>
            ))}
          </StyledTableBody>
        </Table>
      </TableContainer>
    </VrsPatientDetailsDialog>
  );
}
