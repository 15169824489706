import { Box, CircularProgress, Grid, Theme, Typography, useMediaQuery } from "@mui/material";
import { VrsErrorMsg } from "@veris-health/web-core";
import React from "react";
import { Status, VrsPatientInfo } from "../../../features/shared/interfaces";
import { VrsPatientInfoCard } from "../VrsPatientInfoCard";

export interface VrsInpatientsProps {
  inpatients: VrsPatientInfo[];
  inpatientsStatus: Status;
  onInpatientsClick?: () => void;
  width?: string;
  onPatientClick: (id: number) => void;
}

export function VrsInpatients({
  inpatients,
  inpatientsStatus,
  onInpatientsClick,
  onPatientClick,
}: VrsInpatientsProps): JSX.Element {
  const isMediumScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("xl"));
  return (
    <Box
      sx={{
        borderRadius: 2,
        border: (theme) => `1px solid ${theme.veris.colors.neutrals["grey-light"]}`,
        background: (theme) => theme.veris.colors.neutrals.white,
        marginBottom: (theme) => theme.spacing(2),
      }}
      padding={(theme) => theme.spacing(0.5, 0.5, 1.13, 0.5)}
    >
      <Grid
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        padding={(theme) => theme.spacing(0.5, 0.625, 1, 0.625)}
      >
        <Typography color={(theme) => theme.veris.colors.neutrals["grey-dark"]} variant="body1">
          INPATIENTS
        </Typography>
      </Grid>

      <Box
        sx={{
          maxHeight: isMediumScreen ? "35vh" : "38vh",
          overflow: "auto",
          padding: (theme) => theme.spacing(0, 1, 0, 0.5),
        }}
      >
        {inpatientsStatus === "loading" && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            data-test-hook="vrsInpatients-loading"
          >
            <CircularProgress />
          </Box>
        )}
        {inpatientsStatus === "idle" &&
          inpatients.length > 0 &&
          inpatients.map((inpatientDetails) => (
            <Box key={inpatientDetails.id} paddingY={(theme) => theme.spacing(0.375)}>
              <VrsPatientInfoCard
                patientInfo={inpatientDetails}
                onClick={onPatientClick}
                styles={{ diagnosis: { maxWidth: "150px" } }}
              />
            </Box>
          ))}
        {inpatientsStatus === "idle" && inpatients.length === 0 && (
          <Typography
            color={(theme) => theme.veris.colors.neutrals["grey-3"]}
            textAlign="center"
            variant="body"
          >
            There are no patients at the moment.
          </Typography>
        )}

        {inpatientsStatus === "failed" && <VrsErrorMsg size="small" onClick={onInpatientsClick} />}
      </Box>
    </Box>
  );
}
