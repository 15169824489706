import { Box, styled, Typography } from "@mui/material";
import { SymptomEvent } from "@veris-health/virtual-doc-ms/lib/v1";
import React from "react";
import { verisColors } from "@veris-health/web-core";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { getCursorCssStyle } from "../../../../utils/symptoms";
import { selectExpandedSymptomDetails } from "../../../shared/slices/uiSlice";
import { SensorsGraphView, ExpandedSymptomEvent } from "../../measurementSlice";
import CustomSymptomTooltip, { SymptomsTooltip } from "./CustomSymptomTooltip";

const getSymptomsPadding = (isPreview: boolean, isSmallScreen?: boolean) => {
  if (isPreview) return undefined;
  if (isSmallScreen) return "2rem";
  return "45px";
};

export interface SymptomEventsProps {
  data: ExpandedSymptomEvent[];
  onSymptomClickHandler: (symptom: SymptomEvent | undefined) => void;
  clickedSymptom: SymptomEvent | undefined;
  isPreview: boolean;
  isSmallScreen?: boolean;
  view: SensorsGraphView;
  isFirst: boolean;
  isLast: boolean;
  summary?: boolean;
}

const SymptomEvents = React.memo(
  ({
    data,
    onSymptomClickHandler,
    clickedSymptom,
    isPreview,
    isSmallScreen,
    view,
    isFirst,
    isLast,
  }: SymptomEventsProps): JSX.Element => {
    const StyledSymptomEvent = styled(Box, {
      shouldForwardProp: (prop: string) => prop !== "entry",
    })<{ entry: ExpandedSymptomEvent }>(({ entry }) => ({
      backgroundColor: entry.style?.fillColor,
      width: "20px",
      height: "20px",
      borderRadius: "100%",
      border: `1px solid ${
        entry.calculatedStatus === "empty" ? verisColors.neutrals["grey-2"] : entry.style?.fillColor
      }`,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: getCursorCssStyle(isPreview, entry.calculatedStatus),
      textAlign: "center",
      boxShadow:
        !isPreview &&
        clickedSymptom &&
        entry?.timestamp === clickedSymptom?.timestamp &&
        entry.timestamp !== ""
          ? `0px 0px 2px 2px ${verisColors.amethyst.normal}`
          : "",
    }));
    const expandedSymptomDetails = useAppSelector(selectExpandedSymptomDetails);
    return (
      <Box
        width="99%"
        height={isPreview ? 50 : 70}
        display="flex"
        justifyContent={view === "monthly" ? "space-between" : "space-around"}
        alignItems="center"
        px={view === "monthly" ? 1 : 0}
        sx={{
          gap: isPreview || view === "monthly" ? 0 : 4,
          paddingLeft: getSymptomsPadding(isPreview, isSmallScreen),
          borderTop: isPreview
            ? "none"
            : `${isFirst ? "2px" : "1px"} solid ${verisColors.neutrals["grey-2"]}`,
          borderBottom: isPreview
            ? "none"
            : `${isLast ? "2px" : "1px"} solid ${verisColors.neutrals["grey-2"]}`,
        }}
      >
        {data.map((entry) => (
          <SymptomsTooltip
            key={entry.key}
            title={<CustomSymptomTooltip symptom={entry} isPreview={isPreview} />}
            bcgcolor={verisColors.neutrals.black}
            placement="bottom"
            padding="0"
            timestamp={entry.timestamp}
            clickedSymptomTimestamp={expandedSymptomDetails?.timestamp}
          >
            <StyledSymptomEvent
              entry={entry}
              onClick={() => {
                if (entry.report_id && entry.timestamp !== "") onSymptomClickHandler(entry);
              }}
            >
              {isPreview ? (
                <Typography
                  color={verisColors.neutrals["grey-5"]}
                  variant="body"
                  sx={{ position: "relative", top: "1.5px" }}
                >
                  {entry.style?.fillValue}
                </Typography>
              ) : (
                <Typography variant="subtitle1" pt={0.5}>
                  {entry.style?.fillValue}
                </Typography>
              )}
            </StyledSymptomEvent>
          </SymptomsTooltip>
        ))}
      </Box>
    );
  },
);

export default SymptomEvents;
