import React, { HTMLAttributes, useEffect, useState } from "react";
import { DataSourceModel } from "@veris-health/user-ms/lib/v2";
import { VrsPatientInfo } from "../../../features/shared/interfaces";
import { VrsSearchBar } from "../VrsSearchBar";

export type AutoCompleteOptionType =
  | VrsPatientInfo
  | { name: string; id: number }
  | { name: string; id: number; hospital: { id: number }; dataSources: DataSourceModel[] };

export type AutoCompleteOption<T extends AutoCompleteOptionType> = {
  label: string;
  value: T;
};

interface VrsPatientSearchProps<T extends AutoCompleteOptionType> {
  data: T[];
  onChange: (event: React.SyntheticEvent, value?: AutoCompleteOption<T>) => void;
  id: string;
  onBlur?: React.FocusEventHandler<HTMLDivElement>;
  predefinedValue?: AutoCompleteOption<T>;
  disabled?: boolean;
  placeholder?: string;
  renderOption?: (
    props: HTMLAttributes<HTMLLIElement>,
    option: AutoCompleteOption<T>,
  ) => React.ReactNode;
}

function VrsMembersSearch<T extends AutoCompleteOptionType>({
  onChange,
  data,
  id,
  onBlur,
  predefinedValue,
  disabled,
  placeholder,
  renderOption,
  ...rest
}: VrsPatientSearchProps<T>) {
  const [inputValue, setInputValue] = useState<string>(predefinedValue?.label || "");
  useEffect(() => {
    if (predefinedValue?.label) setInputValue(predefinedValue?.label);
  }, [predefinedValue]);
  return (
    <VrsSearchBar<T>
      {...rest}
      id={id}
      placeholder={placeholder}
      inputValue={inputValue}
      onChange={onChange}
      onInputChange={(v) => setInputValue(v)}
      options={data.map((patient) => ({
        value: patient,
        label: patient.name,
      }))}
      renderOption={renderOption}
      clearIcon={<></>}
      freeSolo
      onBlur={onBlur}
      predefinedValue={predefinedValue}
      disabled={disabled}
    />
  );
}

export default VrsMembersSearch;
