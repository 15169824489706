import { createAsyncThunk, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  GetSpokenLanguage,
  HospitalMedStaff,
  MedProfessionItem,
  MedStaffProfileItem,
  ModelError,
  SetMedStaffProfile,
  SpokenLanguage,
  UserLanguages,
} from "@veris-health/user-ms/lib/v1";
import axios from "axios";
import { RootState } from "../../store";
import {
  fetchDoctorProfileData,
  getLanguages,
  fetchDoctorSpokenLanguages,
  getProfessions,
  updateDoctorsProfileData,
  setDoctorsSpokenLanguages,
  editPhoneNumber,
  confirmEditedPhoneNumber,
} from "./api/doctorProfileApi";
import { Status, VrsMedStaffProfileModel } from "../shared/interfaces";
import { localizedLogout, logout } from "../shared/slices/authSlice";
import { getRelatedStaff } from "../Hospitals/api/hospitalsApi";

export const enum TeamMemberAvailabilityStatus {
  Available = "Available",
  Unavailable = "Unavailable",
}

export const enum HopistalWorkStatus {
  Currently = "Currently",
  Past = "Past",
}

export interface DoctorsProfileState {
  hospitals: {
    hospitalsData: HospitalMedStaff[];
    status: Status;
  };
  doctorData: {
    doctorData: VrsMedStaffProfileModel;
    otpValidation?: string;
    status: Status;
  };
  professions: {
    data: MedProfessionItem[];
    status: Status;
  };
  languages: {
    data: GetSpokenLanguage[];
    status: Status;
  };
  roleFilter?: string;
  hospitalFilter?: number;
  selectedMedicalTeamMember?: MedStaffProfileItem;
  spokenLanguages: {
    data: SpokenLanguage[];
    status: Status;
  };
}

const initialState: DoctorsProfileState = {
  hospitals: {
    hospitalsData: [],
    status: "idle",
  },
  doctorData: {
    doctorData: {} as VrsMedStaffProfileModel,
    status: "idle",
  },
  professions: {
    data: [],
    status: "idle",
  },
  languages: {
    data: [],
    status: "idle",
  },
  spokenLanguages: {
    data: [],
    status: "idle",
  },
};

export const loadHospitalsTeamAsync = createAsyncThunk(
  "doctorsProfile/fetchHospitalsTeamData",
  async ({ id }: { id: number }) => {
    const response = await getRelatedStaff(id);
    return response;
  },
);

export const fetchDoctorProfileDataAsync = createAsyncThunk(
  "doctorDemogrpahics/fetchDoctorProfileData",
  async ({ id }: { id: number }) => {
    const response = await fetchDoctorProfileData(id);
    return response;
  },
);

export const fetchDoctorSpokenLanguagesAsync = createAsyncThunk(
  "doctorDemogrpahics/fetchDoctorSpokenLanguagesAsync",
  async ({ id }: { id: number }) => {
    const response = await fetchDoctorSpokenLanguages(id);
    return response;
  },
);

export const updateDocAccountDataAsync = createAsyncThunk(
  "doctorDemogrpahics/updateDocAccountData",
  async ({ id, data }: { id: number; data: SetMedStaffProfile }, { dispatch }) => {
    await updateDoctorsProfileData(id, data).then(() =>
      dispatch(fetchDoctorProfileDataAsync({ id })),
    );
  },
);

export const editPhoneNumberAsync = createAsyncThunk<
  unknown,
  { id: number; phoneNumber: string },
  {
    rejectValue: {
      status: number;
      message: string;
    };
  }
>(
  "doctorDemogrpahics/editPhoneNumber",
  async ({ id, phoneNumber }: { id: number; phoneNumber: string }, { rejectWithValue }) => {
    try {
      const response = await editPhoneNumber(id, phoneNumber);
      return response;
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        const { response } = error;
        if (!response) {
          throw error;
        }
        const { data, status } = response;
        return rejectWithValue({
          status,
          message: (data as ModelError).message || "Something went wrong. Please try again",
        });
      }
      throw error;
    }
  },
);

export const confirmEditedPhoneNumberAsync = createAsyncThunk<
  unknown,
  { id: number; phoneNumber: string; otpCode: string },
  {
    rejectValue: {
      status: number;
      message: string;
    };
  }
>(
  "doctorDemogrpahics/updateDocAccountData",
  async (
    { id, phoneNumber, otpCode }: { id: number; phoneNumber: string; otpCode: string },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const response = await confirmEditedPhoneNumber(id, phoneNumber, otpCode).then(() =>
        dispatch(fetchDoctorProfileDataAsync({ id })),
      );
      return response;
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        const { response } = error;
        if (!response) {
          throw error;
        }
        const { data, status } = response;
        return rejectWithValue({
          status,
          message: (data as ModelError).message || "Something went wrong. Please try again",
        });
      }
      throw error;
    }
  },
);

export const fetchProfessionsAsync = createAsyncThunk(
  "doctorsProfile/fetchProfessions",
  async () => {
    const response = await getProfessions();
    return response;
  },
);

export const fetchLanguagesAsync = createAsyncThunk("doctorsProfile/fetchLanguages", async () => {
  const response = await getLanguages();
  return response;
});

export const setDoctorsLanguagesAsync = createAsyncThunk(
  "doctorsProfile/setDoctorsLanguages",
  async ({ id, data }: { id: number; data: UserLanguages }, { dispatch }) => {
    await setDoctorsSpokenLanguages(id, data);
    await dispatch(fetchDoctorSpokenLanguagesAsync({ id }));
  },
);
export const doctorsProfileSlice = createSlice({
  name: "Doctors Profile",
  initialState,
  reducers: {
    setRoleFilter: (state, action: PayloadAction<string>) => {
      state.roleFilter = action.payload;
    },
    setDoctorData: (state, action: PayloadAction<VrsMedStaffProfileModel>) => {
      state.doctorData.doctorData = action.payload;
    },
    setMedicalStaffNameFilter: (state, action: PayloadAction<MedStaffProfileItem | undefined>) => {
      state.selectedMedicalTeamMember = action.payload;
    },
    setHospitalFilter: (state, action: PayloadAction<number | undefined>) => {
      state.hospitalFilter = action.payload;
    },
    clearOtpError: (state) => {
      state.doctorData.otpValidation = undefined;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(loadHospitalsTeamAsync.pending, (state) => {
        state.hospitals.status = "loading";
      })
      .addCase(loadHospitalsTeamAsync.fulfilled, (state, action) => {
        state.hospitals.status = "idle";
        state.hospitals.hospitalsData = action.payload;
      })
      .addCase(loadHospitalsTeamAsync.rejected, (state) => {
        state.hospitals.status = "failed";
      })
      .addCase(fetchDoctorProfileDataAsync.pending, (state) => {
        state.doctorData.status = "loading";
      })
      .addCase(fetchDoctorProfileDataAsync.fulfilled, (state, action) => {
        state.doctorData.status = "idle";
        state.doctorData.doctorData = action.payload;
      })
      .addCase(fetchDoctorProfileDataAsync.rejected, (state) => {
        state.doctorData.status = "failed";
      })
      .addCase(fetchProfessionsAsync.pending, (state) => {
        state.professions.status = "loading";
      })
      .addCase(fetchProfessionsAsync.fulfilled, (state, action) => {
        state.professions.status = "idle";
        state.professions.data = action.payload;
      })
      .addCase(fetchProfessionsAsync.rejected, (state) => {
        state.professions.status = "failed";
      })
      .addCase(fetchLanguagesAsync.pending, (state) => {
        state.languages.status = "loading";
      })
      .addCase(fetchLanguagesAsync.fulfilled, (state, action) => {
        state.languages.status = "idle";
        state.languages.data = action.payload;
      })
      .addCase(fetchLanguagesAsync.rejected, (state) => {
        state.languages.status = "failed";
      })
      .addCase(fetchDoctorSpokenLanguagesAsync.pending, (state) => {
        state.spokenLanguages.status = "loading";
      })
      .addCase(fetchDoctorSpokenLanguagesAsync.fulfilled, (state, action) => {
        state.spokenLanguages.status = "idle";
        state.spokenLanguages.data = action.payload;
      })
      .addCase(fetchDoctorSpokenLanguagesAsync.rejected, (state) => {
        state.spokenLanguages.status = "failed";
      })
      .addCase(setDoctorsLanguagesAsync.pending, (state) => {
        state.spokenLanguages.status = "loading";
      })
      .addCase(setDoctorsLanguagesAsync.fulfilled, (state) => {
        state.spokenLanguages.status = "idle";
      })
      .addCase(setDoctorsLanguagesAsync.rejected, (state) => {
        state.spokenLanguages.status = "failed";
      })
      .addCase(confirmEditedPhoneNumberAsync.rejected, (state, { payload }) => {
        state.doctorData.otpValidation = payload?.message;
      })
      .addCase(confirmEditedPhoneNumberAsync.fulfilled, (state) => {
        state.doctorData.otpValidation = undefined;
      })
      .addCase(logout, () => {
        return initialState;
      })
      .addCase(localizedLogout, () => {
        return initialState;
      });
  },
});
export const {
  setRoleFilter,
  setDoctorData,
  setMedicalStaffNameFilter,
  setHospitalFilter,
  clearOtpError,
} = doctorsProfileSlice.actions;

export const selectHospitals = ({ doctorsProfile }: RootState): HospitalMedStaff[] =>
  doctorsProfile.hospitals.hospitalsData;

export const selectHospitalsStatus = ({ doctorsProfile }: RootState): Status =>
  doctorsProfile.hospitals.status;

export const selectHospitalsFilter = ({ doctorsProfile }: RootState): number | undefined =>
  doctorsProfile.hospitalFilter;

export const selectMedicalTeamRoleFilter = ({ doctorsProfile }: RootState): string | undefined =>
  doctorsProfile.roleFilter;

export const selectMedicalTeamMemberFilter = ({
  doctorsProfile,
}: RootState): MedStaffProfileItem | undefined => doctorsProfile.selectedMedicalTeamMember;

export const selectDoctorData = ({ doctorsProfile }: RootState): VrsMedStaffProfileModel => {
  return doctorsProfile.doctorData.doctorData;
};

export const selectDoctorSpokenLanguages = ({ doctorsProfile }: RootState): SpokenLanguage[] => {
  return doctorsProfile.spokenLanguages.data;
};

export const selectDoctorSpokenLanguagesStatus = ({ doctorsProfile }: RootState): Status => {
  return doctorsProfile.spokenLanguages.status;
};

export const selectDoctorDataStatus = ({ doctorsProfile }: RootState): Status => {
  return doctorsProfile.doctorData.status;
};

export const selectDoctorEditingValidation = ({
  doctorsProfile,
}: RootState): string | undefined => {
  return doctorsProfile.doctorData.otpValidation;
};

export const selectProfessions = ({ doctorsProfile }: RootState): MedProfessionItem[] => {
  return doctorsProfile.professions.data;
};

export const selectProfessionsStatus = ({ doctorsProfile }: RootState): Status => {
  return doctorsProfile.professions.status;
};

export const selectLanguages = ({ doctorsProfile }: RootState): GetSpokenLanguage[] => {
  return doctorsProfile.languages.data;
};

export const selectLanguagesStatus = ({ doctorsProfile }: RootState): Status => {
  return doctorsProfile.languages.status;
};

export const selectHospitalBasedOnFilter = createSelector(
  [selectHospitals, selectHospitalsFilter],
  (hospitals, filter) => {
    if (filter && hospitals.length > 0) {
      const selectedHospital = hospitals.find((hospital) => hospital.id === filter);
      return selectedHospital;
    }
    return undefined;
  },
);

export const selectMedicalTeam = createSelector([selectHospitalBasedOnFilter], (hospital) =>
  hospital ? hospital.med_staff : [],
);

export const selectFilteredTeamMembers = createSelector(
  [selectMedicalTeamRoleFilter, selectMedicalTeam, selectMedicalTeamMemberFilter],
  (roleFilter, medicalTeam, selectedTeamMember) =>
    medicalTeam
      ?.filter(
        (teamMember: MedStaffProfileItem) =>
          !selectedTeamMember ||
          `${selectedTeamMember.first_name} ${selectedTeamMember.last_name}` ===
            `${teamMember.first_name} ${teamMember.last_name}`,
      )
      .filter(
        (teamMember: MedStaffProfileItem) => !roleFilter || roleFilter === teamMember.profession,
      ),
);

export const selectMedicalTeamRolesOptions = createSelector([selectMedicalTeam], (medicalTeam) =>
  medicalTeam
    ?.map((teamMember) => teamMember.profession)
    .filter((value, index, arr) => value && arr.indexOf(value) === index),
);

export default doctorsProfileSlice.reducer;
