import React, { useEffect } from "react";
import { Box, CircularProgress, Grid, useMediaQuery } from "@mui/material";
import updateLocale from "dayjs/plugin/updateLocale";
import dayjs from "dayjs";
import useTheme from "@mui/material/styles/useTheme";
import { PatientStatusEnumAPI } from "@veris-health/user-ms/lib/v2";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../hooks/useAppSelector";
import {
  selectCurrentChatThreadStatus,
  getChatHistoryThreadsAsync,
  selectChatHistoryPatientId,
  setChatHistoryPatientId,
  selectCurrentChatHistoryThread,
} from "../store";
import { ThreadContainer } from "./ThreadContainer";
import {
  getPatientCareAndSupportTeamsAsync,
  selectCareTeams,
} from "../../shared/slices/careTeamSlice";
import { ChatSidebarContainer } from "./ChatSidebarContainer";
import { ChatHeaderContainer } from "./ChatHeaderContainer";
import {
  VrsDeceasedPatientAlert,
  VrsInactivePatientAlert,
} from "../../../ui/components/VrsInactivePatientAlert/VrsInactivePatientAlert";
import { VrsPatientInfo } from "../../shared/interfaces";

dayjs.extend(updateLocale);

dayjs.updateLocale("en", {
  relativeTime: {
    future: "now",
    past: "%s ago",
    s: "1s",
    m: "1m",
    mm: "%dm",
    h: "1h",
    hh: "%dh",
    d: "1d",
    dd: "%dd",
    w: "%d w",
    ww: "%d w",
    M: "a month",
    MM: "%d months",
    y: "a y",
    yy: "%d yrs",
  },
});

export interface ChatHistoryContainerProps {
  userId: string;
  myAzureChatUserId: string;
  myChatDisplayName: string;
  currentPatient?: VrsPatientInfo;
}

const ChatHistoryContainer = ({
  userId,
  myAzureChatUserId,
  myChatDisplayName,
  currentPatient,
}: ChatHistoryContainerProps): JSX.Element => {
  const chatHistoryPatientId = useAppSelector(selectChatHistoryPatientId);

  const currentThread = useAppSelector((state) =>
    // https://github.com/reduxjs/reselect/issues/550
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    selectCurrentChatHistoryThread(state, chatHistoryPatientId),
  );
  const currentChatThreadStatus = useAppSelector(selectCurrentChatThreadStatus);
  const careTeams = useAppSelector((state) => selectCareTeams(state, currentPatient?.id));
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xl"));
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (currentPatient && !careTeams) {
      dispatch(getPatientCareAndSupportTeamsAsync(currentPatient.id));
    }

    if (chatHistoryPatientId)
      dispatch(getChatHistoryThreadsAsync({ userId: chatHistoryPatientId }));
  }, [dispatch, chatHistoryPatientId]);

  const isChatHistoryActive = () => {
    const patientId =
      currentThread?.participants.find((participant) => participant.user_type === "patient")
        ?.user_id || currentPatient?.id;
    return patientId ? chatHistoryPatientId === patientId : false;
  };

  return (
    <>
      <Grid container sx={{ backgroundColor: theme.veris.colors.neutrals.white }}>
        <Grid
          sx={{
            position: "relative",
            height: isSmallScreen ? "88vh" : "90vh",
          }}
          display="flex"
          flexDirection="column"
          item
          lg={8}
          xl={9}
          xs={8}
          marginBottom={0}
        >
          {currentChatThreadStatus === "loading" && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              p={1}
              sx={{
                backgroundColor: theme.veris.colors.amethyst.touch,
              }}
            >
              <CircularProgress />
            </Box>
          )}
          {currentChatThreadStatus === "idle" && (
            <>
              {currentPatient?.isInactiveOrDeceased && (
                <>
                  {currentPatient?.patientStatus === PatientStatusEnumAPI.Inactive ? (
                    <VrsInactivePatientAlert />
                  ) : (
                    <VrsDeceasedPatientAlert />
                  )}
                </>
              )}
              <ChatHeaderContainer
                currentThread={currentThread}
                currentPatient={currentPatient}
                userId={userId}
                careTeams={careTeams}
                showInteractions={false}
              />
              {currentThread && currentThread.id && (
                <ThreadContainer
                  currentThreadId={currentThread.id}
                  myAzureChatUserId={myAzureChatUserId}
                  myChatDisplayName={myChatDisplayName}
                  participants={currentThread.participants}
                  isPrivateThread={false}
                  showInteractions={false}
                  onChatHistoryToggle={() => {
                    dispatch(setChatHistoryPatientId({ patientId: undefined }));
                  }}
                  activeChatHistory={isChatHistoryActive()}
                  chatHistoryPatientId={chatHistoryPatientId}
                />
              )}
            </>
          )}
        </Grid>
        <ChatSidebarContainer />
      </Grid>
    </>
  );
};

export default ChatHistoryContainer;
