import React from "react";
import { Box, Typography, CircularProgress } from "@mui/material";
import { ConditionResponse } from "@veris-health/med-data-ms/lib/v1";
import { VrsButton, VrsErrorMsg } from "@veris-health/web-core";
import { Status } from "../../../shared/interfaces";
import { EMPTY_DATASET_MESSAGE } from "../../../../constants";

export interface PatientActiveProblemsProps {
  conditions: ConditionResponse[];
  status: Status;
  getPatientConditions: () => void;
  onSeeAllClick: () => void;
}

export function PatientActiveProblems({
  conditions,
  status,
  getPatientConditions,
  onSeeAllClick,
}: PatientActiveProblemsProps): JSX.Element {
  return (
    <Box
      sx={{
        borderRadius: 2,
        border: (theme) => `1px solid ${theme.veris.colors.neutrals["grey-light"]}`,
        flexGrow: 1,
        background: (theme) => theme.veris.colors.neutrals.white,
      }}
      padding={(theme) => theme.spacing(0.5)}
      mb={1}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        padding={(theme) => theme.spacing(0.75, 0.5)}
      >
        <Typography color={(theme) => theme.veris.colors.neutrals["grey-dark"]} variant="body1">
          ACTIVE PROBLEMS
        </Typography>

        {status === "idle" && conditions.length > 0 && (
          <VrsButton
            buttonType="quaternary"
            onClick={onSeeAllClick}
            sx={{
              padding: (theme) => `${theme.spacing(0.5)} ${theme.spacing(1)}`,
            }}
          >
            <Typography variant="caption" pt={0.25}>
              View all
            </Typography>
          </VrsButton>
        )}
      </Box>
      <>
        {status === "loading" && (
          <Box display="flex" justifyContent="center" alignItems="center">
            <CircularProgress />
          </Box>
        )}
        {status === "idle" && (
          <Box
            padding={(theme) => theme.spacing(0, 0.5, 0.75)}
            sx={{ maxHeight: "140px", overflowY: "scroll" }}
          >
            {conditions.length > 0 ? (
              conditions.map((condition) => (
                <Typography
                  variant="body"
                  sx={{ color: (theme) => theme.veris.colors.neutrals["grey-3"] }}
                  margin={(theme) => theme.spacing(1, 0)}
                  key={condition.id}
                >
                  {condition.condition}
                </Typography>
              ))
            ) : (
              <Typography
                variant="body"
                sx={{ color: (theme) => theme.veris.colors.neutrals["grey-3"] }}
                margin={(theme) => theme.spacing(1, 0)}
                textAlign="center"
              >
                {EMPTY_DATASET_MESSAGE}
              </Typography>
            )}
          </Box>
        )}
        {status === "failed" && <VrsErrorMsg size="small" onClick={getPatientConditions} />}
      </>
    </Box>
  );
}
