import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Grid } from "@mui/material";
import { Prompt, useHistory, useLocation, useParams } from "react-router-dom";
import { VrsErrorMsg } from "@veris-health/web-core";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import { selectUserId } from "../shared/slices/authSlice";
import { setActiveNavBarTab, toggleLeftSideBar } from "../shared/slices/uiSlice";
import { selectCallStatus, setCallStatus, setWindowedMode } from "../shared/slices/voipSlice";
import { PatientDetailsSidebarContainer } from "../PatientDetailsSidebar/PatientDetailsSidebarContainer";
import { PatientDemographics } from "../PatientDemographics";
import { Routes, replaceRouteParam } from "../../routes-config";
import {
  loadPatientDetailsAsync,
  loadTCMBillingCodes,
  loadTCMBillingReportAsync,
  selectPatientDetails,
  selectPatientRpmItems,
  selectPatientTcmReport,
} from "../PatientDetails/patientDetailsSlice";
import { setEventCreationVisibility } from "../Calendar/calendarSlice";
import { useCurrentPatient } from "../../hooks/useCurrentPatient";
import SidebarPreview from "../Patients/components/SidebarPreview/SidebarPreview";
import {
  loadNotificationsDataAsync,
  NotificationFilter,
  setNotificationsFilter,
} from "../Notifications/notificationsSlice";
import { VideoCallContainer } from "./containers/VideoCallScreenContainer";
import { getPatientByIdAsync } from "../shared/slices/asyncThunks";
import { selectPatientsMap, updatePatientStatusAsync } from "../shared/slices/patientsSlice";
import { useVideo } from "../../context/video";
import { PAGE_NAME } from "../../constants";
import { AddRPM } from "../PatientDetails/components/ManualRPM/AddRPM/AddRPM";
import { ReviewRPM } from "../PatientDetails/components/ManualRPM/ReviewRPM/ReviewRPM";
import StatusEdit from "../PatientDetails/components/StatusEdit/StatusEdit";
import { UserQueryParams } from "../shared/interfaces";
import ReviewTCM from "../PatientDetails/components/TCMTime/ReviewTCM";
import { AddTCM } from "../PatientDetails/components/TCMTime/AddTCM";

function VideoContainer(): JSX.Element {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const currentPatient = useCurrentPatient();
  const doctorId = useAppSelector(selectUserId);
  const patientDetails = useAppSelector((state) => selectPatientDetails(state, currentPatient?.id));
  const TCMReport = useAppSelector(
    (state) => currentPatient && selectPatientTcmReport(state, currentPatient?.id),
  );
  const callStatus = useAppSelector(selectCallStatus);
  const patients = useAppSelector(selectPatientsMap);
  const { userId } = useParams<UserQueryParams>();
  const videoProps = useVideo();
  const rpmItems = useAppSelector((state) =>
    currentPatient ? selectPatientRpmItems(state, currentPatient.id) : undefined,
  );
  const [openAddRpm, setOpenAddRpm] = useState(false);
  const [openReviewRpm, setOpenReviewRpm] = useState(false);
  const [openStatusEdit, setOpenStatusEdit] = useState(false);
  const [openReviewTCM, setOpenReviewTCM] = useState(false);
  const [openAddTCM, setOpenAddTCM] = useState(false);

  const handleAddRPM = () => {
    setOpenReviewRpm(false);
    setOpenAddRpm(true);
  };

  const handleReviewRPM = () => {
    setOpenReviewRpm(true);
    setOpenAddRpm(false);
  };

  const closeRPMDialogs = () => {
    setOpenReviewRpm(false);
    setOpenAddRpm(false);
  };

  const onAddTCMBilling = () => {
    setOpenReviewTCM(false);
    setOpenAddTCM(true);
  };

  const onReviewTCMBilling = () => {
    if (currentPatient) {
      dispatch(loadTCMBillingReportAsync({ id: currentPatient?.id }));
      dispatch(loadTCMBillingCodes());
      setOpenReviewTCM(true);
    }
  };

  const closeTCMDialogs = () => {
    setOpenReviewTCM(false);
    setOpenAddTCM(false);
  };

  useEffect(() => {
    const state = location.state as { from: string };

    if (!state || state.from !== PAGE_NAME) {
      history.replace(replaceRouteParam("PATIENT_DETAILS", ":userId", userId));
    } else {
      window.history.replaceState({}, document.title);
      dispatch(toggleLeftSideBar(false));

      if (!callStatus.isOnCall) {
        dispatch(
          setCallStatus({
            isOnCall: false,
            isOnLobby: true,
            callEndReason: undefined,
            isWindowed: false,
            callUrl: {
              patientId: userId,
              searchParams: history.location.search ? history.location.search : undefined,
            },
          }),
        );
      }
    }
  }, [dispatch]);

  useEffect(() => {
    if (!currentPatient) {
      dispatch(getPatientByIdAsync({ userId: +userId }));
      return;
    }
    if (currentPatient) {
      dispatch(
        loadPatientDetailsAsync({
          id: currentPatient.id,
          isMioUser: currentPatient.type === "mio",
        }),
      );
    }
  }, [dispatch, currentPatient, userId, patients]);

  useEffect(
    () => () => {
      dispatch(setWindowedMode(true));
    },
    [],
  );

  if (!doctorId) {
    return <VrsErrorMsg size="small" />;
  }

  if (!currentPatient) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" sx={{ mt: 2 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Grid container>
      <Prompt
        message="Video call will go to window mode. Are you sure you want to leave?"
        when={callStatus.isOnCall && !callStatus.isWindowed}
      />
      <Grid
        item
        xs={2.5}
        sx={{
          backgroundColor: (theme) => theme.veris.colors.neutrals.white,
          borderRight: (theme) => `1px solid ${theme.veris.colors.neutrals["grey-2"]}`,
        }}
      >
        <SidebarPreview patient={currentPatient} />
      </Grid>
      <Grid item minHeight="400px" xs pt={1} pl={1}>
        {patientDetails && (
          <>
            <PatientDemographics
              onAddRPMClick={handleAddRPM}
              onReviewRPMClick={handleReviewRPM}
              startCall={() =>
                dispatch(
                  setCallStatus({
                    isOnCall: false,
                    isOnLobby: true,
                    callEndReason: undefined,
                    isWindowed: false,
                    callUrl: {
                      patientId: userId,
                      searchParams: history.location.search ? history.location.search : undefined,
                    },
                  }),
                )
              }
              endCall={() => {
                if (videoProps.call) videoProps.call.hangUp();
              }}
              isOnCall={callStatus.isOnCall}
              callDisabled={
                !(videoProps.callState === "Connected" || videoProps.callState === "Ringing")
              }
              patientDetails={patientDetails}
              patient={currentPatient}
              onScheduleAppointmentClick={() => {
                history.push(Routes.CALENDAR);
                dispatch(
                  setEventCreationVisibility({ isOpen: true, patientId: currentPatient?.id }),
                );
              }}
              disableRpm={currentPatient.isInactiveOrDeceased}
              onLastNotificationsClick={() => {
                dispatch(setNotificationsFilter(NotificationFilter.ViewAll));
                history.push({
                  pathname: replaceRouteParam(
                    "NOTIFICATIONS_DETAILS",
                    ":notificationId",
                    NotificationFilter.ViewAll,
                  ),
                  search: `?userId=${currentPatient.id}`,
                });
                dispatch(
                  loadNotificationsDataAsync({
                    userId: Number(doctorId),
                    patientId: currentPatient.id,
                    offset: 0,
                  }),
                );
              }}
              onStatusEdit={() => setOpenStatusEdit(true)}
              onAddTCMBilling={onAddTCMBilling}
              onReviewTCMBilling={onReviewTCMBilling}
            />
            {openAddTCM && (
              <AddTCM open={openAddTCM} onClose={closeTCMDialogs} patient={currentPatient} />
            )}
            <AddRPM
              open={openAddRpm}
              onClose={closeRPMDialogs}
              patient={currentPatient}
              rpmTimeCurrentMonth={rpmItems ? rpmItems.currentMonthReport.total : 0}
              rpmTimePreviousMonth={rpmItems ? rpmItems.previousMonthReport.total : 0}
            />
            <ReviewRPM
              open={openReviewRpm}
              onClose={closeRPMDialogs}
              patient={currentPatient}
              previousMonthReport={rpmItems?.previousMonthReport}
              currentMonthReport={rpmItems?.currentMonthReport}
            />
            {openReviewTCM && (
              <ReviewTCM open={openReviewTCM} onClose={closeTCMDialogs} TCMData={TCMReport || []} />
            )}
            {openStatusEdit && (
              <StatusEdit
                open={openStatusEdit}
                onClose={() => setOpenStatusEdit(false)}
                onSave={(status) =>
                  dispatch(
                    updatePatientStatusAsync({
                      patientId: currentPatient.id,
                      status,
                    }),
                  ).then(() => setOpenStatusEdit(false))
                }
                currentStatus={currentPatient.patientStatus}
              />
            )}
          </>
        )}
        <VideoCallContainer callState={videoProps.callState} />
      </Grid>
      <Grid item xs="auto" pt={1}>
        <PatientDetailsSidebarContainer
          onViewAllLabsResults={() => {
            dispatch(setActiveNavBarTab(2));
            history.push(
              replaceRouteParam("PATIENT_DETAILS", ":userId", String(currentPatient.id)),
            );
          }}
        />
      </Grid>
    </Grid>
  );
}

export default VideoContainer;
