import { throttle } from "lodash";
import React, { useState, useLayoutEffect, useContext, ReactNode, ReactElement } from "react";

export function useMousePosition() {
  const [position, setPosition] = useState({
    x: 0,
    y: 0,
  });
  useLayoutEffect(() => {
    function updatePosition(e: MouseEvent) {
      setPosition({ x: e.clientX, y: e.clientY });
    }
    document.addEventListener("mousemove", updatePosition);
    return () => document.removeEventListener("mousemove", updatePosition);
  }, []);

  return position;
}

interface OpenTooltipProps {
  open: boolean;
  content?: ReactElement;
}

interface TooltipProps {
  openTooltip: (props: OpenTooltipProps) => void;
  closeTooltip: () => void;
}

const Tooltip = ({ tooltip }: { tooltip: OpenTooltipProps }) => {
  const position = useMousePosition();
  const left = tooltip.open ? position.x : -9999;
  const top = tooltip.open ? position.y : -9999;

  return (
    <div
      style={{
        position: "fixed",
        left: left - 150,
        top: top + 5,
        zIndex: 9999,
      }}
    >
      {tooltip.content}
    </div>
  );
};

const TooltipContext = React.createContext<TooltipProps>({
  openTooltip(): void {
    throw new Error("Function not implemented.");
  },
  closeTooltip(): void {
    throw new Error("Function not implemented.");
  },
});

export function useTooltipContext() {
  const tooltipContext = useContext(TooltipContext);
  return tooltipContext;
}

export default function CustomTooltip({ children }: { children: ReactNode }) {
  const emptyTooltip: OpenTooltipProps = {
    open: false,
    content: undefined,
  };
  const [tooltip, setTooltip] = React.useState(emptyTooltip);
  const openTooltipFunc = throttle((content: ReactElement) => {
    return setTooltip({
      open: true,
      content,
    });
  }, 200);

  const openTooltip = ({ content }: OpenTooltipProps) => {
    if (content) openTooltipFunc(content);
  };

  const closeTooltip = () => {
    setTooltip(emptyTooltip);
  };

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <Tooltip tooltip={tooltip} />
      <TooltipProvider openTooltip={openTooltip} closeTooltip={closeTooltip}>
        {children}
      </TooltipProvider>
    </div>
  );
}

function TooltipProvider({
  children,
  openTooltip,
  closeTooltip,
}: { children: ReactNode } & TooltipProps) {
  const tooltipContext = React.useMemo(() => {
    return {
      openTooltip,
      closeTooltip,
    };
  }, [openTooltip, closeTooltip]);

  return <TooltipContext.Provider value={tooltipContext}>{children}</TooltipContext.Provider>;
}
