import {
  PatientBasicInfo,
  TermsAndConditionsItem,
  TermsAndConditionType,
  PatientInfoAvatarLoader,
  PatientType,
} from "@veris-health/user-ms/lib/v1";
import { PatientInfo, PatientStatusEnumAPI } from "@veris-health/user-ms/lib/v2";
import {
  searchApiV2,
  patientProfileApiV2,
  patientsApiV1,
  termsAndConditionsApiV1,
  searchApiV1,
} from "../../../../api";
import { checkPatientStatus, getFullPictureFormat } from "../../helpers";
import { VrsPatientInfo } from "../../interfaces";

const mapPatientDetails = ({ patient }: { patient: PatientInfo }): VrsPatientInfo => {
  return {
    ...patient,
    healthStatus: patient.health_stats,
    activeSymptoms: {
      allActive: patient.active_symptoms?.all_active?.map((symptom) =>
        symptom.replaceAll("-", " "),
      ),
      latestSymptomTimeline: {
        symptom_name: {
          label:
            patient.active_symptoms?.latest_symptom_timeline?.symptom_name?.label?.replaceAll(
              "-",
              " ",
            ) || "",
          value:
            patient.active_symptoms?.latest_symptom_timeline?.symptom_name?.value?.replaceAll(
              "-",
              " ",
            ) || "",
        },
        name: patient.active_symptoms?.latest_symptom_timeline?.name?.replaceAll("-", " ") || "",
        events: patient.active_symptoms?.latest_symptom_timeline?.events,
      },
    },
    lastInfusion: patient.last_infusion,
    tags: patient.tags,
    type: patient.type || PatientType.GfAhk,
    gender: patient.gender || "N/A",
    batteryLevel: patient.battery_level,
    diagnosis: {
      cancerType: patient.diagnosis?.cancer_type || "N/A",
      cancerStage: patient.diagnosis?.cancer_stage || "N/A",
    },
    picture: patient.picture && getFullPictureFormat(patient.picture),
    qualityOfLife: patient.quality_of_life,
    qualityOfLifeTrend: patient.quality_of_life_trending,
    inCareTeam: patient.in_care_team,
    ingestionData: patient.ingestion_data,
    ehrId: patient.ehr_id,
    patientStatus: patient.patient_status,
    isInactiveOrDeceased: checkPatientStatus(
      [PatientStatusEnumAPI.Inactive, PatientStatusEnumAPI.Deceased],
      patient.patient_status,
    ),
    observation: checkPatientStatus(
      [
        PatientStatusEnumAPI.HospitalAtHome,
        PatientStatusEnumAPI.Hospitalized,
        PatientStatusEnumAPI.Survivor,
      ],
      patient.patient_status,
    ),
    rpmSeconds: patient.rpm_seconds,
    dataSources: patient.data_sources,
  };
};

export const searchPatients = async ({
  searchPhrase,
  offset,
  limit,
  allPatients,
}: {
  searchPhrase?: string;
  offset?: number;
  limit?: number;
  allPatients?: boolean;
} = {}): Promise<{ patients: VrsPatientInfo[]; totalCount: number }> => {
  const { data } = await searchApiV2.patientsSearchGet(
    searchPhrase,
    offset,
    limit,
    undefined,
    allPatients,
  );

  const formattedPatients: VrsPatientInfo[] = data.users.map((patient) =>
    mapPatientDetails({ patient }),
  );
  return { totalCount: data.totalCount, patients: formattedPatients };
};

export const getPatientAvatars = async (ids: number[]): Promise<PatientInfoAvatarLoader> => {
  const response = await searchApiV1.getDemographicsImages(ids);
  return response.data;
};

export const getTermsAndConditions = async (): Promise<TermsAndConditionsItem> => {
  const { data } = await termsAndConditionsApiV1.getTermsAndConditions(
    TermsAndConditionType.MedStaff,
    "en",
  );
  return data;
};

export const getMedStaffPatients = async (medStaffId: number): Promise<PatientBasicInfo[]> => {
  const activePatientStatuses: PatientStatusEnumAPI[] = [
    ...Object.values(PatientStatusEnumAPI).filter(
      (value) => value !== "deceased" && value !== "inactive",
    ),
  ];
  const response = await patientsApiV1.getAssignedPatients(medStaffId, true, activePatientStatuses);
  return response.data;
};

export const getPatientDetails = async (userId: number): Promise<VrsPatientInfo> => {
  const response = await patientProfileApiV2.patientProfileGet(userId);
  const patient = response.data;
  const formattedPatient: VrsPatientInfo = mapPatientDetails({ patient });
  return formattedPatient;
};
