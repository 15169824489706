/* eslint-disable @typescript-eslint/no-explicit-any */
export function stripHtml(json: any): any {
  if (json === null) {
    return null;
  }

  if (typeof json === "string") {
    return json.replace(/<[^>]*>/g, "");
  }

  if (typeof json === "object") {
    if (Array.isArray(json)) {
      return json.map(stripHtml);
    }

    return Object.keys(json).reduce((acc, key) => {
      acc[key] = stripHtml(json[key]);
      return acc;
    }, {} as Record<string, any>);
  }

  return json;
}
