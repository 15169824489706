import React from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { Box, Stack, Typography } from "@mui/material";
import { IconName, VrsButton, VrsIcon, dateFormats } from "@veris-health/web-core";
import { AppointmentNotificationView } from "@veris-health/communication-ms/lib/v1";
import { VrsPatientInfo } from "../../../shared/interfaces";
import { calculateTimeFromNow, utcToLocal } from "../../../../utils/date";
import { StyledNotificationWrapper } from "../../../shared/styledComponents";
import { VrsPatientInfoCard } from "../../../../ui/components/VrsPatientInfoCard";

dayjs.extend(relativeTime);

export interface BillingNotificationProps {
  notification: AppointmentNotificationView;
  onClick: (id: number) => void;
}

export const BillingNotification = ({
  notification,
  onClick,
}: BillingNotificationProps): JSX.Element => {
  const {
    read,
    video_billing_id: videoBillingId,
    patient,
    date_notified: dateNotified,
    number_of_participants: numberOfParticipants,
    duration_of_call: durationOfCall,
  } = notification;

  const getCallDuration = () => {
    const callDurationHours = dayjs.duration(+durationOfCall, "minutes").hours();

    if (callDurationHours > 0) {
      const callDurationFormatted = dayjs
        .duration(+durationOfCall, "minutes")
        .format(dateFormats["HH:mm:ss"]);
      return ` ${callDurationFormatted} hours.`;
    }

    const callDurationFormatted = dayjs
      .duration(+durationOfCall, "minutes")
      .format(dateFormats["mm:ss"]);
    return ` ${callDurationFormatted} minutes.`;
  };

  return (
    <StyledNotificationWrapper
      isUnread={!read}
      onClick={() => !read && onClick(videoBillingId)}
      isActive={read}
      showBorder={false}
      cursor={read ? "default" : "pointer"}
    >
      <Box p={1}>
        <Stack direction="row" justifyContent="space-between">
          <VrsPatientInfoCard
            patientInfo={
              {
                id: patient?.user_id,
                name: patient?.full_name,
                diagnosis: { cancerStage: patient?.cancer_stage, cancerType: patient?.cancer_type },
                dob: patient?.dob,
                picture: patient?.image,
              } as unknown as VrsPatientInfo
            }
            backgroundColor="transparent"
            newNotification={!read}
          />
          <Typography
            variant="body"
            color={(theme) => theme.veris.colors.neutrals["grey-mid"]}
            pt={1}
          >
            {calculateTimeFromNow(utcToLocal(dateNotified).toISOString())}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          paddingLeft={8}
          justifyContent="space-between"
          alignItems="center"
          gap={5}
        >
          <Box>
            <Stack direction="row" alignItems="center" gap={2}>
              <VrsIcon name={IconName.Participants} />
              <Typography variant="body"> {numberOfParticipants} Participants</Typography>
            </Stack>
            <Stack direction="row" alignItems="center" gap={2}>
              <VrsIcon name={IconName.CallDuration} />
              <Typography
                variant="body"
                color={(theme) => theme.veris.colors.neutrals["grey-dark"]}
              >
                Duration of the call:{" "}
              </Typography>
              <Typography pt={0.5}>{getCallDuration()}</Typography>
            </Stack>
          </Box>
          {read ? (
            <Typography
              color={(theme) => theme.veris.colors.amethyst.normal}
              variant="button"
              fontWeight={500}
            >
              Billing Ended
            </Typography>
          ) : (
            <VrsButton buttonType="primary"> Confirm billing</VrsButton>
          )}
        </Stack>
      </Box>
    </StyledNotificationWrapper>
  );
};
