import sharedReducer from "./features/shared/slices/sharedSlice";
import sharedPatientsReducer from "./features/shared/slices/patientsSlice";
import allergiesReducer from "./features/Allergies/allergiesSlice";
import dashboardReducer from "./features/Dashboard/dashboardSlice";
import notificationsReducer from "./features/Notifications/notificationsSlice";
import detailsMeasurementsReducer from "./features/PatientDetailsMeasurements/measurementSlice";
import patientDetailsReducer from "./features/PatientDetails/patientDetailsSlice";
import patientsReducer from "./features/Patients/patientsSlice";
import authReducer from "./features/shared/slices/authSlice";
import calendarReducer from "./features/Calendar/calendarSlice";
import uiReducer from "./features/shared/slices/uiSlice";
import communicationReducer from "./features/Communication/store/communicationSlice";
import doctorsProfileReducer from "./features/DoctorsProfile/doctorsProfileSlice";
import patientRecordsReducer from "./features/PatientRecords/patientRecordsSlice";
import treatmentPlansReducer from "./features/TreatmentPlan/treatmentPlanSlice";
import careTeamReducer from "./features/shared/slices/careTeamSlice";
import voipReducer from "./features/VideoCommunication/voipSlice";
import sharedVoipReducer from "./features/shared/slices/voipSlice";
import patientDetailsSidebarReducer from "./features/PatientDetailsSidebar/patientDetailsSidebarSlice";
import hospitalsReducer from "./features/Hospitals/hospitalSlice";
import activityTrackingReducer from "./features/shared/slices/activityTrackingSlice";
import tasksReducer from "./features/Tasks/tasksSlice";

export const getReducers = () => {
  return {
    shared: sharedReducer,
    sharedPatients: sharedPatientsReducer,
    patients: patientsReducer,
    auth: authReducer,
    dashboard: dashboardReducer,
    notifications: notificationsReducer,
    allergies: allergiesReducer,
    ui: uiReducer,
    detailsMeasurements: detailsMeasurementsReducer,
    patientDetails: patientDetailsReducer,
    patientDetailsSidebar: patientDetailsSidebarReducer,
    calendar: calendarReducer,
    communication: communicationReducer,
    doctorsProfile: doctorsProfileReducer,
    patientRecords: patientRecordsReducer,
    treatmentPlan: treatmentPlansReducer,
    careTeam: careTeamReducer,
    voip: voipReducer,
    sharedVoip: sharedVoipReducer,
    hospitals: hospitalsReducer,
    activityTracking: activityTrackingReducer,
    tasks: tasksReducer,
  };
};
