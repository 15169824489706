import { DataSourceModel } from "@veris-health/user-ms/lib/v2";
import { uniqBy } from "lodash";

export const getShortName = (name: string): string | undefined => {
  if (name) {
    const names: string[] = name?.trim().split(" ") || [];
    let initials = `${names[0]} `;
    names.shift();

    names.forEach((namePart) => {
      initials += `${namePart.charAt(0)}.`;
    });

    return initials;
  }
  return undefined;
};

export const removeFirstOccurrenceFromArray = (arr: string[], valueToRemove: string) => {
  const foundIndex = arr.indexOf(valueToRemove);
  if (foundIndex !== -1) {
    arr.splice(foundIndex, 1);
  }
  return arr;
};

export const getSensorsPerSource = (
  sources: DataSourceModel[],
  sensorOptions: { [key: string]: any[] },
  uniqueProperty: string,
) =>
  uniqBy(
    sources?.flatMap((source) => sensorOptions[source.source_type]),
    uniqueProperty,
  ) || [];
