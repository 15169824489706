import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useLocation } from "react-router-dom";
import { IconName, VrsIconButton, VrsButton, VrsSelect, verisColors } from "@veris-health/web-core";
import {
  SensorsGraphNavigationDirection,
  selectSensorsGraphView,
  selectDateTo,
  selectNavigationDates,
  SensorsGraphView,
  setSensorsGraphView,
  resetSensorsGraphView,
  switchSensorsGraphView,
  navigateSensorsGraphView,
  selectRangeDates,
  fetchUserSymptomsData,
  selectMeasurementsStatus,
  fetchUnifiedUserSensorsDataAsync,
} from "./measurementSlice";
import { useAppSelector } from "../../hooks/useAppSelector";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { VrsGraphNavigatorItem } from "./components/VrsGraphNavigatorItem";
import SymptomsViewContainer from "./containers/SymptomsViewContainer";
import { useCurrentPatient } from "../../hooks/useCurrentPatient";
import { VrsPatientInfo } from "../shared/interfaces";
import BaselineDialog from "./components/BaselineDialog";
import { CombinedGraphContainer } from "./containers/CombinedGraphContainer";

const options: { label: string; value: SensorsGraphView }[] = [
  { label: "Daily", value: "daily" },
  { label: "Last 3 days", value: "3-days" },
  { label: "Weekly", value: "weekly" },
];

export interface PatientDetailsMeasurementsProps {
  isPreview?: boolean;
  patient?: VrsPatientInfo;
}

export const PatientDetailsMeasurementContainer = ({
  isPreview,
  patient,
}: PatientDetailsMeasurementsProps): JSX.Element | null => {
  const dispatch = useAppDispatch();
  const sensorsGraphView = useAppSelector(selectSensorsGraphView);
  const dateTo = useAppSelector(selectDateTo);
  const navigationDates = useAppSelector(selectNavigationDates);
  const rangeDates = useAppSelector(selectRangeDates);
  const currentPatient = useCurrentPatient();
  const [patientBaselineDialog, setPatientBaselineDialog] = useState<boolean>(false);
  const status = useAppSelector(selectMeasurementsStatus);
  const location = useLocation();

  const selectedPatient = patient || currentPatient;

  const fetchUserSensorDataByType = ({ id }: { id: number }) => {
    dispatch(fetchUnifiedUserSensorsDataAsync({ id }));
  };

  const fetchNewData = () => {
    if (selectedPatient) {
      const { id } = selectedPatient;
      dispatch(
        fetchUserSymptomsData({
          userId: id.toString(),
        }),
      );
      fetchUserSensorDataByType({ id });
    }
  };
  useEffect(() => {
    if (isPreview) {
      dispatch(resetSensorsGraphView({ view: "daily" }));
      fetchNewData();
    }
    if (!location.state) {
      dispatch(resetSensorsGraphView({ view: "weekly" }));
    }
    if (selectedPatient) {
      const { id } = selectedPatient;
      fetchUserSensorDataByType({ id });
      dispatch(
        fetchUserSymptomsData({
          userId: id.toString(),
        }),
      );
    }
    return () => {
      if (currentPatient) {
        dispatch(resetSensorsGraphView({ view: "weekly" }));
        fetchNewData();
      }
    };
  }, [dispatch, selectedPatient]);

  const onSensorGraphViewSelection = (selectedOption: SensorsGraphView) => {
    dispatch(setSensorsGraphView({ view: selectedOption }));
    if (selectedPatient) {
      const { id } = selectedPatient;
      dispatch(
        fetchUserSymptomsData({
          userId: id.toString(),
        }),
      );
      fetchUserSensorDataByType({ id });
    }
  };

  const onResetSensorsGraphView = () => {
    dispatch(resetSensorsGraphView({}));
    fetchNewData();
  };

  const onGraphNavigatorItemClicked = (d: dayjs.Dayjs) => {
    dispatch(switchSensorsGraphView(d.format()));
    if (selectedPatient) {
      const { id } = selectedPatient;
      dispatch(
        fetchUserSymptomsData({
          userId: id.toString(),
        }),
      );
      fetchUserSensorDataByType({ id });
    }
  };

  const navigateSensorsGraph = (direction: SensorsGraphNavigationDirection) => {
    dispatch(navigateSensorsGraphView({ direction }));
    if (selectedPatient) {
      const { id } = selectedPatient;
      dispatch(
        fetchUserSymptomsData({
          userId: id.toString(),
        }),
      );
      dispatch(fetchUnifiedUserSensorsDataAsync({ id }));
    }
  };

  if (!selectedPatient) {
    return null;
  }

  return (
    <Box
      p={isPreview ? 0 : 1.5}
      my={1.25}
      sx={{
        backgroundColor: (theme) => theme.veris.colors.neutrals.white,
        border: (theme) =>
          `1px solid ${theme.veris.colors.neutrals[isPreview ? "white" : "grey-2"]}`,
        borderRadius: (theme) => +theme.shape.borderRadius * 0.375,
      }}
      display="flex"
      alignItems="baseline"
      justifyContent="center"
    >
      {patientBaselineDialog && currentPatient && (
        <BaselineDialog
          open={patientBaselineDialog}
          setPatientBaselineDialog={setPatientBaselineDialog}
          currentPatient={currentPatient}
        />
      )}
      <Box flexGrow={1}>
        {!isPreview && status === "idle" && (
          <Box display="flex" justifyContent="space-between" alignItems="center" pb={1}>
            <Typography
              color={(theme) => theme.veris.colors.neutrals["grey-3"]}
              sx={{
                fontWeight: 400,
              }}
            >
              Patient&apos;s normal range values
            </Typography>
            <Button
              onClick={() => {
                setPatientBaselineDialog(true);
              }}
            >
              <Typography
                color={(theme) => theme.veris.colors.amethyst.normal}
                sx={{
                  textTransform: "none",
                  textDecoration: "underline",
                  fontWeight: 400,
                }}
              >
                Set the patient&apos;s normal range
              </Typography>
            </Button>
          </Box>
        )}
        <Box
          display="flex"
          flexDirection={isPreview ? "column" : "row"}
          alignItems={isPreview ? "inherit" : "center"}
        >
          <Box minWidth={105} my={isPreview ? 1.5 : 0} mr={isPreview ? 0 : 6}>
            <VrsSelect
              value={sensorsGraphView}
              options={options}
              onSelected={(selected) => onSensorGraphViewSelection(selected as SensorsGraphView)}
            />
          </Box>
          {isPreview && sensorsGraphView !== "daily" && (
            <Typography
              variant="body"
              color={(theme) => theme.veris.colors.neutrals["grey-mid"]}
              mb={1.5}
            >
              {rangeDates.startDate} - {rangeDates.endDate}
            </Typography>
          )}
          <Box
            flexGrow="1"
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            p={1}
            sx={{
              backgroundColor: (theme) => theme.veris.colors.neutrals["soft-white"],
              borderRadius: (theme) => `${+theme.shape.borderRadius}px`,
            }}
          >
            <Box sx={{ marginRight: isPreview ? 0 : "25px" }}>
              <VrsIconButton
                iconProps={{
                  name: IconName.ArrowLeft,
                  stroke: verisColors.neutrals.black,
                  size: isPreview ? 15 : 20,
                }}
                onClick={() => navigateSensorsGraph("previous")}
                square
              />
            </Box>
            <Box
              flexGrow="1"
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-around"
              gap={isPreview ? 2.5 : 4}
            >
              {navigationDates.map((date) => {
                return (
                  <VrsGraphNavigatorItem
                    key={date.toISOString()}
                    date={date}
                    view={sensorsGraphView}
                    onClick={onGraphNavigatorItemClicked}
                    isPreview={isPreview}
                  />
                );
              })}
            </Box>
            <VrsIconButton
              iconProps={{
                name: IconName.ArrowRight,
                stroke: verisColors.neutrals.black,
                size: isPreview ? 15 : 20,
              }}
              onClick={() => navigateSensorsGraph("next")}
              disabled={dayjs().startOf("day").isBefore(dayjs(dateTo))}
              square
            />
          </Box>
        </Box>
        <CombinedGraphContainer
          isPreview={isPreview}
          currentPatient={selectedPatient}
          fetchData={() => {
            if (selectedPatient) fetchUserSensorDataByType({ id: selectedPatient.id });
          }}
          sensorsGraphView={sensorsGraphView}
        />

        <Box sx={{ maxHeight: "300px", overflowY: "hidden" }}>
          <SymptomsViewContainer
            isPreview={isPreview}
            view={sensorsGraphView}
            currentPatient={selectedPatient}
          />
        </Box>
      </Box>

      {!dayjs(dateTo).isToday() && !isPreview && (
        <Box display="flex" alignItems="center">
          <VrsButton
            buttonType="quaternary"
            onClick={onResetSensorsGraphView}
            sx={{ padding: (theme) => theme.spacing(0.5) }}
          >
            <Typography variant="caption">Today</Typography>
          </VrsButton>
        </Box>
      )}
    </Box>
  );
};
