import { MbscCalendarEvent, MbscEventcalendarView } from "@mobiscroll/react";
import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  EntityState,
  PayloadAction,
} from "@reduxjs/toolkit";
import { AppointmentStatusEnum, Medication } from "@veris-health/med-data-ms/lib/v1";
import dayjs from "dayjs";
import { RootState } from "../../store";
import { NormalizedCalendarEvents } from "../Calendar/calendarSlice";
import { extractErrorMessage } from "../shared/helpers";
import { Status } from "../shared/interfaces";
import { fetchCalendarEvents } from "../shared/slices/api/calendarApi";
import { getPatientMedications } from "../shared/slices/api/medDataApi";
import { localizedLogout, logout } from "../shared/slices/authSlice";
import { ResourceEnum, timelineConfigs } from "./components/timelineConstants";

export type TimelineTypes = "day" | "week" | "month" | "year" | "bi-weekly";

interface NormalizedMedications {
  medications: Medication[];
}
interface TreatmentPlanState {
  view: TimelineTypes;
  events: {
    data: EntityState<NormalizedCalendarEvents>;
    status: Status;
  };
  timelineView: MbscEventcalendarView;
  start: string;
  end: string;
  isCreateEventOpen: boolean;
  medications: {
    data: EntityState<NormalizedMedications>;
    status: Status;
  };
}

export interface CalendarActionPayload {
  view: TimelineTypes;
}

const timelineEventsAdapter = createEntityAdapter<NormalizedCalendarEvents>();
const medicationsAdapter = createEntityAdapter<NormalizedMedications>();

const initialState: TreatmentPlanState = {
  view: "day",
  events: {
    data: timelineEventsAdapter.getInitialState(),
    status: "idle",
  },
  timelineView: {
    timeline: {
      type: "day",
      timeCellStep: 30,
      timeLabelStep: 30,
    },
  },
  medications: {
    data: medicationsAdapter.getInitialState(),
    status: "idle",
  },
  start: dayjs().startOf("day").toISOString(),
  end: dayjs().add(1, "day").toISOString(),
  isCreateEventOpen: false,
};

export interface fetchCalendarEventsProps {
  userId: number;
  start?: string;
  end?: string;
}

export const fetchTreatmentPlanEventsAsync = createAsyncThunk(
  "treatmentPlanSlice/fetchTreatmentPlanEventsAsync",
  async ({ userId, start, end }: fetchCalendarEventsProps) => {
    const events = await fetchCalendarEvents(userId, start, end, [
      AppointmentStatusEnum.Booked,
      AppointmentStatusEnum.Completed,
      AppointmentStatusEnum.Proposed,
    ]);
    return { id: userId, events };
  },
);

export const fetchPatientMedicationsAsync = createAsyncThunk(
  "treatmentPlanSlice/getMedications",
  async ({ userId, start, end }: fetchCalendarEventsProps, { rejectWithValue }) => {
    try {
      const medications = await getPatientMedications(userId, start, end);
      return { id: userId, medications };
    } catch (err) {
      const errorMsg = extractErrorMessage(err);
      return rejectWithValue(errorMsg || "Could not fetch medications data.");
    }
  },
);

const treatmentPlanSlice = createSlice({
  initialState,
  name: "TreatmentPlan",
  reducers: {
    setTimelineView: (state, action: PayloadAction<CalendarActionPayload>) => {
      state.view = action.payload.view;
      state.timelineView = timelineConfigs[action.payload.view] as MbscEventcalendarView;
    },
    setTimelineStartAndEndDate: (state, action: PayloadAction<{ start: string; end: string }>) => {
      state.start = action.payload.start;
      state.end = action.payload.end;
    },
    setEventCreationVisibility: (
      state,
      action: PayloadAction<{ isOpen: boolean; patientId?: number }>,
    ) => {
      state.isCreateEventOpen = action.payload.isOpen;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTreatmentPlanEventsAsync.pending, (state) => {
        state.events.status = "loading";
      })
      .addCase(fetchTreatmentPlanEventsAsync.fulfilled, (state, { payload }) => {
        if (state.events.data.ids.includes(payload.id))
          timelineEventsAdapter.updateOne(state.events.data, {
            id: payload.id,
            changes: { ...payload },
          });
        else timelineEventsAdapter.addOne(state.events.data, payload as NormalizedCalendarEvents);

        state.events.status = "idle";
      })
      .addCase(fetchTreatmentPlanEventsAsync.rejected, (state) => {
        state.events.status = "failed";
      })
      .addCase(fetchPatientMedicationsAsync.pending, (state) => {
        state.medications.status = "loading";
      })
      .addCase(fetchPatientMedicationsAsync.fulfilled, (state, action) => {
        if (
          dayjs(action.meta.arg.start).isSame(dayjs(state.start), "day") &&
          dayjs(action.meta.arg.end).isSame(dayjs(state.end), "day")
        ) {
          medicationsAdapter.upsertOne(
            state.medications.data,
            action.payload as NormalizedMedications,
          );
        }

        state.medications.status = "idle";
      })
      .addCase(fetchPatientMedicationsAsync.rejected, (state) => {
        state.medications.status = "failed";
      })
      .addCase(logout, () => {
        return initialState;
      })
      .addCase(localizedLogout, () => {
        return initialState;
      });
  },
});

export default treatmentPlanSlice.reducer;

export const {
  setTimelineView,
  setTimelineStartAndEndDate,

  setEventCreationVisibility,
} = treatmentPlanSlice.actions;

export const selectEventsStatus = (state: RootState): Status => state.treatmentPlan.events.status;

export const selectTimelineViewConfig = (state: RootState): MbscEventcalendarView =>
  state.treatmentPlan.timelineView;

export const selectTimelineEvents = (
  rootState: RootState,
  id: number | undefined,
): MbscCalendarEvent[] | undefined => {
  if (id) {
    return (
      timelineEventsAdapter
        .getSelectors<RootState>((state) => state.treatmentPlan.events.data)
        .selectById(rootState, id)
        ?.events.map((event) => {
          return { ...event, resource: ResourceEnum.Appointments };
        }) || []
    );
  }
  return undefined;
};

export const selectTimelineView = (state: RootState): TimelineTypes =>
  state.treatmentPlan.view as TimelineTypes;

export const selectStartDate = (state: RootState): string => state.treatmentPlan.start;
export const selectEndDate = (state: RootState): string => state.treatmentPlan.end;

export const selectIsCreateEventOpen = (state: RootState): boolean =>
  state.treatmentPlan.isCreateEventOpen;

export const selectMedicationsCalendar = (
  rootState: RootState,
  id: number | undefined,
): MbscCalendarEvent[] | undefined => {
  if (id) {
    return (
      medicationsAdapter
        .getSelectors<RootState>((state) => state.treatmentPlan.medications.data)
        .selectById(rootState, id)?.medications || []
    );
  }
  return undefined;
};
