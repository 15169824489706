import { ClinicalNote } from "@veris-health/med-data-ms/lib/v1";
import React, { useEffect, useRef, useState } from "react";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import rtfToHTML from "@iarna/rtf-to-html";
import { ContentState, convertFromRaw, convertToRaw, EditorState, convertFromHTML } from "draft-js";
import DraftJsEditor from "@draft-js-plugins/editor";
import { Box, Typography } from "@mui/material";
import { VrsButton, VrsAvatar, VrsDivider, dateFormats } from "@veris-health/web-core";
import { useProfile } from "../../../../context/profile";
import { linkDecorator } from "../../../../ui/components/Editor/Link/Link";
import { NotificationDetailItem } from "../../../Notifications/components/VrsNotificationDetails/SymptomNotificationDetails";
import { Toolbar } from "../../../../ui/components/Editor/Toolbar";
import { Editor } from "../../../../ui/components/Editor";
import { utcToLocal } from "../../../../utils/date";

interface ClinicalNoteEditorProps {
  note: ClinicalNote;
}

export function ClinicalNoteEditor({ note }: ClinicalNoteEditorProps): JSX.Element {
  const [editMode, setEditMode] = useState(false);
  const [editorState, setEditorState] = useState<EditorState | null>();
  const [readOnly, setReadOnly] = useState(true);
  const editorRef = useRef<DraftJsEditor>(null);
  const profile = useProfile();

  const { time_created: timeCreated, payload_mime_type: mimeType, payload, author } = note || {};

  useEffect(() => {
    if (note) {
      if (mimeType === "application/rtf" || mimeType === "text/rtf") {
        rtfToHTML.fromString(payload, (err: string, parsedHtml: string) => {
          if (parsedHtml) {
            try {
              const { contentBlocks, entityMap } = convertFromHTML(parsedHtml);
              const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
              setEditorState(EditorState.createWithContent(contentState, linkDecorator));
            } catch (e) {
              setEditorState(
                EditorState.createWithContent(
                  ContentState.createFromText(parsedHtml),
                  linkDecorator,
                ),
              );
            }
          }
        });
      } else {
        try {
          const parsed = JSON.parse(payload || "");
          setEditorState(EditorState.createWithContent(convertFromRaw(parsed), linkDecorator));
        } catch (e) {
          setEditorState(
            EditorState.createWithContent(
              ContentState.createFromText(payload || ""),
              linkDecorator,
            ),
          );
        }
      }
      setEditMode(false);
      setReadOnly(true);
    }
  }, [note]);

  const noteDescription = editorState?.getCurrentContent().getPlainText("") || "";

  const onSave = () => {
    if (profile && editorState) {
      const row = convertToRaw(editorState.getCurrentContent());
      setEditMode(false);
      setReadOnly(true);
      setEditorState(EditorState.createWithContent(convertFromRaw(row), linkDecorator));
    }
  };
  return (
    <Box
      width="calc(100% - 18rem);"
      height="70vh"
      sx={{
        borderLeft: (theme) => `1px solid ${theme.veris.colors.neutrals["grey-2"]}`,
        maxHeight: "70vh",
        padding: (theme) => theme.spacing(1.2, 3),
        marginTop: "4px",
        overflowY: "auto",
      }}
    >
      <Box display="flex" justifyContent="space-between">
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              padding: (theme) => theme.spacing(0.75),
            }}
          >
            <Box display="grid" gridTemplateColumns="repeat(8, 1fr)" gap={2} mb={1}>
              <NotificationDetailItem
                label="Date Created"
                value={
                  note?.time_created !== undefined
                    ? utcToLocal(note.time_created).format(
                        `${dateFormats["MMMM DD, YYYY"]} • ${dateFormats["h:mm"]} A`,
                      )
                    : "N/A"
                }
              />

              <NotificationDetailItem
                label="Last Modified"
                value={
                  timeCreated !== undefined
                    ? utcToLocal(timeCreated).format(
                        `${dateFormats["MMMM DD, YYYY"]} • ${dateFormats["h:mm"]} A`,
                      )
                    : "N/A"
                }
              />
              <Box gridColumn="span 2" style={{ alignSelf: "center" }}>
                <Typography
                  variant="subtitle2"
                  color={(theme) => theme.veris.colors.neutrals["grey-mid"]}
                >
                  Added by
                </Typography>
              </Box>
              {author?.length && (
                <Box gridColumn="span 3" display="flex" style={{ alignItems: "center" }}>
                  <VrsAvatar size={25} name={author[0].name} />
                  <Typography
                    variant="subtitle2"
                    style={{ textTransform: "capitalize", marginLeft: "0.5rem" }}
                  >
                    {author[0].name}
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>

      <VrsDivider height="4px" orientation="horizontal" />
      {editorState && (
        <Box
          sx={{
            border: (theme) =>
              editMode ? `1px solid ${theme.veris.colors.neutrals["grey-2"]}` : "none",
            borderRadius: (theme) => +theme.shape.borderRadius * 0.5,
            padding: (theme) => theme.spacing(0.75),
            overflow: "hidden",
            marginTop: "8px",
          }}
        >
          {editMode && (
            <Toolbar
              editorState={editorState}
              setEditorState={setEditorState}
              setReadOnly={setReadOnly}
            />
          )}

          <Editor
            readOnly={readOnly || !editMode}
            editorRef={editorRef}
            editorState={editorState}
            onChange={(e: EditorState) => setEditorState(e)}
          />
        </Box>
      )}
      {editMode && (
        <Box
          sx={{
            justifyContent: "center",
            marginTop: "0.5rem",
            display: "flex",
          }}
        >
          <VrsButton
            type="submit"
            buttonType="primary"
            disabled={noteDescription.trim().length === 0}
            onClick={onSave}
          >
            Save changes
          </VrsButton>
        </Box>
      )}
    </Box>
  );
}
