import { Grid, Typography } from "@mui/material";
import { VrsSelect } from "@veris-health/web-core";
import React from "react";
import { BillCode } from "../../../shared/slices/voipSlice";

export interface VideoBillingOptionSelectorProps {
  setSelectedCode: (code: string) => void;
  selectedCode?: string;
  billingCodes: BillCode[];
}

export function VideoBillingOptionSelector({
  setSelectedCode,
  selectedCode,
  billingCodes,
}: VideoBillingOptionSelectorProps): JSX.Element | null {
  const billingOptions = billingCodes.map((code) => {
    return {
      value: code.code,
      label: `${code.code} - ${code.text}`,
    };
  });

  return (
    <Grid item xs={12}>
      <Typography
        variant="subtitle2"
        py={1}
        sx={{ color: (theme) => `${theme.veris.colors.neutrals["grey-3"]}` }}
      >
        Select one of the billing options
      </Typography>
      <VrsSelect
        value={selectedCode}
        options={billingOptions}
        onSelected={(code) => setSelectedCode(code)}
        large
        innerSx={{ textTransform: "none" }}
        outerSx={{
          "& .MuiOutlinedInput-input.MuiSelect-select": {
            whiteSpace: "break-spaces",
          },
        }}
        capitalize={false}
      />
    </Grid>
  );
}
