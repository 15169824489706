import { Box, Dialog, Typography } from "@mui/material";
import {
  IconName,
  VrsButton,
  VrsDivider,
  VrsIcon,
  verisColors,
  dateFormats,
  VrsAvatar,
} from "@veris-health/web-core";
import React, { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import { useReactToPrint } from "react-to-print";
import { Datepicker } from "@mobiscroll/react";
import { VrsMultiselect } from "../../../Patients/components/PatientFilters/VrsMultiselect";
import { useCurrentPatient } from "../../../../hooks/useCurrentPatient";
import RawDataTable from "./components/RawDataTable";
import { exportFilterOptions, exportOptions } from "../../../../constants";
import { SymptomSummaries } from "./components/SymptomSummaries";
import { SymptomDetails } from "./components/SymptomDetails";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import {
  fetchUnifiedUserSensorsDataAsync,
  fetchUserSymptomsData,
  selectDateFrom,
  selectDateTo,
  selectMeasurementsStatus,
  selectUserSymptomsStatus,
  setFetchRange,
} from "../../../PatientDetailsMeasurements/measurementSlice";
import { getFutureDays } from "../../../../utils/date";
import { PhysiologicDataGraphs } from "./components/PhysiologicDataGraphs";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { VrsPatientInfo } from "../../../shared/interfaces";
import { selectAvatarById } from "../../../shared/slices/patientsSlice";

interface PDRExportPreviewProps {
  open: boolean;
  onClose: () => void;
}

export function PDFExportPreview({ open, onClose }: PDRExportPreviewProps): JSX.Element {
  const [exportOptionsSelected, setExportOptionsSelected] = useState<string[]>([
    exportOptions.SelectAll,
    exportOptions.DataGraphs,
    exportOptions.SymptomDetails,
    exportOptions.RawData,
    exportOptions.Symptoms,
  ]);
  const [openDatePicker, setOpenDatePicker] = useState<boolean>(false);
  const currentPatient = useCurrentPatient();
  const startDate = useAppSelector(selectDateFrom);
  const endDate = useAppSelector(selectDateTo);
  const [range, setRange] = useState<{ startDate: string; endDate: string }>({
    startDate: dayjs(startDate).add(1, "day").format(),
    endDate,
  });
  const isChartsDataLoading = useAppSelector(selectMeasurementsStatus);
  const isSymptomsTimelineLoading = useAppSelector(selectUserSymptomsStatus);
  const [symptomDetailsLoading, setSymptomDetailsLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(
    isChartsDataLoading === "loading" || isSymptomsTimelineLoading === "loading",
  );
  const avatar = useAppSelector(
    (state) => currentPatient && selectAvatarById(state, currentPatient?.id),
  );

  useEffect(() => {
    setLoading(
      isChartsDataLoading === "loading" ||
        isSymptomsTimelineLoading === "loading" ||
        symptomDetailsLoading,
    );
  }, [isChartsDataLoading, isSymptomsTimelineLoading, symptomDetailsLoading]);

  const dispatch = useAppDispatch();
  const futureDays = getFutureDays();
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current || null,
    documentTitle: currentPatient ? generatePdfDocumentTitle(currentPatient) : "Veris Health",
  });

  const isIncluded = (option: string) => {
    return exportOptionsSelected.includes(option);
  };

  const onInclude = (option: string) => {
    if (exportOptionsSelected.includes(option)) {
      if (exportOptionsSelected.includes(exportOptions.SelectAll))
        setExportOptionsSelected([
          ...exportOptionsSelected.filter((el) => el !== option && el !== exportOptions.SelectAll),
        ]);
      else setExportOptionsSelected([...exportOptionsSelected.filter((el) => el !== option)]);
    } else setExportOptionsSelected([...exportOptionsSelected, option]);
  };

  useEffect(() => {
    if (currentPatient) {
      dispatch(fetchUnifiedUserSensorsDataAsync({ id: currentPatient?.id }));
      dispatch(fetchUserSymptomsData({ userId: currentPatient?.id.toString() }));
    }
  }, [range.endDate, range.startDate]);
  const disablePdfExport = exportOptionsSelected.length === 0 || loading;

  return (
    <Dialog open={open} onClose={() => onClose()} fullWidth maxWidth="xxl">
      <Box pt={8} pb={8}>
        <Box display="flex" justifyContent="space-between" pb={4} px={6}>
          <Box sx={{ flexBasis: "50%" }}>
            <Typography variant="h3" pb={3}>
              Export Patient Data Summary
            </Typography>
            <Typography variant="body">
              To export the Patient Data Summary, please make a selection of the information and
              time period you would like to include in the report
            </Typography>
          </Box>

          <Box display="flex" gap={2} alignSelf="flex-start">
            <VrsButton buttonType="secondary" onClick={onClose}>
              Cancel
            </VrsButton>
            <VrsButton buttonType="primary" onClick={handlePrint} disabled={disablePdfExport}>
              Generate Patient Data PDF{" "}
              <VrsIcon
                name={IconName.DownloadActive}
                stroke="white"
                size={20}
                sx={{ marginX: 0.5 }}
              />
            </VrsButton>
          </Box>
        </Box>
        <Box display="flex" gap={3} alignItems="center" px={6}>
          <Box display="flex" flexDirection="column" gap={1} minWidth="200px">
            <Typography variant="body1" color={(theme) => theme.veris.colors.neutrals["grey-4"]}>
              Select Export Dates
            </Typography>
            <Box display="flex">
              <VrsIcon name={IconName.Calendar} stroke={verisColors.neutrals["grey-3"]} />
              <Datepicker
                invalid={futureDays}
                anchorAlign="end"
                value={[range.startDate, range.endDate]}
                controls={["calendar"]}
                dateFormat="MMM D, YYYY"
                dateWheels="|DDD MMM D, YYYY|"
                select="range"
                inputComponent="input"
                inputProps={{
                  placeholder: "Choose date",
                  readOnly: true,
                  disabled: exportOptionsSelected.length === 0,
                  className: "vrs-datepicker",
                  onClick: () => {
                    setOpenDatePicker(true);
                  },
                }}
                display="anchored"
                isOpen={openDatePicker}
                onClose={() => setOpenDatePicker(false)}
                onChange={(picker) => {
                  dispatch(
                    setFetchRange({
                      startDate: dayjs(picker.value[0]).subtract(1, "day").endOf("day").format(),
                      endDate: dayjs(picker.value[1]).endOf("day").format(),
                    }),
                  );

                  setRange({
                    startDate: dayjs(picker.value[0]).startOf("day").format(),
                    endDate: dayjs(picker.value[1]).endOf("day").format(),
                  });
                }}
                touchUi
                anchored
                maxRange={30}
              />
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" gap={1} minWidth="200px">
            <Typography variant="body1" color={(theme) => theme.veris.colors.neutrals["grey-4"]}>
              Export
            </Typography>
            <VrsMultiselect
              filters={exportOptionsSelected}
              filterOptions={exportFilterOptions}
              handleChange={(e) => {
                if (e[e.length - 1] === exportOptions.SelectAll)
                  setExportOptionsSelected([
                    exportOptions.SelectAll,
                    exportOptions.DataGraphs,
                    exportOptions.SymptomDetails,
                    exportOptions.RawData,
                    exportOptions.Symptoms,
                  ]);
                else if (
                  e.includes(exportOptions.SelectAll) &&
                  e.length < Object.keys(exportOptions).length
                ) {
                  setExportOptionsSelected([...e.filter((el) => el !== exportOptions.SelectAll)]);
                } else setExportOptionsSelected(e);
              }}
              emptyFilterValue="None"
            />
          </Box>
        </Box>
        <Box px={4.5} pt={5}>
          <VrsDivider height="2px" />{" "}
        </Box>

        <Box // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          ref={componentRef || null}
          px={6}
        >
          {currentPatient && (
            <Box m={1}>
              <VrsAvatar
                name={currentPatient.name}
                imageUrl={currentPatient.picture || avatar?.picture}
              />
              <Box display="flex" gap={1} alignItems="center">
                <Typography variant="bodySemibold">Name:</Typography>
                <Typography variant="body"> {currentPatient.name} </Typography>
              </Box>
              <Box display="flex" gap={1} alignItems="center">
                {" "}
                <Typography variant="bodySemibold"> Date of birth:</Typography>{" "}
                <Typography variant="body">
                  {currentPatient.dob
                    ? dayjs(currentPatient.dob).format(dateFormats["MM/DD/YYYY"])
                    : ""}
                </Typography>{" "}
              </Box>
              <Box display="flex" gap={1} alignItems="center">
                <Typography variant="bodySemibold">MRN:</Typography>
                <Typography variant="body"> {currentPatient.ehrId} </Typography>
              </Box>
              <Box display="flex" gap={1} alignItems="center">
                <Typography variant="bodySemibold">Diagnosis:</Typography>
                <Typography variant="body">
                  {" "}
                  {currentPatient.diagnosis?.cancerType || "N/A"}, Stage{" "}
                  {currentPatient.diagnosis?.cancerStage || "N/A"}{" "}
                </Typography>
              </Box>
            </Box>
          )}
          {currentPatient && isIncluded(exportOptions.DataGraphs) && (
            <PhysiologicDataGraphs
              title={exportOptions.DataGraphs}
              iconName={IconName.AtHomeDevice}
              include={isIncluded(exportOptions.DataGraphs)}
              currentPatient={currentPatient}
              fetchData={() =>
                dispatch(fetchUnifiedUserSensorsDataAsync({ id: currentPatient?.id }))
              }
              onInclude={() => {
                onInclude(exportOptions.DataGraphs);
              }}
            />
          )}
          {currentPatient && isIncluded(exportOptions.RawData) && (
            <RawDataTable
              currentPatient={currentPatient}
              title={exportOptions.RawData}
              iconName={IconName.RawDataIcon}
              include={isIncluded(exportOptions.RawData)}
              onInclude={() => {
                onInclude(exportOptions.RawData);
              }}
            />
          )}
          {currentPatient && isIncluded(exportOptions.Symptoms) && (
            <SymptomSummaries
              title={exportOptions.Symptoms}
              iconName={IconName.Stethoscope}
              include={isIncluded(exportOptions.Symptoms)}
              onInclude={() => {
                onInclude(exportOptions.Symptoms);
              }}
              currentPatient={currentPatient}
            />
          )}
          {currentPatient && isIncluded(exportOptions.SymptomDetails) && (
            <SymptomDetails
              title={exportOptions.SymptomDetails}
              iconName={IconName.Stethoscope}
              include={isIncluded(exportOptions.SymptomDetails)}
              onInclude={() => {
                onInclude(exportOptions.SymptomDetails);
              }}
              currentPatient={currentPatient}
              setLoadingSymptomDetails={setSymptomDetailsLoading}
            />
          )}
        </Box>
      </Box>
    </Dialog>
  );
}

function generatePdfDocumentTitle(patient: VrsPatientInfo): string {
  const dob = patient.dob ? dayjs(patient.dob).format(dateFormats["MM/DD/YYYY"]) : "";
  return `${patient?.name} ${dob}  ${patient?.ehrId}`;
}
