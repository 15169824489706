import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  EntityState,
  PayloadAction,
} from "@reduxjs/toolkit";
import {
  DiagnosticReportSummary,
  GetDiagnosticReportObsResponse,
  GetDiagnosticReportsResponse,
  GetObservationsToCompareResponse,
  ObservationToCompare,
} from "@veris-health/med-data-ms/lib/v1";
import { RootState } from "../../store";
import { Status } from "../shared/interfaces";
import { fetchDiagnosticReportsList } from "./api/patientRecordsApi";
import { fetchLabResultsDetails, getLabResultsComparison } from "./api/services/labresults.service";

export enum ResultEntries {
  LabTests = "Lab Tests",
  Imaging = "Imaging",
  Genomics = "Genomics",
  Pathology = "Pathology",
}
interface PatientRecordsState {
  labResultsDetailedList: {
    details: EntityState<GetDiagnosticReportObsResponse>;
    status: Status;
  };
  filteredDiagnosticReports: {
    details: EntityState<GetDiagnosticReportsResponse>;
    status: Status;
    searchPhrase: string;
  };
  diagnosticReports: {
    details: EntityState<GetDiagnosticReportsResponse>;
    status: Status;
  };
  selectedRecord?: DiagnosticReportSummary;
  labResultsComparisonData: {
    details: EntityState<GetObservationsToCompareResponse>;
    status: Status;
  };
}
const labResultsDetailedListAdapter = createEntityAdapter<GetDiagnosticReportObsResponse>();
const filteredDiagnosticReportsAdapter = createEntityAdapter<GetDiagnosticReportsResponse>();
const diagnosticReportsAdapter = createEntityAdapter<GetDiagnosticReportsResponse>();
const labResultsComparisonDataAdapter = createEntityAdapter<GetObservationsToCompareResponse>();

const initialState: PatientRecordsState = {
  labResultsDetailedList: {
    details: labResultsDetailedListAdapter.getInitialState(),
    status: "idle",
  },
  filteredDiagnosticReports: {
    searchPhrase: "",
    details: filteredDiagnosticReportsAdapter.getInitialState(),
    status: "idle",
  },
  diagnosticReports: {
    details: diagnosticReportsAdapter.getInitialState(),
    status: "idle",
  },
  labResultsComparisonData: {
    details: labResultsComparisonDataAdapter.getInitialState(),
    status: "idle",
  },
};

export const loadLabResultsDetailedListAsync = createAsyncThunk(
  "patientRecords/fetchLabResultsDetailedList",
  async ({ patientId, reportId }: { patientId: number; reportId: string }) => {
    const response = await fetchLabResultsDetails(patientId, reportId);
    return { id: reportId, ...response };
  },
);

export const loadFilteredLabResultsAsync = createAsyncThunk(
  "patientRecords/filterLabResults",
  async ({ patientId, searchQuery }: { patientId: number; searchQuery: string }) => {
    const response = await fetchDiagnosticReportsList(patientId, searchQuery);
    return { id: patientId, ...response };
  },
);

export const loadLabResultsComparisonDataAsync = createAsyncThunk(
  "patientRecords/fetchLabResultsComparisonData",
  async ({
    patientId,
    date,
    codes,
    numberOfObservations,
  }: {
    patientId: number;
    date: string;
    codes: string[];
    numberOfObservations?: number;
  }) => {
    const response = await getLabResultsComparison(patientId, date, codes, numberOfObservations);

    return { id: codes[0], ...response };
  },
);

export const loadDiagnosticReportsAsync = createAsyncThunk(
  "patientRecords/fetchdiagnosticReportsList",
  async (patientId: number) => {
    const response = await fetchDiagnosticReportsList(patientId);
    return { id: patientId, ...response };
  },
);

export const patientRecordsSlice = createSlice({
  name: "patientRecords",
  initialState,
  reducers: {
    setSelectedRecord: (state, action: PayloadAction<DiagnosticReportSummary | undefined>) => {
      state.selectedRecord = action.payload;
    },
    setSearchPhrase: (state, action: PayloadAction<string>) => {
      state.filteredDiagnosticReports.searchPhrase = action.payload;
      state.filteredDiagnosticReports.status = "loading";
    },
    clearFilteredResults: (state) => {
      state.diagnosticReports.status = "idle";
      state.filteredDiagnosticReports.status = "idle";
      filteredDiagnosticReportsAdapter.removeAll(state.filteredDiagnosticReports.details);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadLabResultsDetailedListAsync.pending, (state) => {
        state.labResultsDetailedList.status = "loading";
      })
      .addCase(loadLabResultsDetailedListAsync.fulfilled, (state, action) => {
        state.labResultsDetailedList.status = "idle";
        labResultsDetailedListAdapter.upsertOne(
          state.labResultsDetailedList.details,
          action.payload,
        );
      })
      .addCase(loadLabResultsDetailedListAsync.rejected, (state) => {
        state.labResultsDetailedList.status = "failed";
      })
      .addCase(loadDiagnosticReportsAsync.pending, (state) => {
        state.diagnosticReports.status = "loading";
      })
      .addCase(loadDiagnosticReportsAsync.fulfilled, (state, action) => {
        state.diagnosticReports.status = "idle";
        diagnosticReportsAdapter.upsertOne(state.diagnosticReports.details, action.payload);
      })
      .addCase(loadDiagnosticReportsAsync.rejected, (state) => {
        state.diagnosticReports.status = "failed";
      })
      .addCase(loadLabResultsComparisonDataAsync.pending, (state) => {
        state.labResultsComparisonData.status = "loading";
      })
      .addCase(loadLabResultsComparisonDataAsync.fulfilled, (state, action) => {
        state.labResultsComparisonData.status = "idle";
        if (state.labResultsComparisonData.details.ids.includes(action.payload.id))
          labResultsComparisonDataAdapter.updateOne(state.labResultsComparisonData.details, {
            id: action.payload.id,
            changes: { ...action.payload },
          });
        else
          labResultsComparisonDataAdapter.addOne(
            state.labResultsComparisonData.details,
            action.payload,
          );
      })
      .addCase(loadLabResultsComparisonDataAsync.rejected, (state) => {
        state.labResultsComparisonData.status = "failed";
      })
      .addCase(loadFilteredLabResultsAsync.pending, (state) => {
        state.filteredDiagnosticReports.status = "loading";
      })
      .addCase(loadFilteredLabResultsAsync.fulfilled, (state, action) => {
        filteredDiagnosticReportsAdapter.setOne(
          state.filteredDiagnosticReports.details,
          action.payload,
        );
        state.filteredDiagnosticReports.status = "idle";
      })
      .addCase(loadFilteredLabResultsAsync.rejected, (state) => {
        state.filteredDiagnosticReports.status = "failed";
      });
  },
});

export const { setSelectedRecord, clearFilteredResults, setSearchPhrase } =
  patientRecordsSlice.actions;

export const selectSearchPhrase = ({ patientRecords }: RootState): string =>
  patientRecords.filteredDiagnosticReports.searchPhrase;

export const selectLabResultsDetailedList = (
  rootState: RootState,
  id: string | undefined,
): GetDiagnosticReportObsResponse | undefined => {
  if (id) {
    return labResultsDetailedListAdapter
      .getSelectors<RootState>((state) => state.patientRecords.labResultsDetailedList.details)
      .selectById(rootState, id);
  }
  return undefined;
};

export const selectLabResultsDetailedListStatus = ({ patientRecords }: RootState): Status =>
  patientRecords.labResultsDetailedList.status;

export const selectFilteredLabResultsStatus = ({ patientRecords }: RootState): Status =>
  patientRecords.filteredDiagnosticReports.status;

export const selectDiagnosticReports = (
  rootState: RootState,
  id: number | undefined,
): GetDiagnosticReportsResponse | undefined => {
  if (id) {
    return filteredDiagnosticReportsAdapter
      .getSelectors<RootState>((state) =>
        state.patientRecords.filteredDiagnosticReports.searchPhrase.length >= 3
          ? state.patientRecords.filteredDiagnosticReports.details
          : state.patientRecords.diagnosticReports.details,
      )
      .selectById(rootState, id);
  }
  return undefined;
};

export const selectDiagnosticReportsStatus = ({ patientRecords }: RootState): Status =>
  patientRecords.diagnosticReports.status;

export const selectLabResultsComparisonData = (
  rootState: RootState,
  id: string | undefined,
): ObservationToCompare[] | undefined => {
  if (id) {
    return (
      labResultsComparisonDataAdapter
        .getSelectors<RootState>((state) => state.patientRecords.labResultsComparisonData.details)
        .selectById(rootState, id)?.observations || []
    );
  }
  return undefined;
};

export const selectLabResultsComparisonStatus = ({ patientRecords }: RootState): Status =>
  patientRecords.labResultsComparisonData.status;

export const selectSelectedRecord = ({
  patientRecords,
}: RootState): DiagnosticReportSummary | undefined => patientRecords.selectedRecord;

export default patientRecordsSlice.reducer;
