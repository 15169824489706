import axios, { AxiosError } from "axios";

export async function retryIf404<T>(fn: () => Promise<T>, maxRetries = 1): Promise<T> {
  try {
    const response = await fn();
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      if (axiosError.response && axiosError.response.status === 404 && maxRetries > 0) {
        await new Promise((resolve) => setTimeout(resolve, 1000));

        return retryIf404(fn, maxRetries - 1);
      }
    }
    throw error;
  }
}
