import { Autocomplete, Chip, styled, TextField, TextFieldProps } from "@mui/material";
import React, { HTMLAttributes } from "react";
import { MedStaffProfileItem } from "@veris-health/user-ms/lib/v1";
import { IconName } from "@veris-health/web-core";
import { VrsMedStaffProfileModel } from "../../../features/shared/interfaces";
import { SensorAutocompleteValue } from "../../../features/Tasks/utils/constants";

export type AutoCompleteMultiselectOptionType =
  | MedStaffProfileItem
  | VrsMedStaffProfileModel
  | { name: string; id: number }
  | SensorAutocompleteValue;

export type AutoCompleteMultiselectOption<T extends AutoCompleteMultiselectOptionType> = {
  label: string;
  value: T;
  icon?: IconName;
};

export interface VrsChipMultiselectProps<T extends AutoCompleteMultiselectOptionType> {
  initialValue: AutoCompleteMultiselectOption<T>[];
  onChange: (event: React.SyntheticEvent, value: AutoCompleteMultiselectOption<T>[]) => void;
  options: AutoCompleteMultiselectOption<T>[];
  renderOption?: (
    props: HTMLAttributes<HTMLLIElement>,
    option: AutoCompleteMultiselectOption<T>,
  ) => React.ReactNode;
  placeholder?: string;
  disabledOptionId?: number;
  disableClearable?: boolean;
  disabled?: boolean;
  id?: string;
  onBlur?: React.FocusEventHandler<HTMLDivElement>;
  fullWidth?: boolean;
  backgroundColor?: string;
  width?: string;
}

const StyledTextField = styled(TextField)<TextFieldProps>(({ theme }) => ({
  ".MuiInput-root:before, .MuiInput-root:after, .MuiInput-root:hover, .MuiInput-root:hover:not(.Mui-disabled):before":
    {
      border: "none",
    },
  ".MuiInput-root.MuiInputBase-sizeSmall": {
    ".MuiInput-input": {
      ...theme.typography.body,
      height: "20px",
      paddingBottom: 0,
      [theme.breakpoints.down("lg")]: {
        padding: theme.spacing(0.725, 0.58, 0, 0),
      },
    },
  },
}));

export function VrsChipMultiselect<T extends AutoCompleteMultiselectOptionType>({
  initialValue,
  placeholder = "",
  onChange,
  options,
  renderOption,
  disabledOptionId,
  disableClearable = false,
  disabled = false,
  id,
  onBlur,
  fullWidth,
  backgroundColor,
  width,
  ...rest
}: VrsChipMultiselectProps<T>): JSX.Element {
  return (
    <Autocomplete
      {...rest}
      id={id}
      sx={{
        borderRadius: (theme) => +theme.shape.borderRadius * 0.375,
        border: (theme) => `1px solid ${theme.veris.colors.neutrals["grey-2"]} `,
        padding: (theme) => theme.spacing(1, 1.25),
        width: width ?? "100%",
      }}
      multiple
      disableClearable={disableClearable}
      filterSelectedOptions
      isOptionEqualToValue={(option, value) => option.value?.id === value.value?.id}
      value={initialValue}
      onChange={onChange}
      style={{
        fontWeight: 400,
      }}
      options={options}
      renderOption={renderOption}
      ListboxProps={{
        style: {
          overflowY: "scroll",
          height: "200px",
        },
      }}
      renderInput={(params) => (
        <StyledTextField
          {...params}
          placeholder={placeholder}
          size="small"
          variant="standard"
          disabled={disabled}
        />
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => {
          return (
            <Chip
              variant="outlined"
              label={option.label}
              sx={{
                fontWeight: 400,
                fontSize: "1rem",
                backgroundColor: backgroundColor ?? "default",
                border: `1px solid ${backgroundColor ?? "default"}`,
              }}
              {...getTagProps({ index })}
              disabled={option.value.id === disabledOptionId || disabled}
            />
          );
        })
      }
      disabled={disabled}
      onBlur={onBlur}
      fullWidth={fullWidth}
    />
  );
}
