import { flatten } from "lodash";
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
  PayloadAction,
} from "@reduxjs/toolkit";
import { AllergyCategoryEnum, GetAllergyResponse } from "@veris-health/med-data-ms/lib/v1";
import { RootState } from "../../store";
import { Status } from "../shared/interfaces";
import { fetchAllergiesData } from "./api/allergiesApi";
import { localizedLogout, logout } from "../shared/slices/authSlice";

interface NormalizedAllergies {
  allergies: GetAllergyResponse[];
}

export interface AllergiesState {
  allergies: EntityState<NormalizedAllergies>;
  status: Status;
  typeFilter?: AllergyCategoryEnum;
  nameFilter?: GetAllergyResponse;
}

const allergiesAdapter = createEntityAdapter<NormalizedAllergies>();

const initialState: AllergiesState = {
  allergies: allergiesAdapter.getInitialState(),
  status: "idle",
};

export const loadPatientAllergiesAsync = createAsyncThunk(
  "dashboard/fetchPatientAllergiesData",
  async (patientId: number) => {
    const response = await fetchAllergiesData(patientId);
    return { id: patientId, allergies: response };
  },
);
export const allergiesSlice = createSlice({
  name: "Allergy",
  initialState,
  reducers: {
    setTypeFilter: (state, action) => {
      state.typeFilter = action.payload;
    },
    setNameFilter: (state, action: PayloadAction<GetAllergyResponse | undefined>) => {
      state.nameFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadPatientAllergiesAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(loadPatientAllergiesAsync.fulfilled, (state, { payload }) => {
        state.status = "idle";
        allergiesAdapter.upsertOne(state.allergies, payload);
      })
      .addCase(loadPatientAllergiesAsync.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(logout, () => {
        return initialState;
      })
      .addCase(localizedLogout, () => {
        return initialState;
      });
  },
});

export const { setTypeFilter, setNameFilter } = allergiesSlice.actions;

export const selectAllergies = (rootState: RootState, id: number): GetAllergyResponse[] => {
  return (
    allergiesAdapter
      .getSelectors<RootState>((state) => state.allergies.allergies)
      .selectById(rootState, id)?.allergies || []
  );
};

export const selectAllergyTypeFilter = ({
  allergies,
}: RootState): AllergyCategoryEnum | undefined => allergies.typeFilter;

export const selectAllergyNameFilter = ({ allergies }: RootState): GetAllergyResponse | undefined =>
  allergies.nameFilter;

export const selectFilteredAllergies = createSelector(
  [selectAllergyTypeFilter, selectAllergyNameFilter, selectAllergies],
  (typeFilter, nameFilter, allergies) =>
    allergies
      .filter((allergy: GetAllergyResponse) => !nameFilter || nameFilter.id === allergy.id)
      .filter(
        (allergy: GetAllergyResponse) =>
          !typeFilter || (allergy.type && allergy.type.includes(typeFilter)),
      )
      .sort((a) => {
        if (a.type && a.type.includes(AllergyCategoryEnum.Medication)) {
          return -1;
        }
        return 1;
      }),
);

export const selectPatientAllergyTypes = createSelector([selectAllergies], (allergies) =>
  flatten([
    flatten(allergies.map((allergy) => allergy.type))
      .filter((value, index, arr) => arr.indexOf(value) === index)
      .filter((value) => !!value) as string[],
  ]),
);

export const selectAllergiesSuccessStatus = ({ allergies }: RootState): Status => allergies.status;

export default allergiesSlice.reducer;
