import { ContentBlock, DraftBlockType } from "draft-js";
import { EditorPlugin, PluginFunctions } from "@draft-js-plugins/editor";

import { ListItemBlock } from "./ListItemBlock";

export { ListItemBlock } from "./ListItemBlock";

const blockTypes: DraftBlockType[] = ["unordered-list-item", "ordered-list-item"];

export default (): EditorPlugin => {
  // eslint-disable-next-line consistent-return
  const blockRendererFn = (contentBlock: ContentBlock, pluginFunctions: PluginFunctions) => {
    const type = contentBlock.getType();
    if (blockTypes.includes(type)) {
      return {
        component: ListItemBlock,
        editable: !pluginFunctions.getReadOnly(),
        props: { pluginFunctions },
      };
    }
  };
  return {
    blockRendererFn,
  };
};

export * from "./ListItemBlock";
