import { Box, Typography } from "@mui/material";
import React from "react";
import { VrsDivider } from "@veris-health/web-core";
import { VrsPatientInfoCard } from "../../../../ui/components/VrsPatientInfoCard";
import { calculateTimeFromNow, utcToLocal } from "../../../../utils/date";
import { VrsPatientInfo } from "../../../shared/interfaces";
import { VrsSystemCard } from "../VrsSystemCard";

interface NotificationHeaderProps {
  notificationDetails: VrsPatientInfo;
  date: string;
  systemNotification: boolean;
}

export const NotificationHeader = ({
  notificationDetails,
  date,
  systemNotification,
}: NotificationHeaderProps): JSX.Element => {
  return (
    <>
      <Box display="flex" flexDirection="row" p={1} mb={0.1} mr={1}>
        {systemNotification ? (
          <VrsSystemCard backgroundColor="transparent" />
        ) : (
          <VrsPatientInfoCard
            patientInfo={notificationDetails}
            backgroundColor="transparent"
            styles={{ diagnosis: { maxWidth: "350px" } }}
          />
        )}
        <Typography
          variant="body"
          color={(theme) => theme.veris.colors.neutrals["grey-mid"]}
          pt={1}
        >
          {calculateTimeFromNow(utcToLocal(date).toISOString())}
        </Typography>
      </Box>
      <VrsDivider height="1px" orientation="horizontal" />
    </>
  );
};
