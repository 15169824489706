import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { UserQueryParams, VrsPatientInfo } from "../../features/shared/interfaces";
import { useAppSelector } from "../useAppSelector";
import { selectPatientsMap } from "../../features/shared/slices/patientsSlice";
import { getPatientByIdAsync } from "../../features/shared/slices/asyncThunks";
import { useAppDispatch } from "../useAppDispatch";

export function useCurrentPatient(id?: number): VrsPatientInfo | undefined {
  const [patient, setPatient] = useState<VrsPatientInfo>();
  const { userId } = useParams<UserQueryParams>();
  const patients = useAppSelector(selectPatientsMap);
  const dispatch = useAppDispatch();
  const patientId = id || userId;

  useEffect(() => {
    if (patientId && !Number.isNaN(+patientId) && patients) {
      const currentPatient = patients[Number(patientId)];
      if (currentPatient) {
        setPatient(currentPatient);
      } else {
        dispatch(getPatientByIdAsync({ userId: +patientId })).then((res) => {
          if ("error" in res) {
            setPatient(undefined);
          }
        });
      }
    } else {
      setPatient(undefined);
    }
  }, [patientId, patients]);

  return patient;
}
