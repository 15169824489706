export const routeParams = [":userId", ":hospitalId", ":notificationId"] as const;
export const routeParamsKeys = ["userId", "hospitalId", "notificationId"] as const;
export type RouteParams = typeof routeParams[number];
export type RouteParamsKeys = typeof routeParamsKeys[number];

// NOTE: use this utility type for destructuring route param value in usePrams hook
// along with typescript Pick<> utility if you want to pick subset of values
// const { "key-name": "key-alias" } = useParams<Pick<RouteParamsKeysValues, "key-name">>();
export type RouteParamsKeysValues = {
  [k in RouteParamsKeys]: string;
};

export const USER_ID: RouteParams = ":userId";
export const NOTIFICATION_ID: RouteParams = ":notificationId";
export const HOSPITAL_ID: RouteParams = ":hospitalId";

export const Routes = {
  STAFF_REGISTRATION: "/invite",
  SUCCESSFUL_REGISTRATION: "/successful-registration",
  STAFF_LOGIN: "/staff-login",
  MOBILE_LANDING_PAGE: "/mobile-landing-page",
  DASHBOARD: "/dashboard",
  PATIENTS: "/patients",
  NEW_PATIENTS: "/patients/new",
  INPATIENTS: "/patients/inpatients",
  SEVERE_SYMPTOM_PATIENTS: "/patients/severe-symptom",
  SYMPTOM_REPORT: "/patients/symptom-report",
  QOL_NO_CHANGE: "/patients/qol/no-change",
  QOL_IMPROVE: "/patients/qol/improve",
  QOL_WORSEN: "/patients/qol/worsen",
  PATIENT_DETAILS: `/patient-details/${USER_ID}`,
  PATIENT_GOALS: `/patient-details/${USER_ID}/goals`,
  PATIENT_ALLERGIES: `/patient-details/${USER_ID}/allergies`,
  NOTIFICATIONS_DETAILS: `/notifications/${NOTIFICATION_ID}`,
  CALENDAR: "/calendar",
  DOCTOR_PROFILE: "/doctor-profile",
  COMMUNICATION: "/communication",
  VIDEO_COMMUNICATION: `/video-communication/${USER_ID}`,
  DOCTOR_PROFILE_HOSPITAL_STAFF: `/doctor-profile/hospitals/${HOSPITAL_ID}/medical-staff`,
  LOGIN_OTP_CODE: "/staff-login-otp",
  PASSWORD_EXPIRATION: "/expired-password",
  FORGOT_PASSWORD: "/forgot-password",
  TASKS: "/tasks",
  EMPTY: " ",
} as const;

export const replaceRouteParam = (
  route: RouteDictionaryKeys,
  routeParam: RouteParams,
  routeValue: string,
): string => Routes[route].replace(routeParam, routeValue);

export type RouteDictionaryKeys = keyof typeof Routes;

export type RouteDictionaryValues =
  | typeof Routes.STAFF_REGISTRATION
  | typeof Routes.SUCCESSFUL_REGISTRATION
  | typeof Routes.STAFF_LOGIN
  | typeof Routes.MOBILE_LANDING_PAGE
  | typeof Routes.DASHBOARD
  | typeof Routes.PATIENTS
  | typeof Routes.NEW_PATIENTS
  | typeof Routes.QOL_NO_CHANGE
  | typeof Routes.QOL_IMPROVE
  | typeof Routes.QOL_WORSEN
  | typeof Routes.INPATIENTS
  | typeof Routes.SEVERE_SYMPTOM_PATIENTS
  | typeof Routes.SYMPTOM_REPORT
  | typeof Routes.PATIENT_DETAILS
  | typeof Routes.PATIENT_GOALS
  | typeof Routes.PATIENT_ALLERGIES
  | typeof Routes.NOTIFICATIONS_DETAILS
  | typeof Routes.CALENDAR
  | typeof Routes.DOCTOR_PROFILE
  | typeof Routes.VIDEO_COMMUNICATION
  | typeof Routes.COMMUNICATION
  | typeof Routes.DOCTOR_PROFILE_HOSPITAL_STAFF
  | typeof Routes.LOGIN_OTP_CODE
  | typeof Routes.FORGOT_PASSWORD
  | typeof Routes.PASSWORD_EXPIRATION
  | typeof Routes.TASKS
  | typeof Routes.EMPTY;
