import React from "react";
import { Box, Theme, Typography, useMediaQuery } from "@mui/material";
import { ChatTitleProps, ChatType } from "../../NavBarLayoutContainer";

export const ChatTitle = ({ type, name }: ChatTitleProps): JSX.Element => {
  const isMediumScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("xl"));
  return (
    <Box
      display="flex"
      pt={isMediumScreen ? 1.5 : 1}
      pl={["Patient", "Group"].includes(type) ? undefined : 1.5}
    >
      <Typography variant="h2">
        {type} {type !== ChatType.CHATHISTORY && "Chat"}
      </Typography>
      {name && (
        <>
          <Typography variant="h2" p={(theme) => theme.spacing(0, 1)}>
            •
          </Typography>
          <Typography variant="h2">{name}</Typography>
        </>
      )}
    </Box>
  );
};
