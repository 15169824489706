import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { CareTeamMemberInfo, SupportTeamMember } from "@veris-health/user-ms/lib/v1";
import { ChatThreadInfo, ThreadParticipantInfo } from "@veris-health/communication-ms/lib/v1";
import { useHistory } from "react-router-dom";
import { VrsChatHeader } from "../components/VrsChatHeader";
import { replaceRouteParam, Routes } from "../../../routes-config";
import { VrsTeamMemberSelection } from "../components/VrsTeamMemberSelection";
import { getFilteredTeamMembers } from "../../shared/helpers";
import { VrsGroupChatHeader } from "../components/VrsGroupChatHeader";
import {
  createChatThreadAsync,
  getPrivateChatAsync,
  joinChatThreadAsync,
  resetThreadId,
  updateThreadAsync,
} from "../store";
import {
  getPatientCareAndSupportTeamsAsync,
  PatientCareTeam,
  selectCareTeamStatus,
} from "../../shared/slices/careTeamSlice";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { VrsPatientInfo } from "../../shared/interfaces";
import { useChatLabels } from "../hooks/useChatParticipants";
import { useChatBilling } from "../../../hooks/useChatBilling";

const getCareTeams = (careTeams?: PatientCareTeam) => {
  return careTeams && careTeams.careTeam
    ? careTeams.careTeam.map((member) => ({
        name: member.full_name,
        id: member.id,
        image: member.full_name,
        profession: member.profession,
      }))
    : [];
};

interface ChatHeaderContainerProps {
  currentThread: ChatThreadInfo | undefined;
  userId: string;
  currentPatient: VrsPatientInfo | undefined;
  careTeams: PatientCareTeam | undefined;
  showInteractions: boolean;
  restorePublicThread?: (patientId: number) => void | undefined;
  setPatientInGroupThread?: (patient?: ThreadParticipantInfo) => void;
}

export const ChatHeaderContainer = ({
  currentThread,
  currentPatient,
  userId,
  careTeams,
  restorePublicThread,
  setPatientInGroupThread,
  showInteractions,
}: ChatHeaderContainerProps): JSX.Element => {
  const [showMembersModal, setShowMembersModal] = useState(false);
  const [membersModalType, setMembersModalType] = useState<"care" | "support">("care");
  const [membersModalSearchValue, setMembersModalSearchValue] = useState<string>("");

  useChatBilling(currentThread);

  const careTeamStatus = useAppSelector(selectCareTeamStatus);

  const { label, caption } = useChatLabels(currentThread?.participants);

  const history = useHistory();
  const dispatch = useAppDispatch();

  const handleGroupThreadActions = (members: SupportTeamMember[] | CareTeamMemberInfo[]) => {
    dispatch(resetThreadId());
    if (currentThread && currentThread.participants.length === 2) {
      if (currentPatient) {
        dispatch(
          createChatThreadAsync({
            id: +userId,
            participants: [
              {
                user_id: currentPatient.id,
              },
              ...members.map((member) => ({
                user_id: member.id,
              })),
            ],
          }),
        ).then(() => {
          history.push(`${Routes.COMMUNICATION}/${currentThread?.id}`);
          setShowMembersModal(false);
        });
      }
    } else {
      dispatch(
        joinChatThreadAsync({
          threadId: String(currentThread?.id),
          id: +userId,
          participants: members.map((member) => ({
            user_id: member.id,
          })),
        }),
      )
        .then(() => {
          dispatch(
            updateThreadAsync({ userId: String(userId), threadId: String(currentThread?.id) }),
          );
        })
        .then(() => {
          history.push(`${Routes.COMMUNICATION}/${currentThread?.id}`);
          setShowMembersModal(false);
        });
    }
  };

  const handleGroupModalActions = (
    type: "care" | "support",
    patient?: ThreadParticipantInfo,
  ): void => {
    if (patient && setPatientInGroupThread) {
      dispatch(getPatientCareAndSupportTeamsAsync(patient.user_id));
      setPatientInGroupThread(patient);
      setShowMembersModal(true);
      setMembersModalType(type);
    }
  };

  const handlePrivateChat = (show: boolean, id: number) => {
    if (show) {
      dispatch(getPrivateChatAsync({ patientId: id, userId }));
    } else {
      dispatch(resetThreadId());
      if (restorePublicThread) restorePublicThread(id);
    }
  };

  useEffect(() => {
    if (setPatientInGroupThread) setPatientInGroupThread(undefined);
  }, [currentThread?.id]);

  return (
    <>
      {currentThread && currentThread.id && currentPatient && (
        <Box overflow="visible">
          <VrsChatHeader
            participantInfo={currentPatient}
            publicChat={!currentThread.private}
            careTeam={getCareTeams(careTeams)}
            onPrivateClick={() => handlePrivateChat(true, currentPatient.id)}
            onPublicClick={() => handlePrivateChat(false, currentPatient.id)}
            onAddSupportTeamMember={() => {
              setShowMembersModal(true);
              setMembersModalType("support");
            }}
            onAddCareTeam={() => {
              setShowMembersModal(true);
              setMembersModalType("care");
            }}
            onPatientClick={(id) =>
              history.push(replaceRouteParam("PATIENT_DETAILS", ":userId", id.toString()))
            }
            showInteractions={showInteractions}
            userId={userId}
          />
        </Box>
      )}
      {showInteractions && (
        <VrsTeamMemberSelection
          type={membersModalType}
          patientCareTeam={getFilteredTeamMembers(
            membersModalType,
            membersModalSearchValue,
            currentThread?.participants,
            careTeams,
          )}
          searchValue={membersModalSearchValue}
          onSearchValueChange={(e) => setMembersModalSearchValue(e.target.value)}
          onAddMembers={handleGroupThreadActions}
          status={careTeamStatus}
          onClose={() => {
            setShowMembersModal(false);
          }}
          open={showMembersModal}
        />
      )}
      {currentThread && currentThread.id && !currentPatient && (
        <VrsGroupChatHeader
          caption={caption}
          label={label}
          participants={currentThread?.participants || []}
          onAddSupportTeamMembers={(patient) => {
            handleGroupModalActions("support", patient);
          }}
          onAddCareTeamMembers={(patient) => {
            handleGroupModalActions("care", patient);
          }}
        />
      )}
    </>
  );
};
