import React from "react";
import { MedStaffProfileItem } from "@veris-health/user-ms/lib/v1";
import { Box, CircularProgress, Typography } from "@mui/material";
import { capitalize } from "lodash";
import { VrsErrorMsg, VrsSelect } from "@veris-health/web-core";
import { Status, VrsMedStaffProfileModel } from "../../shared/interfaces";
import { useProfile } from "../../../context/profile";

interface DoctorPickerProps {
  medStaff: MedStaffProfileItem[];
  onSelect: (value: string) => void;
  status: Status;
  onRetry: () => void;
  selectedUser: MedStaffProfileItem | VrsMedStaffProfileModel | undefined;
}

export const DoctorPicker = ({
  medStaff,
  onSelect,
  status,
  onRetry,
  selectedUser,
}: DoctorPickerProps): JSX.Element => {
  const userProfile = useProfile();
  const currentUserOption = {
    value: userProfile ? userProfile.id.toString() : "",
    label: userProfile ? userProfile.fullName : "",
  };
  const displayFullName = selectedUser
    ? `${selectedUser.first_name} ${selectedUser.last_name}`
    : userProfile?.fullName;

  return (
    <>
      {status === "idle" && userProfile && (
        <Box p={2} pb={0} display="flex" justifyContent="space-between" alignItems="center">
          <Typography
            variant="body2"
            sx={{ color: (theme) => theme.veris.colors.neutrals["grey-mid"] }}
          >
            {`${capitalize(
              selectedUser ? selectedUser.profession : userProfile.profession,
            )} ${displayFullName}`}{" "}
            • Calendar
          </Typography>
          <VrsSelect
            value={userProfile.id.toString()}
            maxHeight="450px"
            options={[
              currentUserOption,
              ...medStaff.map((staff) => ({
                label: `${staff.first_name} ${staff.last_name}`,
                value: staff.id.toString(),
              })),
            ]}
            onSelected={onSelect}
          />
        </Box>
      )}
      {status === "loading" && (
        <Box p={2} pb={0} display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      )}
      {status === "failed" && <VrsErrorMsg onClick={onRetry} />}
    </>
  );
};
