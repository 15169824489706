import React from "react";
import { AppointmentStatusEnum } from "@veris-health/med-data-ms/lib/v1";
import { Box, Typography, useTheme } from "@mui/material";
import { capitalize } from "lodash";

interface AppointmentStatusProps {
  status: AppointmentStatusEnum;
}

export const AppointmentStatus = ({ status }: AppointmentStatusProps): JSX.Element => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        backgroundColor: theme.veris.colors.neutrals["soft-white"],
        border: `1px solid ${theme.veris.colors.amethyst.normal}`,
        borderRadius: +theme.shape.borderRadius * 3,
        paddingX: theme.spacing(1),
        minWidth: "fit-content",
        width: "fit-content",
        height: "20px",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Typography variant="description" sx={{ color: theme.veris.colors.amethyst.normal }}>
        {capitalize(status)}
      </Typography>
    </Box>
  );
};
