import React, { useEffect, useState } from "react";
import { CircularProgress, Grid, Typography } from "@mui/material";
import { FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import dayjs from "dayjs";
import { Link, useHistory } from "react-router-dom";
import {
  useCountDown,
  useTargetTimestamp,
  VrsButton,
  VrsConfirmationModals,
  dateFormats,
} from "@veris-health/web-core";
import { Routes } from "../../routes-config";
import { VrsFormInputField } from "../../ui/components/VrsFormInputField";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import {
  clearValidationError,
  loginMedStaffAsync,
  selectValidationError,
  selectIsLoggedIn,
  toggleShowPassword,
  selectIsShowPassword,
  selectAuthStatus,
  selectOtpScreen,
  loginMedStaffOTPAsync,
  selectChangePasswordStatus,
  clearChangePasswordMessages,
  selectlockoutSeconds,
  selectIsAccountBlocked,
  clearLockoutTimer,
  selectIsPasswordExpired,
  clearPasswordExpired,
  selectExpiredPasswordStatus,
  clearExpiredPassword,
} from "../shared/slices/authSlice";
import { OTPLoginContainer } from "./OTPLoginContainer";
import { testAccountEmail, testOTP } from "../../constants";
import { emailValidation } from "../../utils/validations";

const Login = (): JSX.Element => {
  const validationError = useAppSelector(selectValidationError);
  const otpScreen = useAppSelector(selectOtpScreen);
  const isLoggedIn = useAppSelector(selectIsLoggedIn);
  const showPassword = useAppSelector(selectIsShowPassword);
  const authStatus = useAppSelector(selectAuthStatus);
  const changePasswordStatus = useAppSelector(selectChangePasswordStatus);
  const lockoutSeconds = useAppSelector(selectlockoutSeconds);
  const isAccountBlocked = useAppSelector(selectIsAccountBlocked);
  const isPasswordExpired = useAppSelector(selectIsPasswordExpired);
  const { successMessage } = useAppSelector(selectExpiredPasswordStatus);
  const [isAccountBlockedPopupOpen, setAccountBlockedPopup] = useState(isAccountBlocked);

  const targetDate = useTargetTimestamp(lockoutSeconds);
  const remainingTime = useCountDown(targetDate);

  const dispatch = useAppDispatch();
  const history = useHistory();

  useEffect(() => {
    return () => {
      dispatch(clearLockoutTimer());
      dispatch(clearValidationError());
      dispatch(clearPasswordExpired());
      dispatch(clearExpiredPassword());
    };
  }, []);

  useEffect(() => {
    setAccountBlockedPopup(isAccountBlocked);
  }, [isAccountBlocked]);

  useEffect(() => {
    if (isLoggedIn) {
      history.push(Routes.DASHBOARD);
      dispatch(clearChangePasswordMessages());
    }
  }, [isLoggedIn, history]);

  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = yup.object().shape({
    email: emailValidation,
    password: yup
      .string()
      .min(8, "Password is of minimum 8 characters length")
      .max(64, "Password should be of maximum 64 characters length")
      .required("Password is required"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: ({ email, password }) => {
      if (testAccountEmail === email.trim()) {
        dispatch(loginMedStaffOTPAsync({ email: email.trim(), password, otp: testOTP }));
      } else {
        dispatch(loginMedStaffAsync({ email: email.trim(), password }));
      }
    },
  });
  const { errors, handleSubmit, handleChange, handleBlur, touched } = formik;
  const { email, password } = formik.values;

  useEffect(() => {
    if (validationError && (!email || (!password && remainingTime === 0))) {
      dispatch(clearValidationError());
      dispatch(clearLockoutTimer());
    }
  }, [validationError, email, password, dispatch]);

  useEffect(() => {
    if (validationError && remainingTime === 0) {
      dispatch(clearValidationError());
      dispatch(clearLockoutTimer());
    }
  }, [remainingTime]);

  return (
    <>
      {otpScreen && testAccountEmail !== email ? (
        <OTPLoginContainer
          onSubmit={(code) => dispatch(loginMedStaffOTPAsync({ email, password, otp: code }))}
          onResendCode={() => dispatch(loginMedStaffAsync({ email, password }))}
        />
      ) : (
        <FormikProvider value={formik}>
          <form onSubmit={handleSubmit}>
            <Grid item container spacing={4} display="block" xs={10}>
              <Grid item xs={6}>
                <VrsFormInputField
                  name="email"
                  type="text"
                  label="Email"
                  value={email}
                  onFocus={() => {
                    if (!!validationError && remainingTime === 0 && !isAccountBlocked) {
                      dispatch(clearValidationError());
                    }
                  }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={touched.email && errors.email ? errors.email : undefined}
                  error={(touched.email && Boolean(errors.email)) || Boolean(validationError)}
                  isValidField={email.length && !errors.email && !validationError}
                />
              </Grid>
              <Grid item xs={6}>
                <VrsFormInputField
                  name="password"
                  type={showPassword ? "text" : "password"}
                  label="Password"
                  value={password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onFocus={() => {
                    if (!!validationError && remainingTime === 0 && !isAccountBlocked) {
                      dispatch(clearValidationError());
                    }
                  }}
                  helperText={
                    errors.password ||
                    (remainingTime === 0
                      ? validationError
                      : `${validationError} Please try again after ${dayjs
                          .duration(remainingTime)
                          .format(dateFormats["m:ss"])} minutes.`)
                  }
                  error={Boolean(errors.password) || Boolean(validationError)}
                  isValidField={password.length && !errors.password && !validationError}
                  showPassword={showPassword}
                  handleClickShowPassword={() => dispatch(toggleShowPassword())}
                />
              </Grid>
              {!isAccountBlocked && remainingTime === 0 && (
                <Grid item xs={5}>
                  <Link to={Routes.FORGOT_PASSWORD}>
                    <Typography
                      variant="caption"
                      color={(theme) => theme.veris.colors.amethyst.normal}
                    >
                      Forgot password?
                    </Typography>
                  </Link>
                </Grid>
              )}

              <Grid item xs={6}>
                {changePasswordStatus.successMessage && (
                  <Typography
                    color={(theme) => theme.veris.colors.moderate.normal}
                    variant="caption"
                    component="div"
                  >
                    {changePasswordStatus.successMessage}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={6} marginTop={5}>
                <VrsButton
                  buttonType="primary"
                  disabled={
                    authStatus === "loading" ||
                    remainingTime > 0 ||
                    isAccountBlocked ||
                    Boolean(errors.password || errors.email || validationError)
                  }
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Sign in
                  {authStatus === "loading" && (
                    <CircularProgress sx={{ marginLeft: (theme) => theme.spacing(2) }} size={16} />
                  )}
                </VrsButton>
              </Grid>
            </Grid>
          </form>
          <VrsConfirmationModals
            handleClose={() => setAccountBlockedPopup(false)}
            isOpen={isAccountBlockedPopupOpen}
            dialogHeader="Account locked out"
            dialogContent={
              <Typography variant="subtitle2" pt={4} pb={4}>
                Your application access has been blocked due to multiple unsuccessful login
                attempts. Please contact Veris support at{" "}
                <a
                  href={`mailto:${import.meta.env.VITE_SUPPORT_EMAIL}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {import.meta.env.VITE_SUPPORT_EMAIL}
                </a>{" "}
                to resolve the issue.
              </Typography>
            }
            onConfirm={() => setAccountBlockedPopup(false)}
            confirmButtonText="Close"
          />
          <VrsConfirmationModals
            isOpen={isPasswordExpired}
            handleClose={() => dispatch(clearPasswordExpired())}
            dialogHeader="Password Expired"
            dialogContent="Please create a new one."
            dialogContentSpacing={1}
            onCancel={() => dispatch(clearPasswordExpired())}
            onConfirm={() => history.push(Routes.PASSWORD_EXPIRATION)}
            confirmButtonText="Add new password"
            confirmButtonVariant="primary"
            cancelButtonText="Cancel"
          />
          <VrsConfirmationModals
            handleClose={() => dispatch(clearExpiredPassword())}
            isOpen={!!successMessage}
            dialogHeader="Success"
            dialogContent={successMessage}
            onConfirm={() => dispatch(clearExpiredPassword())}
            confirmButtonText="Login"
          />
        </FormikProvider>
      )}
    </>
  );
};

export default Login;
