import { MenuItem, Select, SelectChangeEvent, Typography, selectClasses } from "@mui/material";
import { IconName, VrsIcon } from "@veris-health/web-core";
import React from "react";

interface VrsGraphSelectProps {
  value: string;
  handleChange: (e: SelectChangeEvent<string>) => void;
  options: string[];
  isPreview?: boolean;
}

const VrsGraphSelect = ({ value, handleChange, options, isPreview }: VrsGraphSelectProps) => {
  return (
    <Select
      onChange={handleChange}
      value={value}
      defaultValue="Implant"
      variant="standard"
      disableUnderline
      IconComponent={(props) => <VrsIcon {...props} name={IconName.ArrowDown} size={14} />}
      sx={{
        position: "absolute",
        right: 0,
        top: isPreview ? "12px" : "unset",
        cursor: "pointer",
        height: isPreview ? "20px" : "25px",
        border: "none",
        [`.${selectClasses.select}`]: {
          padding: (theme) => theme.spacing(0.25, 1),
        },
      }}
    >
      {options.map((option) => (
        <MenuItem value={option} key={option}>
          <Typography variant="caption" color={(theme) => theme.veris.colors.neutrals["grey-4"]}>
            {option}{" "}
          </Typography>
        </MenuItem>
      ))}
    </Select>
  );
};

export default VrsGraphSelect;
