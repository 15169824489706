import { Box, CircularProgress, Typography, Stack, styled, Button } from "@mui/material";
import {
  QOLQuestionnaireResponse,
  QOLReportStatusEnum,
  QuestionType,
  QuestionViewType,
} from "@veris-health/virtual-doc-ms/lib/v1";
import dayjs from "dayjs";
import React, { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { round } from "lodash";
import {
  VrsIconButton,
  IconName,
  VrsIcon,
  VrsButton,
  VrsTooltip,
  verisColors,
  VrsErrorMsg,
  dateFormats,
} from "@veris-health/web-core";
import { QolAnswerColor } from "../../../../constants";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { VrsDrawer } from "../../../../ui/components/VrsDrawer";
import { ReportStatusField, VrsQOLReportCard } from "../../../../ui/components/VrsQOLReportCard";
import { VrsReportQADetails } from "../../../Notifications/components/VrsReportQADetails";
import { selectQOLReports, selectQOLReportsStatus } from "../../patientDetailsSidebarSlice";
import { QofLScoreExplanation } from "../../../../ui/components/QOLScoreExplanation/QofLScoreExplanation";

export interface QOfLSidebarPreviewProps {
  onClose: () => void;
  isOpen: boolean;
  fetchQOLReports: () => void;
  onScheduleAppointmentClick: () => void;
  disableScheduleAppointment: boolean;
  patientId: number;
}

const ReportIndexButton = styled(Button)(() => ({
  backgroundColor: verisColors.neutrals.white,
  border: `1px solid ${verisColors.neutrals["grey-2"]}`,
  minWidth: "fit-content",
}));

export function ReportIndex({ index }: { index: number }): JSX.Element {
  return (
    <VrsTooltip
      bcgcolor={verisColors.neutrals.black}
      title={<QofLScoreExplanation />}
      placement="bottom"
    >
      <ReportIndexButton
        sx={{
          borderRadius: (theme) => theme.shape.borderRadius,
          padding: (theme) => theme.spacing(0.5, 0.75, 0, 0.75),
        }}
      >
        <Typography color={(theme) => theme.veris.colors.neutrals["grey-4"]} variant="h6Semibold">
          {round(index * 100, 1)}
        </Typography>
      </ReportIndexButton>
    </VrsTooltip>
  );
}

export function QOfLSidebarPreview({
  onClose,
  isOpen,
  fetchQOLReports,
  onScheduleAppointmentClick,
  disableScheduleAppointment,
  patientId,
}: QOfLSidebarPreviewProps): JSX.Element {
  const reports = useAppSelector((state) => selectQOLReports(state, patientId));
  const reportsStatus = useAppSelector(selectQOLReportsStatus);
  const [selectedReport, setSelectedReport] = useState<QOLQuestionnaireResponse>();

  return (
    <VrsDrawer onClose={onClose} open={isOpen}>
      <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
        <Stack gap={3}>
          <Box
            display="flex"
            alignItems="center"
            sx={{ left: (theme) => (selectedReport ? theme.spacing(-1) : 0) }}
            position="relative"
          >
            {selectedReport && (
              <VrsIconButton
                iconProps={{ name: IconName.ArrowLeft, stroke: "grey" }}
                onClick={() => setSelectedReport(undefined)}
              />
            )}
            <Typography
              variant="h6Semibold"
              pr={1}
              color={(theme) => theme.veris.colors.neutrals["grey-4"]}
            >
              Quality of Life {selectedReport ? "Report" : "Reports"}
            </Typography>
          </Box>
          {selectedReport && (
            <Box display="flex" gap={2} alignItems="center">
              <Typography
                variant="h6Semibold"
                color={(theme) => theme.veris.colors.amethyst.normal}
              >
                {dayjs(selectedReport.date_updated).format(dateFormats["ddd, MMM DD"])}
              </Typography>
              <ReportStatusField status={selectedReport.status ?? QOLReportStatusEnum.Complete} />
              {selectedReport.score?.eq5d_index && (
                <ReportIndex index={selectedReport.score?.eq5d_index} />
              )}
            </Box>
          )}
        </Stack>
        <Box sx={{ alignSelf: "baseline" }}>
          <VrsIconButton iconProps={{ name: IconName.CloseIcon }} onClick={onClose} />
        </Box>
      </Box>

      {reports.items?.length > 0 && (
        <Box height="100%" display="flex" flexDirection="column" mt={3}>
          <Box height="80vh" overflow="scroll" id="qofl-scroll-container">
            {selectedReport ? (
              <>
                {selectedReport.answers.map((answer) => (
                  <Box
                    p={2.28}
                    mb={1.14}
                    key={answer.question_id}
                    sx={{
                      backgroundColor: (theme) => theme.veris.colors.neutrals["soft-white"],
                      borderRadius: (theme) => +theme.shape.borderRadius * 0.375,
                      border: answer.answer_value
                        ? `1px solid ${QolAnswerColor[answer.answer_value]}`
                        : undefined,
                    }}
                  >
                    <VrsReportQADetails
                      reportAnswer={{
                        questionId: answer.question_id,
                        questionType: QuestionType.FreeTextField,
                        view: QuestionViewType.Button,
                        question: answer.question ?? "",
                        answer: answer.answer,
                      }}
                      answerValue={answer.answer_value}
                      qoLView
                    />
                  </Box>
                ))}
              </>
            ) : (
              <InfiniteScroll
                scrollableTarget="qofl-scroll-container"
                hasMore={reports.total - reports.offset > 0}
                dataLength={reports.items.length}
                style={{ overflow: "hidden" }}
                next={fetchQOLReports}
                loader={
                  <Box display="flex" justifyContent="center" alignItems="center" p={1}>
                    <CircularProgress />
                  </Box>
                }
              >
                {reports.items.map((report) => (
                  <VrsQOLReportCard
                    reportItem={report}
                    key={report.report_id}
                    onClick={() => {
                      setSelectedReport(report);
                    }}
                  />
                ))}
              </InfiniteScroll>
            )}
          </Box>
          <Box mt="auto">
            <VrsButton
              buttonType="secondary"
              onClick={onScheduleAppointmentClick}
              sx={{ float: "right" }}
              disabled={disableScheduleAppointment}
            >
              Schedule Appointment
              <VrsIcon
                name={IconName.ArrowRight}
                stroke={
                  disableScheduleAppointment
                    ? verisColors.neutrals["grey-2"]
                    : verisColors.amethyst.normal
                }
              />
            </VrsButton>
          </Box>
        </Box>
      )}
      {reportsStatus === "failed" && <VrsErrorMsg size="small" onClick={fetchQOLReports} />}
      {reportsStatus === "idle" && reports.items?.length === 0 && (
        <Typography
          color={(theme) => theme.veris.colors.neutrals["grey-3"]}
          textAlign="center"
          variant="body"
        >
          There are no quality of life reports.
        </Typography>
      )}
    </VrsDrawer>
  );
}
