import { Box } from "@mui/material";
import { NotificationType } from "@veris-health/communication-ms/lib/v1";
import React from "react";
import { VrsIcon, IconName, VrsButton, verisColors } from "@veris-health/web-core";

interface EventFooterProps {
  notificationType: NotificationType;
  onSeePatientProfileClick: () => void;
  onScheduleAppointmentClick: () => void;
}

export const NotificationFooter = ({
  notificationType,
  onSeePatientProfileClick,
  onScheduleAppointmentClick,
}: EventFooterProps): JSX.Element => {
  return (
    <Box paddingX={1.7} display="flex" flexDirection="row" justifyContent="space-between">
      <VrsButton
        buttonType="secondary"
        endIcon={
          <VrsIcon name={IconName.ArrowRight} stroke={verisColors.amethyst.normal} size={16} />
        }
        onClick={onSeePatientProfileClick}
      >
        See Patient profile
      </VrsButton>
      {notificationType === ("symptom-added" || "symptom-reported") && (
        <VrsButton
          buttonType="secondary"
          endIcon={
            <VrsIcon name={IconName.ArrowRight} stroke={verisColors.amethyst.normal} size={16} />
          }
          onClick={onScheduleAppointmentClick}
        >
          Schedule Appointment
        </VrsButton>
      )}
    </Box>
  );
};
