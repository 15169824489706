import {
  GoalsApi as GoalsApiV1,
  QualityOfLifeApi as QualityOfLifeApiV1,
  SymptomsApi as SymptomsApiV1,
} from "@veris-health/virtual-doc-ms/lib/v1";
import config from "../axios-config";
import { API_ROUTES, getVersion, VERSIONS } from "../helpers";

export const symptomsApiV1 = new SymptomsApiV1(
  undefined,
  getVersion(API_ROUTES.VIRTUAL_DOC, VERSIONS.v1),
  config,
);
export const qualityOfLifeApiV1 = new QualityOfLifeApiV1(
  undefined,
  getVersion(API_ROUTES.VIRTUAL_DOC, VERSIONS.v1),
  config,
);
export const goalsApiV1 = new GoalsApiV1(
  undefined,
  getVersion(API_ROUTES.VIRTUAL_DOC, VERSIONS.v1),
  config,
);
