import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Grid, Typography, useMediaQuery } from "@mui/material";
import updateLocale from "dayjs/plugin/updateLocale";
import dayjs from "dayjs";
import { ThreadParticipantInfo } from "@veris-health/communication-ms/lib/v1";
import useTheme from "@mui/material/styles/useTheme";
import { PatientStatusEnumAPI } from "@veris-health/user-ms/lib/v2";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../hooks/useAppSelector";
import {
  fetchChatThreadsCombinedAsync,
  selectCurrentThread,
  createChatThreadAsync,
  selectCurrentChatThreadStatus,
  fetchChatConfiguration,
  setChatHistoryPatientId,
  selectChatHistoryPatientId,
  selectChatThreadsStatus,
} from "../store";
import { ThreadContainer } from "./ThreadContainer";
import {
  getPatientCareAndSupportTeamsAsync,
  selectCareTeams,
} from "../../shared/slices/careTeamSlice";
import { ChatSidebarContainer } from "./ChatSidebarContainer";
import { ChatHeaderContainer } from "./ChatHeaderContainer";
import { checkIfTokenIsExpired } from "../../../api/utils/jwt-extras";
import {
  VrsDeceasedPatientAlert,
  VrsInactivePatientAlert,
} from "../../../ui/components/VrsInactivePatientAlert/VrsInactivePatientAlert";
import { VrsPatientInfo } from "../../shared/interfaces";

dayjs.extend(updateLocale);

dayjs.updateLocale("en", {
  relativeTime: {
    future: "now",
    past: "%s ago",
    s: "1s",
    m: "1m",
    mm: "%dm",
    h: "1h",
    hh: "%dh",
    d: "1d",
    dd: "%dd",
    w: "%d w",
    ww: "%d w",
    M: "a month",
    MM: "%d months",
    y: "a y",
    yy: "%d yrs",
  },
});

export interface ChatContainerProps {
  endpoint: string;
  token: string;
  userId: string;
  myAzureChatUserId: string;
  myChatDisplayName: string;
  currentPatient?: VrsPatientInfo;
}

const ChatContainer = ({
  endpoint,
  token,
  userId,
  myAzureChatUserId,
  myChatDisplayName,
  currentPatient,
}: ChatContainerProps): JSX.Element => {
  const currentThread = useAppSelector(selectCurrentThread);
  const currentChatThreadStatus = useAppSelector(selectCurrentChatThreadStatus);
  const [patientInGroupThread, setPatientInGroupThread] = useState<ThreadParticipantInfo>();
  const careTeams = useAppSelector((state) =>
    selectCareTeams(
      state,
      patientInGroupThread ? patientInGroupThread.user_id : currentPatient?.id,
    ),
  );
  const threadsStatus = useAppSelector(selectChatThreadsStatus);
  const chatHistoryPatientId = useAppSelector(selectChatHistoryPatientId);
  const theme = useTheme();

  const dispatch = useAppDispatch();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xl"));

  const handleCreate = (patientId: number, activeUserId: number): void => {
    dispatch(
      createChatThreadAsync({
        id: activeUserId,
        participants: [
          {
            user_id: patientId,
          },
        ],
      }),
    );
  };

  const isChatHistoryActive = () => {
    const patientId =
      currentThread?.participants.find((participant) => participant.user_type === "patient")
        ?.user_id || currentPatient?.id;
    return patientId ? chatHistoryPatientId === patientId : false;
  };

  const onChatHistoryToggle = () => {
    if (currentThread?.private && currentPatient) {
      handleCreate(+currentPatient?.id, +userId);
    }
    const patient = currentThread?.participants.find(
      (participant) => participant.user_type === "patient",
    );

    dispatch(
      setChatHistoryPatientId({
        patientId: currentPatient?.id || patient?.user_id,
      }),
    );
  };
  useEffect(() => {
    if (currentPatient && !currentThread?.id && threadsStatus !== "loading") {
      handleCreate(currentPatient.id, +userId);
    }
  }, [dispatch, userId, currentPatient, threadsStatus]);

  useEffect(() => {
    const tokenIsExpired = checkIfTokenIsExpired(token);
    if (token) {
      if (tokenIsExpired) {
        dispatch(fetchChatConfiguration({ id: Number(userId) }));
      } else {
        dispatch(fetchChatThreadsCombinedAsync({ userId }));
      }
    }
  }, [userId, endpoint, token]);

  useEffect(() => {
    if (currentPatient && !careTeams) {
      dispatch(getPatientCareAndSupportTeamsAsync(currentPatient.id));
    }
  }, [dispatch, currentPatient]);

  return (
    <>
      <Grid container sx={{ backgroundColor: theme.veris.colors.neutrals.white }}>
        <Grid
          sx={{
            position: "relative",
            height: isSmallScreen ? "88vh" : "90vh",
            backgroundColor:
              currentThread && currentThread?.private
                ? theme.veris.colors.pink.light
                : theme.veris.colors.neutrals.white,
          }}
          display="flex"
          flexDirection="column"
          item
          lg={8}
          xl={9}
          xs={8}
          marginBottom={0}
        >
          {currentChatThreadStatus === "loading" && (
            <Box display="flex" justifyContent="center" alignItems="center" p={1}>
              <CircularProgress />
            </Box>
          )}
          {currentChatThreadStatus === "idle" && (
            <>
              {currentPatient?.isInactiveOrDeceased && (
                <>
                  {currentPatient?.patientStatus === PatientStatusEnumAPI.Inactive ? (
                    <VrsInactivePatientAlert />
                  ) : (
                    <VrsDeceasedPatientAlert />
                  )}
                </>
              )}
              <ChatHeaderContainer
                setPatientInGroupThread={(patient) => {
                  setPatientInGroupThread(patient);
                }}
                restorePublicThread={(patientId) => handleCreate(patientId, +userId)}
                currentThread={currentThread}
                currentPatient={currentPatient}
                userId={userId}
                careTeams={careTeams}
                showInteractions
              />
              {currentThread && currentThread.id ? (
                <ThreadContainer
                  currentThreadId={currentThread.id}
                  myAzureChatUserId={myAzureChatUserId}
                  myChatDisplayName={myChatDisplayName}
                  endpoint={endpoint}
                  token={token}
                  participants={currentThread.participants}
                  isPrivateThread={currentThread.private}
                  showInteractions
                  onChatHistoryToggle={onChatHistoryToggle}
                  activeChatHistory={isChatHistoryActive()}
                />
              ) : (
                <Typography
                  color={theme.veris.colors.neutrals["grey-3"]}
                  display="flex"
                  textAlign="center"
                  alignItems="center"
                  justifyContent="center"
                  component="div"
                  variant="h6"
                  height="90%"
                >
                  Please select/create a thread to start a conversation.
                </Typography>
              )}
            </>
          )}
        </Grid>
        <ChatSidebarContainer />
      </Grid>
    </>
  );
};

export default ChatContainer;
