import React from "react";
import { Typography, Box, Grid } from "@mui/material";
import { VrsAvatar, verisColors } from "@veris-health/web-core";
import { ThreadParticipantInfo } from "@veris-health/communication-ms/lib/v1";

interface DoctorTreadCardProp {
  doctor?: ThreadParticipantInfo;
}

export const DoctorTreadCard = ({ doctor }: DoctorTreadCardProp) => {
  return (
    <Box
      data-test-hook="patientInfoCard-element"
      display="flex"
      alignItems="center"
      paddingY={(theme) => theme.spacing(0.375)}
      paddingX={(theme) => theme.spacing(0.625)}
      sx={{
        borderRadius: 1.5,
        flexGrow: 1,
      }}
    >
      <Box data-test-hook="avatarWrapper-element" mr={1.5}>
        <VrsAvatar name={doctor?.display_name} opacity="100%" />
      </Box>

      <Grid marginX={(theme) => theme.spacing(0, 1.5)} marginTop={(theme) => theme.spacing(0.5)}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <Box marginRight={(theme) => theme.spacing(1)} display="inline-block">
            <Typography
              variant="subtitle1"
              color={verisColors.neutrals.black}
              sx={{ wordBreak: "break-word" }}
            >
              {doctor?.display_name}
            </Typography>
          </Box>
        </Box>
        <Box
          display="flex"
          whiteSpace="nowrap"
          justifyContent="flex-start"
          alignItems="baseline"
          mt={0}
        >
          <Box
            data-test-hook="diagnosis-tag"
            sx={{
              display: "flex",
              alignItems: "center",
              flexFlow: "row wrap",
              wordBreak: "break-word",
              whiteSpace: "normal",
            }}
          >
            <Box marginRight={(theme) => theme.spacing(0.25)} mt={0.8}>
              <Typography
                variant="subtitle2"
                color={verisColors.neutrals["grey-dark"]}
                sx={{ textTransform: "capitalize" }}
              >
                {doctor?.user_type}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Box>
  );
};
