import {
  Dialog,
  Box,
  Typography,
  InputAdornment,
  inputBaseClasses,
  styled,
  TextField,
  TextFieldProps,
} from "@mui/material";
import React, { ReactNode } from "react";
import { VrsIcon, IconName, VrsIconButton, VrsButton } from "@veris-health/web-core";

export const StyledTextField = styled(TextField)<TextFieldProps>(({ theme }) => ({
  [`& .${inputBaseClasses.input}`]: {
    ...theme.typography.body,
    padding: theme.spacing(1, 0),
  },
}));

export interface VrsPatientDetailsDialogProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  patientName: string;
  searchPhase: string;
  setSearchPhase: (value: string) => void;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  children?: ReactNode;
}

export function VrsPatientDetailsDialog({
  isOpen,
  onClose,
  title,
  patientName,
  searchPhase,
  setSearchPhase,
  handleInputChange,
  children,
}: VrsPatientDetailsDialogProps): JSX.Element {
  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="lg">
      <Box p={4} display="flex" flexDirection="column" gap={1.5}>
        <Typography
          variant="h3"
          pb={1.5}
          sx={{
            "::first-letter": {
              textTransform: "capitalize",
            },
          }}
        >
          {title}
        </Typography>
        <Typography variant="subtitle2">
          Below you can find the {title} of the patient {patientName}.
        </Typography>
        <Box width={300}>
          <StyledTextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <VrsIcon name={IconName.Search} />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {searchPhase.length > 0 && (
                    <VrsIconButton
                      iconProps={{ name: IconName.CloseIcon }}
                      onClick={() => setSearchPhase("")}
                      data-test-hook="vrsPatientDetailsDialog-input-close"
                    />
                  )}
                </InputAdornment>
              ),
            }}
            type="text"
            fullWidth
            placeholder="Search by name"
            onChange={handleInputChange}
            value={searchPhase}
            autoFocus
          />
        </Box>
        {children}
        <Box display="flex" justifyContent="space-around">
          <VrsButton
            buttonType="primary"
            onClick={onClose}
            data-test-hook="vrsPatientDetailsDialog-close"
          >
            Close
          </VrsButton>
        </Box>
      </Box>
    </Dialog>
  );
}
