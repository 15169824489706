import { AxisDomain } from "recharts/types/util/types";
import { Measurement, SensorEnum } from "@veris-health/med-data-ms/lib/v1";
import { verisColors } from "@veris-health/web-core";
import { get } from "lodash";
import dayjs from "dayjs";
import { SensorValueYAxisRange } from "./constants";
import { MioSensorMeasurement, ReferenceLine, SensorsGraphView } from "../../measurementSlice";
import { trendingChartColors } from "../../../../constants";

export const calculateDomain = (
  sensorName: SensorEnum,
  calculatedMax: number,
  sensorBaseLineMax: number,
  sensorBaseLineMin: number,
): AxisDomain => {
  if (sensorName === "motion") {
    const getMotionMax = () => {
      if (Number(calculatedMax) || Number(sensorBaseLineMax)) {
        return Math.max(calculatedMax, sensorBaseLineMax);
      }
      return sensorBaseLineMin;
    };
    return [0, getMotionMax()];
  }
  if (sensorName === "ecg") return ["auto", "auto"];

  const domain = { min: 0, max: 0 };
  const yRange = SensorValueYAxisRange[sensorName];
  if (sensorName === "weight") return [yRange.min, yRange.max];
  if (typeof yRange.max === "number") {
    domain.min =
      sensorBaseLineMin < yRange.min && yRange.offset
        ? sensorBaseLineMin - yRange.offset
        : yRange.min;
    domain.max =
      sensorBaseLineMax > yRange.max && yRange.offset
        ? sensorBaseLineMax + yRange.offset
        : yRange.max;
  }
  return [domain.min, domain.max];
};

export function getColorForDot(
  entry: MioSensorMeasurement | Measurement,
  dataKey: string,
  sensorMin: number,
  sensorMax: number,
  sensorName: string,
  combinedChart?: boolean,
): string {
  const value = get(entry, dataKey);

  const normalValueColor = combinedChart ? verisColors.moderate.dark : verisColors.moderate.normal;
  const outOfRangeValueColor = combinedChart ? verisColors.mango.dark : verisColors.mango.normal;

  if (["weight", "ecg"].includes(sensorName) || (value >= sensorMin && value <= sensorMax)) {
    return normalValueColor;
  }

  return outOfRangeValueColor;
}

export const getColorForStroke = (sensMin: number, sensMax: number, sensValue: number | null) => {
  if (sensValue && (sensValue < sensMin || sensValue > sensMax))
    return trendingChartColors.Critical.start;
  return trendingChartColors.Good.start;
};

export const getTooltipDirection = (
  startDate: string,
  endDate: string,
  view: SensorsGraphView,
  referenceLine: ReferenceLine,
) => {
  const daysDifference = dayjs(endDate).diff(startDate, view === "daily" ? "hours" : "days");
  // finding the middle date of the chart
  const middleDay = dayjs(startDate).add(daysDifference / 2, view === "daily" ? "hours" : "days");
  // determining direction based whether the dot is in the first half of the chart or the second
  return dayjs.unix(referenceLine.date as number).isBefore(middleDay);
};
