import React from "react";
import { Box, Link, styled } from "@mui/material";

type MobileOS = "Android" | "iOS" | "unknown";

const StyledLink = styled(Link)(() => ({
  cursor: "pointer",
  userSelect: "none",
}));

const PlayStoreLink = () => (
  <StyledLink href={import.meta.env.VITE_PLAY_STORE_LINK} variant="body" target="_blank">
    Download the Android app
  </StyledLink>
);

const AppStoreLink = () => (
  <StyledLink
    href={import.meta.env.VITE_APP_STORE_LINK}
    variant="body"
    target="_blank"
    style={{ cursor: "pointer" }}
  >
    Download the iOS app
  </StyledLink>
);

export function MobileLandingPage(): JSX.Element {
  const os = getMobileOperatingSystem();

  const getAppLink = () => {
    switch (os) {
      case "iOS":
        return <AppStoreLink />;
      case "Android":
        return <PlayStoreLink />;
      default:
        return (
          <>
            <PlayStoreLink />
            <AppStoreLink />
          </>
        );
    }
  };

  return (
    <Box
      sx={{
        margin: " 1rem",
        display: " flex",
        flexDirection: "column",
      }}
    >
      {getAppLink()}
    </Box>
  );
}

function getMobileOperatingSystem(): MobileOS {
  const userAgent = navigator.userAgent || navigator.vendor;

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !("MSStream" in window)) {
    return "iOS";
  }

  return "unknown";
}
