import React, { useEffect, useMemo, useState } from "react";
import { differenceBy, isEqual, orderBy, uniqBy } from "lodash";
import { Box, Checkbox, CircularProgress, Dialog, Typography } from "@mui/material";
import { TagItem } from "@veris-health/user-ms/lib/v1";
import { FieldArray, Form, Formik } from "formik";
import { IconName, VrsIcon, VrsIconButton, VrsButton, verisColors } from "@veris-health/web-core";
import { useAppSelector } from "../../hooks/useAppSelector";
import { getHospitalTagsAsync, selectHospitalTags } from "../Hospitals/hospitalSlice";
import { StyledDialogContents, StyledCheckboxContainer } from "../shared/styledComponents";
import { deletePatientTag, setPatientTag } from "./api/patientDetailsApi";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { Status } from "../shared/interfaces";
import { getPatientByIdAsync } from "../shared/slices/asyncThunks";
import SnackbarUtils from "../../utils/SnackbarUtils";

export interface VrsPatientTagsDialogProps {
  patientTags: TagItem[];
  open: boolean;
  onClose: () => void;
  patientId: number;
  hospitalId?: number;
  patientName?: string;
}

export function PatientTagsDialog({
  patientTags,
  open,
  onClose,
  patientId,
  hospitalId,
  patientName,
}: VrsPatientTagsDialogProps): JSX.Element {
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState<Status>("idle");
  const dispatch = useAppDispatch();
  const hospitalTags = useAppSelector(selectHospitalTags);
  const allTags = useMemo(() => {
    const tags = uniqBy([...patientTags, ...hospitalTags], (tag) => tag.id);
    return orderBy(tags, ["type"], ["asc"]);
  }, [patientTags, hospitalTags]);

  async function onEdit(newTags: number[], tagsToDelete: number[]) {
    try {
      const addTags = newTags.map((item) => setPatientTag(patientId, { tag_id: item }));
      const removeTags = tagsToDelete.map((item) => deletePatientTag(patientId, { tag_id: item }));
      await Promise.all([...addTags, ...removeTags]);
      await dispatch(getPatientByIdAsync({ userId: +patientId, ignoreLocalState: true }));
      SnackbarUtils.success("Tags successfully updated.");
    } catch (err) {
      SnackbarUtils.error("Something went wrong, please try again");
    } finally {
      setIsLoading(false);
      onClose();
    }
  }

  const onSubmit = (tags: TagItem[]) => {
    setIsLoading(true);
    const newTags = differenceBy(tags, patientTags, "id").map(({ id }) => id);
    const tagsToDelete = differenceBy(patientTags, tags, "id").map(({ id }) => id);
    if (newTags.length > 0 || tagsToDelete.length > 0) onEdit(newTags, tagsToDelete);
  };

  useEffect(() => {
    if (hospitalId) {
      setStatus("loading");
      dispatch(getHospitalTagsAsync(Number(hospitalId))).then(() => setStatus("idle"));
    }
  }, [patientId, hospitalId, dispatch]);

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <StyledDialogContents>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h3" sx={{ marginBottom: (theme) => theme.spacing(2) }}>
              Edit tags
            </Typography>
            <VrsIconButton iconProps={{ name: IconName.CloseIcon }} onClick={onClose} />
          </Box>
          <Typography variant="subtitle2" sx={{ marginBottom: (theme) => theme.spacing(2) }}>
            Select or Unselect tags
          </Typography>
          {patientName && (
            <Box display="flex" alignItems="end">
              <VrsIcon name={IconName.AllPatients} color={verisColors.neutrals["grey-3"]} />
              <Typography pl={2} variant="bodyMedium">
                {patientName}
              </Typography>
            </Box>
          )}
          {status === "loading" && (
            <Box sx={{ paddingTop: (theme) => theme.spacing(1) }}>
              <CircularProgress sx={{ marginX: (theme) => theme.spacing(2) }} size={16} />
            </Box>
          )}
          {status === "idle" && allTags.length && (
            <Box>
              <Formik
                initialValues={{ selectedTags: [...patientTags] }}
                onSubmit={(values: { selectedTags: TagItem[] }) => {
                  onSubmit(values.selectedTags);
                }}
              >
                {({ values }) => (
                  <Form>
                    <FieldArray name="selectedTags">
                      {(arrayHelpers) => (
                        <>
                          <Box
                            sx={{
                              margin: (theme) => `${theme.spacing(4)} 0`,
                              overflowY: "scroll",
                              maxHeight: "240px",
                            }}
                          >
                            {allTags.length > 0 &&
                              allTags.map((tag) => {
                                const selectedTag = values.selectedTags.find(
                                  ({ id }: TagItem) => id === tag.id,
                                );
                                return (
                                  <StyledCheckboxContainer
                                    key={tag.id}
                                    py={1}
                                    onClick={() => {
                                      if (tag.type !== "system") {
                                        const isSelected = !selectedTag;
                                        if (isSelected) {
                                          arrayHelpers.push(tag);
                                        } else {
                                          const indexToRemove = values.selectedTags.findIndex(
                                            ({ id }) => id === tag.id,
                                          );
                                          arrayHelpers.remove(indexToRemove);
                                        }
                                      }
                                    }}
                                  >
                                    <Checkbox
                                      name={`selectedMembers.values[${tag.id}].label`}
                                      checked={!!selectedTag}
                                      sx={{ color: (theme) => theme.veris.colors.amethyst.normal }}
                                      disabled={tag.type === "system"}
                                    />
                                    <Box display="flex" alignItems="center">
                                      <Typography
                                        variant="body"
                                        mx={1}
                                        style={{ textTransform: "capitalize" }}
                                      >
                                        {tag.label}
                                      </Typography>
                                    </Box>
                                  </StyledCheckboxContainer>
                                );
                              })}
                          </Box>
                          <Box display="flex" gap={1} justifyContent="flex-end">
                            <VrsButton buttonType="secondary" onClick={onClose}>
                              Cancel
                            </VrsButton>
                            <VrsButton
                              buttonType="primary"
                              type="submit"
                              disabled={isLoading || isEqual(values.selectedTags, patientTags)}
                            >
                              Save changes
                            </VrsButton>
                          </Box>
                        </>
                      )}
                    </FieldArray>
                  </Form>
                )}
              </Formik>
            </Box>
          )}
        </StyledDialogContents>
      </Dialog>
    </>
  );
}
