import {
  CareTeamNote,
  SetCareTeamNote,
  GetClinicalNotesResponse,
  ClinicalNote,
  NewClinicalNote,
  GeneratedClinicalNoteResponse,
  WeightTrendLookbackPeriod,
  WeightTrendAggregationWindowSize,
  WeightTrendView,
  MioWeightMeasurement,
} from "@veris-health/med-data-ms/lib/v1";
import {
  Demographics,
  StatusResponse,
  TagRequest,
  UserInfoBaseResponse,
} from "@veris-health/user-ms/lib/v1";
import { GoalFiniteResponse, GoalRecurringResponse } from "@veris-health/virtual-doc-ms/lib/v1";
import {
  RPMReport,
  SubmitManualRPM,
  SubmitManualRPMResponse,
  SubmitManualTCM,
  SubmitManualTCMResponse,
  TCMCodesResponse,
  TCMReport,
} from "@veris-health/communication-ms/lib/v1";
import dayjs from "dayjs";
import { PatientStatusEnumAPI } from "@veris-health/user-ms/lib/v2";
import { dateFormats } from "@veris-health/web-core";
import {
  clinicalNotesApiV1,
  sensorDataApiV1,
  demographicsApiV1,
  careTeamNotesApiV1,
  goalsApiV1,
  tagsApiV1,
  teamsApiV1,
  activityTrackingApiV1,
  patientsApiV1,
} from "../../../api";
import { stripHtml } from "../../../api/stripHtml";

export const fetchPatientDetails = async (id: number): Promise<Demographics> => {
  const response = await demographicsApiV1.getUserDemographics(id);
  return response.data;
};
const careTeamNotesCache: Map<number, CareTeamNote[]> = new Map();
export const fetchCareTeamNotes = async (id: number): Promise<CareTeamNote[]> => {
  const response = await careTeamNotesApiV1.getPatientCareTeamNotes(id);
  const notes = stripHtml(response.data) as CareTeamNote[];
  careTeamNotesCache.set(id, notes);
  return response.data;
};

export const generateClinicalNote = async (id: number): Promise<GeneratedClinicalNoteResponse> => {
  const response = await clinicalNotesApiV1.generateClinicalNoteContent(id);
  return response.data;
};

const clinicalNotesCache: Map<number, GetClinicalNotesResponse> = new Map();
export const fetchClinicalNotes = async (id: number): Promise<GetClinicalNotesResponse> => {
  const response = await clinicalNotesApiV1.getClinicalNotes(id);
  const data = stripHtml(response.data) as GetClinicalNotesResponse;

  clinicalNotesCache.set(id, data);
  return data;
};

export const addCareTeamNote = async (id: number, note: SetCareTeamNote): Promise<CareTeamNote> => {
  const response = await careTeamNotesApiV1.setCareTeamNote(id, note);
  return response.data;
};

export const addClinicalNote = async (id: number, note: NewClinicalNote): Promise<ClinicalNote> => {
  const response = await clinicalNotesApiV1.createClinicalNote(id, note);
  return response.data;
};

export const updateCareTeamNote = async (
  id: number,
  noteId: number,
  note: SetCareTeamNote,
): Promise<CareTeamNote> => {
  const response = await careTeamNotesApiV1.updateCareTeamNote(id, noteId, note);
  return response.data;
};

const patientGoalsMap: Map<number, (GoalFiniteResponse | GoalRecurringResponse)[]> = new Map();
export const fetchPatientGoals = async (
  id: number,
): Promise<(GoalFiniteResponse | GoalRecurringResponse)[]> => {
  const data = patientGoalsMap.get(id);
  if (data) {
    Promise.resolve(data);
  }
  const response = await goalsApiV1.getIntentions(id);
  patientGoalsMap.set(id, response.data);
  return response.data;
};

export const getWeightTrend = async (
  id: number,
  period: WeightTrendLookbackPeriod,
  size: WeightTrendAggregationWindowSize,
): Promise<WeightTrendView> => {
  const response = await sensorDataApiV1.getWeightTrendAggregations(id, period, size);
  return response.data;
};

export const getLatestWeight = async (id: number): Promise<MioWeightMeasurement> => {
  const response = await sensorDataApiV1.getLatestWeight(id);
  return response.data;
};

export const setPatientTag = async (id: number, tag: TagRequest): Promise<StatusResponse> => {
  const response = await tagsApiV1.setPatientTag(id, tag);
  return response.data;
};

export const deletePatientTag = async (id: number, tag: TagRequest): Promise<StatusResponse> => {
  const response = await tagsApiV1.deletePatientTag(id, tag);
  return response.data;
};

export const getPatientsMainOncologist = async (id: number): Promise<UserInfoBaseResponse> => {
  const response = await teamsApiV1.getMainOncologist(id);
  return response.data;
};

export const addRPMEntry = async (
  patientId: number,
  manualRPM: SubmitManualRPM,
): Promise<SubmitManualRPMResponse> => {
  const response = await activityTrackingApiV1.submitRpmTime(patientId, manualRPM);
  return response.data;
};

export const deleteRPMEntry = async (patientId: number, entryId: number): Promise<unknown> => {
  const response = await activityTrackingApiV1.deleteRpmTime(patientId, entryId);
  return response.data;
};

export const getRPMEntries = async (
  patientId: number,
  time: string,
  includeEntries: boolean,
): Promise<RPMReport> => {
  const formattedTime = dayjs(time).format(dateFormats["YYYY-MM-DD"]);
  const response = await activityTrackingApiV1.listRpmItems(
    patientId,
    formattedTime,
    includeEntries,
  );
  return response.data;
};

export const updatePatientStatus = async (
  patientId: number,
  status: PatientStatusEnumAPI,
): Promise<void> => {
  await patientsApiV1.updatePatientStatus(patientId, { status });
};

export const listTCMBillings = async (patientId: number): Promise<TCMReport> => {
  const response = await activityTrackingApiV1.listTcmItems(patientId);
  return response.data;
};

export const listTCMCodes = async (): Promise<TCMCodesResponse> => {
  const response = await activityTrackingApiV1.listTcmCodes();
  return response.data;
};

export const addTCMBilling = async (
  patientId: number,
  payload: SubmitManualTCM,
): Promise<SubmitManualTCMResponse> => {
  const response = await activityTrackingApiV1.submitTcmTime(patientId, payload);
  return response.data;
};
