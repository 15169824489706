import React, { useMemo, useState } from "react";
import { VrsIconButton, IconName, verisColors } from "@veris-health/web-core";
import { RPMManualEntry, RPMReport } from "@veris-health/communication-ms/lib/v1";
import { MedStaffProfileItem } from "@veris-health/user-ms/lib/v1";
import { Dialog, Box, Typography, useTheme } from "@mui/material";
import dayjs from "dayjs";
import { ReviewRpmTable, constructDisplayName } from "./ReviewRpmTable";
import { ReviewRpmHeader } from "./ReviewRpmHeader";
import { VrsTabsContainer } from "../../../../../ui/components/VrsTabsContainer";
import { ConfirmDeletion } from "./ConfirmDeletion";
import { deleteRPMEntry } from "../../../api/patientDetailsApi";
import { VrsPatientInfo } from "../../../../shared/interfaces";
import SnackbarUtils from "../../../../../utils/SnackbarUtils";
import { loadRpmReviewItemsAsync } from "../../../patientDetailsSlice";
import { useAppDispatch } from "../../../../../hooks/useAppDispatch";
import { useProfile } from "../../../../../context/profile";
import { useAppSelector } from "../../../../../hooks/useAppSelector";
import { selectedRelatedStaffByHospital } from "../../../../Hospitals/hospitalSlice";
import { getPatientByIdAsync } from "../../../../shared/slices/asyncThunks";

interface ReviewRPMProps {
  open: boolean;
  onClose: () => void;
  patient: VrsPatientInfo;
  previousMonthReport?: RPMReport;
  currentMonthReport?: RPMReport;
}

const currentDate = dayjs();
const currentDateStringified = dayjs().format();
const previousMonthStringified = currentDate.subtract(1, "month").format();

export const ReviewRPM = ({
  open,
  onClose,
  patient,
  currentMonthReport,
  previousMonthReport,
}: ReviewRPMProps): JSX.Element => {
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [selectedEntry, setSelectedEntry] = useState<RPMManualEntry | undefined>();
  const relatedStaff = useAppSelector(selectedRelatedStaffByHospital);
  const profile = useProfile();
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const relatedStaffLookup = useMemo(() => {
    const lookupMap: { [id: number]: MedStaffProfileItem } = {};
    relatedStaff.forEach((item) => {
      lookupMap[item.id] = item;
    });
    if (profile && profile.id) {
      lookupMap[profile.id] = {
        first_name: profile.first_name || "",
        id: profile.id,
        last_name: profile.last_name || "",
        profession: profile.profession,
      };
    }
    return lookupMap;
  }, [relatedStaff, profile]);
  const handleDeleteClicked = (entry: RPMManualEntry) => {
    setSelectedEntry(entry);
    setDeleteConfirmationOpen(true);
  };

  const handleDeleteCancelled = () => {
    setDeleteConfirmationOpen(false);
    setSelectedEntry(undefined);
  };

  const handleDeleteConfirmed = () => {
    if (selectedEntry) {
      deleteRPMEntry(patient.id, selectedEntry?.id)
        .then(() => {
          SnackbarUtils.success("RPM Entry deleted.");
          dispatch(loadRpmReviewItemsAsync(patient.id));
          dispatch(getPatientByIdAsync({ userId: patient.id, ignoreLocalState: true }));
        })
        .catch(() => {
          SnackbarUtils.error("Could not delete RPM entry.");
        })
        .finally(() => {
          handleDeleteCancelled();
        });
    }
  };
  return (
    <Dialog open={open} onClose={onClose} maxWidth={false}>
      <Box sx={{ padding: theme.spacing(3), minWidth: "100%" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: theme.spacing(2),
            borderBottom: `1px solid ${theme.veris.colors.neutrals["grey-light"]}`,
          }}
        >
          <Typography variant="h3" color={verisColors.neutrals["grey-4"]}>
            Review Clinical Time
          </Typography>
          <VrsIconButton onClick={onClose} iconProps={{ name: IconName.CloseIcon }} />
        </Box>
        <Box sx={{ marginTop: theme.spacing(3) }}>
          <VrsTabsContainer
            containerOverrides={{
              border: "none",
            }}
            sx={{
              borderBottom: "none",
              "& .MuiTab-root": {
                border: `1px solid ${theme.veris.colors.neutrals["grey-light"]}`,
              },
              "& .MuiTabs-indicator": {
                backgroundColor: `${theme.veris.colors.neutrals["grey-light"]} !important`,
                height: "0px",
              },
              "& .MuiTab-root:first-child": {
                borderTopLeftRadius: "6px",
                borderBottomLeftRadius: "6px",
              },
              "& .MuiTab-root:last-child": {
                borderTopRightRadius: "6px",
                borderBottomRightRadius: "6px",
              },
            }}
            tabsContainerContent={{
              "Previous Month": (
                <Box key="Previous Month">
                  <ReviewRpmHeader
                    date={previousMonthStringified}
                    systemRpmTime={previousMonthReport?.total_system || 0}
                    totalRpmTime={previousMonthReport?.total || 0}
                  />
                  <Box>
                    <ReviewRpmTable
                      staffLookup={relatedStaffLookup}
                      manualReports={previousMonthReport?.manual || []}
                      onDeleteEntry={handleDeleteClicked}
                      systemReport={{
                        amount: previousMonthReport?.total_system || 0,
                        dateOccurred: previousMonthStringified,
                        dateReported: previousMonthStringified,
                      }}
                    />
                  </Box>
                </Box>
              ),
              "Current Month": (
                <Box key="Current Month">
                  <ReviewRpmHeader
                    date={currentDateStringified}
                    systemRpmTime={currentMonthReport?.total_system || 0}
                    totalRpmTime={currentMonthReport?.total || 0}
                  />
                  <Box>
                    <ReviewRpmTable
                      staffLookup={relatedStaffLookup}
                      manualReports={currentMonthReport?.manual || []}
                      onDeleteEntry={handleDeleteClicked}
                      systemReport={{
                        amount: currentMonthReport?.total_system || 0,
                        dateOccurred: currentDateStringified,
                        dateReported: currentDateStringified,
                      }}
                    />
                  </Box>
                </Box>
              ),
            }}
          />
          {selectedEntry && (
            <ConfirmDeletion
              activities={selectedEntry.activities}
              dateEntered={selectedEntry.date_occurred}
              open={deleteConfirmationOpen}
              dateOccurred={selectedEntry.date_occurred}
              duration={selectedEntry.minutes}
              staffName={constructDisplayName(relatedStaffLookup[selectedEntry.med_staff_id])}
              onClose={handleDeleteCancelled}
              onDelete={handleDeleteConfirmed}
            />
          )}
        </Box>
      </Box>
    </Dialog>
  );
};
