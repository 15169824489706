import { NotificationType } from "@veris-health/communication-ms/lib/v1";
import { IconName, verisColors } from "@veris-health/web-core";
import { PatientStatusEnumAPI } from "@veris-health/user-ms/lib/v2";
import { RouteDictionaryValues, Routes } from "./routes-config";
import { HealthStatusOptionsType } from "./ui/components/VrsHealthStatus";

export type TopBarTitles = {
  [k in RouteDictionaryValues]?: string;
};

export const topBarTitles: TopBarTitles = {
  [Routes.STAFF_LOGIN]: "Login",
  [Routes.DASHBOARD]: "Medical Dashboard",
  [Routes.PATIENTS]: "All Patients",
  [Routes.NEW_PATIENTS]: "New Patients",
  [Routes.INPATIENTS]: "Inpatients",
  [Routes.QOL_IMPROVE]: "Improve",
  [Routes.QOL_NO_CHANGE]: "No Change",
  [Routes.QOL_WORSEN]: "Worsen",
  [Routes.SYMPTOM_REPORT]: "New Symptom Reported",
  [Routes.SEVERE_SYMPTOM_PATIENTS]: "Severe Symptom",
  [Routes.NOTIFICATIONS_DETAILS]: "Notifications",
  [Routes.CALENDAR]: "Calendar",
  [Routes.VIDEO_COMMUNICATION]: "Patient Overview - On Call",
  [Routes.TASKS]: "Tasks",
} as const;

export type TopBarMenuItems = {
  [k in RouteDictionaryValues]?: string[];
};

export const topBarMenuItems: TopBarMenuItems = {
  [Routes.STAFF_LOGIN]: ["Login"],
  [Routes.PATIENT_DETAILS]: [
    "Overview",
    "Treatment Plan",
    "Patient Records",
    "Progress Notes",
    "Tasks",
  ],
};

export const topBarBreadcrumbItems: TopBarMenuItems = {
  [Routes.PATIENT_GOALS]: ["Patient Overview", "Treatment Goals"],
  [Routes.PATIENT_ALLERGIES]: ["Patient Overview", "Allergies"],
};

export interface SideBarMenuItem {
  title: string;
  iconName: IconName;
  path: RouteDictionaryValues;
}

export interface SideBarMenu {
  [sideBarMenuItem: string]: SideBarMenuItem[];
}

export const sideBarMenu: SideBarMenu = {
  "": [
    { title: "Dashboard", iconName: IconName.Dashboard, path: Routes.DASHBOARD },
    { title: "Tasks", iconName: IconName.Tasks, path: Routes.TASKS },
    { title: "Calendar", iconName: IconName.Calendar, path: Routes.CALENDAR },
  ],
  "QUALITY OF LIFE": [
    {
      title: "No Change",
      iconName: IconName.StablePatients,
      path: Routes.QOL_NO_CHANGE,
    },
    {
      title: "Improve",
      iconName: IconName.GoodPatients,
      path: Routes.QOL_IMPROVE,
    },
    {
      title: "Worsen",
      iconName: IconName.CriticalPatients,
      path: Routes.QOL_WORSEN,
    },
  ],
  Patients: [
    {
      title: "All Patients",
      iconName: IconName.AllPatients,
      path: `${Routes.PATIENTS}`,
    },
    { title: "Inpatients", iconName: IconName.InPatients, path: Routes.INPATIENTS },
    { title: "New Patients", iconName: IconName.NewPatients, path: Routes.NEW_PATIENTS },
    { title: "New symptom reported", iconName: IconName.NewSymptom, path: Routes.SYMPTOM_REPORT },
    {
      title: "Severe symptoms",
      iconName: IconName.SevereSymptom,
      path: Routes.SEVERE_SYMPTOM_PATIENTS,
    },
  ],
};

export type SensorTypes = "temp" | "bpm" | "rpm" | "motion";

/**
 * In minutes. This should be the timeout minutes -2 because in the idle dialog we have a timer for 2 mins
 */
export const SessionWarningTimeout = 58;

export const ALL_HOSPITALS = "All Hospitals";

export const isSystemNotification = (type: NotificationType): boolean => {
  return [
    "release-notes",
    "appointment-cancellation",
    "appointment-reminder",
    "appointment-confirmation",
    "appointment-modification",
    "symptom-reminder",
    "secrets-expiration-reminder",
    "out-of-range-patients",
  ].includes(type);
};

export const numberRegexpPattern = /^[0-9\b]+$/;

export const textRegxpPattern = /^[a-zA-Z0-9-._ ]*$/;

export const floatNumberRegexPattern = /^[+-]?^\d*\.?\d*$/;

export const testAccountEmail = "test.automation@loka.com";
export const testOTP = "9537";

export const enum TagsTypes {
  Inpatients = "inpatient",
  NewPatients = "new patient",
  NewSymptom = "new symptom",
  SevereSymptom = "severe symptom",
}

export const NOTIFICATIONS_OFFSET = 10;

export const QolAnswerColor: Record<number, string> = {
  1: verisColors.moderate.normal,
  2: verisColors.mango.severe,
  3: verisColors.errors.normal,
};

export const MESSAGES_PAGE_SIZE = 15;

export const PATIENT_DETAILS_PER_PAGE = 20;

export const KEEP_ALIVE_TIMER = 60000;

export const EMPTY_DATASET_MESSAGE = "No EHR Data Available";

type LinearGradientPointType = "start" | "end";

export type TrendingChartColor = Record<
  HealthStatusOptionsType,
  Record<LinearGradientPointType, string>
>;

export const trendingChartColors: TrendingChartColor = {
  Abnormal: { start: verisColors.mango.soft, end: verisColors.mango.light },
  Good: { start: verisColors.moderate.normal, end: verisColors.moderate.light },
  Critical: { start: verisColors.mango.normal, end: verisColors.mango.light },
  Stable: { start: verisColors.amethyst.normal, end: verisColors.amethyst.light },
};

export const PAGE_NAME = "VERIS";

export const exportOptions = {
  SelectAll: "Select All",
  DataGraphs: "At-Home Devices",
  Symptoms: "Symptom Summaries",
  RawData: "Physiologic Data",
  SymptomDetails: "Symptom Details",
};

export const exportFilterOptions = [
  {
    label: exportOptions.SelectAll,
    value: exportOptions.SelectAll,
  },
  {
    label: exportOptions.DataGraphs,
    value: exportOptions.DataGraphs,
  },
  {
    label: exportOptions.Symptoms,
    value: exportOptions.Symptoms,
  },
  {
    label: exportOptions.RawData,
    value: exportOptions.RawData,
  },
  {
    label: exportOptions.SymptomDetails,
    value: exportOptions.SymptomDetails,
  },
];

export const PatientStatusesMap: Record<PatientStatusEnumAPI, string> = {
  [PatientStatusEnumAPI.Inactive]: "Inactive",
  [PatientStatusEnumAPI.HospitalAtHome]: "Hospital at Home",
  [PatientStatusEnumAPI.RpmNavigation]: "RPM + Navigation",
  [PatientStatusEnumAPI.Deceased]: "Deceased",
  [PatientStatusEnumAPI.Survivor]: "Survivor",
  [PatientStatusEnumAPI.Ccm]: "CCM",
  [PatientStatusEnumAPI.RpmCcm]: "RPM + CCM",
  [PatientStatusEnumAPI.Rpm]: "RPM",
  [PatientStatusEnumAPI.Hospitalized]: "Hospitalized",
};

export const MeasurementUnits: { [key: string]: string } = {
  temp: "ºF",
  bpm: "bpm",
  motion: "steps",
  blood_pressure: "mmHg",
  oximeter: "%",
  weight: "lbs",
  rpm: "rpm",
  ecg: "",
};
