import React from "react";
import { Box, Stack, Typography, CircularProgress } from "@mui/material";
import { Popup } from "@mobiscroll/react";
import InfiniteScroll from "react-infinite-scroll-component";
import { IconName, VrsIconButton, VrsDivider } from "@veris-health/web-core";
import { AppointmentNotificationView } from "@veris-health/communication-ms/lib/v1";
import { BillingNotification } from "../BillingNotification/BillingNotification";

export interface BillingNotificationsPopupProps {
  anchorEl: HTMLButtonElement;
  handleClose: () => void;
  totalCount: number;
  notifications: AppointmentNotificationView[];
  fetchNotifications: () => void;
  selectNotification: (id: number) => void;
}

export const BillingNotificationsPopup = ({
  anchorEl,
  handleClose,
  notifications,
  totalCount,
  fetchNotifications,
  selectNotification,
}: BillingNotificationsPopupProps): JSX.Element => {
  return (
    <>
      <Popup
        isOpen={!!anchorEl}
        anchor={anchorEl}
        onClose={handleClose}
        scrollLock={false}
        display="anchored"
        css={{
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
        }}
        contentPadding={false}
      >
        <Stack direction="row" mx={2} my={1} justifyContent="space-between">
          <Typography variant="h5" pt={1}>
            Unconfirmed calls • Billing information
          </Typography>
          <Box pt={0.5}>
            <VrsIconButton iconProps={{ name: IconName.CloseIcon }} onClick={handleClose} />
          </Box>
        </Stack>
        <VrsDivider orientation="horizontal" height="2px" />
        {notifications?.length > 0 ? (
          <Box
            p={1}
            overflow="auto"
            sx={{ maxHeight: "50vh" }}
            id="unconfirmed-calls-scroll-container"
          >
            <InfiniteScroll
              scrollableTarget="unconfirmed-calls-scroll-container"
              hasMore={notifications.length < totalCount}
              style={{ overflow: "hidden" }}
              dataLength={totalCount}
              next={fetchNotifications}
              loader={
                <Box display="flex" justifyContent="center" alignItems="center" p={1}>
                  <CircularProgress />
                </Box>
              }
            >
              {notifications &&
                notifications.length > 0 &&
                notifications.map((notification) => (
                  <BillingNotification
                    notification={notification}
                    onClick={selectNotification}
                    key={notification.video_billing_id}
                  />
                ))}
            </InfiniteScroll>
          </Box>
        ) : (
          <Typography
            color={(theme) => theme.veris.colors.neutrals["grey-3"]}
            textAlign="center"
            variant="body"
            p={2}
          >
            There are no unconfirmed calls.
          </Typography>
        )}
      </Popup>
    </>
  );
};
