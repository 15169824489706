import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";
import { HospitalInfo, MedStaffProfileModel } from "@veris-health/user-ms/lib/v1";
import { VrsAvatar, VrsDivider, VrsSelect } from "@veris-health/web-core";
import { Routes } from "../../../routes-config";
import { ALL_HOSPITALS } from "../../../constants";

export const HospitalsDropdownHeader = ({
  isAllPatientsChecked,
  toggleAllPatients,
}: {
  isAllPatientsChecked: boolean;
  toggleAllPatients: (value: boolean) => void;
}): JSX.Element => {
  return (
    <Box p={1} maxWidth="280px">
      <Typography variant="bodySemibold" pb={1}>
        HOSPITAL LIST
      </Typography>
      <FormControlLabel
        sx={{ paddingBottom: (theme) => theme.spacing(1) }}
        control={
          <Checkbox
            sx={{
              paddingTop: (theme) => theme.spacing(0.75),
              color: (theme) => theme.veris.colors.amethyst.normal,
            }}
            checked={!!isAllPatientsChecked}
            onChange={(e) => {
              toggleAllPatients(e.target.checked);
            }}
          />
        }
        label={
          <Typography variant="body" color={(theme) => theme.veris.colors.neutrals["grey-3"]}>
            See all patients from the selected hospital(s)
          </Typography>
        }
      />
      <VrsDivider height="100" />
    </Box>
  );
};
export interface DoctorCardProps {
  selectedHospital: string;
  hospitals: HospitalInfo[];
  onHospitalSelect: (id: string) => void;
  profile?: MedStaffProfileModel;
  isAllPatientsChecked: boolean;
  toggleAllPatients: (value: boolean) => void;
}

export function DoctorCard({
  profile,
  hospitals,
  onHospitalSelect,
  selectedHospital,
  isAllPatientsChecked,
  toggleAllPatients,
}: DoctorCardProps): JSX.Element {
  const history = useHistory();

  const hospitalOptions = [{ label: ALL_HOSPITALS, value: ALL_HOSPITALS }].concat(
    hospitals.map((hospital) => ({
      label: hospital.name,
      value: `${hospital.id}`,
    })),
  );

  const fullName = profile ? `${profile.first_name} ${profile.last_name}` : "";

  return (
    <Box display="flex" alignItems="center">
      <Box onClick={() => history.push(Routes.DOCTOR_PROFILE)} sx={{ cursor: "pointer" }}>
        <VrsAvatar name={fullName} />
      </Box>
      <Box display="flex" sx={{ flexDirection: "column", marginLeft: 2 }}>
        <Typography variant="subtitle2" mb={0.5} data-test-hook="doctorCard-fullName">
          {fullName}
        </Typography>
        <Box sx={{ width: "8.5rem" }}>
          <VrsSelect
            options={hospitalOptions}
            value={selectedHospital}
            onSelected={onHospitalSelect}
            large
            data-test-hook="doctorCard-selectedHospital"
          >
            <HospitalsDropdownHeader
              isAllPatientsChecked={isAllPatientsChecked}
              toggleAllPatients={toggleAllPatients}
            />
          </VrsSelect>
        </Box>
      </Box>
    </Box>
  );
}
