import React from "react";
import { Box, styled, Theme, Typography, useMediaQuery } from "@mui/material";
import { VrsTooltip, verisColors } from "@veris-health/web-core";
import { ExpandedSymptomEvent, SensorsGraphView } from "../../../measurementSlice";
import CustomSymptomTooltip from "../CustomSymptomTooltip";
import { StyledSymptomsDailyViewProps } from "../SymptomsDailyView";
import { getSymptomProps, getCursorCssStyle } from "../../../../../utils/symptoms";

export interface SymptomCellProps {
  entry: ExpandedSymptomEvent;
  onSymptomClickHandler: () => void;
  isPreview: boolean;
  view?: SensorsGraphView;
  isClickedSymptom?: boolean;
}

const StyledCircle = styled(Box, {
  shouldForwardProp: (prop: string) => prop !== "isPreview",
})<StyledSymptomsDailyViewProps>(({ theme, isPreview }) => ({
  justifyContent: "center",
  display: "flex",
  borderRadius: "100%",
  minHeight: isPreview ? "22px" : "32px",
  minWidth: isPreview ? "22px" : "32px",
  marginLeft: isPreview ? "1px" : theme.spacing(1),
}));

export function SymptomCell({
  entry,
  onSymptomClickHandler,
  isPreview,
  view,
}: SymptomCellProps): JSX.Element {
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));
  const getWidth = () => {
    return view === "daily" ? "125px" : "145px";
  };
  return (
    <Box
      display="flex"
      justifyContent="flex-end"
      ml={view === "daily" ? 1 : 0}
      color={verisColors.neutrals["grey-dark"]}
      alignItems="center"
      whiteSpace="nowrap"
      minWidth={!isSmallScreen && !isPreview ? "160px" : getWidth()}
    >
      {isPreview ? (
        <Typography
          variant="body"
          mr={1}
          pt={0.43}
          style={{
            color:
              getSymptomProps(entry).label === "None reported"
                ? verisColors.neutrals["grey-3"]
                : verisColors.neutrals["grey-4"],
          }}
        >
          {getSymptomProps(entry).label}
        </Typography>
      ) : (
        <Typography
          variant="h6"
          mr={1}
          pt={0.43}
          style={{
            color:
              getSymptomProps(entry).label === "None reported"
                ? verisColors.neutrals["grey-3"]
                : verisColors.neutrals["grey-4"],
          }}
        >
          {getSymptomProps(entry).label}
        </Typography>
      )}
      <VrsTooltip
        title={<CustomSymptomTooltip symptom={entry} isPreview={isPreview} />}
        bcgcolor={verisColors.neutrals.black}
        placement="bottom"
      >
        <StyledCircle
          sx={{
            backgroundColor: entry.style?.fillColor,
            cursor: getCursorCssStyle(isPreview, entry.calculatedStatus),
            border: `1px solid ${
              entry.calculatedStatus === "empty"
                ? verisColors.neutrals["grey-2"]
                : entry.style?.fillColor
            }`,
          }}
          onClick={onSymptomClickHandler}
          isPreview={isPreview}
        >
          {isPreview ? (
            <Typography
              color={verisColors.neutrals["grey-5"]}
              variant="body"
              sx={{ position: "relative", top: "1.5px" }}
            >
              {entry.style?.fillValue}
            </Typography>
          ) : (
            <Typography
              color={verisColors.neutrals["grey-5"]}
              variant="h5"
              sx={{ position: "relative", top: "2.1px" }}
            >
              {entry.style?.fillValue}
            </Typography>
          )}
        </StyledCircle>
      </VrsTooltip>
    </Box>
  );
}
