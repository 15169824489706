import React from "react";
import { Box, CircularProgress, Theme, Typography } from "@mui/material";
import { SensorEnum } from "@veris-health/med-data-ms/lib/v1";
import { PatientType } from "@veris-health/user-ms/lib/v1";
import { get, maxBy, minBy } from "lodash";
import { VrsErrorMsg, VrsIcon, VrsTooltip } from "@veris-health/web-core";
import { useAppSelector } from "../../../hooks/useAppSelector";
import {
  BloodPressureNormalRange,
  MioSensorMeasurement,
  selectMeasurementsStatus,
  selectMioSensors,
  selectReferenceLine,
  selectSensorsGraphView,
} from "../measurementSlice";
import MioGraph from "../components/Sensors/MioGraph";
import { StyledSensorDetails, StyledSensorLabel } from "../../shared/styledComponents";
import { SensorLabel } from "../components/Sensors/SensorLabel";
import {
  MioSensorLabelProps,
  mioSensors,
  MioSensorValueYAxisRange,
} from "../components/shared/constants";
import { VrsPatientInfo } from "../../shared/interfaces";

export interface DetailsMeasurementProps {
  isPreview?: boolean;
  type?: PatientType;
  fetchData: () => void;
  currentPatient: VrsPatientInfo;
}

export interface Sensor {
  name: SensorEnum;
  values: MioSensorMeasurement[] | undefined;
  label: MioSensorLabelProps;
  withMeasurementUnit?: string;
  dataKey: string;
  secondaryDataKey?: string;
  normalRange:
    | {
        min: number | BloodPressureNormalRange;
        max: number | BloodPressureNormalRange;
      }
    | undefined;
}

const MioSensorLabel = ({
  isLast,
  strokeColor,
  isFirst,
  title,
  iconName,
  id,
  outlayingValues,
  unit,
}: MioSensorLabelProps & { outlayingValues: boolean }): JSX.Element => (
  <StyledSensorLabel isFirst={!!isFirst} isLast={!!isLast} key={id}>
    <Box display="flex" flexDirection="row" alignItems="flex-start">
      <Typography
        color={(theme) => theme.veris.colors.neutrals["grey-5"]}
        pt={outlayingValues ? 0 : 0.5}
      >
        {title}
      </Typography>
      <Box sx={{ flexGrow: 1 }} />
      {outlayingValues && (
        <VrsTooltip
          title="Chart is rescaled to show outlaying values"
          bcgcolor={`${(theme: Theme) => theme.veris.colors.neutrals.black}`}
        >
          <Typography
            variant="h1"
            color={(theme) => theme.veris.colors.errors.normal}
            sx={{ cursor: "default" }}
          >
            •
          </Typography>
        </VrsTooltip>
      )}
    </Box>
    <StyledSensorDetails>
      {iconName && (
        <VrsIcon
          name={iconName}
          sx={{ marginTop: (theme) => theme.spacing(outlayingValues ? 0 : 0.5) }}
          stroke={strokeColor}
        />
      )}
    </StyledSensorDetails>
    <StyledSensorDetails>
      {" "}
      {unit && (
        <Typography color={(theme) => theme.veris.colors.neutrals["grey-3"]}>{unit}</Typography>
      )}
    </StyledSensorDetails>
  </StyledSensorLabel>
);

export const MioGraphContainer = ({
  isPreview,
  fetchData,
  currentPatient,
}: DetailsMeasurementProps): JSX.Element => {
  const mioSensorsData = useAppSelector((state) => selectMioSensors(state, +currentPatient.id));
  const view = useAppSelector(selectSensorsGraphView);
  const referenceLine = useAppSelector(selectReferenceLine);
  const sensorDataStatus = useAppSelector(selectMeasurementsStatus);
  const [
    temperatureLabelData,
    bpmLabelData,
    oximeterLabelData,
    bloodPressureLabelData,
    weightLabelData,
    motionLabelData,
  ] = mioSensors;

  const sensors: Sensor[] = [
    {
      name: SensorEnum.Temp,
      values: mioSensorsData?.temp?.data || [],
      label: temperatureLabelData,
      withMeasurementUnit: "º",
      dataKey: "v",
      normalRange: mioSensorsData?.temp?.normalRange || { min: 0, max: 0 },
    },
    {
      name: SensorEnum.Bpm,
      values: mioSensorsData?.bpm?.data || [],
      label: bpmLabelData,
      dataKey: "v",
      normalRange: mioSensorsData?.bpm?.normalRange || { min: 0, max: 0 },
    },
    {
      name: SensorEnum.Oximeter,
      values: mioSensorsData?.oximeter?.data || [],
      label: oximeterLabelData,
      dataKey: "v",
      normalRange: mioSensorsData?.oximeter?.normalRange || { min: 0, max: 0 },
    },
    {
      name: SensorEnum.BloodPressure,
      values: mioSensorsData?.blood_pressure?.data || [],
      label: bloodPressureLabelData,
      dataKey: "sys",
      secondaryDataKey: "dia",
      normalRange: mioSensorsData?.blood_pressure?.normalRange || { min: 0, max: 0 },
    },
    {
      name: SensorEnum.Weight,
      values: mioSensorsData?.weight?.data || [],
      label: weightLabelData,
      dataKey: "v",
      normalRange: mioSensorsData?.weight?.normalRange || { min: 0, max: 0 },
    },
    {
      name: SensorEnum.Motion,
      values: mioSensorsData?.motion?.data || [],
      label: motionLabelData,
      dataKey: "sum",
      normalRange: mioSensorsData?.motion?.normalRange || { min: 0, max: 0 },
    },
  ];

  function checkOutlayingValues(sensorName: SensorEnum): boolean {
    const sensorData = sensors.find((sensor) => sensor.name === sensorName);
    if (sensorData) {
      const max = get(
        maxBy(sensorData.values, (value) => get(value, sensorData.dataKey)),
        sensorData.dataKey,
      );
      const min = get(
        minBy(sensorData.values, (value) =>
          get(value, sensorData.secondaryDataKey ?? sensorData.dataKey),
        ),
        sensorData.secondaryDataKey ?? sensorData.dataKey,
      );

      if (
        max > MioSensorValueYAxisRange[sensorData.name].max ||
        min < MioSensorValueYAxisRange[sensorData.name].min
      )
        return true;
    }
    return false;
  }

  return (
    <Box display="flex" px={0} py={1}>
      {!isPreview && (
        <Box sx={{ overflow: "initial" }}>
          {mioSensors.map((sensor) => (
            <MioSensorLabel
              {...sensor}
              key={sensor.id}
              outlayingValues={checkOutlayingValues(sensor.id)}
            />
          ))}
        </Box>
      )}
      <Box
        sx={{
          width: "100%",
        }}
      >
        {sensorDataStatus === "failed" && <VrsErrorMsg onClick={fetchData} />}
        {sensorDataStatus === "loading" && (
          <Box
            display="flex"
            justifyContent="center"
            height={110 * sensors.length}
            alignItems="center"
          >
            <CircularProgress />
          </Box>
        )}
        {sensorDataStatus === "idle" &&
          sensors.map(
            ({
              values,
              name,
              dataKey,
              withMeasurementUnit,
              label,
              secondaryDataKey,
              normalRange,
            }) =>
              values &&
              normalRange && (
                <React.Fragment key={name}>
                  <Box
                    display="flex"
                    justifyContent="space-evenly"
                    sx={{
                      borderBottom: (theme) =>
                        !isPreview && name === "motion"
                          ? `2px solid ${theme.veris.colors.neutrals["grey-2"]}`
                          : `1px solid ${theme.veris.colors.neutrals["grey-2"]}`,
                      borderTop: (theme) =>
                        !isPreview && name === "temp"
                          ? `2px solid ${theme.veris.colors.neutrals["grey-2"]}`
                          : `1px solid ${theme.veris.colors.neutrals["grey-2"]}`,

                      height: isPreview ? "auto" : "110px",
                    }}
                  >
                    <Box flex={1} width={0}>
                      {isPreview && (
                        <SensorLabel
                          {...label}
                          isPreview={isPreview}
                          outlayingValues={checkOutlayingValues(name)}
                        />
                      )}
                      <MioGraph
                        sensorName={name}
                        data={values}
                        dataKey={dataKey}
                        withMeasurementUnit={withMeasurementUnit}
                        isPreview={isPreview}
                        referenceLine={referenceLine}
                        secondaryDataKey={secondaryDataKey}
                        normalRange={normalRange}
                        sensorsGraphView={view}
                      />
                    </Box>
                  </Box>
                </React.Fragment>
              ),
          )}
      </Box>
    </Box>
  );
};
