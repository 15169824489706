import { Box, BoxProps, styled } from "@mui/material";
import React from "react";
import { verisColors } from "@veris-health/web-core";

export interface VrsBannerProps extends BoxProps {
  bcgcolor?: string;
  bordercolor?: string;
}

const StyledWarningBanner = styled(({ children, ...props }: VrsBannerProps) => (
  <Box {...props}>{children}</Box>
))(({ theme, bcgcolor, bordercolor }) => ({
  border: `1px solid ${bordercolor}`,
  backgroundColor: bcgcolor,
  borderRadius: theme.spacing(1),
  padding: theme.spacing(1),
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  margin: theme.spacing(1, 0),
}));

export function VrsBanner({
  children,
  bcgcolor = verisColors.mango.light,
  bordercolor = verisColors.mango.normal,
}: VrsBannerProps): JSX.Element {
  return (
    <StyledWarningBanner
      data-test-hook="banner-element"
      bcgcolor={bcgcolor}
      bordercolor={bordercolor}
    >
      {children}
    </StyledWarningBanner>
  );
}
