import { PainSeverityEnum, ReportStatusEnum, SymptomEvent } from "@veris-health/user-ms/lib/v1";
import dayjs from "dayjs";
import { groupBy } from "lodash";
import { v4 as uuid } from "uuid";
import { verisColors } from "@veris-health/web-core";
import { EventTypeEnum } from "@veris-health/virtual-doc-ms/lib/v1";
import {
  ExpandedSymptomEvent,
  SymptomStatusEnum,
  SymptomStyle,
} from "../features/PatientDetailsMeasurements/measurementSlice";
import { utcToLocal } from "./date";

const dateFormat = "YYYY-MM-DD";

export const getCursorCssStyle = (
  isPreview: boolean,
  status: SymptomStatusEnum,
): "default" | "pointer" => {
  switch (status) {
    case "no_update":
    case "empty":
    case "incomplete_no_update":
      return "default";
    default:
      return isPreview ? "default" : "pointer";
  }
};

export const symptomSeverityColor: Record<PainSeverityEnum, SymptomStyle> = {
  mild: { fillColor: verisColors.mango.mild, fillValue: 1, label: "Mild" },
  moderate: { fillColor: verisColors.mango.moderate, fillValue: 2, label: "Moderate" },
  severe: { fillColor: verisColors.mango.severe, fillValue: 3, label: "Severe" },
  "very severe": { fillColor: verisColors.mango.normal, fillValue: 4, label: "Very Severe" },
};

export const symptomStatusColor: Record<SymptomStatusEnum, SymptomStyle> = {
  resolved: { fillColor: verisColors.moderate.soft, fillValue: "R", label: "Resolved" },
  no_update: { fillColor: verisColors.mango.light, fillValue: "", label: "No updates" },
  incomplete: { fillColor: verisColors.neutrals["grey-2"], fillValue: "", label: "Incomplete" },
  incomplete_no_update: {
    fillColor: verisColors.neutrals["grey-2"],
    fillValue: "",
    label: "Incomplete",
  },
  deleted: { fillColor: verisColors.neutrals["grey-2"], fillValue: "D", label: "Deleted" },
  empty: { fillColor: verisColors.neutrals.white, fillValue: "", label: "None reported" },
  new: { fillColor: verisColors.neutrals.black, fillValue: "", label: "" },
  active: { fillColor: verisColors.neutrals.black, fillValue: "", label: "" },
  stable: { fillColor: verisColors.neutrals.black, fillValue: "", label: "" },
};

export const getSymptomProps = (entry: ExpandedSymptomEvent): SymptomStyle => {
  const symptomStatus: SymptomStatusEnum[] = [
    ReportStatusEnum.Resolved,
    ReportStatusEnum.Incomplete,
    ReportStatusEnum.Deleted,
    "empty",
    "no_update",
    "incomplete_no_update",
  ];
  if (entry.severity && !symptomStatus.includes(entry.calculatedStatus)) {
    const { severity } = entry;
    return symptomSeverityColor[severity as keyof typeof symptomSeverityColor];
  }
  return symptomStatusColor[entry.calculatedStatus];
};

export interface SymptomReport {
  name: string;
  events: ExpandedSymptomEvent[];
}

export const configureSymptomsData = (data: SymptomReport[]): SymptomReport[] => {
  return data.map((symptom: SymptomReport) => {
    const configuredSymptomObj = symptom.events.map((symptomEvent) => {
      const eventDisplayProperties = getSymptomProps(symptomEvent);

      return {
        ...symptomEvent,
        style: {
          fillColor: eventDisplayProperties.fillColor,
          fillValue: eventDisplayProperties.fillValue,
          label: eventDisplayProperties.label,
        },
      };
    });

    return { ...symptom, events: configuredSymptomObj };
  });
};

function groupByDate(timestamp: string) {
  return dayjs(timestamp).format(dateFormat);
}

export const symptomTimelineEventsCalculation = (
  daysArray: string[],
  activeSymptom: SymptomReport,
): SymptomReport => {
  const groups: Record<string, SymptomEvent[]> = groupBy(activeSymptom.events, (symtpomEvent) =>
    groupByDate(utcToLocal(symtpomEvent.timestamp).format()),
  );
  const start: Record<string, SymptomEvent> = {};
  const singleGroups: Record<string, SymptomEvent> = Object.keys(groups).reduce((res, key) => {
    // get all the events for the given day (in format YYYY-MM-DD)
    const events = groups[key];
    // sort all the events by timestamp
    events.sort((a, b) => (utcToLocal(a.timestamp).isBefore(utcToLocal(b.timestamp)) ? -1 : 1));
    // fetch the last event
    const lastEvent = events[events.length - 1];
    // put it in the map
    res[key] = lastEvent;
    return res;
  }, start);
  const newSymptomObj = {
    ...activeSymptom,
    events: daysArray.map((day): ExpandedSymptomEvent => {
      const date = dayjs(day);
      const current = singleGroups[date.format(dateFormat)];
      if (current)
        return {
          ...current,
          generalStatus: current.status,
          calculatedStatus: current.status,
          key: uuid(),
          // todo remove this once BE makes symptom_name required, as it should be
          symptom_name: current.symptom_name || "",
        };
      const prevEvents = activeSymptom.events?.filter((item) =>
        utcToLocal(item.timestamp).isBefore(date),
      );
      const prev: SymptomEvent | undefined = prevEvents && prevEvents[prevEvents.length - 1];
      const finalSymptomStatus: SymptomStatusEnum[] = [
        ReportStatusEnum.Resolved,
        ReportStatusEnum.Deleted,
      ];
      if (prev) {
        if (prev.status === "incomplete")
          return {
            ...prev,
            calculatedStatus: "incomplete_no_update",
            generalStatus: prev.status,
            timestamp: "",
            local_timestamp: "",
            key: uuid(),
            // todo remove this once BE makes symptom_name required, as it should be
            symptom_name: prev.symptom_name || "",
          };
        if (!finalSymptomStatus.includes(prev.status))
          return {
            ...prev,
            calculatedStatus: "no_update",
            generalStatus: prev.status,
            timestamp: "",
            local_timestamp: "",
            key: uuid(),
            // todo remove this once BE makes symptom_name required, as it should be
            symptom_name: prev.symptom_name || "",
          };
        return {
          ...prev,
          calculatedStatus: "empty",
          generalStatus: prev.status,
          timestamp: "",
          local_timestamp: "",
          key: uuid(),
          // todo remove this once BE makes symptom_name required, as it should be
          symptom_name: prev.symptom_name || "",
        };
      }
      return {
        calculatedStatus: "empty",
        generalStatus: ReportStatusEnum.Active,
        report_id: 0,
        symptom_name: "",
        active_since_date: "",
        is_userdefined: false,
        severity: undefined,
        event: EventTypeEnum.Added,
        timestamp: "",
        local_timestamp: "",
        status: ReportStatusEnum.Active,
        in_timeline: true,
        key: uuid(),
      };
    }),
  };
  return newSymptomObj;
};
