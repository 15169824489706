import { Typography, Box } from "@mui/material";
import React from "react";
import { VrsButton } from "@veris-health/web-core";

export const ResolveConfirmationModal = ({
  onClose,
  onConfirm,
}: {
  onClose: () => void;
  onConfirm: () => void;
}): JSX.Element => (
  <>
    <Typography variant="subtitle1">
      This action can not be undone and the task will remain in resolved status!
    </Typography>
    <Box sx={{ display: "flex", justifyContent: "end", paddingTop: "1rem", gap: "1rem" }}>
      <VrsButton buttonType="secondary" onClick={onClose}>
        Cancel
      </VrsButton>
      <VrsButton buttonType="primary" onClick={onConfirm}>
        Confirm
      </VrsButton>
    </Box>
  </>
);

export const ConfirmCloseModal = ({
  onClose,
  onConfirm,
}: {
  onClose: () => void;
  onConfirm: () => void;
}): JSX.Element => (
  <Box sx={{ display: "flex", justifyContent: "end", paddingTop: "1rem", gap: "1rem" }}>
    <VrsButton buttonType="secondary" onClick={onClose}>
      Close without saving
    </VrsButton>
    <VrsButton buttonType="primary" onClick={onConfirm}>
      Save and Close
    </VrsButton>
  </Box>
);
