import { createAsyncThunk, createEntityAdapter, createSlice, EntityState } from "@reduxjs/toolkit";
import { CareTeamMemberInfo, SupportTeamMember } from "@veris-health/user-ms/lib/v1";
import { Status } from "../interfaces";
import { RootState } from "../../../store";
import { getCareTeamForPatient, getSupportTeamForPatient } from "./api/careTeamApi";

export interface PatientCareTeam {
  careTeam: CareTeamMemberInfo[];
  supportTeam: SupportTeamMember[];
}

interface CareTeamState {
  status: Status;
  careTeams: EntityState<PatientCareTeam>;
}

const careTeamAdapter = createEntityAdapter<PatientCareTeam>();

const initialState: CareTeamState = {
  status: "idle",
  careTeams: careTeamAdapter.getInitialState(),
};

export const getPatientCareAndSupportTeamsAsync = createAsyncThunk(
  "careTeam/getPatientCareTeam",
  async (patientId: number) => {
    const careTeamMembers = await getCareTeamForPatient(patientId);
    const supportTeamMembers = await getSupportTeamForPatient(patientId);
    return { id: patientId, careTeam: careTeamMembers, supportTeam: supportTeamMembers };
  },
);

const careTeamSlice = createSlice({
  name: "Care Team",
  initialState,
  reducers: {
    clearCareTeamError: (state) => {
      state.status = "idle";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPatientCareAndSupportTeamsAsync.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(getPatientCareAndSupportTeamsAsync.fulfilled, (state, { payload }) => {
      state.status = "idle";
      careTeamAdapter.upsertOne(state.careTeams, payload);
    });
    builder.addCase(getPatientCareAndSupportTeamsAsync.rejected, (state) => {
      state.status = "failed";
    });
  },
});

export const { clearCareTeamError } = careTeamSlice.actions;

export const selectCareTeams = (rootState: RootState, id?: number): PatientCareTeam | undefined => {
  if (id) {
    return careTeamAdapter
      .getSelectors<RootState>((state) => state.careTeam.careTeams)
      .selectById(rootState, id);
  }
  return undefined;
};

export const selectCareTeamStatus = ({ careTeam }: RootState): Status => careTeam.status;

export default careTeamSlice.reducer;
