import { WellbeingClassification } from "@veris-health/virtual-doc-ms/lib/v1";
import React from "react";
import { TagsTypes } from "../../../constants";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { Routes } from "../../../routes-config";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { selectSortedAndFilteredVrsPatients } from "../patientsSlice";
import { VrsPatientInfo } from "../../shared/interfaces";

export const filteringAction = {
  [`${Routes.QOL_WORSEN}`]: WellbeingClassification.Worsen,
  [`${Routes.QOL_NO_CHANGE}`]: WellbeingClassification.NoChange,
  [`${Routes.QOL_IMPROVE}`]: WellbeingClassification.Improve,
  [`${Routes.INPATIENTS}`]: TagsTypes.Inpatients,
  [`${Routes.NEW_PATIENTS}`]: TagsTypes.NewPatients,
  [`${Routes.SEVERE_SYMPTOM_PATIENTS}`]: TagsTypes.SevereSymptom,
  [`${Routes.SYMPTOM_REPORT}`]: TagsTypes.NewSymptom,
};

const filterByQol = (patients: VrsPatientInfo[], qolTrend: string): VrsPatientInfo[] => {
  return patients.filter(
    ({ qualityOfLife }) => qualityOfLife?.wellbeing_classification === qolTrend,
  );
};

const filterByTag = (patients: VrsPatientInfo[], tagFilters: string[]): VrsPatientInfo[] => {
  return patients.filter(
    ({ tags }) =>
      tagFilters?.length === 0 ||
      tagFilters.every((filter) => tags?.some((tag) => tag.label === filter)),
  );
};

export function usePatientsFilter(pathname: string, tagFilters: string[]): VrsPatientInfo[] {
  const dispatch = useAppDispatch();
  const patients = useAppSelector(selectSortedAndFilteredVrsPatients);

  const filteredPatients = React.useMemo(() => {
    const action = filteringAction[`${pathname}`];

    if (action && pathname.includes("/qol")) {
      const filteredPatientsByQol = filterByQol(patients, action);
      if (tagFilters.length) return filterByTag(filteredPatientsByQol, tagFilters);
      return filteredPatientsByQol;
    }

    return filterByTag(patients, tagFilters);
  }, [dispatch, pathname, patients, tagFilters]);

  return filteredPatients;
}
