import { Box, Theme, useMediaQuery } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";
import { IconName, VrsIcon, VrsButton, verisColors } from "@veris-health/web-core";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import { togglePreviewBar } from "../../../shared/slices/uiSlice";
import { PatientDetailsMeasurementContainer } from "../../../PatientDetailsMeasurements/PatientDetailsMeasurementContainer";
import { VrsPatientInfoCard } from "../../../../ui/components/VrsPatientInfoCard";
import { replaceRouteParam, Routes } from "../../../../routes-config";
import { VrsPatientInfo } from "../../../shared/interfaces";
import { useBilling } from "../../../../hooks/useBilling";

interface SidebarPreviewProps {
  patient: VrsPatientInfo;
}

const SidebarPreview = ({ patient }: SidebarPreviewProps): JSX.Element => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const isMediumScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("xl"));

  useBilling(patient.id);

  const handleFullDetailsClick = () => {
    dispatch(togglePreviewBar(false));
    history.push({
      pathname: replaceRouteParam("PATIENT_DETAILS", ":userId", String(patient?.id)),
      state: Routes.PATIENTS,
    });
  };

  return (
    <Box padding={(theme) => theme.spacing(1.5)}>
      {patient && (
        <VrsPatientInfoCard
          patientInfo={patient}
          backgroundColor={verisColors.neutrals.white}
          styles={{ diagnosis: { maxWidth: "150px" } }}
        />
      )}
      <PatientDetailsMeasurementContainer isPreview patient={patient} />
      <Box display="flex" justifyContent="space-between" py={0.5}>
        <VrsButton
          buttonType="secondary"
          endIcon={
            <VrsIcon name={IconName.ArrowRight} stroke={verisColors.amethyst.normal} size={16} />
          }
          onClick={handleFullDetailsClick}
          sx={{
            marginRight: 1,
            paddingX: isMediumScreen ? 1.15 : "inherits",
            minWidth: "6.438rem",
          }}
        >
          Full Details
        </VrsButton>
        <VrsButton
          buttonType="secondary"
          endIcon={
            <VrsIcon name={IconName.ArrowRight} stroke={verisColors.amethyst.normal} size={16} />
          }
          sx={{ paddingX: isMediumScreen ? 1.29 : "inherits", minWidth: "8.125rem" }}
          onClick={() => {
            history.push(replaceRouteParam("PATIENT_DETAILS", ":userId", String(patient?.id)));
            history.push({
              pathname: replaceRouteParam("PATIENT_DETAILS", ":userId", String(patient?.id)),
              state: { view: 1 },
            });
          }}
        >
          Treatment Plan
        </VrsButton>
      </Box>
    </Box>
  );
};

export default SidebarPreview;
