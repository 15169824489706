import React from "react";
import { Box, Button as MuiButton, buttonBaseClasses, styled, Typography } from "@mui/material";
import { IconName, VrsIcon, VrsTooltip, verisColors } from "@veris-health/web-core";
import CustomSymptomTooltip from "../CustomSymptomTooltip";
import { ExpandedSymptomEvent, SymptomStatusEnum } from "../../../measurementSlice";
import { SymptomCellProps } from "../SymptomCell/SymptomCell";
import { getCursorCssStyle } from "../../../../../utils/symptoms";

export interface SymptomDailyButtonProps {
  entry: ExpandedSymptomEvent;
  onSymptomClickHandler?: () => void;
}

const VrsStyledButtonSymptom = styled(MuiButton, {
  shouldForwardProp: (prop: string) => prop !== "symptom",
})<SymptomDailyButtonProps>(({ theme, entry }) => ({
  [`&.${buttonBaseClasses.root}`]: {
    ...theme.typography.button,
    height: "30px",
    textTransform: "none",
    boxShadow: "none",
    textDecoration: entry.calculatedStatus === "no_update" ? "none" : "underline",
    margin: theme.spacing(0, 2),
    paddingTop: theme.spacing(0.1),
    paddingBottom: theme.spacing(0),
    color: verisColors.neutrals["grey-5"],
    backgroundColor: entry.style?.fillColor,
    "&:hover": {
      textDecoration: entry.calculatedStatus === "no_update" ? "none" : "underline",
      backgroundColor: entry.style?.fillColor,
    },
  },
}));

export function SymptomDailyButton({
  entry,
  onSymptomClickHandler,
  isPreview,
  isClickedSymptom,
}: SymptomCellProps): JSX.Element {
  const disabledStatuses: SymptomStatusEnum[] = ["empty", "no_update", "incomplete_no_update"];
  return (
    <VrsStyledButtonSymptom
      entry={entry}
      fullWidth
      onClick={onSymptomClickHandler}
      sx={{ cursor: getCursorCssStyle(isPreview, entry.calculatedStatus) }}
      disableRipple={disabledStatuses.includes(entry.calculatedStatus)}
    >
      <VrsTooltip
        title={<CustomSymptomTooltip symptom={entry} isPreview={isPreview} />}
        bcgcolor={verisColors.neutrals.black}
        placement="bottom"
      >
        {isClickedSymptom ? (
          <Box display="flex" pr={1.5}>
            <VrsIcon name={IconName.ArrowLeft} stroke={verisColors.neutrals["grey-5"]} size={24} />
            <Typography variant="body" mr={1} pt={0.4}>
              Hide
            </Typography>
          </Box>
        ) : (
          <Box display="flex" pl={1.5}>
            {disabledStatuses.includes(entry.calculatedStatus) ? (
              <Typography variant="body" mr={1} pt={0.4} color={verisColors.neutrals["grey-4"]}>
                No details available
              </Typography>
            ) : (
              <>
                <Typography variant="body" mr={1} pt={0.4}>
                  See details
                </Typography>
                <VrsIcon name={IconName.ArrowRight} stroke={verisColors.neutrals["grey-5"]} />
              </>
            )}
          </Box>
        )}
      </VrsTooltip>
    </VrsStyledButtonSymptom>
  );
}
