import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { CircularProgress } from "@mui/material";
import { ThreadParticipantInfo } from "@veris-health/communication-ms/lib/v1";
import { VrsErrorMsg } from "@veris-health/web-core";
import {
  fetchAttachmentsForThreadAsync,
  getChatThreadMessagesAsync,
  selectAttachmentsStatus,
  selectMessagesStatus,
  selectThreadAttachments,
  resetMessages,
  getChatHistoryThreadMessagesAsync,
  selectChatHistoryMessagesStatus,
} from "../store";

import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { AttachmentsContainer } from "./AttachmentsContainer";
import { VrsChatAttachmentToggle } from "../components/VrsChatAttachmentToggle";
import { ThreadMessagesContainer } from "./ThreadMessagesContainer";

export interface ThreadContainerProps {
  currentThreadId: string;
  endpoint?: string;
  token?: string;
  myAzureChatUserId: string;
  myChatDisplayName: string;
  participants: ThreadParticipantInfo[];
  isPrivateThread: boolean;
  showInteractions: boolean;
  onChatHistoryToggle: () => void;
  activeChatHistory: boolean;
  chatHistoryPatientId?: number;
}

export const ThreadContainer = ({
  myAzureChatUserId,
  myChatDisplayName,
  currentThreadId,
  endpoint,
  token,
  participants,
  isPrivateThread,
  showInteractions,
  onChatHistoryToggle,
  activeChatHistory,
  chatHistoryPatientId,
}: ThreadContainerProps): JSX.Element => {
  const [showAttachmentsTab, setShowAttachmentsTab] = useState<boolean>(false);
  const messagesStatus = useAppSelector(
    chatHistoryPatientId ? selectChatHistoryMessagesStatus : selectMessagesStatus,
  );
  const threadAttachments = useAppSelector((state) =>
    selectThreadAttachments(state, currentThreadId),
  );
  const attachmentsStatus = useAppSelector(selectAttachmentsStatus);

  const getParticipantsMap = (keyField: keyof ThreadParticipantInfo) => {
    const participantsMap: Record<string, ThreadParticipantInfo> = {};
    participants.forEach((participant) => {
      const field = participant[keyField];
      participantsMap[field as string] = participant;
    });
    return participantsMap;
  };

  const dispatch = useAppDispatch();

  const handleFetchMessages = () => {
    if (currentThreadId && token && endpoint) {
      dispatch(getChatThreadMessagesAsync({ threadId: currentThreadId, endpoint, token }));
    } else if (chatHistoryPatientId)
      dispatch(
        getChatHistoryThreadMessagesAsync({
          userId: chatHistoryPatientId,
          threadId: currentThreadId,
          items: 10,
        }),
      );
  };

  useEffect(() => {
    handleFetchMessages();
  }, [dispatch, currentThreadId, endpoint, token]);

  useEffect(() => {
    return () => {
      dispatch(resetMessages(currentThreadId));
    };
  }, [currentThreadId]);

  return (
    <>
      {(messagesStatus !== "failed" || attachmentsStatus !== "failed") && (
        <VrsChatAttachmentToggle
          showAttachmentsTab={showAttachmentsTab}
          setShowAttachmentsTab={(value) => setShowAttachmentsTab(value)}
          threadAttachmentsLength={threadAttachments?.total || 0}
          onChatHistoryToggle={onChatHistoryToggle}
          activeChatHistory={activeChatHistory}
        />
      )}
      {messagesStatus === "loading" && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            height: "35vh",
            backgroundColor: (theme) =>
              chatHistoryPatientId ? theme.veris.colors.amethyst.touch : "unset",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {!showAttachmentsTab && messagesStatus === "failed" && (
        <Box display="flex" justifyContent="center">
          <VrsErrorMsg size="large" onClick={handleFetchMessages} />
        </Box>
      )}
      {!showAttachmentsTab && messagesStatus !== "failed" && (
        <ThreadMessagesContainer
          messagesStatus={messagesStatus}
          currentThreadId={currentThreadId}
          endpoint={endpoint}
          token={token}
          myAzureChatUserId={myAzureChatUserId}
          participantsMap={getParticipantsMap("communication_user_id")}
          isPrivateThread={isPrivateThread}
          showInteractions={showInteractions}
          chatHistoryPatientId={chatHistoryPatientId}
        />
      )}
      {showAttachmentsTab && attachmentsStatus === "failed" && (
        <Box display="flex" justifyContent="center">
          <VrsErrorMsg
            size="large"
            onClick={() => dispatch(fetchAttachmentsForThreadAsync({ threadId: currentThreadId }))}
          />
        </Box>
      )}
      {showAttachmentsTab && (
        <AttachmentsContainer
          total={threadAttachments?.total || 0}
          attachmentsStatus={attachmentsStatus}
          currentThreadId={currentThreadId}
          threadAttachments={threadAttachments?.attachments}
          myChatDisplayName={myChatDisplayName}
          participants={getParticipantsMap("user_id")}
          isPrivateThread={isPrivateThread}
        />
      )}
    </>
  );
};
