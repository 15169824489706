import {
  Reporter,
  ListNotificationsResponse,
  MarkAsRead,
  AppApiV1ModelsNotificationModelsStatusResponse as NotificationStatusChange,
  NotificationView,
} from "@veris-health/communication-ms/lib/v1";
import { QuestionsAnswersModel, ReportItem } from "@veris-health/virtual-doc-ms/lib/v1";
import {
  ApplicationType,
  NotificationPayloadView,
  Platform,
} from "@veris-health/communication-ms/lib/v1/api";
import { symptomsApiV1, notificationsApiV1 } from "../../../api";

export const fetchReportAnswers = async (
  userId: string,
  reportId: string,
): Promise<QuestionsAnswersModel> => {
  const response = await symptomsApiV1.getReportAnswers(+userId, +reportId);
  return response.data;
};

export const fetchAllNotifications = async (
  userId: number,
  read?: boolean,
  reportedBy?: Reporter,
  patientId?: number,
  offset?: number,
  limit?: number,
): Promise<ListNotificationsResponse> => {
  const response = await notificationsApiV1.listNotificationsForRecipient(
    userId,
    read,
    reportedBy,
    undefined,
    patientId,
    limit ?? 10,
    offset,
  );
  return response.data;
};

export const markNotificationAsRead = async (
  userId: number,
  markAsRead: MarkAsRead,
): Promise<NotificationStatusChange> => {
  const response = await notificationsApiV1.markAsRead(userId, markAsRead);
  return response.data;
};

export const registerFirebaseToken = async (
  userId: number,
  firebaseToken: string,
): Promise<NotificationStatusChange> => {
  const response = await notificationsApiV1.registerNotificationsToken(userId, {
    app_type: ApplicationType.WebStaff,
    platform: Platform.Web,
    token: firebaseToken,
  });
  return response.data;
};

export const getNotificationDetails = async ({
  userId,
  vrsNotificationId,
}: {
  userId?: string;
  vrsNotificationId?: string;
}): Promise<NotificationView> => {
  const { data: responseData } = await notificationsApiV1.getNotificationDetails(
    Number(userId),
    Number(vrsNotificationId),
  );
  const { payload, type } = responseData;
  const { data, text, title } = payload;

  return {
    ...responseData,
    payload: {
      title,
      text,
      data: {
        ...data,
        type,
      } as Extract<"data", NotificationPayloadView>,
    },
  };
};

export const loadSymptomsReportedHistoryAsync = async (payload: {
  reporterId: string;
}): Promise<ReportItem[]> => {
  const { reporterId } = payload;
  const reportHistoryResponse = await symptomsApiV1.getReports(+reporterId);
  return reportHistoryResponse.data;
};

export const unregisterFirebaseToken = async (userId: number): Promise<unknown> => {
  const response = await notificationsApiV1.unregisterToken(userId, {
    app_type: ApplicationType.WebStaff,
    platform: Platform.Web,
  });

  return response.data;
};
