import { Box, Grid, styled } from "@mui/material";
import React from "react";
import { verisColors } from "@veris-health/web-core";
import { SymptomEventsProps } from "./SymptomEvents";
import { SymptomDailyButton } from "./SymptomsDailyButton";
import { SymptomCell } from "./SymptomCell";

export interface StyledSymptomsDailyViewProps {
  isPreview: boolean;
  isFirst?: boolean;
  isLast?: boolean;
  summary?: boolean;
}

const StyledSymptomDiv = styled("div", {
  shouldForwardProp: (prop: string) =>
    prop !== "isPreview" && prop !== "isFirst" && prop !== "isLast",
})<StyledSymptomsDailyViewProps>(({ theme, isPreview, isFirst, isLast }) => ({
  height: isPreview ? 50 : 70,
  borderTop: isPreview
    ? "none"
    : `${isFirst ? "2px" : "1px"} solid ${verisColors.neutrals["grey-2"]}`,
  borderBottom: isPreview
    ? "none"
    : `${isLast ? "2px" : "1px"} solid ${verisColors.neutrals["grey-2"]}`,
  padding: isPreview ? 0 : theme.spacing(0.4),
}));

const StyledCell = styled(Box, {
  shouldForwardProp: (prop: string) => prop !== "isPreview",
})<StyledSymptomsDailyViewProps>(({ isPreview }) => ({
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: isPreview ? "flex-end" : "center",
  alignItems: "center",
}));

const SymptomsDailyView = ({
  data,
  onSymptomClickHandler,
  clickedSymptom,
  isPreview,
  view,
  isFirst,
  isLast,
  summary,
}: SymptomEventsProps): JSX.Element => {
  const isDailyView = view === "daily";
  return (
    <Grid item container width="99%" display="flex" justifyContent="center" xs={12}>
      {data.map((entry, index) => (
        <Grid item xs={isDailyView ? 12 : 4} key={entry.key}>
          <StyledSymptomDiv
            isPreview={isPreview}
            style={{
              width: isDailyView ? "99%" : "",
            }}
            isFirst={!!isFirst}
            isLast={!!isLast}
          >
            <StyledCell
              isPreview={isPreview}
              style={{
                backgroundColor:
                  !isPreview &&
                  clickedSymptom &&
                  entry?.timestamp === clickedSymptom?.timestamp &&
                  entry.timestamp !== ""
                    ? verisColors.amethyst.light
                    : "",
              }}
            >
              {isDailyView && !isPreview && !summary && (
                <SymptomDailyButton
                  entry={entry}
                  isPreview={isPreview}
                  isClickedSymptom={
                    clickedSymptom &&
                    clickedSymptom?.report_id + index + clickedSymptom?.symptom_name ===
                      entry.report_id + index + entry.symptom_name
                  }
                  onSymptomClickHandler={() => {
                    if (entry.report_id && entry.timestamp !== "") {
                      onSymptomClickHandler(entry);
                    }
                  }}
                />
              )}
              <SymptomCell
                view={view}
                entry={entry}
                isPreview={isPreview}
                onSymptomClickHandler={() => {
                  if (entry.report_id && entry.timestamp !== "") onSymptomClickHandler(entry);
                }}
              />
            </StyledCell>
          </StyledSymptomDiv>
        </Grid>
      ))}
    </Grid>
  );
};

export default SymptomsDailyView;
