import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  useTheme,
} from "@mui/material";
import { VrsIconButton, IconName, dateFormats } from "@veris-health/web-core";
import { RPMManualEntry } from "@veris-health/communication-ms/lib/v1";
import { MedStaffProfileItem } from "@veris-health/user-ms/lib/v1";
import React from "react";
import dayjs from "dayjs";
import { ActivityItem } from "./ActivityItem";
import { useAppSelector } from "../../../../../hooks/useAppSelector";
import { selectUserId } from "../../../../shared/slices/authSlice";

interface ReviewRpmTableProps {
  onDeleteEntry: (report: RPMManualEntry) => void;
  systemReport: { amount: number; dateOccurred: string; dateReported: string };
  manualReports: RPMManualEntry[];
  staffLookup: { [id: number]: MedStaffProfileItem };
}

export const constructDisplayName = (item: MedStaffProfileItem): string => {
  return `${item?.profession || ""} ${item?.first_name || ""} ${item?.last_name || ""}`;
};

export const ReviewRpmTable = ({
  onDeleteEntry,
  systemReport,
  manualReports,
  staffLookup,
}: ReviewRpmTableProps): JSX.Element => {
  const currentUserId = useAppSelector(selectUserId);
  const theme = useTheme();

  return (
    <TableContainer
      component={Paper}
      sx={{ border: `1px solid ${theme.veris.colors.neutrals["grey-light"]}`, maxHeight: "450px" }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                color: theme.veris.colors.neutrals["grey-mid"],
                paddingY: theme.spacing(1),
              }}
            >
              CLINICAL TIME
            </TableCell>
            <TableCell
              sx={{
                color: theme.veris.colors.neutrals["grey-mid"],
                paddingY: theme.spacing(1),
              }}
            >
              ACTIVITY
            </TableCell>
            <TableCell
              sx={{
                color: theme.veris.colors.neutrals["grey-mid"],
                paddingY: theme.spacing(1),
              }}
            >
              DATE OCCURRED
            </TableCell>
            <TableCell
              sx={{
                color: theme.veris.colors.neutrals["grey-mid"],
                paddingY: theme.spacing(1),
              }}
            >
              DATE ENTERED
            </TableCell>
            <TableCell
              colSpan={2}
              sx={{
                color: theme.veris.colors.neutrals["grey-mid"],
                paddingY: theme.spacing(1),
              }}
            >
              ENTERED BY
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>{systemReport.amount} min</TableCell>
            <TableCell>
              <ActivityItem activities={["Summarized System activity"]} />
            </TableCell>
            <TableCell sx={{ color: theme.veris.colors.neutrals["grey-mid"] }}>
              {dayjs(systemReport.dateOccurred).format(dateFormats["MM/YY"])}
            </TableCell>
            <TableCell sx={{ color: theme.veris.colors.neutrals["grey-mid"] }}>
              {dayjs(systemReport.dateReported).format(dateFormats["MM/YY"])}
            </TableCell>
            <TableCell sx={{ color: theme.veris.colors.neutrals["grey-mid"] }}>
              System automated
            </TableCell>
            <TableCell />
          </TableRow>
          {manualReports.map((manualReport) => (
            <TableRow key={manualReport.id}>
              <TableCell>{manualReport.minutes} min</TableCell>
              <TableCell>
                <ActivityItem activities={manualReport.activities} />
              </TableCell>
              <TableCell>
                {dayjs(manualReport.date_occurred).format(dateFormats["ddd MMM D, YYYY"])}
              </TableCell>
              <TableCell>
                {dayjs(manualReport.date_entered).format(dateFormats["ddd MMM D, YYYY"])}
              </TableCell>
              <TableCell>{constructDisplayName(staffLookup[manualReport.med_staff_id])}</TableCell>
              <TableCell>
                <VrsIconButton
                  iconProps={{ name: IconName.Trashcan }}
                  sx={{
                    path: {
                      stroke:
                        currentUserId && manualReport.med_staff_id !== +currentUserId
                          ? theme.veris.colors.neutrals["grey-2"]
                          : theme.veris.colors.neutrals["grey-3"],
                    },
                  }}
                  onClick={() => onDeleteEntry(manualReport)}
                  disabled={currentUserId ? manualReport.med_staff_id !== +currentUserId : false}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
