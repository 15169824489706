import React from "react";
import { Typography, Box } from "@mui/material";
import { CallEndReason } from "@azure/communication-calling";

export interface VideoCallEndProps {
  callEndReason?: CallEndReason;
}

export function VideoCallEnd({ callEndReason }: VideoCallEndProps): JSX.Element {
  let message = "Your call has ended";

  if (callEndReason?.code === 603 || callEndReason?.code === 480 || callEndReason?.code === 487) {
    message = "No answer";
  }

  return (
    <Box p={2}>
      <Typography variant="h1">{message}</Typography>
    </Box>
  );
}
