import {
  Box,
  Typography,
  Grid,
  Tabs,
  Tab,
  styled,
  Stack,
  tabsClasses,
  tabClasses,
  CircularProgress,
} from "@mui/material";
import { ReportItem } from "@veris-health/virtual-doc-ms/lib/v1";
import React, { useEffect } from "react";
import { VrsErrorMsg, dateFormats } from "@veris-health/web-core";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { VrsPastAppointmentsOrSymptoms } from "../../../../ui/components/VrsPastAppointments";
import { Status } from "../../../shared/interfaces";
import {
  selectFilteredSymptomsReportHistoryActive,
  selectFilteredSymptomsReportHistoryResolved,
  selectSymptomsReportHistoryFilter,
  setSymptomsReportHistoryFilter,
  SymptomsReportHistoryFilter,
  SymptomsReportHistoryFilterEnum,
} from "../../notificationsSlice";
import { useNotification } from "../../hooks/useNotification";
import { utcToLocal } from "../../../../utils/date";

export interface LastSymptomsReportedProps {
  handleSeeAnswers: (reportId: string) => void;
  loadReportedSymptoms: () => void;
  symptomsReportHistoryStatus: Status;
}

interface StyledTabsProps {
  children?: React.ReactNode;
  value: string;
  onChange: (event: React.SyntheticEvent, newValue: SymptomsReportHistoryFilterEnum) => void;
}

const VrsTabs = styled((props: StyledTabsProps) => (
  <Tabs {...props} TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }} />
))({
  marginBottom: "12px",
  [`&.${tabsClasses.root}`]: {
    minHeight: "1.25rem",
  },
  [`&.${tabsClasses.indicator}`]: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
});

const VrsTab = styled(Tab)(({ theme }) => ({
  [`&.${tabClasses.root}`]: {
    minHeight: "1.125rem",
    padding: theme.spacing(0, 1.45),
    lineHeight: "1.125rem",
    minWidth: "fit-content",
  },
}));

export const LastSymptomsReported = ({
  handleSeeAnswers,
  loadReportedSymptoms,
  symptomsReportHistoryStatus,
}: LastSymptomsReportedProps): JSX.Element => {
  const activeNotification = useNotification();
  const dispatch = useAppDispatch();
  const tabFilterValue = useAppSelector(selectSymptomsReportHistoryFilter);
  const filteredSymptomsReportHistoryActive = useAppSelector(
    selectFilteredSymptomsReportHistoryActive(activeNotification),
  );
  const filteredSymptomsReportHistorySolved = useAppSelector(
    selectFilteredSymptomsReportHistoryResolved,
  );

  useEffect(() => {
    if (activeNotification) {
      loadReportedSymptoms();
    }
  }, [activeNotification]);

  return (
    <Box paddingX={1.7} marginBottom={1}>
      <Grid display="flex" justifyContent="space-between" alignItems="baseline">
        <Typography marginBottom={1} variant="h6">
          Last Symptoms Reported
        </Typography>
      </Grid>
      <Box mt={1}>
        {symptomsReportHistoryStatus === "loading" && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{
              paddingBlock: "2rem",
            }}
          >
            <CircularProgress />
          </Box>
        )}
        {symptomsReportHistoryStatus === "failed" && (
          <Box
            display="flex"
            sx={{
              justifyContent: "center",
              paddingBlock: "6.25rem",
              backgroundColor: (theme) => theme.veris.colors.neutrals.white,
            }}
          >
            <VrsErrorMsg
              size="small"
              onClick={() => {
                loadReportedSymptoms();
              }}
            />
          </Box>
        )}
        {symptomsReportHistoryStatus === "idle" && (
          <>
            <VrsTabs
              value={tabFilterValue}
              onChange={(
                event: React.SyntheticEvent,
                newFilterValue: SymptomsReportHistoryFilterEnum,
              ) => dispatch(setSymptomsReportHistoryFilter(newFilterValue))}
            >
              {SymptomsReportHistoryFilter.map((symptomFilter) => (
                <VrsTab
                  value={symptomFilter.filter}
                  label={symptomFilter.name}
                  key={symptomFilter.filter}
                />
              ))}
            </VrsTabs>
            <Stack
              px={1.45}
              border={(theme) => `1px solid ${theme.veris.colors.neutrals["grey-light"]}`}
              borderRadius={2}
              maxHeight="248px"
              overflow="scroll"
            >
              {tabFilterValue === SymptomsReportHistoryFilterEnum.Active &&
                filteredSymptomsReportHistoryActive &&
                filteredSymptomsReportHistoryActive.map((symptomReport: ReportItem) => (
                  <VrsPastAppointmentsOrSymptoms
                    key={symptomReport.id}
                    name={symptomReport.symptom.value}
                    date={utcToLocal(symptomReport.last_report).format(dateFormats["MM/DD"])}
                    notes={symptomReport.severity?.value || ""}
                    onClick={() => handleSeeAnswers(symptomReport.id.toString())}
                    actionText="See answers"
                  />
                ))}
              {tabFilterValue === SymptomsReportHistoryFilterEnum.Active &&
                filteredSymptomsReportHistoryActive.length === 0 && (
                  <Typography
                    variant="subtitle2"
                    color={(theme) => theme.veris.colors.neutrals["grey-5"]}
                    my={3}
                  >
                    No active symptoms history reported
                  </Typography>
                )}
              {tabFilterValue === SymptomsReportHistoryFilterEnum.Resolved &&
                filteredSymptomsReportHistorySolved &&
                filteredSymptomsReportHistorySolved.map((symptomReport: ReportItem) => (
                  <VrsPastAppointmentsOrSymptoms
                    key={symptomReport.id}
                    name={symptomReport.symptom.value}
                    date={utcToLocal(symptomReport.last_report).format(dateFormats["MM/DD"])}
                    notes={symptomReport.severity?.value || ""}
                    onClick={() => handleSeeAnswers(symptomReport.id.toString())}
                    actionText="See answers"
                  />
                ))}
              {tabFilterValue === SymptomsReportHistoryFilterEnum.Resolved &&
                filteredSymptomsReportHistorySolved.length === 0 && (
                  <Typography
                    variant="subtitle2"
                    color={(theme) => theme.veris.colors.neutrals["grey-5"]}
                    my={3}
                  >
                    No resolved symptoms history reported
                  </Typography>
                )}
            </Stack>
          </>
        )}
      </Box>
    </Box>
  );
};
