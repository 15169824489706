import {
  GetSpokenLanguage,
  MedProfessionItem,
  SetMedStaffProfile,
  SpokenLanguage,
  UserLanguages,
} from "@veris-health/user-ms/lib/v1";
import { profileInfoApiV1, demographicsApiV1, changeCredentialsStaffApiV1 } from "../../../api";
import { VrsMedStaffProfileModel } from "../../shared/interfaces";
import { stripHtml } from "../../../api/stripHtml";

export const fetchDoctorProfileData = async (id: number): Promise<VrsMedStaffProfileModel> => {
  const res = await profileInfoApiV1.getProfileInfo(id);
  const data = stripHtml(res.data) as VrsMedStaffProfileModel;

  return {
    ...data,
    fullName: `${data.first_name} ${data.last_name}`,
    phoneNumber: data.phone_number,
    emergencyPhoneNumber: data.emergency_phone_number,
  };
};

export const fetchDoctorSpokenLanguages = async (id: number): Promise<SpokenLanguage[]> => {
  const response = await demographicsApiV1.getUserLanguages(id);
  return response.data;
};

export const setDoctorsSpokenLanguages = async (id: number, data: UserLanguages): Promise<void> => {
  await demographicsApiV1.setUserSpokenLanguages(id, data);
};
export const updateDoctorsProfileData = async (
  id: number,
  data: SetMedStaffProfile,
): Promise<SetMedStaffProfile> => {
  const response = await profileInfoApiV1.setProfileInfo(id, data);
  return response.data;
};

export const getProfessions = async (): Promise<MedProfessionItem[]> => {
  const response = await profileInfoApiV1.getMedProfessions();
  return response.data;
};

export const getLanguages = async (): Promise<GetSpokenLanguage[]> => {
  const response = await demographicsApiV1.getLanguages();
  return response.data;
};

export const editPhoneNumber = async (userId: number, phoneNumber: string): Promise<void> => {
  const response = await changeCredentialsStaffApiV1.sendStaffChangePhoneNumberOtp(userId, {
    phone_number: phoneNumber,
  });
  return response.data;
};

export const confirmEditedPhoneNumber = async (
  userId: number,
  phoneNumber: string,
  otpCode: string,
): Promise<void> => {
  const response = await changeCredentialsStaffApiV1.confirmStaffChangePhoneNumber(userId, {
    phone_number: phoneNumber,
    otp: otpCode,
  });
  return response.data;
};
