import { SensorEnum } from "@veris-health/med-data-ms/lib/v1";
import { IconName, verisColors } from "@veris-health/web-core";
import { MeasurementUnits } from "../../../../constants";

export interface CombinedSensorLabelProps {
  title: string;
  iconName?: IconName;
  isFirst?: boolean;
  isLast?: boolean;
  isPreview?: boolean;
  id: SensorEnum;
  strokeColor?: string;
  unit?: string;
}

export const atHomeDeviceSensors: CombinedSensorLabelProps[] = [
  {
    title: "Temperature",
    iconName: IconName.Temperature,
    id: SensorEnum.Temp,
    unit: MeasurementUnits[SensorEnum.Temp],
  },
  {
    title: "Heart Rate",
    iconName: IconName.BPM,
    id: SensorEnum.Bpm,
    unit: MeasurementUnits[SensorEnum.Bpm],
  },
  {
    title: "Oxygen Saturation",
    iconName: IconName.Oximeter,
    id: SensorEnum.Oximeter,
    unit: MeasurementUnits[SensorEnum.Oximeter],
  },
  {
    title: "Blood Pressure",
    iconName: IconName.BloodPressure,
    id: SensorEnum.BloodPressure,
    unit: MeasurementUnits[SensorEnum.BloodPressure],
  },
  {
    title: "Weight",
    iconName: IconName.Weight,
    strokeColor: verisColors.neutrals.black,
    id: SensorEnum.Weight,
    unit: MeasurementUnits[SensorEnum.Weight],
  },
  {
    title: "Activity",
    iconName: IconName.Motion,
    id: SensorEnum.Motion,
    unit: MeasurementUnits[SensorEnum.Motion],
  },
];

export const implantSensors: CombinedSensorLabelProps[] = [
  {
    title: "Temperature",
    iconName: IconName.Temperature,
    id: SensorEnum.Temp,
    unit: MeasurementUnits[SensorEnum.Temp],
  },
  {
    title: "Heart Rate",
    iconName: IconName.BPM,
    id: SensorEnum.Bpm,
    unit: MeasurementUnits[SensorEnum.Bpm],
  },
  {
    title: "Respiratory Rate",
    iconName: IconName.RPM,
    id: SensorEnum.Rpm,
    unit: MeasurementUnits[SensorEnum.Rpm],
  },
  {
    title: "Activity",
    iconName: IconName.Motion,
    id: SensorEnum.Motion,
    unit: MeasurementUnits[SensorEnum.Motion],
  },
  {
    title: "ECG",
    iconName: IconName.RPM,
    strokeColor: verisColors.neutrals.black,
    id: SensorEnum.Ecg,
    unit: MeasurementUnits[SensorEnum.Ecg],
  },
];

export const gfAhkSensors: CombinedSensorLabelProps[] = [
  {
    title: "Activity",
    iconName: IconName.Motion,
    id: SensorEnum.Motion,
    unit: MeasurementUnits[SensorEnum.Motion],
  },
];

export const SensorsByDataSource = {
  mio_hub: atHomeDeviceSensors,
  implant: implantSensors,
  gf_ahk: gfAhkSensors,
};

export interface MioSensorLabelProps {
  title: string;
  iconName?: IconName;
  rangeSubtitle: string;
  isFirst?: boolean;
  isLast?: boolean;
  isPreview?: boolean;
  id: SensorEnum;
  strokeColor?: string;
  unit?: string;
}

export const mioSensors: MioSensorLabelProps[] = [
  {
    title: "Temperature",
    iconName: IconName.Temperature,
    rangeSubtitle: "avg.",
    isFirst: true,
    id: SensorEnum.Temp,
    unit: MeasurementUnits[SensorEnum.Temp],
  },
  {
    title: "Heart Rate",
    iconName: IconName.BPM,
    rangeSubtitle: "avg. bpm",
    id: SensorEnum.Bpm,
    unit: MeasurementUnits[SensorEnum.Bpm],
  },
  {
    title: "Oxygen Saturation",
    iconName: IconName.Oximeter,
    rangeSubtitle: "avg. O2 saturation",
    id: SensorEnum.Oximeter,
    unit: MeasurementUnits[SensorEnum.Oximeter],
  },
  {
    title: "Blood Pressure",
    iconName: IconName.BloodPressure,
    rangeSubtitle: "avg. steps",
    id: SensorEnum.BloodPressure,
    unit: MeasurementUnits[SensorEnum.BloodPressure],
  },
  {
    title: "Weight",
    iconName: IconName.Weight,
    rangeSubtitle: "avg. steps",
    strokeColor: verisColors.neutrals.black,
    id: SensorEnum.Weight,
    unit: MeasurementUnits[SensorEnum.Weight],
  },
  {
    title: "Activity",
    iconName: IconName.Motion,
    rangeSubtitle: "avg. steps",
    isLast: true,
    id: SensorEnum.Motion,
    unit: MeasurementUnits[SensorEnum.Motion],
  },
];

export const MioSensorValueYAxisRange: Record<SensorEnum, { min: number; max: number | string }> = {
  temp: {
    min: 90,
    max: 105,
  },
  bpm: {
    min: 55,
    max: 105,
  },
  rpm: {
    min: 9,
    max: 16,
  },
  motion: {
    min: 0,
    max: "auto",
  },
  blood_pressure: {
    min: 50,
    max: 150,
  },
  weight: {
    min: 20,
    max: "auto",
  },
  oximeter: {
    min: 85,
    max: 101,
  },
  ecg: {
    min: 0,
    max: 0,
  },
  sleep: {
    min: 0,
    max: "auto",
  },
};

export const SensorValueYAxisRange: Record<
  SensorEnum,
  { min: number; max: number | string; offset?: number }
> = {
  temp: {
    min: 94,
    max: 104,
    offset: 2,
  },
  bpm: {
    min: 55,
    max: 105,
    offset: 5,
  },
  rpm: {
    min: 9,
    max: 16,
    offset: 1,
  },
  motion: {
    min: 0,
    max: "auto",
  },
  blood_pressure: {
    min: 50,
    max: 150,
    offset: 10,
  },
  weight: {
    min: 20,
    max: "auto",
  },
  oximeter: {
    min: 94,
    max: 101,
    offset: 2,
  },
  ecg: {
    min: 0,
    max: 0,
  },
  sleep: {
    min: 0,
    max: "auto",
  },
};
