import { Box, Typography } from "@mui/material";
import {
  AnswerItem,
  BodyPartAnswerItem,
  QuestionType,
  QuestionView,
  QuestionViewType,
  QuestionnaireResponse,
  QuestionnaireView,
  QuestionnaireViewLabel,
  ReportItem,
} from "@veris-health/virtual-doc-ms/lib/v1";
import React, { useState } from "react";
import { get } from "lodash";
import { fetchSymptomQuestionnare, submitSymptomQuestionnare } from "../api/symptomReportApi";
import {
  BodyPartsAnswer,
  ButtonAnswer,
  DateTimePicker,
  MultiChoiceAnswer,
  NumberInputAnswer,
  PainSlider,
  TextAnswer,
} from "./QuestionnaireAnswers";
import { SymptomReportReview } from "./SymptomReportReview";
import { useCurrentPatient } from "../../../../../hooks/useCurrentPatient";
import SnackbarUtils from "../../../../../utils/SnackbarUtils";
import { useAppDispatch } from "../../../../../hooks/useAppDispatch";
import { fetchUserSymptomsData } from "../../../../PatientDetailsMeasurements/measurementSlice";
import { getPatientByIdAsync } from "../../../../shared/slices/asyncThunks";

interface SymptomQuestionnaireProps {
  questionnaire: QuestionnaireView;
  closeModal: () => void;
  reportedSymptoms?: ReportItem[];
}

const getAnswerView = (
  questionView: QuestionViewType,
  handleClick: (answer: number | string | Array<string> | BodyPartAnswerItem[]) => void,
  options: QuestionnaireViewLabel[],
  type: QuestionType,
) => {
  switch (questionView) {
    case QuestionViewType.Button:
      return <ButtonAnswer handleClick={handleClick} />;

    case QuestionViewType.PainSlider:
      return <PainSlider handleChange={handleClick} />;

    case QuestionViewType.NumberInput:
      return <NumberInputAnswer handleChange={handleClick} />;

    case QuestionViewType.Text:
      return <TextAnswer handleChange={handleClick} />;

    case QuestionViewType.DatetimePicker:
      return <DateTimePicker handleChange={handleClick} />;

    case QuestionViewType.Circle:
      return (
        <MultiChoiceAnswer
          handleChange={handleClick}
          options={options || []}
          isMultiChoice={type === QuestionType.MultipleChoice}
        />
      );
    case QuestionViewType.AreaPicker:
      return <BodyPartsAnswer handleChange={handleClick} />;
    case QuestionViewType.MultipleChoiceList:
      return (
        <MultiChoiceAnswer
          handleChange={handleClick}
          options={options || []}
          isMultiChoice={type === QuestionType.MultipleChoice}
        />
      );
    default:
      return <TextAnswer handleChange={handleClick} />;
  }
};

const SymptomQuestionnaire = ({
  questionnaire,
  closeModal,
  reportedSymptoms,
}: SymptomQuestionnaireProps): JSX.Element => {
  const [answers, setAnswers] = useState<{ [key: string]: AnswerItem[] }>();
  const [symptom, setSymptom] = useState<string>(questionnaire.questions.symptom.label);
  const [allQuestions, setAllQuestions] = useState<{ [key: string]: QuestionnaireView }>({
    [symptom]: questionnaire,
  });
  const [first] = allQuestions[symptom].questions.questions;
  const [currentQuestion, setCurrentQuestion] = useState<QuestionView>(first);
  const { id, type, question, goto, flow, view, options } = currentQuestion;
  const [displayReportReview, setDisplayReportReview] = useState<boolean>(false);
  const currentPatient = useCurrentPatient();
  const dispatch = useAppDispatch();

  const handleSymptomSubmit = () => {
    Object.keys(allQuestions).forEach((symptomName) => {
      const response: QuestionnaireResponse = {
        questionnaire_id: allQuestions[symptomName].id,
        answers: (answers && answers[symptomName]) || [],
      };
      if (currentPatient)
        submitSymptomQuestionnare(currentPatient?.id, response)
          .then(() => {
            closeModal();
            dispatch(
              fetchUserSymptomsData({
                userId: currentPatient.id.toString(),
              }),
            );
            dispatch(getPatientByIdAsync({ userId: currentPatient.id, ignoreLocalState: true }));
            SnackbarUtils.success("Symptom was reported successful.");
          })
          .catch((e) => {
            const errMessage = get(e, "response.data.detail");

            SnackbarUtils.error(errMessage || "Could not report this symptom.");
          });
    });
  };

  const handleSetAnswer = async (
    answer: number | string | Array<string> | BodyPartAnswerItem[],
  ) => {
    const answersArr = {
      ...answers,
      [symptom]: [...((answers && answers[symptom]) || []), { answer, question_id: id }],
    };
    // Handles the last question
    if (goto === -1) setDisplayReportReview(true);

    let nextQuestion = allQuestions[symptom].questions.questions.find((q) => q.id === goto);

    // Skip questions that require uploading a photo
    if (nextQuestion?.view === QuestionViewType.Camera) {
      if (nextQuestion?.goto === -1) {
        setDisplayReportReview(true);
      } else
        nextQuestion = allQuestions[symptom].questions.questions.find(
          (q) => q.id === nextQuestion?.goto,
        );
    }
    // Handle questions that have additional flow ( switching to another symptom questionnaire )
    if (!nextQuestion && flow) {
      const goTo = flow.find(
        (el) => el.option.toLowerCase() === answer.toString().toLowerCase(),
      )?.goto;

      if (typeof goTo === "string") {
        if (reportedSymptoms?.map((el) => el.symptom.value).includes(goTo)) {
          setDisplayReportReview(true);
          setAnswers(answersArr);
          return;
        }
        let newQuestions: QuestionView[] = [];
        await fetchSymptomQuestionnare(goTo).then((res) => {
          newQuestions = res.questions.questions;
          setAllQuestions({
            [symptom]: questionnaire,
            [goTo]: res,
          });
        });
        setSymptom(goTo);
        nextQuestion = newQuestions?.find((q) => q.id === 1);
      } else if (goTo === -1) setDisplayReportReview(true);
      else nextQuestion = allQuestions[symptom].questions.questions.find((q) => q.id === goTo);
    }

    setAnswers(answersArr);
    if (nextQuestion) {
      setCurrentQuestion(nextQuestion);
    }
  };

  return (
    <Box py={2}>
      {displayReportReview ? (
        <SymptomReportReview
          questions={allQuestions}
          answers={answers}
          handleSymptomSubmit={handleSymptomSubmit}
        />
      ) : (
        <>
          <Box display="flex" sx={{ alignItems: "center", justifyContent: "center" }}>
            <Typography variant="h6" color={(theme) => theme.veris.colors.neutrals["grey-3"]}>
              You selected{" "}
            </Typography>{" "}
            <Typography
              variant="h6"
              color={(theme) => theme.veris.colors.amethyst.normal}
              py={1}
              px={2}
              mx={1}
              sx={{
                backgroundColor: (theme) => theme.veris.colors.amethyst.light,
                width: "fit-content",
                borderRadius: (theme) => theme.spacing(2),
                "::first-letter": {
                  textTransform: "capitalize",
                },
              }}
            >
              {symptom?.replaceAll("-", " ")}
            </Typography>
          </Box>

          <Box my={4}>
            <Typography
              variant="h6"
              color={(theme) => theme.veris.colors.neutrals["grey-3"]}
              py={1}
            >
              {currentQuestion?.id}/{questionnaire.questions.questions.length}
            </Typography>
            <Typography
              color={(theme) => theme.veris.colors.neutrals["grey-4"]}
              variant="h4"
              py={1}
            >
              {question}
            </Typography>{" "}
          </Box>

          <Box my={3}>{getAnswerView(view, handleSetAnswer, options || [], type)}</Box>
        </>
      )}
    </Box>
  );
};

export default SymptomQuestionnaire;
