import { Box, Typography, Grid, CircularProgress } from "@mui/material";
import React, { useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";
import InfiniteScroll from "react-infinite-scroll-component";
import relativeTime from "dayjs/plugin/relativeTime";
import { NotificationView, Reporter } from "@veris-health/communication-ms/lib/v1";
import { VrsIcon, IconName, VrsButton, verisColors } from "@veris-health/web-core";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import { NotificationListItem } from "../../ui/components/NotificationListItem";
import {
  notificationFilters,
  selectFilteredNotificationsfromEarlier,
  selectFilteredNotificationsfromToday,
  selectFilteredNotificationsfromYesterday,
  selectNotificationsFilter,
  setNotificationsFilter,
  showQuestionsAnswers,
  loadNotificationsDataAsync,
  selectNotificationsStatus,
  selectNotificationsFetchingOffset,
  selectQuestionsAnswers,
  selectNotifications,
  NotificationFilter,
  selectTotalCount,
} from "./notificationsSlice";
import { selectUserId } from "../shared/slices/authSlice";
import { replaceRouteParam } from "../../routes-config";
import { NotificationsDetailsContainer } from "./NotificationDetailsContainer";
import { useNotification } from "./hooks/useNotification";
import { selectPatientsMap } from "../shared/slices/patientsSlice";
import { useQuery } from "../../hooks/useQuery";
import { NOTIFICATIONS_OFFSET } from "../../constants";
import TaskContainer from "../Tasks/components/TaskContainer";
import { useProfile } from "../../context/profile";
import { useTaskNotification } from "./hooks/useTaskNotification";

dayjs.extend(relativeTime);

export const NotificationsListContainer = (): JSX.Element => {
  const filteredNotificationsToday = useAppSelector(selectFilteredNotificationsfromToday);
  const filteredNotificationsEarlier = useAppSelector(selectFilteredNotificationsfromEarlier);
  const filteredNotificationsYesterday = useAppSelector(selectFilteredNotificationsfromYesterday);
  const activeNotification = useNotification();
  const currentUserId = useAppSelector(selectUserId);
  const offset = useAppSelector(selectNotificationsFetchingOffset);
  const totalCount = useAppSelector(selectTotalCount);
  const notificationStatus = useAppSelector(selectNotificationsStatus);
  const notifications = useAppSelector(selectNotifications);

  const query = useQuery();
  const currentUser = useProfile();
  const patientId = query.get("userId");
  const patients = useAppSelector(selectPatientsMap);
  const activeFilter = useAppSelector(selectNotificationsFilter);
  const isShowQuestionsAnswers = useAppSelector(selectQuestionsAnswers);
  const { setNotification, notificationTask } = useTaskNotification();

  const dispatch = useAppDispatch();
  const history = useHistory();

  useEffect(() => {
    if (patientId) {
      dispatch(setNotificationsFilter(NotificationFilter.ViewAll));
      dispatch(
        loadNotificationsDataAsync({
          userId: Number(currentUserId),
          patientId: +patientId,
          offset: 0,
        }),
      );
    } else {
      dispatch(
        loadNotificationsDataAsync({
          userId: Number(currentUserId),
          offset: 0,
          reportedBy:
            activeFilter === NotificationFilter.ViewAll
              ? undefined
              : (activeFilter as unknown as Reporter),
        }),
      );
      if (activeNotification)
        history.push(replaceRouteParam("NOTIFICATIONS_DETAILS", ":notificationId", activeFilter));
    }
  }, [dispatch, patientId, activeFilter]);

  const fetchNotifications = useCallback(async () => {
    if (notificationStatus === "loading") {
      return;
    }
    dispatch(
      loadNotificationsDataAsync({
        userId: Number(currentUserId),
        offset: offset + NOTIFICATIONS_OFFSET,
        reportedBy:
          activeFilter === NotificationFilter.ViewAll
            ? undefined
            : (activeFilter as unknown as Reporter),
        patientId: Number(patientId) || undefined,
      }),
    );
  }, [notificationStatus, patientId]);

  const handleNotificationClick = (notification: NotificationView) => {
    if (isShowQuestionsAnswers) dispatch(showQuestionsAnswers(false));

    history.push({
      pathname: replaceRouteParam(
        "NOTIFICATIONS_DETAILS",
        ":notificationId",
        notification.notification_id.toString(),
      ),
      search: patientId ? `?userId=${patientId}` : "",
    });
  };

  const resetSearchClick = (param: string) => {
    if (patientId) {
      history.push(replaceRouteParam("NOTIFICATIONS_DETAILS", ":notificationId", param));

      dispatch(
        loadNotificationsDataAsync({
          userId: Number(currentUserId),
          patientId: undefined,
          offset: 0,
        }),
      );
    }
  };

  return (
    <>
      <Grid padding={3} container>
        <Grid
          container
          sx={{
            border: ({ veris }) => `1px solid ${veris.colors.neutrals["grey-light"]}`,
            borderRadius: "0.25rem",
          }}
        >
          <Grid
            item
            xs={notifications.length === 0 ? 12 : 6}
            sx={{
              borderRight: ({ veris }) =>
                notifications.length === 0
                  ? "none"
                  : `1px solid ${veris.colors.neutrals["grey-light"]}`,

              backgroundColor: ({ veris }) => veris.colors.neutrals.white,
              borderRadius: "0.25rem",
              borderTopRightRadius: notifications.length === 0 ? "0.25rem" : "0",
              borderBottomRightRadius: notifications.length === 0 ? "0.25rem" : "0",
            }}
          >
            <Box
              borderBottom={`3px solid ${verisColors.neutrals["grey-2"]}`}
              display="flex"
              flexDirection="row"
              pb={1.9}
              mt={1.9}
              mb={0.3}
              marginX={1.6}
            >
              {patientId && patients[Number(patientId)] && (
                <VrsButton
                  buttonType="primary"
                  sx={{
                    justifyContent: "space-between",
                    marginRight: (theme) => theme.spacing(2),
                    borderRadius: "0.5rem",
                    cursor: "auto",
                  }}
                  endIcon={
                    <VrsIcon
                      name={IconName.CloseIcon}
                      size={18}
                      sx={{ cursor: "pointer", position: "relative", top: "-2px" }}
                      stroke={verisColors.neutrals.white}
                    />
                  }
                  onClick={() => resetSearchClick(activeFilter)}
                >
                  {patients[Number(patientId)]?.name}
                </VrsButton>
              )}
              {notificationFilters.map((filterButton) => {
                return (
                  <VrsButton
                    key={filterButton.name}
                    buttonType={
                      activeFilter === filterButton.filter && !patientId ? "primary" : "quaternary"
                    }
                    onClick={() => {
                      resetSearchClick(filterButton.filter);
                      dispatch(setNotificationsFilter(filterButton.filter));
                    }}
                    sx={{
                      marginRight: (theme) => theme.spacing(2),
                      borderRadius: "0.5rem",
                    }}
                  >
                    {filterButton.name}
                  </VrsButton>
                );
              })}
            </Box>
            <Box
              marginX={1.6}
              marginRight={0.5}
              paddingRight={0.9}
              height="670px"
              overflow="auto"
              display="flex"
              flexDirection="column"
              id="notifications-list-scroll-container"
            >
              <InfiniteScroll
                scrollableTarget="notifications-list-scroll-container"
                dataLength={notifications.length}
                style={{ overflow: "hidden" }}
                hasMore={notifications.length < totalCount}
                next={() => {
                  if (notificationStatus === "idle") fetchNotifications();
                }}
                loader={
                  <Box display="flex" justifyContent="center" alignItems="center" p={1}>
                    <CircularProgress />
                  </Box>
                }
              >
                <Box>
                  {notifications.length === 0 && (
                    <Typography
                      variant="h6"
                      paddingY={1}
                      mt={2}
                      color={verisColors.neutrals["grey-3"]}
                    >
                      There are no notifications available for this
                      {patientId ? " patient" : " filter"}.
                    </Typography>
                  )}
                  {filteredNotificationsToday.length > 0 && (
                    <>
                      <Typography
                        variant="body1"
                        paddingY={1}
                        mt={2}
                        color={verisColors.neutrals["grey-3"]}
                        sx={{ textTransform: "uppercase" }}
                      >
                        Today
                      </Typography>
                      {filteredNotificationsToday.map((notification) => (
                        <NotificationListItem
                          key={notification.notification_id}
                          notification={notification}
                          isActive={
                            !!activeNotification &&
                            activeNotification.notification_id === notification.notification_id
                          }
                          onClick={() => handleNotificationClick(notification)}
                          onCreateTask={setNotification}
                        />
                      ))}
                    </>
                  )}
                  {filteredNotificationsYesterday.length > 0 && (
                    <>
                      <Typography
                        variant="body1"
                        paddingY={1}
                        mt={2}
                        color={verisColors.neutrals["grey-3"]}
                        sx={{ textTransform: "uppercase" }}
                      >
                        Yesterday
                      </Typography>
                      {filteredNotificationsYesterday.map((notification) => (
                        <NotificationListItem
                          key={notification.notification_id}
                          notification={notification}
                          isActive={
                            !!activeNotification &&
                            activeNotification.notification_id === notification.notification_id
                          }
                          onClick={() => handleNotificationClick(notification)}
                          onCreateTask={setNotification}
                        />
                      ))}
                    </>
                  )}

                  {filteredNotificationsEarlier.length > 0 && (
                    <>
                      <Typography
                        variant="body1"
                        paddingY={1}
                        mt={2}
                        color={verisColors.neutrals["grey-3"]}
                        sx={{ textTransform: "uppercase" }}
                      >
                        Older
                      </Typography>
                      {filteredNotificationsEarlier.map((notification) => {
                        return (
                          <NotificationListItem
                            key={notification.notification_id}
                            notification={notification}
                            isActive={
                              !!activeNotification &&
                              activeNotification.notification_id === notification.notification_id
                            }
                            onClick={() => handleNotificationClick(notification)}
                            onCreateTask={setNotification}
                          />
                        );
                      })}
                    </>
                  )}
                </Box>
              </InfiniteScroll>
            </Box>
          </Grid>
          {notificationStatus !== "failed" && notifications.length !== 0 && (
            <Grid
              sx={{
                backgroundColor: ({ veris }) => veris.colors.neutrals["grey-background"],
                borderRadius: "0.25rem",
              }}
              item
              xs={6}
            >
              <NotificationsDetailsContainer />
            </Grid>
          )}
          {notificationTask && currentUser && (
            <TaskContainer
              onClose={() => setNotification(undefined)}
              currentUser={currentUser}
              open={!!notificationTask}
              modalView
              currentPatientId={notificationTask?.patient?.id}
              notificationTask={notificationTask}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};
